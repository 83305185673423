<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Add Allowed Business Processes</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row
                        v-for="allowed_process in allowed_business_processes"
                        :key="allowed_process.id"
                        style="background:#eaeaea; padding-bottom:10px"
                    >
                        <v-col cols="12" sm="12">
                            <v-select
                                v-model="allowed_process.process"
                                :items="business_processes"
                                color="secondary"
                                item-text="display_value"
                                item-value="_id"
                                label="Business Process"

                                :disabled="refreshing_business_processes_list"

                                hint="You can click the refresh icon next to the field if the business-process list is incomplete"
                                persistent-hint

                                required
                            >
                                <!-- Format prepend inner icon -->
                                <template v-slot:prepend-inner>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon
                                                v-on="on"
                                                color="success"
                                                @click="refreshBusinessProcessesList()"
                                            >
                                                mdi-refresh
                                            </v-icon>
                                        </template>
                                        <span>Refresh Business Processes List</span>
                                    </v-tooltip>
                                </template>
                            </v-select>
                            <v-progress-linear
                                color="green"
                                class="mb-0"
                                height="4"
                                indeterminate
                                :active="refreshing_business_processes_list"
                            ></v-progress-linear>
                        </v-col>
                        <div style="background:white; height: 10px; width: 100%"></div>
                    </v-row>
                    <!-- Buttons for Adding/Removing allowed_business_processes -->
                    <v-row>
                        <v-col sm="10"></v-col>
                        <v-col sm="2">
                            <v-tooltip bottom v-if="allowed_business_processes.length > 1">
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        class="mx-2"
                                        color="secondary"
                                        small
                                        v-on="on"
                                        @click="removeBusinessProcess()"
                                    >
                                        <v-icon>remove</v-icon>
                                    </v-btn>
                                </template>
                                <span>Remove Business Process</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        color="secondary"
                                        small
                                        v-on="on"
                                        @click="addBusinessProcess()"
                                    >
                                        <v-icon>add</v-icon>
                                    </v-btn>
                                </template>
                                <span>Add Another Business Process</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting Business Processes
                        <v-progress-linear
                            indeterminate
                            :active="submitting"
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submitDataBtnDisabled()"
                                @click="submitData()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Save Business Process Details</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";

import { getBusinessProcesses } from "../gql/queries";
import { lookupProcessUserRole } from "../gql/mutations";

export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        role_id: {
            type: String,
            default: null
        },
    },
    data: () => ({
        server_response_obj: null,
        submitting: false,
        refreshing_business_processes_list: false,

        allowed_business_processes: [
            {
                id: 1,
                process: null,
            }
        ],
        business_processes: []
    }),
    methods: {
        closeDialog() {
            eventBus.$emit('CLOSE_ADD_ALLOWED_BUSINESS_PROCESSES_DIALOG', this.server_response_obj);
        },
        resetForm() {
            this.submitting = false;
            this.server_response_obj = null;

            this.allowed_business_processes = [
                {
                    id: 1,
                    process: null,
                }
            ];
        },
        
        //--------------------ADD/REMOVE ALLOWED BUSINESS PROCESSES--------------------------//
        addBusinessProcess() {
            const allowed_business_processes_size = this.allowed_business_processes.length;

            this.allowed_business_processes.push({
                id: allowed_business_processes_size + 1,
                process: null,
            });
        },
        removeBusinessProcess() {
            this.allowed_business_processes.pop();
        },

        submitDataBtnDisabled() {
            let validBusinessProcess = this.allowed_business_processes.filter(rep => { return rep.process !== null });

            if (validBusinessProcess.length === 0) {
                return true;
            }

            return false;
        },

        async submitData() {
            const validAllowedBusinessProcesses = this.allowed_business_processes
                                    .filter(rep => { return rep.process !== null });

            const arrCopy = JSON.parse(JSON.stringify(validAllowedBusinessProcesses));
            arrCopy.forEach(perm => {
                delete perm.id;
                return perm;
            });


            try {
                this.submitting = true;
                const res = await this.$apollo
                                            .mutate({
                                                mutation: lookupProcessUserRole,
                                                variables: {
                                                    input: {
                                                        OP_TYPE: 'ADD_ALLOWED_BUSINESS_PROCESS',
                                                        _id: this.role_id,

                                                        // Fields not needed (but placeholders)
                                                        name: '',
                                                        description: '',

                                                        // Needed payload
                                                        allowed_business_processes: arrCopy
                                                    }
                                                },
                                            });
                if (res.data.lookupProcessUserRole.errors === true) {
                    emitAlert(res.data.lookupProcessUserRole.message);
                    this.submitting = false;
                    return;
                }

                emitSuccess(res.data.lookupProcessUserRole.message);
                
                this.server_response_obj = res.data.lookupProcessUserRole.business_object;
                this.closeDialog();

                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.lookupProcessUserRole.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }

        },

        async refreshBusinessProcessesList() {
            this.refreshing_business_processes_list = true;
            this.business_processes = [];

            try {
                const res = await this.$apollo
                                    .query({
                                        query: getBusinessProcesses,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'BUSINESS_PROCESS',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.submitting = false;
                    return;
                }

                this.refreshing_business_processes_list = false;
                
                this.business_processes = res.data.getBusinessObject.business_objects.map(process => {
                    process.display_value = process.name + ' - ' + process.description;
                    return process;
                });
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        }
    },
    async mounted() {
        // Load Vusiness Processes List
        try {
            const res = await this.$apollo
                                    .query({
                                        query: getBusinessProcesses,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'BUSINESS_PROCESS',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.submitting = false;
                return;
            }
            
            this.business_processes = res.data.getBusinessObject.business_objects.map(process => {
                process.display_value = process.name + ' - ' + process.description;
                return process;
            });
        } catch (error) {
            this.submitting = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
            
            return;
        }
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
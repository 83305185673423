<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Add Screen</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeAddScreen()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <!-- Screen Name -->
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="name"
                                color="secondary"
                                label="Screen Name"
                                required
                            ></v-text-field>
                        </v-col>
                        <!-- Screen Category -->
                        <v-col cols="12" sm="6">
                            <v-select
                                v-model="screen_category"
                                :items="[
                                    'Core Insurance System',
                                    'Dashboard',
                                    'Lookups',
                                    'Settings',
                                    'Finance Management',
                                    'Credit',
                                    'Central Underwriting',
                                    'VAS',
                                    'Management Accounts'
                                ]"
                                color="secondary"
                                label="Screen Category"
                                required
                            ></v-select>
                        </v-col>
                        <!-- Description -->
                        <v-col cols="12" sm="12">
                            <v-text-field
                                v-model="description"
                                color="secondary"
                                label="Description"
                                type="text"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting Screen Details
                        <v-progress-linear
                            indeterminate
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submitScreenBtnDisabled()"
                                @click="submitScreen()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Submit Screen Details</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";

import { createUserScreen } from "../gql/mutations";

export default {
    name: 'SearchMembersDialog',
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        name: null,
        description: null,
        screen_category: null,
        server_resp_screen: null,
        submitting: false,
    }),
    methods: {
        closeAddScreen() {
            eventBus.$emit('CLOSE_ADD_SCREEN_DIALOG', this.server_resp_screen);
        },
        resetForm() {
            this.name = null;
            this.description = null;
            this.screen_category = null;

            this.server_resp_screen = null;
            this.submitting = false;
        },
        submitScreenBtnDisabled() {
            if (this.name === null || this.description === null || this.screen_category === null) {
                return true;
            }

            if ((this.name + '').trim() === '' || (this.description + '').trim() === '') {
                return true;
            }
            return false;
        },
        async submitScreen() {
            this.submitting = true;

            // createUserScreen(this.name, this.description).then((res) => {
            //     this.submitting = false;

            //     if (res._errors !== null) {
            //         this.error_message = res._errors;
            //     } else {
            //         this.server_resp_screen = res.user_screen;
            //         this.closeAddScreen();

            //         this.name = null;
            //         this.description = null;
            //         this.server_resp_screen = null;
            //     }
            // });



            try {
                const payload = {
                    name: this.name,
                    description: this.description,
                    category: this.screen_category
                };
                // const res = await createUserScreen(payload);
                const res = await this.$apollo
                                            .mutate({
                                                mutation: createUserScreen,
                                                variables: {
                                                    input: payload
                                                },
                                            });
                if (res.data.lookupProcessUserScreen.errors === true) {
                    emitAlert(res.data.lookupProcessUserScreen.message);
                    this.submitting = false;
                    return;
                }

                this.server_resp_bank = res.data.lookupProcessUserScreen.business_object;
                emitSuccess(res.data.lookupProcessUserScreen.message);

                this.closeAddScreen();
                this.resetForm();
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.lookupProcessUserScreen.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        }
    },
    mounted() {
        
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
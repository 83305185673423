<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Create Scheme Allocation</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeOnboardingDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-stepper v-model="e1">
                <v-stepper-header>
                    <v-stepper-step :complete="e1 > 1" step="1" color="success">Transaction Details</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 2" step="2" color="success">Policies</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 3" step="3" color="success">Documents</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="4" color="success">Processing</v-stepper-step>
                </v-stepper-header>
            
                <v-stepper-items>
                    <!-- Basic Details -->
                    <v-stepper-content step="1">
                        <v-card>
                            <v-card-text>
                                <!-- Insurance Shceme Information -->
                                <v-row style="background:#b6eedb; padding-bottom:10px">
                                    <v-col cols="12" sm="3" style="padding: 34px;">
                                        <h2>Allocation Source</h2>
                                    </v-col>
                                    <v-col cols="12" sm="9">
                                        <v-radio-group
                                            v-model="allocation_source"
                                            row
                                        >
                                            <v-radio
                                                label="BANK-FEED"
                                                value="BANK-FEED"
                                                color="secondary"
                                            ></v-radio>
                                            <v-radio
                                                label="SCHEME-BALANCE"
                                                value="SCHEME-BALANCE"
                                                color="secondary"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                                <br><br>
                                <!-- Insurance Shceme Information -->
                                <v-row style="background:#eaeaea; padding-bottom:10px">
                                    <v-col cols="12" sm="12">
                                        <h2>Scheme Information</h2>
                                    </v-col>
                                    <v-col cols="12" sm="12">
                                        <SchemeSearch :scheme="scheme_allocation_details" label="Insurance Scheme to Allocate to" />
                                    </v-col>
                                </v-row>
                                <br><br>
                                <!-- Bank Feed Information -->
                                <v-row
                                    v-if="allocation_source === 'BANK-FEED'"
                                    style="background:#efee91; padding-bottom:10px"
                                >
                                    <v-col cols="12" sm="12">
                                        <h2>Bank Feed Information</h2>
                                    </v-col>
                                    <v-col cols="12" sm="8">
                                        <BankFeedSearch
                                            :item="bank_feed_search"
                                        />
                                    </v-col>
                                    <!-- Payment Method -->
                                    <v-col cols="12" sm="4">
                                        <v-select
                                            v-model="scheme_allocation_details.payment_method"
                                            :items="system_finance_accounts"
                                            color="secondary"
                                            item-text="name"
                                            item-value="_id"
                                            label="Payment Method"
                                            :disabled="refreshing_system_finance_accounts_list"

                                            hint="You can click the refresh icon next to the field if the system accounts list is incomplete"
                                            persistent-hint

                                            required
                                        >
                                            <!-- Format prepend inner icon -->
                                            <template v-slot:prepend-inner>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon
                                                            v-on="on"
                                                            color="success"
                                                            @click="refreshSystemAccountsLookupList()"
                                                        >
                                                            mdi-refresh
                                                        </v-icon>
                                                    </template>
                                                    <span>Refresh System Accounts List</span>
                                                </v-tooltip>
                                            </template>
                                        </v-select>
                                        <v-progress-linear
                                            color="green"
                                            class="mb-0"
                                            height="4"
                                            indeterminate
                                            :active="refreshing_system_finance_accounts_list"
                                        ></v-progress-linear>
                                    </v-col>
                                </v-row>
                                <br><br>
                                <v-row
                                    v-if="bank_feed_search.bank_feed_item !== null"
                                    style="background:#eaeaea; padding-bottom:10px"
                                >
                                    <v-col cols="12" sm="12">
                                        <h2>Bank Feed Details</h2>
                                    </v-col>
                                    <!-- Date -->
                                    <v-col cols="12" sm="2">
                                        <v-text-field
                                            :value="bank_feed_search.bank_feed_item.date.split('T')[0]"
                                            color="secondary"
                                            label="Transaction Date"
                                            disabled
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <!-- Description -->
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            :value="bank_feed_search.bank_feed_item.description"
                                            color="secondary"
                                            label="Transaction Description"
                                            disabled
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <!-- Description -->
                                    <v-col cols="12" sm="3">
                                        <v-text-field
                                            :value="bank_feed_search.bank_feed_item.reference"
                                            color="secondary"
                                            label="Transaction Reference"
                                            disabled
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <!-- Amount -->
                                    <v-col cols="12" sm="2">
                                        <v-text-field
                                            :value="bank_feed_search.bank_feed_item.amount"
                                            color="secondary"
                                            label="Amount"
                                            prefix="R"
                                            disabled
                                            required
                                        ></v-text-field>
                                    </v-col>

                                    <!-- Classified -->
                                    <v-col
                                        cols="12"
                                        sm="1"
                                        :style="bank_feed_search.bank_feed_item.classification !== null ? 'background-color: rgb(234, 121, 121);' : 'background-color: green'">
                                        <v-text-field
                                            :value="bank_feed_search.bank_feed_item.classification === null ? 'NO' : 'YES'"
                                            color="secondary"
                                            label="Classified"
                                            disabled
                                            required
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-progress-linear
                                color="green"
                                class="mb-0"
                                height="10"
                                indeterminate
                                :active="backend_check"
                            ></v-progress-linear>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    fab
                                    color="amber"
                                    :disabled="moveToStep2BtnDisabled()"
                                    @click="moveToStep2()"
                                >
                                    <v-icon large>keyboard_arrow_right</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Policies Section -->
                    <v-stepper-content step="2">
                        <v-card>
                            <v-card-text>
                                <!-- Scheme Basic Details -->
                                <v-row style="background:#ffda62; padding-bottom:10px">
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            v-if="scheme_allocation_details.scheme !== null"
                                            :value="scheme_allocation_details.scheme.name"
                                            color="secondary"
                                            label="Scheme"
                                            disabled
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" v-if="bank_feed_search.bank_feed_item !== null">
                                        <v-text-field
                                            v-model="bank_feed_search.bank_feed_item.amount"
                                            color="secondary"
                                            label="Transaction Amount"
                                            prefix="ZAR"
                                            disabled
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <!-- Amount -->
                                    <v-col cols="12" sm="2" v-else>
                                        <v-text-field
                                            :value="scheme_balance"
                                            color="secondary"
                                            label="Scheme Balance"
                                            prefix="R"
                                            disabled
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            v-model="scheme_allocation_details.policies_info.total_policy_premium"
                                            color="secondary"
                                            label="Total Scheme Premium"
                                            prefix="ZAR"
                                            disabled
                                            required
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <br><br>
                                <!-- Policy List -->
                                <div style="height:500px; padding-left:10px; overflow-y:scroll; overflow-x:hidden;">
                                    <SchemePolicyItem
                                        :policy="policy"
                                        v-for="policy in scheme_allocation_details.policies_info.policies"
                                        :key="policy._id"
                                        style="background:#eaeaea; padding-bottom:10px"
                                    />
                                    <div style="background:white; height: 10px; width: 100%"></div>
                                </div>
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="e1 = 1"
                                >
                                    <v-icon large>keyboard_arrow_left</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="validatePolicyPremiums()"
                                >
                                    <v-icon large>keyboard_arrow_right</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Documents Section -->
                    <v-stepper-content step="3">
                        <v-card>
                            <v-card-text>
                                <v-row
                                    v-for="file in file_details"
                                    :key="file.id"
                                    style="background:#eaeaea; padding-bottom:10px"
                                >
                                    <v-col cols="12" sm="5">
                                        <v-select
                                            v-model="file.type"
                                            :items="['Scheme Allocation Form']"
                                            color="secondary"
                                            item-value="name"
                                            item-text="name"
                                            label="Document Type"
                                            required
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-file-input
                                            v-model="file.file"
                                            color="secondary"
                                            accept="application/pdf, image/*"
                                            label="File/Document"
                                        ></v-file-input>
                                    </v-col>
                                    <v-col cols="12" sm="1">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    fab
                                                    color="amber"
                                                    v-on="on"
                                                    @click="previewFile(file)"
                                                    :disabled="file.file===null || file.file===undefined"
                                                >
                                                    <v-icon>remove_red_eye</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Preview File</span>
                                        </v-tooltip>
                                    </v-col>
                                    <div style="background:white; height: 10px; width: 100%"></div>
                                </v-row>
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="e1 = 2"
                                >
                                    <v-icon large>keyboard_arrow_left</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    large
                                    color="success"
                                    :disabled="submitBtnDisabled()"
                                    @click="submitSchemeAllocation()"
                                >
                                    <v-icon large>save</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Submission Results Section -->
                    <v-stepper-content step="4">
                         <v-card light >
                            <v-card-text class="text-center">
                                <v-progress-linear
                                    color="green"
                                    class="mb-0"
                                    height="10"
                                    indeterminate
                                    :active="submitting"
                                ></v-progress-linear>
                            </v-card-text>
                            <v-list flat>
                                <v-subheader>Saving Scheme Info</v-subheader>
                                <v-list-item-group color="primary">
                                    <v-list-item
                                        v-for="(item, i) in backendActionItems"
                                        :key="i"
                                    >
                                        <v-list-item-icon>
                                            <v-icon>done</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ item }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    large
                                    color="success"
                                    @click="closeOnboardingDialog()"
                                >
                                    <v-icon large>done</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>
        <DocPreviewDialog
            :dialog="open_doc_preview_dialog"
            :url="file_url"
            :doc_type="doc_type"
            @CLOSE_DOC_PREVIEW_DIALOG="closeDocPreviewDialog()"
        />
    </v-row>
</template>
<script>
import BankFeedSearch from "../components/bank_feed_search.vue";
import DocPreviewDialog from '../../../components/doc_preview';
import ObjectID from "bson-objectid";

import SchemeSearch from "../components/scheme_search";
import SchemePolicyItem from "../components/scheme_policy.vue";
import { submitDocumentumFile, emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";

import { getSchemePolicies, getSystemFinanceAccounts, finProcessFinanceGetAccountBalance } from "../gql/queries";
import { businessProcessSchemeAllocation } from "../gql/mutations";

export default {
    components: {
        DocPreviewDialog,
        SchemeSearch,
        SchemePolicyItem,
        BankFeedSearch,
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        backend_check: false,
        submitting: false,
        backendActionItems: [],
        server_response_obj: null,

        file: '',

        // File Preview Dialog
        file_url: null,
        doc_type: null,

        transaction_date_menu: false,

        e1: 1,
        allocation_source: 'BANK-FEED',
        scheme_balance: -1,
        scheme_allocation_details: {
            scheme: null,
            bank_reference: null,

            transaction_date: null,
            payment_method: null,

            policies_info: {
                total_policy_premium: 0,
                bank_reference: null,
                policies: []
            },

            name: '',
        },
        file_details: [
            {
                id: 1,
                type: 'Scheme Allocation Form',
                file: null,
                valid: false,
            }
        ],

        open_doc_preview_dialog: false,
        documents_map: {},

        bank_feed_search: {
            bank_feed_item: null
        },

        refreshing_system_finance_accounts_list: false,
        system_finance_accounts: [],
    }),
    methods: {
        closeOnboardingDialog() {
            eventBus.$emit('CLOSE_ONBOARDING_DIALOG', this.server_response_obj);
        },
        resetForm() {
            this.e1 = 1;
            this.server_response_obj = null;
            this.submitting = false;

            this.documents_map = {};

            this.scheme_allocation_details = {
                scheme: null,
                bank_reference: null,

                transaction_date: null,
                payment_method: null,

                policies_info: {
                    total_policy_premium: 0,
                    bank_reference: null,
                    policies: []
                },

                name: '',
            };

            this.file_details = [
                {
                    id: 1,
                    type: 'Scheme Allocation Form',
                    file: null,
                    valid: false,
                }
            ];

            this.bank_feed_search = {
                bank_feed_item: null
            };
        },

        previewFile(fileObject) {
            // Set file oobject so we can trigger the readAsDataURL function
            const reader = new FileReader();

            reader.addEventListener("load", () => {
                // Set PDF-Viewer URL to the selected file Base64 String
                this.file_url = reader.result;
                if (fileObject.file.type === 'application/pdf') {
                    this.doc_type = 'pdf';
                } else {
                    this.doc_type = 'image';
                }

                // Display the preview dialog
                this.open_doc_preview_dialog = true;
            }, false);

            reader.readAsDataURL(fileObject.file);
        },
        closeDocPreviewDialog() {
            this.open_doc_preview_dialog = false;
        },

        moveToStep2BtnDisabled() {
            if (this.scheme_allocation_details.scheme === null) {
                return true;
            }
            if (this.allocation_source === 'BANK-FEED') {
                if (this.bank_feed_search.bank_feed_item === null) {
                    return true;
                }
                if (this.scheme_allocation_details.payment_method === null) {
                    return true;
                }
            }
            return false;
        },
        async moveToStep2() {
            // Check if scheme with name entered already exists
            this.backend_check = true;

            try {
                // Get scheme policies
                const res = await this.$apollo
                                    .query({
                                        query: getSchemePolicies,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'POLICY',
                                            pagination: {
                                                page: 1,
                                                limit: 100
                                            },
                                            searchInput: {
                                                scheme: this.scheme_allocation_details.scheme._id
                                            }
                                        },
                                    });
                this.backend_check = false;

                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.submitting = false;
                    return;
                }

                const schemePolicies = res.data.getBusinessObject.business_objects;
                // console.log(schemePolicies);

                /**
                 * Get policies which the scheme is set to be contributing premiums for
                 */
                this.scheme_allocation_details.policies_info.policies = schemePolicies
                                                                    .filter(item => {
                                                                        const hasSchemePortion = item.payment_information.scheme.percentage_amount > 0;
                                                                        return (
                                                                            hasSchemePortion === true &&
                                                                            (
                                                                                item.status === 'ACTIVE' ||
                                                                                item.status === 'LAPSABLE'
                                                                            )
                                                                        );
                                                                    })
                                                                    .map(item => {
                                                                        const premium = item.premium;

                                                                        // calculate scheme portion
                                                                        let schemePortion = (item.payment_information.scheme.percentage_amount/100) * premium;
                                                                        schemePortion = Math.round((schemePortion + Number.EPSILON) * 100) / 100; // rounded to at most 2 decimal places

                                                                        item.premium = premium;
                                                                        item.premium_scheme_copy = isNaN(schemePortion) ? 0 : schemePortion;
                                                                        item.premium_scheme = isNaN(schemePortion) ? 0 : schemePortion;
                                                                        return item;
                                                                    });

                let total = 0;
                this.scheme_allocation_details.policies_info.policies.forEach(item => {
                    total += item.premium_scheme;
                });
                total = Math.round((total + Number.EPSILON) * 100) / 100; // rounded to at most 2 decimal places
                this.scheme_allocation_details.policies_info.total_policy_premium = total;

                /**
                 * Get scheme balance
                 */
                if (this.allocation_source === 'SCHEME-BALANCE') {
                    const balanceRes = await this.$apollo
                                        .query({
                                            query: finProcessFinanceGetAccountBalance,
                                            // fetchPolicy: 'network-only',
                                            fetchPolicy: 'no-cache',
                                            errorPolicy: 'all',
                                            variables: {
                                                account_id: this.scheme_allocation_details.scheme._id
                                            },
                                        });
                    if (balanceRes.data.finProcessFinanceGetAccountBalance.errors === true) {
                        this.submitting = false;
                        emitAlert(balanceRes.data.finProcessFinanceGetAccountBalance.message);
                        return;
                    }

                    this.scheme_balance = balanceRes.data.finProcessFinanceGetAccountBalance.balance;
                }

                // Move to next page
                this.e1 = 2;
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },

        validatePolicyPremiums() {
            const totalCapturedPremiums = this.scheme_allocation_details.policies_info.total_policy_premium;

            if (this.allocation_source === 'BANK-FEED') {
                const transactionAmount = this.bank_feed_search.bank_feed_item.amount;
                if (totalCapturedPremiums > transactionAmount) {
                    emitAlert('The captured total-premium amount is more than the transaction amount.');
                    return;
                }
            }

            if (this.allocation_source === 'SCHEME-BALANCE') {
                const schemeBalance = this.scheme_balance;
                if (totalCapturedPremiums > schemeBalance) {
                    emitAlert('The captured total-premium amount is more than the scheme-balance.');
                    return;
                }
            }

            this.e1 = 3;
        },
        submitBtnDisabled() {
            /**
             * Remove the following condition because SCHEME-BALANCE allocations 
             * might not need documents
             */

            if (this.allocation_source === 'BANK-FEED') {
                if (this.file_details[0].file === null) {
                    return true;
                }
            }
            return false;
        },
        async submitSchemeAllocation() {
            this.e1 = 4;

            this.submitting = true;
            const schemeObj = this.extractSchemeAllocationInfo();

            // if (schemeObj !== null) {
            //     console.log(schemeObj);
            //     return;
            // }

            try {
                this.backendActionItems.push("Saving scheme-allocation info");

                const payloadObj = {
                    OP_TYPE: 'CREATE',

                    ...schemeObj
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcessSchemeAllocation,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.businessProcessSchemeAllocation.errors === true) {
                    emitAlert(res.data.businessProcessSchemeAllocation.message);
                    this.submitting = false;
                    this.backendActionItems = [];
                    return;
                }

                this.backendActionItems.push("Saving scheme-allocation info succeeded");


                // Now upload documents to documentum
                const schemeId = res.data.businessProcessSchemeAllocation.business_object._id;

                if (this.file_details.filter(file => { return file.valid === true; }).length > 0) {
                    this.backendActionItems.push("Saving scheme-allocation document");
                }
                
                for (const file of this.file_details) {
                    if (file.valid) {
                        const doc = this.documents_map[ file.id ];


                        const uploadRes = await submitDocumentumFile(file.id, file.file, 'scheme_allocation', schemeId);
                        if (uploadRes.errors === true) {
                            emitAlert(uploadRes.message);
                            this.submitting = false;
                            this.backendActionItems.push(`  --> Uploading ${doc.name} failed`);
                            return;
                        }
                        
                        this.backendActionItems.push(`  --> Uploading ${doc.name} succeeded`);
                    }
                }

                this.server_response_obj = res.data.businessProcessSchemeAllocation.business_object;
                this.closeOnboardingDialog();
                emitSuccess(res.data.businessProcessSchemeAllocation.message);
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessSchemeAllocation.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
        extractSchemeAllocationInfo() {
            const schemeObj = {
                status: 'NOT-PROCESSED',
                scheme: null,
                // allocation_source: this.allocation_source,

                transaction_date: null,
                transaction_amount: null,
                transaction_description: null,
                transaction_reference: null,

                bank_feed_item: null,
                allocation_amount: null,
                payment_method: null,

                documents: [],
                policies: []
            };

            schemeObj.scheme = this.scheme_allocation_details.scheme._id;
            
            
            
            schemeObj.allocation_amount = this.scheme_allocation_details.policies_info.total_policy_premium;
            
            if (this.allocation_source === 'BANK-FEED') {
                schemeObj.payment_method = this.scheme_allocation_details.payment_method;
                schemeObj.bank_feed_item = this.bank_feed_search.bank_feed_item._id;
                schemeObj.transaction_date = this.bank_feed_search.bank_feed_item.date.split('T')[0];
                schemeObj.transaction_amount = this.bank_feed_search.bank_feed_item.amount;
                schemeObj.transaction_description = this.bank_feed_search.bank_feed_item.description;
                schemeObj.transaction_reference = this.bank_feed_search.bank_feed_item.reference;
            } else {
                // SCHEME-BALANCE
                schemeObj.payment_method = this.scheme_allocation_details.scheme._id;
                schemeObj.bank_feed_item = null;
                // schemeObj.transaction_date = this.bank_feed_search.bank_feed_item.date.split('T')[0];
                // schemeObj.transaction_amount = this.scheme_balance;
                // schemeObj.transaction_description = this.bank_feed_search.bank_feed_item.description;
                // schemeObj.transaction_reference = this.bank_feed_search.bank_feed_item.reference;
            }

            schemeObj.policies = this.scheme_allocation_details.policies_info.policies.map(item => {
                                        return {
                                            policy: item._id,
                                            premium_amount: item.premium,
                                            scheme_contribution_amount: item.premium_scheme,
                                        };
                                    });

            // ----------------------------------Extract documents if there are any
            const documents = [];
            this.file_details.forEach(file => {
                if ((file.file !== null) && (file.type !== null)) {
                    const objID = ObjectID();

                    file.id = objID.toString();
                    file.valid = true;

                    documents.push({
                        _id: objID, //Generate mngodb id
                        type: file.type,
                        name: file.file.name,
                        mime_type: file.file.type,
                    });

                    this.documents_map[objID.toString()] = {
                        _id: objID, //Generate mngodb id
                        type: file.type,
                        name: file.file.name,
                        mime_type: file.file.type,
                    };
                }

                schemeObj.documents = documents;
            });

            return schemeObj;
        },

        schemePolicyPremiumChanged(eventObj) {
            let total = 0;
            this.scheme_allocation_details.policies_info.policies.forEach(item => {
                let premiumScheme = Math.round((parseFloat(item.premium_scheme) + Number.EPSILON) * 100) / 100; // rounded to at most 2 decimal places
                if (item._id === eventObj._id) {
                    const premFloat = parseFloat(eventObj.premium_scheme);
                    if (!isNaN(premFloat)) {
                        premiumScheme = Math.round((premFloat + Number.EPSILON) * 100) / 100; // rounded to at most 2 decimal places
                    } else {
                        premiumScheme = 0;
                    }
                }

                // Update scheme premium for policy
                item.premium_scheme = premiumScheme;

                total += premiumScheme;
                total = Math.round((total + Number.EPSILON) * 100) / 100; // rounded to at most 2 decimal places
            });

            this.scheme_allocation_details.policies_info.total_policy_premium = total;
        },

        async refreshSystemAccountsLookupList() {
            this.refreshing_system_finance_accounts_list = true;
            try {
                // Load System Accounts
                const res = await this.$apollo
                                    .query({
                                        query: getSystemFinanceAccounts,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'FINANCE_SYSTEM_ACCOUNT',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                this.refreshing_system_finance_accounts_list = false;
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }

                this.system_finance_accounts = res.data.getBusinessObject.business_objects.filter(acc => {
                                                                                return (
                                                                                    acc.name === 'CARD_SWIPE' ||
                                                                                    acc.name === 'CASH' ||
                                                                                    acc.name === 'EFT' ||
                                                                                    acc.name === 'ATM'
                                                                                );
                                                                            });
            } catch (error) {
                this.refreshing_system_finance_accounts_list = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
    },
    async mounted() {
        eventBus.$on('SCHEME_POLICY_PREMIUM_SCHANGE', this.schemePolicyPremiumChanged);

        // Get system accounts list
        await this.refreshSystemAccountsLookupList();
    },
}
</script>
<style scoped>

</style>
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>People Search</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeMemberSearchDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <!-- First Names -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.name.search"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-text-field
                                v-model="search_fields.name.value"
                                label="First Name(s)"
                                required
                                :disabled="!search_fields.name.search"
                            ></v-text-field>
                        </v-col>
                        <!-- Surname -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.surname.search"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-text-field
                                v-model="search_fields.surname.value"
                                label="Surname"
                                required
                                :disabled="!search_fields.surname.search"
                            ></v-text-field>
                        </v-col>
                        <!-- Id Number -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.id_number.search"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-text-field
                                v-model="search_fields.id_number.value"
                                label="ID Number"
                                required
                                :disabled="!search_fields.id_number.search"
                            ></v-text-field>
                        </v-col>
                        <!-- Birthdate -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.date_of_birth.search"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-menu
                                v-model="birthdate_menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="search_fields.date_of_birth.value"
                                    label="Birth Date"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :disabled="!search_fields.date_of_birth.search"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    color="secondary"
                                    v-model="search_fields.date_of_birth.value"
                                    @input="birthdate_menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <!-- Gender -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.gender.search"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-select
                                v-model="search_fields.gender.value"
                                :items="entity_gender"
                                label="Gender"
                                :disabled="!search_fields.gender.search"
                                required
                            ></v-select>
                        </v-col>
                        <!-- Marital Status -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.marital_status.search"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-select
                                v-model="search_fields.marital_status.value"
                                :items="marital_statuses"
                                label="Marital Status"
                                :disabled="!search_fields.marital_status.search"
                                required
                            ></v-select>
                        </v-col>
                        <!-- Life Status -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.life_status.search"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-select
                                v-model="search_fields.life_status.value"
                                :items="['Alive', 'Deceased']"
                                label="Life Status"
                                :disabled="!search_fields.life_status.search"
                                required
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Searching Member(s)
                        <v-progress-linear
                            indeterminate
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom v-if="search_field_selected">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="primary"
                                v-on="on"
                                @click="submitSearchQuery()"
                            >
                                <v-icon large>search</v-icon>
                            </v-btn>
                        </template>
                        <span>Submit Search Query</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { entity_gender, marital_statuses } from "../../../../config";
import { eventBus } from '../../../main';
import { searchEntity } from '../../../utils/api';
export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        birthdate_menu: false,

        search_fields: {
            name: {
                search: false,
                value: null,
            },
            surname: {
                search: false,
                value: null,
            },
            id_number: {
                search: false,
                value: null,
            },
            date_of_birth: {
                search: false,
                value: null,
            },
            gender: {
                search: false,
                value: null,
            },
            marital_status: {
                search: false,
                value: null,
            },
            life_status: {
                search: false,
                value: null,
            },
        },

        entity_gender: entity_gender,
        marital_statuses: marital_statuses,

        submitting: false
    }),
    methods: {
        closeMemberSearchDialog() {
            eventBus.$emit('CLOSE_MEMBER_SEARCH_DIALOG', null);
        },
        async submitSearchQuery() {
            this.submitting = true;

            const searchObj = {};

            Object.keys(this.search_fields).forEach(searchField => {
                if (this.search_fields[ searchField ].search === true) {
                    searchObj[ searchField ] = this.search_fields[ searchField ].value;
                }
            });
            const searchRes = await searchEntity(searchObj);
            this.submitting = false;
            eventBus.$emit('CLOSE_MEMBER_SEARCH_DIALOG', searchRes.entities);
        }
    },
    computed: {
        search_field_selected() {
            return (
                this.search_fields.name.search ||
                this.search_fields.surname.search ||
                this.search_fields.id_number.search ||
                this.search_fields.date_of_birth.search ||
                this.search_fields.gender.search ||
                this.search_fields.marital_status.search ||
                this.search_fields.life_status.search
            )
        }
    },
    mounted() {
        
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="100%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Debit Order Details for Installment #{{ installment_item.installment_number }}</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip> -->
                <v-btn
                    icon
                    @click="closeViewDebitOrderDetailsDialog()"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <!-- <v-row style="background:#ffffff; padding-top:30px"> -->
                    <br>
                    <v-row
                        v-for="(collectionItem, index) in getSoftyCompCollections()"
                        :key="index"
                        style="background:#dec99a; padding-top:30px; border-radius:15px; margin-bottom: 20px;"
                    >
                        <v-col cols="12" sm="2">
                            <v-text-field
                                :value="collectionItem.action_date === null ? 'NONE' : collectionItem.action_date.split('T')[0]"
                                color="secondary"
                                label="Action Date"
                                autocomplete="some-random-text"
                                type="text"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-text-field
                                :value="collectionItem.frequency"
                                color="secondary"
                                label="Frequency"
                                autocomplete="some-random-text"
                                type="text"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                :value="deriveDebitOrderStatus(collectionItem)"
                                color="secondary"
                                label="Status"
                                autocomplete="some-random-text"
                                type="text"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                :value="collectionItem.captured_by.entity.name + ' ' + collectionItem.captured_by.entity.surname"
                                color="secondary"
                                label="Captured By"
                                autocomplete="some-random-text"
                                type="text"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-tooltip
                                bottom
                                v-if="(
                                    isCentralUnderwriterAdminUser ||
                                    isCollectionsManager
                                )">
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        style="margin-left: 20px;"
                                        color="red white--text"
                                        x-large
                                        @click="deactivateDebitOrder(collectionItem)"
                                        v-on="on"
                                        :disabled="(
                                            deriveDebitOrderStatus(collectionItem) === 'FAILED' ||
                                            deriveDebitOrderStatus(collectionItem) === 'COLLECTED' ||
                                            deriveDebitOrderStatus(collectionItem) === 'REVERSED' ||
                                            deriveDebitOrderStatus(collectionItem) === 'CANCELLED'
                                        )"
                                    >
                                        <v-progress-circular
                                            v-if="collectionItem.deactivating_debit_order"
                                            color="white"
                                            indeterminate
                                            width="9"
                                        >
                                        </v-progress-circular>
                                        <span v-else>
                                            <v-icon>mdi-close</v-icon> Deactivate<br> Debit Order
                                        </span>
                                    </v-btn>
                                </template>
                                <span>Deactivate Debit Order</span>

                            </v-tooltip>
                        </v-col>
                        <!-- <v-overlay :value="deactivating_debit_order">
                            <v-progress-circular
                                color="white"
                                indeterminate
                            >
                                <h3 class="text-center">Deep Refreshing</h3>
                            </v-progress-circular>
                        </v-overlay> -->
                    </v-row>
                </v-card-text>
                <br><br>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { eventBus } from "../../../main";
import { store } from "../../../../store";
import { BUSINESS_PARTNER_TYPES } from "../../../../config";

import { emitAlert, emitSuccess } from '../../../utils/api';
import { businessProcessLoan } from "../gql/mutations";

export default {
    components:{},
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        loan: {
            type: Object,
            required: false,
        },
        installment_item: {
            type: Object,
            required: false,
        },
    },
    data: () => ({
        submitting: false,
        server_response_obj: null,
        updated_schedule_item: null,

        next_action_date_menu: false,
        case_notes: null,
        case_next_action_date: null,
    }),

    computed: {
        isCentralUnderwriterAdminUser() {
            const result = store.state.currentUserAccount.roles.filter(role => {
                                                            return (
                                                                role.role.level.includes(BUSINESS_PARTNER_TYPES.CENTRAL_UNDERWRITER) &&
                                                                role.role.name.toLowerCase() ===  'admin - central underwriter'
                                                            );
                                                        }).length > 0;

            return result;
        },
        isCollectionsManager() {
            const result = store.state.currentUserAccount.roles.filter(role => {
                                                            return (
                                                                role.role.level.includes(BUSINESS_PARTNER_TYPES.CENTRAL_UNDERWRITER) &&
                                                                role.role.name.toLowerCase() ===  'collections manager'
                                                            );
                                                        }).length > 0;

            return result;
        },
    },
        
    methods: {
        getSoftyCompCollections() {
            const loanObj = this.installment_item.loan;

            const matchingSchedule = loanObj.installment_schedule.filter(scheduleItem => {
                return this.installment_item.installment_number === scheduleItem.installment_number;
            })[0];

            // console.log(matchingSchedule)

            if (matchingSchedule !== undefined) {
                return matchingSchedule.softy_comp_collections;
            } else {
                return [];
            }

            // const result = loanObj.installment_schedule.map(scheduleItem => {
            //     if (scheduleItem.installment_number === scheduleItem.installment_number) {
            //         return scheduleItem.softy_comp_collections;
            //     }


            //     const matchingSchedule = scheduleItem.loan.installment_schedule.filter(item => {
            //         return item.installment_number === scheduleItem.installment_number;
            //     })[0];

            //     if (matchingSchedule !== undefined) {
            //         return matchingSchedule.softy_comp_collections;
            //     } else {
            //         return [];
            //     }
            // });


            // const result = this.collectionObj.upcoming_loan_schedules.map(scheduleItem => {
            //     const matchingSchedule = scheduleItem.loan.installment_schedule.filter(item => {
            //         return item.installment_number === scheduleItem.installment_number;
            //     })[0];
            //     // console.log('HEY', matchingSchedule)
            //     if (matchingSchedule !== undefined) {
            //         scheduleItem.softy_comp_collections = matchingSchedule.softy_comp_collections;
            //     } else {
            //         scheduleItem.softy_comp_collections = []
            //     }
            //     return scheduleItem;
            // });
            // // console.log(result)
            // return result;
        },
        closeViewDebitOrderDetailsDialog() {
            eventBus.$emit('CLOSE_VIEW_SCHEDULE_DEBIT_ORDER_DETAILS_DIALOG', this.server_response_obj, this.updated_schedule_item);
        },
        deriveDebitOrderStatus(collectionItem) {
            let result = null;
            const now = new Date();
            const deadlineDate = new Date(this.installment_item.deadline_date.split('T')[0]);

            // Future-Dated
            if (
                deadlineDate > now &&
                collectionItem.local_status === 'ACTIVE'
            ) {
                result = 'NOT YET ACTIONED';
            }

            // Past and current
            else if (
                deadlineDate <= now &&
                collectionItem.local_status === 'ACTIVE'
            ) {
                result = 'TO BE CONFIRMED';
            }

            // Actual status
            else {
                result = collectionItem.local_status;
            }

            return result;
        },
        async deactivateDebitOrder(collectionItem) {
            collectionItem.deactivating_debit_order = true;

            try {
                const payloadObj = {
                    OP_TYPE: 'DEACTIVATE_DEBIT_ORDER',
                    _id: this.loan._id,

                    // Payload
                    installment_schedule_item_id: this.installment_item._id,
                    collection_item_id: collectionItem._id,

                };
                // if (payloadObj !== null) {
                //     console.log(payloadObj)
                //     return
                // }
                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcessLoan,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                collectionItem.deactivating_debit_order = false;
                if (res.data.businessProcessLoan.errors === true) {
                    emitAlert(res.data.businessProcessLoan.message);
                    return;
                }

                emitSuccess(res.data.businessProcessLoan.message);

                this.server_response_obj = res.data.businessProcessLoan.business_object;

                // Update installment schedule item
                const updatedInstallmentSchedule = this.server_response_obj.installment_schedule;
                this.updated_schedule_item = updatedInstallmentSchedule.filter(item => { return item._id.trim() === this.installment_item._id.trim() })[0];

                this.closeViewDebitOrderDetailsDialog();

                // Reset model
                this.server_response_obj = null;
                this.updated_schedule_item = null;
            } catch (error) {
                collectionItem.deactivating_debit_order = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessLoan.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
        }
    },
    mounted() {

    },
}
</script>
<style scoped>

</style>
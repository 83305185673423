<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Policy Search</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeMemberSearchDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <!-- Policy Number -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.policy_number.search"
                                color="success"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-text-field
                                v-model="search_fields.policy_number.value"
                                label="Product Number"
                                type="number"
                                color="secondary"
                                required
                                :disabled="!search_fields.policy_number.search"
                            ></v-text-field>
                        </v-col>

                        <!-- Policy Status -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.status.search"
                                color="success"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-select
                                v-model="search_fields.status.value"
                                :items="['ACTIVE', 'LAPSABLE', 'LAPSED', 'CLOSED']"
                                label="Policy Status"
                                color="secondary"
                                :disabled="!search_fields.status.search"
                                required
                            ></v-select>
                        </v-col>

                        <!-- Member Id Number -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.member_id_number.search"
                                color="success"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-text-field
                                v-model="search_fields.member_id_number.value"
                                label="Member Id Number"
                                color="secondary"
                                required
                                :disabled="!search_fields.member_id_number.search"
                            ></v-text-field>
                        </v-col>

                        <!-- Effective Date -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.effective_date.search"
                                color="success"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-menu
                                v-model="effective_date_menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="search_fields.effective_date.value"
                                    label="Effective Date"
                                    color="secondary"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :disabled="!search_fields.effective_date.search"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    color="secondary"
                                    v-model="search_fields.effective_date.value"
                                    @input="effective_date_menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>

                        <!-- Elected Payment Date -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.elected_payment_date.search"
                                color="success"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <!-- <v-select
                                v-model="search_fields.elected_payment_date.value"
                                :items="elected_payment_dates"
                                label="Elected Payment Date"
                                color="secondary"
                                :disabled="!search_fields.elected_payment_date.search"
                                required
                            ></v-select> -->
                            <v-text-field
                                v-model="search_fields.elected_payment_date.value"
                                label="Elected Payment Date"
                                color="secondary"
                                type="number"
                                required
                                :disabled="!search_fields.elected_payment_date.search"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Searching Policies
                        <v-progress-linear
                            indeterminate
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom v-if="search_field_selected">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                @click="submitSearchQuery()"
                            >
                                <v-icon large>search</v-icon>
                            </v-btn>
                        </template>
                        <span>Submit Search Query</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { eventBus } from '../../../main';
import { emitAlert } from '../../../utils/api';
import { getPolicies } from "../gql/queries";
export default {
    name: 'SearchMembersDialog',
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        effective_date_menu: false,
        search_fields: {
            policy_number: {
                search: false,
                value: null,
            },
            status: {
                search: false,
                value: null,
            },
            member_id_number: {
                search: false,
                value: null,
            },
            effective_date: {
                search: false,
                value: null,
            },
            elected_payment_date: {
                search: false,
                value: null,
            },
        },

        submitting: false
    }),
    methods: {
        closeMemberSearchDialog() {
            eventBus.$emit('CLOSE_POLICY_SEARCH_DIALOG', null);
        },
        async submitSearchQuery() {
            this.submitting = true;

            const searchObj = {};

            Object.keys(this.search_fields).forEach(searchField => {
                if (this.search_fields[ searchField ].search === true) {
                    searchObj[ searchField ] = this.search_fields[ searchField ].value;
                }
            });
            
            // const searchRes = await searchPolicy(searchObj);
            // this.submitting = false;

            try {
                const res = await this.$apollo
                                        .query({
                                            query: getPolicies,
                                            // fetchPolicy: 'network-only',
                                            fetchPolicy: 'no-cache',
                                            errorPolicy: 'all',
                                            variables: {
                                                objectType: 'POLICY',
                                                pagination: {
                                                    page: this.page,
                                                    limit: this.limit
                                                },
                                                searchInput: searchObj
                                            },
                                        });
                this.submitting = false;
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }

                // this.policies = this.policies.concat(res.data.getBusinessObject.business_objects);
                eventBus.$emit('CLOSE_POLICY_SEARCH_DIALOG', res.data.getBusinessObject.business_objects);
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }


            
        },
    },
    mounted() {
        
    },
    computed: {
        search_field_selected() {
            return (
                this.search_fields.policy_number.search ||
                this.search_fields.status.search ||
                this.search_fields.member_id_number.search ||
                this.search_fields.effective_date.search ||
                this.search_fields.elected_payment_date.search
            )
        }
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
<template>
    <div>
        <v-toolbar
            color="secondary"
            dark
            class="pa-0"
            height="50"
            extension-height="3"
        >
            <v-row>
                <!-- Grid Buttons -->
                <v-col sm="4" style="margin-top:10px">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="refreshRoles()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="selectedRole !== null">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="openEditRoleDetailsDialog()"
                            >
                                <v-icon>edit</v-icon>
                            </v-btn>
                        </template>
                        <span>Edit Record</span>
                    </v-tooltip>
                </v-col>

                <!-- Search Result Indicator -->
                <v-col sm="4">
                </v-col>

                <!-- Action Buttons -->
                <v-col sm="4" style="margin-top:10px">
                    <!-- <v-spacer></v-spacer> -->
                    <div class="text-right">
                        <v-tooltip bottom >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="openAddRoleDialog()"
                                    v-on="on"
                                >
                                    <v-icon medium>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add a User Role</span>
                        </v-tooltip>
                    </div>
                </v-col>
            </v-row>
        </v-toolbar>
        <multipane class="horizontal-panes" layout="horizontal" style="height: 100%" @paneResize="paneResizing">
            <div class="pane" :style="{ height: '50%', maxHeight: '50%'}">
                <v-data-table
                    fixed-header
                    :height="main_tab_height"
                    :headers="role_details_headers"
                    :items="user_roles"
                    item-key="_id"
                    class="elevation-1"
                    :single-select="true"
                    @click:row="selectMainTableRow"
                    item-class="secondary"
                    hide-default-footer
                    :loading="data_table_loading"
                    :disable-pagination="true"
                >
                    <!-- Level -->
                    <template v-slot:[`item.level`]="{ item }" >
                        <td>
                            {{item.level.join(',   ')}}
                        </td>
                    </template>
                </v-data-table>
            </div>
            <multipane-resizer></multipane-resizer>
            <div class="pane tab-nav" :style="{ height: '30%', maxHeight: '50%', minHeight: '30%' }">
                <v-tabs
                    v-model="sub_tabs"
                    color="transparent"
                    class="tab-nav"
                    hide-slider
                    height="35px"
                    grow
                >
                    <!-- Allowed Screens Sub Tab -->
                    <template>
                        <v-tab key="allowed_screens_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Allowed Screens
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>
                    <!-- Allowed Reports Sub Tab -->
                    <template>
                        <v-tab key="allowed_reports_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Allowed Reports
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>
                    <!-- Allowed Dashboards Sub Tab -->
                    <template>
                        <v-tab key="allowed_dashboards_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Allowed Dashboards
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>
                    <!-- Allowed Business Processes Sub Tab -->
                    <template>
                        <v-tab key="allowed_business_processes_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Allowed Business Processes
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>
                </v-tabs>
                <v-tabs-items v-model="sub_tabs">
                    <!-- Sub-Tab Toolbar -->
                    <v-toolbar
                        color="secondary"
                        dark
                        class="pa-0"
                        height="50"
                        extension-height="3"
                    >
                        
                        <!-- Buttons for the allowed_screens sub-tab -->
                        <v-tooltip bottom v-if="sub_tabs === 0 && selectedRole !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddAllowedScreensDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Allowed Screens</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 0  && selectedAllowedScreen !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="removeRoleAllowedScreen()"
                                    v-on="on"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete Allowed Screen</span>
                        </v-tooltip>

                        <!-- Buttons for the allowed_reports sub-tab -->
                        <v-tooltip bottom v-if="sub_tabs === 1 && selectedRole !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddAllowedReportsDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Allowed Reports</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 1  && selectedAllowedReport !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="removeRoleAllowedReport()"
                                    v-on="on"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete Allowed Report</span>
                        </v-tooltip>

                        <!-- Buttons for the allowed_dashboards sub-tab -->
                        <v-tooltip bottom v-if="sub_tabs === 2 && selectedRole !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddAllowedDashboardsDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Allowed Dashboards</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 2  && selectedAllowedDashboard !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="removeRoleAllowedDashboard()"
                                    v-on="on"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete Allowed Dashboard</span>
                        </v-tooltip>

                        <!-- Buttons for the allowed_dashboards sub-tab -->
                        <v-tooltip bottom v-if="sub_tabs === 3 && selectedRole !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddAllowedBusinessProcessesDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Allowed Dashboards</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 3  && selectedAllowedBusinessProcess !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="removeAllowedBusinessProcessesDialog()"
                                    v-on="on"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete Allowed Dashboard</span>
                        </v-tooltip>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <!-- allowed_screens Sub-Tab Content -->
                    <v-tab-item key="allowed_screens_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="allowed_screens_details_headers"
                            :items="role_allowed_screens"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectAllowedScreensTabRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Display Formated capture date -->
                            <template v-slot:[`item.created`]="{ item }">
                                <td>{{item.created.split('T')[0]}}</td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- allowed_reports Sub-Tab Content -->
                    <v-tab-item key="allowed_reports_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="allowed_reports_details_headers"
                            :items="role_allowed_reports"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectAllowedReportsTabRow"
                            class="elevation-1"
                            sort-by="report.report_number"
                            :sort-desc="false"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Display Formated capture date -->
                            <template v-slot:[`item.created`]="{ item }">
                                <td>{{item.created.split('T')[0]}}</td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- allowed_dashboards Sub-Tab Content -->
                    <v-tab-item key="allowed_dashboards_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="allowed_dashboards_details_headers"
                            :items="role_allowed_dashboards"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectAllowedDashboardsTabRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Display Formated capture date -->
                            <template v-slot:[`item.created`]="{ item }">
                                <td>{{item.created.split('T')[0]}}</td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- allowed_business_processes Sub-Tab Content -->
                    <v-tab-item key="allowed_business_processes_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="allowed_business_processes_details_headers"
                            :items="role_allowed_business_processes"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectAllowedBusinessProcessesTabRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Display Formated capture date -->
                            <template v-slot:[`item.created`]="{ item }">
                                <td>{{item.created.split('T')[0]}}</td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </multipane>
        <AddRoleDialog
            :dialog="open_onboarding_dialog"
        />
        <EditRoleDetailsDialog
            v-if="selectedRole !== null"
            :dialog="open_edit_role_dialog"
            :role_original="selectedRole"
            :role_copy="selectedRoleCopy"
        />
        <AddAllowedScreensDialog
            v-if="selectedRole !== null"
            :dialog="open_add_allowed_screens_dialog"
            :role_id="selectedRole._id"
        />
        <AddAllowedReportsDialog
            v-if="selectedRole !== null"
            :dialog="open_add_allowed_reports_dialog"
            :role_id="selectedRole._id"
        />
        <AddAllowedDashboardsDialog
            v-if="selectedRole !== null"
            :dialog="open_add_allowed_dashboards_dialog"
            :role_id="selectedRole._id"
        />
        <AddAllowedBusinessProcessesDialog
            v-if="selectedRole !== null"
            :dialog="open_add_allowed_business_processes_dialog"
            :role_id="selectedRole._id"
        />
    </div>
</template>
<script>
import { Multipane, MultipaneResizer } from 'vue-multipane';
import AddRoleDialog from './dialogs/add_role';
import EditRoleDetailsDialog from './dialogs/edit_role_details.vue';
import AddAllowedScreensDialog from './dialogs//add_role_allowed_screens';
import AddAllowedReportsDialog from './dialogs/add_role_allowed_reports';
import AddAllowedDashboardsDialog from './dialogs/add_role_allowed_dashboards';
import AddAllowedBusinessProcessesDialog from './dialogs/add_role_allowed_business_processes';

import { emitAlert, emitSuccess } from "../../utils/api";
import { eventBus } from "../../main";

import { getUserRoles } from "./gql/queries";
import { lookupProcessUserRole } from "./gql/mutations";

export default {
    components: {
        Multipane,
        MultipaneResizer,
        AddRoleDialog,
        EditRoleDetailsDialog,

        AddAllowedScreensDialog,
        AddAllowedReportsDialog,
        AddAllowedDashboardsDialog,
        AddAllowedBusinessProcessesDialog,
    },

    data: () => ({
        sub_tabs: null,
        main_tab_height: '250px',
        sub_tab_height:'180',

        // main data-table row
        selectedRole: null,
        selectedRow: null,
        selectedRoleCopy: {
            name:  null,
            description: null,
            level: []
        },

        // allowed_screens sub-tab data-table row
        selectedAllowedScreen: null,
        selectedAllowedScreenRow: null,

        selectedAllowedReport: null,
        selectedAllowedReportRow: null,

        selectedAllowedDashboard: null,
        selectedAllowedDashboardRow: null,

        selectedAllowedBusinessProcess: null,
        selectedAllowedBusinessProcessRow: null,
        

        role_details_headers: [
            { text: 'Role Name', value: 'name' },
            { text: 'Description', value: 'description' },
            { text: 'Level', value: 'level' },
        ],
        allowed_screens_details_headers: [
            { text: 'Screen', value: 'screen.name' },
            { text: 'Screen Category', value: 'screen.category' },
            { text: 'Created', value: 'created' },
        ],
        allowed_reports_details_headers: [
            { text: 'Report Number', value: 'report.report_number' },
            { text: 'Report', value: 'report.name' },
            { text: 'Report Category', value: 'report.group' },
            { text: 'Created', value: 'created' },
        ],
        allowed_dashboards_details_headers: [
            { text: 'Dashboard', value: 'dashboard.name' },
            { text: 'Dashboard Category', value: 'dashboard.group' },
            { text: 'Created', value: 'created' },
        ],
        allowed_business_processes_details_headers: [
            { text: 'Business Process', value: 'process.name' },
            { text: 'Process Category', value: 'process.group' },
            { text: 'Created', value: 'created' },
        ],
        
        // Table data models
        role_allowed_screens: [],
        role_allowed_reports: [],
        role_allowed_dashboards: [],
        role_allowed_business_processes: [],

        // Dialogs
        user_roles: [],
        open_onboarding_dialog: false,
        open_edit_role_dialog: false,
        open_add_allowed_screens_dialog: false,
        open_add_allowed_reports_dialog: false,
        open_add_allowed_dashboards_dialog: false,
        open_add_allowed_business_processes_dialog: false,
        
        data_table_loading: false,
    }),

    methods: {
        //-------------------- OPEN DIALOGS ---------------------//
        openAddRoleDialog() {
            this.open_onboarding_dialog = true;
        },
        openEditRoleDetailsDialog() {
            this.open_edit_role_dialog = true;
        },
        openAddAllowedScreensDialog() {
            this.open_add_allowed_screens_dialog = true;
        },
        openAddAllowedReportsDialog() {
            this.open_add_allowed_reports_dialog = true;
        },
        openAddAllowedDashboardsDialog() {
            this.open_add_allowed_dashboards_dialog = true;
        },
        openAddAllowedBusinessProcessesDialog() {
            this.open_add_allowed_business_processes_dialog = true;
        },

        //------------------- CLOSE DIALOGS---------------------//
        closeAddRoleDialog(new_role) {
            if (new_role !== null) {
                this.user_roles.push(new_role);
            }
            this.open_onboarding_dialog = false;
        },
        closeEditRoleDialog(new_role) {
            if (new_role !== null) {
                const obj_index = this.user_roles.map(obj => { return obj._id; }).indexOf(new_role._id)
                this.user_roles[obj_index] = new_role;

                 // Deep copy user_roles for refreshing main data table
                const temp_arr = Array.from(this.user_roles);
                this.user_roles = temp_arr;

                // Clear sub-tabs as the main row get automatically unselected
                this.role_allowed_screens = new_role.allowed_screens;
                this.role_allowed_reports = new_role.allowed_reports;
                this.role_allowed_dashboards = new_role.allowed_dashboards;
                this.role_allowed_business_processes = new_role.allowed_business_processes;

                this.selectedRole = new_role;
            }

            this.open_edit_role_dialog = false;
        },
        closeAddAllowedScreensDialog(new_role) {
            if (new_role !== null) {
                const obj_index = this.user_roles.map(obj => { return obj._id; }).indexOf(new_role._id)
                this.user_roles[obj_index] = new_role;

                 // Deep copy roles for refreshing main data table
                const temp_arr = Array.from(this.user_roles);
                this.user_roles = temp_arr;

                // Also update the allowed_screens sub-tab
                this.role_allowed_screens = new_role.allowed_screens;
                this.role_allowed_reports = new_role.allowed_reports;
                this.role_allowed_dashboards = new_role.allowed_dashboards;
                this.role_allowed_business_processes = new_role.allowed_business_processes;
            }

            this.open_add_allowed_screens_dialog = false;
        },
        closeAddAllowedReportsDialog(new_role) {
            if (new_role !== null) {
                const obj_index = this.user_roles.map(obj => { return obj._id; }).indexOf(new_role._id)
                this.user_roles[obj_index] = new_role;

                 // Deep copy roles for refreshing main data table
                const temp_arr = Array.from(this.user_roles);
                this.user_roles = temp_arr;

                // Also update the allowed_screens sub-tab
                this.role_allowed_screens = new_role.allowed_screens;
                this.role_allowed_reports = new_role.allowed_reports;
                this.role_allowed_dashboards = new_role.allowed_dashboards;
                this.role_allowed_business_processes = new_role.allowed_business_processes;
            }

            this.open_add_allowed_reports_dialog = false;
        },
        closeAddAllowedDashboardsDialog(new_role) {
            if (new_role !== null) {
                const obj_index = this.user_roles.map(obj => { return obj._id; }).indexOf(new_role._id)
                this.user_roles[obj_index] = new_role;

                 // Deep copy roles for refreshing main data table
                const temp_arr = Array.from(this.user_roles);
                this.user_roles = temp_arr;

                // Also update the allowed_screens sub-tab
                this.role_allowed_screens = new_role.allowed_screens;
                this.role_allowed_reports = new_role.allowed_reports;
                this.role_allowed_dashboards = new_role.allowed_dashboards;
                this.role_allowed_business_processes = new_role.allowed_business_processes;
            }

            this.open_add_allowed_dashboards_dialog = false;
        },
        closeAddAllowedBusinessProcessesDialog(new_role) {
            if (new_role !== null) {
                const obj_index = this.user_roles.map(obj => { return obj._id; }).indexOf(new_role._id)
                this.user_roles[obj_index] = new_role;

                 // Deep copy roles for refreshing main data table
                const temp_arr = Array.from(this.user_roles);
                this.user_roles = temp_arr;

                // Also update the allowed_screens sub-tab
                this.role_allowed_screens = new_role.allowed_screens;
                this.role_allowed_reports = new_role.allowed_reports;
                this.role_allowed_dashboards = new_role.allowed_dashboards;
                this.role_allowed_business_processes = new_role.allowed_business_processes;
            }

            this.open_add_allowed_business_processes_dialog = false;
        },
        unselectSubTabRows() {
            // Unselect allowed_screens sub-tab row
            this.selectedAllowedScreen = null;
            if (this.selectedAllowedScreenRow !== null) {
                this.selectedAllowedScreenRow.select(false);
            }

            // Unselect allowed_report sub-tab row
            this.selectedAllowedReport = null;
            if (this.selectedAllowedReportRow !== null) {
                this.selectedAllowedReportRow.select(false);
            }

            // Unselect allowed_dashboard sub-tab row
            this.selectedAllowedDashboard = null;
            if (this.selectedAllowedDashboardRow !== null) {
                this.selectedAllowedDashboardRow.select(false);
            }

            // Unselect allowed_business process sub-tab row
            this.selectedAllowedBusinessProcess = null;
            if (this.selectedAllowedBusinessProcessRow !== null) {
                this.selectedAllowedBusinessProcessRow.select(false);
            }
        },
        selectMainTableRow(item, item_metadata) {
            this.unselectSubTabRows();

            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                this.selectedRole = item;
                // Update role allowed_screens model
                this.role_allowed_screens = item.allowed_screens;
                this.role_allowed_reports = item.allowed_reports;
                this.role_allowed_dashboards = item.allowed_dashboards;
                this.role_allowed_business_processes = item.allowed_business_processes;

                // Set role copy
                this.selectedRoleCopy = Object.assign({}, this.selectedRole);
            } else {
                this.selectedRole = null;
                // Unighlight selected row
                item_metadata.select(false);

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                // Update role allowed_screens model
                this.role_allowed_screens = [];
                this.role_allowed_reports = [];
                this.role_allowed_dashboards = [];
                this.role_allowed_business_processes = [];

                // ----------------------UPDATE VISIBILITY OF ACTION BUTTONS -----------------------//
            }
        },
        selectAllowedScreensTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedAllowedScreen = item;
                this.selectedAllowedScreenRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedAllowedScreen = null;
                this.selectedAllowedScreenRow = null;
            }
        },
        selectAllowedReportsTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedAllowedReport = item;
                this.selectedAllowedReportRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedAllowedReport = null;
                this.selectedAllowedReportRow = null;
            }
        },
        selectAllowedDashboardsTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedAllowedDashboard = item;
                this.selectedAllowedDashboardRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedAllowedDashboard = null;
                this.selectedAllowedDashboardRow = null;
            }
        },
        selectAllowedBusinessProcessesTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedAllowedBusinessProcess = item;
                this.selectedAllowedBusinessProcessRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedAllowedBusinessProcess = null;
                this.selectedAllowedBusinessProcessRow = null;
            }
        },

        resetRoleEditForm(updatedRole) {
            let difference = updatedRole.level.filter(x => !this.selectedRole.level.includes(x))
                                    .concat(this.selectedRole.level.filter(x => !updatedRole.level.includes(x)));
            if (
                updatedRole.name !== this.selectedRole.name ||
                updatedRole.description !== this.selectedRole.description ||
                difference.length > 0
            ) {
                this.selectedRoleCopy = Object.assign({}, this.selectedRole);
            }
        },

        async removeRoleAllowedScreen() {
            this.data_table_loading = true;
            try {
                const res = await this.$apollo
                                            .mutate({
                                                mutation: lookupProcessUserRole,
                                                variables: {
                                                    input: {
                                                        OP_TYPE: 'REMOVE_ALLOWED_SCREEN',
                                                        _id: this.selectedAllowedScreen._id,

                                                        // Fields not needed (but placeholders)
                                                        name: '',
                                                        description: ''
                                                    }
                                                },
                                            });
                if (res.data.lookupProcessUserRole.errors === true) {
                    emitAlert(res.data.lookupProcessUserRole.message);
                    this.submitting = false;
                    return;
                }

                this.data_table_loading = false;
                emitSuccess(res.data.lookupProcessUserRole.message);

                // -------- UPDATE DATA-TABLE MODEL ----------
                this.selectedAllowedScreen = null;
                this.selectedAllowedScreenRow = null;

                // refresh main data table
                const obj_index = this.user_roles.map(obj => { return obj._id; }).indexOf(res.data.lookupProcessUserRole.business_object._id);
                this.user_roles[obj_index] = res.data.lookupProcessUserRole.business_object;

                // Deep copy user roles for refreshing main data table
                const temp_arr = Array.from(this.user_roles);
                this.user_roles = temp_arr;
                
                this.selectedRole = res.data.lookupProcessUserRole.business_object;
                this.role_allowed_screens = res.data.lookupProcessUserRole.business_object.allowed_screens;
                this.role_allowed_reports = res.data.lookupProcessUserRole.business_object.allowed_reports;
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.lookupProcessUserRole.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },

        async removeRoleAllowedReport() {
            this.data_table_loading = true;
            try {
                const res = await this.$apollo
                                            .mutate({
                                                mutation: lookupProcessUserRole,
                                                variables: {
                                                    input: {
                                                        OP_TYPE: 'REMOVE_ALLOWED_REPORT',
                                                        _id: this.selectedAllowedReport._id,

                                                        // Fields not needed (but placeholders)
                                                        name: '',
                                                        description: ''
                                                    }
                                                },
                                            });
                if (res.data.lookupProcessUserRole.errors === true) {
                    emitAlert(res.data.lookupProcessUserRole.message);
                    this.submitting = false;
                    return;
                }

                this.data_table_loading = false;
                emitSuccess(res.data.lookupProcessUserRole.message);

                // -------- UPDATE DATA-TABLE MODEL ----------
                this.selectedAllowedReport = null;
                this.selectedAllowedReportRow = null;

                // refresh main data table
                const obj_index = this.user_roles.map(obj => { return obj._id; }).indexOf(res.data.lookupProcessUserRole.business_object._id);
                this.user_roles[obj_index] = res.data.lookupProcessUserRole.business_object;

                // Deep copy user roles for refreshing main data table
                const temp_arr = Array.from(this.user_roles);
                this.user_roles = temp_arr;
                
                this.selectedRole = res.data.lookupProcessUserRole.business_object;
                this.role_allowed_screens = res.data.lookupProcessUserRole.business_object.allowed_screens;
                this.role_allowed_reports = res.data.lookupProcessUserRole.business_object.allowed_reports;
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.lookupProcessUserRole.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },

        async removeRoleAllowedDashboard() {
            this.data_table_loading = true;
            try {
                const res = await this.$apollo
                                            .mutate({
                                                mutation: lookupProcessUserRole,
                                                variables: {
                                                    input: {
                                                        OP_TYPE: 'REMOVE_ALLOWED_DASHBOARD',
                                                        _id: this.selectedAllowedDashboard._id,

                                                        // Fields not needed (but placeholders)
                                                        name: '',
                                                        description: ''
                                                    }
                                                },
                                            });
                if (res.data.lookupProcessUserRole.errors === true) {
                    emitAlert(res.data.lookupProcessUserRole.message);
                    this.submitting = false;
                    return;
                }

                this.data_table_loading = false;
                emitSuccess(res.data.lookupProcessUserRole.message);

                // -------- UPDATE DATA-TABLE MODEL ----------
                this.selectedAllowedDashboard = null;
                this.selectedAllowedDashboardRow = null;

                // refresh main data table
                const obj_index = this.user_roles.map(obj => { return obj._id; }).indexOf(res.data.lookupProcessUserRole.business_object._id);
                this.user_roles[obj_index] = res.data.lookupProcessUserRole.business_object;

                // Deep copy user roles for refreshing main data table
                const temp_arr = Array.from(this.user_roles);
                this.user_roles = temp_arr;
                
                this.selectedRole = res.data.lookupProcessUserRole.business_object;
                this.role_allowed_screens = res.data.lookupProcessUserRole.business_object.allowed_screens;
                this.role_allowed_reports = res.data.lookupProcessUserRole.business_object.allowed_reports;
                this.role_allowed_dashboards = res.data.lookupProcessUserRole.business_object.allowed_dashboards;
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.lookupProcessUserRole.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },

        async removeAllowedBusinessProcessesDialog() {
            this.data_table_loading = true;
            try {
                const res = await this.$apollo
                                            .mutate({
                                                mutation: lookupProcessUserRole,
                                                variables: {
                                                    input: {
                                                        OP_TYPE: 'REMOVE_ALLOWED_BUSINESS_PROCESS',
                                                        _id: this.selectedAllowedBusinessProcess._id,

                                                        // Fields not needed (but placeholders)
                                                        name: '',
                                                        description: ''
                                                    }
                                                },
                                            });
                if (res.data.lookupProcessUserRole.errors === true) {
                    emitAlert(res.data.lookupProcessUserRole.message);
                    this.submitting = false;
                    return;
                }

                this.data_table_loading = false;
                emitSuccess(res.data.lookupProcessUserRole.message);

                // -------- UPDATE DATA-TABLE MODEL ----------
                this.selectedAllowedBusinessProcess = null;
                this.selectedAllowedBusinessProcessRow = null;

                // refresh main data table
                const obj_index = this.user_roles.map(obj => { return obj._id; }).indexOf(res.data.lookupProcessUserRole.business_object._id);
                this.user_roles[obj_index] = res.data.lookupProcessUserRole.business_object;

                // Deep copy user roles for refreshing main data table
                const temp_arr = Array.from(this.user_roles);
                this.user_roles = temp_arr;
                
                this.selectedRole = res.data.lookupProcessUserRole.business_object;
                this.role_allowed_screens = res.data.lookupProcessUserRole.business_object.allowed_screens;
                this.role_allowed_reports = res.data.lookupProcessUserRole.business_object.allowed_reports;
                this.role_allowed_dashboards = res.data.lookupProcessUserRole.business_object.allowed_dashboards;
                this.role_allowed_business_processes = res.data.lookupProcessUserRole.business_object.allowed_business_processes;
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.lookupProcessUserRole.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
    
        async refreshRoles() {
            this.data_table_loading = true;
            this.user_roles = [];

            try {
                const res = await this.$apollo
                                    .query({
                                        query: getUserRoles,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'USER_ROLE',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }

                this.data_table_loading = false;
                this.user_roles = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        paneResizing(pane, container, size) {
            const size_int = parseInt(size);

            this.main_tab_height = size;
            const max_screen_height = window.innerHeight - 300;

            const sub_tab_percentage = 1 - (size_int/max_screen_height);
            this.sub_tab_height = sub_tab_percentage * max_screen_height;
        }
    },

    async mounted() {
        eventBus.$on('CLOSE_ADD_ROLE_DIALOG', this.closeAddRoleDialog);
        eventBus.$on('CLOSE_EDIT_ROLE_DIALOG', this.closeEditRoleDialog);
        eventBus.$on('CLOSE_ADD_ALLOWED_SCREENS_DIALOG', this.closeAddAllowedScreensDialog);
        eventBus.$on('CLOSE_ADD_ALLOWED_REPORTS_DIALOG', this.closeAddAllowedReportsDialog);
        eventBus.$on('CLOSE_ADD_ALLOWED_DASHBOARDS_DIALOG', this.closeAddAllowedDashboardsDialog);
        eventBus.$on('CLOSE_ADD_ALLOWED_BUSINESS_PROCESSES_DIALOG', this.closeAddAllowedBusinessProcessesDialog);

        eventBus.$on('RESET_ROLE_EDIT_FORM', this.resetRoleEditForm);

        /**
         * The following logic is for manually triggering a pane-divider movement
         * so as to get the windows to properly resize
         */
        this.paneResizing(null, null, 250);


        try {
            this.data_table_loading = true;
            const res = await this.$apollo
                                    .query({
                                        query: getUserRoles,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'USER_ROLE',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.data_table_loading = false;
                return;
            }

            this.data_table_loading = false;
            this.user_roles = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.data_table_loading = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
        }
    },

    watch: {
        
    }
}
</script>
<style scoped>
/* Overriding of textbox min height */
 .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
}
</style>
import gql from 'graphql-tag'

export const businessProcessProspectPolicy = gql`
    mutation businessProcessProspectPolicy($input: ProspectPolicyInput!) {
        businessProcessProspectPolicy(input: $input) {
            errors
            message
            business_object {
                ... on ProspectPolicy {
                    __typename
                    _id
                    prospect_number

                    total_cover_amount
                    premium

                    payment_date
                    scheme_level_payment
                    payment_information {
                        premium_payer {
                            payment_method
                            percentage_amount
                        }
                        scheme {
                            payment_method
                            percentage_amount
                        }
                    }

                    principal_member {
                        _id
                        # is_premium_payer
                        settlement_banking
                        gender
                        cover_amount
                        entity {
                            _id
                            name
                            surname
                            id_number
                            addresses {
                                _id
                                type
                                line1
                                line2
                                line3
                                line4
                                created
                                deleted
                                delete_date
                                delete_reason
                            }

                            contact_details {
                                __typename
                                _id
                                type
                                primary
                                details
                                created
                                deleted
                                delete_date
                                delete_reason
                            }

                            documents {
                                __typename
                                _id
                                type
                                mime_type
                                name
                                created
                                deleted
                                delete_date
                                delete_reason
                            }

                            bank_account_details {
                                __typename
                                _id
                                purpose
                                bank {
                                    __typename
                                    _id
                                    name
                                    universal_branch_code
                                }
                                account_type
                                account_number
                                created
                                deleted
                                delete_date
                                delete_reason
                            }
                        }
                    }

                    premium_payer {
                        _id
                        collection_banking
                        gender
                        entity {
                            _id
                            name
                            surname
                            id_number
                            addresses {
                                _id
                                type
                                line1
                                line2
                                line3
                                line4
                                created
                                deleted
                                delete_date
                                delete_reason
                            }

                            contact_details {
                                __typename
                                _id
                                type
                                primary
                                details
                                created
                                deleted
                                delete_date
                                delete_reason
                            }

                            documents {
                                __typename
                                _id
                                type
                                mime_type
                                name
                                created
                                deleted
                                delete_date
                                delete_reason
                            }

                            bank_account_details {
                                __typename
                                _id
                                purpose
                                bank {
                                    __typename
                                    _id
                                    name
                                    universal_branch_code
                                }
                                account_type
                                account_number
                                created
                                deleted
                                delete_date
                                delete_reason
                            }
                        }
                    }

                    dependents {
                        _id
                        spouse_to_principal
                        elected_dependent
                        gender
                        cover_amount
                        entity {
                            _id
                            name
                            surname
                            id_number
                            addresses {
                                _id
                                type
                                line1
                                line2
                                line3
                                line4
                                created
                                deleted
                                delete_date
                                delete_reason
                            }

                            contact_details {
                                __typename
                                _id
                                type
                                primary
                                details
                                created
                                deleted
                                delete_date
                                delete_reason
                            }

                            documents {
                                __typename
                                _id
                                type
                                mime_type
                                name
                                created
                                deleted
                                delete_date
                                delete_reason
                            }

                            bank_account_details {
                                __typename
                                _id
                                purpose
                                bank {
                                    __typename
                                    _id
                                    name
                                    universal_branch_code
                                }
                                account_type
                                account_number
                                created
                                deleted
                                delete_date
                                delete_reason
                            }
                        }
                    }

                    scheme {
                        _id
                        name
                    }
                    agent {
                        _id
                        agent_number
                        entity {
                            _id
                            name
                            surname
                            id_number
                        }
                    }
                    product {
                        _id
                        name
                        markup_fixed
                        markup_percentage

                        primary_product {
                            _id
                            name
                            underwriter_premium
                            broker_commission
                            broker_markup
                            active
                            
                            payout_structure {
                                _id
                                payout_amount
                                category {
                                    _id
                                    name
                                    membership_category
                                    start_age
                                    end_age
                                }
                            }
                        }
                    }
                    status
                    commission_status
                    created
                    captured_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }

                    documents {
                        _id
                        type
                        mime_type
                        name

                        start_date
                        end_date

                        captured_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }
                }
            }
        }
    }
`

export const businessValidatePolicy = gql`
    mutation businessValidatePolicy($policy_id: String!) {
        businessValidatePolicy(policy_id: $policy_id) {
            policy {
                _id
                policy_number
            }
            overall_status
            validation_results {
                id
                rule_id
                rule_name
                policy_param_value
                min
                max
                valid
                waived
                fail_reason
            }
        }
    }
`
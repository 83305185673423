<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Edit Basic Product Details</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeEditProductDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <div v-if="product_copy !== null && product_copy !== undefined">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-model="product_copy.name"
                                    color="secondary"
                                    label="Product Name"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" >
                                <v-select
                                    v-model="product_copy.primary_product"
                                    :items="primary_product_select_items"
                                    color="secondary"
                                    item-text="name"
                                    item-value="_id"
                                    label="Primary Product"
                                    return-object
                                    required
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="3" v-if="product_copy.primary_product !== null">
                                <v-text-field
                                    :value="product_copy.primary_product.underwriter_premium + product_copy.primary_product.broker_markup"
                                    prefix="ZAR"
                                    color="secondary"
                                    label="Underwriter Premium"
                                    type="number"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" v-if="product_copy.primary_product !== null">
                                <v-text-field
                                    v-model="product_copy.markup_percentage"
                                    color="secondary"
                                    label="Percentage Markup"
                                    type="number"
                                    prefix="%"
                                    hint="If your markup is percentage based"
                                    persistent-hint
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" v-if="product_copy.primary_product !== null">
                                <v-text-field
                                    v-model="product_copy.markup_fixed"
                                    prefix="ZAR"
                                    color="secondary"
                                    label="Fixed Markup"
                                    type="number"
                                    hint="If your markup is fixed"
                                    persistent-hint
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" v-if="product_copy.primary_product !== null">
                                <v-text-field
                                    :value="derived_final_price"
                                    prefix="ZAR"
                                    color="secondary"
                                    label="Final Price"
                                    type="number"
                                    hint="The price you will be selling at"
                                    persistent-hint
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
                <!-- Submission Results Section -->
                <div v-if="submitting">
                    <v-card-text class="text-center">
                        <v-progress-linear
                            color="green"
                            class="mb-0"
                            height="10"
                            indeterminate
                            :active="submitting"
                        ></v-progress-linear>
                    </v-card-text>
                    <v-list flat>
                        <v-subheader>Saving Product Info</v-subheader>
                        <v-list-item-group color="primary">
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon>done</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Submiting edited product features</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </div>

                <!-- Lower Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        large
                        color="success"
                        :disabled="submitProductDetailsBtnDisabled()"
                        @click="submitProductDetails()"
                    >
                        <v-icon large>save</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../../../utils/api";
import { eventBus } from "../../../../../main";

import { getProducts } from "../../product_primary/gql/queries";
import { productProcessInsuranceProductSecondary } from "../gql/mutations";

export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        product: {
            type: Object,
            default: () => {
                return {
                    _id: null,
                    name: null,
                    underwriter: null,
                    premium: null,
                    underwriter_premium: null,
                    broker_commission: null,
                    features: {
                        principal_members_per_scheme: null,
                        dependents_size: null,
                        principal_member_max_age: null,
                        principal_member_spouse_max_age: null,
                        dependent_max_age: null,
                        general_waiting_period: null,
                        suicide_waiting_period: null,
                        accidental_cover: null,
                        repatriation: null,
                    },
                }
            }
        },
        product_copy: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data: () => ({
        server_resp_obj: null,
        submitting: false,
        primary_product_select_items: []
    }),
    computed: {
        derived_final_price() {
            if (this.product_copy.primary_product !== null) {
                const afrosurance_premium = this.product_copy.primary_product.underwriter_premium + 
                                                this.product_copy.primary_product.broker_markup;

                let markup_percentage_val = 0;
                if (!isNaN(parseFloat(this.product_copy.markup_percentage))) {
                    markup_percentage_val = parseFloat(this.product_copy.markup_percentage);
                }
                const percentage_markup = afrosurance_premium * (markup_percentage_val/100);

                let fixed_markup = 0;
                if (!isNaN(parseFloat(this.product_copy.markup_fixed))) {
                    fixed_markup = parseFloat(this.product_copy.markup_fixed);
                }

                const final_selling_price = afrosurance_premium + percentage_markup + fixed_markup;
                return final_selling_price;
            }
            return 0;
        }
    },
    methods: {
        closeEditProductDialog() {
            eventBus.$emit('CLOSE_EDIT_SECONDARY_PRODUCT_DETAILS_DIALOG', this.server_resp_obj);
        },
        resetForm() {
            this.submitting = false;
            this.server_resp_obj = null;
            eventBus.$emit('RESET_SECONDARY_PRODUCT_EDIT_FORM', this.product_copy);
        },

        submitProductDetailsBtnDisabled() {
            if (this.product !== null && this.product_copy !== null) {
                if (
                    this.product_copy.name.trim() === this.product.name.trim() &&
                    this.product_copy.primary_product._id === this.product.primary_product._id &&
                    parseFloat(this.product_copy.markup_fixed) === parseFloat(this.product.markup_fixed) &&
                    parseFloat(this.product_copy.markup_percentage) === parseFloat(this.product.markup_percentage)
                ) {
                    return true;
                }
            }
            
            return false;
        },

        async submitProductDetails() {

            this.submitting = true;

            try {
                // Payload with old values
                const payload = {
                    name: this.product_copy.name.trim(),
                    primary_product: this.product_copy.primary_product._id,
                    markup_fixed: parseFloat(this.product_copy.markup_fixed),
                    markup_percentage: parseFloat(this.product_copy.markup_percentage)
                };

                // const res = await editProductDetails(config.PRODUCT_TYPES.SECONDARY, this.product._id, payload);
                // if (res.errors === true) {
                //     emitAlert(res.message);
                //     this.submitting = false;
                //     return;
                // }

                const payloadObj = {
                    OP_TYPE: 'UPDATE',
                    _id: this.product._id,

                    ...payload
                }

                const res = await this.$apollo
                                            .mutate({
                                                mutation: productProcessInsuranceProductSecondary,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.productProcessInsuranceProductSecondary.errors === true) {
                    emitAlert(res.data.productProcessInsuranceProductSecondary.message);
                    this.submitting = false;
                    return;
                }

                this.server_resp_obj = res.data.productProcessInsuranceProductSecondary.business_object;
                this.closeEditProductDialog();
                emitSuccess(res.data.productProcessInsuranceProductSecondary.message);

                // Reset fields
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.productProcessInsuranceProductSecondary.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        }
    },
    async mounted() {
        // Load lookup data
        try {
            // Load Primary Products
            const res = await this.$apollo
                                    .query({
                                        query: getProducts,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'INSURANCE_PRODUCT_PRIMARY',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.submitting = false;
                return;
            }

            this.primary_product_select_items = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.submitting = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
            
            return;
        }
    },
    watch: {
        
    }
}
</script>
<style scoped>
.error-multi-line {
  white-space: pre-line;
}

</style>
import { store } from '../../store';
import { eventBus } from '../main';

export default {
    methods: {
        getAccessToken() {
            // if (this.$cookies.isKey('token')) {
            //     return this.$cookies.get('token');
            // }
            const token = store.state.token;
            if (token === null) {
                this.logout();
                // return false;
            }
            
            return token;
            
        },
        logout() {
            // Clear token
            this.clearAccessToken();

            // Clear Tabs
            store.commit('update_tabs', [
                {
                    id: 1,
                    path: '/',
                    title: 'Dashboard',
                    name: 'Dash1'
                },
            ]);

            // Clear current user
            store.commit('update_user', null);
            
            // Clear reports map
            store.commit('update_report_download_progress_map', {});
            store.commit('update_business_process_progress_map', {});

            if (window.location.pathname !== '/auth/') {
                window.location.replace('/auth/');
            }
        },
        clearAccessToken() {
            // if (this.$cookies.isKey('token')) {
            //     this.$cookies.remove('token');
            // }
            store.commit('update_token', null);
            store.commit('update_context_list', []);
        },
    },
    created() {
        this.getAccessToken();
    },
    mounted() {
        eventBus.$on('checkAuth', () => {
            this.getAccessToken();
        });

        eventBus.$on('userLogout', () => {
            this.logout();
        });
    },
};

<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Delete Member Contact Details</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeDeleteMemberContactDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <div v-if="contact !== null">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-if="contact !== null"
                                    v-model="contact.type"
                                    label="Contact Type"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-if="contact !== null"
                                    :value="contact.created.split('T')[0]"
                                    label="Date Captured"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-if="contact !== null"
                                    :value="contact.primary ? 'Yes' : 'No'"
                                    label="Primary Contact"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-if="contact !== null"
                                    :value="contact.details"
                                    label="Contact Details"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12">
                                <v-textarea
                                    color="secondary"
                                    name="Delete Reason"
                                    filled
                                    label="Delete Reason"
                                    auto-grow
                                    v-model="delete_reason"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </div>
                    <v-card-text class="text-center" v-if="submitting">
                        Deleting Contact
                        <v-progress-linear
                            indeterminate
                            :active="submitting"
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submitBtnDisabled()"
                                @click="submitDeleteAction()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Submit Delete Action</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";
import { entityProcessEntity } from "../gql/mutations";
export default {
    components: {},
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        entity: {
            type: Object,
            required: false,
            default: () => {
                return { _id: null }
            }
        },
        contact: {
            type: Object,
            required: false,
            default: () => {
                return { _id: null }
            }
        }
    },
    data: () => ({
        server_response_obj: null,
        submitting: false,
        delete_reason: null
    }),
    methods: {
        closeDeleteMemberContactDialog() {
            eventBus.$emit('CLOSE_DELETE_MEMBER_CONTACT_DIALOG', this.server_response_obj);
        },
        resetForm() {
            this.delete_reason = null;
            this.server_response_obj = null;
        },

        submitBtnDisabled() {
            if (this.delete_reason === null || this.delete_reason === undefined) {
                return true;
            }

            if (typeof(this.delete_reason) === 'string') {
                if (this.delete_reason.trim().length === 0) {
                    this.delete_reason = null;
                    return true;
                }
            }
            return false;
        },
        
        async submitDeleteAction() {
            this.submitting = true;

            try {
                const payload = {
                    OP_TYPE: 'REMOVE_CONTACT',
                    _id: this.entity._id,
                    contact_details: [
                        {
                            _id: this.contact._id,
                            delete_reason: this.delete_reason,

                            // Placeholder fields
                            type: '',
                        }
                    ]
                };
                
                const deleteResponse = await this.$apollo
                                        .mutate({
                                            mutation: entityProcessEntity,
                                            variables: {
                                                input: payload
                                            },
                                        });
                if (deleteResponse.data.entityProcessEntity.errors === true) {
                    emitAlert(deleteResponse.data.entityProcessEntity.message);
                    this.submitting = false;
                    return;
                }

                // Sanitize version history
                deleteResponse.data.entityProcessEntity.business_object.version_history.changes.forEach(change => {
                    change.old = JSON.parse(change.old);
                    change.new = JSON.parse(change.new);
                });

                this.submitting = false;
                this.server_response_obj = deleteResponse.data.entityProcessEntity.business_object;

                this.closeDeleteMemberContactDialog();
                emitSuccess(deleteResponse.data.entityProcessEntity.message);

                // Reset fields
                this.server_response_obj = null;
                this.submitting = false;
                
                // reset form
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.entityProcessEntity.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
            
        }
    },
    mounted() {
        
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="85%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Approve Pending Loan</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeActivateLoanDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card v-if="loan !== null && loan !== undefined">
                <v-expansion-panels focusable :value="1">
                    <v-expansion-panel style="background: #f6c20a8f">
                        <v-expansion-panel-header>
                            <h3>Loan Details</h3><br>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row
                                style="background:#ffffff; padding-top:30px"
                            >
                                <v-col cols="12" sm="2" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="loan.quote_number"
                                        prefix="L"
                                        label="Quote Number"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2" style="padding-top: 0px">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="loan.created.split('T')[0]"
                                        label="Date Captured"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" style="padding-top: 0px">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        :value="loan.entity.name + ' ' + loan.entity.surname + ' (' + loan.entity.id_number + ')'"
                                        label="Loan Entity"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2" style="padding-top: 0px">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="loan.status"
                                        label="Status"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <div style="background:white; height: 10px; width: 100%"></div>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel style="background: #f6c20a8f">
                        <v-expansion-panel-header>
                            <h3>Loan Parameters</h3><br>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row
                                style="background:#ffffff; padding-top:30px"
                            >
                                <v-col cols="12" sm="3" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="getLoanType"
                                        label="Loan Type"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        :value="loan.pricing_input.principal_amount"
                                        label="Principal Amount"
                                        prefix="ZAR"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        :value="loan.pricing_input.loan_term"
                                        label="Loan Term"
                                        suffix="MONTHS"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <div style="background:white; height: 10px; width: 100%"></div>
                            </v-row>

                            <v-row style="background:#ffffff;">
                                <v-col cols="12" sm="2" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        :value="loan.pricing_input.fees_config.initiation_fee_paid_at_inception === true ? 'YES' : 'NO'"
                                        label="Initiation Fees Paid At Inception"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="initiationFeeAmount"
                                        label="Initiation Fees"
                                        prefix="ZAR"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="loan.pricing_input.fees_config.monthly_admin_fee"
                                        label="Monthly Admin Fees"
                                        prefix="ZAR"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row style="background:#ffffff;">
                                <v-col cols="12" sm="2" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        :value="loan.pricing_input.interest_config.annual_interest_rate"
                                        label="Annual Interest Rate"
                                        prefix="%"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <h4 style="color: red">NB: Capture the loan effective date below</h4>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-menu
                                v-model="effective_date_menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="effective_date"
                                    color="secondary"
                                    label="Effective Date"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    color="secondary"
                                    v-model="effective_date"
                                    :min="new Date().toISOString().substr(0, 10)"
                                    :allowed-dates="allowedEffectiveDates"
                                    @input="effective_date_menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting to the server
                        <v-progress-linear
                            indeterminate
                            :active="submitting"
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                class="mx-2"
                                v-on="on"
                                :disabled="submitBtnDisabled()"
                                @click="activateLoanQuote()"
                            >
                                <v-icon large>mdi-checkbox-marked-circle-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Activate Loan</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { changeLoanStatus, emitAlert, emitSuccess } from "../../../../../../utils/api";
import { eventBus } from "../../../../../../main";
import converter from "../../../../../../mixins/converter";
import { LOAN_STATUS_ACTIONS, LOAN_QUOTE_STATUS_ACTIONS } from '../../../../../../../config';
import { businessProcessLoanQuote } from '../../../gql/mutations';

export default {
    mixins: [converter],
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        loan: {
            type: Object,
            default: null
        },
    },
    data: () => ({
        submitting: false,
        server_response_obj: null,

        effective_date_menu: false,
        effective_date: null,
    }),
    methods: {
        allowedEffectiveDates(val) {
            const datePortion = parseInt(val.split('-')[2]);
            return (
                datePortion !== 28 &&
                datePortion !== 29 &&
                datePortion !== 30 &&
                datePortion !== 31
            )
        },
        closeActivateLoanDialog() {
            eventBus.$emit('CLOSE_ACTIVATE_PENDING_LOAN_DIALOG', this.server_response_obj);
        },
        resetForm() {
            this.submitting = false;
            this.server_response_obj = null;
            this.effective_date = null;
        },
        submitBtnDisabled() {
            if (
                this.effective_date === null ||
                this.effective_date === undefined
            ) {
                return true;
            }

            return false;
        },
        async activateLoan() {
            if (
                this.effective_date === null ||
                this.effective_date === undefined
            ) {
                emitAlert('Please capture the effective date');
                return;
            }

            try {
                const loan_id = this.loan._id;
                this.submitting = true;

                const payloadObj = {
                    action_code: LOAN_STATUS_ACTIONS.PENDING_PAYOUT_TO_ACTIVE,
                    effective_date: this.effective_date,
                };
                const res = await changeLoanStatus(loan_id, payloadObj);
                this.submitting = false;

                if (res.errors === true) {
                    emitAlert(res.message);
                    return;
                }

                emitSuccess(res.message);

                this.server_response_obj = res.loan;
                this.closeActivateLoanDialog();
                
                // Reset form
                this.resetForm();
                return;
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
        },
        async activateLoanQuote() {
            this.submitting = true;

            try {
                const payloadObj = {
                    OP_TYPE: 'STATUS',
                    _id: this.loan._id,

                    action_code: LOAN_QUOTE_STATUS_ACTIONS.PENDING_APPROVAL_TO_APPROVED,
                    effective_date: this.effective_date
                };

                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcessLoanQuote,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                this.submitting = false;
                if (res.data.businessProcessLoanQuote.errors === true) {
                    emitAlert(res.data.businessProcessLoanQuote.message);
                    return;
                }

                emitSuccess(res.data.businessProcessLoanQuote.message);

                this.server_response_obj = res.data.businessProcessLoanQuote.business_object;
                this.closeActivateLoanDialog();
                
                // Reset form
                this.resetForm();
                return;
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessLoanQuote.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
        },
    },
    computed: {
        getLoanType() {
            switch(this.loan.pricing_input.loan_type) {
                case 1:
                    return 'Unsecured Credit';
                case 2:
                    return 'Asset Financing';
                case 3:
                    return 'Revolving Credit';
                case 4:
                    return 'Developmental Credit';
                case 5:
                    return 'Mortage Agreement';
                default:
                    return 'UNKNOWN'
            }
        },
        initiationFeeAmount() {
            let initFeeAmount = (
                (parseFloat(this.loan.pricing_input.fees_config.initiation_fee_principal_percentage) / 100) * 
                (parseFloat(this.loan.pricing_input.principal_amount))
            );

            if (isNaN(initFeeAmount)) {
                initFeeAmount = 0;
            }

            // Round to 2 decimal places
            initFeeAmount = Math.round( ( initFeeAmount + Number.EPSILON ) * 100 ) / 100;

            return initFeeAmount;
        },
    },
    async mounted() {}
}
</script>
<style scoped>

</style>
<template>
    <div>
        <v-toolbar
            color="secondary"
            dark
            class="pa-0"
            height="50"
            extension-height="3"
        >
            <v-row>
                <!-- Grid Buttons -->
                <v-col sm="4" style="margin-top:10px">
                    <v-tooltip bottom v-if="SEARCH_MODE === false">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                @click="loadMoreData()"
                                v-on="on"
                            >
                                <v-icon>cloud_download</v-icon>
                            </v-btn>
                        </template>
                        <span>Load More Data</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="openSearchLoanDialog()"
                            >
                                <v-icon>search</v-icon>
                            </v-btn>
                        </template>
                        <span>Search for Loan Quotes</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="SEARCH_MODE === false">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="refreshLoans()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh</span>
                    </v-tooltip>

                    <!-- <v-tooltip bottom v-if="selectedLoan !== null && selectedLoan.status === LOAN_QUOTE_STATUSES.DRAFT">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="red white--text"
                                small
                                v-on="on"
                                @click="deleteDraftedLoan()"
                            >
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                        <span>Delete Drafted Loan</span>
                    </v-tooltip> -->
                </v-col>

                <!-- Search Result Indicator -->
                <v-col sm="2">
                    <v-card-actions v-if="SEARCH_MODE === true">
                        <h3>Search Results</h3>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="clearSearchResults()"
                                    v-on="on"
                                >
                                    <v-icon small>search</v-icon>
                                    <v-icon small>close</v-icon>
                                </v-btn>
                            </template>
                            <span>Clear Search Results</span>
                        </v-tooltip>
                    </v-card-actions>
                </v-col>

                <!-- Action Buttons -->
                <v-col sm="6" style="margin-top:10px">
                    <!-- <v-spacer></v-spacer> -->
                    <div class="text-right">
                        
                        <!-------------------------------------------------------------------------------->
                        <!----------------------------------LIFE CYCLE BUTTONS---------------------------->
                        <!-------------------------------------------------------------------------------->


                        <!-- VIEW SYSTEM APPROVAL RESULTS -->
                        <!-- <v-tooltip
                            bottom
                            v-if="(
                                selectedLoan !== null &&
                                selectedLoan.system_approval_results.approval_method !== null
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="purple white--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="openViewSystemApprovalResultsDialog()"
                                >
                                    View System Approval Results
                                </v-btn>
                            </template>
                            <span>View System Approval Results</span>
                        </v-tooltip> -->

                        <!-- PROCESS BANK STATEMENT -->
                        <v-tooltip
                            bottom
                            v-if="(
                                selectedLoan !== null &&
                                selectedLoan.status === LOAN_QUOTE_STATUSES.DRAFT &&
                                selectedLoan.bank_statement_status !== 'PROCESSED'
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="black white--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="processBankStatement()"
                                >
                                    Process Bank Statement
                                </v-btn>
                            </template>
                            <span>Process Bank Statement</span>
                        </v-tooltip>

                        <!-- VALIDATE DRAFTED LOAN -->
                        <v-tooltip
                            bottom
                            v-if="(
                                selectedLoan !== null &&
                                selectedLoan.status === LOAN_QUOTE_STATUSES.DRAFT /*&&
                                selectedLoan.bank_statement_status !== 'PROCESSED' &&
                                (
                                    selectedLoan.system_approval_results.approval_method === 'AUTO-APPROVE' ||
                                    selectedLoan.system_approval_results.approval_method === 'MANUAL' ||
                                    selectedLoan.system_approval_results.approval_method === null
                                )*/
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="white black--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="openValidateDraftedLoanDialog()"
                                >
                                    <v-icon medium>check</v-icon>
                                </v-btn>
                            </template>
                            <span>Validate Drafted Loan</span>
                        </v-tooltip>

                        <!-- PENDING LOAN - ACTIVATE -->
                        <v-tooltip
                            v-if="(
                                isCentralUnderwriterAdminUser &&
                                selectedLoan !== null &&
                                selectedLoan.status === LOAN_QUOTE_STATUSES.PENDING_APPROVAL
                            )"
                            bottom
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="success white--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="openActivatePendingLoanDialog()"
                                >
                                    <v-icon medium>mdi-checkbox-marked-circle-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Activate Pending Loan</span>
                        </v-tooltip>
                        <!-- PENDING LOAN - DECLINE -->
                        <v-tooltip
                            v-if="(
                                isCentralUnderwriterAdminUser &&
                                selectedLoan !== null &&
                                selectedLoan.status === LOAN_QUOTE_STATUSES.PENDING_APPROVAL
                            )"
                            bottom
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="red white--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="openDeclinePendingLoanDialog()"
                                >
                                    <v-icon medium>mdi-close-circle-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Decline Pending Loan Application</span>
                        </v-tooltip>

                        <v-tooltip
                            bottom
                            v-if="(
                                selectedLoan !== null &&
                                selectedLoan.status === LOAN_QUOTE_STATUSES.DRAFT &&
                                (
                                    selectedLoan.system_approval_results.approval_method === 'AUTO-APPROVE' ||
                                    selectedLoan.system_approval_results.approval_method === 'MANUAL' ||
                                    selectedLoan.system_approval_results.approval_method === null
                                )
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="red white--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="openCaptureSignatureDialog()"
                                >
                                    <v-icon medium>mdi-fountain-pen-tip</v-icon>
                                </v-btn>
                            </template>
                            <span>Capture Client Signature</span>
                        </v-tooltip>

                        <!-- GENERATE POLICY APPLICATION FORM -->
                        <v-tooltip
                            bottom
                            v-if="(
                                selectedLoan !== null &&
                                selectedLoan.status === LOAN_QUOTE_STATUSES.DRAFT &&
                                (
                                    selectedLoan.system_approval_results.approval_method === 'AUTO-APPROVE' ||
                                    selectedLoan.system_approval_results.approval_method === 'MANUAL' ||
                                    selectedLoan.system_approval_results.approval_method === null
                                )
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="primary white--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="openViewPolicyCertificateDialog(selectedLoan._id)"
                                >
                                    <v-icon medium>mdi-file-document</v-icon>
                                </v-btn>
                            </template>
                            <span>Generate Application Form</span>
                        </v-tooltip>

                        <!-- ADD NEW LOAN -->
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="openAddLoanDialog()"
                                    v-on="on"
                                >
                                    <v-icon medium>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Onboard a Loan</span>
                        </v-tooltip>
                    </div>
                </v-col>
            </v-row>
        </v-toolbar>
        <multipane class="horizontal-panes" layout="horizontal" style="height: 100%" @paneResize="paneResizing">
            <div class="pane" :style="{ height: '50%', maxHeight: '50%'}">
                <v-data-table
                    fixed-header
                    :height="main_tab_height"
                    :headers="loan_details_headers"
                    :items="loans"
                    item-key="quote_number"
                    class="elevation-1"
                    :single-select="true"
                    @click:row="selectMainTableRow"
                    item-class="secondary"
                    hide-default-footer
                    :loading="data_table_loading"
                    :disable-pagination="true"
                >
                    <!-- Display Formated quote number -->
                    <template v-slot:item.quote_number="{ item }">
                        <td>L{{item.quote_number}}</td>
                    </template>
                    <!-- Display Formated monthly income -->
                    <template v-slot:item.entity_monthly_income="{ item }">
                        <td>R{{item.entity_monthly_income}}</td>
                    </template>
                    <!-- Display Formated capture date -->
                    <template v-slot:item.created="{ item }">
                        <td>{{item.created.split('T')[0]}}</td>
                    </template>
                    <!-- Display Formated amount -->
                    <template v-slot:item.amount="{ item }">
                        <td>R {{formatNumber(item.amount) }}</td>
                    </template>
                    <!-- Display Formated premium -->
                    <template v-slot:item.premium="{ item }">
                        <td>R {{item.premium }}</td>
                    </template>
                    <!-- Display Formated entity -->
                    <template v-slot:item.entity="{ item }">
                        <td>
                            {{
                                item.entity !== null && item.entity !== undefined ? 
                                item.entity.name + ' ' + item.entity.surname + ' (' + item.entity.id_number + ')':
                                ''
                            }}
                        </td>
                    </template>
                    <!-- Display Formated agent -->
                    <template v-slot:item.agent="{ item }">
                        <td>
                            {{
                                item.agent !== null && item.agent !== undefined ? 
                                item.agent.entity.name + ' ' + item.agent.entity.surname + ' (' + item.agent.agent_number + ')':
                                ''
                            }}
                        </td>
                    </template>
                    <!-- Display Formated scheme -->
                    <template v-slot:item.scheme="{ item }">
                        <td>
                            {{
                                item.scheme !== null && item.scheme !== undefined ? 
                                item.scheme.name + ' (' + item.scheme.scheme_number + ')':
                                ''
                            }}
                        </td>
                    </template>
                    <!-- Display Formated principal Amount -->
                    <template v-slot:item.pricing_input.principal_amount="{ item }">
                        <td>
                            {{
                                item.pricing_input.principal_amount !== null && item.pricing_input.principal_amount !== undefined ? 
                                'R '+item.pricing_input.principal_amount :
                                ''
                            }}
                        </td>
                    </template>
                    <!-- Display Formated Loan Term -->
                    <template v-slot:item.pricing_input.loan_term="{ item }">
                        <td>
                            {{
                                item.pricing_input.loan_term !== null && item.pricing_input.loan_term !== undefined ? 
                                item.pricing_input.loan_term + ' ' + item.pricing_input.loan_term_units :
                                ''
                            }}
                        </td>
                    </template>
                    <!-- Display Formated Loan Type -->
                    <template v-slot:item.pricing_input.loan_type="{ item }">
                        <td v-if="item.pricing_input.loan_type === 1">Unsecured Credit</td>
                        <td v-else-if="item.pricing_input.loan_type === 2">Asset Financing</td>
                        <td v-else-if="item.pricing_input.loan_type === 3">Revolving Credit</td>
                        <td v-else-if="item.pricing_input.loan_type === 4">Developmental Credit</td>
                        <td v-else-if="item.pricing_input.loan_type === 5">Mortage Agreement</td>
                        <td v-else>Unknown</td>
                    </template>
                    <template v-slot:item.pricing_input.specialized_landing="{ item }">
                        <td v-if="item.pricing_input.specialized_landing === true"><v-icon color="success">mdi-check</v-icon></td>
                        <td v-else><v-icon color="red">mdi-close</v-icon></td>
                    </template>
                    <!-- User who captured the details -->
                    <template v-slot:item.created_by="{ item }" >
                        <td v-if="item.created_by !== null">
                            <span v-if="item.created_by.entity !== null">
                                {{item.created_by.entity.name}} {{item.created_by.entity.surname}}
                            </span>
                            <span v-else>N/A</span>
                        </td>
                    </template>
                    <!-- Display Repayment Date -->
                    <template v-slot:item.repayment_info.elected_repayment_time_point="{ item }">
                        <td v-if="item.repayment_info.elected_repayment_time_point === -1">N/A</td>
                        <td v-else>{{ item.repayment_info.elected_repayment_time_point }}</td>
                    </template>
                    <!-- Display Bank Statement Status -->
                    <template v-slot:item.bank_statement_status="{ item }">
                        <td v-if="item.bank_statement_status === 'PROCESSED'" style="background:green; color:white;">{{item.bank_statement_status}}</td>
                        <td v-else-if="item.bank_statement_status === 'PROCESSING-FAILED'" style="background:red; color:white;">{{item.bank_statement_status}}</td>
                        <td v-else-if="item.bank_statement_status === 'PROCESSING'" style="background:orange; color:black;">{{item.bank_statement_status}}</td>
                        <td v-else style="background:orange; color:black;">{{item.bank_statement_status}}</td>
                    </template>
                    <!-- Display System-Approval -->
                    <!-- <template v-slot:item.system_approval_results.approval_method="{ item }">
                        <td v-if="item.system_approval_results.approval_method === 'AUTO-APPROVE'" style="background:green; color:white;">{{item.system_approval_results.approval_method}}</td>
                        <td v-else-if="item.system_approval_results.approval_method === 'AUTO-DECLINE'" style="background:red; color:white;">{{item.system_approval_results.approval_method}}</td>
                        <td v-else-if="item.system_approval_results.approval_method === 'MANUAL'" style="background:orange; color:black;">{{item.system_approval_results.approval_method}}</td>
                        <td v-else style="background:orange; color:black;">MANUAL</td>
                    </template> -->
                </v-data-table>
            </div>
            <multipane-resizer></multipane-resizer>
            <div class="pane tab-nav" :style="{ height: '30%', maxHeight: '50%', minHeight: '30%' }">
                <v-tabs
                    v-model="sub_tabs"
                    color="transparent"
                    class="tab-nav"
                    hide-slider
                    height="35px"
                    grow
                >
                    <!-- Amortization Schedule Sub Tab -->
                    <template>
                        <v-tab key="amortization_schedule_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Amortization Schedule
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Documents Sub Tab -->
                    <template>
                        <v-tab key="documents_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Documents
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Pricing-Parameters Sub Tab -->
                    <template>
                        <v-tab key="pricing_parameters_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Pricing Parameters
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Entity-Banking Details Sub Tab -->
                    <template>
                        <v-tab key="entity_banking_details_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Entity Banking
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Bank-Statement Data Sub Tab -->
                    <template>
                        <v-tab key="bank_statement_data_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Bank Statement Data
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>
                </v-tabs>
                <v-tabs-items v-model="sub_tabs">
                    <!-- Sub-Tab Toolbar -->
                    <v-toolbar
                        color="secondary"
                        dark
                        class="pa-0"
                        height="50"
                        extension-height="3"
                    >
                        <!-- Show following buttons only for the DOCUMENTS sub-tab -->
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 1 &&
                                selectedLoan !== null &&
                                selectedLoan.status === LOAN_QUOTE_STATUSES.DRAFT
                            )"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddDocumentsDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Loan Document(s)</span>
                        </v-tooltip>
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 1 &&
                                selectedDocument !== null &&
                                selectedLoan !== null &&
                                selectedLoan.status === LOAN_QUOTE_STATUSES.DRAFT
                            )"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    v-on="on"
                                    @click="openDeleteLoanDocumentDialog()"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete Loan Document(s)</span>
                        </v-tooltip>
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 1 &&
                                selectedDocument !== null &&
                                selectedLoan !== null
                            )"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    v-on="on"
                                    @click="openViewDocumentDialog()"
                                >
                                    <v-icon>content_paste</v-icon>
                                </v-btn>
                            </template>
                            <span>View Document Info</span>
                        </v-tooltip>

                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 4 &&
                                selectedLoan !== null
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="amber black--text"
                                    small
                                    @click="refreshBankStatementTransactions()"
                                    v-on="on"
                                >
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>Refresh Statement Transactions</span>
                        </v-tooltip>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <!-- Amortization Schedule Sub-Tab Content -->
                    <v-tab-item key="amortization_schedule_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="amortization_schedule_headers"
                            :items="amortization_schedule"
                            item-key="_id"
                            class="elevation-1"
                            :single-select="true"
                            @click:row="tobeDefined"
                            item-class="secondary"
                            hide-default-footer
                            :loading="amortization_schedule_loading"
                            :disable-pagination="true"
                        >
                            <template v-slot:item.term_start="{ item }">
                                <td>Month  {{ item.term_start }}</td>
                            </template>
                            <template v-slot:item.term_end="{ item }">
                                <td>Month  {{ item.term_end }}</td>
                            </template>
                            <template v-slot:item.annual_interest_amortization_min_month_term="{ item }">
                                <td>{{ item.annual_interest_amortization_min_month_term }} Months</td>
                            </template>
                            <template v-slot:item.principal_required="{ item }">
                                <td v-if="item.principal_required === true"><v-icon color="success">mdi-check</v-icon></td>
                                <td v-else><v-icon color="red">mdi-close</v-icon></td>
                            </template>
                            <template v-slot:item.initiation_fee_premium_required="{ item }">
                                <td v-if="item.initiation_fee_premium_required === true"><v-icon color="success">mdi-check</v-icon></td>
                                <td v-else><v-icon color="red">mdi-close</v-icon></td>
                            </template>
                            <template v-slot:item.admin_fee_required="{ item }">
                                <td v-if="item.admin_fee_required === true"><v-icon color="success">mdi-check</v-icon></td>
                                <td v-else><v-icon color="red">mdi-close</v-icon></td>
                            </template>
                            <template v-slot:item.interest_required="{ item }">
                                <td v-if="item.interest_required === true"><v-icon color="success">mdi-check</v-icon></td>
                                <td v-else><v-icon color="red">mdi-close</v-icon></td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Documents Sub-Tab Content -->
                    <v-tab-item key="documents_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="document_details_headers"
                            :items="loan_documents"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectDocumentsTabRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Display create date -->
                            <template v-slot:item.created="{ item }">
                                <td>{{item.created.split('T')[0]}}</td>
                            </template>
                            <!-- User who captured the details -->
                            <template v-slot:item.captured_by="{ item }" >
                                <td v-if="item.captured_by !== null">
                                    <span v-if="item.captured_by.entity !== null">
                                        {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                                    </span>
                                    <span v-else>N/A</span>
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Pricing Parameters Sub-Tab Content -->
                    <v-tab-item key="pricing_parameters_sub_tab">
                        <v-card
                            :height="sub_tab_height"
                            style="overflow-y:scroll"
                        >
                            <v-card-text v-if="selectedLoan !== null">
                                <v-row>
                                    <v-col cols="12" sm="12"><h2>Fees</h2></v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            v-model="selectedLoan.pricing_input.fees_config.monthly_admin_fee"
                                            prefix="ZAR"
                                            color="secondary"
                                            label="Monthly Admin Fee"
                                            persistent-hint
                                            required
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            v-model="selectedLoan.pricing_input.fees_config.initiation_fee_principal_percentage"
                                            prefix="%"
                                            color="secondary"
                                            label="Init Fee Percentage"
                                            persistent-hint
                                            required
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-checkbox
                                            v-model="selectedLoan.pricing_input.fees_config.initiation_fee_paid_at_inception"
                                            color="secondary"
                                            label="Init Fee Paid At Inception"
                                            disabled
                                            required
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" sm="12"><h2>Interest</h2></v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            v-model="selectedLoan.pricing_input.interest_config.annual_interest_rate"
                                            prefix="%"
                                            color="secondary"
                                            label="Annual Interest Rate"
                                            persistent-hint
                                            required
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            v-model="selectedLoan.pricing_input.interest_config.annual_interest_rate_monthly"
                                            prefix="%"
                                            color="secondary"
                                            label="Annual Interest Rate (Monthly)"
                                            persistent-hint
                                            required
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>

                    <!-- Entity-Banking Details -->
                    <v-tab-item key="entity_banking_details_sub_tab">
                        <v-card
                            :height="sub_tab_height"
                            style="overflow-y:scroll"
                        >
                            <v-card-text v-if="selectedLoan !== null">
                                <v-row>
                                    <v-col cols="12" sm="12"><h2>Banking Details</h2></v-col>
                                    <v-col cols="12" sm="12">
                                        <v-radio-group v-model="selectedLoan.banking_details_id" disabled>
                                            <v-radio
                                                v-for="bankObj in selectedLoan.entity.bank_account_details.filter(bank => { return bank.deleted === false })"
                                                :key="bankObj._id"
                                                :label="`${bankObj.bank.name} - ${bankObj.account_number}`"
                                                :value="bankObj._id"
                                                color="secondary"
                                            ></v-radio>
                                        </v-radio-group>
                                        <h1
                                            style="color:red"
                                            v-if="selectedLoan.entity.bank_account_details.filter(bank => { return bank.deleted === false }).length === 0"
                                        >
                                            No Banking Details Captured
                                        </h1>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>

                    <!-- Bank Statement Data Sub-Tab Content -->
                    <v-tab-item key="bank_statement_data_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="bank_statement_data_headers"
                            :items="bank_statement_transactions"
                            item-key="_id"
                            :single-select="true"
                            @click:row="tobeDefined"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                            :loading="bank_statement_transactions_loading"
                        >
                            <!-- Display create date -->
                            <template v-slot:item.transaction_date="{ item }">
                                <td>{{item.transaction_date.split('T')[0]}}</td>
                            </template>
                            <!-- User who captured the details -->
                            <template v-slot:item.created_by="{ item }" >
                                <td v-if="item.created_by !== null">
                                    <span v-if="item.created_by.entity !== null">
                                        {{item.created_by.entity.name}} {{item.created_by.entity.surname}}
                                    </span>
                                    <span v-else>N/A</span>
                                </td>
                            </template>
                            <!-- Display Formated correct -->
                            <template v-slot:item.correct="{ item }">
                                <td
                                    v-if="item.correct === true"
                                    style="background:green; color:white; font-weight:bold; border: 1px solid white"
                                >
                                    YES
                                </td>
                                <td
                                    v-else
                                    style="background:red; color:white; font-weight:bold; border: 1px solid white"
                                >
                                    NO
                                </td>
                            </template>
                            <!-- Display Formated correct -->
                            <template v-slot:item.corrected_by_system="{ item }">
                                <td
                                    v-if="item.corrected_by_system === true"
                                    style="background:red; color:white; font-weight:bold; border: 1px solid white"
                                >
                                    YES
                                </td>
                                <td
                                    v-else
                                    style="background:green; color:white; font-weight:bold; border: 1px solid white"
                                >
                                    NO
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </multipane>
        <AddLoanQuoteDialog
            :dialog="open_add_loan_dialog"
        />
        <!-- <DeleteDraftedLoanDialog
            v-if="selectedLoan !== null"
            :dialog="open_delete_drafted_loan_dialog"
            :loan="selectedLoan"
        /> -->
        <SearchLoansDialog
            :dialog="open_search_loans_dialog"
        />
        <AddLoanDocumentDialog
            v-if="selectedLoan !== null"
            :dialog="open_add_document_dialog"
            :loan="selectedLoan"
        />
        <ViewDocumentDialog
            v-if="selectedDocument !== null"
            :dialog="open_view_loan_document_dialog"
            :document="selectedDocument"
            param_path="loan_quote"
            :param_id="selectedLoan._id"
        />
        <DeleteLoanDocumentDialog
            v-if="selectedDocument !== null"
            :dialog="open_delete_loan_document_dialog"
            :loan="selectedLoan"
            :document="selectedDocument"
        />
        <CaptureSignatureDialog
            v-if="selectedLoan !== null"
            :dialog="open_capture_signature_dialog"
            :loan="selectedLoanCopy"
        />
        <DocPreviewDialog
            :dialog="open_policy_certificate_preview_dialog"
            :url="policy_certificate_url"
            :doc_type="policy_certificate_doc_type"
            @CLOSE_DOC_PREVIEW_DIALOG="closePolicyCertificatePreviewDialog()"
        />

        <!-- Life Cycle Management -->
        <ValidateDraftedLoanDialog
            v-if="selectedLoan !== null"
            :dialog="open_validate_drafted_loan_dialog"
            :loan="selectedLoan"
        />
        <!-- pending -->
        <ActivatePendingLoanDialog
            v-if="selectedLoan !== null"
            :dialog="open_activate_pending_loan_dialog"
            :loan="selectedLoan"
        />
        <DeclinePendingLoanDialog
            v-if="selectedLoan !== null"
            :dialog="open_decline_pending_loan_dialog"
            :loan="selectedLoan"
        />
        <ViewSystemApprovalResultsDialog
            v-if="selectedLoan !== null"
            :dialog="open_view_system_approval_results_dialog"
            :loan="selectedLoan"
        />
    </div>
</template>
<script>
import { Multipane, MultipaneResizer } from 'vue-multipane';
import AddLoanQuoteDialog from './dialogs/add_loan_quote';
import ViewSystemApprovalResultsDialog from './dialogs/view_system_approval_results';
import SearchLoansDialog from './dialogs/search_loans';
// import DeleteDraftedLoanDialog from './dialogs/delete_drafted_loan';
import AddLoanDocumentDialog from './dialogs/add_loan_documents';
import ViewDocumentDialog from '../../../components/common_dialogs/view_document_info';
import DeleteLoanDocumentDialog from './dialogs/delete_loan_document';
import CaptureSignatureDialog from './dialogs/capture_signature.vue';
import DocPreviewDialog from '../../../components/doc_preview';

// // Life cycle managers
import ValidateDraftedLoanDialog from './dialogs/life_cycle/draft/validate_drafted_loan';
import ActivatePendingLoanDialog from './dialogs/life_cycle/pending/activate_pending_loan';
import DeclinePendingLoanDialog from './dialogs/life_cycle/pending/decline_pending_loan';

import { eventBus } from "../../../main";
import { emitAlert, emitSuccess, generateLoanQuoteApplicationForm } from "../../../utils/api";
import { LOAN_QUOTE_STATUSES, BUSINESS_PARTNER_TYPES } from "../../../../config";

import { store } from "../../../../store";
import converter from "../../../mixins/converter";

import { getLoanQuotes, getBankStatementTransactions } from "./gql/queries";
import { businessProcessLoanQuote } from "./gql/mutations";

export default {
    mixins: [converter],
    components: {
        Multipane,
        MultipaneResizer,
        AddLoanQuoteDialog,
        // DeleteDraftedLoanDialog,
        SearchLoansDialog,
        AddLoanDocumentDialog,
        ViewDocumentDialog,
        DeleteLoanDocumentDialog,
        CaptureSignatureDialog,
        DocPreviewDialog,
        ViewSystemApprovalResultsDialog,

        // Loan Life Cycle Dialogs
        ValidateDraftedLoanDialog,
        DeclinePendingLoanDialog,
        ActivatePendingLoanDialog,
    },

    computed: {
        isCentralUnderwriterAdminUser() {
            const result = store.state.currentUserAccount.roles.filter(role => {
                                                            return (
                                                                role.role.level.includes(BUSINESS_PARTNER_TYPES.CENTRAL_UNDERWRITER) &&
                                                                role.role.name.toLowerCase() ===  'admin - central underwriter'
                                                            );
                                                        }).length > 0;

            return result;
        }
    },

    data: () => ({
        sub_tabs: null,
        main_tab_height: '250px',
        sub_tab_height:'180',

        // main data-table row
        selectedLoan: null,
        selectedRow: null,

        // documents sub-tab data-table row
        selectedDocument: null,
        selectedDocumentRow: null,

        selectedPaymentHolidayClaim: null,
        selectedPaymentHolidayClaimRow: null,

        loan_details_headers: [
            { text: 'Quote Number', value: 'quote_number' },
            // { text: 'Loan Type', value: 'pricing_input.loan_type' },
            { text: 'Loan Entity', value: 'entity' },
            // { text: 'Monthly Income', value: 'entity_monthly_income' },
            { text: 'Bank Statement Status', value: 'bank_statement_status' },
            { text: 'Agent', value: 'agent' },
            { text: 'Scheme / Book', value: 'scheme' },
            { text: 'Monthly Repayment Date', value: 'repayment_info.elected_repayment_time_point', width: '6%' },
            { text: 'Payment Method', value: 'repayment_info.payment_method' },
            { text: 'Amount', value: 'pricing_input.principal_amount' },
            { text: 'Loan Term', value: 'pricing_input.loan_term' },
            // { text: 'Specialized Lending', value: 'pricing_input.specialized_landing' },
            { text: 'Bank Name', value: 'bank_name' },
            { text: 'Status', value: 'status' },
            // { text: 'System Approval Method', value: 'system_approval_results.approval_method' },
            // { text: 'Bank Statement Status', value: 'bank_statement_status' },
            { text: 'Date Captured', value: 'created' },
            { text: 'Created By', value: 'created_by' },
        ],
        amortization_schedule_headers: [
            { text: 'Term Start', value: 'term_start' },
            { text: 'Term End', value: 'term_end' },
            { text: 'Annual Interest Amortization Min Month Term', value: 'annual_interest_amortization_min_month_term' },

            { text: 'Principal Required', value: 'principal_required' },
            { text: 'Init Fee Prem Required', value: 'initiation_fee_premium_required' },
            { text: 'Admin Fee Required', value: 'admin_fee_required' },
            { text: 'Interest Required', value: 'interest_required' },
        ],
        document_details_headers: [
            { text: 'Document Type', value: 'type' },
            { text: 'Name', value: 'name' },
            { text: 'Date Captured', value: 'created' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        bank_statement_data_headers: [
            { text: 'Page Number', value: 'page_number' },
            { text: 'Item Number', value: 'item_number' },
            { text: 'Transaction Date', value: 'transaction_date' },
            { text: 'Description', value: 'description' },
            { text: 'Amount', value: 'amount' },
            { text: 'Balance', value: 'running_balance' },
            { text: 'Correct', value: 'correct' },
            { text: 'Corrected By System', value: 'corrected_by_system' },
            { text: 'Processed By', value: 'created_by' },
        ],

        policy_certificate_doc_type: 'pdf',
        policy_certificate_url: null,
        
        // Table data models
        amortization_schedule: [],
        loan_documents: [],
        bank_statement_transactions: [],

        // Dialogs
        
        loans: [],
        open_add_loan_dialog: false,
        open_search_loans_dialog: false,
        open_add_document_dialog: false,
        open_view_loan_document_dialog: false,
        open_delete_loan_document_dialog: false,
        // open_delete_drafted_loan_dialog: false,
        open_capture_signature_dialog: false,
        open_policy_certificate_preview_dialog: false,
        open_view_system_approval_results_dialog: false,

        // Life cycle management
        open_validate_drafted_loan_dialog: false,
        open_process_loan_bank_statement_dialog: false,
        // pending
        open_activate_pending_loan_dialog: false,
        open_decline_pending_loan_dialog: false,

        data_table_loading: false,
        SEARCH_MODE: false,
        page: 1,
        limit: 50,

        LOAN_QUOTE_STATUSES: LOAN_QUOTE_STATUSES,
        
        selectedLoanCopy: null,

        amortization_schedule_loading: false,
        bank_statement_transactions_loading: false,
    }),

    methods: {
        //-------------------- OPEN DIALOGS ---------------------//
        openAddLoanDialog() {
            this.open_add_loan_dialog = true;
        },
        openSearchLoanDialog() {
            // this.unselectSelectedRow();
            this.open_search_loans_dialog = true;
        },
        openAddDocumentsDialog() {
            this.open_add_document_dialog = true;
        },
        openViewDocumentDialog() {
            this.open_view_loan_document_dialog = true;
        },
        openDeleteLoanDocumentDialog() {
            this.open_delete_loan_document_dialog = true;
        },
        // deleteDraftedLoan() {
        //     this.open_delete_drafted_loan_dialog = true;
        // },
        openCaptureSignatureDialog() {
            this.open_capture_signature_dialog = true;
        },

        resetLoanEditForm(updatedLoan) {
            if (
                parseFloat(updatedLoan.amount) !== this.selectedLoan.amount ||
                parseFloat(updatedLoan.installments) !== this.selectedLoan.installments ||
                parseFloat(updatedLoan.elected_monthly_repayment_date) !== this.selectedLoan.elected_monthly_repayment_date ||
                updatedLoan.payment_method !== this.selectedLoan.payment_method ||

                updatedLoan.elected_monthly_repayment_date !== this.selectedLoan.elected_monthly_repayment_date
            ) {
                // this.selectedLoanCopy = Object.assign({}, this.selectedLoan);
                this.selectedLoanCopy = JSON.parse( JSON.stringify(this.selectedLoan) );
            }
        },
        //------------------------------------------------------------------------------------//
        //-------------------------------- LIFE CYCLE MANAGEMENT -----------------------------//
        //------------------------------------------------------------------------------------//

        openViewSystemApprovalResultsDialog() {
            this.open_view_system_approval_results_dialog = true;
        },
        // Draft
        openValidateDraftedLoanDialog() {
            this.open_validate_drafted_loan_dialog = true;
        },
        openProcessLoanBankStatementDialog() {
            this.open_process_loan_bank_statement_dialog = true;
        },

        // Pending
        openDeclinePendingLoanDialog() {
            this.open_decline_pending_loan_dialog = true;
        },
        openActivatePendingLoanDialog() {
            this.open_activate_pending_loan_dialog = true;
        },
        openViewPolicyCertificateDialog(loan_quote_id) {
            generateLoanQuoteApplicationForm(loan_quote_id).then(res => {
                const url = window.URL.createObjectURL(new Blob([res], {type: 'application/pdf'}));
                
                this.policy_certificate_url =  url;
                this.open_policy_certificate_preview_dialog = true;
            });
        },

        //------------------- CLOSE DIALOGS---------------------//
        updateUITableModel(new_loan) {
            if (new_loan !== null) {
                const obj_index = this.loans.map(obj => { return obj._id; }).indexOf(new_loan._id)
                this.loans[obj_index] = new_loan;

                this.selectedLoan = new_loan;
                this.selectedLoanCopy = JSON.parse( JSON.stringify(new_loan) );

                 // Deep copy loans for refreshing main data table
                const temp_arr = Array.from(this.loans);
                this.loans = temp_arr;

                // Also update the documents tab
                this.loan_documents = new_loan.documents.filter(doc => { return !doc.deleted });
                this.amortization_schedule = [];
            }
        },
        closeAddLoanDialog(new_loan) {
            if (new_loan !== null) {
                // this.loans.push(new_loan);
                this.loans.unshift(new_loan);
            }
            this.open_add_loan_dialog = false;
        },
        closeSearchLoansDialog(searchResults) {
            if (searchResults !== null) {
                this.loans = searchResults;
                this.SEARCH_MODE = true;

                // Update loan addresses model
                this.amortization_schedule = [];
                // Update loan documents model
                this.loan_documents = [];
            }
            this.open_search_loans_dialog = false;
        },
        closeAddLoanDocumentsDialog(new_loan) {
            this.updateUITableModel(new_loan);
            this.open_add_document_dialog = false;
        },
        closeDeleteLoanDocumentDialog(new_loan) {
            if (new_loan !== null) {
                // Then a delete action actually happened
                this.selectedDocument = null;
                this.selectedDocumentRow = null;
            }
            this.updateUITableModel(new_loan);
            this.open_delete_loan_document_dialog = false;
        },
        closeViewServerDocumentsDialog() {
            this.open_view_loan_document_dialog = false;
        },
        // closeDeleteDraftedLoanDialog(canceled) {
        //     if (canceled === false) {
        //         this.selectedLoan = null;

        //         // Also update the documents tab
        //         this.loan_documents = [];
        //         this.amortization_schedule = [];

        //         this.refreshLoans();
        //         this.unselectSubTabRows();
        //     }
            
        //     this.open_delete_drafted_loan_dialog = false;
        // },
        closeViewSystemApprovalResultsDialog() {
            this.open_view_system_approval_results_dialog = false;
        },
        closeValidateDraftedLoanDialog(new_loan) {
            this.updateUITableModel(new_loan);
            this.open_validate_drafted_loan_dialog = false;
        },
        closeProcessLoanBankStatementDialog(canceled, new_loan) {
            if (canceled === false) {
                this.updateUITableModel(new_loan);
            }
            this.open_process_loan_bank_statement_dialog = false;
        },
        closeActivatePendingLoanDialog(new_loan) {
            this.updateUITableModel(new_loan);
            this.open_activate_pending_loan_dialog = false;
        },
        closeDeclinePendingLoanDialog(new_loan) {
            this.updateUITableModel(new_loan);
            this.open_decline_pending_loan_dialog = false;
        },
        closeCaptureSignatureDialog(new_loan) {
            this.updateUITableModel(new_loan);
            this.open_capture_signature_dialog = false;
        },
        closePolicyCertificatePreviewDialog() {
            this.policy_certificate_url = null;
            this.open_policy_certificate_preview_dialog = false;
        },


        // Row Selector Control
        unselectSubTabRows() {
            // Unselect documents sub-tab row
            this.selectedDocument = null;
            if (this.selectedDocumentRow !== null) {
                this.selectedDocumentRow.select(false);
            }
            // Unselect payment holiday claim sub-tab row
            this.selectedPaymentHolidayClaim = null;
            if (this.selectedPaymentHolidayClaimRow !== null) {
                this.selectedPaymentHolidayClaimRow.select(false);
            }
        },
        async selectMainTableRow(item, item_metadata) {
            // console.log(item)
            this.unselectSubTabRows();

            // console.log('ABA: ', item);
            // console.log('ZZZ: ', item_metadata);
            this.selectedLoanCopy = JSON.parse( JSON.stringify(item) );
            // console.log(this.selectedLoanCopy);

            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                this.selectedRow = item_metadata;

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                this.selectedLoan = item;
                // Update loan documents model
                this.loan_documents = item.documents.filter(doc => { return !doc.deleted });
                // Update amortization_schedule model
                this.amortization_schedule = item.pricing_input.amortization_schedule_config;

                if (this.sub_tabs === 4) {
                    await this.refreshBankStatementTransactions();
                }

                // Create loan copy
                // this.selectedLoanCopy = JSON.parse( JSON.stringify(this.selectedLoan) );



                // this.selectedLoanCopy = Object.assign({}, this.selectedLoan);
                // console.log('ABA: ', this.selectedLoan);
            } else {
                this.selectedLoan = null;
                // Unighlight selected row
                item_metadata.select(false);
                this.selectedRow = null;

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                // Update loan addresses model
                this.amortization_schedule = [];
                // Update loan documents model
                this.loan_documents = [];
                // Update bank statement transactions
                this.bank_statement_transactions = [];

                // ----------------------UPDATE VISIBILITY OF ACTION BUTTONS -----------------------//
            }
        },
        selectDocumentsTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedDocument = item;
                this.selectedDocumentRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedDocument = null;
                this.selectedDocumentRow = null;
            }
        },
        selectPaymentHolidayClaimTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedPaymentHolidayClaim = item;
                this.selectedPaymentHolidayClaimRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedPaymentHolidayClaim = null;
                this.selectedPaymentHolidayClaimRow = null;
            }
        },
        tobeDefined(){},

        clearSearchResults() {
            // Unselect a row if there was any
            this.unselectSubTabRows();
            this.unselectSelectedRow();

            this.SEARCH_MODE = false;
            this.refreshLoans();

            this.bank_statement_transactions = [];
        },

        unselectSelectedRow() {
            if (this.selectedRow !== null) {
                this.selectedRow.select(false);
                this.selectedRow = null;

                // Update loan addresses model
                this.amortization_schedule = [];
                // Update loan documents model
                this.loan_documents = [];
            }
            this.selectedLoan = null;
        },

        async loadMoreData() {
            this.data_table_loading = true;

            this.page += 1;
            // getLoans(this.page, this.limit).then(res => {
            //     this.data_table_loading = false;
            //     this.loans = this.loans.concat( res.data );
            // });

            try {
                const res = await this.$apollo
                                        .query({
                                            query: getLoanQuotes,
                                            // fetchPolicy: 'network-only',
                                            fetchPolicy: 'no-cache',
                                            errorPolicy: 'all',
                                            variables: {
                                                objectType: 'LOAN_QUOTE',
                                                pagination: {
                                                    page: this.page,
                                                    limit: this.limit
                                                }
                                            },
                                        });
                this.data_table_loading = false;
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }
    
                this.loans = this.loans.concat( res.data.getBusinessObject.business_objects );
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        async refreshLoans() {
            this.data_table_loading = true;
            this.loans = [];

            this.page = 1; //, limit = 20;

            try {
                const res = await this.$apollo
                                        .query({
                                            query: getLoanQuotes,
                                            // fetchPolicy: 'network-only',
                                            fetchPolicy: 'no-cache',
                                            errorPolicy: 'all',
                                            variables: {
                                                objectType: 'LOAN_QUOTE',
                                                pagination: {
                                                    page: this.page,
                                                    limit: this.limit
                                                }
                                            },
                                        });
                this.data_table_loading = false;
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }
    
                this.loans = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
        async refreshBankStatementTransactions() {
            try {
                this.bank_statement_transactions_loading = true;
                const res = await this.$apollo
                                    .query({
                                        query: getBankStatementTransactions,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'BANK_STATEMENT_DATA',
                                            pagination: {
                                                page: 0,
                                                limit: 0
                                            },
                                            searchInput: {
                                                business_object_id: this.selectedLoan._id
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.bank_statement_transactions_loading = false;
                    return;
                }


                this.bank_statement_transactions_loading = false;
                if (res.data.getBusinessObject.business_objects.length > 0) {
                    res.data.getBusinessObject.business_objects[0].parsed_transactions.forEach(item => {
                        item.created_by = res.data.getBusinessObject.business_objects[0].created_by;
                    });
                    this.bank_statement_transactions = res.data.getBusinessObject.business_objects[0].parsed_transactions;
                } else {
                    this.bank_statement_transactions = [];
                }
            } catch (error) {
                this.bank_statement_transactions_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
        async processBankStatement() {
            this.data_table_loading = true;

            try {
                const payloadObj = {
                    OP_TYPE: 'BANK_STATEMENT_PROCESSING',
                    _id: this.selectedLoan._id,
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcessLoanQuote,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.businessProcessLoanQuote.errors === true) {
                    emitAlert(res.data.businessProcessLoanQuote.message);
                    this.data_table_loading = false;
                    return;
                }

                emitSuccess(res.data.businessProcessLoanQuote.message);
                this.data_table_loading = false;

                // Update UI model
                this.updateUITableModel(res.data.businessProcessLoanQuote.business_object);
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessLoanQuote.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
        },
        paneResizing(pane, container, size) {
            const size_int = parseInt(size);

            this.main_tab_height = size;
            const max_screen_height = window.innerHeight - 300;

            const sub_tab_percentage = 1 - (size_int/max_screen_height);
            this.sub_tab_height = sub_tab_percentage * max_screen_height;
        },
    },

    async mounted() {
        eventBus.$on('CLOSE_ADD_LOAN_DIALOG', this.closeAddLoanDialog);
        eventBus.$on('CLOSE_ADD_LOAN_DOCUMENTS_DIALOG', this.closeAddLoanDocumentsDialog);
        // eventBus.$on('CLOSE_DELETE_LOAN_DRAFTED_LOAN_DIALOG', this.closeDeleteDraftedLoanDialog);
        eventBus.$on('CLOSE_DELETE_LOAN_DOCUMENT_DIALOG', this.closeDeleteLoanDocumentDialog);
        eventBus.$on('CLOSE_VIEW_DOCUMENT_DIALOG', this.closeViewServerDocumentsDialog);
        eventBus.$on('CLOSE_LOAN_SEARCH_DIALOG', this.closeSearchLoansDialog);
        eventBus.$on('CLOSE_CAPTURE_LOAN_SIGNATURE_DIALOG', this.closeCaptureSignatureDialog);

        eventBus.$on('CLOSE_VIEW_SYSTEM_VALIDATION_RESULTS_DIALOG', this.closeViewSystemApprovalResultsDialog);

        // Life cycle
        eventBus.$on('CLOSE_VALIDATE_LOAN_DIALOG', this.closeValidateDraftedLoanDialog);
        eventBus.$on('CLOSE_PROCESS_LOAN_BANK_STATEMENT_DIALOG', this.closeProcessLoanBankStatementDialog);
        // pending
        eventBus.$on('CLOSE_ACTIVATE_PENDING_LOAN_DIALOG', this.closeActivatePendingLoanDialog);
        eventBus.$on('CLOSE_DECLINE_PENDING_LOAN_DIALOG', this.closeDeclinePendingLoanDialog);

        // Form reseters
        eventBus.$on('RESET_LOAN_EDIT_FORM', this.resetLoanEditForm);


        /**
         * The following logic is for manually triggering a pane-divider movement
         * so as to get the windows to properly resize
         */
        this.paneResizing(null, null, 250);

        // try {
        //     this.data_table_loading = true;
        //     const res = await getLoans(this.page, this.limit);
        //     if (res.errors === true) {
        //         emitAlert(res.message);
        //         this.data_table_loading = false;
        //         return;
        //     }

        //     this.data_table_loading = false;
        //     this.loans = res.data;
        // } catch (error) {
        //     this.data_table_loading = false;
        //     console.log(error);
        //     if (error !== undefined) {
        //         emitAlert(error.data.message);
        //     } else {
        //         emitAlert('The server has taken too long in responding to your request');
        //     }
        // }

        try {
            this.data_table_loading = true;
            const res = await this.$apollo
                                    .query({
                                        query: getLoanQuotes,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'LOAN_QUOTE',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            this.data_table_loading = false;
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                return;
            }

            // // eslint-disable-next-line
            // debugger
            this.loans = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.data_table_loading = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
        }
    },

    watch: {
        async sub_tabs(val) {
            if (val === 4) {
                await this.refreshBankStatementTransactions();
            }
        }
    }
}
</script>
<style scoped>
/* Overriding of textbox min height */
 .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
}
</style>
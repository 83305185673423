<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Add Product VAS</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row
                        style="background:#eaeaea; padding-bottom:10px"
                        v-for="vas_service in vas_services"
                        :key="vas_service.id"
                    >
                        <v-col cols="12" sm="7">
                            <v-select
                                v-model="vas_service.vas_service"
                                :items="vas_services_list"
                                color="secondary"
                                item-value="_id"
                                item-text="name"
                                label="VAS Service"
                                :disabled="refreshing_vas_services_list"

                                hint="You can click the refresh icon next to the field if the VAS services list is incomplete"
                                persistent-hint

                                required
                            >
                                <!-- Format prepend inner icon -->
                                <template v-slot:prepend-inner>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon
                                                v-on="on"
                                                color="success"
                                                @click="refreshVASServicessList()"
                                            >
                                                mdi-refresh
                                            </v-icon>
                                        </template>
                                        <span>Refresh VAS Services List</span>
                                    </v-tooltip>
                                </template>
                            </v-select>
                            <v-progress-linear
                                color="green"
                                class="mb-0"
                                height="4"
                                indeterminate
                                :active="refreshing_vas_services_list"
                            ></v-progress-linear>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-select
                                v-model="vas_service.limit_model"
                                :items="['PER_MONTH', 'PER_ANNUM']"
                                item-text="text"
                                item-value="value"
                                label="Limit Model"
                                color="secondary"
                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-text-field
                                v-model="vas_service.limit"
                                :rules="vas_fee_rules"
                                color="secondary"
                                label="Limit"
                                autocomplete="some-random-text"
                                type="number"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <!-- Buttons for Adding/Removing VAS items -->
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting Product VAS Service
                        <v-progress-linear
                            indeterminate
                            :active="submitting"
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="validVASServicesCaptured()"
                                @click="submitData()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Save Product VAS</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../../../utils/api";
import { eventBus } from "../../../../../main";

import { getVASList } from "../gql/queries";
import { productProcessInsuranceProductPrimary } from "../gql/mutations";

export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        product_id: {
            type: String,
            default: null
        },
    },
    data: () => ({
        server_response_obj: null,
        submitting: false,
        vas_services: [
            {
                id: 1,
                vas_service: null,
                limit_model: null,
                limit: 0,
            }
        ],

        vas_services_list: [],
        refreshing_vas_services_list: false,

        vas_fee_rules: [
            v => !!v || 'Fee amount is required',
            v => v > 0 || 'Please enter a valid fee amount'
        ],
    }),
    methods: {
        closeDialog() {
            eventBus.$emit('CLOSE_ADD_PRODUCT_VAS_DIALOG', this.server_response_obj);
        },
        resetForm() {
            this.submitting = false;
            this.vas_services = [
                {
                    id: 1,
                    vas_service: null,
                    limit_model: null,
                    limit: 0,
                }
            ];
        },

        validVASServicesCaptured() {
            const validVASServices = this.vas_services.filter(vas => {
                                        return (
                                            vas.vas_service !== null &&
                                            vas.limit_model !== null &&
                                            vas.limit !== null &&
                                            vas.limit !== undefined &&
                                            !isNaN(parseFloat(vas.limit)) &&
                                            parseFloat(vas.limit) > 0
                                        );
                                    });

            if (validVASServices.length === 0) {
                return true;
            }

            return false;
        },

        async submitData() {
            const validVASServices = this.vas_services.filter(vas => {
                                        return (
                                            vas.vas_service !== null &&
                                            vas.limit_model !== null &&
                                            vas.limit !== null &&
                                            vas.limit !== undefined &&
                                            !isNaN(parseFloat(vas.limit)) &&
                                            parseFloat(vas.limit) > 0
                                        );
                                    });

            // deep copy the validVASServices array
            const arrCopy = JSON.parse(JSON.stringify(validVASServices))
                                    .map(vas => {
                                        delete vas.id;

                                        vas.limit = parseFloat(vas.limit);
                                        return vas;
                                    });

            if (arrCopy.length === 0) {
                emitAlert('Please capture atleast one valid VAS service');
                return;
            }

            this.submitting = true;

            try {
                const payloadObj = {
                    OP_TYPE: 'ADD_VAS',
                    _id: this.product_id,

                    // Placeholder fields
                    name: '',
                    underwriter_premium: 0,
                    broker_commission: 0,
                    broker_markup: 0,

                    vas_services: arrCopy
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: productProcessInsuranceProductPrimary,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.productProcessInsuranceProductPrimary.errors === true) {
                    emitAlert(res.data.productProcessInsuranceProductPrimary.message);
                    this.submitting = false;
                    return;
                }
                
                this.server_response_obj = res.data.productProcessInsuranceProductPrimary.business_object;
                this.closeDialog();
                emitSuccess(res.data.productProcessInsuranceProductPrimary.message);

                // Reset fields
                this.server_response_obj = null;
                this.submitting = false;
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.productProcessInsuranceProductPrimary.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request')
                }
                
                return;
            }
        },

        async refreshVASServicessList() {
            this.refreshing_vas_services_list = true;
            try {
                // Load VAS services list
                const res = await this.$apollo
                                    .query({
                                        query: getVASList,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'VAS_SERVICE',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                this.refreshing_vas_services_list = false;
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }
                this.vas_services_list = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.refreshing_vas_services_list = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
    },
    async mounted() {
        this.submitting = true;
        try {
            // Get VAS Services list
            const res = await this.$apollo
                                    .query({
                                        query: getVASList,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'VAS_SERVICE',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.submitting = false;
                return;
            }
            this.vas_services_list = res.data.getBusinessObject.business_objects;

            // Reset fields
            this.submitting = false;
        } catch (error) {
            this.submitting = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
            
            return;
        }
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
import gql from 'graphql-tag'

export const getFinancialCalendar = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on FinanceCalendarYear {
                    __typename
                    _id
                    name
                    value
                    status
                    created

                    fin_months {
                        _id
                        name
                        value
                        status
                        created
                    }
                }
            }
        }
    }
`

export const getFinCalendarYearRangeLookup = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on FinanceCalendarRange {
                    __typename
                    year
                }
            }
        }
    }
`
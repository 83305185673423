<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Search Cases</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeMemberSearchDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <!-- Case Number -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.case_number.search"
                                color="success"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-text-field
                                v-model="search_fields.case_number.value"
                                prefix="CS-"
                                label="Case Number"
                                type="number"
                                color="secondary"
                                required
                                :disabled="!search_fields.case_number.search"
                            ></v-text-field>
                        </v-col>

                        <!-- Case Status -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.status.search"
                                color="success"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-select
                                v-model="search_fields.status.value"
                                :items="[
                                    'OPEN',
                                    'CLOSED',
                                ]"
                                label="Case Status"
                                color="secondary"
                                :disabled="!search_fields.status.search"
                                required
                            ></v-select>
                        </v-col>

                        <!-- Loan Status -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.loan_status.search"
                                color="success"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-select
                                v-model="search_fields.loan_status.value"
                                :items="quality_assurance_list.filter(item => { return item.text_status !== null; })"
                                item-text="text_status"
                                item-value="value"
                                label="Loan Status"
                                color="secondary"
                                :disabled="!search_fields.loan_status.search"
                                required
                            ></v-select>
                        </v-col>

                        <!-- Quality Assurance -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.quality_assurance.search"
                                color="success"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-select
                                v-model="search_fields.quality_assurance.value"
                                :items="quality_assurance_list"
                                item-text="text_qa"
                                item-value="value"
                                label="Quality Assurance Check"
                                color="secondary"
                                :disabled="!search_fields.quality_assurance.search"
                                required
                            ></v-select>
                        </v-col>

                        <!-- Loan Number -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.loan_number.search"
                                color="success"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-text-field
                                v-model="search_fields.loan_number.value"
                                prefix="L"
                                label="Loan Number"
                                color="secondary"
                                required
                                :disabled="!search_fields.loan_number.search"
                            ></v-text-field>
                        </v-col>

                        <!-- Next Action Date -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.next_action_date.search"
                                color="success"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-menu
                                v-model="next_action_date_menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="search_fields.next_action_date.value"
                                    label="Next Action Date"
                                    color="secondary"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :disabled="!search_fields.next_action_date.search"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    color="secondary"
                                    v-model="search_fields.next_action_date.value"
                                    @input="next_action_date_menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>

                        <!-- Elected Payment Date -->
                        <!-- <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.elected_monthly_repayment_date.search"
                                color="success"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-text-field
                                v-model="search_fields.elected_monthly_repayment_date.value"
                                type="number"
                                label="Elected Payment Date"
                                color="secondary"
                                :disabled="!search_fields.elected_monthly_repayment_date.search"
                                required
                            ></v-text-field>
                        </v-col> -->
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Searching Cases
                        <v-progress-linear
                            indeterminate
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom v-if="search_field_selected">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                @click="submitSearchQuery()"
                            >
                                <v-icon large>search</v-icon>
                            </v-btn>
                        </template>
                        <span>Submit Search Query</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { eventBus } from '../../../main';
import { emitAlert } from '../../../utils/api';
import { getOperationalExcellenceCases } from "../gql/queries";
export default {
    name: 'SearchCasesDialog',
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        next_action_date_menu: false,
        search_fields: {
            case_number: {
                search: false,
                value: null,
            },
            status: {
                search: false,
                value: null,
            },
            loan_status: {
                search: false,
                value: null,
            },
            quality_assurance: {
                search: false,
                value: null,
            },
            loan_number: {
                search: false,
                value: null,
            },
            next_action_date: {
                search: false,
                value: null,
            },
            elected_monthly_repayment_date: {
                search: false,
                value: null,
            },
        },
        submitting: false,

        quality_assurance_list: [
            {
                text_qa: 'Stage 1 ------ (1 - 7 Days No notes)',
                text_status: 'Stage 1 ------ (1 - 7 Days)',
                value: 'STAGE_1 - 1_TO_7_DAYS'
            },
            {
                text_qa: 'Stage 1 ------ (8 - 30 Days No notes)',
                text_status: 'Stage 1 ------ (8 - 30 Days)',
                value: 'STAGE_1 - 8_TO_30_DAYS'
            },
            {
                text_qa: 'Stage 2 ------ (30 - 60 Days No notes)',
                text_status: 'Stage 2 ------ (30 - 60 Days)',
                value: 'STAGE_2 - 30_TO_60_DAYS'
            },
            {
                text_qa: 'Stage 3 ------ (60 - 90 Days No notes)',
                text_status: 'Stage 3 ------ (60 - 90 Days)',
                value: 'STAGE_3 60_TO_90_DAYS'
            },
            {
                text_qa: 'Stage 3 ------ (Above 90 Days No notes)',
                text_status: 'Stage 3 ------ (Above 90 Days)',
                value: 'STAGE_3 ABOVE_90_DAYS'
            },
            // // Not actioned timeously
            // {
            //     text_qa: 'Action Date is passed (No action notes)',
            //     text_status: null,
            //     value: 'ACTION_DATE_PASSED'
            // },
        ]
    }),
    methods: {
        closeMemberSearchDialog() {
            eventBus.$emit('CLOSE_CASE_SEARCH_DIALOG', null);
        },
        resetForm() {
            this.search_fields = {
                case_number: {
                    search: false,
                    value: null,
                },
                status: {
                    search: false,
                    value: null,
                },
                loan_status: {
                    search: false,
                    value: null,
                },
                quality_assurance: {
                    search: false,
                    value: null,
                },
                loan_number: {
                    search: false,
                    value: null,
                },
                next_action_date: {
                    search: false,
                    value: null,
                },
                elected_monthly_repayment_date: {
                    search: false,
                    value: null,
                },
            };
        },
        async submitSearchQuery() {
            this.submitting = true;

            const searchObj = {};

            Object.keys(this.search_fields).forEach(searchField => {
                if (this.search_fields[ searchField ].search === true) {
                    if (
                        this.search_fields[ searchField ].value !== null &&
                        this.search_fields[ searchField ].value !== undefined
                    ) {
                        searchObj[ searchField ] = this.search_fields[ searchField ].value;
                    }

                    // Make sure we don't send empty strings
                    if (typeof(this.search_fields[ searchField ].value) === 'string') {
                        if (this.search_fields[ searchField ].value.trim().length === 0) {
                            delete searchObj[ searchField ];
                        }
                    }
                }
            });

            if (Object.keys(searchObj).length === 0) {
                this.submitting = false;
                emitAlert('Please capture valid search field values');
                return;
            }

            try {
                const res = await this.$apollo
                                        .query({
                                            query: getOperationalExcellenceCases,
                                            // fetchPolicy: 'network-only',
                                            fetchPolicy: 'no-cache',
                                            errorPolicy: 'all',
                                            variables: {
                                                objectType: 'OPERATIONAL_EXCELLENCE',
                                                pagination: {
                                                    page: 1,
                                                    limit: 1000
                                                },
                                                searchInput: searchObj
                                            },
                                        });
                this.submitting = false;
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }
    
                // this.loans = res.data.getBusinessObject.business_objects;
                eventBus.$emit('CLOSE_CASE_SEARCH_DIALOG', res.data.getBusinessObject.business_objects);
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
    },
    mounted() {
        
    },
    computed: {
        search_field_selected() {
            return (
                this.search_fields.case_number.search ||
                this.search_fields.status.search ||
                this.search_fields.loan_status.search ||
                this.search_fields.quality_assurance.search ||
                this.search_fields.loan_number.search ||
                this.search_fields.next_action_date.search ||
                this.search_fields.elected_monthly_repayment_date.search
            )
        }
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticStyle:{"background":"white","overflow-y":"auto","height":"100%"}},[_c('v-toolbar',{staticStyle:{"border":"1px solid #07ad2d"},attrs:{"dark":"","color":"secondary"}},[_c('v-spacer'),_c('v-toolbar-title',[_c('b',[_vm._v("Add Dependents")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.resetForm()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Reset Form")])]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card',[_c('v-card-text',[_vm._l((_vm.dependents),function(dep){return _c('v-row',{key:dep.id},[_c('v-col',{attrs:{"sm":"12"}},[_c('DependentForm',{attrs:{"dependent":dep,"product":_vm.product,"show_numbering":true}})],1),_c('div',{staticStyle:{"background":"white","height":"10px","width":"100%"}})],1)}),_c('v-row',[_c('v-col',{attrs:{"sm":"10"}}),_c('v-col',{attrs:{"sm":"2"}},[(_vm.dependents.length > 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.removeDependent()}}},on),[_c('v-icon',[_vm._v("remove")])],1)]}}],null,false,1891870343)},[_c('span',[_vm._v("Remove Dependent")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.addDependent()}}},on),[_c('v-icon',[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v("Add Another Dependent")])])],1)],1),(_vm.submitting)?_c('v-card-text',{staticClass:"text-center"},[_vm._v(" Submitting Policy Dependents "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","active":_vm.submitting,"color":"green"}})],1):_vm._e()],2),_c('v-card-actions',[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"large":"","color":"success","disabled":_vm.submitBtnDisabled()},on:{"click":function($event){return _vm.submitDependents()}}},on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("save")])],1)]}}])},[_c('span',[_vm._v("Save Policy Dependent")])])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
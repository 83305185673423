<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Edit Underwriter Banking Details</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row style="background:#eaeaea; padding-bottom:10px">
                        <v-col cols="12" sm="3">
                            <v-select
                                v-model="banking_copy.purpose"
                                :items="banking_types"
                                color="secondary"
                                label="Purpose"
                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-select
                                v-model="banking_copy.bank"
                                :items="banks_list"
                                item-value="_id"
                                item-text="name"
                                color="secondary"
                                label="Bank"
                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-select
                                v-model="banking_copy.account_type"
                                :items="bank_account_types"
                                color="secondary"
                                label="Account Type"
                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                v-model="banking_copy.account_number"
                                color="secondary"
                                label="Account Number"
                                autocomplete="some-random-text2"
                                type="number"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting Underwriter Banking Details Update
                        <v-progress-linear
                            indeterminate
                            :active="submitting"
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submit_disabled()"
                                @click="submitData()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Update Underwriter Banking Details</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { eventBus } from "../../../../main";
import { banking_types, bank_account_types } from "../../../../../config";
import { emitAlert, emitSuccess } from '../../../../utils/api';

import { getBanks } from "../gql/queries";
import { entityProcessUnderwriter } from "../gql/mutations";

export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        underwriter_id: {
            type: String,
            default: null
        },
        banking_original: {
            type: Object,
            required: false,
            default: null,
        },
        banking_copy: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data: () => ({
        server_response_obj: null,
        submitting: false,
        // submit_disabled: true,

        banking_types: banking_types,
        banks_list: [],
        bank_account_types: bank_account_types
    }),
    computed: {
        
    },
    methods: {
        submit_disabled() {
            if (
                this.banking_copy.purpose === this.banking_original.purpose &&
                this.banking_copy.bank === this.banking_original.bank &&
                this.banking_copy.account_type === this.banking_original.account_type &&
                this.banking_copy.account_number.trim() === this.banking_original.account_number
            ) {
                return true;
            }
            
            // Cater for if account number is an empty string
            if (typeof(this.banking_copy.account_number) === 'string') {
                if (
                    this.banking_copy.account_number.trim() === '' &&
                    this.banking_original.account_number === null
                ) {
                    this.banking_copy.account_number = null;
                    return true;
                }
            }
            
            return false;
        },
        closeDialog() {
            eventBus.$emit('CLOSE_EDIT_UNDERWRITER_BANKING_DIALOG', this.server_response_obj);
        },
        resetForm() {
            eventBus.$emit('RESET_BANKING_EDIT_FORM', this.banking_copy);
        },

        async submitData() {
            this.submitting = true;

            try {
                // Payload with old values
                const payload = {
                    purpose: this.banking_copy.purpose,
                    bank: typeof(this.banking_copy.bank) === 'object' ? this.banking_copy.bank._id : this.banking_copy.bank,
                    account_type: this.banking_copy.account_type,
                    account_number: this.banking_copy.account_number
                };

                const payloadObj = {
                    OP_TYPE: 'UPDATE_BANKING',
                    _id: this.underwriter_id,

                    bank_account_details: [
                        {
                            _id: this.banking_original._id,
                            ...payload
                        }
                    ]
                }

                const res = await this.$apollo
                                            .mutate({
                                                mutation: entityProcessUnderwriter,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.entityProcessUnderwriter.errors === true) {
                    emitAlert(res.data.entityProcessUnderwriter.message);
                    this.submitting = false;
                    return;
                }

                this.server_response_obj = res.data.entityProcessUnderwriter.business_object;

                this.closeDialog();
                emitSuccess(res.data.entityProcessUnderwriter.message);

                // Reset fields
                this.server_response_obj = null;
                this.submitting = false;
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.entityProcessUnderwriter.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
    },
    async mounted() {
        this.submitting = true;
        try {
            // const res = await getBanks();
            // if (res.errors === true) {
            //     emitAlert(res.message);
            //     this.submitting = false;
            //     return;
            // }

            const res = await this.$apollo
                                    .query({
                                        query: getBanks,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'BANK',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.submitting = false;
                return;
            }
            
            this.banks_list = res.data.getBusinessObject.business_objects;

            // Reset fields
            this.submitting = false;
        } catch (error) {
            this.submitting = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
            
            return;
        }
    },
    // updated()
    watch: {
        
    }
}
</script>
<style scoped>

</style>
import gql from 'graphql-tag'

export const getProductRuleParameters = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on ProductRuleParameter {
                    __typename
                    _id
                    id
                    level
                    name
                    description
                    object_key
                }
            }
        }
    }
`
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Edit Product VAS</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row style="background:#eaeaea; padding-bottom:10px">
                        <v-col cols="12" sm="6">
                            <v-text-field
                                :value="vas_copy.vas_service.name"
                                label="VAS Service"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                v-model="vas_copy.limit"
                                color="secondary"
                                label="Limit"
                                autocomplete="some-random-text2"
                                type="number"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-select
                                v-model="vas_copy.limit_model"
                                :items="['PER_MONTH', 'PER_ANNUM']"
                                item-text="text"
                                item-value="value"
                                label="Billing Model"
                                color="secondary"
                                required
                            ></v-select>
                        </v-col>
                    </v-row>
                    <!-- Buttons for Adding/Removing VAS -->
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting Product VAS Service Update
                        <v-progress-linear
                            indeterminate
                            :active="submitting"
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submit_disabled()"
                                @click="submitData()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Update Product VAS Service</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../../../utils/api";
import { eventBus } from "../../../../../main";

import { productProcessInsuranceProductPrimary } from "../gql/mutations";

export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        product_id: {
            type: String,
            default: null
        },
        vas_original: {
            type: Object,
            required: false,
            default: null,
        },
        vas_copy: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data: () => ({
        server_response_obj: null,
        submitting: false,
        // submit_disabled: true,
    }),
    computed: {
        
    },
    methods: {
        submit_disabled() {
            if (
                isNaN(this.vas_copy.limit) ||
                parseFloat(this.vas_copy.limit) <= 0 ||
                isNaN(parseFloat(this.vas_copy.limit))
            ) {
                return true;
            }

            if (
                this.vas_copy.limit_model === this.vas_original.limit_model &&
                parseFloat(this.vas_copy.limit) === parseFloat(this.vas_original.limit)
            ) {
                return true;
            }
            return false;
        },
        closeDialog() {
            eventBus.$emit('CLOSE_EDIT_PRODUCT_VAS_DIALOG', this.server_response_obj);
        },
        resetForm() {
            eventBus.$emit('RESET_VAS_EDIT_FORM', this.vas_copy);
        },

        async submitData() {
            this.submitting = true;

            try {
                // Payload with old values
                const payload = {
                    vas_service: this.vas_copy.vas_service._id,
                    limit: parseFloat(this.vas_copy.limit),
                    limit_model: this.vas_copy.limit_model,
                };

                const payloadObj = {
                    OP_TYPE: 'UPDATE_VAS',
                    _id: this.product_id,

                    // Placeholder fields
                    name: '',
                    underwriter_premium: 0,
                    broker_commission: 0,
                    broker_markup: 0,

                    vas_services: [
                        {
                            _id: this.vas_original._id,

                            ...payload
                        }
                    ]
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: productProcessInsuranceProductPrimary,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.productProcessInsuranceProductPrimary.errors === true) {
                    emitAlert(res.data.productProcessInsuranceProductPrimary.message);
                    this.submitting = false;
                    return;
                }


                this.submitting = false;
                this.server_response_obj = res.data.productProcessInsuranceProductPrimary.business_object;
                this.closeDialog();
                emitSuccess(res.data.productProcessInsuranceProductPrimary.message);

                // Reset fields
                this.server_response_obj = null;
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.productProcessInsuranceProductPrimary.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
    },
    mounted() {
        
    },
    // updated()
    watch: {
        
    }
}
</script>
<style scoped>

</style>
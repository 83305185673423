<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Load Bank Statement</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeOnboardingDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-stepper v-model="e1">
                <v-stepper-header>
                    <v-stepper-step :complete="e1 > 1" step="1" color="success">Statement Details</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 2" step="2" color="success">Documents</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="3" color="success">Processing</v-stepper-step>
                </v-stepper-header>
            
                <v-stepper-items>
                    <!-- Basic Details -->
                    <v-stepper-content step="1">
                        <v-card>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-select
                                            v-model="bank_statement_details.fin_calendar_month"
                                            :items="fin_calendar_months_lookup"
                                            color="secondary"
                                            item-text="name"
                                            item-value="_id"
                                            label="Financial Calendar Month"
                                            :disabled="refreshing_fin_calendar_month_list"
            
                                            hint="You can click the refresh icon next to the field if the financial calendar months list is incomplete"
                                            persistent-hint
            
                                            required
                                        >
                                            <!-- Format prepend inner icon -->
                                            <template v-slot:prepend-inner>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon
                                                            v-on="on"
                                                            color="success"
                                                            @click="getFinCalendarMonthsLookupList()"
                                                        >
                                                            mdi-refresh
                                                        </v-icon>
                                                    </template>
                                                    <span>Refresh Fin Calendar List</span>
                                                </v-tooltip>
                                            </template>
                                        </v-select>
                                        <v-progress-linear
                                            color="green"
                                            class="mb-0"
                                            height="4"
                                            indeterminate
                                            :active="refreshing_fin_calendar_month_list"
                                        ></v-progress-linear>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-select
                                            v-model="bank_statement_details.bank_account_name"
                                            :items="['MAIN', 'CORPORATE-BONDS', 'SOFTY-COMP']"
                                            label="Bank Account"
                                            color="secondary"
                                            required
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-progress-linear
                                color="green"
                                class="mb-0"
                                height="10"
                                indeterminate
                                :active="backend_check"
                            ></v-progress-linear>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    fab
                                    color="amber"
                                    :disabled="moveToStep2BtnDisabled()"
                                    @click="moveToStep2()"
                                >
                                    <v-icon large>keyboard_arrow_right</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Documents Section -->
                    <v-stepper-content step="2">
                        <v-card>
                            <v-card-text>
                                <v-row
                                    v-for="file in file_details"
                                    :key="file.id"
                                    style="background:#eaeaea; padding-bottom:10px"
                                >
                                    <v-col cols="12" sm="5">
                                        <v-select
                                            v-model="file.type"
                                            :items="['Bank Statement']"
                                            color="secondary"
                                            item-value="name"
                                            item-text="name"
                                            label="Document Type"
                                            required
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-file-input
                                            v-model="file.file"
                                            color="secondary"
                                            accept="application/pdf, image/*"
                                            label="File/Document"
                                        ></v-file-input>
                                    </v-col>
                                    <v-col cols="12" sm="1">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    fab
                                                    color="amber"
                                                    v-on="on"
                                                    @click="previewFile(file)"
                                                    :disabled="file.file===null || file.file===undefined"
                                                >
                                                    <v-icon>remove_red_eye</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Preview File</span>
                                        </v-tooltip>
                                    </v-col>
                                    <div style="background:white; height: 10px; width: 100%"></div>
                                </v-row>
                                <!-- Buttons for Adding/Removing Contact -->
                                <!-- <v-row>
                                    <v-col sm="10"></v-col>
                                    <v-col sm="2">
                                        <v-tooltip bottom v-if="file_details.length > 1">
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    class="mx-2"
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="removeFile()"
                                                >
                                                    <v-icon>remove</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Remove Document</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="addFile()"
                                                >
                                                    <v-icon>add</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Add Another Document</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row> -->
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="e1 = 1"
                                >
                                    <v-icon large>keyboard_arrow_left</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    large
                                    color="success"
                                    :disabled="submitBankStatementDisabled()"
                                    @click="submitBankStatement()"
                                >
                                    <v-icon large>save</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Submission Results Section -->
                    <v-stepper-content step="3">
                         <v-card light >
                            <v-card-text class="text-center">
                                <v-progress-linear
                                    color="green"
                                    class="mb-0"
                                    height="10"
                                    indeterminate
                                    :active="submitting"
                                ></v-progress-linear>
                            </v-card-text>
                            <v-list flat>
                                <v-subheader>Saving Bank Statement</v-subheader>
                                <v-list-item-group color="primary">
                                    <v-list-item
                                        v-for="(item, i) in backendActionItems"
                                        :key="i"
                                    >
                                        <v-list-item-icon>
                                            <v-icon>done</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ item }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    large
                                    color="success"
                                    @click="closeOnboardingDialog()"
                                >
                                    <v-icon large>done</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>
        <DocPreviewDialog
            :dialog="open_doc_preview_dialog"
            :url="file_url"
            :doc_type="doc_type"
            @CLOSE_DOC_PREVIEW_DIALOG="closeDocPreviewDialog()"
        />
    </v-row>
</template>
<script>
import DocPreviewDialog from '../../../../components/doc_preview';
import ObjectID from "bson-objectid";

import { submitDocumentumFile, emitAlert, emitSuccess } from "../../../../utils/api";
import { eventBus } from "../../../../main";
import {
    document_types,
    address_types,
    contact_types,
    banking_types,
    bank_account_types,
} from "../../../../../config";

import { getBanks, getFinancialCalendarMonths } from "../gql/queries";
import { businessProcessManagementAccounts } from "../gql/mutations";

export default {
    components: {
        DocPreviewDialog,
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        backend_check: false,
        submitting: false,
        backendActionItems: [],
        server_response_obj: null,

        file: '',

        // File Preview Dialog
        file_url: null,
        doc_type: null,

        transaction_date_menu: false,

        e1: 1,
        bank_statement_details: {
            fin_calendar_month: null,
            bank_account_name: null,
        },
        file_details: [
            {
                id: 1,
                type: 'Bank Statement',
                file: null,
                valid: false,
            }
        ],

        open_doc_preview_dialog: false,

        address_types: address_types,
        contact_types: contact_types,
        banking_types: banking_types,
        banks_list: [],
        bank_account_types: bank_account_types,
        document_types: document_types.filter(doc_type => { return doc_type.type === 'bank_statement' }),

        documents_map: {},

        name_rules: [
            v => !!v || 'Name is required',
            v => v.trim().length >= 2 || 'Min 2 characters'
        ],

        fin_calendar_months_lookup: [],
        refreshing_fin_calendar_month_list: false
    }),
    methods: {
        closeOnboardingDialog() {
            eventBus.$emit('CLOSE_ONBOARDING_DIALOG', this.server_response_obj);
        },
        resetForm() {
            this.e1 = 1;
            this.server_response_obj = null;
            this.submitting = false;

            this.documents_map = {};

            this.bank_statement_details = {
                fin_calendar_month: null,
                bank_account_name: null,
            };

            this.file_details = [
                {
                    id: 1,
                    type: 'Bank Statement',
                    file: null,
                    valid: false,
                }
            ];
        },

        //--------------------ADD/REMOVE FILES--------------------------//
        addFile() {
            const files_size = this.file_details.length;

            this.file_details.push({
                id: files_size + 1,
                type: null,
                file: null
            });
        },
        removeFile() {
            this.file_details.pop();
        },

        previewFile(fileObject) {
            // Set file oobject so we can trigger the readAsDataURL function
            const reader = new FileReader();

            reader.addEventListener("load", () => {
                // Set PDF-Viewer URL to the selected file Base64 String
                this.file_url = reader.result;
                if (fileObject.file.type === 'application/pdf') {
                    this.doc_type = 'pdf';
                } else {
                    this.doc_type = 'image';
                }

                // Display the preview dialog
                this.open_doc_preview_dialog = true;
            }, false);

            reader.readAsDataURL(fileObject.file);
        },
        closeDocPreviewDialog() {
            this.open_doc_preview_dialog = false;
        },

        moveToStep2BtnDisabled() {
            if (this.bank_statement_details.fin_calendar_month === null) {
                return true;
            }
            if (this.bank_statement_details.bank_account_name === null) {
                return true;
            }
            return false;
        },
        async moveToStep2() {
            
            // this.backend_check = true;
            // // Check if a statement for this has not been uploaded
            // this.backend_check = false;

            this.e1 = 2;

            // try {
            //     // Get scheme policies
            //     const res = await this.$apollo
            //                         .query({
            //                             query: getSchemePolicies,
            //                             // fetchPolicy: 'network-only',
            //                             fetchPolicy: 'no-cache',
            //                             errorPolicy: 'all',
            //                             variables: {
            //                                 objectType: 'POLICY',
            //                                 pagination: {
            //                                     page: 1,
            //                                     limit: 100
            //                                 },
            //                                 searchInput: {
            //                                     scheme: this.bank_statement_details.scheme._id
            //                                 }
            //                             },
            //                         });
            //     this.backend_check = false;

            //     if (res.data.getBusinessObject.errors === true) {
            //         emitAlert(res.data.getBusinessObject.message);
            //         this.submitting = false;
            //         return;
            //     }

            //     const schemePolicies = res.data.getBusinessObject.business_objects;
            //     // Move to next page
            //     this.e1 = 2;
            // } catch (error) {
            //     this.submitting = false;
            //     console.log(error);
            //     if (error !== undefined) {
            //         emitAlert(error.data.getBusinessObject.message);
            //     } else {
            //         emitAlert('The server has taken too long in responding to your request');
            //     }
                
            //     return;
            // }
        },

        submitBankStatementDisabled() {
            if (this.file_details[0].file === null) {
                return true;
            }
            return false;
        },

        async submitBankStatement() {
            this.e1 = 3;

            this.submitting = true;
            const statementObj = this.extractStatementInfo();

            try {
                this.backendActionItems.push("Saving statement info");
                const payloadObj = {
                    OP_TYPE: 'UPLOAD_BANK_STATEMENT',
                    ...statementObj
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcessManagementAccounts,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.businessProcessManagementAccounts.errors === true) {//businessProcessManagementAccounts
                    emitAlert(res.data.businessProcessManagementAccounts.message);
                    this.submitting = false;
                    this.backendActionItems = [];
                    return;
                }

                this.backendActionItems.push("Saving bank statement info succeeded");


                // Now upload documents to documentum
                const bankStatementId = res.data.businessProcessManagementAccounts.business_objects[0]._id;

                if (this.file_details.filter(file => { return file.valid === true; }).length > 0) {
                    this.backendActionItems.push("Saving bank statement document");
                }
                
                for (const file of this.file_details) {
                    if (file.valid) {
                        const doc = this.documents_map[ file.id ];

                        const uploadRes = await submitDocumentumFile(file.id, file.file, 'bank_statement', bankStatementId);
                        if (uploadRes.errors === true) {
                            emitAlert(uploadRes.message);
                            this.submitting = false;
                            this.backendActionItems.push(`  --> Uploading ${doc.name} failed`);
                            return;
                        } else {
                            // Trigger bank statement processing
                            const payloadObj = {
                                OP_TYPE: 'PROCESS_BANK_STATEMENT',
                                _id: bankStatementId
                            };
                            const res = await this.$apollo
                                                        .mutate({
                                                            mutation: businessProcessManagementAccounts,
                                                            variables: {
                                                                input: payloadObj
                                                            },
                                                        });
                            if (res.data.businessProcessManagementAccounts.errors === true) {//businessProcessManagementAccounts
                                emitAlert(res.data.businessProcessManagementAccounts.message);
                                this.submitting = false;
                                this.backendActionItems = [];
                                return;
                            }
                        }
                        
                        this.backendActionItems.push(`  --> Uploading ${doc.name} succeeded`);
                    }
                }

                this.server_response_obj = res.data.businessProcessManagementAccounts.business_objects[0];
                this.closeOnboardingDialog();
                emitSuccess(res.data.businessProcessManagementAccounts.message);
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessManagementAccounts.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
        extractStatementInfo() {
            const statementObj = {
                bank_account_name: null,
                finance_month: null,
            };

            statementObj.bank_account_name = this.bank_statement_details.bank_account_name;
            statementObj.finance_month = this.bank_statement_details.fin_calendar_month;

            // ----------------------------------Extract documents if there are any
            const documents = [];
            this.file_details.forEach(file => {
                if ((file.file !== null) && (file.type !== null)) {
                    const objID = ObjectID();

                    file.id = objID.toString();
                    file.valid = true;

                    documents.push({
                        _id: objID, //Generate mngodb id
                        type: file.type,
                        name: file.file.name,
                        mime_type: file.file.type,
                    });

                    this.documents_map[objID.toString()] = {
                        _id: objID, //Generate mngodb id
                        type: file.type,
                        name: file.file.name,
                        mime_type: file.file.type,
                    };
                }

                statementObj.documents = documents;
            });

            return statementObj;
        },

        async getFinCalendarMonthsLookupList() {
            this.refreshing_fin_calendar_month_list = true;
            try {
                // Load Opened Fin Calendar Months
                const res = await this.$apollo
                                    .query({
                                        query: getFinancialCalendarMonths,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'FINANCE_CALENDAR_MONTH',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            },
                                            searchInput: {
                                                // status: 'OPEN'
                                            }
                                        },
                                    });
                this.refreshing_fin_calendar_month_list = false;
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }

                if (res.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }
                this.fin_calendar_months_lookup = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.refreshing_fin_calendar_month_list = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
    },
    async mounted() {
        await this.getFinCalendarMonthsLookupList();

        this.submitting = true;
        try {
            const res = await this.$apollo
                                    .query({
                                        query: getBanks,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'BANK',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.submitting = false;
                return;
            }
            
            this.banks_list = res.data.getBusinessObject.business_objects;

            // Reset fields
            this.submitting = false;
        } catch (error) {
            this.submitting = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
            
            return;
        }
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
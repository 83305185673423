<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Document Info</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeViewDocumentDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <div v-if="document !== null">
                        <v-row>
                            <v-col cols="12" sm="6" v-if="document !== null">
                                <v-text-field
                                    v-model="document.type"
                                    label="Document Type"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="document !== null">
                                <v-text-field
                                    v-model="document.name"
                                    label="Document Name"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="document !== null && document.created">
                                <v-text-field
                                    :value="document.created.split('T')[0] + '   ' + document.created.split('T')[1].substr(0, 8)"
                                    label="Created At"
                                    disabled
                                    required
                                ></v-text-field>
                                <!-- <v-text-field
                                    v-else
                                    :value="''"
                                    label="Created At"
                                    disabled
                                    required
                                ></v-text-field> -->
                            </v-col>
                            <v-col cols="12" sm="6" v-if="document !== null && (document.captured_by !== null && document.captured_by !== undefined)">
                                <v-text-field
                                    v-if="document.captured_by.entity !== null"
                                    :value="document.captured_by.entity.name + ' ' + document.captured_by.entity.surname"
                                    label="Captured By"
                                    disabled
                                    required
                                ></v-text-field>
                                <v-text-field
                                    v-else
                                    :value="'N/A'"
                                    label="Captured By"
                                    disabled
                                    required
                                ></v-text-field>
                                <!-- <v-text-field
                                    v-else
                                    value="Pule Kenneth Mojanaga"
                                    label="Captured By"
                                    disabled
                                    required
                                ></v-text-field> -->
                            </v-col>
                            <v-col cols="12" sm="6" v-if="document !== null">
                                <v-text-field
                                    :value="document.deleted ? 'Yes' : 'No'"
                                    label="Deleted"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="document.deleted">
                                <v-text-field
                                    v-if="document !== null && (document.deleted_by !== null && document.deleted_by !== undefined)"
                                    :value="document.deleted_by.entity.name + ' ' + document.deleted_by.entity.surname"
                                    label="Deleted By"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="document.deleted">
                                <v-text-field
                                    v-if="document !== null"
                                    v-model="document.delete_reason"
                                    label="Delete Reason"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <!-- <div style="background:white; height: 10px; width: 100%"></div> -->
                        </v-row>
                    </div>
                    <v-divider></v-divider>
                    <!-- Progress Section -->
                    <v-row
                        v-if="downloading"
                        align-content="center"
                        justify="center"
                    >
                        <v-col class="text-subtitle-1 text-center" cols="12">
                            Downloading document from server...
                        </v-col>
                        <v-col cols="6">
                            <v-progress-linear
                                color="green"
                                indeterminate
                                rounded
                                height="6"
                            ></v-progress-linear>
                        </v-col>
                    </v-row>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                color="amber"
                                v-on="on"
                                @click="previewServerFile()"
                            >
                                <v-icon>remove_red_eye</v-icon>
                            </v-btn>
                        </template>
                        <span>Preview File From Server</span>
                    </v-tooltip>
                    <!-- <v-btn
                        fab
                        color="primary"
                        @click="closeViewDocumentDialog()"
                    >
                        <v-icon large>done</v-icon>
                    </v-btn> -->
                </v-card-actions>
            </v-card>
        </v-dialog>
        <DocPreviewDialog
            :dialog="open_doc_preview_dialog"
            :url="file_url"
            :doc_type="doc_type"
            @CLOSE_DOC_PREVIEW_DIALOG="closeDocPreviewDialog()"
        />
    </v-row>
</template>
<script>
import DocPreviewDialog from '../doc_preview';
import { getDocumentumFile } from "../../utils/api";
import { eventBus } from "../../main";
export default {
    components: {
        DocPreviewDialog
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        document: {
            type: Object,
            required: false,
            default: () => {
                return {
                    type: null,
                    name: null,
                    mime_type: null,
                    created: null,
                    deleted: null,
                    deleted_by: null,
                    delete_reason: null,
                }
            }
        },
        param_path: {
            type: String,
            required: false,
            default: ''
        },
        param_id: {
            type: String,
            required: false,
            default: ''
        }
    },
    data: () => ({
        server_resp_obj: null,

        // File props
        file: '',
        file_url: null,
        doc_type: null,
        open_doc_preview_dialog: false,

        downloading: false,
        
    }),
    methods: {
        closeViewDocumentDialog() {
            eventBus.$emit('CLOSE_VIEW_DOCUMENT_DIALOG', this.server_resp_obj);
        },
        closeDocPreviewDialog() {
            this.open_doc_preview_dialog = false;
        },

        previewServerFile() {
            this.downloading = true;
            const file_path = `${this.param_path}/${this.param_id}/${this.document._id}`;
            
            getDocumentumFile(file_path)
                .then(res => {
                    this.downloading = false;
                    // // this.file_url = `/api/v1/documentum/${document_id}`;
                    // this.file_url = `/v1/documentum/?path=${file_path}`;
                    this.doc_type = 'image';
                    if (res.headers['content-type'] === 'application/pdf') {
                        this.doc_type = 'pdf';
                    }

                    this.file_url = window.URL.createObjectURL(new Blob([res.data], {type: res.headers['content-type']}));

                    // prepare base64 string
                    // this.file_url =  `data:${res.data['content_type']};base64,` + res.data.base64;
                    
                    this.open_doc_preview_dialog = true;
                })
                .catch(error => {
                    this.downloading = false;
                    console.log('Error whilst downloading file. Details: ', error);
                });
        },
        
        
        //--------------------PREVIEW FILES--------------------------//
        previewFile(fileObject) {
            // Set file oobject so we can trigger the readAsDataURL function
            const reader = new FileReader();

            reader.addEventListener("load", () => {
                // Set PDF-Viewer URL to the selected file Base64 String
                this.file_url = reader.result;
                if (fileObject.file.type === 'application/pdf') {
                    this.doc_type = 'pdf';
                } else {
                    this.doc_type = 'image';
                }

                // Display the preview dialog
                this.open_doc_preview_dialog = true;
            }, false);

            reader.readAsDataURL(fileObject.file);
        },
    },
    mounted() {
        
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
<template>
    <div>
        <v-toolbar
            color="secondary"
            dark
            class="pa-0"
            height="50"
            extension-height="3"
        >
            <v-row>
                <!-- Grid Buttons -->
                <v-col sm="5" style="margin-top:10px">
                    <v-tooltip bottom v-if="SEARCH_MODE === false">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                @click="loadMoreData()"
                                v-on="on"
                            >
                                <v-icon>cloud_download</v-icon>
                            </v-btn>
                        </template>
                        <span>Load More Data</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="openSearchPolicyDialog()"
                            >
                                <v-icon>search</v-icon>
                            </v-btn>
                        </template>
                        <span>Search for policies</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="SEARCH_MODE === false">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="refreshPolicies()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh</span>
                    </v-tooltip>
                </v-col>

                <!-- Search Result Indicator -->
                <v-col sm="3">
                    <v-card-actions v-if="SEARCH_MODE === true">
                        <h3>Search Results</h3>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="clearSearchResults()"
                                    v-on="on"
                                >
                                    <v-icon small>search</v-icon>
                                    <v-icon small>close</v-icon>
                                </v-btn>
                            </template>
                            <span>Clear Search Results</span>
                        </v-tooltip>
                    </v-card-actions>
                </v-col>

                <!-- Action Buttons -->
                <v-col sm="4" style="margin-top:10px">
                    <!-- <v-spacer></v-spacer> -->
                    <div class="text-right">
                        <!-- VALIDATE DRAFTED POLICY -->
                        <v-tooltip
                            bottom
                            v-if="(
                                selectedPolicy !== null &&
                                (
                                    selectedPolicy.status === POLICY_STATUSES.ACTIVE
                                )
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="primary white--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="openViewPolicyCertificateDialog(selectedPolicy._id)"
                                >
                                    <v-icon medium>mdi-bookmark-check</v-icon>
                                </v-btn>
                            </template>
                            <span>Get Policy Certificate</span>
                        </v-tooltip>

                        <!-- GET DEBIT ORDER STATUS -->
                        <v-tooltip
                            bottom
                            v-if="(
                                selectedPolicy !== null &&
                                (
                                    selectedPolicy.status === POLICY_STATUSES.ACTIVE &&
                                    selectedPolicy.payment_information.premium_payer.payment_method === 'DEBIT ORDER'
                                )
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber primary--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="openViewDebitOrderStatus()"
                                >
                                    <v-icon medium>mdi-cash-100</v-icon>
                                </v-btn>
                            </template>
                            <span>Get Debit Order Status</span>
                        </v-tooltip>

                        <!-- RUN ALLOCATION PROCESS FOR POLICY -->
                        <v-tooltip
                            bottom
                            v-if="(
                                selectedPolicy !== null &&
                                (
                                    isCentralUnderwriterAdminUser ||
                                    isPolicyAdministrator
                                ) &&
                                (
                                    selectedPolicy.status === POLICY_STATUSES.ACTIVE ||
                                    selectedPolicy.status === POLICY_STATUSES.LAPSABLE
                                )
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="success white--text"
                                    class="mx-2"
                                    v-on="on"
                                    :disabled="data_table_loading === true"
                                    @click="runAllocationProcessForPolicy()"
                                >
                                    <v-icon medium>mdi-download</v-icon> Run Allocation Process
                                </v-btn>
                            </template>
                            <span>Run Allocation Process</span>
                        </v-tooltip>
                        
                        <!-------------------------------------------------------------------------------->
                        <!----------------------------------LIFE CYCLE BUTTONS---------------------------->
                        <!-------------------------------------------------------------------------------->


                        <!-- VALIDATE DRAFTED POLICY -->
                        <!-- PENDING POLICY - DECLINE -->
                        <!-- <v-tooltip bottom v-if="selectedPolicy !== null && selectedPolicy.status === POLICY_STATUSES.PENDING">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="red white--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="openDeclinePendingPolicyDialog()"
                                >
                                    <v-icon medium>mdi-close-circle-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Decline Pending Policy</span>
                        </v-tooltip> -->

                        <!-- LAPSED - REINSTATE -->
                        <!-- <v-tooltip bottom v-if="selectedPolicy !== null && selectedPolicy.status === POLICY_STATUSES.LAPSED">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="blue black--text"
                                    class="mx-2"
                                    v-on="on"
                                >
                                    <v-icon medium>mdi-checkbox-marked-circle-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Reinstate Lapsed Policy</span>
                        </v-tooltip> -->
                    </div>
                </v-col>
            </v-row>
        </v-toolbar>
        <multipane class="horizontal-panes" layout="horizontal" style="height: 100%" @paneResize="paneResizing">
            <div class="pane" :style="{ height: '50%', maxHeight: '50%'}">
                <v-data-table
                    fixed-header
                    :height="main_tab_height"
                    :headers="policy_details_headers"
                    :items="policies"
                    item-key="policy_number"
                    class="elevation-1"
                    :single-select="true"
                    @click:row="selectMainTableRow"
                    item-class="secondary"
                    hide-default-footer
                    :loading="data_table_loading"
                    :disable-pagination="true"
                >
                    <!-- Display Formated capture date -->
                    <template v-slot:item.principal_member="{ item }">
                        <td>{{item.principal_member.name + ' ' + item.principal_member.surname + ' (' + item.principal_member.id_number + ')'}}</td>
                    </template>
                    <!-- Display Formated capture date -->
                    <template v-slot:item.underwriter_policy_number="{ item }">
                        <td>{{item.underwriter_policy_number !== POLICY_STATUSES.DRAFT ? item.underwriter_policy_number : null}}</td>
                    </template>
                    <!-- Display Formated capture date -->
                    <template v-slot:item.created="{ item }">
                        <td>{{item.created.split('T')[0]}}</td>
                    </template>
                    <!-- Display Formated effective date -->
                    <template v-slot:item.effective_date="{ item }">
                        <td>{{item.effective_date !== null ? item.effective_date.split('T')[0] : ''}}</td>
                    </template>
                    <!-- Display Formated end date -->
                    <template v-slot:item.end_date="{ item }">
                        <td>{{item.end_date !== null ? item.end_date.split('T')[0] : ''}}</td>
                    </template>
                    <!-- Display Formated agent -->
                    <template v-slot:item.agent="{ item }">
                        <td>
                            {{
                                item.agent !== null && item.agent !== undefined ? 
                                item.agent.entity.name + ' ' + item.agent.entity.surname + ' (' + item.agent.agent_number + ')':
                                ''}}</td>
                    </template>
                    <!-- Product premium -->
                    <template v-slot:item.premium="{ item }" >
                        <td>R {{ item.premium }}</td>
                    </template>
                    <!-- Total Cover Amount -->
                    <template v-slot:[`item.total_cover_amount`]="{ item }" >
                        <td>R {{ formatNumber(item.total_cover_amount) }}</td>
                    </template>
                    <!-- Display Formated payment method -->
                    <template v-slot:item.payment_information.premium_payer.payment_method="{ item }">
                        <td>{{item.payment_information.premium_payer.payment_method}}</td>
                    </template>
                    <!-- Display Formated Scheme-Level Payment -->
                    <template v-slot:item.scheme_level_payment="{ item }">
                        <td>{{item.scheme_level_payment === true ? 'Yes' : 'No'}}</td>
                    </template>

                    <!-- Display Formated high_risk -->
                    <template v-slot:item.high_risk="{ item }">
                        <td v-if="item.high_risk === 'NO'" style="background:green; color:white; font-weight:bold; font-size:20px; border: 1px solid white">{{item.high_risk}}</td>
                        <td v-else style="background:red; color:white; font-weight:bold; font-size:20px; border: 1px solid white">{{item.high_risk}}</td>
                    </template>
                </v-data-table>
            </div>
            <multipane-resizer></multipane-resizer>
            <div class="pane tab-nav" :style="{ height: '30%', maxHeight: '50%', minHeight: '30%' }">
                <v-tabs
                    v-model="sub_tabs"
                    color="transparent"
                    class="tab-nav"
                    hide-slider
                    height="35px"
                    grow
                >
                    <!-- Members Sub Tab -->
                    <template>
                        <v-tab key="policy_members_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Members
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Payment Details Sub Tab -->
                    <template>
                        <v-tab key="payment_details_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Payment Arrangement
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Transactions History Sub Tab -->
                    <template>
                        <v-tab key="transactions_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Transactions History
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Applications Sub Tab -->
                    <template>
                        <v-tab key="applications_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Changes
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Documents Sub Tab -->
                    <template>
                        <v-tab key="documents_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Documents
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Installment Schedule Sub Tab -->
                    <template>
                        <v-tab key="installment_schedule_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Installment Schedule
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>
                    <!-- Subsidy Sub Tab -->
                    <!-- <template>
                        <v-tab key="subsidy_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Subsidies
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template> -->
                </v-tabs>
                <v-tabs-items v-model="sub_tabs">
                    <!-- Sub-Tab Toolbar -->
                    <v-toolbar
                        color="secondary"
                        dark
                        class="pa-0"
                        height="50"
                        extension-height="3"
                    >
                        <div v-if="selectedPolicy !== null && selectedPolicyMember !== null">
                            <!-- <v-tooltip
                                bottom
                                v-if="(
                                    sub_tabs === 0  &&
                                    selectedPolicy.status === POLICY_STATUSES.ACTIVE &&
                                    selectedPolicyMember !== null &&
                                    selectedPolicyMember.end_date === null &&
                                    selectedPolicyMember.role !== 'Premium Payer'
                                )"> -->
                            <!-- <v-tooltip
                                bottom
                                v-if="(
                                    sub_tabs === 0  &&
                                    selectedPolicyMember !== null &&
                                    selectedPolicyMember.end_date === null &&
                                    selectedPolicyMember.role !== 'Premium Payer'
                                )">
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        color="secondary"
                                        small
                                        v-on="on"
                                        @click="openUpdateMemberUndersriterStatusDialog()"
                                    >
                                        <v-icon>mdi-circle-edit-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Update Underwriter Cover Status</span>
                            </v-tooltip> -->
                        </div>
                        <!-- do an actual delete on policies with DRAFT status  -->
                        <div v-if="selectedPolicy !== null && selectedPolicyMember !== null">
                            <v-tooltip bottom v-if="sub_tabs === 0  && selectedPolicy.status === POLICY_STATUSES.DRAFT && selectedPolicyMember !== null">
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        color="secondary"
                                        small
                                        v-on="on"
                                        @click="removeDraftedPolicyMember()"
                                    >
                                        <v-icon>delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>Remove Policy Member</span>
                            </v-tooltip>
                        </div>

                        <!-- Payment Details Sub-Tab Buttons -->
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 1 &&
                                selectedPolicy !== null &&
                                selectedPolicy.scheme !== null &&
                                selectedPolicy.payment_information.premium_payer.payment_method !== 'DEBIT ORDER' &&
                                (
                                    selectedPolicy.status === POLICY_STATUSES.LAPSABLE ||
                                    selectedPolicy.status === POLICY_STATUSES.ACTIVE
                                )
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="amber black--text"
                                    small
                                    v-on="on"
                                    @click="updatePaymentArrangementDetails()"
                                >
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <span>Update Payment Arrangement Details</span>
                        </v-tooltip>

                        <!-- Transactions History Sub-Tab-Buttons -->
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 2 &&
                                selectedPolicy !== null &&
                                (
                                    selectedPolicy.status === POLICY_STATUSES.ACTIVE ||
                                    selectedPolicy.status === POLICY_STATUSES.LAPSABLE
                                )
                        
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddPremiumPaymentEventDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Capture Payment Event</span>
                        </v-tooltip>
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 2 &&
                                selectedPolicy !== null
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="amber black--text"
                                    small
                                    @click="refreshPolicyTransactions()"
                                    v-on="on"
                                >
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>Refresh Payment Events</span>
                        </v-tooltip>
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 2 &&
                                selectedTransaction !== null
                            )"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    style="margin-left: 20px;"
                                    color="red white--text"
                                    small
                                    @click="reverseTransaction()"
                                    v-on="on"
                                >
                                <v-icon>mdi-arrow-u-left-top</v-icon> Reverse Transaction
                                </v-btn>
                            </template>
                            <span>Reverse Transaction</span>
                        </v-tooltip>

                        <!-- Show following buttons only for the DOCUMENTS sub-tab -->
                        <!-- <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 4 && selectedPolicy !== null
                            )"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddDocumentsDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Signed Form</span>
                        </v-tooltip> -->
                        <v-tooltip bottom v-if="sub_tabs === 4  && selectedDocument !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    v-on="on"
                                    @click="openViewDocumentDialog()"
                                >
                                    <v-icon>content_paste</v-icon>
                                </v-btn>
                            </template>
                            <span>View Document Info</span>
                        </v-tooltip>

                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 5 &&
                                selectedPolicy !== null
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="amber black--text"
                                    small
                                    @click="refreshInstallmentSchedules()"
                                    v-on="on"
                                >
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>Refresh Installment Schedules</span>
                        </v-tooltip>

                        <!-- 
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 6 &&
                                selectedPolicy !== null
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="primary white--text"
                                    small
                                    @click="openAddPolicySubsidyDialog()"
                                    v-on="on"
                                >
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Subsidy</span>
                        </v-tooltip>
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 6 &&
                                selectedPolicy !== null
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="amber black--text"
                                    small
                                    @click="refreshPolicySubsidies()"
                                    v-on="on"
                                >
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>Refresh Policy Subsidies</span>
                        </v-tooltip>
                         -->
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <!-- Policy Members Sub-Tab Content -->
                    <v-tab-item key="policy_members_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="policy_member_details_headers"
                            :items="policy_members"
                            item-key="_id"
                            class="elevation-1"
                            :single-select="true"
                            @click:row="selectPolicyMemberTabRow"
                            item-class="secondary"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Display Formated capture date -->
                            <template v-slot:item.created="{ item }">
                                <td>{{item.created.split('T')[0]}}</td>
                            </template>
                            <!-- Display Formated start_date -->
                            <template v-slot:item.start_date="{ item }">
                                <td>{{item.start_date !== null ? item.start_date.split('T')[0] : null}}</td>
                            </template>
                            <!-- Display Formated end date -->
                            <template v-slot:item.end_date="{ item }">
                                <td>{{item.end_date !== null ? item.end_date.split('T')[0] : null}}</td>
                            </template>
                            <!-- Display Formated age_inception -->
                            <template v-slot:item.age_inception="{ item }">
                                <td v-if="item.age_inception < 75">{{item.age_inception}}</td>
                                <td v-else style="background:red; color:white; font-weight:bold; font-size:20px; border: 1px solid white">{{item.age_inception}}</td>
                            </template>
                            <!-- Display Formated age_current -->
                            <template v-slot:item.age_current="{ item }">
                                <td v-if="item.age_current < 75">{{item.age_current}}</td>
                                <td v-else style="background:red; color:white; font-weight:bold; font-size:20px; border: 1px solid white">{{item.age_current}}</td>
                            </template>
                            <!-- Display Formated covered_by_underwriter -->
                            <template v-slot:item.covered_by_underwriter="{ item }">
                                <td v-if="item.role === 'Premium Payer'">
                                    N/A
                                </td>
                                <td
                                    v-else-if="item.covered_by_underwriter === true"
                                    style="background:green; color:white; font-weight:bold; border: 1px solid white"
                                >
                                    YES
                                </td>
                                <td
                                    v-else
                                    style="background:red; color:white; font-weight:bold; border: 1px solid white"
                                >
                                    NO
                                </td>
                            </template>
                            <!-- Cover Amount -->
                            <template v-slot:[`item.cover_amount`]="{ item }" >
                                <td>R {{ formatNumber(item.cover_amount) }}</td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Transactions History Sub-Tab Content -->
                    <v-tab-item key="payment_details_sub_tab">
                        <v-card
                            :height="sub_tab_height"
                        >
                            <v-card-text >
                                <!-- PREMIUM PAYER -->
                                <v-row v-if="selectedPolicy !== null">
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            value="PREMIUM_PAYER"
                                            label="Payer Type"
                                            color="secondary"
                                            disabled
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            v-model="selectedPolicy.payment_information.premium_payer.payment_method"
                                            hint="Payment method"
                                            persistent-hint
                                            disabled
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" >
                                        <v-text-field
                                            v-model="selectedPolicy.payment_information.premium_payer.percentage_amount"
                                            prefix="%"
                                            color="secondary"
                                            label="Percentage Amount"
                                            type="number"
                                            disabled
                                            required
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <!-- Scheme -->
                                <v-row v-if="selectedPolicy !== null">
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            value="SCHEME"
                                            label="Payer Type"
                                            color="secondary"
                                            disabled
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            v-model="selectedPolicy.payment_information.scheme.payment_method"
                                            hint="Payment method"
                                            persistent-hint
                                            disabled
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" >
                                        <v-text-field
                                            v-model="selectedPolicy.payment_information.scheme.percentage_amount"
                                            prefix="%"
                                            color="secondary"
                                            label="Percentage Amount"
                                            type="number"
                                            disabled
                                            required
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <!-- </div> -->
                    </v-tab-item>
                    <!-- Transactions History Sub-Tab Content -->
                    <v-tab-item key="transactions_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="transactions_headers"
                            :items="transactions"
                            item-key="_id"
                            class="elevation-1"
                            :single-select="true"
                            @click:row="selectTransactionTabRow"
                            item-class="secondary"
                            hide-default-footer
                            :loading="transactions_loading"
                            :disable-pagination="true"
                        >
                            <!-- Display create date -->
                            <template v-slot:item.transaction_date="{ item }">
                                <td>{{item.transaction_date.split('T')[0]}}</td>
                            </template>
                            <!-- Display Formated created date -->
                            <template v-slot:item.created="{ item }">
                                <td>{{item.created !== null ? item.created.split('T')[0] + '  @  ' + item.created.split('T')[1].substr(0,8) : ''}}</td>
                            </template>
                            <!-- Display Formated amount -->
                            <template v-slot:item.amount="{ item }">
                                <td>R {{item.amount}}</td>
                            </template>
                            <!-- Display Formated balance -->
                            <template v-slot:item.balance="{ item }">
                                <td>R {{item.balance}}</td>
                            </template>
                            <!-- Display Formated Bank Feed -->
                            <template v-slot:item.bank_feed="{ item }">
                                <td v-if="item.to._id === selectedPolicy._id">{{item.bank_feed === null ? 'NO' : 'YES'}}</td>
                                <td v-else></td>
                            </template>
                            <!-- User who created the details -->
                            <template v-slot:item.created_by="{ item }" >
                                <td v-if="item.created_by !== null">
                                    <span v-if="item.created_by.entity !== null">
                                        {{item.created_by.entity.name}} {{item.created_by.entity.surname}}
                                    </span>
                                    <span v-else>N/A</span>
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Applications Sub-Tab Content -->
                    <v-tab-item key="applications_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="applications_headers"
                            :items="applications"
                            item-key="_id"
                            class="elevation-1"
                            :single-select="true"
                            @click:row="tobeDefined"
                            item-class="secondary"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                        </v-data-table>
                    </v-tab-item>
                    <!-- Documents Sub-Tab Content -->
                    <v-tab-item key="documents_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="document_details_headers"
                            :items="policy_documents"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectDocumentsTabRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Display create date -->
                            <template v-slot:item.created="{ item }">
                                <td>{{item.created.split('T')[0]}}</td>
                            </template>
                            <!-- User who captured the details -->
                            <template v-slot:item.captured_by="{ item }" >
                                <td v-if="item.captured_by !== null">
                                    {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Installment Schedule Sub-Tab Content -->
                    <v-tab-item key="installment_schedule_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="installment_schedule_headers"
                            :items="installment_schedules"
                            item-key="_id"
                            class="elevation-1"
                            :single-select="true"
                            @click:row="selectInstallmentScheduleTabRow"
                            item-class="secondary"
                            hide-default-footer
                            :loading="installment_schedules_loading"
                            :disable-pagination="true"
                        >
                            <!-- Display Formated deadline_date: false, -->
                            <template v-slot:item.deadline_date="{ item }">
                                <td>{{item.deadline_date.split('T')[0]}}</td>
                            </template>

                            <!-- Display Formated premium_underwriter -->
                            <template v-slot:item.premium_underwriter="{ item }">
                                <td>
                                    R {{item.premium_underwriter}} / <b>{{item.premium_underwriter_target}}</b>

                                    <span v-if="item.premium_underwriter_target > 0">
                                        <span v-if="item.premium_underwriter === item.premium_underwriter_target"><v-icon color="success">mdi-check</v-icon></span>
                                        <span v-else><v-icon color="red">mdi-close</v-icon></span>
                                    </span>
                                    <span v-else>
                                        <v-icon color="success">mdi-minus-circle-outline</v-icon>
                                    </span>
                                </td>
                            </template>
                            <!-- Display Formated premium_broker_markup -->
                            <template v-slot:item.premium_broker_markup="{ item }">
                                <td>
                                    R {{item.premium_broker_markup}} / <b>{{item.premium_broker_markup_target}}</b>

                                    <span v-if="item.premium_broker_markup_target > 0">
                                        <span v-if="item.premium_broker_markup === item.premium_broker_markup_target"><v-icon color="success">mdi-check</v-icon></span>
                                        <span v-else><v-icon color="red">mdi-close</v-icon></span>
                                    </span>
                                    <span v-else>
                                        <v-icon color="success">mdi-minus-circle-outline</v-icon>
                                    </span>
                                </td>
                            </template>
                            <!-- Total Expected -->
                            <template v-slot:item.premium_total_expected="{ item }">
                                <v-chip
                                    color="green"
                                    dark
                                >
                                    R {{ item.premium_total_expected }}
                                </v-chip>
                            </template>
                            <!-- Total collected -->
                            <template v-slot:item.premium_total_collected="{ item }">
                                <v-chip
                                    :color="item.premium_total_expected === item.premium_total_collected ? 'green' : 'red'"
                                    dark
                                >
                                    R {{ item.premium_total_collected }}
                                </v-chip>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Subsidies Sub-Tab Content -->
                    <v-tab-item key="subsidy_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="subsidy_details_headers"
                            :items="policy_subsidies"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectSubsidyTabRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Display create date -->
                            <template v-slot:item.created="{ item }">
                                <td>{{item.created.split('T')[0]}}</td>
                            </template>
                            <!-- User who captured the details -->
                            <template v-slot:item.captured_by="{ item }" >
                                <td v-if="item.captured_by !== null">
                                    {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </multipane>
        <SearchMembersDialog
            :dialog="open_search_policies_dialog"
        />
        <UpdateMemberUndersriterStatusDialog
            v-if="selectedPolicy !== null && selectedPolicyMember !== null"
            :dialog="open_update_member_underwrite_cover_status_dialog"
            :policy_id="selectedPolicy._id"
            :policy_member_original="selectedPolicyMember"
            :policy_member_copy="selectedPolicyMemberCopy"
        />
        <AddPolicyPremiumPaymentDialog
            v-if="selectedPolicy !== null"
            :dialog="open_add_premium_payment_dialog"
            :policy="selectedPolicy"
        />
        <AddPolicySubsidyDialog
            v-if="selectedPolicy !== null"
            :dialog="open_add_policy_subsidy_dialog"
            :policy="selectedPolicy"
        />
        <ViewDocumentDialog
            v-if="selectedDocument !== null"
            :dialog="open_view_policy_document_dialog"
            :document="selectedDocument"
            param_path="policy"
            :param_id="selectedPolicy._id"
        />
        <ReverseTransactionDialog
            v-if="selectedTransaction !== null"
            :dialog="open_reverse_transaction_dialog"
            :policy="selectedPolicy"
            :transaction="selectedTransaction"
        />
        <DocPreviewDialog
            :dialog="open_policy_certificate_preview_dialog"
            :url="policy_certificate_url"
            :doc_type="policy_certificate_doc_type"
            @CLOSE_DOC_PREVIEW_DIALOG="closePolicyCertificatePreviewDialog()"
        />

        <ViewDebitOrderStatusDialog
            v-if="selectedPolicy !== null"
            :dialog="open_view_debit_order_status_dialog"
            :policy="selectedPolicy"
        />
        <UpdatePaymentArrangementDialog
            v-if="selectedPolicy !== null"
            :dialog="open_update_payment_arrangement_details_dialog"
            :policy_original="selectedPolicy"
            :policy_copy="selectedPolicyCopy"
        />

        <!-- DOCUMENT DIALOGS -->
        <AddPolicyDocumentDialog
            v-if="selectedPolicy !== null"
            :dialog="open_add_document_dialog"
            :policy="selectedPolicy"
        />
    </div>
</template>
<script>
import { Multipane, MultipaneResizer } from 'vue-multipane';
import SearchMembersDialog from './dialogs/search_policies';
import UpdateMemberUndersriterStatusDialog from './dialogs/update_member_underwriter_cover_status';
import AddPolicyPremiumPaymentDialog from './dialogs/add_policy_premium_payment.vue';
import AddPolicySubsidyDialog from './dialogs/add_policy_subsidy.vue';
import ReverseTransactionDialog from './dialogs/reverse_transaction.vue';
import ViewDocumentDialog from '../../components/common_dialogs/view_document_info';
import DocPreviewDialog from '../../components/doc_preview';
import ViewDebitOrderStatusDialog from './dialogs/view_debit_order_status.vue';
import UpdatePaymentArrangementDialog from './dialogs/update_payment_arrangement_details.vue';
import AddPolicyDocumentDialog from './dialogs/add_policy_documents';

import converter from "../../mixins/converter";
import { eventBus } from "../../main";
import { deletePolicyMember, emitAlert, emitSuccess, getPolicyCertificate } from "../../utils/api";
import { POLICY_STATUSES, payment_methods, BUSINESS_PARTNER_TYPES } from "../../../config";
// import clone from "rfdc";

import { getPolicies, getFinanceTransactionsList, getInstallmentScheduleList } from "./gql/queries";
import { businessProcessPolicy } from "./gql/mutations";
import { store } from "../../../store";

export default {
    mixins: [converter],
    components: {
        Multipane,
        MultipaneResizer,
        SearchMembersDialog,
        UpdateMemberUndersriterStatusDialog,
        AddPolicyPremiumPaymentDialog,
        AddPolicySubsidyDialog,
        ReverseTransactionDialog,
        ViewDocumentDialog,
        DocPreviewDialog,
        ViewDebitOrderStatusDialog,
        UpdatePaymentArrangementDialog,
        AddPolicyDocumentDialog,
    },

    computed: {
        isCentralUnderwriterAdminUser() {
            const result = store.state.currentUserAccount.roles.filter(role => {
                                                            return (
                                                                role.role.level.includes(BUSINESS_PARTNER_TYPES.CENTRAL_UNDERWRITER) &&
                                                                role.role.name.toLowerCase() ===  'admin - central underwriter'
                                                            );
                                                        }).length > 0;

            return result;
        },
        isPolicyAdministrator() {
            const result = store.state.currentUserAccount.roles.filter(role => {
                                                            return (
                                                                role.role.level.includes(BUSINESS_PARTNER_TYPES.CENTRAL_UNDERWRITER) &&
                                                                role.role.name.toLowerCase() ===  'policy administration'
                                                            );
                                                        }).length > 0;

            return result;
        }
    },

    data: () => ({
        sub_tabs: null,
        main_tab_height: '250px',
        sub_tab_height:'180',

        // main data-table row
        selectedPolicy: null,
        selectedRow: null,

        // Policy members sub-tab data-table row
        selectedPolicyMember: null,
        selectedPolicyMemberRow: null,

        selectedPolicyMemberCopy: null,

        // documents sub-tab data-table row
        selectedDocument: null,
        selectedDocumentRow: null,

        // documents sub-tab data-table row
        selectedSubsidy: null,
        selectedSubsidyRow: null,

        selectedTransaction: null,
        selectedTransactionRow: null,

        selectedInstallmentSchedule: null,
        selectedInstallmentScheduleRow: null,

        policy_details_headers: [
            // { text: 'Group', value: 'group' },
            { text: 'Policy Number', value: 'policy_number' },
            // { text: 'Underwriter Policy Number', value: 'underwriter_policy_number' },
            // { text: 'Product Number', value: 'policy_number', width: '2%' },
            { text: 'Principal Member', value: 'principal_member', width: '15%' },
            { text: 'Agent', value: 'agent', width: '15%' },
            { text: 'Payment Date', value: 'elected_payment_date', width: '5%' },
            { text: 'Product', value: 'product.name' },
            { text: 'Scheme/Group', value: 'scheme.name' },
            { text: 'Effective Date', value: 'effective_date' },
            // { text: 'Scheme Payment', value: 'scheme_level_payment' },
            { text: 'Premium', value: 'premium' },
            { text: 'Total Cover', value: 'total_cover_amount' },
            { text: 'Payment Method', value: 'payment_information.premium_payer.payment_method' },
            { text: 'Status', value: 'status', width: '5%' },
            { text: 'Date Captured', value: 'created' },
            // { text: 'High Risk', value: 'high_risk', width: '5%' },
        ],
        policy_member_details_headers: [
            { text: 'Name', value: 'entity.name' },
            { text: 'Surname', value: 'entity.surname' },
            { text: 'ID Number', value: 'entity.id_number' },
            { text: 'Gender', value: 'gender' },
            { text: 'Role', value: 'role' },
            { text: 'Policy Inception Age', value: 'age_inception', width: '10%' },
            { text: 'Current Age', value: 'age_current', width: '5%' },
            { text: 'Cover Amount', value: 'cover_amount' },
            { text: 'Date Captured', value: 'created' },
            { text: 'Effective Date', value: 'start_date' },
            { text: 'End Date', value: 'end_date' },
            { text: 'Covered By Underwriter', value: 'covered_by_underwriter' },
            // { text: 'Termination Reason', value: 'termination_reason' },
        ],
        transactions_headers: [
            { text: 'Transaction Date', value: 'transaction_date' },
            { text: 'Created', value: 'created' },
            { text: 'Payment Method', value: 'from.name' },
            // { text: 'Financial Calendar Month', value: 'fin_calendar_month.name' },
            { text: 'System Reference', value: 'reference' },
            { text: 'Customer Reference', value: 'additional_reference' },
            { text: 'Amount', value: 'amount' },
            { text: 'Running Balance', value: 'balance' },
            { text: 'Bank Feed Association', value: 'bank_feed' },
            { text: 'Captured By', value: 'created_by' },
        ],
        applications_headers: [
            { text: 'Application Number', value: 'application_number' },
            { text: 'Application Type', value: 'type' },
            { text: 'Date', value: 'date' },
            { text: 'Status', value: 'status' },
            { text: 'Date Captured', value: 'created' },
            { text: 'Last Updated', value: 'last_updated' },
        ],
        subsidy_details_headers: [
            { text: 'Subsidy Number', value: 'subsidy_number' },
            { text: 'Subsidy Type', value: 'subsidy_type' },
            { text: 'Subsidy Amount', value: 'subsidy_amount' },
            { text: 'Start Date', value: 'start_date' },
            { text: 'End Date', value: 'end_date' },
            { text: 'Date Captured', value: 'created' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        document_details_headers: [
            { text: 'Document Type', value: 'type' },
            { text: 'Name', value: 'name' },
            { text: 'Date Captured', value: 'created' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        installment_schedule_headers: [
            { text: 'Installment Number', value: 'installment_number' },
            { text: 'Deadline Date', value: 'deadline_date' },
            { text: 'Installment Status', value: 'installment_status' },

            { text: 'Underwriter Premium', value: 'premium_underwriter' },
            { text: 'Premium Markup', value: 'premium_broker_markup' },

            { text: 'Total Expected', value: 'premium_total_expected' },
            { text: 'Total Collected', value: 'premium_total_collected' },
        ],

        policy_certificate_doc_type: 'pdf',
        policy_certificate_url: null,
        
        // Table data models
        transactions: [],
        applications: [],
        policy_members: [],
        policy_documents: [],
        installment_schedules: [],
        policy_subsidies: [],

        // Dialogs
        
        policies: [],
        open_search_policies_dialog: false,
        open_add_premium_payment_dialog: false,
        open_add_policy_subsidy_dialog: false,
        open_reverse_transaction_dialog: false,
        open_view_policy_document_dialog: false,
        open_expire_policy_member_role_dialog: false,
        open_update_member_underwrite_cover_status_dialog: false,
        open_policy_certificate_preview_dialog: false,
        open_view_debit_order_status_dialog: false,
        open_update_payment_arrangement_details_dialog: false,
        open_add_document_dialog: false,

        data_table_loading: false,
        SEARCH_MODE: false,
        page: 1,
        limit: 50,

        POLICY_STATUSES: POLICY_STATUSES,
        
        selectedPolicyCopy: null,

        payment_methods: payment_methods,

        transactions_loading: false,
        installment_schedules_loading: false,
    }),

    methods: {
        //-------------------- OPEN DIALOGS ---------------------//
        openSearchPolicyDialog() {
            // this.unselectSelectedRow();
            this.open_search_policies_dialog = true;
        },
        openAddPremiumPaymentEventDialog() {
            this.open_add_premium_payment_dialog = true;
        },
        openAddPolicySubsidyDialog() {
            this.open_add_policy_subsidy_dialog = true;
        },
        reverseTransaction() {
            this.open_reverse_transaction_dialog = true;
        },
        openViewDocumentDialog() {
            this.open_view_policy_document_dialog = true;
        },
        openExpireActivePolicyMemberRole() {
            this.open_expire_policy_member_role_dialog = true;
        },
        openUpdateMemberUndersriterStatusDialog() {
            this.open_update_member_underwrite_cover_status_dialog = true;
        },
        openViewPolicyCertificateDialog(policy_id) {
            getPolicyCertificate(policy_id).then(res => {
                const url = window.URL.createObjectURL(new Blob([res], {type: 'application/pdf'}));
                this.policy_certificate_url =  url;
                this.open_policy_certificate_preview_dialog = true;
            });
        },
        openViewDebitOrderStatus() {
            this.open_view_debit_order_status_dialog = true;
        },
        updatePaymentArrangementDetails() {
            this.open_update_payment_arrangement_details_dialog = true;
        },
        openAddDocumentsDialog() {
            this.open_add_document_dialog = true;
        },

        resetPolicyEditForm(updatedPolicy) {
            // console.log(updatedPolicy)

            if (updatedPolicy.scheme !== null) {
                if (
                    updatedPolicy.product._id !== this.selectedPolicy.product._id ||
                    updatedPolicy.scheme._id !== this.selectedPolicy.scheme._id ||

                    // updatedPolicy.scheme_level_payment !== this.selectedPolicy.scheme_level_payment ||
                    updatedPolicy.elected_payment_date !== this.selectedPolicy.elected_payment_date ||

                    updatedPolicy.payment_information.premium_payer.payment_method !== this.selectedPolicy.payment_information.premium_payer.payment_method ||
                    updatedPolicy.payment_information.premium_payer.percentage_amount !== this.selectedPolicy.payment_information.premium_payer.percentage_amount ||
                    
                    updatedPolicy.payment_information.scheme.payment_method !== this.selectedPolicy.payment_information.scheme.payment_method ||
                    updatedPolicy.payment_information.scheme.percentage_amount !== this.selectedPolicy.payment_information.scheme.percentage_amount
                ) {
                    // this.selectedPolicyCopy = Object.assign({}, this.selectedPolicy);
                    this.selectedPolicyCopy = JSON.parse( JSON.stringify(this.selectedPolicy) );
                }
            } else {
                if (
                    updatedPolicy.product._id !== this.selectedPolicy.product._id ||
                    // updatedPolicy.scheme._id !== this.selectedPolicy.scheme._id ||

                    // updatedPolicy.scheme_level_payment !== this.selectedPolicy.scheme_level_payment ||
                    updatedPolicy.elected_payment_date !== this.selectedPolicy.elected_payment_date ||

                    updatedPolicy.payment_information.premium_payer.payment_method !== this.selectedPolicy.payment_information.premium_payer.payment_method ||
                    updatedPolicy.payment_information.premium_payer.percentage_amount !== this.selectedPolicy.payment_information.premium_payer.percentage_amount ||
                    
                    updatedPolicy.payment_information.scheme.payment_method !== this.selectedPolicy.payment_information.scheme.payment_method ||
                    updatedPolicy.payment_information.scheme.percentage_amount !== this.selectedPolicy.payment_information.scheme.percentage_amount
                ) {
                    // this.selectedPolicyCopy = Object.assign({}, this.selectedPolicy);
                    this.selectedPolicyCopy = JSON.parse( JSON.stringify(this.selectedPolicy) );
                }
            }
            
        },

        resetActivatePendingPolicyForm() {
            this.selectedPolicyCopy = JSON.parse( JSON.stringify(this.selectedPolicy) );
        },
        resetUpdateMemberUndersriterStatusForm() {
            this.selectedPolicyMemberCopy = JSON.parse( JSON.stringify(this.selectedPolicyMember) );
        },

        async runAllocationProcessForPolicy() {
            this.data_table_loading = true;

            try {
                const payloadObj = {
                    OP_TYPE: 'RUN_ALLOCATION_PROCESS',
                    _id: this.selectedPolicy._id,
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcessPolicy,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.businessProcessPolicy.errors === true) {
                    emitAlert(res.data.businessProcessPolicy.message);
                    this.data_table_loading = false;
                    return;
                }

                emitSuccess(res.data.businessProcessPolicy.message);
                this.data_table_loading = false;

                // Update UI model
                this.updateUITableModel(res.data.businessProcessPolicy.business_object);
                
                if (this.sub_tabs === 2) { // transactions-history
                    await this.refreshPolicyTransactions();
                }
                if (this.sub_tabs === 5) { // Installment-Schedules
                    await this.refreshInstallmentSchedules();
                }
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessPolicy.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
        },

        //------------------- CLOSE DIALOGS---------------------//
        updateUITableModel(new_policy) {
            if (new_policy !== null) {
                const obj_index = this.policies.map(obj => { return obj._id; }).indexOf(new_policy._id)
                this.policies[obj_index] = new_policy;

                this.selectedPolicy = new_policy;
                this.selectedPolicyCopy = JSON.parse( JSON.stringify(new_policy) );

                 // Deep copy policies for refreshing main data table
                const temp_arr = Array.from(this.policies);
                this.policies = temp_arr;

                // Also update the documents tab
                this.policy_documents = new_policy.documents;
                this.policy_members = new_policy.members;
            }
        },
        closeSearchPoliciesDialog(searchResults) {
            if (searchResults !== null) {
                this.policies = searchResults;
                this.SEARCH_MODE = true;

                // Update member addresses model
                this.transactions = [];
                // Update member applications model
                this.applications = [];
                // Update member policy-members model
                this.policy_members = [];
                // Update member documents model
                this.policy_documents = [];
            }
            this.open_search_policies_dialog = false;
        },
        closeExpirePolicyMemberRoleDialog(new_policy) {
            this.updateUITableModel(new_policy);
            this.open_expire_policy_member_role_dialog = false;
        },
        closeUpdateMemberUndersriterStatusDialog(new_policy) {
            this.updateUITableModel(new_policy);
            this.open_update_member_underwrite_cover_status_dialog = false;
        },
        closeViewServerDocumentsDialog() {
            this.open_view_policy_document_dialog = false;
        },
        closePolicyCertificatePreviewDialog() {
            this.policy_certificate_url = null;
            this.open_policy_certificate_preview_dialog = false;
        },

        closeAddPremiumPaymentDialog(new_transaction) {
            if (new_transaction !== null) {
                // Prepend to transactions array
                this.transactions.unshift(new_transaction);

                // Prepare balance
                if (new_transaction.from._id === this.selectedPolicy._id) {
                    new_transaction.balance = new_transaction.balance_from;
                }

                if (new_transaction.to._id === this.selectedPolicy._id) {
                    new_transaction.balance = new_transaction.balance_to;
                }
            }
            this.open_add_premium_payment_dialog = false;
        },
        closeAddPolicySubsidyDialog(new_subsidy) {
            if (new_subsidy !== null) {
                // Prepend to subsidies array
                this.policy_subsidies.unshift(new_subsidy);

                // Prepare balance
                if (new_subsidy.from._id === this.selectedPolicy._id) {
                    new_subsidy.balance = new_subsidy.balance_from;
                }

                if (new_subsidy.to._id === this.selectedPolicy._id) {
                    new_subsidy.balance = new_subsidy.balance_to;
                }
            }
            this.open_add_policy_subsidy_dialog = false;
        },
        async closeReverseTransactionDialog(cancelled, newPolicy) {
            if (cancelled === false) {
                this.updateUITableModel(newPolicy);
                await this.refreshPolicyTransactions();

                // Unselect currently selected transaction
                this.selectedTransaction = null;
                if (this.selectedTransactionRow !== null) {
                    this.selectedTransactionRow.select(false);
                }
            }
            this.open_reverse_transaction_dialog = false;
        },
        closeViewDebitOrderStatus() {
            this.open_view_debit_order_status_dialog = false;
        },
        closeUpdatePaymentArrangementDetails(new_policy) {
            this.updateUITableModel(new_policy);
            this.open_update_payment_arrangement_details_dialog = false;
        },
        closeAddPolicyDocumentsDialog(new_policy) {
            this.updateUITableModel(new_policy);
            this.open_add_document_dialog = false;
        },


        // Row Selector Control
        unselectSubTabRows() {
            // Unselect policy-members sub-tab row
            this.selectedPolicyMember = null;
            if (this.selectedPolicyMemberRow !== null) {
                this.selectedPolicyMemberRow.select(false);
            }

            // Unselect documents sub-tab row
            this.selectedDocument = null;
            if (this.selectedDocumentRow !== null) {
                this.selectedDocumentRow.select(false);
            }

            // Unselect subsidy sub-tab row
            this.selectedSubsidy = null;
            if (this.selectedSubsidyRow !== null) {
                this.selectedSubsidyRow.select(false);
            }

            // Unselect transactions sub-tab row
            this.selectedTransaction = null;
            if (this.selectedTransactionRow !== null) {
                this.selectedTransactionRow.select(false);
            }

            // Unselect installment-schedule sub-tab row
            this.selectedInstallmentSchedule = null;
            if (this.selectedInstallmentScheduleRow !== null) {
                this.selectedInstallmentScheduleRow.select(false);
            }
        },
        async selectMainTableRow(item, item_metadata) {
            this.unselectSubTabRows();

            this.selectedPolicyCopy = JSON.parse( JSON.stringify(item) );

            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                this.selectedRow = item_metadata;

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                this.selectedPolicy = item;
                // Update member applications model
                this.applications = item.applications;
                // Update policy members model
                this.policy_members = item.members;
                // Update member documents model
                this.policy_documents = item.documents;

                // this.transactions = [];
                this.installment_schedules = [];

                if (this.sub_tabs === 2) { // transactions-history
                    await this.refreshPolicyTransactions();
                }
                if (this.sub_tabs === 5) { // Installment-Schedules
                    await this.refreshInstallmentSchedules();
                }
            } else {
                this.selectedPolicy = null;
                // Unighlight selected row
                item_metadata.select(false);
                this.selectedRow = null;

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                // Update member addresses model
                this.transactions = [];
                // Update member applications model
                this.applications = [];
                // Update member policy-members model
                this.policy_members = [];
                // Update member documents model
                this.policy_documents = [];

                this.installment_schedules = [];

                // ----------------------UPDATE VISIBILITY OF ACTION BUTTONS -----------------------//
            }
        },
        selectPolicyMemberTabRow(item, item_metadata) {
            this.selectedPolicyMemberCopy = JSON.parse( JSON.stringify(item) );

            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);

                this.selectedPolicyMember = item;
                this.selectedPolicyMemberRow = item_metadata;

                // ----------------------UPDATE VISIBILITY OF ACTION BUTTONS -----------------------//
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedPolicyMember = null;
                this.selectedPolicyMemberRow = null;

                // ----------------------UPDATE VISIBILITY OF ACTION BUTTONS -----------------------//
            }
        },
        selectSubsidyTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedSubsidy = item;
                this.selectedSubsidyRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedSubsidy = null;
                this.selectedSubsidyRow = null;
            }
        },
        selectDocumentsTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedDocument = item;
                this.selectedDocumentRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedDocument = null;
                this.selectedDocumentRow = null;
            }
        },
        selectTransactionTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedTransaction = item;
                this.selectedTransactionRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedTransaction = null;
                this.selectedTransactionRow = null;
            }
        },
        selectInstallmentScheduleTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedInstallmentSchedule = item;
                this.selectedInstallmentScheduleRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedInstallmentSchedule = null;
                this.selectedInstallmentScheduleRow = null;
            }
        },
        tobeDefined(){},
        async removeDraftedPolicyMember() {
            try {
                this.data_table_loading = true;
                const res = await deletePolicyMember(this.selectedPolicy._id, this.selectedPolicyMember._id);
                if (res.errors === true) {
                    emitAlert(res.message);
                    this.data_table_loading = false;
                    return;
                }

                emitSuccess(res.message);
                this.data_table_loading = false;
                
                // ----------- UPDATE UI MODEL -------------

                this.selectedPolicyMember = null;
                this.selectedPolicyMemberRow = null;

                this.policy_members = res.policy.members;

                // refresh main data table
                const obj_index = this.policies.map(obj => { return obj._id; }).indexOf(res.policy._id);
                this.policies[obj_index] = res.policy;

                // Deep copy policies for refreshing main data table
                const temp_arr = Array.from(this.policies);
                this.policies = temp_arr;
                
                this.selectedPolicy = res.policy;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        clearSearchResults() {
            // Unselect a row if there was any
            this.unselectSubTabRows();
            this.unselectSelectedRow();

            this.SEARCH_MODE = false;
            this.refreshPolicies();
        },

        unselectSelectedRow() {
            if (this.selectedRow !== null) {
                this.selectedRow.select(false);
                this.selectedRow = null;

                // Update member addresses model
                this.transactions = [];
                // Update member contact details model
                this.applications = [];
                // Update member policy-members model
                this.policy_members = [];
                // Update member documents model
                this.policy_documents = [];
            }
            this.selectedPolicy = null;
        },

        async loadMoreData() {
            this.data_table_loading = true;

            this.page += 1;
            // getPolicies(this.page, this.limit).then(res => {
            //     this.data_table_loading = false;
            //     this.policies = this.policies.concat( res.data );
            // });

            try {
                const res = await this.$apollo
                                        .query({
                                            query: getPolicies,
                                            // fetchPolicy: 'network-only',
                                            fetchPolicy: 'no-cache',
                                            errorPolicy: 'all',
                                            variables: {
                                                objectType: 'POLICY',
                                                pagination: {
                                                    page: this.page,
                                                    limit: this.limit
                                                }
                                            },
                                        });
                this.data_table_loading = false;
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }

                this.policies = this.policies.concat(res.data.getBusinessObject.business_objects);
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
            
        },

        async refreshPolicies() {
            this.data_table_loading = true;
            this.policies = [];

            this.page = 1; //, limit = 20;

            try {
                const res = await this.$apollo
                                        .query({
                                            query: getPolicies,
                                            // fetchPolicy: 'network-only',
                                            fetchPolicy: 'no-cache',
                                            errorPolicy: 'all',
                                            variables: {
                                                objectType: 'POLICY',
                                                pagination: {
                                                    page: this.page,
                                                    limit: this.limit
                                                }
                                            },
                                        });
                this.data_table_loading = false;
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }

                this.policies = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
        paneResizing(pane, container, size) {
            const size_int = parseInt(size);

            this.main_tab_height = size;
            const max_screen_height = window.innerHeight - 300;

            const sub_tab_percentage = 1 - (size_int/max_screen_height);
            this.sub_tab_height = sub_tab_percentage * max_screen_height;
        },

        async refreshPolicyTransactions() {
            try {
                this.transactions_loading = true;
                const res = await this.$apollo
                                    .query({
                                        query: getFinanceTransactionsList,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'FINANCE_ACCOUNT_TRANSACTION',
                                            pagination: {
                                                page: 1,
                                                limit: 1000
                                            },
                                            searchInput: {
                                                account_id: this.selectedPolicy._id
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.transactions_loading = false;
                    return;
                }


                this.transactions_loading = false;
                // this.transactions = res.data.getBusinessObject.business_objects;
                const transactionsTemp = res.data.getBusinessObject.business_objects.reverse();

                // Adjust running balance
                let runningBalance = 0;
                for (let i = 0; i < transactionsTemp.length; i += 1) {
                    const trans = transactionsTemp[i];

                    // Initialize balance
                    if (i === 0) {
                        runningBalance = trans.amount;
                        runningBalance = Math.round((runningBalance + Number.EPSILON) * 100) / 100;
                        trans.balance = runningBalance;
                        continue;
                    }

                    if (trans.from._id === this.selectedPolicy._id) {
                        trans.amount = -trans.amount;
                        runningBalance = runningBalance - Math.abs(trans.amount);
                        runningBalance = Math.round((runningBalance + Number.EPSILON) * 100) / 100;
                        trans.balance = runningBalance;
                    }

                    if (trans.to._id === this.selectedPolicy._id) {
                        runningBalance = runningBalance + trans.amount;
                        runningBalance = Math.round((runningBalance + Number.EPSILON) * 100) / 100;
                        trans.balance = runningBalance;
                    }
                }
                this.transactions = transactionsTemp.reverse();

                // // Prepare balance
                // // let runningBalance = 0;
                // // let runningAmount = 0;
                // for (const trans of this.transactions) {
                //     if (trans.from._id === this.selectedPolicy._id) {
                //         trans.amount = -trans.amount;
                //         trans.balance = trans.balance_from;
                //     }

                //     if (trans.to._id === this.selectedPolicy._id) {
                //         trans.balance = trans.balance_to;
                //     }

                //     // if (this.transactions.indexOf(trans) === 0) {
                //     //     if (trans.from._id === this.selectedPolicy._id) {
                //     //         // trans.balance = trans.from_running_balance;
                //     //         trans.balance = trans.from.balance;
                //     //     }
    
                //     //     if (trans.to._id === this.selectedPolicy._id) {
                //     //         // trans.balance = trans.to_running_balance;
                //     //         trans.balance = trans.to.balance;
                //     //     }

                //     //     runningBalance = trans.balance;
                //     //     runningAmount = trans.amount;
                //     // } else {
                //     //     // NB: We're working the running balances backwards, so we've filpped the signs


                //     //     if (trans.from._id === this.selectedPolicy._id) {
                //     //         // trans.balance = trans.from_running_balance;

                //     //         // runningBalance = runningBalance + trans.amount;
                //     //         runningBalance = runningBalance + runningAmount;
                //     //         // trans.balance = trans.from.balance;
                //     //         // trans.balance = runningBalance;

                //     //     }
    
                //     //     if (trans.to._id === this.selectedPolicy._id) {
                //     //         // trans.balance = trans.to_running_balance;

                //     //         // runningBalance = runningBalance - trans.amount;
                //     //         runningBalance = runningBalance - runningAmount;
                //     //         // trans.balance = trans.to.balance;
                //     //         // trans.balance = runningBalance;
                //     //     }
                //     //     trans.balance = runningBalance;
                //     //     runningAmount = trans.amount;
                //     // }
                // }
            } catch (error) {
                this.transactions_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
        async refreshPolicySubsidies() {

        },
        async refreshInstallmentSchedules() {
            this.installment_schedules = [];
            try {
                this.installment_schedules_loading = true;
                const res = await this.$apollo
                                    .query({
                                        query: getInstallmentScheduleList,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'POLICY_INSTALLMENT_SCHEDULE_ITEM',
                                            pagination: {
                                                page: 1,
                                                limit: 50
                                            },
                                            searchInput: {
                                                policy: this.selectedPolicy._id
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.installment_schedules_loading = false;
                    return;
                }


                this.installment_schedules_loading = false;
                this.installment_schedules = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.transactions_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        }
    },

    async mounted() {
        eventBus.$on('CLOSE_EXPIRE_POLICY_MEMBER_ROLE_DIALOG', this.closeExpirePolicyMemberRoleDialog);
        eventBus.$on('CLOSE_UPDATE_MEMBER_UNDERWRITER_COVER_STATUS_DIALOG', this.closeUpdateMemberUndersriterStatusDialog);
        eventBus.$on('CLOSE_VIEW_DOCUMENT_DIALOG', this.closeViewServerDocumentsDialog);
        eventBus.$on('CLOSE_POLICY_SEARCH_DIALOG', this.closeSearchPoliciesDialog);
        eventBus.$on('CLOSE_ADD_POLICY_PREMIUM_PAYMENT_DIALOG', this.closeAddPremiumPaymentDialog);
        eventBus.$on('CLOSE_ADD_POLICY_SUBSIDY_DIALOG', this.closeAddPolicySubsidyDialog);
        eventBus.$on('CLOSE_REVERSE_POLICY_TRANSACTION_DIALOG', this.closeReverseTransactionDialog);
        eventBus.$on('CLOSE_VIEW_DEBIT_ORDER_STATUS_DIALOG', this.closeViewDebitOrderStatus);
        eventBus.$on('CLOSE_UPDATE_PAYMENT_ARRANGEMENT_DIALOG', this.closeUpdatePaymentArrangementDetails);
        eventBus.$on('CLOSE_ADD_POLICY_DOCUMENTS_DIALOG', this.closeAddPolicyDocumentsDialog);

        // Form reseters
        eventBus.$on('RESET_POLICY_EDIT_FORM', this.resetPolicyEditForm);
        eventBus.$on('RESET_ACTIVATE_PENDING_POLICY_FORM', this.resetActivatePendingPolicyForm);
        eventBus.$on('RESET_UPDATE_MEMBER_UNDERWRITER_COVER_STATUS', this.resetUpdateMemberUndersriterStatusForm);


        /**
         * The following logic is for manually triggering a pane-divider movement
         * so as to get the windows to properly resize
         */
        this.paneResizing(null, null, 250);

        try {
            this.data_table_loading = true;
            const res = await this.$apollo
                                    .query({
                                        query: getPolicies,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'POLICY',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            this.data_table_loading = false;
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                return;
            }

            this.policies = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.data_table_loading = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
        }
    },

    watch: {
        'sub_tabs': async function(val) {
            if (val === 2) { // transactions-history
                if (this.selectedPolicy !== null) {
                    await this.refreshPolicyTransactions();
                }
            }

            if (val === 5) { // Installment-Schedules
                if (this.selectedPolicy !== null) {
                    await this.refreshInstallmentSchedules();
                }
            }
        }
    }
}
</script>
<style scoped>
/* Overriding of textbox min height */
 .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
}
</style>
import gql from 'graphql-tag'

export const entityProcessUser = gql`
    mutation entityProcessUser($input: UserInput!) {
        entityProcessUser(input: $input) {
            errors
            message
            business_object {
                ... on User {
                    __typename
                    _id
                    active
                    created
                    entity {
                        _id
                        name
                        surname
                        id_number
                    }
                    captured_by {
                        __typename
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }
                    roles {
                        __typename
                        created
                        role {
                            __typename
                            _id
                            name
                        }
                    }
                }
            }
        }
    }
`
import gql from 'graphql-tag'

export const lookupProcessUserRole = gql`
    mutation lookupProcessUserRole($input: UserRoleInput) {
        lookupProcessUserRole(input: $input) {
            errors
            message
            business_object {
                ... on UserRole {
                    __typename
                    _id
                    name
                    description
                    level
                    allowed_screens {
                        _id
                        created
                        screen {
                            _id
                            name
                            description
                            category
                            description
                        }
                    }
                    allowed_reports{
                        _id
                        created
                        report {
                            _id
                            name
                            group
                            report_number
                            description
                        }
                    }
                    allowed_business_processes {
                        _id
                        created
                        process {
                            _id
                            name
                            group
                            description
                            process_number
                        }
                    }
                    allowed_dashboards {
                        _id
                        created
                        dashboard {
                            _id
                            dashboard_number
                            name
                            group
                            description
                        }
                    }
                }
            }
        }
    }
`
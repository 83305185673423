import gql from 'graphql-tag'

export const getCreditProducts = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on LoanProductSecondary {
                    __typename
                    _id

                    name
                    markup_fixed_monthly_fee
                    markup_fixed_monthly_finance_charge
                    markup_percentage_monthly_finance_charge

                    created
                    captured_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }

                    primary_product {
                        _id
                        
                        name
                        max_payment_holidays
                        min_loan_amount
                        max_loan_amount

                        min_number_of_installments

                        monthly_fixed_fee
                        monthly_finance_charge_percentage
                        monthly_finance_charge_amount

                        rules {
                            _id
                            min
                            max
                            rule_parameter {
                                _id
                                id
                                level
                                name
                                description
                                object_key
                            }
                        }
                    }
                }

                ... on LoanProductPrimary {
                    __typename
                    _id
                    
                    name
                    max_payment_holidays
                    min_loan_amount
                    max_loan_amount

                    min_number_of_installments

                    monthly_fixed_fee
                    monthly_finance_charge_percentage
                    monthly_finance_charge_amount

                    rules {
                        _id
                        min
                        max
                        rule_parameter {
                            _id
                            id
                            level
                            name
                            description
                            object_key
                        }
                    }
                }
            }
        }
    }
`

export const getSystemFinanceAccounts = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on FinanceAccount {
                    __typename
                    _id
                    name
                    description
                    type
                }
            }
        }
    }
`

export const searchAgentAutocomplete = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on Agent {
                    __typename
                    _id

                    agent_number
                    entity {
                        _id
                        name
                        surname
                        id_number
                    }
                }
            }
        }
    }
`

export const searchEntityAutocomplete = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on Entity {
                    _id
                    business_partner { _id }
                    name
                    surname
                    id_number
                    gender
                    date_of_birth
                    marital_status
                    life_status
                    marketing_channels

                    # version_history {
                    #     _id
                    #     changes {
                    #         type
                    #         description
                    #         old
                    #         new
                    #         captured_by {
                    #             __typename
                    #             _id
                    #             entity {
                    #                 __typename
                    #                 _id
                    #                 name
                    #                 surname
                    #             }
                    #         }
                    #         created
                    #     }
                    # }

                    # relationships {
                    #     __typename
                    #     _id
                    #     created
                    #     deleted
                    #     captured_by {
                    #         __typename
                    #         _id
                    #         entity {
                    #             __typename
                    #             _id
                    #             name
                    #             surname
                    #         }
                    #     }
                    #     member {
                    #         __typename
                    #         _id
                    #         name
                    #         surname
                    #     }
                    #     relationship_type {
                    #         __typename
                    #         _id
                    #         name
                    #         description
                    #         gender_map
                    #         opposite_relationship {
                    #             __typename
                    #             _id
                    #             name
                    #         }
                    #         payout_target_roles_for_principal
                    #     }
                    # }
                    
                    addresses {
                        __typename
                        _id
                        type
                        line1
                        line2
                        line3
                        line4
                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }

                    contact_details {
                        __typename
                        _id
                        type
                        primary
                        details

                        captured_by {
                            __typename
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }

                    documents {
                        __typename
                        _id
                        type
                        mime_type
                        name

                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }

                    bank_account_details {
                        __typename
                        _id
                        purpose
                        bank {
                            __typename
                            _id
                            name
                            universal_branch_code
                        }
                        account_type
                        account_number

                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }

                    captured_by {
                        __typename
                        _id
                        entity {
                            __typename
                            _id
                            name
                            surname
                        }
                    }
                    created
                }
            }
        }
    }
`

export const searchSchemesAutocomplete = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on Scheme {
                    __typename
                    _id

                    scheme_number
                    name
                }
            }
        }
    }
`

export const getLoans = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on Loan {
                    _id
                    business_partner {
                        _id
                        name
                    }
                    loan_number
                    premium

                    projected_end_date
                    last_transaction_date

                    agent {
                        _id
                        agent_number
                        entity {
                            _id
                            name
                            surname
                            id_number
                        }
                    }
                    scheme {
                        _id
                        name
                        scheme_number
                    }
                    entity {
                        _id
                        name
                        surname
                        id_number

                        bank_account_details {
                            _id
                            purpose
                            bank {
                                _id
                                name
                                universal_branch_code
                            }
                            account_type
                            account_number

                            captured_by {
                                _id
                                entity {
                                    _id
                                    name
                                    surname
                                }
                            }
                            created
                            deleted
                            delete_date
                            deleted_by {
                                _id
                                entity {
                                    _id
                                    name
                                    surname
                                }
                            }
                            delete_reason
                        }
                    }
                    banking_details_id
                    bank_name
                    bank_account_number

                    effective_date
                    created
                    approved_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }
                    created_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }

                    status
                    bank_statement_status
                    agent_status
                    decline_reason

                    pricing_input {
                        loan_type

                        principal_amount
                        loan_term_units
                        loan_term

                        specialized_landing

                        fees_config {
                            monthly_admin_fee
                            initiation_fee_principal_percentage
                            initiation_fee_paid_at_inception
                        }
                        premium_allocation_priority {
                            principal
                            interest
                            admin_fee_premium
                            initiation_fee_premium
                        }
                        interest_config {
                            annual_interest_rate
                            annual_interest_rate_monthly
                        }
                        amortization_schedule_config {
                            term_start
                            term_end
                            annual_interest_amortization_min_month_term

                            principal_required
                            initiation_fee_premium_required
                            admin_fee_required
                            interest_required
                        }
                    }

                    repayment_info {
                        payment_method
                        repayment_frequency
                        elected_repayment_time_point
                    }

                    installment_schedule {
                        _id
                        installment_number
                        deadline_date

                        premium_principal
                        premium_principal_collected
                        premium_principal_target
                        # premium_principal_finance_transaction

                        premium_admin_fee
                        premium_admin_fee_collected
                        premium_admin_fee_target
                        # premium_admin_fee_finance_transaction

                        premium_initiation_fee
                        premium_initiation_fee_collected
                        premium_initiation_fee_target
                        # premium_initiation_fee_finance_transaction

                        premium_interest
                        premium_interest_collected
                        premium_interest_target
                        # premium_interest_finance_transaction

                        premium_total_theoretical
                        premium_total_collected

                        installment_item_carried_over

                        installment_status

                        softy_comp_collections {
                            _id
                            collection_id
                            frequency
                            action_date
                            created

                            local_status
                            transaction_id
                            captured_by {
                                _id
                                entity {
                                    _id
                                    name
                                    surname
                                }
                            }
                        }
                    }

                    documents {
                        _id
                        type
                        mime_type
                        name
                        captured_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }
                }
            }
        }
    }
`

export const getFinanceTransactionsList = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on FinanceAccountTransaction {
                    __typename
                    _id
                    transaction_date

                    amount
                    from {
                        _id
                        name
                        balance
                        last_updated
                    }
                    to {
                        _id
                        name
                        balance
                        last_updated
                    }
                    bank_feed {
                        _id
                        bank_account_name
                        date
                        amount
                        description
                        reference
                        created
                        classification {
                            _id
                            name
                            category
                        }
                    }
                    reference
                    additional_reference

                    balance_from
                    balance_to

                    created
                    created_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }
                }
            }
        }
    }
`

export const finGetDebitOrderStatus = gql`
    query finGetDebitOrderStatus($input: DebitOrderStatusInput!) {
        finGetDebitOrderStatus(input: $input) {
            date
            amount
            status
            days_until_confirmation
        }
    }
`

export const getOperationalExcellenceCases = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on OperationalExcellence {
                    _id
                    calendar_month {
                        _id
                        name
                        value
                        status
                    }
                    case_number
                    created
                    created_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }

                    # LOAN-QUOTE
                    loan_quote {
                        quote_number
                        status
                    }
                    # LOAN
                    loan {
                        loan_number
                        status
                        repayment_info {
                            payment_method
                            repayment_frequency
                            elected_repayment_time_point
                        }
                    }
                    # POLICY
                    policy {
                        _id
                        policy_number
                        payment_information {
                            premium_payer {
                                payment_method
                                percentage_amount
                            }
                            scheme {
                                payment_method
                                percentage_amount
                            }
                        }
                    }
                    # PROSPECT-POLICY
                    prospect_policy {
                        prospect_number
                        payment_date
                        scheme_level_payment
                        payment_information {
                            premium_payer {
                                payment_method
                                percentage_amount
                            }
                            scheme {
                                payment_method
                                percentage_amount
                            }
                        }
                    }
                    # ENTITY
                    entity {
                        _id
                        name
                        surname
                        id_number
                    }
                    # AGENT
                    agent {
                        _id
                        agent_number
                        entity {
                            _id
                            name
                            surname
                            id_number
                        }
                    }


                    required_action
                    description
                    status
                    category

                    closed_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }
                    close_date
                    # close_reason
                    next_action_date
                    
                    notes {
                        notes
                        created
                        captured_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                    }

                    contact_person {
                        name
                        surname
                        phone
                        email
                        banking_details
                    }
                }
            }
        }
    }
`

export const searchBankFeedAutocomplete = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on BankFeed {
                    __typename
                    _id
                    bank_account_name
                    date
                    amount
                    description
                    reference
                    classification {
                        _id
                        name
                        category
                    }
                    created
                }
            }
        }
    }
`
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="100%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>View Case Details</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip> -->
                <v-btn
                    icon
                    @click="closeEditCaseDialog()"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-expansion-panels focusable :value="0">
                    <!-- CASE DETAILS -->
                    <v-expansion-panel style="background: #f6c20a8f" >
                        <v-expansion-panel-header>
                            <h3>Case Details</h3><br>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row style="background:#ffffff; padding-top:30px">
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :value="'CS-' + case_original.case_number"
                                        color="secondary"
                                        label="Case Number"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :value="case_original.calendar_month.name"
                                        color="secondary"
                                        label="Month"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :value="case_original.status"
                                        color="secondary"
                                        label="Status"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :value="case_original.next_action_date === null ? 'NONE' : case_original.next_action_date.split('T')[0]"
                                        color="secondary"
                                        label="Next Action Date"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
            
            
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :value="case_original.description"
                                        color="secondary"
                                        label="Description"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :value="case_original.category"
                                        color="secondary"
                                        label="Category"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        :value="case_original.required_action"
                                        color="secondary"
                                        label="Required Action"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- LOAN DETAILS -->
                    <v-expansion-panel
                        v-if="case_original.loan !== null"
                        style="background: #f6c20a8f"
                    >
                        <v-expansion-panel-header>
                            <h3>Loan Details</h3><br>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row style="background:#ffffff; padding-top:30px">
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :value="'L' + case_original.loan.loan_number"
                                        color="secondary"
                                        label="Loan Number"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :value="case_original.loan.status"
                                        color="secondary"
                                        label="Loan Status"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :value="case_original.loan.repayment_info.payment_method"
                                        color="secondary"
                                        label="Payment Method"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :value="case_original.loan.repayment_info.elected_repayment_time_point"
                                        color="secondary"
                                        label="Payment Date"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- CONTACT PERSON DETAILS -->
                    <v-expansion-panel style="background: #f6c20a8f">
                        <v-expansion-panel-header>
                            <h3>Contact Person Details</h3><br>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row style="background:#ffffff; padding-top:30px">
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :value="case_original.contact_person.name"
                                        color="secondary"
                                        label="Name"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :value="case_original.contact_person.surname"
                                        color="secondary"
                                        label="Surname"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :value="case_original.contact_person.phone"
                                        color="secondary"
                                        label="Phone"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :value="case_original.contact_person.email"
                                        color="secondary"
                                        label="Email"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-text-field
                                        :value="case_original.contact_person.banking_details"
                                        color="secondary"
                                        label="Banking Details"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- CASE NOTES -->
                    <v-expansion-panel style="background: #f6c20a8f">
                        <v-expansion-panel-header>
                            <h3>Case Notes</h3><br>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row
                                v-for="(notesItem, index) in case_original.notes"
                                :key="index"
                                style="background:#ffffff; padding-top:30px"
                            >
                                <v-col cols="12" sm="6">
                                    <v-textarea
                                        :value="notesItem.notes"
                                        color="secondary"
                                        label="Notes"
                                        auto-grow
                                        rows="1"
                                        disabled
                                        required
                                    ></v-textarea>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :value="notesItem.created.split('T')[0] + '  at  ' + notesItem.created.split('T')[1].split('.')[0]"
                                        color="secondary"
                                        label="Created At"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :value="notesItem.captured_by.entity.name + ' ' + notesItem.captured_by.entity.surname"
                                        color="secondary"
                                        label="Captured By"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <br />
                <br />

                <!-- <v-card-text class="text-center" v-if="submitting">
                    Submitting...
                    <v-progress-linear
                        indeterminate
                        color="green"
                        class="mb-0"
                    ></v-progress-linear>
                </v-card-text> -->



                <!-- Button Section -->
                <!-- <v-card-actions style="border-top: 4px solid #bad8df; background: #ffffff">
                    <v-btn
                        x-large
                        color="red white--text"
                        @click="closeCase()"
                    >
                        <v-icon x-large>cancel</v-icon> Close Case
                    </v-btn>
                    <v-spacer></v-spacer>

                    <v-col cols="12" sm="3">
                        <v-menu
                            v-model="next_action_date_menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="case_next_action_date"
                                    color="secondary"
                                    label="Next Action Date"
                                    filled
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                color="secondary"
                                v-model="case_next_action_date"
                                :min="new Date().toISOString().substr(0, 10)"
                                @input="next_action_date_menu = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-spacer></v-spacer>

                    <v-textarea
                        v-model="case_notes"
                        color="secondary"
                        label="Notes"
                        auto-grow
                        rows="1"
                        filled
                        required
                    ></v-textarea>
                    <v-spacer></v-spacer>

                    <v-btn
                        x-large
                        color="success"
                        :disabled="submitBtnDisabled()"
                        @click="submitCase()"
                    >
                        <v-icon x-large>save</v-icon> Submit
                    </v-btn>
                </v-card-actions> -->
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";

import { opsProcessOperationalExcellence } from "../gql/mutations";

export default {
    components:{},
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        case_original: {
            type: Object,
            required: false,
        },
    },
    data: () => ({
        submitting: false,
        server_response_obj: null,

        next_action_date_menu: false,
        case_notes: null,
        case_next_action_date: null,
    }),
        
    methods: {
        closeEditCaseDialog() {
            eventBus.$emit('CLOSE_VIEW_LOAN_CASE_DIALOG', this.server_response_obj);
        },
        resetForm() {
            this.server_response_obj = null;
            this.case_notes = null;
            this.case_next_action_date = null;
        },
        
        submitBtnDisabled() {
            if (this.case_notes === null) {
                return true;
            }
            if (typeof(this.case_notes) === 'string') {
                if (this.case_notes.trim().length === 0) {
                    this.case_notes = null;
                    return true;
                }
            }
            if (this.case_next_action_date === null) {
                return true;
            }
            return false;
        },

        capturingDone() {
            this.closeEditCaseDialog();
            this.resetForm();
        },
        async submitCase() {
            this.submitting = true;

            try {
                // Payload payload
                const submitObj = {
                    notes: null,
                    next_action_date: null,
                    status: null,
                };

                if (this.case_notes !== null) {
                    submitObj.notes = this.case_notes;
                }
                if (this.case_next_action_date !== null) {
                    submitObj.next_action_date = this.case_next_action_date;
                }

                const payloadObj = {
                    OP_TYPE: 'UPDATE',
                    _id: this.case_original._id,

                    ...submitObj
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: opsProcessOperationalExcellence,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.opsProcessOperationalExcellence.errors === true) {
                    emitAlert(res.data.opsProcessOperationalExcellence.message);
                    this.submitting = false;
                    return;
                }
                
                this.server_response_obj = res.data.opsProcessOperationalExcellence.business_object;
                this.closeEditCaseDialog();
                emitSuccess(res.data.opsProcessOperationalExcellence.message);

                // Reset fields
                this.server_response_obj = null;
                this.submitting = false;
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.opsProcessOperationalExcellence.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
        },

        async closeCase() {
            this.submitting = true;

            try {
                // Payload payload
                const submitObj = {
                    notes: null,
                    next_action_date: null,
                    status: 'CLOSED',
                };

                const payloadObj = {
                    OP_TYPE: 'UPDATE',
                    _id: this.case_original._id,

                    ...submitObj
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: opsProcessOperationalExcellence,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.opsProcessOperationalExcellence.errors === true) {
                    emitAlert(res.data.opsProcessOperationalExcellence.message);
                    this.submitting = false;
                    return;
                }
                
                this.server_response_obj = res.data.opsProcessOperationalExcellence.business_object;
                this.closeEditCaseDialog();
                emitSuccess(res.data.opsProcessOperationalExcellence.message);

                // Reset fields
                this.server_response_obj = null;
                this.submitting = false;
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.opsProcessOperationalExcellence.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
        },
    },
    mounted() {

    },
}
</script>
<style scoped>

</style>
import gql from 'graphql-tag'

export const getUsers = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on User {
                    __typename
                    _id
                    active
                    created
                    entity {
                        _id
                        name
                        surname
                        id_number
                    }
                    captured_by {
                        __typename
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }
                    roles {
                        __typename
                        _id
                        created
                        role {
                            __typename
                            _id
                            name
                        }
                    }
                }
            }
        }
    }
`

export const getUserRolesContextualized = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on UserRole {
                    __typename
                    _id
                    name
                    description
                    level
                    allowed_screens {
                        _id
                        created
                        screen {
                            _id
                            name
                            description
                            category
                            description
                        }
                    }
                    allowed_reports{
                        _id
                        created
                        report {
                            _id
                            name
                            group
                            report_number
                            description
                        }
                    }
                    allowed_business_processes {
                        _id
                        created
                        process {
                            _id
                            name
                            group
                            description
                            process_number
                        }
                    }
                    allowed_dashboards {
                        _id
                        created
                        dashboard {
                            _id
                            dashboard_number
                            name
                            group
                            description
                        }
                    }
                }
            }
        }
    }
`

export const searchEntityAutocomplete = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on Entity {
                    __typename
                    _id
                    name
                    surname
                    id_number
                }
            }
        }
    }
`
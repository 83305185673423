import gql from 'graphql-tag'

export const calculateLoanPricing = gql`
    mutation calculateLoanPricing($input: LoanPricingInput!) {
        calculateLoanPricing(input: $input) {
            initiation_fee
            monthly_admin_fee
            loan_term
            initiation_fee_paid_at_inception
            total_expected_collection

            premium_profile {
                term_start
                term_end
                period

                principal
                initiation_fees
                admin_fees
                interest
                interest_amount
                total
            }
            amortization_schedule {
                installment_number

                premium_principal
                premium_admin_fee
                premium_initiation_fee
                premium_interest
                premium_total

                status
            }
        }
    }
`

export const businessProcessLoan = gql`
    mutation businessProcessLoan($input: LoanInput!) {
        businessProcessLoan(input: $input) {
            errors
            message
            business_object {
                ... on Loan {
                    _id
                    business_partner {
                        _id
                        name
                    }
                    loan_number
                    premium

                    projected_end_date
                    last_transaction_date

                    agent {
                        _id
                        agent_number
                        entity {
                            _id
                            name
                            surname
                            id_number
                        }
                    }
                    scheme {
                        _id
                        name
                        scheme_number
                    }
                    entity {
                        _id
                        name
                        surname
                        id_number

                        bank_account_details {
                            _id
                            purpose
                            bank {
                                _id
                                name
                                universal_branch_code
                            }
                            account_type
                            account_number

                            captured_by {
                                _id
                                entity {
                                    _id
                                    name
                                    surname
                                }
                            }
                            created
                            deleted
                            delete_date
                            deleted_by {
                                _id
                                entity {
                                    _id
                                    name
                                    surname
                                }
                            }
                            delete_reason
                        }
                    }
                    banking_details_id
                    bank_name
                    bank_account_number

                    effective_date
                    created
                    approved_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }
                    created_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }

                    status
                    bank_statement_status
                    agent_status
                    decline_reason

                    pricing_input {
                        loan_type

                        principal_amount
                        loan_term_units
                        loan_term

                        specialized_landing

                        fees_config {
                            monthly_admin_fee
                            initiation_fee_principal_percentage
                            initiation_fee_paid_at_inception
                        }
                        premium_allocation_priority {
                            principal
                            interest
                            admin_fee_premium
                            initiation_fee_premium
                        }
                        interest_config {
                            annual_interest_rate
                            annual_interest_rate_monthly
                        }
                        amortization_schedule_config {
                            term_start
                            term_end
                            annual_interest_amortization_min_month_term

                            principal_required
                            initiation_fee_premium_required
                            admin_fee_required
                            interest_required
                        }
                    }

                    repayment_info {
                        payment_method
                        repayment_frequency
                        elected_repayment_time_point
                    }

                    installment_schedule {
                        _id
                        installment_number
                        deadline_date

                        premium_principal
                        premium_principal_collected
                        premium_principal_target
                        # premium_principal_finance_transaction

                        premium_admin_fee
                        premium_admin_fee_collected
                        premium_admin_fee_target
                        # premium_admin_fee_finance_transaction

                        premium_initiation_fee
                        premium_initiation_fee_collected
                        premium_initiation_fee_target
                        # premium_initiation_fee_finance_transaction

                        premium_interest
                        premium_interest_collected
                        premium_interest_target
                        # premium_interest_finance_transaction

                        premium_total_theoretical
                        premium_total_collected

                        installment_item_carried_over

                        installment_status

                        softy_comp_collections {
                            _id
                            collection_id
                            frequency
                            action_date
                            created

                            local_status
                            transaction_id
                            captured_by {
                                _id
                                entity {
                                    _id
                                    name
                                    surname
                                }
                            }
                        }
                    }

                    documents {
                        _id
                        type
                        mime_type
                        name
                        captured_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }
                }
            }
        }
    }
`

export const businessValidateLoanQuote = gql`
    mutation businessValidateLoanQuote($loan_quote_id: String!) {
        businessValidateLoanQuote(loan_quote_id: $loan_quote_id) {
            loan_quote {
                _id
                quote_number
            }
            overall_status
            validation_results {
                id
                rule_id
                rule_name
                loan_quote_param_value
                min
                max
                valid
                waived
                fail_reason
            }
        }
    }
`

export const createFinanceTransaction = gql`
    mutation finProcessFinanceAccountTransaction($input: FinanceAccountTransactionInput) {
        finProcessFinanceAccountTransaction(input: $input) {
            errors
            message
            business_object {
                ... on FinanceAccountTransaction {
                    __typename
                    _id
                    transaction_date

                    amount
                    from {
                        _id
                        name
                        balance
                        last_updated
                    }
                    to {
                        _id
                        name
                        balance
                        last_updated
                    }
                    reference
                    additional_reference

                    balance_from
                    balance_to

                    created
                    created_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }
                }
            }
        }
    }
`

export const getLoanDebtStatus = gql`
    mutation businessProcessLoan($input: LoanInput!) {
        businessProcessLoan(input: $input) {
            errors
            message
            business_object {
                ... on Loan {
                    _id
                    business_partner {
                        _id
                        name
                    }
                    debt_status {
                        total_collected
                        total_outstanding
                        total_outstanding_to_date
                    }
                }
            }
        }
    }
`

export const opsProcessOperationalExcellence = gql`
    mutation opsProcessOperationalExcellence($input: OperationalExcellenceInput!) {
        opsProcessOperationalExcellence(input: $input) {
            errors
            message
            # business_object {}
        }
    }
`

export const entityProcessEntity = gql`
    mutation entityProcessEntity($input: EntityInput!) {
        entityProcessEntity(input: $input) {
            errors
            message
            business_object {
                ... on Entity {
                    _id
                    business_partner { _id }
                    name
                    surname
                    id_number
                    gender
                    date_of_birth
                    marital_status
                    life_status
                    marketing_channels

                    # version_history {
                    #     _id
                    #     changes {
                    #         type
                    #         description
                    #         old
                    #         new
                    #         captured_by {
                    #             __typename
                    #             _id
                    #             entity {
                    #                 __typename
                    #                 _id
                    #                 name
                    #                 surname
                    #             }
                    #         }
                    #         created
                    #     }
                    # }

                    relationships {
                        __typename
                        _id
                        created
                        deleted
                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        member {
                            __typename
                            _id
                            name
                            surname
                        }
                        relationship_type {
                            __typename
                            _id
                            name
                            description
                            gender_map
                            opposite_relationship {
                                __typename
                                _id
                                name
                            }
                            payout_target_roles_for_principal
                        }
                    }
                    
                    addresses {
                        __typename
                        _id
                        type
                        line1
                        line2
                        line3
                        line4
                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }

                    contact_details {
                        __typename
                        _id
                        type
                        primary
                        details

                        captured_by {
                            __typename
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }

                    documents {
                        __typename
                        _id
                        type
                        mime_type
                        name

                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }

                    bank_account_details {
                        __typename
                        _id
                        # purpose
                        bank {
                            __typename
                            _id
                            name
                            universal_branch_code
                        }
                        account_type
                        account_number

                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }

                    captured_by {
                        __typename
                        _id
                        entity {
                            __typename
                            _id
                            name
                            surname
                        }
                    }
                    created

                    call_recordings {
                        __typename
                        _id
                        call_sid
                        recording_imported
                        twilio_call_obj {
                            to
                        }
                        reason
                        created
                        captured_by {
                            __typename
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        call_rating {
                            rated_by {
                                _id
                                entity {
                                    _id
                                    name
                                    surname
                                }
                            }
                            call_sid
                            call_answered

                            debt_acknowledgement
                            tone_client
                            tone_agent
                            ten_day_collection_confidence
                            total_score

                            notes
                            captured_by {
                                _id
                                entity {
                                    _id
                                    name
                                    surname
                                }
                            }
                            created
                        }
                    }
                }
            }
        }
    }
`
import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client'
import { ApolloLink } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { createHttpLink } from 'apollo-link-http'

import introspectionQueryResultData from '../fragmentTypes.json';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
// import { createUploadLink } from 'apollo-upload-client'
// import { WebSocketLink } from 'apollo-link-ws'
// import { split } from 'apollo-link'
// import { getMainDefinition } from 'apollo-utilities'
// import https from 'https'
import { onError } from 'apollo-link-error'
import { eventBus } from './main'
import { store } from '../store';
import auth from "./mixins/auth";

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
});
const cache = new InMemoryCache({ fragmentMatcher });

// Install the vue plugin
Vue.use(VueApollo)

// Http endpoint
const httpEndpoint = '/graphql'
// Files URL root
export const filesRoot = httpEndpoint.substr(0, httpEndpoint.indexOf('/graphql'))

Vue.prototype.$filesRoot = filesRoot

const errorLink = onError(
    ({ networkError, graphQLErrors }) => {
        if (graphQLErrors) {
            graphQLErrors.map(({ message, locations, path }) => {
                eventBus.$emit('alertError', message)
                console.error(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                    ); // eslint-disable-line

                if (message === 'Unauthorized') {
                    // Clear the session
                    auth.methods.logout();
                }
            })
        }

        if (networkError) console.log(`[Network error]: ${networkError}`); // eslint-disable-line
    }
)

// const uploadLink = createUploadLink(
//     {
//         uri: httpEndpoint,
//         //   Accept self-signed certs
//         fetchOptions: {
//             agent: new https.Agent({ rejectUnauthorized: false })
//         }
//     }
// );

const httpLint = createHttpLink({
    uri: httpEndpoint,
    //   Accept self-signed certs
    // fetchOptions: {
    //     agent: new https.Agent({ rejectUnauthorized: false })
    // }
});

// Create the subscription websocket link
// const wsLink = new WebSocketLink({
//     uri: 'ws://localhost:8080/socket',
//     // uri: httpEndpoint,
//     options: {
//         reconnect: true,
//     },
// });

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
// const link = split(
//     // split based on operation type
//     ({ query }) => {
//       const definition = getMainDefinition(query)
//       return definition.kind === 'OperationDefinition' &&
//         definition.operation === 'subscription'
//     },
//     // wsLink,
//     // uploadLink
//     httpLint
// );

// Intercept all responses
const afterwareLink = new ApolloLink((operation, forward) => {
	// Called before operation is sent to server
	// console.log('EEEE', operation.getContext())

	return forward(operation).map(response => {
		// Called after server responds
		const context = operation.getContext();

		const newToken = context.response.headers.get("token_renew");
		if (newToken) {
			// console.log('TOKEN-REFRESH: ', newToken)
            store.commit('update_token', newToken);
		}
		// console.log('DATA: ', response.data)
		return response;
	});
})

// Config
const defaultOptions = {
    httpEndpoint,
    wsEndpoint: null,
    persisting: false,
    websocketsOnly: false,
    ssr: false,
    defaultHttpLink: false,
    cache: cache,
    link: ApolloLink.from([
        errorLink,
        afterwareLink,
        setContext(async (_, { headers }) => {
            // const token = await JSON.parse(sessionStorage.getItem('user'))

            const token = store.state.token;

            const selected_context = store.state.context_list.filter(ctx => { return ctx.selected === true })[0];
            const additionalHeaders = {};

            if (selected_context) {
                const context = selected_context.business_partner._id;
                if (token !== null) {
                    additionalHeaders['authorization'] = `Bearer ${token}`;
                    additionalHeaders['x-as-context'] = `${context}`;
                }
            }

            return {
                headers: {
                    ...headers,
                    ...additionalHeaders
                    // authorization: token ? `Bearer ${token.value}` : '',
                }
            }
        }),
        // createHttpLink({
        //   uri: httpEndpoint,
        //   //   Accept self-signed certs
        //   fetchOptions: {
        //     agent: new https.Agent({ rejectUnauthorized: false })
        //   }
        // }),

        // uploadLink,
        // wsLink,
        httpLint,
    ])
}

// Call this in the Vue app file
export function createProvider (options = {}) {
    // Create apollo client
    const { apolloClient, wsClient } = createApolloClient({
        ...defaultOptions,
        ...options
    })
    apolloClient.wsClient = wsClient

    // Create vue apollo provider
    const apolloProvider = new VueApollo({
        defaultClient: apolloClient,
        defaultOptions: {
            $query: {
                // fetchPolicy: 'cache-and-network'
                fetchPolicy: 'no-cache',
            }
        },
        errorHandler (error) {
            // eslint-disable-next-line no-console
            console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
        }
    })

    return apolloProvider
}

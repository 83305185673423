<template>
    <v-row style="padding-bottom:10px; padding-left: 10px;">
        <!-- Term Start -->
        <v-col cols="12" sm="2">
            <v-text-field
                v-model="schedule_config.term_start"
                label="Term Start"
                autocomplete="some-random-text"
                type="number"
                color="secondary"
                :suffix="pricing_details.loan_term_units"
                :disabled="(pricing_details.specialized_landing === false)"
                required
            ></v-text-field>
        </v-col>
        <!-- Term End -->
        <v-col cols="12" sm="2">
            <v-text-field
                v-model="schedule_config.term_end"
                label="Term End"
                autocomplete="some-random-text"
                type="number"
                color="secondary"
                :suffix="pricing_details.loan_term_units"
                :disabled="(pricing_details.specialized_landing === false)"
                required
            ></v-text-field>
        </v-col>

        <!-- TERM PERIOD -->
        <v-col cols="12" sm="1" :style="periodIsFloatingPt ? 'background:#ffa2a2' : 'background:green; padding:5px'">
            <v-text-field
                :value="getTermPeriod"
                label="Period"
                autocomplete="some-random-text"
                type="number"
                color="secondary"
                :suffix="pricing_details.loan_term_units"
                disabled
                required
            ></v-text-field>
        </v-col>
        <!-- <v-col cols="12" sm="1" v-if="(pricing_details.specialized_landing === false)">
            <v-text-field
                :value="pricing_details.loan_term"
                label="Period"
                autocomplete="some-random-text"
                type="number"
                color="secondary"
                :suffix="pricing_details.loan_term_units"
                disabled
                required
            ></v-text-field>
        </v-col>
        <v-col cols="12" sm="1" v-else>
            <v-text-field
                :value="(parseFloat(schedule_config.term_end) - parseFloat(schedule_config.term_start)) + 1"
                label="Period"
                autocomplete="some-random-text"
                type="number"
                color="secondary"
                :suffix="pricing_details.loan_term_units"
                disabled
                required
            ></v-text-field>
        </v-col> -->
        <v-col cols="12" sm="1"></v-col>
        
        <v-col cols="12" sm="1">
            <v-checkbox
                v-model="schedule_config.principal_required"
                color="secondary"
                label="Principal Required"
                required
                :disabled="(pricing_details.specialized_landing === false)"
            ></v-checkbox>
        </v-col>
        <v-col cols="12" sm="1">
            <v-checkbox
                v-model="schedule_config.admin_fee_required"
                color="secondary"
                label="Admin Fee Required"
                :disabled="(pricing_details.specialized_landing === false)"
                required
            ></v-checkbox>
        </v-col>
        <v-col cols="12" sm="1">
            <v-checkbox
                v-model="schedule_config.interest_required"
                color="secondary"
                label="Interest Required"
                :disabled="(pricing_details.specialized_landing === false)"
                required
            ></v-checkbox>
        </v-col>
        <v-col cols="12" sm="1" v-if="(pricing_details.fees_config.initiation_fee_paid_at_inception === false)">
            <v-checkbox
                v-model="schedule_config.initiation_fee_premium_required"
                color="secondary"
                label="Init Fee Required"
                :disabled="(pricing_details.specialized_landing === false)"
                required
            ></v-checkbox>
        </v-col>
        <v-col cols="12" sm="2">
            <v-text-field
                v-model="schedule_config.annual_interest_amortization_min_month_term"
                hint="Annual Interest Amortization Min Month Term"
                persistent-hint
                autocomplete="some-random-text"
                type="number"
                color="secondary"
                suffix="MONTHS"
                required
            ></v-text-field>
        </v-col>
        <div style="background:white; height: 2px; width: 100%"></div>
    </v-row>
</template>

<script>
export default {
    name: "AmortizationScheduleItem",
    props: {
        schedule_config: {
            required: false,
            default: null
        },
        pricing_details: {
            required: false,
            default: null
        },
    },
    data: () => ({
        search: null,
        search_entries: [],
        isLoading: false
    }),
    computed: {
        getTermPeriod() {
            const termEndNum = parseFloat(this.schedule_config.term_end);
            const termStartNum = parseFloat(this.schedule_config.term_start);

            if (termEndNum === 0 && termStartNum === 0) {
                return 0;
            }

            if (termStartNum > termEndNum) {
                return 0;
            }

            const result = (termEndNum - termStartNum) + 1;
            return result;
        },
        periodIsFloatingPt() {
            const termStartNum = parseFloat(this.schedule_config.term_start);
            const termEndNum = parseFloat(this.schedule_config.term_end);

            const annualizedPeriod = (termEndNum - termStartNum + 1) / 12;
            const floatingPtPeriod = parseFloat((annualizedPeriod % 1).toFixed(2));
            const BASE_TERM = Math.abs( Math.round( ( (annualizedPeriod - floatingPtPeriod) + Number.EPSILON ) * 100 ) / 100 );

            return (
                BASE_TERM > 0 &&
                floatingPtPeriod > 0
            );
        }
    },
    methods: {},
    mounted() {
        
    },
    watch: {
        
    }
};
</script>
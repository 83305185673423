<template>
    <v-autocomplete
        v-model="policy_search.policy"
        :items="search_entries"
        :loading="isLoading"
        :search-input.sync="search"
        hide-no-data
        small-chips
        item-text="display_value"
        item-value="_id"
        :label="label"
        placeholder="Start typing to Search"
        prepend-icon="search"
        color="secondary"
        return-object
    ></v-autocomplete>
</template>

<script>
import { emitAlert } from "../../../utils/api";
import { getPolicies } from "../gql/queries";
export default {
    name: "PolicySearch",
    props: {
        policy_search: {
            required: false,
            default: null
        },
        label: {
            required: false,
            default: 'Search Policy (by policy number)'
        }
    },
    data: () => ({
        search: null,
        search_entries: [],
        isLoading: false
    }),
    computed: {
        items () {
            return this.search_entries.map(entry => {
                const display_value = `${entry.policy_number}  [ ${entry.principal_member.name} ${entry.principal_member.surname} (${entry.principal_member.id_number}) ]`;
                return Object.assign({}, entry, { display_value })
            })
        },
    },
    mounted() {
        // Set selected chip if policy-search is already initialized
        if (this.policy_search.policy !== null && this.policy_search.policy !== undefined) {
            this.search_entries.push(this.policy_search.policy);
        }
    },
    watch: {
        async search (val) {
            // Items have already been loaded
            // if (this.items.length > 0) return

            // // Items have already been requested
            // if (this.isLoading) return

            this.isLoading = true

            if (val !== null) {
                if (val.length > 0) {
                    const searchQuery = {
                        policy_number: val,
                    };

                    const res = await this.$apollo
                                            .query({
                                                query: getPolicies,
                                                // fetchPolicy: 'network-only',
                                                fetchPolicy: 'no-cache',
                                                errorPolicy: 'all',
                                                variables: {
                                                    objectType: 'POLICY',
                                                    pagination: {
                                                        page: this.page,
                                                        limit: this.limit
                                                    },
                                                    searchInput: searchQuery
                                                },
                                            });
                    this.isLoading = false;
                    if (res.data.getBusinessObject.errors === true) {
                        emitAlert(res.data.getBusinessObject.message);
                        return;
                    }

                    res.data.getBusinessObject.business_objects.map(entry => {
                        const display_value = `${entry.policy_number}  [ ${entry.principal_member.name} ${entry.principal_member.surname} (${entry.principal_member.id_number}) ]`;
                        entry.display_value = display_value;
                        return entry;
                    });
                    this.search_entries = res.data.getBusinessObject.business_objects;
                } else {
                    this.isLoading = false;
                    this.search_entries = [];
                }
            } else {
                this.isLoading = false;
            }
            
        },
    }
};
</script>
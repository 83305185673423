<template>
    <div
        style="
            padding:0px;
            width:100%;
            border-top:3px solid #4590a0;
            border-bottom:3px solid #4590a0;
        "
    >
        <v-toolbar
            color="secondary"
            dark
            height="50"
        >
            <v-tooltip
                bottom
            >
                <template v-slot:activator="{ on }">
                    <v-btn
                        color="amber black--text"
                        small
                        @click="refreshCallRecordings()"
                        v-on="on"
                    >
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                </template>
                <span>Refresh Call Recordings</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <!-- Download Call Recording -->
            <v-tooltip
                bottom
                v-if="(
                    selectedRecording !== null &&
                    //selectedRecording.recording_imported === false
                    selectedRecording.twilio_call_obj === null
                )"
            >
                <template v-slot:activator="{ on }">
                    <v-btn
                        color="amber black--text"
                        small
                        @click="downloadCallRecording()"
                        v-on="on"
                    >
                        <v-icon>mdi-download</v-icon> Download Recording
                    </v-btn>
                </template>
                <span>Download Call Recording</span>
            </v-tooltip>
            <!-- Play Call Recording -->
            <v-tooltip
                bottom
                v-if="(
                    selectedRecording !== null &&
                    selectedRecording.recording_imported === true
                )"
            >
                <template v-slot:activator="{ on }">
                    <v-btn
                        color="black white--text"
                        small
                        @click="playCallRecording()"
                        v-on="on"
                    >
                        <v-icon>mdi-play</v-icon> Play Recording
                    </v-btn>
                </template>
                <span>Play Call Recording</span>
            </v-tooltip>
            <v-tooltip
                bottom
                v-if="(
                    selectedRecording !== null &&
                    selectedRecording.recording_imported === true &&
                    selectedRecording.call_rating === null && 
                    (
                        isCentralUnderwriterAdminUser ||
                        isCallQualityAssurer
                    )/*&&
                    selectedRecording.call_rating === null*/
                )"
            >
                <template v-slot:activator="{ on }">
                    <v-btn
                        color="red white--text"
                        small
                        @click="openCallRatingDialog()"
                        v-on="on"
                        style="margin:5px"
                    >
                        <v-icon>mdi-marker-check</v-icon> Rate Call
                    </v-btn>
                </template>
                <span>Rate Call</span>
            </v-tooltip>

            <v-tooltip
                bottom
                v-if="(
                    selectedRecording !== null &&
                    selectedRecording.recording_imported === true &&
                    selectedRecording.call_rating !== null
                )"
            >
                <template v-slot:activator="{ on }">
                    <v-btn
                        color="green white--text"
                        small
                        @click="openViewCallRatingResultsDialog()"
                        v-on="on"
                        style="margin:5px"
                    >
                        <v-icon>mdi-marker-check</v-icon> View Call Rating
                    </v-btn>
                </template>
                <span>View Call Rating</span>
            </v-tooltip>
        </v-toolbar>


        <v-data-table
            fixed-header
            :height="sub_tab_height"
            :headers="document_details_headers"
            :items="call_recordings"
            item-key="_id"
            :single-select="true"
            @click:row="selectDocumentsTabRow"
            class="elevation-1"
            hide-default-footer
            :loading="recordings_loading"
            :disable-pagination="true"
        >
            <!-- Display create date -->
            <template v-slot:item.created="{ item }">
                <td>{{item.created.split('T')[0]}}</td>
            </template>
            <!-- Display recording_imported -->
            <template v-slot:item.recording_imported="{ item }">
                <td v-if="item.recording_imported === true && item.twilio_call_obj !== null">YES</td>
                <td v-else-if="item.recording_imported === false && item.twilio_call_obj !== null">NO</td> <!-- This means that the call was attempted, but the caller didn't proceed -->
                <td v-else>NOT-YET</td>
            </template>
            <!-- Display call_rating -->
            <template v-slot:item.call_rating="{ item }">
                <td v-if="item.call_rating !== null">YES</td>
                <td v-else>NO</td>
            </template>
            <!-- Display call_rating -->
            <template v-slot:item._id="{ item }">
                <td
                    style="background:green; color:white;"
                    v-if="item.call_rating !== null"
                >
                    RATING-DONE
                </td>
                <td
                    style="background:green; color:white;"
                    v-else-if="(
                        item.call_rating === null &&
                        item.recording_imported === false &&
                        item.twilio_call_obj !== null
                    )"
                >
                    CALL-NOT-ANSWERED
                </td>
                <td
                    style="background:red; color:white;"
                    v-else-if="(
                        item.call_rating === null &&
                        item.recording_imported === true &&
                        item.twilio_call_obj !== null
                    )"
                >
                    PENDING-RATING
                </td>
                <td
                    style="background:red; color:white;"
                    v-else-if="(
                        item.call_rating === null &&
                        item.recording_imported === false &&
                        item.twilio_call_obj === null
                    )"
                >
                    PENDING-DOWNLOAND-AND-RATING
                </td>
                <td v-else>NO</td>
            </template>
            <!-- Display rating-score -->
            <template v-slot:item.score="{ item }">
                <td v-if="item.call_rating !== null">{{ item.call_rating.total_score }}</td>
                <td v-else>N/A</td>
            </template>
            <!-- User who created the details -->
            <template v-slot:item.captured_by="{ item }" >
                <td v-if="item.captured_by !== null">
                    <span v-if="item.captured_by.entity !== null">
                        {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                    </span>
                    <span v-else>N/A</span>
                </td>
            </template>
        </v-data-table>
        <ListenToCall
            v-if="selectedRecording !== null && recordingData !== null"
            :dialog="open_listen_to_call_dialog"
            :recording_data="recordingData"
        />
        <CallRatingDialog
            v-if="collectionObj !== null && selectedRecording !== null && recordingData !== null"
            :dialog="open_call_rating_dialog"
            :recording_data="recordingData"
            :recording_obj="selectedRecording"
            :entity_id="collectionObj.entity._id"
        />
        <ViewCallRatingResultsDialog
            v-if="selectedRecording !== null && recordingData !== null"
            :dialog="open_view_call_rating_results_dialog"
            :recording_data="recordingData"
            :recording_obj="selectedRecording"
        />
    </div>
</template>

<script>
import { eventBus } from "../../../../../../main";
import { emitAlert, getCallRecording } from "../../../../../../utils/api";
// import ViewDocumentDialog from '../../../../../../components/common_dialogs/view_document_info';
import { entityProcessEntity } from "../../../../gql/mutations";
import { getEntityCallRecordings } from "../../../../gql/queries";
import ListenToCall from "../../../../dialogs/listen_to_call.vue";
import CallRatingDialog from "../../../../dialogs/call_rating.vue";
import ViewCallRatingResultsDialog from "../../../../dialogs/view_call_rating.vue";
import { store } from "../../../../../../../store";
import { BUSINESS_PARTNER_TYPES } from "../../../../../../../config";

export default {
    name: "CallRecordings",
    components: {
        // ViewDocumentDialog
        ListenToCall,
        CallRatingDialog,
        ViewCallRatingResultsDialog,
    },
    props: {
        collectionObj: {
            required: true,
        },
    },
    data: () => ({
        sub_tab_height:'450',
        document_details_headers: [
            { text: 'Call-SID', value: 'call_sid' },
            { text: 'Recording Available', value: 'recording_imported' },
            { text: 'Call Rated', value: 'call_rating' },
            { text: 'Status', value: '_id' }, // The _id is a temporary hack
            { text: 'Score', value: 'score' },
            { text: 'Reason', value: 'reason' },
            { text: 'Created', value: 'created' },
            { text: 'Created By', value: 'captured_by' },
        ],

        selectedRecording: null,
        selectedRecordingRow: null,

        recordings_loading: false,
        call_recordings: [],
        recordingData: null,

        open_view_loan_document_dialog: false,
        open_listen_to_call_dialog: false,
        open_call_rating_dialog: false,
        open_view_call_rating_dialog: false,
        open_view_call_rating_results_dialog: false,
    }),
    computed: {
        // Call Quality Assurer
        isCentralUnderwriterAdminUser() {
            const result = store.state.currentUserAccount.roles.filter(role => {
                                                            return (
                                                                role.role.level.includes(BUSINESS_PARTNER_TYPES.CENTRAL_UNDERWRITER) &&
                                                                role.role.name.toLowerCase() ===  'admin - central underwriter'
                                                            );
                                                        }).length > 0;

            return result;
        },
        isCallQualityAssurer() {
            const result = store.state.currentUserAccount.roles.filter(role => {
                                                            return (
                                                                role.role.level.includes(BUSINESS_PARTNER_TYPES.CENTRAL_UNDERWRITER) &&
                                                                role.role.name.toLowerCase() ===  'call quality assurer'
                                                            );
                                                        }).length > 0;

            return result;
        }
    },
    mounted() {
        eventBus.$on('CLOSE_VIEW_DOCUMENT_DIALOG', this.closeViewServerDocumentsDialog);
        eventBus.$on('CLOSE_CALL_RECORDING_LISTEN_DIALOG', this.closeListenToCallRecordingDialog);
        eventBus.$on('CLOSE_CALL_RATING_DIALOG', this.closeCallRatingDialog);
        eventBus.$on('CLOSE_VIEW_CALL_RATING_RESULTS_DIALOG', this.closeViewCallRatingResultsDialog);
    },
    methods: {
        async refreshCallRecordings() {
            this.recordings_loading = true;
            this.call_recordings = [];

            try {
                const res = await this.$apollo
                                            .query({
                                                query: getEntityCallRecordings,
                                                // fetchPolicy: 'network-only',
                                                fetchPolicy: 'no-cache',
                                                errorPolicy: 'all',
                                                variables: {
                                                    objectType: 'ENTITY',
                                                    pagination: {
                                                        page: 1,
                                                        limit: 50
                                                    },
                                                    searchInput: {
                                                        _id: this.collectionObj.entity._id
                                                    }
                                                },
                                            });

                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.transactions_loading = false;
                    return;
                }

                this.call_recordings = res.data.getBusinessObject.business_objects[0].call_recordings;
                this.recordings_loading = false;
                
            } catch (error) {
                this.transactions_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
        async downloadCallRecording() {
            this.recordings_loading = true;
            this.call_recordings = [];

            try {
                const payload = {
                    OP_TYPE: 'DOWNLOAD_CALL_RECORDING',
                    _id: this.collectionObj._id,
                    call_recordings: [ { _id: this.selectedRecording._id } ]
                };
                const recRes = await this.$apollo
                                        .mutate({
                                            mutation: entityProcessEntity,
                                            variables: {
                                                input: payload
                                            },
                                        });
                if (recRes.data.entityProcessEntity.errors === true) {
                    emitAlert(recRes.data.entityProcessEntity.message);
                    this.submitting = false;
                    return;
                }

                const entityObj = recRes.data.entityProcessEntity.business_object;
                this.call_recordings = entityObj.call_recordings;

                for (const callRecording of this.call_recordings) {
                    if (callRecording._id === this.selectedRecording._id) {
                        this.selectedRecording = callRecording;
                        break;
                    }
                }

                this.recordings_loading = false;
                
            } catch (error) {
                this.transactions_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
        async playCallRecording() {
            try {
                const callRecordingRes = await getCallRecording(this.collectionObj._id, this.selectedRecording._id);
                // console.log(callRecordingRes)


                const recordingUrl = window.URL.createObjectURL(new Blob([callRecordingRes], {type: 'audio/mpeg'}));
                // console.log(recordingUrl)
                this.recordingData = recordingUrl;
                this.open_listen_to_call_dialog = true;
            } catch (error) {
                console.log(error)
            }
        },
        closeListenToCallRecordingDialog() {
            this.recordingData = null;
            this.open_listen_to_call_dialog = false;
        },
        async openCallRatingDialog() {
            try {
                const callRecordingRes = await getCallRecording(this.collectionObj._id, this.selectedRecording._id);
                // console.log(callRecordingRes)


                const recordingUrl = window.URL.createObjectURL(new Blob([callRecordingRes], {type: 'audio/mpeg'}));
                // console.log(recordingUrl)
                this.recordingData = recordingUrl;
                this.open_call_rating_dialog = true;
            } catch (error) {
                console.log(error)
            }
        },
        async closeCallRatingDialog(updatedEntity) {
            if (updatedEntity !== null) {
                await this.refreshCallRecordings();
            }
            this.recordingData = null;
            this.open_call_rating_dialog = false;
        },
        async openViewCallRatingResultsDialog() {
            try {
                const callRecordingRes = await getCallRecording(this.collectionObj._id, this.selectedRecording._id);
                // console.log(callRecordingRes)


                const recordingUrl = window.URL.createObjectURL(new Blob([callRecordingRes], {type: 'audio/mpeg'}));
                // console.log(recordingUrl)
                this.recordingData = recordingUrl;
                this.open_view_call_rating_results_dialog = true;
            } catch (error) {
                console.log(error)
            }
        },
        closeViewCallRatingResultsDialog() {
            this.recordingData = null;
            this.open_view_call_rating_results_dialog = false;
        },
        selectDocumentsTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedRecording = item;
                this.selectedRecordingRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedRecording = null;
                this.selectedRecordingRow = null;
            }
        },
        openViewDocumentDialog() {
            this.open_view_loan_document_dialog = true;
        },
        closeViewServerDocumentsDialog() {
            this.open_view_loan_document_dialog = false;
        },
    },
    watch: {
        async collectionObj(val) {
            // console.log('CHANGED', val);
            // console.log(val)
            if (val !== null) {
                await this.refreshCallRecordings()
            }

            if (this.selectedRecordingRow !== null) {
                // Unselect currently selected row
                this.selectedRecordingRow.select(false);
            }
            this.selectedRecording = null;
            this.selectedRecordingRow = null;
        }
    }
};
</script>
<style>
  .v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
    transform: rotate(180deg);
  }
</style>
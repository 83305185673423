import gql from 'graphql-tag'

export const finProcessFinanceCalendarYear = gql`
    mutation finProcessFinanceCalendarYear($input: FinanceCalendarYearInput) {
        finProcessFinanceCalendarYear(input: $input) {
            errors
            message
            business_object {
                ... on FinanceCalendarYear {
                    __typename
                    _id
                    name
                    value
                    status
                    created

                    fin_months {
                        _id
                        name
                        value
                        status
                        created
                    }
                }
            }
        }
    }
`

export const finProcessFinanceCalendarMonth = gql`
    mutation finProcessFinanceCalendarMonth($input: FinanceCalendarMonthInput) {
        finProcessFinanceCalendarMonth(input: $input) {
            errors
            message
            business_object {
                ... on FinanceCalendarMonth {
                    __typename
                    _id
                    name
                    value
                    status
                    created
                }
            }
        }
    }
`
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Add Premium Payment</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeAddSchemeTransactionDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row style="background:#eaeaea; padding-bottom:10px">
                        <v-col cols="12" sm="3">
                            <v-menu
                                v-model="transaction_date_menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="premium_details.transaction_date"
                                    color="secondary"
                                    label="Transaction Date"
                                    hint="The date on which the transaction occurred"
                                    persistent-hint
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    color="secondary"
                                    v-model="premium_details.transaction_date"
                                    @input="transaction_date_menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                v-model="premium_details.additional_reference"
                                color="secondary"
                                label="Customer Reference"
                                hint="For EFT or CASH or ATM clients"
                                persistent-hint
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-select
                                v-model="premium_details.payment_method"
                                :items="system_finance_accounts"
                                color="secondary"
                                item-text="name"
                                item-value="_id"
                                label="Payment Method"
                                :disabled="refreshing_system_finance_accounts_list"

                                hint="You can click the refresh icon next to the field if the system accounts list is incomplete"
                                persistent-hint

                                required
                            >
                                <!-- Format prepend inner icon -->
                                <template v-slot:prepend-inner>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon
                                                v-on="on"
                                                color="success"
                                                @click="refreshSystemAccountsLookupList()"
                                            >
                                                mdi-refresh
                                            </v-icon>
                                        </template>
                                        <span>Refresh System Accounts List</span>
                                    </v-tooltip>
                                </template>
                            </v-select>
                            <v-progress-linear
                                color="green"
                                class="mb-0"
                                height="4"
                                indeterminate
                                :active="refreshing_system_finance_accounts_list"
                            ></v-progress-linear>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-text-field
                                v-model="premium_details.amount"
                                color="secondary"
                                label="Amount"
                                type="number"
                                prefix="R"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <!-- Progress Section -->
                <v-progress-linear
                    color="green"
                    class="mb-0"
                    height="10"
                    indeterminate
                    :active="submitting"
                ></v-progress-linear>
                <v-list flat v-if="submitting">
                    <v-subheader>Saving Scheme Transaction</v-subheader>
                    <!-- <v-list-item-group v-model="item" color="primary"> -->
                    <v-list-item-group color="primary">
                        <v-list-item
                            v-for="(item, i) in backendActionItems"
                            :key="i"
                        >
                            <v-list-item-icon>
                                <v-icon>done</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ item }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>

                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        large
                        color="success"
                        :disabled="submitSchemeDocumentBtnDisabled()"
                        @click="saveSchemeTransaction()"
                    >
                        <v-icon large>save</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";

import { getSystemFinanceAccounts } from "../gql/queries";
import { createFinanceTransaction } from "../gql/mutations";

export default {
    components: {
        
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        scheme: {
            type: Object,
            required: false,
            default: () => {
                return {
                    // name,
                    documents: []
                }
            }
        },
    },
    data: () => ({
        server_resp_obj: null,
        transaction_date_menu: false,
        submitting: false,

        premium_details: {
            transaction_date: null,
            additional_reference: null,
            payment_method: null,
            amount: 0,
        },

        backendActionItems: [],

        refreshing_system_finance_accounts_list: false,
        system_finance_accounts: [],
    }),
    methods: {
        closeAddSchemeTransactionDialog() {
            eventBus.$emit('CLOSE_ADD_SCHEME_PREMIUM_PAYMENT_DIALOG', this.server_resp_obj);
        },
        resetForm() {
            this.premium_details = {
                transaction_date: null,
                additional_reference: null,
                payment_method: null,
                amount: 0,
            };
            this.server_resp_obj = null;
            this.backendActionItems = [];
        },

        submitSchemeDocumentBtnDisabled() {
            if (
                this.premium_details.transaction_date === null ||
                this.premium_details.payment_method === null ||
                this.premium_details.amount === null ||
                this.premium_details.amount === undefined ||
                parseFloat(this.premium_details.amount) <= 0 ||
                isNaN(parseFloat(this.premium_details.amount))
            ) {
                return true;
            }
            
            return false;
        },
        
        async saveSchemeTransaction() {
            this.submitting = true;
            try {
                const transactionObj = {
                    OP_TYPE: 'CREATE',
                    
                    transaction_date: this.premium_details.transaction_date,
                    from: this.premium_details.payment_method,
                    to: this.scheme._id,
                    amount: parseFloat(this.premium_details.amount),
                    reference: 'SCHEME PAYMENT',
                    additional_reference: this.premium_details.additional_reference,
                };

                const res = await this.$apollo
                                            .mutate({
                                                mutation: createFinanceTransaction,
                                                variables: {
                                                    input: transactionObj
                                                },
                                            });

                this.submitting = false;
                if (res.data.finProcessFinanceAccountTransaction.errors === true) {
                    emitAlert(res.data.finProcessFinanceAccountTransaction.message);
                    return;
                }

                emitSuccess(res.data.finProcessFinanceAccountTransaction.message);

                this.server_resp_obj = res.data.finProcessFinanceAccountTransaction.business_object;
                this.closeAddSchemeTransactionDialog();

                // Reset form
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.finProcessFinanceAccountTransaction.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },

        async refreshSystemAccountsLookupList() {
            this.refreshing_system_finance_accounts_list = true;
            try {
                // Load System Accounts
                const res = await this.$apollo
                                    .query({
                                        query: getSystemFinanceAccounts,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'FINANCE_SYSTEM_ACCOUNT',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                this.refreshing_system_finance_accounts_list = false;

                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }
                this.system_finance_accounts = res.data.getBusinessObject.business_objects.filter(acc => {
                    return (
                        acc.name === 'CARD_SWIPE' ||
                        acc.name === 'CASH' ||
                        acc.name === 'EFT' ||
                        acc.name === 'ATM'
                    );
                });
            } catch (error) {
                this.refreshing_system_finance_accounts_list = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
    },
    async mounted() {
        try {
            // System finance accounts
            this.refreshing_system_finance_accounts_list = true;
            let res = await this.$apollo
                                    .query({
                                        query: getSystemFinanceAccounts,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'FINANCE_SYSTEM_ACCOUNT',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.refreshing_system_finance_accounts_list = false;
                return;
            }

            this.refreshing_system_finance_accounts_list = false;
            this.system_finance_accounts = res.data.getBusinessObject.business_objects.filter(acc => {
                return (
                    acc.name === 'CARD_SWIPE' ||
                    acc.name === 'CASH' ||
                    acc.name === 'EFT' ||
                    acc.name === 'ATM'
                );
            });
        } catch (error) {
            this.refreshing_system_finance_accounts_list = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
        }
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Loan Debt Status</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-text-field
                                :value="formatNumber(debt_status_details.total_outstanding_to_date)"
                                prefix="R"
                                label="Total Outstanding To Date"
                                color="secondary"
                                required
                                disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12">
                            <v-text-field
                                :value="formatNumber(debt_status_details.total_outstanding)"
                                prefix="R"
                                label="Total Required For Closing off the loan"
                                color="secondary"
                                required
                                disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12">
                            <v-text-field
                                :value="formatNumber(debt_status_details.total_collected)"
                                prefix="R"
                                label="Total Collected To Date"
                                color="secondary"
                                required
                                disabled
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        <v-overlay :value="submitting" :absolute="true">
                            <v-progress-circular
                                color="white"
                                indeterminate
                                size="150"
                                width="9"
                            >
                                <h3 class="text-center">Loading...</h3>
                            </v-progress-circular>
                        </v-overlay>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <!-- <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom v-if="search_field_selected">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                @click="submitSearchQuery()"
                            >
                                <v-icon large>search</v-icon>
                            </v-btn>
                        </template>
                        <span>Submit Search Query</span>
                    </v-tooltip>
                </v-card-actions> -->
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { eventBus } from '../../../main';
import { emitAlert } from '../../../utils/api';
import { getLoanDebtStatus } from "../gql/mutations";
import converter from "../../../mixins/converter";
export default {
    name: 'ViewDebitOrderStatusDialog',
    mixins: [converter],
    components: {},
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        loan: {
            type: Object,
            required: true,
            default: null
        },
    },
    data: () => ({
        effective_date_menu: false,
        debt_status_details: {
            total_collected: null,
            total_outstanding: null,
            total_outstanding_to_date: null,
        },

        submitting: true
    }),
    methods: {
        closeDialog() {
            eventBus.$emit('CLOSE_VIEW_DEBT_STATUS_DIALOG', null);
        },
    },
    mounted() {
        
    },
    computed: {},
    watch: {
        async dialog(val) {
            if (val === true) {
                this.submitting = true;

                const payloadObj = {
                    OP_TYPE: 'CHECK_LOAN_BALANCE',
                    _id: this.loan._id,
                };

                try {
                    const res = await this.$apollo
                                            .mutate({
                                                mutation: getLoanDebtStatus,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                    this.submitting = false;

                    this.debt_status_details.total_collected = res.data.businessProcessLoan.business_object.debt_status.total_collected;
                    this.debt_status_details.total_outstanding = res.data.businessProcessLoan.business_object.debt_status.total_outstanding;
                    this.debt_status_details.total_outstanding_to_date = res.data.businessProcessLoan.business_object.debt_status.total_outstanding_to_date;
                } catch (error) {
                    this.submitting = false;
                    console.log(error);
                    if (error !== undefined) {
                        emitAlert(error.data.getBusinessObject.message);
                    } else {
                        emitAlert('The server has taken too long in responding to your request');
                    }
                }
            }

            if (val === false) {
                this.debt_status_details.total_collected = null;
                this.debt_status_details.total_outstanding = null;
                this.debt_status_details.total_outstanding_to_date = null;
            }
        }
    }
}
</script>
<style scoped>

</style>
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Add Bank</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeAddBankDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <!-- Bank Name -->
                        <v-col cols="12" sm="12">
                            <v-text-field
                                v-model="name"
                                color="secondary"
                                label="Bank Name"
                                required
                            ></v-text-field>
                        </v-col>
                        <!-- Branch Code -->
                        <v-col cols="12" sm="12">
                            <v-text-field
                                v-model="branch_code"
                                color="secondary"
                                label="Universal Branch Code"
                                type="number"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting Bank Details
                        <v-progress-linear
                            indeterminate
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submitBankBtnDisabled()"
                                @click="submitBank()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Submit Bank Details</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../../utils/api";
import { eventBus } from "../../../../main";
import { createBank } from "../gql/mutations";
export default {
    name: 'SearchMembersDialog',
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        name: null,
        branch_code: null,
        server_resp_bank: null,
        submitting: false,
    }),
    methods: {
        closeAddBankDialog() {
            eventBus.$emit('CLOSE_ADD_BANK_DIALOG', this.server_resp_bank);
        },
        resetForm() {
            this.name = null;
            this.branch_code = null;
            this.server_resp_bank = null;
        },
        submitBankBtnDisabled() {
            if (this.name === null || this.branch_code === null) {
                return true;
            }

            if (typeof(this.name) === 'string') {
                if (this.name.trim() === '') {
                    this.name = null;
                    return true;
                }
            }

            if (typeof(this.branch_code) === 'string') {
                if (this.branch_code.trim() === '') {
                    this.branch_code = null;
                    return true;
                }
            }

            return false;
        },
        async submitBank() {
            // Validate
            if (this.name === null || this.branch_code === null) {
                emitAlert("Please fill in all fields");
                return;
            }

            this.submitting = true;

            try {
                const res = await this.$apollo
                                            .mutate({
                                                mutation: createBank,
                                                variables: {
                                                    input: {
                                                        name: this.name,
                                                        universal_branch_code: this.branch_code,
                                                    }
                                                },
                                            });
                if (res.data.lookupProcessBank.errors === true) {
                    emitAlert(res.data.lookupProcessBank.message);
                    this.submitting = false;
                    return;
                }

                emitSuccess(res.data.lookupProcessBank.message);
                
                this.submitting = false;
                this.server_resp_bank = res.data.lookupProcessBank.business_object;
                this.closeAddBankDialog();

                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.lookupProcessBank.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        }
    },
    mounted() {
        
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
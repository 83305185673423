<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Add New Credit Primary Product</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeAddProductDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-stepper v-model="e1">
                <v-stepper-header>
                    <v-stepper-step :complete="e1 > 1" step="1" color="success">Details</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="2" color="success">Processing</v-stepper-step>
                </v-stepper-header>
            
                <v-stepper-items>
                    <!-- Basic Details -->
                    <v-stepper-content step="1">
                        <v-card>
                            <v-card-text>
                                <v-row style="background: #cfe4e9">
                                    <v-col cols="12" sm="12">
                                        <h3>Basic Details</h3>
                                    </v-col>

                                    <v-col cols="12" sm="12">
                                        <v-text-field
                                            v-model="product_details.name"
                                            :rules="name_rules"
                                            ref="name_field"
                                            color="secondary"
                                            label="Product Name"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <!-- <v-col cols="12" sm="2">
                                        <v-text-field
                                            v-model="product_details.max_payment_holidays"
                                            prefix="DAYS"
                                            color="secondary"
                                            label="Maximum Payment Holidays"
                                            type="number"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-text-field
                                            v-model="product_details.min_loan_amount"
                                            prefix="ZAR"
                                            color="secondary"
                                            label="Minimum Loan Amount"
                                            type="number"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-text-field
                                            v-model="product_details.max_loan_amount"
                                            prefix="ZAR"
                                            color="secondary"
                                            label="Maximum Loan Amount"
                                            type="number"
                                            required
                                        ></v-text-field>
                                    </v-col> -->
                                </v-row>
                                
                                <!-- <v-row style="background: #ffeeb6">
                                    <v-col cols="12" sm="12" >
                                        <h3>Installments</h3>
                                    </v-col>
                                    <v-col cols="12" sm="12">
                                        <v-text-field
                                            v-model="product_details.min_number_of_installments"
                                            color="secondary"
                                            label="Minimum Installments"
                                            type="number"
                                            hint="Minimum number of installments"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                </v-row> -->
                                
                                <!-- <v-row style="background: #e9e9e9">
                                    <v-col cols="12" sm="12" >
                                        <h3>Fees</h3>
                                    </v-col>
                                    <v-col cols="12" sm="4" >
                                        <v-text-field
                                            v-model="product_details.monthly_fixed_fee"
                                            color="secondary"
                                            label="Fixed Monthly Fee"
                                            type="number"
                                            prefix="ZAR"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" >
                                        <v-text-field
                                            v-model="product_details.monthly_finance_charge_percentage"
                                            color="secondary"
                                            label="Monthly Finance Charge (%)"
                                            type="number"
                                            prefix="%"
                                            required
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" >
                                        <v-text-field
                                            v-model="product_details.monthly_finance_charge_amount"
                                            color="secondary"
                                            label="Monthly Finance Charge Amount (Fixed)"
                                            type="number"
                                            prefix="ZAR"
                                            required
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                </v-row> -->
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    large
                                    color="success"
                                    :disabled="submitBtnDisabled()"
                                    @click="submitProduct()"
                                >
                                    <v-icon large>save</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Submission Results Section -->
                    <v-stepper-content step="2">
                        <v-card>
                            <v-card-text class="text-center">
                                <v-progress-linear
                                    color="green"
                                    class="mb-0"
                                    height="10"
                                    indeterminate
                                    :active="submitting"
                                ></v-progress-linear>
                            </v-card-text>
                            <v-list flat>
                                <v-subheader>Saving Product Info</v-subheader>
                                <!-- <v-list-item-group v-model="item" color="primary"> -->
                                <v-list-item-group color="primary">
                                    <v-list-item
                                        v-for="(item, i) in backendActionItems"
                                        :key="i"
                                    >
                                        <v-list-item-icon>
                                            <v-icon>done</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="item"></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    large
                                    color="success"
                                    @click="closeAddProductDialog()"
                                >
                                    <v-icon large>done</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>
    </v-row>
</template>
<script>

import { emitAlert, emitSuccess } from "../../../../../utils/api";
import { eventBus } from "../../../../../main";

import { productProcessLoanProductPrimary } from "../gql/mutations";

export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        e1: 1,
        
        product_details: {
            name: '',

            // // Payment Holidays
            // max_payment_holidays: 0,

            // // Loan Amount
            // min_loan_amount: 0,
            // max_loan_amount: 0,

            // // Number of Installments
            // min_number_of_installments: 0,

            // // Fees
            // monthly_fixed_fee: 0,
            // monthly_finance_charge_percentage: 0,
            // monthly_finance_charge_amount: 0,
        },

        server_response_obj: null,
        submitting: false,
        backendActionItems: [],

        name_rules: [
            v => !!v || 'Name is required',
            v => v.length >= 2 || 'Min 2 characters'
        ],
    }),
    methods: {
        closeAddProductDialog() {
            eventBus.$emit('CLOSE_ADD_PRODUCT_DIALOG', this.server_response_obj);
        },

        resetForm() {
            this.e1 = 1;

            this.submitting = false;
            this.server_response_obj = null;

            this.backendActionItems = [];
            this.product_details = {
                name: '',

                // Payment holidays
                max_payment_holidays: 0,
                
                // Loan Amount
                min_loan_amount: 0,
                max_loan_amount: 0,

                // Number of Installments
                min_number_of_installments: 0,

                // Fees
                monthly_fixed_fee: 0,
                monthly_finance_charge_percentage: 0,
                monthly_finance_charge_amount: 0,
            };

            this.$refs.name_field.resetValidation();
        },

        submitBtnDisabled() {
            // NB: We will not enforce broker commission and markup to be greate than zero
            // if (
            //     this.product_details.name.trim().length < 2 ||

            //     // pyament holidays
            //     isNaN(parseFloat(this.product_details.max_payment_holidays)) ||

            //     // min loan amount
            //     parseFloat(this.product_details.min_loan_amount) === 0 ||
            //     isNaN(parseFloat(this.product_details.min_loan_amount)) ||

            //     // max loan amount
            //     parseFloat(this.product_details.max_loan_amount) === 0 ||
            //     isNaN(parseFloat(this.product_details.max_loan_amount)) ||
                
            //     // min number of installments
            //     parseFloat(this.product_details.min_number_of_installments) === 0 ||
            //     isNaN(parseFloat(this.product_details.min_number_of_installments)) ||

            //     // fixed monthly fee
            //     parseFloat(this.product_details.monthly_fixed_fee) === 0 ||
            //     isNaN(parseFloat(this.product_details.monthly_fixed_fee)) ||

            //     // monthly finance charge percentage (this can be zero)
            //     isNaN(parseFloat(this.product_details.monthly_finance_charge_percentage)) ||

            //     // fixed monthly finance charge (this can be zero)
            //     isNaN(parseFloat(this.product_details.monthly_finance_charge_amount))
            // ) {
            if (this.product_details.name.trim().length < 2) {
                return true;
            }

            this.product_details.name = this.product_details.name.trim();

            return false;
        },
        
        async submitProduct() {
            // Move to next page
            this.e1 = 2;
            this.submitting = true;

            // Now submit the product to the server
            this.backendActionItems.push("Submiting product");

            try {
                this.submitting = true;

                // // Sanitize numerical fields
                // this.product_details.max_loan_amount = parseFloat(this.product_details.max_loan_amount);
                // this.product_details.max_payment_holidays = parseFloat(this.product_details.max_payment_holidays);
                // this.product_details.min_loan_amount = parseFloat(this.product_details.min_loan_amount);
                // this.product_details.min_number_of_installments = parseFloat(this.product_details.min_number_of_installments);
                // this.product_details.monthly_finance_charge_amount = parseFloat(this.product_details.monthly_finance_charge_amount);
                // this.product_details.monthly_finance_charge_percentage = parseFloat(this.product_details.monthly_finance_charge_percentage);
                // this.product_details.monthly_fixed_fee = parseFloat(this.product_details.monthly_fixed_fee);

                const payloadObj = {
                    OP_TYPE: 'CREATE',

                    ...this.product_details
                };

                const res = await this.$apollo
                                            .mutate({
                                                mutation: productProcessLoanProductPrimary,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.productProcessLoanProductPrimary.errors === true) {
                    emitAlert(res.data.productProcessLoanProductPrimary.message);
                    this.submitting = false;
                    return;
                }
                
                this.submitting = false;
                this.server_response_obj = res.data.productProcessLoanProductPrimary.business_object;

                this.closeAddProductDialog();
                emitSuccess(res.data.productProcessLoanProductPrimary.message);

                // Reset fields
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.productProcessLoanProductPrimary.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        }
    },
    async mounted() {
        
    },
    watch: {
        
    }
}
</script>
<style scoped>
.error-multi-line {
  white-space: pre-line;
}

</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticClass:"pa-0",attrs:{"color":"secondary","dark":"","height":"50","extension-height":"3"}},[_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"10px"},attrs:{"sm":"4"}},[(_vm.SEARCH_MODE === false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.loadMoreData()}}},on),[_c('v-icon',[_vm._v("cloud_download")])],1)]}}],null,false,3262684405)},[_c('span',[_vm._v("Load More Data")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.refreshDataTable()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh")])]),((
                        _vm.selectedBatchPayment !== null &&
                        _vm.selectedBatchPayment.total_amount > 0
                    ))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.downloadSafricanExcelReport()}}},on),[_c('v-icon',[_vm._v("mdi-file-excel")]),_vm._v(" Safrican ")],1)]}}],null,false,589077986)},[_c('span',[_vm._v("Download Safrican Spreadsheet")])]):_vm._e()],1),_c('v-col',{staticStyle:{"margin-top":"10px"},attrs:{"sm":"8"}},[_c('div',{staticClass:"text-right"},[((
                            _vm.selectedBatchPayment !== null &&
                            _vm.selectedBatchPayment.date_paid === null
                        ))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"purple white--text"},on:{"click":function($event){return _vm.processBatchPayment()}}},on),[_vm._v(" Process Batch Payment ")])]}}],null,false,3044765394)},[_c('span',[_vm._v("Process Batch Payment")])]):_vm._e(),((
                            _vm.selectedBatchPayment !== null &&
                            _vm.selectedBatchPayment.bank_feed === null
                        ))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"green white--text"},on:{"click":function($event){return _vm.openBankFeedAssociationDialog()}}},on),[_vm._v(" Associate Bank Feed ")])]}}],null,false,2546738582)},[_c('span',[_vm._v("Associate Bank Feed")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"amber black--text"},on:{"click":function($event){return _vm.openCaptureBatchPaymentDialog()}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v("Add Batch Payment")])])],1)])],1)],1),_c('multipane',{staticClass:"horizontal-panes",staticStyle:{"height":"100%"},attrs:{"layout":"horizontal"}},[_c('div',{staticClass:"pane",style:({ height: '50%', maxHeight: '50%'})},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.main_tab_height,"headers":_vm.batch_payment_details_headers,"items":_vm.batch_payments_list,"item-key":"_id","single-select":true,"item-class":"secondary","hide-default-footer":"","disable-pagination":true,"loading":_vm.data_table_loading,"loading-text":"Loading... Please wait"},on:{"click:row":_vm.selectMainTableRow},scopedSlots:_vm._u([{key:"item.batch_number",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v("UB-"+_vm._s(item.batch_number))])]}},{key:"item.total_amount",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v("R "+_vm._s(_vm.formatNumber(item.total_amount)))])]}},{key:"item.captured_by",fn:function(ref){
                        var item = ref.item;
return [(item.captured_by !== null)?_c('td',[(item.captured_by.entity !== null)?_c('span',[_vm._v(" "+_vm._s(item.captured_by.entity.name)+" "+_vm._s(item.captured_by.entity.surname)+" ")]):_c('span',[_vm._v("N/A")])]):_vm._e()]}},{key:"item.processed_by",fn:function(ref){
                        var item = ref.item;
return [(item.processed_by !== null)?_c('td',[(item.processed_by.entity !== null)?_c('span',[_vm._v(" "+_vm._s(item.processed_by.entity.name)+" "+_vm._s(item.processed_by.entity.surname)+" ")]):_c('span',[_vm._v("N/A")])]):_vm._e()]}},{key:"item.date",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.date.split('T')[0]))])]}},{key:"item.created",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.created !== null ? item.created.split('T')[0] + ' @ ' + item.created.split('T')[1].substr(0,8) : ''))])]}},{key:"item.processing_date",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.processing_date.split('T')[0]))])]}},{key:"item.date_paid",fn:function(ref){
                        var item = ref.item;
return [(item.date_paid !== null)?_c('td',[_vm._v(_vm._s(item.date_paid.split('T')[0]))]):_vm._e()]}}])})],1),_c('multipane-resizer'),_c('div',{staticClass:"pane tab-nav",style:({ height: '30%', maxHeight: '50%', minHeight: '30%' })},[_c('v-tabs',{staticClass:"tab-nav",attrs:{"color":"transparent","hide-slider":"","height":"35px","grow":""},model:{value:(_vm.sub_tabs),callback:function ($$v) {_vm.sub_tabs=$$v},expression:"sub_tabs"}},[[_c('v-tab',{key:"batch_payment_policies_sub_tab",staticStyle:{"max-width":"210px","font-size":"12px"},attrs:{"active-class":"secondary white--text"}},[_c('span',{staticClass:"tab-title text-truncate"},[_vm._v(" Policies ")]),_c('div',{staticClass:"spacer"})])]],2),_c('v-tabs-items',{model:{value:(_vm.sub_tabs),callback:function ($$v) {_vm.sub_tabs=$$v},expression:"sub_tabs"}},[_c('v-toolbar',{staticClass:"pa-0",attrs:{"color":"secondary","dark":"","height":"50","extension-height":"3"}},[((
                            _vm.sub_tabs === 0 &&
                            _vm.selectedBatchPayment !== null
                        ))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"amber black--text","small":""},on:{"click":function($event){return _vm.refreshBatchPaymentPolicies()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}],null,false,1837314840)},[_c('span',[_vm._v("Refresh Policies")])]):_vm._e()],1),_c('v-tab-item',{key:"batch_payment_policies_sub_tab"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.sub_tab_height,"headers":_vm.batch_payment_policy_headers,"items":_vm.batch_payment_policies,"item-key":"_id","single-select":true,"hide-default-footer":"","loading":_vm.policies_loading,"disable-pagination":true},on:{"click:row":_vm.toBeDefined},scopedSlots:_vm._u([{key:"item.policy.principal_member",fn:function(ref){
                        var item = ref.item;
return [(item.policy !== null)?_c('td',[_vm._v(_vm._s(item.policy.principal_member.name + ' ' + item.policy.principal_member.surname + ' (' + item.policy.principal_member.id_number + ')'))]):_vm._e()]}},{key:"item.policy",fn:function(ref){
                        var item = ref.item;
return [(item.policy !== null)?_c('td',[_vm._v("R "+_vm._s(item.policy.product.primary_product.underwriter_premium))]):_vm._e()]}},{key:"item.created",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.created.split('T')[0]))])]}},{key:"item.captured_by",fn:function(ref){
                        var item = ref.item;
return [(item.captured_by !== null)?_c('td',[(item.captured_by.entity !== null)?_c('span',[_vm._v(" "+_vm._s(item.captured_by.entity.name)+" "+_vm._s(item.captured_by.entity.surname)+" ")]):_c('span',[_vm._v("N/A")])]):_vm._e()]}}])})],1)],1)],1)],1),_c('CaptureBatchPaymentDialog',{attrs:{"dialog":_vm.open_add_batch_payment_dialog}}),(_vm.selectedBatchPayment !== null)?_c('BankFeedAssociationDialog',{attrs:{"id":_vm.selectedBatchPayment._id,"batch_payment":_vm.selectedBatchPayment,"dialog":_vm.open_bank_feed_association_dialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Delete Document</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeDeleteDocumentDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <div v-if="document !== null">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-if="document !== null"
                                    v-model="document.type"
                                    label="Document Type"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-if="document !== null"
                                    v-model="document.name"
                                    label="Document Name"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-if="document !== null"
                                    v-model="document.created"
                                    label="Created At"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12">
                                <v-textarea
                                    v-model="document.delete_reason"
                                    color="secondary"
                                    name="Delete Reason"
                                    filled
                                    label="Delete Reason"
                                    auto-grow
                                ></v-textarea>
                            </v-col>
                            <!-- <v-col cols="12" sm="6">
                                <v-text-field
                                    v-if="document !== null"
                                    v-model="document.deleted_by"
                                    label="Deleted By"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col> -->
                            <!-- <div style="background:white; height: 10px; width: 100%"></div> -->
                        </v-row>
                    </div>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submitBtnDisabled()"
                                @click="submitDeleteAction()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Submit Delete Action</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <DocPreviewDialog
            :dialog="open_doc_preview_dialog"
            :url="file_url"
            :doc_type="doc_type"
            @CLOSE_DOC_PREVIEW_DIALOG="closeDocPreviewDialog()"
        />
    </v-row>
</template>
<script>
import DocPreviewDialog from '../../../../../components/doc_preview';
import { emitAlert, emitSuccess } from "../../../../../utils/api";
import { eventBus } from "../../../../../main";

import { businessProcessProspectPolicy } from "../gql/mutations";

export default {
    components: {
        DocPreviewDialog
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        policy_original: {
            type: Object,
            default: null
        },
        policy_copy: {
            type: Object,
            default: null
        },
        document: {
            type: Object,
            required: false,
            default: () => {
                return {
                    type: null,
                    name: null,
                    mime_type: null,
                    created: null,
                    deleted: null,
                    deleted_by: null,
                    delete_reason: null,
                }
            }
        },
    },
    data: () => ({
        server_resp_obj: null,

        // File props
        file: '',
        file_url: null,
        doc_type: null,
        open_doc_preview_dialog: false,
        
    }),
    methods: {
        closeDeleteDocumentDialog() {
            eventBus.$emit('CLOSE_DELETE_POLICY_DOCUMENT_DIALOG', this.server_resp_obj);
        },
        closeDocPreviewDialog() {
            this.open_doc_preview_dialog = false;
        },

        resetForm() {
            this.document.delete_reason = null;

            this.submitting = false;
            this.server_resp_obj = null;
            this.document.delete_reason = null;
        },

        submitBtnDisabled() {
            if (this.document.delete_reason === null || this.document.delete_reason === undefined) {
                return true;
            }

            if (typeof(this.document.delete_reason) === 'string') {
                if (this.document.delete_reason.trim() === '') {
                    this.document.delete_reason = null;
                    return true;
                }
            }

            return false;
        },
        
        
        //--------------------PREVIEW FILES--------------------------//
        previewFile(fileObject) {
            // Set file oobject so we can trigger the readAsDataURL function
            const reader = new FileReader();

            reader.addEventListener("load", () => {
                // Set PDF-Viewer URL to the selected file Base64 String
                this.file_url = reader.result;
                if (fileObject.file.type === 'application/pdf') {
                    this.doc_type = 'pdf';
                } else {
                    this.doc_type = 'image';
                }

                // Display the preview dialog
                this.open_doc_preview_dialog = true;
            }, false);

            reader.readAsDataURL(fileObject.file);
        },

        async submitDeleteAction() {
            if (this.document.delete_reason !== null && this.document.delete_reason !== undefined) {
                this.submitting = true;

                try {
                    const payloadObj = {
                        OP_TYPE: 'REMOVE_DOCUMENT',
                        _id: this.policy_original._id,

                        documents: [
                            {
                                _id: this.document._id,
                                delete_reason: this.document.delete_reason,

                                // Placeholder fields
                                type: '',
                                mime_type: '',
                                name: '',
                            }
                        ]
                    };

                    const res = await this.$apollo
                                                .mutate({
                                                    mutation: businessProcessProspectPolicy,
                                                    variables: {
                                                        input: payloadObj
                                                    },
                                                });
                    if (res.data.businessProcessProspectPolicy.errors === true) {
                        emitAlert(res.data.businessProcessProspectPolicy.message);
                        this.submitting = false;
                        return;
                    }

                    this.submitting = false;
                    emitSuccess(res.data.businessProcessProspectPolicy.message);

                    this.server_resp_obj = res.data.businessProcessProspectPolicy.business_object;
                    this.closeDeleteDocumentDialog();

                    // this.resetForm();
                    this.submitting = false;
                    this.server_resp_obj = null;
                    this.document.delete_reason = null;
                } catch (error) {
                    this.submitting = false;
                    console.log(error);
                    if (error !== undefined) {
                        emitAlert(error.data.businessProcessProspectPolicy.message);
                    } else {
                        emitAlert('The server has taken too long in responding to your request')
                    }
                    
                    return;
                }
            }
        }
    },
    mounted() {
        
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
<template>
    <div>
        <v-toolbar
            color="secondary"
            dark
            class="pa-0"
            height="50"
            extension-height="3"
        >
            <v-row>
                <!-- Grid Buttons -->
                <v-col sm="5" style="margin-top:10px">
                    <v-tooltip bottom v-if="SEARCH_MODE === false">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                @click="loadMoreData()"
                                v-on="on"
                            >
                                <v-icon>cloud_download</v-icon>
                            </v-btn>
                        </template>
                        <span>Load More Data</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="openSearchBusinessProcessRunsDialog()"
                            >
                                <v-icon>search</v-icon>
                            </v-btn>
                        </template>
                        <span>Search for business process runs</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="SEARCH_MODE === false">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="refreshBusinessProcessRuns()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh</span>
                    </v-tooltip>
                </v-col>

                <!-- Search Result Indicator -->
                <v-col sm="3">
                    <v-card-actions v-if="SEARCH_MODE === true">
                        <h3>Search Results</h3>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="clearSearchResults()"
                                    v-on="on"
                                >
                                    <v-icon small>search</v-icon>
                                    <v-icon small>close</v-icon>
                                </v-btn>
                            </template>
                            <span>Clear Search Results</span>
                        </v-tooltip>
                    </v-card-actions>
                </v-col>

                <!-- Action Buttons -->
                <v-col sm="4" style="margin-top:10px">
                    <!-- <v-spacer></v-spacer> -->
                    <div class="text-right">
                        <!-- CREATE NEW BUSINESS PROCESS RUN -->
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="openCreateBusinessProcessRunDialog()"
                                    v-on="on"
                                >
                                    <v-icon medium>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Trigger Business Process</span>
                        </v-tooltip>
                    </div>
                </v-col>
            </v-row>
        </v-toolbar>
        <multipane class="horizontal-panes" layout="horizontal" style="height: 100%">
            <div class="pane" :style="{ height: '50%', maxHeight: '50%'}">
                <v-data-table
                    fixed-header
                    :height="main_tab_height"
                    :headers="business_process_runs_details_headers"
                    :items="business_process_runs"
                    item-key="_id"
                    class="elevation-1"
                    :single-select="true"
                    @click:row="selectMainTableRow"
                    item-class="secondary"
                    hide-default-footer
                    :loading="data_table_loading"
                    :disable-pagination="true"
                >
                    <!-- Display Formated capture date -->
                    <template v-slot:[`item.created`]="{ item }">
                        <td>{{item.created !== null ? item.created.split('T')[0]  + '  @  ' + item.created.split('T')[1].substr(0,8) : ''}}</td>
                    </template>
                    <!-- Captured By -->
                    <template v-slot:[`item.captured_by`]="{ item }" >
                        <td v-if="item.captured_by !== null && item.captured_by !== undefined">
                            {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                        </td>
                    </template>
                    <!-- Time Taken -->
                    <template v-slot:[`item.time_taken`]="{ item }" >
                        <td v-if="item.time_taken !== null && item.time_taken !== undefined">
                            {{item.time_taken / 1000}} sec
                        </td>
                    </template>
                </v-data-table>
            </div>
        </multipane>
        <CreateBusinessProcessDialog
            :dialog="open_create_business_process_run_dialog"
        />
        <SearchBusinessProcessesDialog
            :dialog="open_search_business_process_runs_dialog"
        />
    </div>
</template>
<script>
import { Multipane } from 'vue-multipane';
import CreateBusinessProcessDialog from './dialogs/create_business_process_run';
import SearchBusinessProcessesDialog from './dialogs/search_business_process_runs';

import { eventBus } from "../../main";
import { emitAlert } from "../../utils/api";

import { getBusinessProcessRuns } from "./gql/queries";

export default {
    components: {
        Multipane,
        CreateBusinessProcessDialog,
        SearchBusinessProcessesDialog,
    },

    computed: {
        
    },

    data: () => ({
        sub_tabs: null,
        main_tab_height: '600px',
        sub_tab_height:'180',

        // main data-table row
        selectedBusinessProcessRun: null,
        selectedRow: null,

        business_process_runs_details_headers: [
            { text: 'Fin Calendar Month', value: 'fin_calendar_month.name' },
            { text: 'Business Process', value: 'business_process.name' },
            { text: 'Created By', value: 'captured_by' },
            { text: 'Created', value: 'created' },
            { text: 'Time Taken', value: 'time_taken' },
            { text: 'Status', value: 'status' },
        ],
        
        business_process_runs: [],
        open_create_business_process_run_dialog: false,
        open_search_business_process_runs_dialog: false,

        data_table_loading: false,
        SEARCH_MODE: false,
        page: 1,
        limit: 50,
        
        selectedBusinessProcessRunCopy: null,
    }),

    methods: {
        //-------------------- OPEN DIALOGS ---------------------//
        openCreateBusinessProcessRunDialog() {
            this.open_create_business_process_run_dialog = true;
        },
        openSearchBusinessProcessRunsDialog() {
            this.unselectSelectedRow();
            this.open_search_business_process_runs_dialog = true;
        },

        //------------------- CLOSE DIALOGS---------------------//
        updateUITableModel(new_business_process_run) {
            if (new_business_process_run !== null) {
                const obj_index = this.business_process_runs.map(obj => { return obj._id; }).indexOf(new_business_process_run._id)
                this.business_process_runs[obj_index] = new_business_process_run;

                this.selectedBusinessProcessRun = new_business_process_run;
                this.selectedBusinessProcessRunCopy = JSON.parse( JSON.stringify(new_business_process_run) );

                 // Deep copy process runs for refreshing main data table
                const temp_arr = Array.from(this.business_process_runs);
                this.business_process_runs = temp_arr;
            }
        },
        closeCreateBusinessProcessRunDialog(new_business_process_run) {
            if (new_business_process_run !== null) {
                this.business_process_runs.push(new_business_process_run);
            }
            this.open_create_business_process_run_dialog = false;
        },
        closeSearchBusinessProcessRunsDialog(searchResults) {
            if (searchResults !== null) {
                this.business_process_runs = searchResults;
                this.SEARCH_MODE = true;
            }
            this.open_search_business_process_runs_dialog = false;
        },

        selectMainTableRow(item, item_metadata) {
            this.selectedBusinessProcessRunCopy = JSON.parse( JSON.stringify(item) );

            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                this.selectedRow = item_metadata;

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                this.selectedBusinessProcessRun = item;
            } else {
                this.selectedBusinessProcessRun = null;
                // Unighlight selected row
                item_metadata.select(false);
                this.selectedRow = null;
            }
        },
        tobeDefined(){},
        clearSearchResults() {
            // Unselect a row if there was any
            this.unselectSelectedRow();

            this.SEARCH_MODE = false;
            this.refreshBusinessProcessRuns();
        },

        unselectSelectedRow() {
            if (this.selectedRow !== null) {
                this.selectedRow.select(false);
                this.selectedRow = null;
            }
            this.selectedBusinessProcessRun = null;
        },

        async loadMoreData() {
            this.data_table_loading = true;
            this.page += 1;

            try {
                const res = await this.$apollo
                                    .query({
                                        query: getBusinessProcessRuns,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'BUSINESS_PROCESS_RUN',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }

                this.data_table_loading = false;
                this.business_process_runs = this.business_process_runs.concat( res.data.getBusinessObject.business_objects );
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        async refreshBusinessProcessRuns() {
            this.data_table_loading = true;
            this.business_process_runs = [];

            this.page = 1; //, limit = 20;

            try {
                const res = await this.$apollo
                                    .query({
                                        query: getBusinessProcessRuns,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'BUSINESS_PROCESS_RUN',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }

                this.data_table_loading = false;
                this.business_process_runs = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
    },

    async mounted() {
        eventBus.$on('CLOSE_CREATE_BUSINESS_PROCESS_RUN_DIALOG', this.closeCreateBusinessProcessRunDialog);
        eventBus.$on('CLOSE_SEARCH_BUSINESS_PROCESS_RUN_DIALOG', this.closeSearchBusinessProcessRunsDialog);

        try {
            this.data_table_loading = true;
            const res = await this.$apollo
                                    .query({
                                        query: getBusinessProcessRuns,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'BUSINESS_PROCESS_RUN',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.data_table_loading = false;
                return;
            }

            this.data_table_loading = false;
            this.business_process_runs = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.data_table_loading = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
        }
    },

    watch: {
        
    }
}
</script>
<style scoped>
/* Overriding of textbox min height */
 .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
}
</style>
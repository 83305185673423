<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="100%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>View / Update Case Details</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn
                    icon
                    @click="closeEditCaseDialog()"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-expansion-panels focusable :value="0">
                    <!-- CASE DETAILS -->
                    <v-expansion-panel style="background: #f6c20a8f" >
                        <v-expansion-panel-header>
                            <h3>Case Details</h3><br>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row style="background:#ffffff; padding-top:30px">
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :value="'CS-' + case_original.case_number"
                                        color="secondary"
                                        label="Case Number"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :value="case_original.calendar_month.name"
                                        color="secondary"
                                        label="Month"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :value="case_original.status"
                                        color="secondary"
                                        label="Status"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :value="case_original.next_action_date === null ? 'NONE' : case_original.next_action_date.split('T')[0]"
                                        color="secondary"
                                        label="Next Action Date"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
            
            
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :value="case_original.description"
                                        color="secondary"
                                        label="Description"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :value="case_original.category"
                                        color="secondary"
                                        label="Category"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        :value="case_original.required_action"
                                        color="secondary"
                                        label="Required Action"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- LOAN DETAILS -->
                    <v-expansion-panel
                        v-if="case_original.loan !== null"
                        style="background: #f6c20a8f"
                    >
                        <v-expansion-panel-header>
                            <h3>Loan Details</h3><br>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row style="background:#ffffff; padding-top:30px">
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :value="'L' + case_original.loan.loan_number"
                                        color="secondary"
                                        label="Loan Number"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :value="case_original.loan.status"
                                        color="secondary"
                                        label="Loan Status"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :value="case_original.loan.repayment_info.payment_method"
                                        color="secondary"
                                        label="Payment Method"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :value="case_original.loan.repayment_info.elected_repayment_time_point"
                                        color="secondary"
                                        label="Payment Date"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- CONTACT PERSON DETAILS -->
                    <v-expansion-panel style="background: #f6c20a8f">
                        <v-expansion-panel-header>
                            <h3>Contact Person Details</h3><br>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row style="background:#ffffff; padding-top:30px">
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :value="case_original.contact_person.name"
                                        color="secondary"
                                        label="Name"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :value="case_original.contact_person.surname"
                                        color="secondary"
                                        label="Surname"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :value="case_original.contact_person.phone"
                                        color="secondary"
                                        label="Phone"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :value="case_original.contact_person.email"
                                        color="secondary"
                                        label="Email"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-text-field
                                        :value="case_original.contact_person.banking_details"
                                        color="secondary"
                                        label="Banking Details"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- CASE NOTES -->
                    <v-expansion-panel style="background: #f6c20a8f">
                        <v-expansion-panel-header>
                            <h3>Case Notes</h3><br>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row
                                v-for="(notesItem, index) in case_original.notes"
                                :key="index"
                                style="background:#ffffff; padding-top:30px"
                            >
                                <v-col cols="12" sm="6">
                                    <v-textarea
                                        :value="notesItem.notes"
                                        color="secondary"
                                        label="Notes"
                                        auto-grow
                                        rows="1"
                                        disabled
                                        required
                                    ></v-textarea>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :value="notesItem.created.split('T')[0] + '  at  ' + notesItem.created.split('T')[1].split('.')[0]"
                                        color="secondary"
                                        label="Created At"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        :value="notesItem.captured_by.entity.name + ' ' + notesItem.captured_by.entity.surname"
                                        color="secondary"
                                        label="Captured By"
                                        autocomplete="some-random-text"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <br />
                <br />

                <v-card-text class="text-center" v-if="submitting">
                    Submitting...
                    <v-progress-linear
                        indeterminate
                        color="green"
                        class="mb-0"
                    ></v-progress-linear>
                </v-card-text>



                <!-- Button Section -->
                <h2
                    v-if="(
                        isIndoorAgent ||
                        isLoanCollectionsAdministratorUser ||
                        isOperationsManagerUser ||
                        isCentralUnderwriterAdminUser
                    )"
                    class="pl-4"
                >
                    Capture Case Notes
                </h2>
                <v-card-actions
                    v-if="(
                        isIndoorAgent ||
                        isLoanCollectionsAdministratorUser ||
                        isOperationsManagerUser ||
                        isCentralUnderwriterAdminUser
                    )"
                    style="border-top: 4px solid #bad8df; background: #ffffff"
                >
                    <!-- <v-btn
                        x-large
                        color="red white--text"
                        @click="closeCase()"
                    >
                        <v-icon x-large>cancel</v-icon> Close Case
                    </v-btn>
                    <v-spacer></v-spacer> -->
                    
                    <v-row>
                        <v-col cols="12" sm="3">
                            <v-menu
                                v-model="next_action_date_menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="case_next_action_date"
                                        color="secondary"
                                        label="Next Action Date"
                                        filled
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    color="secondary"
                                    v-model="case_next_action_date"
                                    :min="new Date().toISOString().substr(0, 10)"
                                    @input="next_action_date_menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-select
                                v-model="case_notes"
                                :items="case_notes_standard_reasons"
                                item-text="details"
                                item-value="details"
                                label="Notes"
                                color="secondary"
                                hint="Case notes logging"
                                persistent-hint
                                required
                            >
                                <template v-slot:item="{ item, on }">
                                    <v-list-item
                                        v-on="on"
                                        style="border-bottom: 1px solid grey"
                                    >
                                        <v-list-item-icon>
                                            <v-chip
                                                    v-if="item.severity.includes('LITIGATION')"
                                                    class="ma-2"
                                                    color="red white--text"
                                                >
                                                    Litigation
                                                </v-chip>
                                                <v-chip
                                                    v-if="item.severity.includes('NORMAL')"
                                                    class="ma-2"
                                                    color="success white--text"
                                                >
                                                    No Litigation
                                                </v-chip>
                                                <v-chip
                                                    v-if="item.severity.includes('AGENT-DISCIPLINARY-HEARING')"
                                                    class="ma-2"
                                                    color="purple white--text"
                                                >
                                                    Agent Disciplinary Action
                                            </v-chip>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <b>{{ item.details }}</b>
                                            </v-list-item-title>
                                            <v-list-item-subtitle> <b style="color:rgb(32, 32, 153);">Category: </b> {{ item.category }} </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                </template>

                                <template v-slot:selection="{ item }">
                                    <!-- <div>{{ item.details }} --- ({{ item.category }} Category)</div> -->
                                    <div>{{ item.details }}</div>
                                </template>
                            </v-select>
                        </v-col>

                        <v-col cols="12" sm="9" v-if="case_notes === 'Other'">
                            <v-textarea
                                v-model="notes_other"
                                color="secondary"
                                label="Notes (Other)"
                                auto-grow
                                rows="1"
                                filled
                                required
                            ></v-textarea>
                        </v-col>

                        <v-col cols="12" sm="3">
                            <v-btn
                                x-large
                                color="success"
                                :disabled="submitBtnDisabled()"
                                @click="submitCase()"
                            >
                                <v-icon x-large>save</v-icon> Submit Notes
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>

                <h2
                    v-if="(
                        this.case_original.is_in_disciplinary_action === true &&
                        (
                            isOperationsManagerUser ||
                            isCentralUnderwriterAdminUser
                        )
                    )"
                    class="pl-4"
                >
                    Disciplinary Action Notes
                </h2>
                <v-card-actions
                    v-if="(
                        this.case_original.is_in_disciplinary_action === true &&
                        (
                            isOperationsManagerUser ||
                            isCentralUnderwriterAdminUser
                        )
                    )"
                    style="border-top: 1px solid #bad8df; background: #ffffff"
                >
                    <v-row>
                        <v-col cols="12" sm="9">
                            <v-select
                                v-model="disciplinary_action_notes"
                                :items="agent_disciplinary_action_notes_list"
                                label="Disciplinary Action Notes"
                                color="secondary"
                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-btn
                                x-large
                                color="purple white--text"
                                :disabled="disciplinaryActionBtnDisabled()"
                                @click="completeDisciplinaryAction()"
                            >
                                <v-icon x-large>save</v-icon> Disciplinary Action Complete
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>

                <h2
                    v-if="(
                        this.case_original.is_in_litigation === true &&
                        (
                            isLegalAdministratorUser ||
                            isCentralUnderwriterAdminUser
                        )
                    )"
                    class="pl-4"
                >
                    Litigation Notes
                </h2>
                <v-card-actions
                    v-if="(
                        this.case_original.is_in_litigation === true &&
                        (
                            isLegalAdministratorUser ||
                            isCentralUnderwriterAdminUser
                        )
                    )"
                    style="border-top: 1px solid #bad8df; background: #ffffff"
                >
                    <v-row>
                        <v-col cols="12" sm="9">
                            <v-textarea
                                v-model="litigation_notes"
                                color="secondary"
                                label="Litigation Notes"
                                auto-grow
                                rows="1"
                                filled
                                required
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-btn
                                x-large
                                color="red white--text"
                                :disabled="litigationBtnDisabled()"
                                @click="completeLitigation()"
                            >
                                <v-icon x-large>save</v-icon> Suspend Litigation
                            </v-btn>
                        </v-col>
                    </v-row>
                
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";
import { store } from "../../../../store";
import { BUSINESS_PARTNER_TYPES } from "../../../../config";

import { opsProcessOperationalExcellence } from "../gql/mutations";

export default {
    components:{},
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        case_original: {
            type: Object,
            required: false,
        },
        case_copy: {
            type: Object,
            required: false,
        },
    },
    data: () => ({
        submitting: false,
        server_response_obj: null,

        next_action_date_menu: false,
        case_notes: null,
        case_next_action_date: null,

        notes_other: null,

        case_notes_standard_reasons: [
            // Office
            {
                category: 'Office',
                severity: 'NORMAL',
                details: 'Awaiting debit order confirmation within the next 7 days'
            },
            // Client
            {
                category: 'Client',
                severity: 'NORMAL',
                details: 'The client promised to make payment within the next 7 days'
            },
            {
                category: 'Client',
                severity: 'NORMAL',
                details: 'The client promised to make payment within the next 14 days'
            },
            {
                category: 'Client',
                severity: 'NORMAL',
                details: 'The client promised to make payment next month on payment date'
            },
            {
                category: 'Client',
                severity: 'NORMAL',
                details: 'The client is not taking calls'
            },
            {
                category: 'Client',
                severity: 'NORMAL',
                details: 'The agent is not taking calls'
            },
            {
                category: 'Client',
                severity: 'NORMAL',
                details: 'The client lost their job, and is trying to make a plan'
            },
            {
                category: 'Client',
                severity: 'NORMAL',
                details: 'The client said they\'re on unpaid maternity leave'
            },
            {
                category: 'Client',
                severity: 'NORMAL',
                details: 'The client said they\'re on unpaid leave'
            },
            {
                category: 'Client',
                severity: 'NORMAL',
                details: 'The client has blocked the debit order'
            },
            {
                category: 'Client',
                severity: 'NORMAL',
                details: 'The client asked to be called back later'
            },
            // AGENT
            {
                category: 'Agent',
                severity: 'NORMAL',
                details: 'The agent asked to be called back later'
            },
            {
                category: 'Agent',
                severity: 'AGENT-DISCIPLINARY-HEARING',
                details: 'The agent is not being coorperative'
            },
            {
                category: 'Agent',
                severity: 'AGENT-DISCIPLINARY-HEARING',
                details: 'The client says they paid, and the money is sitting with the agent'
            },
            {
                category: 'Agent',
                severity: 'AGENT-DISCIPLINARY-HEARING',
                details: 'The agent is influencing clients not to pay'
            },
            {
                category: 'Agent',
                severity: 'AGENT-DISCIPLINARY-HEARING',
                details: 'The client said the agent was disrespectul to them'
            },
            {
                category: 'Client',
                severity: 'LITIGATION',
                details: 'The client refuses to pay and sounds violent'
            },
            {
                category: 'Client',
                severity: 'LITIGATION',
                details: 'The client refuses to pay and influencing others not to pay'
            },
            {
                category: 'Client',
                severity: 'LITIGATION',
                details: 'The client refuses to pay'
            },
            // Other
            {
                category: 'Other',
                severity: 'NORMAL',
                details: 'Other'
            },
        ],


        agent_disciplinary_action_notes_list: [
            'The agent was given a first warning, and they promised to make the necessary changes',
            'The agent was given a second warning, and they promised to make the necessary changes',
            'The agent was released from duty, because they reached their warning limit',
            'The agent was released from duty, because a common ground could not be found',
        ],

        disciplinary_action_notes: null,
        litigation_notes: null,
    }),

    computed: {
        isIndoorAgent() {
            const result = store.state.currentUserAccount.roles.filter(role => {
                                                            return (role.role.name.toLowerCase() ===  'indoor agent');
                                                        }).length > 0;

            return result;
        },
        isCentralUnderwriterAdminUser() {
            const result = store.state.currentUserAccount.roles.filter(role => {
                                                            return (
                                                                role.role.level.includes(BUSINESS_PARTNER_TYPES.CENTRAL_UNDERWRITER) &&
                                                                role.role.name.toLowerCase() ===  'admin - central underwriter'
                                                            );
                                                        }).length > 0;

            return result;
        },
        isLegalAdministratorUser() {
            const result = store.state.currentUserAccount.roles.filter(role => {
                                                            return (
                                                                role.role.level.includes(BUSINESS_PARTNER_TYPES.CENTRAL_UNDERWRITER) &&
                                                                role.role.name.toLowerCase() ===  'legal administrator'
                                                            );
                                                        }).length > 0;

            return result;
        },
        isOperationsManagerUser() {
            const result = store.state.currentUserAccount.roles.filter(role => {
                                                            return (
                                                                role.role.level.includes(BUSINESS_PARTNER_TYPES.CENTRAL_UNDERWRITER) &&
                                                                role.role.name.toLowerCase() ===  'operations manager'
                                                            );
                                                        }).length > 0;

            return result;
        },
        isLoanCollectionsAdministratorUser() {
            const result = store.state.currentUserAccount.roles.filter(role => {
                                                            return (
                                                                role.role.level.includes(BUSINESS_PARTNER_TYPES.CENTRAL_UNDERWRITER) &&
                                                                role.role.name.toLowerCase() ===  'loan collections administrator'
                                                            );
                                                        }).length > 0;

            return result;
        },
    },
        
    methods: {
        closeEditCaseDialog() {
            eventBus.$emit('CLOSE_EDIT_CASE_DIALOG', this.server_response_obj);
        },
        resetForm() {
            this.server_response_obj = null;
            this.case_notes = null;
            this.notes_other = null;
            this.case_next_action_date = null;
        },
        
        submitBtnDisabled() {
            if (this.case_notes === null) {
                return true;
            }
            if (typeof(this.case_notes) === 'string') {
                if (this.case_notes.trim().length === 0) {
                    this.case_notes = null;
                    return true;
                }
            }
            if (this.case_next_action_date === null) {
                return true;
            }
            if (this.case_notes === 'Other') {
                if (this.notes_other === null) {
                    return true;
                }
                if (typeof(this.notes_other) === 'string') {
                    if (this.notes_other.trim().length === 0) {
                        return true;
                    }
                }
            }
            return false;
        },

        disciplinaryActionBtnDisabled() {
            if (this.disciplinary_action_notes === null) {
                return true;
            }
            if (typeof(this.disciplinary_action_notes) === 'string') {
                if (this.disciplinary_action_notes.trim().length === 0) {
                    this.disciplinary_action_notes = null;
                    return true;
                }
            }
            return false;
        },

        litigationBtnDisabled() {
            if (this.litigation_notes === null) {
                return true;
            }
            if (typeof(this.litigation_notes) === 'string') {
                if (this.litigation_notes.trim().length === 0) {
                    this.litigation_notes = null;
                    return true;
                }
            }
            return false;
        },

        capturingDone() {
            this.closeEditCaseDialog();
            this.resetForm();
        },
        async submitCase() {
            this.submitting = true;

            try {
                // Payload payload
                const submitObj = {
                    notes: null,
                    next_action_date: null,
                    status: null,
                };

                if (this.case_notes !== null && this.case_notes !== 'Other') {
                    submitObj.notes = this.case_notes;
                }
                if (this.case_notes === 'Other' && this.notes_other !== null) {
                    if (typeof(this.notes_other) === 'string') {
                        if (this.notes_other.trim().length > 0) {
                            submitObj.notes = this.notes_other;
                        }
                    }
                }
                if (this.case_next_action_date !== null) {
                    submitObj.next_action_date = this.case_next_action_date;
                }

                const payloadObj = {
                    OP_TYPE: 'UPDATE',
                    _id: this.case_original._id,

                    ...submitObj
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: opsProcessOperationalExcellence,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.opsProcessOperationalExcellence.errors === true) {
                    emitAlert(res.data.opsProcessOperationalExcellence.message);
                    this.submitting = false;
                    return;
                }
                
                this.server_response_obj = res.data.opsProcessOperationalExcellence.business_object;
                this.closeEditCaseDialog();
                emitSuccess(res.data.opsProcessOperationalExcellence.message);

                // Reset fields
                this.server_response_obj = null;
                this.submitting = false;
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.opsProcessOperationalExcellence.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
        },

        async completeDisciplinaryAction() {
            this.submitting = true;

            try {
                const payloadObj = {
                    OP_TYPE: 'COMPLETE_AGENT_DISCIPLINARY_ACTION',
                    _id: this.case_original._id,
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: opsProcessOperationalExcellence,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.opsProcessOperationalExcellence.errors === true) {
                    emitAlert(res.data.opsProcessOperationalExcellence.message);
                    this.submitting = false;
                    return;
                }
                
                this.server_response_obj = res.data.opsProcessOperationalExcellence.business_object;
                this.closeEditCaseDialog();
                emitSuccess(res.data.opsProcessOperationalExcellence.message);

                // Reset fields
                this.server_response_obj = null;
                this.submitting = false;
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.opsProcessOperationalExcellence.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
        },

        async completeLitigation() {
            this.submitting = true;

            try {
                const submitObj = {
                    notes: this.litigation_notes,
                };
                const payloadObj = {
                    OP_TYPE: 'COMPLETE_LITIGATION',
                    _id: this.case_original._id,

                    ...submitObj
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: opsProcessOperationalExcellence,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.opsProcessOperationalExcellence.errors === true) {
                    emitAlert(res.data.opsProcessOperationalExcellence.message);
                    this.submitting = false;
                    return;
                }
                
                this.server_response_obj = res.data.opsProcessOperationalExcellence.business_object;
                this.closeEditCaseDialog();
                emitSuccess(res.data.opsProcessOperationalExcellence.message);

                // Reset fields
                this.server_response_obj = null;
                this.submitting = false;
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.opsProcessOperationalExcellence.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
        },

        async closeCase() {
            this.submitting = true;

            try {
                // Payload payload
                const submitObj = {
                    notes: null,
                    next_action_date: null,
                    status: 'CLOSED',
                };

                const payloadObj = {
                    OP_TYPE: 'UPDATE',
                    _id: this.case_original._id,

                    ...submitObj
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: opsProcessOperationalExcellence,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.opsProcessOperationalExcellence.errors === true) {
                    emitAlert(res.data.opsProcessOperationalExcellence.message);
                    this.submitting = false;
                    return;
                }
                
                this.server_response_obj = res.data.opsProcessOperationalExcellence.business_object;
                this.closeEditCaseDialog();
                emitSuccess(res.data.opsProcessOperationalExcellence.message);

                // Reset fields
                this.server_response_obj = null;
                this.submitting = false;
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.opsProcessOperationalExcellence.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
        },
    },
    mounted() {

    },
}
</script>
<style scoped>

</style>
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
    key: 'vuex', // The key to store the state on in the storage provider.
    storage: window.localStorage, // or window.sessionStorage or localForage
    // Function that passes the state and returns the state with only the objects you want to store.
    // reducer: state => state,
    // Function that passes a mutation and lets you decide if it should update the state in localStorage.
    // filter: mutation => (true)
  })

export const store = new Vuex.Store({
    plugins: [vuexLocalStorage.plugin],
    state: {
        tabItems: [
            {
            id: 1,
            path: '/',
            title: 'Dashboard',
            name: 'Dash1'
            },
        ],
        currentUserAccount: null,
        token: null,
        context_list: [],
        REPORT_DOWNLOAD_PROGRESS_MAP: {},
        BUSINESS_PROCESS_PROGRESS_MAP: {},
    },
    mutations: {
        update_tabs(state, tabs) {      
            state.tabItems = tabs;
        },
        update_user(state, userEntityObj) {
            state.currentUserAccount = userEntityObj;
        },
        update_token(state, token) {
            state.token = token;
        },
        update_context_list(state, context_list) {
            state.context_list = context_list;
        },
        update_report_download_progress_map(state, report_download_progress_map) {
            state.REPORT_DOWNLOAD_PROGRESS_MAP = report_download_progress_map;
        },
        update_business_process_progress_map(state, business_process_progress_map) {
            state.BUSINESS_PROCESS_PROGRESS_MAP = business_process_progress_map;
        },
    },
    actions: {
      
    },
    getters: {
        tabItems(state) {
            return state.tabItems;
        },
        currentUserAccount(state) {
            return state.currentUserAccount;
        },
        token(state) {
            return state.token;
        },
        context_list(state) {
            return state.context_list;
        },
        report_download_progress_map(state) {
            return state.REPORT_DOWNLOAD_PROGRESS_MAP;
        },
    }
});
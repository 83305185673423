<template>
    <v-row
        v-if="policy !== null"
        style="background:#eaeaea; padding-left:10px"
        no-gutters
    >
        <v-col cols="12" sm="1">
            <v-text-field
                v-model="policy.policy_number"
                color="secondary"
                label="Policy Number"
                required
                disabled
            ></v-text-field>
        </v-col>
        <v-col cols="12" sm="5">
            <v-text-field
                :value="policy.principal_member.name + ' ' + policy.principal_member.surname + ' (' + policy.principal_member.id_number + ')'"
                color="secondary"
                label="principal Member"
                required
                disabled
            ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
            <v-text-field
                v-model="policy.status"
                color="secondary"
                label="Status"
                required
                disabled
            ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
            <v-text-field
                v-model="policy.premium_scheme_copy"
                color="secondary"
                label="Expected Premium"
                prefix="ZAR"
                type="number"
                disabled
                required
            ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
            <v-text-field
                v-model="policy.premium_scheme"
                color="secondary"
                label="Premium Paid"
                prefix="ZAR"
                type="number"
                required
            ></v-text-field>
        </v-col>
    </v-row>
</template>

<script>
import { eventBus } from '../../../main';

export default {
    name: "SchemePolicyItem",
    props: {
        policy: {
            required: false,
            default: null
        },
    },
    data: () => ({
        search: null,
        search_entries: [],
        isLoading: false
    }),
    computed: {},
    methods: {},
    mounted() {
        
    },
    watch: {
        'policy.premium_scheme': function(val) {
            // console.log(val);
            const eventObj = {
                _id: this.policy._id,
                premium_scheme: parseFloat(val)
            };
            eventBus.$emit('SCHEME_POLICY_PREMIUM_SCHANGE', eventObj);
        }
    }
};
</script>
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>View Call Rating</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card v-if="recording_obj.call_rating !== null">
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="4">
                            <v-card v-if="recording_data !== null">
                                <v-card-text class="text-center">
                                    <audio controls>
                                        <source :src="recording_data" type="audio/mpeg">
                                        Your browser does not support the audio element.
                                      </audio>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-card v-if="recording_data !== null">
                                <v-card-text class="text-center">
                                    <v-select
                                        v-model="recording_obj.call_rating.call_answered"
                                        :items="[
                                            'YES',
                                            'NO',
                                        ]"
                                        label="Call Answered?"
                                        color="secondary"
                                        required
                                        :disabled="true"
                                    ></v-select>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-card v-if="recording_data !== null">
                                <v-card-text class="text-center">
                                    <v-text-field
                                        :value="recording_obj.call_rating.rated_by.entity.name + ' ' + recording_obj.call_rating.rated_by.entity.surname"
                                        label="Rated By"
                                        color="secondary"
                                        required
                                        :disabled="true"
                                    ></v-text-field>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <!-- Notes -->
                        <v-col cols="12" sm="12">
                            <v-textarea
                                v-model="recording_obj.call_rating.notes"
                                name="Rating Notes"
                                color="secondary"
                                filled
                                label="Rating Notes"
                                auto-grow
                                :disabled="true"
                            ></v-textarea>
                        </v-col>

                        <!-- Acknowledgement of Debt -->
                        <v-col cols="12" sm="12" v-if="recording_obj.call_rating.call_answered === 'YES'" style="background:#595959; color:white">
                            <h4>Client Acknowledgement of Debt: {{recording_obj.call_rating.debt_acknowledgement}}</h4>
                            <!-- <v-text-field
                                v-model="recording_obj.call_rating.debt_acknowledgement"
                                label="Client Acknowledgement of Debt"
                                type="number"
                                color="secondary"
                                required
                                :disabled="true"
                            ></v-text-field> -->
                        </v-col>
                        <v-col cols="12" sm="12"></v-col>

                        <!-- Tone of Client -->
                        <v-col cols="12" sm="12" v-if="recording_obj.call_rating.call_answered === 'YES'" style="background:#595959; color:white">
                            <h4>Tone of Client: {{recording_obj.call_rating.tone_client}}</h4>
                            <!-- <v-text-field
                                v-model="recording_obj.call_rating.tone_client"
                                label="Tone of Client"
                                type="number"
                                color="secondary"
                                required
                                :disabled="true"
                            ></v-text-field> -->
                        </v-col>
                        <v-col cols="12" sm="12"></v-col>

                        <!-- Tone of Agent -->
                        <v-col cols="12" sm="12" v-if="recording_obj.call_rating.call_answered === 'YES'" style="background:#595959; color:white">
                            <h4>Tone of Agent: {{recording_obj.call_rating.tone_agent}}</h4>
                            <!-- <v-text-field
                                v-model="recording_obj.call_rating.tone_agent"
                                label="Tone of Agent"
                                type="number"
                                color="secondary"
                                required
                                :disabled="true"
                            ></v-text-field> -->
                        </v-col>
                        <v-col cols="12" sm="12"></v-col>

                        <!-- Ten Day Collection Confidence -->
                        <v-col cols="12" sm="12" v-if="recording_obj.call_rating.call_answered === 'YES'" style="background:#595959; color:white">
                            <h4>Confidence that we can collect in the next 10 days: {{recording_obj.call_rating.ten_day_collection_confidence}}</h4>
                            <!-- <v-text-field
                                v-model="recording_obj.call_rating.ten_day_collection_confidence"
                                label="Confidence that we can collect in the next 10 days"
                                type="number"
                                color="secondary"
                                required
                                :disabled="true"
                            ></v-text-field> -->
                        </v-col>
                        <v-col cols="12" sm="12"></v-col>

                        <!-- Total Score -->
                        <v-col cols="12" sm="12" v-if="recording_obj.call_rating.call_answered === 'YES'" style="background:green; color:white">
                            <h3>Total Score: {{recording_obj.call_rating.total_score}}</h3>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { eventBus } from '../../../main';
export default {
    name: 'ViewCallRatingDialog',
    components: {},
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        recording_data: {
            type: String,
            required: false,
            default: null
        },
        recording_obj: {
            type: Object,
            required: false,
            default: null
        },
    },
    data: () => ({}),
    methods: {
        closeDialog() {
            eventBus.$emit('CLOSE_VIEW_CALL_RATING_RESULTS_DIALOG', null);
        },
    },
    mounted() {
        
    },
    computed: {},
    watch: {}
}
</script>
<style scoped>

</style>
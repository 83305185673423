<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Eit Payout Age Category</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closePayoutAgeCatDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <!-- Name -->
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="selected_age_cat_copy.name"
                                color="secondary"
                                label="Name"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-select
                                v-model="selected_age_cat_copy.membership_category"
                                color="secondary"
                                :items="membership_categories"
                                label="Membership Category"
                                required
                            ></v-select>
                        </v-col>
                        <!-- Start Age -->
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="selected_age_cat_copy.start_age"
                                color="secondary"
                                label="Start Age"
                                type="number"
                                required
                            ></v-text-field>
                        </v-col>
                        <!-- End Age -->
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="selected_age_cat_copy.end_age"
                                color="secondary"
                                label="End Age"
                                type="number"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting Payout Age Category
                        <v-progress-linear
                            indeterminate
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submitEditRelTypeBtnDisabled()"
                                @click="submitEditRelType()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Submit Details</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../../utils/api";
import { eventBus } from "../../../../main";
import { membership_categories } from "../../../../../config";

import { lookupProcessPayoutCategory } from "../gql/mutations";

export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        selected_age_cat: {
            type: Object,
            required: true,
            default: () => {
                return {
                    name: null,
                    membership_category: null,
                    start_age: null,
                    end_age: null,
                }
            }
        },
        selected_age_cat_copy: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data: () => ({
        server_resp_obj: null,
        submitting: false,

        membership_categories: membership_categories
    }),
    methods: {
        closePayoutAgeCatDialog() {
            eventBus.$emit('CLOSE_EDIT_PAYOUT_AGE_CAT_DIALOG', this.server_resp_obj);
        },
        submitEditRelTypeBtnDisabled() {
            if (
                this.selected_age_cat_copy.name.trim() === this.selected_age_cat.name &&
                this.selected_age_cat_copy.membership_category === this.selected_age_cat.membership_category &&
                parseFloat(this.selected_age_cat_copy.start_age) === this.selected_age_cat.start_age &&
                parseFloat(this.selected_age_cat_copy.end_age) === this.selected_age_cat.end_age
            ) {
                return true;
            }

            // Cater for if name is an empty string
            if (typeof(this.selected_age_cat_copy.name) === 'string') {
                if (this.selected_age_cat_copy.name.trim() === '') {
                    this.selected_age_cat_copy.name = null;
                    return true;
                }
            }
                
            // Cater for if start_age is an empty string
            if (typeof(this.selected_age_cat_copy.start_age) === 'string') {
                if (this.selected_age_cat_copy.start_age.trim() === '') {
                    this.selected_age_cat_copy.start_age = null;
                    return true;
                }
            }
            
            // Cater for if end_age is an empty string
            if (typeof(this.selected_age_cat_copy.end_age) === 'string') {
                if (this.selected_age_cat_copy.end_age.trim() === '') {
                    this.selected_age_cat_copy.end_age = null;
                    return true;
                }
            }
                
            return false;
        },
        resetForm() {
            this.server_resp_obj = null;
            this.submitting = false;
            eventBus.$emit('RESET_PAYOUT_AGE_CATEGORY_EDIT_FORM', this.selected_age_cat_copy);
        },
        async submitEditRelType() {
            this.submitting = true;

            try {
                // const res = await editPayoutAgeCategory(
                //                 this.selected_age_cat_copy._id,
                //                 this.selected_age_cat_copy.name,
                //                 this.selected_age_cat_copy.start_age,
                //                 this.selected_age_cat_copy.end_age,
                //             );
                const payloadObj = {
                    OP_TYPE: 'UPDATE',
                    _id: this.selected_age_cat_copy._id,

                    name: this.selected_age_cat_copy.name,
                    membership_category: this.selected_age_cat_copy.membership_category,
                    start_age: parseFloat(this.selected_age_cat_copy.start_age),
                    end_age: parseFloat(this.selected_age_cat_copy.end_age),
                };

                const res = await this.$apollo
                                            .mutate({
                                                mutation: lookupProcessPayoutCategory,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });

                if (res.data.lookupProcessPayoutCategory.errors === true) {
                    emitAlert(res.data.lookupProcessPayoutCategory.message);
                    this.submitting = false;
                    return;
                }

                emitSuccess(res.data.lookupProcessPayoutCategory.message);

                this.server_resp_obj = res.data.lookupProcessPayoutCategory.business_object;
                this.closePayoutAgeCatDialog();
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.lookupProcessPayoutCategory.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
    },
    mounted() {
        
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
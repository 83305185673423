var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding":"0px","width":"100%","border-top":"3px solid #4590a0","border-bottom":"3px solid #4590a0"}},[_c('v-toolbar',{attrs:{"color":"secondary","dark":"","height":"50"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"amber black--text","small":""},on:{"click":function($event){return _vm.refreshLoanTransactions()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh Payment Events")])])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.sub_tab_height,"headers":_vm.transactions_headers,"items":_vm.transactions,"item-key":"_id","single-select":true,"item-class":"secondary","hide-default-footer":"","loading":_vm.transactions_loading,"disable-pagination":true},on:{"click:row":_vm.selectTransactionTabRow},scopedSlots:_vm._u([{key:"item.transaction_date",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.transaction_date.split('T')[0]))])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.created !== null ? item.created.split('T')[0] + ' @ ' + item.created.split('T')[1].substr(0,8) : ''))])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v("R "+_vm._s(item.amount))])]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v("R "+_vm._s(item.balance))])]}},{key:"item.created_by",fn:function(ref){
var item = ref.item;
return [(item.created_by !== null)?_c('td',[(item.created_by.entity !== null)?_c('span',[_vm._v(" "+_vm._s(item.created_by.entity.name)+" "+_vm._s(item.created_by.entity.surname)+" ")]):_c('span',[_vm._v("N/A")])]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
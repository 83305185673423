<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Add User Role(s)</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeAddPolicyMemberDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row
                        v-for="user_role in roles"
                        :key="user_role.id"
                        style="background:#eaeaea; padding-bottom:10px"
                    >
                        <v-col cols="12" sm="12">
                            <v-select
                                v-model="user_role.role"
                                :items="user_roles"
                                color="secondary"
                                item-value="_id"
                                item-text="name"
                                label="Role"

                                :disabled="refreshing_user_roles_list"

                                hint="You can click the refresh icon next to the field if the user roles list is incomplete"
                                persistent-hint
                                required
                            >
                                <!-- Format prepend inner icon -->
                                <template v-slot:prepend-inner>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon
                                                v-on="on"
                                                color="success"
                                                @click="refreshUserRolesList()"
                                            >
                                                mdi-refresh
                                            </v-icon>
                                        </template>
                                        <span>Refresh User Roles List</span>
                                    </v-tooltip>
                                </template>
                            </v-select>
                            <v-progress-linear
                                color="green"
                                class="mb-0"
                                height="4"
                                indeterminate
                                :active="refreshing_user_roles_list"
                            ></v-progress-linear>
                        </v-col>
                        <div style="background:white; height: 10px; width: 100%"></div>
                    </v-row>
                    <!-- Buttons for Adding/Removing User Roles -->
                    <v-row>
                        <v-col sm="10"></v-col>
                        <v-col sm="2">
                            <v-tooltip bottom v-if="roles.length > 1">
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        class="mx-2"
                                        color="secondary"
                                        small
                                        v-on="on"
                                        @click="removeRole()"
                                    >
                                        <v-icon>remove</v-icon>
                                    </v-btn>
                                </template>
                                <span>Remove Role</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        color="secondary"
                                        small
                                        v-on="on"
                                        @click="addRole()"
                                    >
                                        <v-icon>add</v-icon>
                                    </v-btn>
                                </template>
                                <span>Add Another Role</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting Policy Members
                        <v-progress-linear
                            indeterminate
                            :active="submitting"
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submitUserRolesBtnDisabled()"
                                @click="submitUserRoles()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Save Policy Member</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";

import { getUserRolesContextualized } from "../gql/queries";
import { entityProcessUser } from "../gql/mutations";

export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        user_id: {
            type: String,
            default: null
        },
    },
    data: () => ({
        roles: [
            {
                id: 1,
                role: null,
            }
        ],

        final_roles: [],
        
        error_message: null,
        submitting: false,
        server_response_obj: null,
        refreshing_user_roles_list: false,

        user_roles: []
    }),
    methods: {
        closeAddPolicyMemberDialog() {
            eventBus.$emit('CLOSE_ADD_POLICY_MEMBERS_DIALOG', this.server_response_obj);
        },
        resetForm() {
            this.server_response_obj = null;
            this.final_roles = [];
            this.roles = [
                {
                    id: 1,
                    role: null,
                }
            ];
        },

        //--------------------ADD/REMOVE ROLES--------------------------//
        addRole() {
            const role_size = this.roles.length;

            this.roles.push({
                id: role_size + 1,
                role: null
            });
        },
        removeRole() {
            this.roles.pop();
        },
        
        submitUserRolesBtnDisabled() {
            const validRoles = this.roles.filter(role => { return role.role !== null; });
            if (validRoles.length === 0) {
                return true;
            }
            return false;
        },
        async submitUserRoles() {
            // Then submit the policy
            this.submitting = true;
            
            for (const role of this.roles) {
                if (role.role !== null) {
                    this.final_roles.push({
                        role: role.role
                    });
                }
            }

            // // Submit policy to server
            // const serverRes = await addUserRoles(this.user_id, this.final_roles);
            
            // this.server_response_obj = serverRes.user;
            // this.submitting = false;
            // this.closeAddPolicyMemberDialog();

            try {
                const payload = {
                    OP_TYPE: 'ADD_ROLES',
                    _id: this.user_id,
                    roles: this.final_roles
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: entityProcessUser,
                                                variables: {
                                                    input: payload
                                                },
                                            });
                this.submitting = false;

                if (res.data.entityProcessUser.errors === true) {
                    emitAlert(res.data.entityProcessUser.message);
                    return;
                }
                
                emitSuccess(res.data.entityProcessUser.message);

                this.server_response_obj = res.data.entityProcessUser.business_object;
                this.closeAddPolicyMemberDialog();

                // Reset
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.entityProcessUser.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },

        async refreshUserRolesList() {
            this.refreshing_user_roles_list = true;
            try {
                // Load Contextualized user roles
                const res = await this.$apollo
                                    .query({
                                        query: getUserRolesContextualized,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'USER_ROLE_CONTEXTUALIZED',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                this.refreshing_user_roles_list = false;

                if (res.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }
                this.user_roles = res.data.getBusinessObject.business_objects
            } catch (error) {
                this.refreshing_user_roles_list = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        }
    },
    computed: {
    },
    async mounted() {
        try {
            // Load Contextualized user roles
            const res = await this.$apollo
                                    .query({
                                        query: getUserRolesContextualized,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'USER_ROLE_CONTEXTUALIZED',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.submitting = false;
                return;
            }
            this.user_roles = res.data.getBusinessObject.business_objects
        } catch (error) {
            this.submitting = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
            
            return;
        }
    }
}
</script>
<style scoped>

</style>
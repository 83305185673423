<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title v-if="identifier === 'principal_member'"><b>Onboard Principal Member</b></v-toolbar-title>
                <v-toolbar-title v-else-if="identifier === 'premium_payer'"><b>Onboard Premium Payer</b></v-toolbar-title>
                <v-toolbar-title v-else-if="identifier !== null">
                    <b v-if="identifier.startsWith('dependent')">Onboard Dependent</b>
                    <b v-else>Onboard a Person</b>
                </v-toolbar-title>
                <!-- <v-toolbar-title v-else><b>Onboard a Person</b></v-toolbar-title> -->
                <v-spacer></v-spacer>
                <!-- <v-btn class="mx-10" icon @click="snapshotMember()">
                    <v-icon large>camera</v-icon>
                </v-btn> -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn
                    v-if="e1!==6"
                    icon
                    @click="closeOnboardingDialog()"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-stepper v-model="e1">
                <v-stepper-header>
                    <v-stepper-step :complete="e1 > 1" step="1" color="success">Details</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 2" step="2" color="success">Address</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 3" step="3" color="success">Contact</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 4" step="4" color="success">Documents</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 5" step="5" color="success">Banking</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="6" color="success">Processing</v-stepper-step>
                </v-stepper-header>
            
                <v-stepper-items>
                    <!-- Basic Details -->
                    <v-stepper-content step="1">
                        <v-card>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            v-model="user_details.name"
                                            :rules="name_rules"
                                            label="First Name(s)"
                                            autocomplete="some-random-text"
                                            type="text"
                                            color="secondary"
                                            required
                                            ref="name_field"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" >
                                        <v-text-field
                                            v-model="user_details.surname"
                                            :rules="surname_rules"
                                            label="Surname"
                                            autocomplete="some-random-text"
                                            type="text"
                                            color="secondary"
                                            required
                                            ref="surname_field"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3" >
                                        <v-text-field
                                            v-model="id_number_field"
                                            :rules="id_number_rules"
                                            label="ID Number"
                                            autocomplete="some-random-text"
                                            type="number"
                                            color="secondary"
                                            required
                                            ref="id_number_field"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3" >
                                        <v-text-field
                                            v-model="id_number_field_valid"
                                            label="ID Number Valid"
                                            disabled
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3" >
                                        <v-select
                                            v-model="birth_date_century_field"
                                            :items="['19', '20']"
                                            label="Birth Century"
                                            disabled
                                            required
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="3" >
                                        <v-text-field
                                            v-model="partial_birth_date_field"
                                            label="Birth Date"
                                            disabled
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3" >
                                        <v-select
                                            v-model="user_details.gender"
                                            :items="entity_gender"
                                            label="Gender"
                                            disabled
                                            required
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="3" >
                                        <v-select
                                            v-model="user_details.marital_status"
                                            :items="marital_statuses"
                                            label="Marital Status"
                                            color="secondary"
                                            required
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6" >
                                        <v-select
                                            v-model="user_details.marketing_channels"
                                            :items="['Email', 'SMS', 'Postal']"
                                            label="Marketing Material Channels"
                                            hint="Optional: If the person is consenting to receive marketing material"
                                            persistent-hint
                                            chips
                                            multiple
                                            color="secondary"
                                            required
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-progress-linear
                                color="green"
                                class="mb-0"
                                height="10"
                                indeterminate
                                :active="backend_check"
                            ></v-progress-linear>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    fab
                                    color="amber"
                                    :disabled="step2BtnDisabled()"
                                    @click="validateBasicDetails()"
                                >
                                    <v-icon large>keyboard_arrow_right</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Address Section -->
                    <v-stepper-content step="2">
                        <v-card>
                            <v-card-text>
                                <v-row
                                    v-for="address in addresses"
                                    :key="address.id"
                                    style="background:#eaeaea; padding-bottom:10px"
                                >
                                    <v-col cols="12" sm="3">
                                        <v-select
                                            v-model="address.type"
                                            :items="address_types"
                                            label="Address Type"
                                            color="secondary"
                                            required
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-text-field
                                            v-model="address.line1"
                                            label="Line 1"
                                            autocomplete="some-random-text"
                                            type="text"
                                            color="secondary"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-text-field
                                            v-model="address.line2"
                                            label="Line 2"
                                            autocomplete="some-random-text"
                                            type="text"
                                            color="secondary"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-text-field
                                            v-model="address.line3"
                                            label="Line 3"
                                            autocomplete="some-random-text"
                                            type="text"
                                            color="secondary"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-text-field
                                            v-model="address.line4"
                                            label="Postal Code"
                                            autocomplete="some-random-text"
                                            type="text"
                                            color="secondary"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <div style="background:white; height: 10px; width: 100%"></div>
                                </v-row>
                                <!-- Buttons for Adding/Removing Addresses -->
                                <v-row>
                                    <v-col sm="10"></v-col>
                                    <v-col sm="2">
                                        <v-tooltip bottom v-if="addresses.length > 1">
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    class="mx-2"
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="removeAddress()"
                                                >
                                                    <v-icon>remove</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Remove Address</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="addAddress()"
                                                >
                                                    <v-icon>add</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Add Another Address</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="e1 = 1"
                                >
                                    <v-icon large>keyboard_arrow_left</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="validateAddresses()"
                                >
                                    <v-icon large>keyboard_arrow_right</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Contact Section -->
                    <v-stepper-content step="3">
                        <v-card>
                            <v-card-text>
                                <v-row
                                    v-for="contact in contact_details"
                                    :key="contact.id"
                                    style="background:#eaeaea; padding-bottom:10px"
                                >
                                    <v-col cols="12" sm="4">
                                        <v-select
                                            v-model="contact.type"
                                            :items="contact_types"
                                            label="Contact Type"
                                            color="secondary"
                                            required
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-select
                                            v-model="contact.primary"
                                            :items="['Yes', 'No']"
                                            label="Primary Contact"
                                            color="secondary"
                                            required
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            v-model="contact.details"
                                            label="Contact Details"
                                            autocomplete="some-random-text"
                                            type="text"
                                            color="secondary"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <div style="background:white; height: 10px; width: 100%"></div>
                                </v-row>
                                <!-- Buttons for Adding/Removing Contact -->
                                <v-row>
                                    <v-col sm="10"></v-col>
                                    <v-col sm="2">
                                        <v-tooltip bottom v-if="contact_details.length > 1">
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    class="mx-2"
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="removeContact()"
                                                >
                                                    <v-icon>remove</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Remove Contact</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="addContact()"
                                                >
                                                    <v-icon>add</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Add Another Contact</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="e1 = 2"
                                >
                                    <v-icon large>keyboard_arrow_left</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="validateContacts()"
                                >
                                    <v-icon large>keyboard_arrow_right</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Documents Section -->
                    <v-stepper-content step="4">
                        <v-card>
                            <v-card-text>
                                <v-row
                                    v-for="file in file_details"
                                    :key="file.id"
                                    style="background:#eaeaea; padding-bottom:10px"
                                >
                                    <v-col cols="12" sm="5">
                                        <v-select
                                            v-model="file.type"
                                            :items="document_types"
                                            item-text="name"
                                            item-value="name"
                                            label="Document Type"
                                            color="secondary"
                                            required
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-file-input
                                            v-model="file.file"
                                            accept="application/pdf, image/*"
                                            label="File/Document"
                                            color="secondary"
                                        ></v-file-input>
                                    </v-col>
                                    <v-col cols="12" sm="1">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    fab
                                                    color="amber"
                                                    v-on="on"
                                                    @click="previewFile(file)"
                                                    :disabled="file.file===null || file.file===undefined"
                                                >
                                                    <v-icon>remove_red_eye</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Preview File</span>
                                        </v-tooltip>
                                    </v-col>
                                    <div style="background:white; height: 10px; width: 100%"></div>
                                </v-row>
                                <!-- Buttons for Adding/Removing Contact -->
                                <v-row>
                                    <v-col sm="10"></v-col>
                                    <v-col sm="2">
                                        <v-tooltip bottom v-if="file_details.length > 1">
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    class="mx-2"
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="removeFile()"
                                                >
                                                    <v-icon>remove</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Remove Document</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="addFile()"
                                                >
                                                    <v-icon>add</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Add Another Document</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="e1 = 3"
                                >
                                    <v-icon large>keyboard_arrow_left</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="e1 = 5"
                                >
                                    <v-icon large>keyboard_arrow_right</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Banking Section -->
                    <v-stepper-content step="5">
                        <v-card>
                            <v-card-text>
                                <v-row
                                    v-for="banking in bank_account_details"
                                    :key="banking.id"
                                    style="background:#eaeaea; padding-bottom:10px"
                                >
                                    <!-- <v-col cols="12" sm="3">
                                        <v-select
                                            v-model="banking.type"
                                            :items="banking_types"
                                            label="Type"
                                            color="secondary"
                                            required
                                        ></v-select>
                                    </v-col> -->
                                    <v-col cols="12" sm="4">
                                        <v-select
                                            v-model="banking.bank"
                                            :items="banks_list"
                                            item-value="_id"
                                            item-text="name"
                                            label="Bank"
                                            color="secondary"
                                            required
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-select
                                            v-model="banking.account_type"
                                            :items="bank_account_types"
                                            label="Account Type"
                                            color="secondary"
                                            required
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            v-model="banking.account_number"
                                            label="Account Number"
                                            autocomplete="some-random-text"
                                            type="text"
                                            color="secondary"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <div style="background:white; height: 10px; width: 100%"></div>
                                </v-row>
                                <!-- Buttons for Adding/Removing Banking Details -->
                                <v-row>
                                    <v-col sm="10"></v-col>
                                    <v-col sm="2">
                                        <v-tooltip bottom v-if="bank_account_details.length > 1">
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    class="mx-2"
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="removeBankingDetails()"
                                                >
                                                    <v-icon>remove</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Remove Banking Info</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="addBankingDetails()"
                                                >
                                                    <v-icon>add</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Add Another Banking Info</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="e1 = 4"
                                >
                                    <v-icon large>keyboard_arrow_left</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            large
                                            color="success"
                                            v-on="on"
                                            @click="submitPerson()"
                                        >
                                            <v-icon large>save</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Submit Person's Details</span>
                                </v-tooltip>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Submission Results Section -->
                    <v-stepper-content step="6">
                         <v-card light >
                            <v-card-text class="text-center">
                                <v-progress-linear
                                    color="green"
                                    class="mb-0"
                                    height="8"
                                    indeterminate
                                    :active="submitting"
                                ></v-progress-linear>
                            </v-card-text>
                            <v-list flat v-if="submitting">
                                <v-subheader>Saving Person Info</v-subheader>
                                <v-list-item-group color="primary">
                                    <v-list-item
                                        v-for="(item, i) in backendActionItems"
                                        :key="i"
                                    >
                                        <v-list-item-icon>
                                            <v-icon>done</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ item }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-card>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>
        <DocPreviewDialog
            :dialog="open_doc_preview_dialog"
            :url="file_url"
            :doc_type="doc_type"
            @CLOSE_DOC_PREVIEW_DIALOG="closeDocPreviewDialog()"
        />
    </v-row>
</template>
<script>
import DocPreviewDialog from '../../../components/doc_preview';
import { idValid, extractBirthDate, extractGender } from "../../../utils/id_number_check";
import { submitDocumentumFile, emitAlert, emitSuccess } from "../../../utils/api";
import ObjectID from "bson-objectid";
import { eventBus } from "../../../main";

import { getBanks, searchEntity } from "../gql/queries";
import { entityProcessEntity } from "../gql/mutations";

import {
    entity_gender,
    document_types,
    address_types,
    marital_statuses,
    contact_types,
    banking_types,
    bank_account_types,
} from "../../../../config";

export default {
    components: {
        DocPreviewDialog,
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        identifier: {
            type: String,
            required: false,
            default: null
        },
    },
    data: () => ({
        id_number_field: '',
        id_number_field_valid: 'NO',
        partial_birth_date_field: null,
        birth_date_century_field: null,

        backend_check: false,
        submitting: false,
        backendActionItems: [],
        server_response_obj: null,

        file: '',
        currentPage: 0,
        pageCount: 0,

        // File Preview Dialog
        file_url: null,
        doc_type: null,

        e1: 1,
        birthdate_menu: false,
        user_details: {
            name: '',
            surname: '',
            id_number: '',
            birth_date: new Date().toISOString().substr(0, 10),
            gender: null,
            marital_status: null,
            marketing_channels: []
        },
        
        addresses: [
            {
                id: 1,
                type: null,
                line1: null,
                line2: null,
                line3: null,
                line4: null,
            }
        ],
        contact_details: [
            {
                id: 1,
                type: null,
                primary: null,
                details: null
            }
        ],
        file_details: [
            {
                id: 1,
                type: null,
                file: null,
                valid: false,
            }
        ],
        bank_account_details: [
            {
                id: 1,
                // type: null,
                bank: null,
                account_type: null,
                account_number: null
            }
        ],

        open_doc_preview_dialog: false,

        entity_gender: entity_gender,
        address_types: address_types,
        marital_statuses: marital_statuses,
        contact_types: contact_types,
        banking_types: banking_types,
        banks_list: [],
        bank_account_types: bank_account_types,
        document_types: document_types.filter(doc_type => { return doc_type.type === 'entity' }),

        documents_map: {},

        name_rules: [
            v => !!v || 'Name is required',
            v => v.length >= 2 || 'Min 2 characters'
        ],
        surname_rules: [
            v => !!v || 'Surname is required',
            v => v.length >= 2 || 'Min 2 characters'
        ],
        id_number_rules: [
            v => !!v || 'ID number is required',
            v => v.length === 13 || '13 characters required'
        ],
    }),
    methods: {
        closeOnboardingDialog() {
            eventBus.$emit('CLOSE_ONBOARDING_DIALOG', this.server_response_obj, this.identifier);
        },

        //--------------------ADD/REMOVE ADDRESSES--------------------------//
        addAddress() {
            // const postal_and_res = this.addresses.filter(addr => { return addr.type === 'Postal + Residential'})

            // if ((this.addresses[0].type !== 'Postal + Residential') && (this.addresses[0].type !== null)) {
                const address_size = this.addresses.length;

                this.addresses.push({
                    id: address_size + 1,
                    type: null,
                    line1: null,
                    line2: null,
                    line3: null,
                    line4: null,
                });
            // }
        },
        removeAddress() {
            this.addresses.pop();
        },

        //--------------------ADD/REMOVE CONTACTS--------------------------//
        addContact() {
            // if (this.contact_details[0].type !== null) {
                const contacts_size = this.contact_details.length;

                this.contact_details.push({
                    id: contacts_size + 1,
                    type: null,
                    primary: null,
                    details: null
                });
            // }
        },
        removeContact() {
            this.contact_details.pop();
        },

        //--------------------ADD/REMOVE FILES--------------------------//
        addFile() {
            const files_size = this.file_details.length;

            this.file_details.push({
                id: files_size + 1,
                type: null,
                file: null
            });
        },
        removeFile() {
            this.file_details.pop();
        },

        //--------------------ADD/REMOVE BANKING--------------------------//
        addBankingDetails() {
            const size = this.bank_account_details.length;

            this.bank_account_details.push({
                id: size + 1,
                // type: null,
                bank: null,
                account_type: null,
                account_number: null,
            });
        },
        removeBankingDetails() {
            this.bank_account_details.pop();
        },

        previewFile(fileObject) {
            // Set file oobject so we can trigger the readAsDataURL function
            const reader = new FileReader();

            reader.addEventListener("load", () => {
                // Set PDF-Viewer URL to the selected file Base64 String
                this.file_url = reader.result;
                if (fileObject.file.type === 'application/pdf') {
                    this.doc_type = 'pdf';
                } else {
                    this.doc_type = 'image';
                }

                // Display the preview dialog
                this.open_doc_preview_dialog = true;
            }, false);

            reader.readAsDataURL(fileObject.file);
        },
        closeDocPreviewDialog() {
            this.open_doc_preview_dialog = false;
        },

        /**
         * Run validation of step 1 form
         */
        validateBasicDetails() {
            if (this.id_number_field_valid === 'NO') {
                emitAlert('Please enter a valid id number');
                return;
            }

            // Check if user with id_number entered already exists
            this.backend_check = true;

            this.$apollo
                    .query({
                        query: searchEntity,
                        // fetchPolicy: 'network-only',
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all',
                        variables: {
                            objectType: 'ENTITY',
                            pagination: {
                                page: this.page,
                                limit: this.limit
                            },
                            searchInput: {
                                id_number: this.id_number_field
                            }
                        },
                    })
                    .then(res => {
                        this.backend_check = false;

                        if (res.data.getBusinessObject.business_objects.length > 0) {
                            emitAlert(`There is already a person with the id number ${this.id_number_field} onboarded on the system`);
                            return;
                        }

                        this.e1 = 2;
                    });
        },

        step2BtnDisabled() {
            if (this.user_details.name.trim().length < 2) {
                return true;
            }
            if (this.user_details.surname.trim().length < 2) {
                return true;
            }
            if (this.id_number_field.trim().length !== 13) {
                return true;
            }
            if (this.birth_date_century_field === null) {
                return true;
            }
            if (this.user_details.marital_status === null) {
                return true;
            }
            if (this.id_number_field_valid === 'NO') {
                return true;
            }

            return false;
        },

        validateAddresses() {
            this.e1 = 3;
        },

        validateContacts() {
            // We're no longer enforcing that a person should have contact details....e.g. if its a baby???
            this.e1 = 4;
        },
        
        validateDocuments() {
            this.e1 = 5;
        },
        validateBankingDetails() {
            return;
        },
        capturingDone() {
            this.closeOnboardingDialog();
            this.resetForm();
        },
        resetForm() {
            this.server_response_obj = null;
                        
            // reset onboarding form
            this.backendActionItems = [];
            this.documents_map = {};

            this.user_details = {
                name: '',
                surname: '',
                id_number: '',
                birth_date: new Date().toISOString().substr(0, 10),
                gender: null,
                marital_status: null,
                marketing_channels: []
            };

            this.addresses = [
                {
                    id: 1,
                    type: null,
                    line1: null,
                    line2: null,
                    line3: null,
                    line4: null,
                }
            ];
            this.contact_details = [
                {
                    id: 1,
                    type: null,
                    primary: null,
                    details: null
                }
            ],
            this.file_details = [
                {
                    id: 1,
                    type: null,
                    file: null,
                    valid: false,
                }
            ];
            this.bank_account_details = [
                {
                    id: 1,
                    // type: null,
                    bank: null,
                    account_type: null,
                    account_number: null
                }
            ];
            
            this.birth_date_century_field = null;
            this.id_number_field = '';
            this.id_number_field_valid = false;
            this.e1 = 1;

            // Reset Field Validations
            this.$refs.name_field.resetValidation();
            this.$refs.surname_field.resetValidation();
            this.$refs.id_number_field.resetValidation();
        },
        async submitPerson() {
            this.validateBankingDetails();

            this.e1 = 6;

            this.submitting = true;
            const entityObj = this.extractEntityInfo();

            try {
                this.backendActionItems.push("Saving person info");

                // First create the entity
                const res = await this.$apollo
                                            .mutate({
                                                mutation: entityProcessEntity,
                                                variables: {
                                                    input: {
                                                        OP_TYPE: 'CREATE',
                                                        ...entityObj
                                                    }
                                                },
                                            });
                if (res.data.entityProcessEntity.errors === true) {
                    emitAlert(res.data.entityProcessEntity.message);
                    this.submitting = false;
                    this.backendActionItems = [];
                    return;
                }

                this.backendActionItems.push("Saving person info succeeded");


                // Now upload documents to documentum
                const entityId = res.data.entityProcessEntity.business_object._id;

                if (this.file_details.filter(file => { return file.valid === true; }).length > 0) {
                    this.backendActionItems.push("Saving person's documents");
                }
                
                for (const file of this.file_details) {
                    if (file.valid) {
                        const doc = this.documents_map[ file.id ];


                        const uploadRes = await submitDocumentumFile(file.id, file.file, 'entity', entityId);
                        if (uploadRes.errors === true) {
                            emitAlert(uploadRes.message);
                            this.submitting = false;
                            this.backendActionItems.push(`  --> Uploading ${doc.name} failed`);
                            return;
                        }
                        
                        this.backendActionItems.push(`  --> Uploading ${doc.name} succeeded`);
                    }
                }

                this.submitting = false;
                this.server_response_obj = res.data.entityProcessEntity.business_object;
                this.closeOnboardingDialog();
                emitSuccess(res.data.entityProcessEntity.message);
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.entityProcessEntity.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
        extractEntityInfo() {
            const entityObj = {
                name: null, 
                surname: null,
                id_number: null,
                gender: null,
                date_of_birth: null,
                marital_status: null,
                life_status: null,
            };

            entityObj.name = this.user_details.name;
            entityObj.surname = this.user_details.surname;
            entityObj.id_number = this.id_number_field;
            entityObj.gender = this.user_details.gender;
            entityObj.date_of_birth = this.birth_date_century_field + this.partial_birth_date_field;
            entityObj.marital_status = this.user_details.marital_status;
            entityObj.marketing_channels = this.user_details.marketing_channels;

            // ----------------------------------Extract Addresses if there are any
            const addresses = [];
            const validAddresses = this.addresses.filter(det => {
                return det.type !== null
            });

            if (validAddresses.length > 0) {
                validAddresses.forEach(addr => {
                    addresses.push({
                        type: addr.type,
                        line1: addr.line1,
                        line2: addr.line2,
                        line3: addr.line3,
                        line4: addr.line4,
                    });
                });
            }

            entityObj.addresses = addresses;

            // ----------------------------------Extract contact details if there are any
            const contacts = [];
            const validContacts = this.contact_details.filter(cont => {
                return cont.type !== null && cont.primary
            });

            if (validContacts.length > 0) {
                validContacts.forEach(cont => {
                    contacts.push({
                        type: cont.type,
                        primary: (cont.primary === 'Yes') ? true : false,
                        details: cont.details,
                    })
                })
            }

            entityObj.contact_details = contacts;

            // ----------------------------------Extract documents if there are any
            const documents = [];
            this.file_details.forEach(file => {
                if ((file.file !== null) && (file.type !== null)) {
                    const objID = ObjectID();

                    file.id = objID.toString();
                    file.valid = true;

                    documents.push({
                        _id: objID, //Generate mngodb id
                        type: file.type,
                        name: file.file.name,
                        mime_type: file.file.type,
                    });

                    this.documents_map[objID.toString()] = {
                        _id: objID, //Generate mngodb id
                        type: file.type,
                        name: file.file.name,
                        mime_type: file.file.type,
                    };
                }
            });

            entityObj.documents = documents;

            // ----------------------------------Extract banking details if there are any
            const banking = [];
            const validBanking = this.bank_account_details.filter(bank => {
                // For nebank, always set to cheque
                if (bank.bank === '5f4a98356229e10e83fa0e26') {
                    bank.account_type = 'Cheque'
                }
                return bank.bank !== null
            });

            if (validBanking.length > 0) {
                validBanking.forEach(bank => {
                    banking.push({
                        // purpose: bank.type,
                        bank: bank.bank,
                        account_type: bank.account_type,
                        account_number: bank.account_number,
                    });
                });
            }

            entityObj.bank_account_details = banking;

            return entityObj;
        }
    },
    async mounted() {
        this.submitting = true;
        try {
            const res = await this.$apollo
                                            .query({
                                                query: getBanks,
                                                // fetchPolicy: 'network-only',
                                                fetchPolicy: 'no-cache',
                                                errorPolicy: 'all',
                                                variables: {
                                                    objectType: 'BANK',
                                                    pagination: {
                                                        page: this.page,
                                                        limit: this.limit
                                                    }
                                                },
                                            });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.submitting = false;
                return;
            }
            
            this.banks_list = res.data.getBusinessObject.business_objects;

            // Reset fields
            this.submitting = false;
        } catch (error) {
            this.submitting = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
            
            return;
        }
    },
    watch: {
        id_number_field: function(val) {
            this.id_number_field_valid = 'NO';

            // Check if valid
            if (val.length === 13) {
                const valid = idValid(val);

                if (valid) {
                    this.id_number_field_valid = 'YES';
                }

                // The following is to cater for events when the user just copies, and pastes the whole id fiel
                // if (this.partial_birth_date_field === null && this.birth_date_century_field === null) {
                    const birthDate = extractBirthDate(val);
                    
                    this.partial_birth_date_field = birthDate.partial_date_string;
                    this.birth_date_century_field = birthDate.century_string;
                // }

                // if (this.user_details.gender === null) {
                    this.user_details.gender = extractGender(val);
                // }
            }

            // Extract Birth date
            if (val.length === 6) {
                const birthDate = extractBirthDate(val);
                this.partial_birth_date_field = birthDate.partial_date_string;
                this.birth_date_century_field = birthDate.century_string;
            }

            if (val.length < 6) {
                this.partial_birth_date_field = null;
                this.birth_date_century_field = null;
            }

            // extract gender
            if (val.length === 10) {
                this.user_details.gender = extractGender(val);
            }

            if (val.length < 10) {
                this.user_details.gender = null;
            }
        }
    }
}
</script>
<style scoped>

</style>
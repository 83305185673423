var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticClass:"pa-0",attrs:{"color":"secondary","dark":"","height":"50","extension-height":"3"}},[_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"10px"},attrs:{"sm":"5"}},[(_vm.SEARCH_MODE === false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.loadMoreData()}}},on),[_c('v-icon',[_vm._v("cloud_download")])],1)]}}],null,false,3262684405)},[_c('span',[_vm._v("Load More Data")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.openSearchBusinessProcessRunsDialog()}}},on),[_c('v-icon',[_vm._v("search")])],1)]}}])},[_c('span',[_vm._v("Search for business process runs")])]),(_vm.SEARCH_MODE === false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.refreshBusinessProcessRuns()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}],null,false,3475386646)},[_c('span',[_vm._v("Refresh")])]):_vm._e()],1),_c('v-col',{attrs:{"sm":"3"}},[(_vm.SEARCH_MODE === true)?_c('v-card-actions',[_c('h3',[_vm._v("Search Results")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"amber black--text"},on:{"click":function($event){return _vm.clearSearchResults()}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("search")]),_c('v-icon',{attrs:{"small":""}},[_vm._v("close")])],1)]}}],null,false,1354360713)},[_c('span',[_vm._v("Clear Search Results")])])],1):_vm._e()],1),_c('v-col',{staticStyle:{"margin-top":"10px"},attrs:{"sm":"4"}},[_c('div',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"amber black--text"},on:{"click":function($event){return _vm.openCreateBusinessProcessRunDialog()}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v("Trigger Business Process")])])],1)])],1)],1),_c('multipane',{staticClass:"horizontal-panes",staticStyle:{"height":"100%"},attrs:{"layout":"horizontal"}},[_c('div',{staticClass:"pane",style:({ height: '50%', maxHeight: '50%'})},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.main_tab_height,"headers":_vm.business_process_runs_details_headers,"items":_vm.business_process_runs,"item-key":"_id","single-select":true,"item-class":"secondary","hide-default-footer":"","loading":_vm.data_table_loading,"disable-pagination":true},on:{"click:row":_vm.selectMainTableRow},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.created !== null ? item.created.split('T')[0] + ' @ ' + item.created.split('T')[1].substr(0,8) : ''))])]}},{key:"item.captured_by",fn:function(ref){
var item = ref.item;
return [(item.captured_by !== null && item.captured_by !== undefined)?_c('td',[_vm._v(" "+_vm._s(item.captured_by.entity.name)+" "+_vm._s(item.captured_by.entity.surname)+" ")]):_vm._e()]}},{key:"item.time_taken",fn:function(ref){
var item = ref.item;
return [(item.time_taken !== null && item.time_taken !== undefined)?_c('td',[_vm._v(" "+_vm._s(item.time_taken / 1000)+" sec ")]):_vm._e()]}}],null,true)})],1)]),_c('CreateBusinessProcessDialog',{attrs:{"dialog":_vm.open_create_business_process_run_dialog}}),_c('SearchBusinessProcessesDialog',{attrs:{"dialog":_vm.open_search_business_process_runs_dialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import gql from 'graphql-tag'

export const getEntityRelationshipTypes = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on EntityRelationshipType {
                    __typename
                    _id
                    name
                    description
                    gender_map
                    payout_target_roles_for_principal
                    opposite_relationship {
                        _id
                        name
                    }
                }
            }
        }
    }
`
<template>
    <v-row justify="center">
        <!-- <v-dialog
            v-model="dialog"
            fullscreen
            persistent
        > -->
        <v-dialog
            v-model="dialog"
            persistent
        >
            <div style="background:white; overflow-y:auto; height:100%">
                <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                    <v-toolbar-title><b>Edit Dependent Details</b></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                class="mx-2"
                                color="red"
                                small
                                v-on="on"
                                @click="resetForm()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Reset Form</span>
                    </v-tooltip>
                    <v-btn icon @click="closeDialog()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card>
                    <v-card-text>
                        <DependentForm
                            :dependent="dependent_copy"
                            :product="product"
                            :show_numbering="false"
                        />
                        <v-card-text class="text-center" v-if="submitting">
                            Updating Dependent Details
                            <v-progress-linear
                                indeterminate
                                color="green"
                                class="mb-0"
                            ></v-progress-linear>
                        </v-card-text>
                    </v-card-text>
                    <!-- Button Section -->
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    large
                                    color="success"
                                    v-on="on"
                                    :disabled="submitBtnDisabled()"
                                    @click="savePolicy()"
                                >
                                    <v-icon large>save</v-icon>
                                </v-btn>
                            </template>
                            <span>Submit Update</span>
                        </v-tooltip>
                    </v-card-actions>
                </v-card>
            </div>
        </v-dialog>
    </v-row>
</template>
<script>
import { eventBus } from "../../../../../main";
import { emitAlert, emitSuccess } from "../../../../../utils/api";
import { idValid } from "../../../../../utils/id_number_check";
import DependentForm from "../components/DependentForm.vue";

import { businessProcessProspectPolicy } from "../gql/mutations";

export default {
    name: 'EditDependentDialog',
    components: {
        DependentForm
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        policy_id: {
            type: String,
            default: null
        },
        dependent_copy: {
            type: Object,
            default: null
        },
        dependent_original: {
            type: Object,
            default: null
        },
        product: {
            type: Object,
            default: null
        },
    },
    data: () => ({
        end_date_menu: false,
        server_response_obj: null,
        member_end_date: null,
        submitting: false
    }),
    methods: {
        closeDialog() {
            eventBus.$emit('CLOSE_EDIT_DEPENDENT_DIALOG', this.server_response_obj);
        },
        resetForm() {
            eventBus.$emit('RESET_EDIT_DEPENDENT_FORM', null);

            this.submitting = false;
            this.server_response_obj = null;

            // if (this.$refs.dependent_name) this.$refs.dependent_name.resetValidation();
            // if (this.$refs.dependent_surname) this.$refs.dependent_surname.resetValidation();
            // if (this.$refs.dependent_id_number) this.$refs.dependent_id_number.resetValidation();
        },
        submitBtnDisabled() {
            if (this.dependent_copy.member === null) {
                return true;
            }

            if (this.dependent_original.member !== null && this.dependent_copy.member !== null) {
                if (
                    this.dependent_original.member._id === this.dependent_copy.member._id &&
                    this.dependent_original.elected_dependent === this.dependent_copy.elected_dependent &&
                    this.dependent_original.spouse_to_principal === this.dependent_copy.spouse_to_principal
                ) {
                    return true;
                }
            }

            return false;
        },
        async savePolicy() {
            this.submitting = true;

            // Prepare payload
            const extractedPayloadObj = this.extractPayload();

            try {
                const payloadObj = {
                    OP_TYPE: 'UPDATE_DEPENDENT',
                    _id: this.policy_id,

                    dependents: [extractedPayloadObj]
                };

                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcessProspectPolicy,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.businessProcessProspectPolicy.errors === true) {
                    emitAlert(res.data.businessProcessProspectPolicy.message);
                    this.submitting = false;
                    return;
                }

                this.server_response_obj = res.data.businessProcessProspectPolicy.business_object;

                emitSuccess(res.data.businessProcessProspectPolicy.message);
                this.closeDialog();

                // Reset form
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessProspectPolicy.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
        },
        extractPayload() {
            const payloadObj = {
                _id: this.dependent_copy._id,
                // name: this.dependent_copy.name,
                // surname: this.dependent_copy.surname,
                // id_number: this.dependent_copy.id_number,
                entity: this.dependent_copy.member._id,
                spouse_to_principal: this.dependent_copy.spouse_to_principal,
                elected_dependent: this.dependent_copy.elected_dependent,
            };

            return payloadObj;
        }
    },
    mounted() {
        
    },
    watch: {
        dialog (dialogVal) {
            if (dialogVal === true) {
                const val = this.dependent_copy.id_number;
                this.dependent_copy.id_number_valid = false;

                // ---------------------------------------------------------
                // ---------------------- CHECK IF VALID -------------------
                // ---------------------------------------------------------
                if (val.length === 13) {
                    const valid = idValid(val);

                    if (valid) {
                        this.dependent_copy.id_number_valid = true;
                    }
                }
            }
        },
        'dependent_copy' (dep) {
            const val = dep.id_number;
            dep.id_number_valid = false;
            
            if (typeof(val) === 'string') {
                // ---------------------------------------------------------
                // ---------------------- CHECK IF VALID -------------------
                // ---------------------------------------------------------
                if (val.length === 13) {
                    const valid = idValid(val);
    
                    if (valid) {
                        dep.id_number_valid = true;
                    }
                }
            }
        }
    }
}
</script>
<style scoped>

</style>
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Rate Call</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-card v-if="recording_data !== null">
                                <v-card-text class="text-center">
                                    <audio controls>
                                        <source :src="recording_data" type="audio/mpeg">
                                        Your browser does not support the audio element.
                                      </audio>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-card v-if="recording_data !== null">
                                <v-card-text class="text-center">
                                    <v-select
                                        v-model="rating_data.call_answered"
                                        :items="[
                                            'YES',
                                            'NO',
                                        ]"
                                        label="Call Answered?"
                                        color="secondary"
                                        required
                                    ></v-select>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <!-- Notes -->
                        <v-col cols="12" sm="12">
                            <v-textarea
                                v-model="rating_data.notes"
                                name="Rating Notes"
                                color="secondary"
                                filled
                                label="Rating Notes"
                                auto-grow
                            ></v-textarea>
                        </v-col>

                        <!-- Acknowledgement of Debt -->
                        <v-col cols="12" sm="12" v-if="rating_data.call_answered === 'YES'">
                            <v-subheader class="pl-0"><h3>Client Acknowledgement of Debt</h3></v-subheader>
                            <br/>
                            <br/>
                            <v-slider
                                v-model="rating_data.debt_acknowledgement"
                                :thumb-size="44"
                                thumb-label="always"
                                :tick-labels="ticksLabels"

                                :max="5"
                                :min="1"
                                step="1"
                                ticks="always"
                                tick-size="5"
                            >
                                <template v-slot:thumb-label="{ value }">
                                    <h2>{{ satisfactionEmojis[value - 1] }}</h2>
                                </template>
                            </v-slider>
                        </v-col>

                        <!-- Tone of Client -->
                        <v-col cols="12" sm="12" v-if="rating_data.call_answered === 'YES'">
                            <v-subheader class="pl-0"><h3>Tone of Client</h3></v-subheader>
                            <br/>
                            <br/>
                            <v-slider
                                v-model="rating_data.tone_client"
                                :thumb-size="44"
                                thumb-label="always"
                                :tick-labels="ticksLabels"

                                :max="5"
                                :min="1"
                                step="1"
                                ticks="always"
                                tick-size="5"
                            >
                                <template v-slot:thumb-label="{ value }">
                                    <h2>{{ satisfactionEmojis[value - 1] }}</h2>
                                </template>
                            </v-slider>
                        </v-col>

                        <!-- Tone of Agent -->
                        <v-col cols="12" sm="12" v-if="rating_data.call_answered === 'YES'">
                            <v-subheader class="pl-0"><h3>Tone of Agent</h3></v-subheader>
                            <br/>
                            <br/>
                            <v-slider
                                v-model="rating_data.tone_agent"
                                :thumb-size="44"
                                thumb-label="always"
                                :tick-labels="ticksLabels"

                                :max="5"
                                :min="1"
                                step="1"
                                ticks="always"
                                tick-size="5"
                            >
                                <template v-slot:thumb-label="{ value }">
                                    <h2>{{ satisfactionEmojis[value - 1] }}</h2>
                                </template>
                            </v-slider>
                        </v-col>

                        <!-- Ten Day Collection Confidence -->
                        <v-col cols="12" sm="12" v-if="rating_data.call_answered === 'YES'">
                            <v-subheader class="pl-0"><h3>Confidence that we can collect in the next 10 days</h3></v-subheader>
                            <br/>
                            <br/>
                            <v-slider
                                v-model="rating_data.ten_day_collection_confidence"
                                :thumb-size="44"
                                thumb-label="always"
                                :tick-labels="ticksLabels"

                                :max="5"
                                :min="1"
                                step="1"
                                ticks="always"
                                tick-size="5"
                            >
                                <template v-slot:thumb-label="{ value }">
                                    <h2>{{ satisfactionEmojis[value - 1] }}</h2>
                                </template>
                            </v-slider>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting Call Rating
                        <v-progress-linear
                            indeterminate
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submitRatingDataBtnDisabled()"
                                @click="submitRatingData()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Submit Rating Data</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { eventBus } from '../../../main';
import { emitAlert } from '../../../utils/api';
import { entityProcessEntity } from "../gql/mutations";
export default {
    name: 'CallRatingDialog',
    components: {},
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        entity_id: {
            type: String,
            required: false,
            default: null
        },
        recording_data: {
            type: String,
            required: false,
            default: null
        },
        recording_obj: {
            type: Object,
            required: false,
            default: null
        },
    },
    data: () => ({
        // satisfactionEmojis: ['😭', '😢', '☹️', '🙁', '😐', '🙂', '😊', '😄', '😍'],
        satisfactionEmojis: ['😭', '🙁', '😐', '😊', '😍'],
        ticksLabels: [1, 2, 3, 4, 5],

        rating_data: {
            call_answered: null,
            debt_acknowledgement: 0,
            tone_client: 0,
            tone_agent: 0,
            ten_day_collection_confidence: 0,

            notes: null
        },
        submitting: false
    }),
    methods: {
        closeDialog() {
            eventBus.$emit('CLOSE_CALL_RATING_DIALOG', null);
        },
        resetForm() {
            this.rating_data = {
                call_answered: null,
                debt_acknowledgement: 0,
                tone_client: 0,
                tone_agent: 0,
                ten_day_collection_confidence: 0,

                notes: null
            };
        },
        submitRatingDataBtnDisabled() {
            if (this.rating_data.call_answered === null) {
                return true;
            }

            if (this.rating_data.call_answered === 'NO') {
                if (this.rating_data.notes === null) {
                    return true;
                }
                if (typeof(this.rating_data.notes) === 'string') {
                    if (this.rating_data.notes.trim() === '') {
                        return true;
                    }
                }
            }

            if (this.rating_data.call_answered === 'YES') {
                if (this.rating_data.notes === null) {
                    return true;
                }
                if (typeof(this.rating_data.notes) === 'string') {
                    if (this.rating_data.notes.trim() === '') {
                        return true;
                    }
                }
            }
            return false;
        },
        async submitRatingData() {
            this.submitting = true;

            if (this.rating_data.call_answered === 'NO') {
                this.rating_data.debt_acknowledgement = 0;
                this.rating_data.tone_agent = 0;
                this.rating_data.tone_client = 0;
                this.rating_data.ten_day_collection_confidence = 0;
                this.rating_data.total_score = -1;
            }

            if (this.rating_data.call_answered === 'YES') {
                let totalScore = 0;

                totalScore = (this.rating_data.debt_acknowledgement + this.rating_data.tone_agent + this.rating_data.tone_client + this.rating_data.ten_day_collection_confidence)
                totalScore = totalScore/4; // Average
                totalScore = Math.round((totalScore + Number.EPSILON) * 100) / 100;
                this.rating_data.total_score = totalScore;
            }

            const ratingsDataObj = {
                ...this.rating_data
            };

            ratingsDataObj.OP_TYPE = 'RATE_CALL';
            ratingsDataObj._id = this.recording_obj._id;
            ratingsDataObj.entity_id = this.entity_id;
            ratingsDataObj.call_sid = this.recording_obj.call_sid;

            // if (ratingsDataObj !== null) {
            //     console.log(ratingsDataObj)
            //     return
            // }

            try {
                const res = await this.$apollo
                                        .mutate({
                                            mutation: entityProcessEntity,
                                            variables: {
                                                input: {
                                                    OP_TYPE: 'RATE_CALL',
                                                    _id: this.recording_obj._id,
                                                    call_recordings: [ratingsDataObj]
                                                }
                                            },
                                        });
                this.submitting = false;
                if (res.data.entityProcessEntity.errors === true) {
                    emitAlert(res.data.entityProcessEntity.message);
                    return;
                }
    
                // this.loans = res.data.entityProcessEntity.business_objects;
                // eventBus.$emit('CLOSE_LOAN_SEARCH_DIALOG', res.data.entityProcessEntity.business_objects);
                eventBus.$emit('CLOSE_CALL_RATING_DIALOG', res.data.entityProcessEntity.business_object);
                this.resetForm();
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.entityProcessEntity.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
    },
    mounted() {
        
    },
    computed: {},
    watch: {}
}
</script>
<style scoped>

</style>
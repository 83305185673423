import gql from 'graphql-tag'

export const entityProcessUnderwriter = gql`
    mutation entityProcessUnderwriter($input: UnderwriterInput!) {
        entityProcessUnderwriter(input: $input) {
            errors
            message
            business_object {
                ... on Underwriter {
                    __typename
                    _id
                    name

                    created
                    captured_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }

                    documents {
                        _id
                        type
                        mime_type
                        name

                        captured_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }

                    bank_account_details {
                        _id
                        purpose
                        bank {
                            _id
                            name
                            universal_branch_code
                        }
                        account_type
                        account_number

                        captured_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }
                }
            }
        }
    }
`
import gql from 'graphql-tag'

export const entityProcessEntity = gql`
    mutation entityProcessEntity($input: EntityInput!) {
        entityProcessEntity(input: $input) {
            errors
            message
            business_object {
                ... on Entity {
                    _id
                    business_partner { _id }
                    name
                    surname
                    id_number
                    gender
                    date_of_birth
                    marital_status
                    life_status
                    marketing_channels

                    version_history {
                        _id
                        changes {
                            type
                            description
                            old
                            new
                            captured_by {
                                __typename
                                _id
                                entity {
                                    __typename
                                    _id
                                    name
                                    surname
                                }
                            }
                            created
                        }
                    }

                    relationships {
                        __typename
                        _id
                        created
                        deleted
                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        member {
                            __typename
                            _id
                            name
                            surname
                        }
                        relationship_type {
                            __typename
                            _id
                            name
                            description
                            gender_map
                            opposite_relationship {
                                __typename
                                _id
                                name
                            }
                            payout_target_roles_for_principal
                        }
                    }
                    
                    addresses {
                        __typename
                        _id
                        type
                        line1
                        line2
                        line3
                        line4
                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }

                    contact_details {
                        __typename
                        _id
                        type
                        primary
                        details

                        captured_by {
                            __typename
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }

                    documents {
                        __typename
                        _id
                        type
                        mime_type
                        name

                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }

                    bank_account_details {
                        __typename
                        _id
                        # purpose
                        bank {
                            __typename
                            _id
                            name
                            universal_branch_code
                        }
                        account_type
                        account_number

                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }

                    captured_by {
                        __typename
                        _id
                        entity {
                            __typename
                            _id
                            name
                            surname
                        }
                    }
                    created
                }
            }
        }
    }
`
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Listen To Call</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeRecordingListenDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card v-if="recording_data !== null">
                <v-card-text class="text-center">
                    <audio controls>
                        <source :src="recording_data" type="audio/mpeg">
                        Your browser does not support the audio element.
                      </audio>
                    <!-- <v-progress-linear
                        indeterminate
                        color="green"
                        class="mb-0"
                    ></v-progress-linear> -->
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { eventBus } from '../../../main';
export default {
    name: 'ListenToCallRecordingDialog',
    components: {},
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        recording_data: {
            type: String,
            required: false,
            default: null
        },
    },
    data: () => ({
        submitting: false
    }),
    methods: {
        closeRecordingListenDialog() {
            eventBus.$emit('CLOSE_CALL_RECORDING_LISTEN_DIALOG', null);
        },
    },
    mounted() {
        
    },
    computed: {
        recepient_numbers() {
            // console.log(this.entityObj)
            const activenumbers = this.entityObj.entity.contact_details.filter(cont => {
                                                        return (
                                                            cont.type === 'Mobile' &&
                                                            cont.deleted === false
                                                        )
                                                    })
                                                    .map(cont => {
                                                        return cont.details
                                                    });

            return activenumbers.join(', ');
        }
    },
    watch: {
        // "dialog": async function(val) {
        //     if (val === true) {
        //         await this.submitCall();
        //     }
        // },
        "recording_data": function(val) {
            if (val === true) {
                console.log(val)
            }
        },
    }
}
</script>
<style scoped>

</style>
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Add Product Rules</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row
                        v-for="rule in rules"
                        :key="rule.id"
                        style="background:#eaeaea; padding-bottom:10px"
                    >
                        <v-col cols="12" sm="2">
                            <v-text-field
                                v-model="rule_level"
                                label="Level"
                                autocomplete="some-random-text2"
                                type="text"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-select
                                v-model="rule.rule_parameter_obj"
                                :items="filtered_rule_parameters"
                                color="secondary"
                                item-text="name"
                                label="Rule Type"
                                return-object
                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-text-field
                                :value="rule.rule_parameter_obj === null ? null : rule.rule_parameter_obj.id"
                                label="Rule ID"
                                autocomplete="some-random-text2"
                                type="number"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-text-field
                                v-model="rule.min"
                                color="secondary"
                                label="Minimum"
                                autocomplete="some-random-text2"
                                type="number"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-text-field
                                v-model="rule.max"
                                color="secondary"
                                label="Maximum"
                                autocomplete="some-random-text2"
                                type="number"
                                required
                            ></v-text-field>
                        </v-col>
                        <div style="background:white; height: 10px; width: 100%"></div>
                    </v-row>
                    <!-- Buttons for Adding/Removing Rules -->
                    <v-row>
                        <v-col sm="10"></v-col>
                        <v-col sm="2">
                            <v-tooltip bottom v-if="rules.length > 1">
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        class="mx-2"
                                        color="secondary"
                                        small
                                        v-on="on"
                                        @click="removeRule()"
                                    >
                                        <v-icon>remove</v-icon>
                                    </v-btn>
                                </template>
                                <span>Remove Rule</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        color="secondary"
                                        small
                                        v-on="on"
                                        @click="addRule()"
                                    >
                                        <v-icon>add</v-icon>
                                    </v-btn>
                                </template>
                                <span>Add Another Rule</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting Product Rules
                        <v-progress-linear
                            indeterminate
                            :active="submitting"
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submitDataBtnDisabled()"
                                @click="submitData()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Save Product Rule</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../../../utils/api";
import { eventBus } from "../../../../../main";

import { getProductRuleParameters } from "../gql/queries";
import { productProcessInsuranceProductPrimary } from "../gql/mutations";

export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        product_id: {
            type: String,
            default: null
        },
        rule_level: {
            type: String,
            default: null
        },
    },
    data: () => ({
        server_response_obj: null,
        submitting: false,
        rules: [
            {
                id: 1,
                rule_parameter: null,
                rule_parameter_obj: null,
                min: null,
                max: null,
            }
        ],
        product_rule_parameters: [],
    }),
    computed: {
        filtered_rule_parameters() {
            return this.product_rule_parameters.filter(rule_parameter => { return rule_parameter.level === this.rule_level });
        }
    },
    methods: {
        closeDialog() {
            eventBus.$emit('CLOSE_ADD_PRODUCT_RULE_PARAMETERS_DIALOG', this.server_response_obj);
        },
        
        //--------------------ADD/REMOVE RULES--------------------------//
        addRule() {
            const rules_size = this.rules.length;

            this.rules.push({
                id: rules_size + 1,
                rule_parameter: null,
                rule_parameter_obj: null,
                min: null,
                max: null,
            });
        },
        removeRule() {
            this.rules.pop();
        },

        resetForm() {
            this.submitting = false;
            this.server_response_obj = null;
            this.rules = [
                {
                    id: 1,
                    rule_parameter: null,
                    rule_parameter_obj: null,
                    min: null,
                    max: null,
                }
            ];
        },
        submitDataBtnDisabled() {
            const validRules = this.rules
                                    .filter(rule => {
                                        return (
                                            rule.type !== null &&
                                            rule.rule_parameter_obj !== null &&
                                            !isNaN(rule.min) &&
                                            !isNaN(rule.max) &&
                                            parseFloat(rule.max) >= parseFloat(rule.min)
                                        );
                                    });

            if (validRules.length === 0) {
                return true;
            }

            return false;
        },

        async submitData() {
            const validRules = this.rules
                                    .filter(rule => {
                                        return (
                                            rule.type !== null &&
                                            !isNaN(rule.min) &&
                                            !isNaN(rule.max) &&
                                            parseFloat(rule.max) >= parseFloat(rule.min)
                                        );
                                    });

            // deep copy the validRules array
            const arrCopy = JSON.parse(JSON.stringify(validRules));
            
            // prepare payload object
            arrCopy.forEach(rule => {
                        delete rule.id;
                        rule.level = this.rule_level; // Component param
                        rule.rule_parameter = rule.rule_parameter_obj._id;

                        // Sanitize numerical values
                        rule.min = parseFloat(rule.min);
                        rule.max = parseFloat(rule.max);

                        delete rule.rule_parameter_obj;
                    });

            try {
                this.submitting = true;

                const payloadObj = {
                    OP_TYPE: 'ADD_PRODUCT_RULE',
                    _id: this.product_id,

                    // Placeholder fields
                    name: '',
                    underwriter_premium: 0,
                    broker_commission: 0,
                    broker_markup: 0,

                    rules: arrCopy
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: productProcessInsuranceProductPrimary,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.productProcessInsuranceProductPrimary.errors === true) {
                    emitAlert(res.data.productProcessInsuranceProductPrimary.message);
                    this.submitting = false;
                    return;
                }

                this.submitting = false;
                this.server_response_obj = res.data.productProcessInsuranceProductPrimary.business_object;

                this.closeDialog();
                emitSuccess(res.data.productProcessInsuranceProductPrimary.message);

                // Reset fields
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.productProcessInsuranceProductPrimary.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
    },
    async mounted() {
        // Load lookup data
        try {
            const res = await this.$apollo
                                    .query({
                                        query: getProductRuleParameters,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'PRODUCT_RULE_PARAMETER',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.submitting = false;
                return;
            }
            this.product_rule_parameters = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.submitting = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
            
            return;
        }
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
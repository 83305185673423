<template>
    <div style="background:#ffc70073; border-radius:10px">
        <v-row>
            <v-col cols="12" sm="1">
                <div
                    v-if="show_numbering === true"
                    style="padding:20px"
                >
                    <b>#{{dependent.id}}</b>
                </div>
            </v-col>
            <v-col cols="12" sm="6">
                <v-row>
                    <v-col cols="12" sm="10">
                        <MemberSearch :member="dependent"/>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-tooltip bottom v-if="dependent.member === null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="primary white--text"
                                    fab
                                    v-on="on"
                                    @click="openCaptureEntityDialog(`dependent_${dependent.id}`)"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Capture Entity</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row v-if="dependent.member !== null">
            <v-col cols="12" sm="1"></v-col>
            <v-col cols="12" sm="4">
                <v-text-field
                    v-model="dependent.name"
                    ref="dependent_name"
                    :rules="name_rules"
                    color="secondary"
                    label="Name"
                    persistent-hint
                    required
                    disabled
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
                <v-text-field
                    v-model="dependent.surname"
                    ref="dependent_surname"
                    :rules="surname_rules"
                    color="secondary"
                    label="Surname"
                    persistent-hint
                    required
                    disabled
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
                <v-text-field
                    v-model="dependent.id_number"
                    ref="dependent_id_number"
                    :rules="id_number_rules"
                    color="secondary"
                    label="ID Number"
                    type="number"
                    persistent-hint
                    required
                    disabled
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="1">
                <template>
                    <div>
                        <span>Id Valid</span><br>
                        <span v-if="dependent.id_number_valid === true"><v-icon color="success">mdi-check</v-icon></span>
                        <span v-else><v-icon color="red">mdi-close</v-icon></span>
                    </div>
                </template>
            </v-col>
        </v-row>

        <!-- Second line -->
        <v-row no-gutters v-if="dependent.member !== null">
            <v-col
                cols="12"
                sm="1"
            >
                <!-- Spacer -->
            </v-col>
            <v-col cols="12" sm="1">
                <v-text-field
                    v-model="memberAgeComputed"
                    color="secondary"
                    hint="Age"
                    persistent-hint
                    disabled
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="1">
                <v-text-field
                    v-model="dependent.gender"
                    color="secondary"
                    hint="Gender"
                    persistent-hint
                    disabled
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="1">
                <v-text-field
                    :value="formatNumber(dependent.cover_amount)"
                    prefix="R"
                    color="secondary"
                    hint="Payout Amount"
                    persistent-hint
                    disabled
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="1">
                <!-- Spacer -->
            </v-col>
            <v-col cols="12" sm="2" v-if="dependent.id_number_valid" style="background:#b8a952; padding-left:10px;">
                <v-checkbox
                    v-model="dependent.spouse_to_principal"
                    color="primary"
                    label="Spouse to principal member?"
                ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="2" v-if="dependent.id_number_valid" style="background:#b8a952">
                <v-checkbox
                    v-model="dependent.elected_dependent"
                    color="primary"
                    label="Elected Dependent?"
                ></v-checkbox>
            </v-col>
        </v-row>
        <AddEntity
            :dialog="capture_entity_dialog"
            :identifier="add_entity_identifier"
        />
    </div>
</template>
<script>
import {
    idValid,
    extractBirthDate,
    // extractGender
} from "../../../../../utils/id_number_check";
import { calculateEntityAge } from "../../../../../utils/age";
import { eventBus } from '../../../../../main';
import converter from '../../../../../mixins/converter';
import MemberSearch from "./member_search.vue";
import AddEntity from "../../../../people/dialogs/onboarding.vue";
export default {
    name: "DependentForm",
    mixins: [converter],
    components: {
        MemberSearch,
        AddEntity
    },
    props: {
        dependent: {
            required: true,
            type: Object,
            default: () => {
                return {
                    id: null,
                    name: null,
                    surname: null,
                    id_number: null,
                    id_number_valid: false,

                    spouse_to_principal: false,
                    elected_dependent: false,
                }
            }
        },
        product: {
            required: false,
            type: Object,
            default: null
        },
        show_numbering: {
            required: true,
            type: Boolean,
            default: true
        }
    },
    data: () => ({
        name_rules: [
            v => !!v || 'Name is required',
            v => v.length >= 2 || 'Min 2 characters'
        ],
        surname_rules: [
            v => !!v || 'Surname is required',
            v => v.length >= 2 || 'Min 2 characters'
        ],
        id_number_rules: [
            v => !!v || 'ID number is required',
            v => v.length === 13 || '13 characters required'
        ],

        capture_entity_dialog: false,
        add_entity_identifier: null,
    }),
    methods: {
        resetForm() {
            // DEPENDENTS
            if (this.$refs.dependent_name) if (this.$refs.dependent_name) this.$refs.dependent_name.resetValidation();
            if (this.$refs.dependent_surname) if (this.$refs.dependent_surname) this.$refs.dependent_surname.resetValidation();
            if (this.$refs.dependent_id_number) if (this.$refs.dependent_id_number) this.$refs.dependent_id_number.resetValidation();
        },

        openCaptureEntityDialog(identifier) {
            this.add_entity_identifier = identifier;
            this.capture_entity_dialog = true;
        },
        closeCaptureEntityDialog(newEntity, identifier) {
            this.add_entity_identifier = null;
            this.capture_entity_dialog = false;
            eventBus.$emit('CLOSE_ONBOARDING_DIALOG_FROM_DEPENDENT_FROM', newEntity, identifier);
        },
    },
    computed: {
        memberAgeComputed () {
            let returnValue = null;
            if (typeof(this.dependent.id_number) === 'string') {
                if (this.dependent.id_number.length >= 6) {
                    const birthDateObj = extractBirthDate(this.dependent.id_number);
                    const memberAge = calculateEntityAge(birthDateObj.century_string + birthDateObj.partial_date_string);
                    returnValue = memberAge;
                }
            }
            return returnValue;
        },
    },
    mounted() {
        eventBus.$on('CLOSE_ONBOARDING_DIALOG', this.closeCaptureEntityDialog);

        eventBus.$on('ELECTED_DEPENDENT_CHANGED', (dependentParam) => {
            // Allow only one elected dependent
            if (
                this.dependent.elected_dependent === true &&
                dependentParam.id !== this.dependent.id
            ) {
                this.dependent.elected_dependent = false
            }
        });
        eventBus.$on('SPOUSE_TO_PRINCIPAL_CHANGED', (dependentParam) => {
            // Allow only one spouse
            if (
                this.dependent.spouse_to_principal === true &&
                dependentParam.id !== this.dependent.id
            ) {
                this.dependent.spouse_to_principal = false
            }
        });

        eventBus.$on('RESET_DEPENDENT_FORM_FIELDS', () => {
            this.resetForm();
        });
    },
    watch: {
        'dependent.id_number': {
            handler (val) {
                this.dependent.id_number_valid = false;

                if (typeof(val) === 'string') {
                    // ---------------------------------------------------------
                    // ---------------------- CHECK IF VALID -------------------
                    // ---------------------------------------------------------
                    if (val.length === 13) {
                        const valid = idValid(val);
    
                        if (valid) {
                            this.dependent.id_number_valid = true;
                        } else {
                            // Reset spousal and elected dependent values
                            this.dependent.spouse_to_principal = false;
                            this.dependent.elected_dependent = false;
                        }
                    }
                }
            }
        },
        'dependent.elected_dependent' (val) {
            if (val === true) {
                eventBus.$emit('ELECTED_DEPENDENT_CHANGED', this.dependent);
            }
        },
        'dependent.spouse_to_principal' (val) {
            if (val === true) {
                eventBus.$emit('SPOUSE_TO_PRINCIPAL_CHANGED', this.dependent);
            }
        },

        'dependent.member': {
            handler(serverEntity) {
                if (serverEntity !== null && serverEntity !== undefined) {
                    // ---------------------------------------------------
                    // ---------------- BASIC DETAILS --------------------
                    // ---------------------------------------------------
                    this.dependent.name = serverEntity.name;
                    this.dependent.surname = serverEntity.surname;
                    this.dependent.id_number = serverEntity.id_number;
                } else {
                    this.dependent.name = '';
                    this.dependent.surname = '';
                    this.dependent.id_number = '';
                }
            },
            deep: true
        }
    }
};
</script>
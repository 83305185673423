import axios from 'axios';
import { eventBus } from '../main';
import { store } from '../../store';

axios.defaults.baseURL = '/api';
// axios.defaults.timeout = 60000;
axios.defaults.timeout = 300000;

// error overides
const axiosOverride = axios.create();
// set authorization header
// if (window.$cookies.get('token')) {
//   axios.defaults.headers.common['authorization'] = `Bearer ${window.$cookies.get('token')}`;
//   axiosOverride.defaults.headers.common['authorization'] = `Bearer ${window.$cookies.get('token')}`;
// }

// set client instance header
// if (window.$cookies.get('YC-Client-Instance')) {
//   axios.defaults.headers.common['YC-Client-Instance'] = window.$cookies.get('YC-Client-Instance');
//   axiosOverride.defaults.headers.common['YC-Client-Instance'] = window.$cookies.get('YC-Client-Instance');
// }

// error alert messages
function emitAlert(alert_message) {
    if (!alert_message) {
        eventBus.$emit('alertError', 'Server connection error, please try again later. If the problem persists, please contact the administrator.');
    } else {
        eventBus.$emit('alertError', `Error: ${alert_message}`);
    }
    // eventBus.$emit('progressBar', false);
}

function emitSuccess(success_message) {
    eventBus.$emit('alertSuccess', `Success: ${success_message}`);
}

function clearAlert() {
    eventBus.$emit('alertClear');
}

// check auth cookie
function checkAuth() {
    eventBus.$emit('checkAuth');
}

// request interceptor
axiosOverride.interceptors.request.use((config) => {
    // const tokenCookie = window.$cookies.get('token');

    const token = store.state.token;

    const selected_context = store.state.context_list.filter(ctx => { return ctx.selected === true })[0];
    const context = selected_context.business_partner._id;

    if (token !== null) {
        config.headers['authorization'] = `Bearer ${token}`;
        config.headers['x-as-context'] = `${context}`;
    }

    // config.onUploadProgress(progress)
    return config;
}, (error) => {
    emitAlert();
    return Promise.reject(error);
});

// response interceptor
axiosOverride.interceptors.response.use((response) => {
    // console.log('header', response['headers']['token_renew'])
    // if (response['headers']['token_renew'] !== "NONE") {
    if (response['headers']['token_renew']) {
        // window.$cookies.set('token', response['headers']['token_renew']);
        store.commit('update_token', response['headers']['token_renew']);
    }

    clearAlert();
    checkAuth();
    return response;
}, (error) => {
    console.log('error', error)
    if (error.response.status === 401) {
        // The token has expired. So logout
        eventBus.$emit('userLogout');
        return;
    }

    emitAlert(error.response.data);
    return Promise.reject(error);
});



//************************************************* api calls ************************************************//
function healthCheck() {
    const url = '/-/health';
    return axiosOverride.get(url)
        .then(res => res.data);
}
//-------------------------AUTH-CONTEXT API CALLS------------------------//
function switchUserContext(context_id) {
    const url = `/v1/auth/context/${context_id}`;
    return axiosOverride.put(url).then(res => res.data);
}


//-----------------------------BANKS API CALLS---------------------------//
function getBanks() {
    const url = '/v1/bank/';
    return axiosOverride.get(url)
        .then(res => res.data);
}

function createBank(name, universal_branch_code) {
    const url = '/v1/bank/';
    return axiosOverride.post(url, {
        name: name,
        universal_branch_code: universal_branch_code
    }).then(res => res.data);
}

//-----------------------------REL TYPES API CALLS---------------------------//
function createEntityRelationshipType(payloadObj) {
    const url = '/v1/entity_relationship_type/';
    return axiosOverride.post(url, payloadObj)
        .then(res => res.data);
}

function getEntityRelationshipTypes() {
    const url = '/v1/entity_relationship_type/';
    return axiosOverride.get(url)
        .then(res => res.data);
}

function editEntityRelationshipType(rel_type_id, opposite_relationship) {
    const url = `/v1/entity_relationship_type/${rel_type_id}`;
    return axiosOverride.put(url, {
        opposite_relationship: opposite_relationship
    }).then(res => res.data);
}


//-----------------------------PAYOUT AGE CAT API CALLS---------------------------//
function createPayoutAgeCategory(name, membership_category, start_age, end_age) {
    const url = '/v1/payout_age_cat/';
    return axiosOverride.post(url, {
        name: name,
        membership_category: membership_category,
        start_age: start_age,
        end_age: end_age
    }).then(res => res.data);
}

function getPayoutAgeCategories() {
    const url = '/v1/payout_age_cat/';
    return axiosOverride.get(url)
        .then(res => res.data);
}

function editPayoutAgeCategory(cat_id, name, start_age, end_age) {
    const url = `/v1/payout_age_cat/${cat_id}`;
    return axiosOverride.put(url, {
        name: name,
        start_age: start_age,
        end_age: end_age,
    }).then(res => res.data);
}

//-----------------------------VAS API CALLS---------------------------//
function getVASList() {
    const url = '/v1/vas/';
    return axiosOverride.get(url)
        .then(res => res.data);
}

function createVAS(vasObj) {
    const url = '/v1/vas/';
    return axiosOverride.post(url, vasObj).then(res => res.data);
}

function getVASCategoryList() {
    const url = '/v1/vas_category/';
    return axiosOverride.get(url)
        .then(res => res.data);
}

//-----------------------------UNDERWRITER API CALLS---------------------------//
function createUnderwriter(underwriterObj) {
    const url = '/v1/underwriter/';
    return axiosOverride.post(url, underwriterObj)
        .then(res => res.data);
}

function getUnderwriters() {
    const url = '/v1/underwriter/';
    return axiosOverride.get(url)
        .then(res => res.data);
}


function addUnderwriterDocument(underwriterId, documentObj) {
    const url = `/v1/underwriter/${underwriterId}/document/`;
    return axiosOverride.post(url, documentObj)
        .then(res => res.data);
}

function deleteUnderwriterDocument(underwriterId, documentId, detailsObj) {
    const url = `/v1/underwriter/${underwriterId}/document/${documentId}`;
    return axiosOverride.delete(url, { data: detailsObj })
        .then(res => res.data);
}

function addUnderwriterBanking(underwriterId, bankingList) {
    const url = `/v1/underwriter/${underwriterId}/banking/`;
    return axiosOverride.post(url, bankingList)
        .then(res => res.data);
}

function updateUnderwriterBanking(underwriterId, banking_id, bankingObj) {
    const url = `/v1/underwriter/${underwriterId}/banking/${banking_id}`;
    return axiosOverride.put(url, bankingObj)
        .then(res => res.data);
}

function deleteUnderwriteryBanking(underwriterId, bankingId, detailsObj) {
    const url = `/v1/underwriter/${underwriterId}/banking/${bankingId}`;
    return axiosOverride.delete(url, { data: detailsObj })
        .then(res => res.data);
}

//-----------------------------INSURANCE PRODUCT API CALLS---------------------------//
function createProduct(productType, productObj) {
    const url = `/v1/insurance/product/${productType}/`;
    return axiosOverride.post(url, productObj)
        .then(res => res.data);
}

function getProducts(productType) {
    const url = `/v1/insurance/product/${productType}/`;
    return axiosOverride.get(url)
        .then(res => res.data);
}

function editProductDetails(productType, product_id, productObj) {
    const url = `/v1/insurance/product/${productType}/${product_id}`;
    return axiosOverride.put(url, productObj)
        .then(res => res.data);
}

function addProductPayoutItem(product_id, payoutItemObj) {
    const url = `/v1/insurance/product/primary/${product_id}/payout/`;
    return axiosOverride.post(url, [payoutItemObj])
        .then(res => res.data);
}

function deleteProductPayoutItem(product_id, payout_structure_id) {
    const url = `/v1/insurance/product/primary/${product_id}/payout/${payout_structure_id}`;
    return axiosOverride.delete(url)
        .then(res => res.data);
}

function addProductRules(product_id, productRulesList) {
    const url = `/v1/insurance/product/primary/${product_id}/rule/`;
    return axiosOverride.post(url, productRulesList)
        .then(res => res.data);
}

function deleteProductRule(product_id, rule_id) {
    const url = `/v1/insurance/product/primary/${product_id}/rule/${rule_id}`;
    return axiosOverride.delete(url)
        .then(res => res.data);
}

function addProductVAS(product_id, vasList) {
    const url = `/v1/insurance/product/primary/${product_id}/vas/`;
    return axiosOverride.post(url, vasList)
        .then(res => res.data);
}

function deleteProductVAS(product_id, vasId) {
    const url = `/v1/insurance/product/primary/${product_id}/vas/${vasId}`;
    return axiosOverride.delete(url)
        .then(res => res.data);
}

function updateProductVAS(product_id, vas_id, vasObj) {
    const url = `/v1/insurance/product/primary/${product_id}/vas/${vas_id}`;
    return axiosOverride.put(url, vasObj)
        .then(res => res.data);
}

//-----------------------------INSURANCE PRODUCT API CALLS---------------------------//
function createCreditProduct(productType, productObj) {
    const url = `/v1/credit/product/${productType}/`;
    return axiosOverride.post(url, productObj)
        .then(res => res.data);
}

function getCreditProducts(productType) {
    const url = `/v1/credit/product/${productType}/`;
    return axiosOverride.get(url)
        .then(res => res.data);
}

function editCreditProductDetails(productType, product_id, productObj) {
    const url = `/v1/credit/product/${productType}/${product_id}`;
    return axiosOverride.put(url, productObj)
        .then(res => res.data);
}

function addCreditProductRules(product_id, productRulesList) {
    const url = `/v1/credit/product/primary/${product_id}/rule/`;
    return axiosOverride.post(url, productRulesList)
        .then(res => res.data);
}

function deleteCreditProductRule(product_id, rule_id) {
    const url = `/v1/credit/product/primary/${product_id}/rule/${rule_id}`;
    return axiosOverride.delete(url)
        .then(res => res.data);
}

//-----------------------------BUSINESS PARTNER API CALLS---------------------------//
function getBusinessPartners(page, limit) {
    const url = `/v1/bp/?page=${page}&limit=${limit}`;
    return axiosOverride.get(url)
        .then(res => res.data);
}

function createBusinessPartner(bpObj) {
    const url = '/v1/bp/';
    return axiosOverride.post(url, bpObj)
        .then(res => res.data);
}

function updateBusinessPartnerDetails(bp_id, bpObj) {
    const url = `/v1/bp/${bp_id}`;
    return axiosOverride.put(url, bpObj)
        .then(res => res.data);
}

function searchBusinessPartner(searchObj) {
    const url = '/v1/bp/search/';
    return axiosOverride.post(url, searchObj)
        .then(res => res.data);
}

function addBusinessPartnerVAS(bp_id, vasList) {
    const url = `/v1/bp/${bp_id}/vas/`;
    return axiosOverride.post(url, vasList)
        .then(res => res.data);
}

function deleteBusinessPartnerVAS(bp_id, vasId, detailsObj) {
    const url = `/v1/bp/${bp_id}/vas/${vasId}`;
    return axiosOverride.delete(url, { data: detailsObj })
        .then(res => res.data);
}

function updateBusinessPartnerVAS(bp_id, vas_id, vasObj) {
    const url = `/v1/bp/${bp_id}/vas/${vas_id}`;
    return axiosOverride.put(url, vasObj)
        .then(res => res.data);
}

function addBusinessPartnerAddresses(bp_id, addressList) {
    const url = `/v1/bp/${bp_id}/address/`;
    return axiosOverride.post(url, addressList)
        .then(res => res.data);
}

function deleteBusinessPartnerAddress(bp_id, addressId, detailsObj) {
    const url = `/v1/bp/${bp_id}/address/${addressId}`;
    return axiosOverride.delete(url, { data: detailsObj })
        .then(res => res.data);
}

function updateBusinessPartnerAddress(bp_id, address_id, addressObj) {
    const url = `/v1/bp/${bp_id}/address/${address_id}`;
    return axiosOverride.put(url, addressObj)
        .then(res => res.data);
}

function addBusinessPartnerContact(bp_id, contactList) {
    const url = `/v1/bp/${bp_id}/contact/`;
    return axiosOverride.post(url, contactList)
        .then(res => res.data);
}

function updateBusinessPartnerContact(bp_id, contact_id, contactObj) {
    const url = `/v1/bp/${bp_id}/contact/${contact_id}`;
    return axiosOverride.put(url, contactObj)
        .then(res => res.data);
}

function deleteBusinessPartnerContact(bp_id, contactId, detailsObj) {
    const url = `/v1/bp/${bp_id}/contact/${contactId}`;
    return axiosOverride.delete(url, { data: detailsObj })
        .then(res => res.data);
}

function addBusinessPartnerDocument(bp_id, documentObj) {
    const url = `/v1/bp/${bp_id}/document/`;
    return axiosOverride.post(url, documentObj)
        .then(res => res.data);
}

function deleteBusinessPartnerDocument(bp_id, documentId, detailsObj) {
    const url = `/v1/bp/${bp_id}/document/${documentId}`;
    return axiosOverride.delete(url, { data: detailsObj })
        .then(res => res.data);
}

function addBusinessPartnerBanking(bp_id, bankingList) {
    const url = `/v1/bp/${bp_id}/banking/`;
    return axiosOverride.post(url, bankingList)
        .then(res => res.data);
}

function updateBusinessPartnerBanking(bp_id, banking_id, bankingObj) {
    const url = `/v1/bp/${bp_id}/banking/${banking_id}`;
    return axiosOverride.put(url, bankingObj)
        .then(res => res.data);
}

function deleteBusinessPartnerBanking(bp_id, bankingId, detailsObj) {
    const url = `/v1/bp/${bp_id}/banking/${bankingId}`;
    return axiosOverride.delete(url, { data: detailsObj })
        .then(res => res.data);
}

//-----------------------------ENTITY API CALLS---------------------------//
function getEntities(page, limit) {
    const url = `/v1/entity/?page=${page}&limit=${limit}`;
    return axiosOverride.get(url)
        .then(res => res.data);
}

function getEntityById(entity_id) {
    const url = `/v1/entity/${entity_id}`;
    return axiosOverride.get(url)
        .then(res => res.data);
}

function createEntity(entityObj) {
    const url = '/v1/entity/';
    return axiosOverride.post(url, entityObj)
        .then(res => res.data);
}

function updateEntityDetails(entity_id, entityObj) {
    const url = `/v1/entity/${entity_id}`;
    return axiosOverride.put(url, entityObj)
        .then(res => res.data);
}

function searchEntity(searchObj) {
    const url = '/v1/entity/search/';
    return axiosOverride.post(url, searchObj)
        .then(res => res.data);
}

function searchEntityAutocomplete(searchObj) {
    const url = '/v1/entity/search/autocomplete/';
    return axiosOverride.post(url, searchObj)
        .then(res => res.data);
}

//-----------------------------ENTITY-SUB-DETAILS API CALLS---------------------------//
function addEntityAddresses(entity_id, addressList) {
    const url = `/v1/entity/${entity_id}/address/`;
    return axiosOverride.post(url, addressList)
        .then(res => res.data);
}

function updateEntityAddresses(entity_id, address_id, addressObj) {
    const url = `/v1/entity/${entity_id}/address/${address_id}`;
    return axiosOverride.put(url, addressObj)
        .then(res => res.data);
}

function deleteEntityAddress(entityId, addressId, detailsObj) {
    const url = `/v1/entity/${entityId}/address/${addressId}`;
    return axiosOverride.delete(url, { data: detailsObj })
        .then(res => res.data);
}

function addEntityContact(entity_id, contactList) {
    const url = `/v1/entity/${entity_id}/contact/`;
    return axiosOverride.post(url, contactList)
        .then(res => res.data);
}

function updateEntityContact(entity_id, contact_id, contactObj) {
    const url = `/v1/entity/${entity_id}/contact/${contact_id}`;
    return axiosOverride.put(url, contactObj)
        .then(res => res.data);
}

function deleteEntityContact(entityId, contactId, detailsObj) {
    const url = `/v1/entity/${entityId}/contact/${contactId}`;
    return axiosOverride.delete(url, { data: detailsObj })
        .then(res => res.data);
}

function addEntityDocument(entityId, documentObj) {
    const url = `/v1/entity/${entityId}/document/`;
    return axiosOverride.post(url, documentObj)
        .then(res => res.data);
}

function deleteEntityDocument(entityId, documentId, detailsObj) {
    const url = `/v1/entity/${entityId}/document/${documentId}`;
    return axiosOverride.delete(url, { data: detailsObj })
        .then(res => res.data);
}

function addEntityBanking(entity_id, bankingList) {
    const url = `/v1/entity/${entity_id}/banking/`;
    return axiosOverride.post(url, bankingList)
        .then(res => res.data);
}

function updateEntityBanking(entity_id, banking_id, bankingObj) {
    const url = `/v1/entity/${entity_id}/banking/${banking_id}`;
    return axiosOverride.put(url, bankingObj)
        .then(res => res.data);
}

function deleteEntityBanking(entityId, bankingId, detailsObj) {
    const url = `/v1/entity/${entityId}/banking/${bankingId}`;
    return axiosOverride.delete(url, { data: detailsObj })
        .then(res => res.data);
}

function getRelatedEntities(entity_id) {
    const url = `/v1/entity/${entity_id}/relationship/`;
    return axiosOverride.get(url)
        .then(res => res.data);
}

function addEntityRelationship(entity_id, relationshipList) {
    const url = `/v1/entity/${entity_id}/relationship/`;
    return axiosOverride.post(url, relationshipList)
        .then(res => res.data);
}

function deleteEntityRelationship(entityId, relationshipId, detailsObj) {
    const url = `/v1/entity/${entityId}/relationship/${relationshipId}`;
    return axiosOverride.delete(url, { data: detailsObj })
        .then(res => res.data);
}

//-----------------------------SCHEME API CALLS---------------------------//
function getSchemes(page, limit) {
    const url = `/v1/scheme/?page=${page}&limit=${limit}`;
    return axiosOverride.get(url)
        .then(res => res.data);
}

function createScheme(schemeObj) {
    const url = '/v1/scheme/';
    return axiosOverride.post(url, schemeObj)
        .then(res => res.data);
}

function updateSchemeDetails(scheme_id, schemeObj) {
    const url = `/v1/scheme/${scheme_id}`;
    return axiosOverride.put(url, schemeObj)
        .then(res => res.data);
}

function searchScheme(searchObj) {
    const url = '/v1/scheme/search/';
    return axiosOverride.post(url, searchObj)
        .then(res => res.data);
}

//-----------------------------SCHEME-SUB-DETAILS API CALLS---------------------------//
function addSchemeAddresses(scheme_id, addressList) {
    const url = `/v1/scheme/${scheme_id}/address/`;
    return axiosOverride.post(url, addressList)
        .then(res => res.data);
}

function deleteSchemeAddress(schemeId, addressId, detailsObj) {
    const url = `/v1/scheme/${schemeId}/address/${addressId}`;
    return axiosOverride.delete(url, { data: detailsObj })
        .then(res => res.data);
}

function updateSchemeAddress(scheme_id, address_id, addressObj) {
    const url = `/v1/scheme/${scheme_id}/address/${address_id}`;
    return axiosOverride.put(url, addressObj)
        .then(res => res.data);
}

function addSchemeContact(scheme_id, contactList) {
    const url = `/v1/scheme/${scheme_id}/contact/`;
    return axiosOverride.post(url, contactList)
        .then(res => res.data);
}

function updateSchemeContact(scheme_id, contact_id, contactObj) {
    const url = `/v1/scheme/${scheme_id}/contact/${contact_id}`;
    return axiosOverride.put(url, contactObj)
        .then(res => res.data);
}

function deleteSchemeContact(schemeId, contactId, detailsObj) {
    const url = `/v1/scheme/${schemeId}/contact/${contactId}`;
    return axiosOverride.delete(url, { data: detailsObj })
        .then(res => res.data);
}

function addSchemeDocument(scheme_id, documentObj) {
    const url = `/v1/scheme/${scheme_id}/document/`;
    return axiosOverride.post(url, documentObj)
        .then(res => res.data);
}

function deleteSchemeDocument(schemeId, documentId, detailsObj) {
    const url = `/v1/scheme/${schemeId}/document/${documentId}`;
    return axiosOverride.delete(url, { data: detailsObj })
        .then(res => res.data);
}

function addSchemeBanking(scheme_id, bankingList) {
    const url = `/v1/scheme/${scheme_id}/banking/`;
    return axiosOverride.post(url, bankingList)
        .then(res => res.data);
}

function updateSchemeBanking(scheme_id, banking_id, bankingObj) {
    const url = `/v1/scheme/${scheme_id}/banking/${banking_id}`;
    return axiosOverride.put(url, bankingObj)
        .then(res => res.data);
}

function deleteSchemeBanking(schemeId, bankingId, detailsObj) {
    const url = `/v1/scheme/${schemeId}/banking/${bankingId}`;
    return axiosOverride.delete(url, { data: detailsObj })
        .then(res => res.data);
}

//-----------------------------LOAN API CALLS---------------------------//
function getLoans(page, limit) {
    const url = `/v1/loan/?page=${page}&limit=${limit}`;
    return axiosOverride.get(url)
        .then(res => res.data);
}

function createLoan(loanObj) {
    const url = `/v1/loan/`;
    return axiosOverride.post(url, loanObj)
        .then(res => res.data);
}

function updateLoanTopLevelDetails(loan_id, payloadObj) {
    const url = `/v1/loan/${loan_id}`;
    return axiosOverride.put(url, payloadObj)
        .then(res => res.data);
}

function deleteDraftedLoan(loanId) {
    const url = `/v1/loan/${loanId}`;
    return axiosOverride.delete(url)
        .then(res => res.data);
}

function searchLoan(searchObj) {
    const url = '/v1/loan/search/';
    return axiosOverride.post(url, searchObj)
        .then(res => res.data);
}

function addLoanDocument(loanId, documentObj) {
    const url = `/v1/loan/${loanId}/document/`;
    return axiosOverride.post(url, documentObj)
        .then(res => res.data);
}

function deleteLoanDocument(loanId, documentId, detailsObj) {
    const url = `/v1/loan/${loanId}/document/${documentId}`;
    return axiosOverride.delete(url, { data: detailsObj })
        .then(res => res.data);
}

function validateLoan(loan_id) {
    const url = `/v1/loan/${loan_id}/validation/`;
    return axiosOverride.get(url)
        .then(res => res.data);
}

function changeLoanStatus(loan_id, bodyObj) {
    const url = `/v1/loan/${loan_id}/status/`;
    return axiosOverride.post(url, bodyObj)
        .then(res => res.data);
}

function addLoanPaymentHolidayClaim(loanId) {
    const url = `/v1/loan/${loanId}/payment_holiday/`;
    return axiosOverride.post(url)
        .then(res => res.data);
}

//-----------------------------POLICY API CALLS---------------------------//
function getPolicies(page, limit) {
    const url = `/v1/policy/?page=${page}&limit=${limit}`;
    return axiosOverride.get(url)
        .then(res => res.data);
}

function createPolicy(policyObj) {
    const url = `/v1/policy/`;
    return axiosOverride.post(url, policyObj)
        .then(res => res.data);
}

function addPolicyMembers(policy_id, memberList) {
    const url = `/v1/policy/${policy_id}/member/`;
    return axiosOverride.post(url, memberList)
        .then(res => res.data);
}

function deletePolicyMember(policy_id, membership_id) {
    const url = `/v1/policy/${policy_id}/member/${membership_id}`;
    return axiosOverride.delete(url)
        .then(res => res.data);
}

function expirePolicyMember(policy_id, membership_id, payloadObj) {
    const url = `/v1/policy/${policy_id}/member/${membership_id}`;
    return axiosOverride.delete(url, { data: payloadObj })
        .then(res => res.data);
}

function updatePolicyMemberUnderwriterCoverStatus(policy_id, membership_id, payloadObj) {
    const url = `/v1/policy/${policy_id}/member/${membership_id}/underwriter_cover/`;
    return axiosOverride.put(url, payloadObj)
        .then(res => res.data);
}

function getPolicyPremium(policy_id, memberList) {
    const url = `/v1/policy/${policy_id}/premium/`;
    return axiosOverride.get(url, memberList)
        .then(res => res.data);
}

function addPolicyDocument(policyId, documentObj) {
    const url = `/v1/policy/${policyId}/document/`;
    return axiosOverride.post(url, documentObj)
        .then(res => res.data);
}

function searchPolicy(searchObj) {
    const url = '/v1/policy/search/';
    return axiosOverride.post(url, searchObj)
        .then(res => res.data);
}

function validatePolicy(policy_id) {
    const url = `/v1/policy/${policy_id}/validation/`;
    return axiosOverride.get(url)
        .then(res => res.data);
}

function changePolicyStatus(policy_id, bodyObj) {
    const url = `/v1/policy/${policy_id}/status/`;
    return axiosOverride.post(url, bodyObj)
        .then(res => res.data);
}

function deleteDraftedPolicy(policyId) {
    const url = `/v1/policy/${policyId}`;
    return axiosOverride.delete(url)
        .then(res => res.data);
}

function updatePolicyMemberStartDate(policy_id, membership_id, payloadObj) {
    const url = `/v1/policy/${policy_id}/member/${membership_id}`;
    return axiosOverride.put(url, payloadObj)
        .then(res => res.data);
}

function updatePolicyTopLevelDetails(policy_id, payloadObj) {
    const url = `/v1/policy/${policy_id}`;
    return axiosOverride.put(url, payloadObj)
        .then(res => res.data);
}

function getPolicyCertificate(policy_id) {
    const url = `/policy/${policy_id}/certificate/`;
    return axiosOverride.get(url, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
        })
        .then(res => {
            return res.data
        });
}

//-----------------------------POLICY PROSPECT API CALLS---------------------------//
function getPolicyProspects(page, limit) {
    const url = `/v1/policy_prospect/?page=${page}&limit=${limit}`;
    return axiosOverride.get(url)
        .then(res => res.data);
}

function createPolicyProspect(policyObj) {
    const url = `/v1/policy_prospect/`;
    return axiosOverride.post(url, policyObj)
        .then(res => res.data);
}

function addPolicyProspectMember(policy_id, memberList) {
    const url = `/v1/policy_prospect/${policy_id}/member/`;
    return axiosOverride.post(url, memberList)
        .then(res => res.data);
}

function updatePolicyProspectMember(policy_id, member_id, payloadObj) {
    const url = `/v1/policy_prospect/${policy_id}/member/${member_id}`;
    return axiosOverride.put(url, payloadObj)
        .then(res => res.data);
}

function deletePolicyProspectMember(policy_id, membership_id) {
    const url = `/v1/policy_prospect/${policy_id}/member/${membership_id}`;
    return axiosOverride.delete(url)
        .then(res => res.data);
}

function updatePolicyProspectDetails(policy_id, payloadObj) {
    const url = `/v1/policy_prospect/${policy_id}`;
    return axiosOverride.put(url, payloadObj)
        .then(res => res.data);
}

function getPolicyProspectCertificate(policy_id) {
    const url = `/v1/policy_prospect/${policy_id}/application_form/`;
    return axiosOverride.get(url)
        .then(res => res.data);
}

function deleteDraftedProspectPolicy(policyId) {
    const url = `/v1/policy_prospect/${policyId}`;
    return axiosOverride.delete(url)
        .then(res => res.data);
}

function addProspectPolicyDocument(policyId, documentObj) {
    const url = `/v1/policy_prospect/${policyId}/document/`;
    return axiosOverride.post(url, documentObj)
        .then(res => res.data);
}

function deleteProspectPolicyDocument(entityId, documentId, detailsObj) {
    const url = `/v1/policy_prospect/${entityId}/document/${documentId}`;
    return axiosOverride.delete(url, { data: detailsObj })
        .then(res => res.data);
}

function validateProspectPolicy(policy_id) {
    const url = `/v1/policy_prospect/${policy_id}/validation/`;
    return axiosOverride.get(url)
        .then(res => res.data);
}

//-----------------------------PRODUCT RULE TYPES API CALLS---------------------------//
function getProductRuleParameters() {
    const url = '/v1/product_rule_parameter/';
    return axiosOverride.get(url)
        .then(res => res.data);
}

//-----------------------------USER SCREEN API CALLS---------------------------//
function getUserScreens() {
    const url = '/v1/user_screen/';
    return axiosOverride.get(url)
        .then(res => res.data);
}

function createUserScreen(screenObj) {
    const url = '/v1/user_screen/';
    return axiosOverride.post(url, screenObj)
        .then(res => res.data);
}

//-----------------------------USER ROLES API CALLS---------------------------//
function getUserRoles() {
    const url = '/v1/user_role/';
    return axiosOverride.get(url)
        .then(res => res.data);
}

function getUserRolesContextualized() {
    const url = '/v1/user_role/contextualized/';
    return axiosOverride.get(url)
        .then(res => res.data);
}

function createUserRole(userRoleObj) {
    const url = '/v1/user_role/';
    return axiosOverride.post(url, userRoleObj)
        .then(res => res.data);
}

function updateUserRole(role_id, roleDetails) {
    const url = `/v1/user_role/${role_id}`;
    return axiosOverride.put(url, roleDetails)
        .then(res => res.data);
}

function addUserRoleAllowedScreens(role_id, userRoleAllowedScreens) {
    const url = `/v1/user_role/${role_id}/screen/`;
    return axiosOverride.put(url, userRoleAllowedScreens)
        .then(res => res.data);
}

function deleteUserRoleAllowedScreen(role_id, screen_id) {
    const url = `/v1/user_role/${role_id}/screen/${screen_id}`;
    return axiosOverride.delete(url)
        .then(res => res.data);
}

function addUserRoleAllowedReports(role_id, userRoleAllowedReports) {
    const url = `/v1/user_role/${role_id}/report/`;
    return axiosOverride.put(url, userRoleAllowedReports)
        .then(res => res.data);
}

function deleteUserRoleAllowedReport(role_id, report_id) {
    const url = `/v1/user_role/${role_id}/report/${report_id}`;
    return axiosOverride.delete(url)
        .then(res => res.data);
}

function addUserRoleAllowedDashboards(role_id, userRoleAllowedDashboards) {
    const url = `/v1/user_role/${role_id}/dashboard/`;
    return axiosOverride.put(url, userRoleAllowedDashboards)
        .then(res => res.data);
}

function deleteUserRoleAllowedDashboard(role_id, dashboard_id) {
    const url = `/v1/user_role/${role_id}/dashboard/${dashboard_id}`;
    return axiosOverride.delete(url)
        .then(res => res.data);
}

function addUserRoleAllowedBusinessProcesses(role_id, userRoleAllowedBusinessProcesses) {
    const url = `/v1/user_role/${role_id}/business_process/`;
    return axiosOverride.put(url, userRoleAllowedBusinessProcesses)
        .then(res => res.data);
}

function deleteUserRoleAllowedBusinessProcess(role_id, bsuiness_process_id) {
    const url = `/v1/user_role/${role_id}/business_process/${bsuiness_process_id}`;
    return axiosOverride.delete(url)
        .then(res => res.data);
}

//-----------------------------USERS API CALLS---------------------------//
function getUsers(page, limit) {
    const url = `/v1/user/?page=${page}&limit=${limit}`;
    return axiosOverride.get(url)
        .then(res => res.data);
}

function getUser(user_id) {
    const url = `/v1/user/${user_id}`;
    return axiosOverride.get(url)
        .then(res => res.data);
}

function getUserByEntity(entity_id) {
    const url = `/v1/user/entity/${entity_id}`;
    return axiosOverride.get(url)
        .then(res => res.data);
}

function userDeepRefresh() {
    const url = '/v1/auth/deep_refresh/';
    return axiosOverride.get(url)
        .then(res => res.data);
}

function getLoggedInUser() {
    // const tokenCookie = window.$cookies.get('token');
    const token = store.state.token;
    
    const selected_context = store.state.context_list.filter(ctx => { return ctx.selected === true })[0];
    const context = selected_context.business_partner._id;
    
    const config = {
        headers: {
            'authorization': `Bearer ${token}`,
            'x-as-context': `${context}`,
        }
    }

    const url = `/v1/auth/me/`;
    return axiosOverride.get(url, config)
        .then(res => res.data);
}

function createUser(userObj) {
    const url = '/v1/user/';
    return axiosOverride.post(url, userObj)
        .then(res => res.data);
}

function resetUserPassword(user_id) {
    const url = `/v1/user/${user_id}/password/`;
    return axiosOverride.put(url)
        .then(res => res.data);
}

function deactivateUser(user_id) {
    const url = `/v1/user/${user_id}`;
    return axiosOverride.delete(url)
        .then(res => res.data);
}

function reactivateUser(user_id) {
    const url = `/v1/user/${user_id}/reactivate/`;
    return axiosOverride.put(url)
        .then(res => res.data);
}

function addUserRoles(user_id, userRoles) {
    const url = `/v1/user/${user_id}/role/`;
    return axiosOverride.put(url, userRoles)
        .then(res => res.data);
}

function deleteUserAssignedRole(user_id, role_id) {
    const url = `/v1/user/${user_id}/role/${role_id}`;
    return axiosOverride.delete(url)
        .then(res => res.data);
}

//-----------------------------DIRECTOR API CALLS---------------------------//
function createDirector(directorObj) {
    const url = '/v1/director/';
    return axiosOverride.post(url, directorObj)
        .then(res => res.data);
}

function getDirectors() {
    const url = '/v1/director/';
    return axiosOverride.get(url)
        .then(res => res.data);
}

function deactivateDirector(director_id) {
    const url = `/v1/director/${director_id}/deactivate/`;
    return axiosOverride.delete(url)
        .then(res => res.data);
}

function reactivateDirector(director_id) {
    const url = `/v1/director/${director_id}/reactivate/`;
    return axiosOverride.put(url)
        .then(res => res.data);
}

function searchDirectorAutocomplete(searchObj) {
    const url = '/v1/director/search/autocomplete/';
    return axiosOverride.post(url, searchObj)
        .then(res => res.data);
}

//-----------------------------CLAIM API CALLS---------------------------//
function getClaims(page, limit) {
    const url = `/v1/claim/?page=${page}&limit=${limit}`;
    return axiosOverride.get(url)
    .then(res => res.data);
}

function createClaim(claimObj) {
    const url = `/v1/claim/`;
    return axiosOverride.post(url, claimObj)
    .then(res => res.data);
}

function addClaimDocument(claimId, documentObj) {
    const url = `/v1/claim/${claimId}/document/`;
    return axiosOverride.post(url, documentObj)
    .then(res => res.data);
}

function validateClaim(claim_id) {
    const url = `/v1/claim/${claim_id}/validation/`;
    return axiosOverride.get(url)
    .then(res => res.data);
}

function changeClaimStatus(claim_id, bodyObj) {
    const url = `/v1/claim/${claim_id}/status/`;
    return axiosOverride.post(url, bodyObj)
    .then(res => res.data);
}


//-----------------------------AGENT API CALLS---------------------------//
function createAgent(agetObj) {
    const url = '/v1/agent/';
    return axiosOverride.post(url, agetObj)
    .then(res => res.data);
}

function getAgents(page, limit) {
    const url = `/v1/agent/?page=${page}&limit=${limit}`;
    return axiosOverride.get(url)
        .then(res => res.data);
}

function getAgentByEntity(entity_id) {
    const url = `/v1/agent/entity/${entity_id}`;
    return axiosOverride.get(url)
        .then(res => res.data);
}

function updateAgentDetails(agent_id, agentObj) {
    const url = `/v1/agent/${agent_id}`;
    return axiosOverride.put(url, agentObj)
        .then(res => res.data);
}

function addAgentDocument(agent_id, documentObj) {
    const url = `/v1/agent/${agent_id}/document/`;
    return axiosOverride.post(url, documentObj)
        .then(res => res.data);
}

function searchAgentAutocomplete(searchObj) {
    const url = '/v1/agent/search/autocomplete/';
    return axiosOverride.post(url, searchObj)
        .then(res => res.data);
}

function addAgentCommissionRule(agent_id, commissionRuleList) {
    const url = `/v1/agent/${agent_id}/commission_rule/`;
    return axiosOverride.post(url, commissionRuleList)
        .then(res => res.data);
}

function updateAgentCommissionRule(agent_id, rule_id, commRuleObj) {
    const url = `/v1/agent/${agent_id}/commission_rule/${rule_id}`;
    return axiosOverride.put(url, commRuleObj)
        .then(res => res.data);
}

function deleteAgentCommissionRule(agentId, ruleId, detailsObj) {
    const url = `/v1/agent/${agentId}/commission_rule/${ruleId}`;
    return axiosOverride.delete(url, { data: detailsObj })
        .then(res => res.data);
}

//-----------------------------DOCUMENTUM API CALLS---------------------------//
function generatePolicyApplicationForm(policy_id) {
    const url = `/policy_prospect/${policy_id}/application_form/`;
    return axiosOverride.get(url, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
        })
        .then(res => {
            return res.data
        });
}

function generateLoanQuoteApplicationForm(bond_id) {
    const url = `/loan_quote/${bond_id}/application_form/`;
    return axiosOverride.get(url, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
        })
        .then(res => {
            return res.data
        });
}

function generateBondApplicationForm(bond_id) {
    const url = `/corporate_bond/${bond_id}/application_form/`;
    return axiosOverride.get(url, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
        })
        .then(res => {
            return res.data
        });
}

function generateSchemeAllocationForm(scheme_id, calendar_month_id) {
    const url = `/scheme/${scheme_id}/allocation_form/?cm=${calendar_month_id}`;
    return axiosOverride.get(url, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
        })
        .then(res => {
            return res.data
        });
}

function getAgentCommissionsReport(agent_id, start_date, end_date) {
    const url = `/agent/${agent_id}/commissions_report/?start_date=${start_date}&end_date=${end_date}`;
    return axiosOverride.get(url, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
        })
        .then(res => {
            return res.data
        });
}

function getAgentGloabCommissionsReport(start_date, end_date) {
    const url = `/global_commissions_report/?start_date=${start_date}&end_date=${end_date}`;
    return axiosOverride.get(url, {
            // responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                // 'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
        })
        .then(res => {
            return res.data
        });
}

function submitDocumentumFile(file_id, file, param_path, param_id) {
    const formData = new FormData();
    formData.append("documentum_file", file);
    formData.append("param_path", param_path);
    formData.append("param_id", param_id);
    
    const url = `/documentum/${file_id}`;

    return axiosOverride.post(url, formData)
        .then(res => res.data);
}

function submitSignatures(
    principal_signature,
    principal_initials,
    premium_payer_signature,
    premium_payer_initials,
    policy_id,
    signature_location
) {
    const formData = new FormData();
    formData.append("documentum_file", principal_signature, 'principal-signature');
    formData.append("documentum_file", principal_initials, 'principal-initials');
    formData.append("documentum_file", premium_payer_signature, 'premium-payer-signature');
    formData.append("documentum_file", premium_payer_initials, 'premium-payer-initials');
    formData.append("signature_location", signature_location);

    const url = `/policy_prospect/${policy_id}/application_form_signing/`;

    return axiosOverride.post(url, formData)
        .then(res => res.data);
}

function submitLoanQuoteSignatures(
    entity_signature,
    entity_initials,
    loan_quote_id,
    signature_location
) {
    const formData = new FormData();
    formData.append("documentum_file", entity_signature, 'entity-signature');
    formData.append("documentum_file", entity_initials, 'entity-initials');
    formData.append("signature_location", signature_location);

    const url = `/loan_quote/${loan_quote_id}/application_form_signing/`;

    return axiosOverride.post(url, formData)
        .then(res => res.data);
}

function getDocumentumFile(file_path) {
    const url = `/documentum/?path=${file_path}`;

    return axiosOverride.get(url, {
            responseType: 'arraybuffer',
            timeout: 120000 // 120 seconds
        })
        .then(res => res);
}

function deleteDocumentumFile(file_id, param_path, param_id) {
    const url = `/documentum_delete/${file_id}`;

    const formData = new FormData();
    formData.append("param_path", param_path);
    formData.append("param_id", param_id);

    // return axiosOverride.delete(url, { data: { param_path: param_path, param_id: param_id }} )
    // return axiosOverride.post(url, { param_path: param_path, param_id: param_id })
    return axiosOverride.post(url, formData)
        .then(res => res.data);
}

function downloadSafricanReport(batch_payment_id) {
    const url = `/underwriter_batch_payment/${batch_payment_id}/safrican/`;

    return axiosOverride.get(url, {
            responseType: 'blob',
            timeout: 120000 // 120 seconds
        })
        .then(res => res);
}

function getCallRecording(entity_id, call_recording_id) {
    const url = `/entity/${entity_id}/call_recordings/${call_recording_id}`;
    return axiosOverride.get(url, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'audio/mpeg'
            }
        })
        .then(res => {
            return res.data
        });
}

//-----------------------------REPORTS API CALLS---------------------------//

function getReports() {
    const url = `/v1/report/`;

    return axiosOverride.get(url)
        .then(res => res.data);
}

function createReport(payloadObj) {
    const url = `/v1/report/`;
    return axiosOverride.post(url, payloadObj)
    .then(res => res.data);
}

//-----------------------------BUSINESS PROCESSES API CALLS---------------------------//

function getBusinessProcesses() {
    const url = `/v1/business_process/`;

    return axiosOverride.get(url)
        .then(res => res.data);
}

function createBusinessProcess(payloadObj) {
    const url = `/v1/business_process/`;
    return axiosOverride.post(url, payloadObj)
    .then(res => res.data);
}


//-----------------------------DASHBOARDS API CALLS---------------------------//

function getDashboards() {
    const url = `/v1/dashboard/`;

    return axiosOverride.get(url)
        .then(res => res.data);
}

function createDashboard(payloadObj) {
    const url = `/v1/dashboard/`;
    return axiosOverride.post(url, payloadObj)
    .then(res => res.data);
}

//-----------------------------FIN CALENDAR API CALLS---------------------------//
function getFinancialCalendar(page, limit) {
    const url = `/v1/finance/calendar/?page=${page}&limit=${limit}`;
    return axiosOverride.get(url)
    .then(res => res.data);
}

function getOpenedFinancialCalendarMonths(page, limit) {
    const url = `/v1/finance/calendar/month/open/?page=${page}&limit=${limit}`;
    return axiosOverride.get(url)
    .then(res => res.data);
}

function generateFinancialCalendar(payloadObj) {
    const url = `/v1/finance/calendar/`;
    return axiosOverride.post(url, payloadObj)
    .then(res => res.data);
}

function openFinancialYear(year_id) {
    const url = `/v1/finance/calendar/year/${year_id}`;
    return axiosOverride.put(url)
    .then(res => res.data);
}

function closeFinancialYear(year_id) {
    const url = `/v1/finance/calendar/year/${year_id}`;
    return axiosOverride.delete(url)
    .then(res => res.data);
}

function openFinancialMonth(month_id) {
    const url = `/v1/finance/calendar/month/${month_id}`;
    return axiosOverride.put(url)
    .then(res => res.data);
}

function closeFinancialMonth(month_id) {
    const url = `/v1/finance/calendar/month/${month_id}`;
    return axiosOverride.delete(url)
    .then(res => res.data);
}

function getFinCalendarYearRangeLookup() {
    const url = `/v1/finance/calendar/range/`;
    return axiosOverride.get(url)
    .then(res => res.data);
}

//-----------------------------SYSTEM ACCOUNT API CALLS---------------------------//
function getSystemFinanceAccounts() {
    const url = `/v1/finance/account/system/`;
    return axiosOverride.get(url)
    .then(res => res.data);
}

function generateSystemFinanceAccounts() {
    const url = `/v1/finance/account/system/`;
    return axiosOverride.post(url)
    .then(res => res.data);
}

//-----------------------------FINANCE ACCOUNT API CALLS---------------------------//
function createFinanceTransaction(transactionObj) {
    const url = `/v1/finance/transaction/`;
    return axiosOverride.post(url, transactionObj)
    .then(res => res.data);
}

function getFinanceTransactionsList(account_id,) {
    const page = 1;
    const limit = 50;
    const url = `/v1/finance/${account_id}/transaction/?page=${page}&limit=${limit}`;
    return axiosOverride.get(url)
    .then(res => res.data);
}

//-----------------------------SERVER PROCESS API CALLS---------------------------//
function submitServerProcessTrigger(payloadObj) {
    const url = `/v1/process/`;
    return axiosOverride.post(url, payloadObj)
        .then(res => res.data);
}

export {
    healthCheck,
    // General Error
    emitAlert,
    emitSuccess,

    // Auth context
    switchUserContext,

    
    // Banks
    getBanks,
    createBank,


    // Entity Relationship types
    createEntityRelationshipType,
    editEntityRelationshipType,
    getEntityRelationshipTypes,


    // Payout Age Category
    createPayoutAgeCategory,
    getPayoutAgeCategories,
    editPayoutAgeCategory,

    // VAS
    getVASList,
    createVAS,
    getVASCategoryList,


    // Underwriter
    createUnderwriter,
    getUnderwriters,
    addUnderwriterDocument,
    deleteUnderwriterDocument,
    addUnderwriterBanking,
    updateUnderwriterBanking,
    deleteUnderwriteryBanking,


    // Insurance Product
    createProduct,
    getProducts,
    editProductDetails,
    addProductPayoutItem,
    deleteProductPayoutItem,
    addProductRules,
    deleteProductRule,
    addProductVAS,
    deleteProductVAS,
    updateProductVAS,

    // Credit Products
    createCreditProduct,
    getCreditProducts,
    editCreditProductDetails,
    addCreditProductRules,
    deleteCreditProductRule,

    // Business Partner
    getBusinessPartners,
    createBusinessPartner,
    updateBusinessPartnerDetails,
    searchBusinessPartner,
    addBusinessPartnerVAS,
    deleteBusinessPartnerVAS,
    updateBusinessPartnerVAS,
    addBusinessPartnerAddresses,
    deleteBusinessPartnerAddress,
    updateBusinessPartnerAddress,
    addBusinessPartnerContact,
    updateBusinessPartnerContact,
    deleteBusinessPartnerContact,
    addBusinessPartnerDocument,
    deleteBusinessPartnerDocument,
    addBusinessPartnerBanking,
    updateBusinessPartnerBanking,
    deleteBusinessPartnerBanking,


    // Entity
    getEntities,
    getEntityById,
    createEntity,
    updateEntityDetails,
    searchEntity,
    searchEntityAutocomplete,


    // Entity Sub-Details
    addEntityAddresses,
    updateEntityAddresses,
    deleteEntityAddress,
    addEntityContact,
    updateEntityContact,
    deleteEntityContact,
    addEntityBanking,
    updateEntityBanking,
    deleteEntityBanking,
    getRelatedEntities,
    addEntityRelationship,
    deleteEntityRelationship,
    addEntityDocument,
    deleteEntityDocument,
    getCallRecording,


    // Scheme
    getSchemes,
    createScheme,
    updateSchemeDetails,
    searchScheme,


    // Scheme Sub-Details
    addSchemeAddresses,
    deleteSchemeAddress,
    updateSchemeAddress,
    addSchemeContact,
    updateSchemeContact,
    deleteSchemeContact,
    addSchemeDocument,
    deleteSchemeDocument,
    addSchemeBanking,
    updateSchemeBanking,
    deleteSchemeBanking,


    // Loans
    getLoans,
    createLoan,
    updateLoanTopLevelDetails,
    deleteDraftedLoan,
    searchLoan,
    addLoanDocument,
    deleteLoanDocument,
    validateLoan,
    changeLoanStatus,
    addLoanPaymentHolidayClaim,


    // Policy
    getPolicies,
    createPolicy,
    addPolicyMembers,
    deletePolicyMember,
    expirePolicyMember,
    updatePolicyMemberUnderwriterCoverStatus,
    getPolicyPremium,
    addPolicyDocument,
    searchPolicy,
    validatePolicy,
    changePolicyStatus,
    deleteDraftedPolicy,
    updatePolicyMemberStartDate,
    updatePolicyTopLevelDetails,
    getPolicyCertificate,


    // Policy prospects
    getPolicyProspects,
    createPolicyProspect,
    addPolicyProspectMember,
    updatePolicyProspectMember,
    deletePolicyProspectMember,
    updatePolicyProspectDetails,
    getPolicyProspectCertificate,
    deleteDraftedProspectPolicy,
    addProspectPolicyDocument,
    deleteProspectPolicyDocument,
    validateProspectPolicy,


    // Product Rule Parameters
    getProductRuleParameters,


    // User Screens
    getUserScreens,
    createUserScreen,


    // User Roles
    getUserRoles,
    getUserRolesContextualized,
    createUserRole,
    updateUserRole,
    addUserRoleAllowedScreens,
    deleteUserRoleAllowedScreen,
    addUserRoleAllowedReports,
    deleteUserRoleAllowedReport,
    addUserRoleAllowedDashboards,
    deleteUserRoleAllowedDashboard,
    addUserRoleAllowedBusinessProcesses,
    deleteUserRoleAllowedBusinessProcess,


    // Users
    getUsers,
    getUser,
    getUserByEntity,
    getLoggedInUser,
    userDeepRefresh,
    createUser,
    resetUserPassword,
    deactivateUser,
    reactivateUser,
    addUserRoles,
    deleteUserAssignedRole,


    // Director
    createDirector,
    getDirectors,
    deactivateDirector,
    reactivateDirector,
    searchDirectorAutocomplete,


    // Claim
    getClaims,
    createClaim,
    addClaimDocument,
    validateClaim,
    changeClaimStatus,


    // Agent
    createAgent,
    getAgents,
    getAgentByEntity,
    updateAgentDetails,
    addAgentDocument,
    searchAgentAutocomplete,
    addAgentCommissionRule,
    updateAgentCommissionRule,
    deleteAgentCommissionRule,


    // Documentum
    generatePolicyApplicationForm,
    generateLoanQuoteApplicationForm,
    generateBondApplicationForm,
    generateSchemeAllocationForm,
    getAgentCommissionsReport,
    getAgentGloabCommissionsReport,
    submitDocumentumFile,
    getDocumentumFile,
    deleteDocumentumFile,
    downloadSafricanReport,
    submitSignatures,
    submitLoanQuoteSignatures,

    // Reports
    getReports,
    createReport,

    // Business processes Lookups
    getBusinessProcesses,
    createBusinessProcess,

    // Dashboards
    getDashboards,
    createDashboard,


    // Finance
    getFinancialCalendar,
    getOpenedFinancialCalendarMonths,
    generateFinancialCalendar,
    openFinancialYear,
    closeFinancialYear,
    openFinancialMonth,
    closeFinancialMonth,
    getFinCalendarYearRangeLookup,

    getSystemFinanceAccounts,
    generateSystemFinanceAccounts,
    createFinanceTransaction,
    getFinanceTransactionsList,
    

    // Server Process
    submitServerProcessTrigger
};

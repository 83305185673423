<template>
    <div>
        <v-toolbar
            color="secondary"
            dark
            class="pa-0"
            height="50"
            extension-height="3"
        >
            <v-row>
                <!-- Grid Buttons -->
                <v-col sm="5" style="margin-top:10px">
                    <v-tooltip bottom v-if="SEARCH_MODE === false">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                @click="loadMoreData()"
                                v-on="on"
                            >
                                <v-icon>cloud_download</v-icon>
                            </v-btn>
                        </template>
                        <span>Load More Data</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="openSearchPolicyDialog()"
                            >
                                <v-icon>search</v-icon>
                            </v-btn>
                        </template>
                        <span>Search for policies</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="SEARCH_MODE === false">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="refreshPolicies()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="(
                        (
                            isCentralUnderwriterAdminUser ||
                            isPolicyAdministrator
                        ) &&
                        selectedPolicy !== null &&
                        selectedPolicy.status === POLICY_PROSPECT_STATUSES.DRAFT
                    )">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="red white--text"
                                small
                                v-on="on"
                                @click="deleteDraftedPolicy()"
                            >
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                        <span>Delete Drafted Policy</span>
                    </v-tooltip>
                    <v-tooltip
                        bottom
                        v-if="(
                            selectedPolicy !== null &&
                            (
                                selectedPolicy.status === POLICY_PROSPECT_STATUSES.DRAFT
                            )
                        )">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary amber--text"
                                small
                                v-on="on"
                                @click="editTopLevelPolicyDetails()"
                            >
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>Edit Product and/or scheme</span>
                    </v-tooltip>
                </v-col>

                <!-- Search Result Indicator -->
                <v-col sm="3">
                    <v-card-actions v-if="SEARCH_MODE === true">
                        <h3>Search Results</h3>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="clearSearchResults()"
                                    v-on="on"
                                >
                                    <v-icon small>search</v-icon>
                                    <v-icon small>close</v-icon>
                                </v-btn>
                            </template>
                            <span>Clear Search Results</span>
                        </v-tooltip>
                    </v-card-actions>
                </v-col>

                <!-- Action Buttons -->
                <v-col sm="4" style="margin-top:10px">
                    <!-- <v-spacer></v-spacer> -->
                    <div class="text-right">
                        <!-- Capture Client Signature -->
                        <v-tooltip
                            bottom
                            v-if="(
                                selectedPolicy !== null &&
                                selectedPolicy.status === POLICY_PROSPECT_STATUSES.DRAFT
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="red white--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="openCaptureSignatureDialog()"
                                >
                                    <v-icon medium>mdi-fountain-pen-tip</v-icon>
                                </v-btn>
                            </template>
                            <span>Capture Client Signature</span>
                        </v-tooltip>

                        <!-- GENERATE POLICY APPLICATION FORM -->
                        <v-tooltip
                            bottom
                            v-if="(
                                selectedPolicy !== null &&
                                selectedPolicy.status === POLICY_PROSPECT_STATUSES.DRAFT
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="primary white--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="openViewPolicyCertificateDialog(selectedPolicy._id)"
                                >
                                    <!-- <v-icon medium>mdi-bookmark-check</v-icon> -->
                                    <v-icon medium>mdi-file-document</v-icon>
                                </v-btn>
                            </template>
                            <span>Generate Application Form</span>
                        </v-tooltip>
                        
                        <!-------------------------------------------------------------------------------->
                        <!----------------------------------LIFE CYCLE BUTTONS---------------------------->
                        <!-------------------------------------------------------------------------------->


                        <!-- VALIDATE DRAFTED POLICY -->
                        <v-tooltip bottom v-if="selectedPolicy !== null && selectedPolicy.status === POLICY_PROSPECT_STATUSES.DRAFT">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="white success--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="openValidateDraftedPolicyDialog()"
                                >
                                    <!-- <v-icon medium>check</v-icon> -->
                                    <v-icon medium>mdi-check-decagram</v-icon>
                                </v-btn>
                            </template>
                            <span>Validate Drafted Policy</span>
                        </v-tooltip>

                        <!-- APPROVE PENDING POLICY -->
                        <v-tooltip
                            v-if="(
                                (
                                    isCentralUnderwriterAdminUser ||
                                    isPolicyAdministrator
                                ) &&
                                selectedPolicy !== null &&
                                selectedPolicy.status === POLICY_PROSPECT_STATUSES.PENDING_APPROVAL
                            )"
                            bottom
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="primary white--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="openApproveDraftedPolicyDialog()"
                                >
                                    <!-- <v-icon medium>check</v-icon> -->
                                    <v-icon medium>mdi-check-decagram</v-icon> Submit Underwriter Approval
                                </v-btn>
                            </template>
                            <span>Sumbit for Underwriter Approval</span>
                        </v-tooltip>

                        <v-tooltip
                            v-if="(
                                (
                                    isCentralUnderwriterAdminUser ||
                                    isPolicyAdministrator
                                ) &&
                                selectedPolicy !== null &&
                                selectedPolicy.status === POLICY_PROSPECT_STATUSES.AWAITING_UNDERWRITER_APPROVAL
                            )"
                            bottom
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="green white--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="openUnderwriterApprovalDialog()"
                                >
                                    <!-- <v-icon medium>check</v-icon> -->
                                    <v-icon medium>mdi-check</v-icon> Underwriter Approved
                                </v-btn>
                            </template>
                            <span>Underwriter Approved</span>
                        </v-tooltip>

                        <v-tooltip
                            v-if="(
                                (
                                    isCentralUnderwriterAdminUser ||
                                    isPolicyAdministrator
                                ) &&
                                selectedPolicy !== null &&
                                selectedPolicy.status === POLICY_PROSPECT_STATUSES.AWAITING_UNDERWRITER_APPROVAL
                            )"
                            bottom
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="red white--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="tobeDefined()"
                                >
                                    <!-- <v-icon medium>check</v-icon> -->
                                    <v-icon medium>mdi-close</v-icon> Underwriter Declined
                                </v-btn>
                            </template>
                            <span>Underwriter Declined</span>
                        </v-tooltip>

                        <!-- ADD NEW POLICY -->
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="openAddPolicyDialog()"
                                    v-on="on"
                                >
                                    <v-icon medium>add</v-icon>
                                    <!-- <v-icon medium>policy</v-icon> -->
                                </v-btn>
                            </template>
                            <span>Onboard a Policy</span>
                        </v-tooltip>
                    </div>
                </v-col>
            </v-row>
        </v-toolbar>
        <multipane class="horizontal-panes" layout="horizontal" style="height: 100%" @paneResize="paneResizing">
            <div class="pane" :style="{ height: '50%', maxHeight: '50%'}">
                <v-data-table
                    fixed-header
                    :height="main_tab_height"
                    :headers="policy_details_headers"
                    :items="policies"
                    item-key="prospect_number"
                    class="elevation-1"
                    :single-select="true"
                    @click:row="selectMainTableRow"
                    item-class="secondary"
                    hide-default-footer
                    :loading="data_table_loading"
                    :disable-pagination="true"
                >
                    <!-- Display Formated capture date -->
                    <template v-slot:[`item.created`]="{ item }">
                        <td>{{item.created.split('T')[0]}}</td>
                    </template>
                    <!-- Display Formated Principal Member -->
                    <template v-slot:[`item.principal_member`]="{ item }">
                        <td>
                            {{
                                item.principal_member.entity.name !== null && item.principal_member.entity.surname !== null ? 
                                item.principal_member.entity.name + ' ' + item.principal_member.entity.surname + ' (' + item.principal_member.entity.id_number + ')': 'NONE'
                            }}
                        </td>
                    </template>
                    <!-- Product premium -->
                    <template v-slot:[`item.premium`]="{ item }" >
                        <td>R {{ formatNumber(item.premium) }}</td>
                    </template>
                    <!-- Total Cover Amount -->
                    <template v-slot:[`item.total_cover_amount`]="{ item }" >
                        <td>R {{ formatNumber(item.total_cover_amount) }}</td>
                    </template>
                    <!-- Display Formated agent -->
                    <template v-slot:[`item.agent`]="{ item }">
                        <td>
                            {{
                                item.agent !== null && item.agent !== undefined ? 
                                item.agent.entity.name + ' ' + item.agent.entity.name + ' (' + item.agent.agent_number + ')': ''
                            }}
                        </td>
                    </template>
                    <!-- User who captured the details -->
                    <template v-slot:[`item.captured_by`]="{ item }" >
                        <td v-if="item.captured_by !== null">
                            {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                        </td>
                    </template>
                </v-data-table>
            </div>
            <multipane-resizer></multipane-resizer>
            <div class="pane tab-nav" :style="{ height: '30%', maxHeight: '50%', minHeight: '30%' }">
                <v-tabs
                    v-model="sub_tabs"
                    color="transparent"
                    class="tab-nav"
                    hide-slider
                    height="35px"
                    grow
                >
                    <!-- Principal Member Sub Tab -->
                    <template>
                        <v-tab key="principal_member_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Principal Member
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Premium Payer Sub Tab -->
                    <template>
                        <v-tab key="premium_payer_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Premium Payer
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Members Sub Tab -->
                    <template>
                        <v-tab key="policy_members_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Dependents
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Signed Forms Sub Tab -->
                    <template>
                        <v-tab key="documents_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Signed Forms
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>
                </v-tabs>
                <v-tabs-items v-model="sub_tabs">
                    <!-- Sub-Tab Toolbar -->
                    <v-toolbar
                        color="secondary"
                        dark
                        class="pa-0"
                        height="50"
                        extension-height="3"
                    >
                        <!-- Show following buttons only for the policy-members sub-tab -->

                        <!-- Buttons for the Principal Member sub-tab -->
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 0 && selectedPolicy !== null &&
                                selectedPolicy.status === POLICY_PROSPECT_STATUSES.DRAFT
                            )"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="amber black--text"
                                    small
                                    v-on="on"
                                    @click="editPrincipalMemberDetails()"
                                >
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <span>Update Principal Member Information</span>
                        </v-tooltip>

                        <!-- Buttons for the Premium Payer sub-tab -->
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 1 && selectedPolicy !== null &&
                                selectedPolicy.status === POLICY_PROSPECT_STATUSES.DRAFT
                            )"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="amber black--text"
                                    small
                                    v-on="on"
                                    @click="editPremiumPayerDetails()"
                                >
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <span>Update Premium Payer Information</span>
                        </v-tooltip>

                        <!-- Buttons for the Dependent sub-tab -->
                        <v-tooltip
                            v-if="(
                                sub_tabs === 2 &&
                                selectedPolicy !== null &&
                                selectedPolicyMember !== null &&
                                selectedPolicy.status === POLICY_PROSPECT_STATUSES.DRAFT
                            )"
                            bottom
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    class="mx-2"
                                    color="amber black--text"
                                    small
                                    v-on="on"
                                    @click="editDependentDetails()"
                                >
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <span>Update Selected Dependent</span>
                        </v-tooltip>
                        <v-tooltip
                            v-if="(
                                sub_tabs === 2 &&
                                selectedPolicy !== null &&
                                selectedPolicyMember !== null &&
                                selectedPolicy.status === POLICY_PROSPECT_STATUSES.DRAFT
                            )"
                            bottom
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    class="mx-2"
                                    color="red"
                                    small
                                    v-on="on"
                                    @click="removeDraftedPolicyMember()"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Remove Selected Dependent</span>
                        </v-tooltip>
                        <v-tooltip
                            v-if="(
                                sub_tabs === 2 && selectedPolicy !== null &&
                                selectedPolicy.status === POLICY_PROSPECT_STATUSES.DRAFT
                            )"
                            bottom
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    class="mx-2"
                                    color="amber black--text"
                                    small
                                    v-on="on"
                                    @click="addDependentsDetails()"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Dependent(s)</span>
                        </v-tooltip>

                        <!-- Show following buttons only for the DOCUMENTS sub-tab -->
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 3 && selectedPolicy !== null &&
                                selectedPolicy.status === POLICY_PROSPECT_STATUSES.DRAFT
                            )"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddDocumentsDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Signed Form</span>
                        </v-tooltip>
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 3  && selectedDocument !== null &&
                                selectedPolicy.status === POLICY_PROSPECT_STATUSES.DRAFT
                            )"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    v-on="on"
                                    @click="openDeletePolicyDocumentDialog()"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete Signed Form</span>
                        </v-tooltip>
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 3  && selectedDocument !== null
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    v-on="on"
                                    @click="openViewDocumentDialog()"
                                >
                                    <v-icon>content_paste</v-icon>
                                </v-btn>
                            </template>
                            <span>View Document Info</span>
                        </v-tooltip>

                        <v-spacer></v-spacer>
                    </v-toolbar>

                    <!-- Principal Member Sub-Tab Content -->
                    <v-tab-item key="principal_member_sub_tab">
                        <v-card
                            :height="sub_tab_height"
                            style="overflow-y:scroll"
                        >
                            <v-card-text v-if="selectedPolicy !== null">
                                <!-- Basic Details -->
                                <div style="background:#e6e6e6; border:1px #aaaaaa solid; padding:10px; border-radius:10px;">
                                    <h3>Basic Details</h3>
                                    <v-row>
                                        <v-col cols="12" sm="3">
                                            <v-text-field
                                                v-model="selectedPolicy.principal_member.entity.name"
                                                color="secondary"
                                                hint="Name"
                                                persistent-hint
                                                required
                                                disabled
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <v-text-field
                                                v-model="selectedPolicy.principal_member.entity.surname"
                                                color="secondary"
                                                hint="Surname"
                                                persistent-hint
                                                required
                                                disabled
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <v-text-field
                                                v-model="selectedPolicy.principal_member.entity.id_number"
                                                type="number"
                                                color="secondary"
                                                hint="ID Number"
                                                persistent-hint
                                                required
                                                disabled
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <v-text-field
                                                v-model="selectedPolicy.principal_member.phone"
                                                color="secondary"
                                                type="number"
                                                hint="Phone. e.g. 0721234567"
                                                persistent-hint
                                                required
                                                disabled
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="3">
                                            <v-text-field
                                                v-model="selectedPolicy.principal_member.email"
                                                color="secondary"
                                                hint="Email"
                                                persistent-hint
                                                required
                                                disabled
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <v-text-field
                                                v-model="selectedPolicy.principal_member.gender"
                                                color="secondary"
                                                hint="Gender"
                                                persistent-hint
                                                required
                                                disabled
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <v-text-field
                                                :value="formatNumber(selectedPolicy.principal_member.cover_amount)"
                                                color="secondary"
                                                hint="Cover Amount"
                                                prefix="R"
                                                persistent-hint
                                                required
                                                disabled
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </div>
                                <br>

                                <!-- Banking Details -->
                                <div style="background:#e6e6e6; border:1px #aaaaaa solid; padding:10px;">
                                    <h3>Settlement Banking Details</h3>
                                    <v-row>
                                        <v-col cols="12" sm="12">
                                            <v-radio-group
                                                v-model="selectedPolicy.principal_member.settlement_banking"
                                                disabled
                                            >
                                                <v-radio
                                                    v-for="bankObj in selectedPolicy.principal_member.entity.bank_account_details.filter(bank => { return bank.deleted === false })"
                                                    :key="bankObj._id"
                                                    :label="`${bankObj.bank.name} - ${bankObj.account_number}`"
                                                    :value="bankObj._id"
                                                    color="secondary"
                                                ></v-radio>
                                            </v-radio-group>
                                            <h1
                                                style="color:red"
                                                v-if="selectedPolicy.principal_member.entity.bank_account_details.filter(bank => { return bank.deleted === false }).length === 0"
                                            >
                                                No Banking Details Captured
                                            </h1>
                                        </v-col>
                                    </v-row>
                                </div>
                                <br>

                                <!-- Physical Address -->
                                <div style="background:#e6e6e6; border:1px #aaaaaa solid; padding:10px; border-radius:10px;">
                                    <h3>Physical Address</h3>
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <v-text-field
                                                v-model="selectedPolicy.principal_member.physical_address.line1"
                                                color="secondary"
                                                hint="Line 1"
                                                persistent-hint
                                                required
                                                disabled
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field
                                                v-model="selectedPolicy.principal_member.physical_address.line2"
                                                color="secondary"
                                                hint="Line 2"
                                                persistent-hint
                                                required
                                                disabled
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="2">
                                            <v-text-field
                                                v-model="selectedPolicy.principal_member.physical_address.line3"
                                                color="secondary"
                                                hint="Line 3"
                                                persistent-hint
                                                required
                                                disabled
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="2">
                                            <v-text-field
                                                v-model="selectedPolicy.principal_member.physical_address.line4"
                                                type="number"
                                                color="secondary"
                                                hint="Postal Code"
                                                persistent-hint
                                                required
                                                disabled
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </div>
                                <br>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>

                    <!-- Premium Payer Sub-Tab Content -->
                    <v-tab-item key="premium_payer_sub_tab">
                        <v-card
                            :height="sub_tab_height"
                            style="overflow-y:scroll"
                        >
                            <v-card-text v-if="selectedPolicy !== null">
                                <!-- Basic Details -->
                                <div style="background:#e6e6e6; border:1px #aaaaaa solid; padding:10px; border-radius:10px;">
                                    <h3>Basic Details</h3>
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <v-text-field
                                                v-model="selectedPolicy.premium_payer.entity.name"
                                                color="secondary"
                                                hint="Name"
                                                persistent-hint
                                                required
                                                disabled
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field
                                                v-model="selectedPolicy.premium_payer.entity.surname"
                                                color="secondary"
                                                hint="Surname"
                                                persistent-hint
                                                required
                                                disabled
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field
                                                v-model="selectedPolicy.premium_payer.entity.id_number"
                                                type="number"
                                                color="secondary"
                                                hint="ID Number"
                                                persistent-hint
                                                required
                                                disabled
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <v-text-field
                                                v-model="selectedPolicy.premium_payer.phone"
                                                color="secondary"
                                                type="number"
                                                hint="Phone. e.g. 0721234567"
                                                persistent-hint
                                                required
                                                disabled
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field
                                                v-model="selectedPolicy.premium_payer.email"
                                                color="secondary"
                                                hint="Email"
                                                persistent-hint
                                                required
                                                disabled
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field
                                                v-model="selectedPolicy.premium_payer.gender"
                                                color="secondary"
                                                hint="Gender"
                                                persistent-hint
                                                required
                                                disabled
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </div>
                                <br>

                                <!-- Banking Details -->
                                <div
                                    v-if="selectedPolicy.payment_information.premium_payer.payment_method === 'DEBIT ORDER'"
                                    style="background:#e6e6e6; border:1px #aaaaaa solid; padding:10px;"
                                >
                                    <h3>Collection Banking Details</h3>
                                    <v-row>
                                        <v-col cols="12" sm="12">
                                            <v-radio-group
                                                v-if="selectedPolicy.payment_information.premium_payer.payment_method === 'DEBIT ORDER'"
                                                v-model="selectedPolicy.premium_payer.collection_banking"
                                                disabled
                                            >
                                                <v-radio
                                                    v-for="bankObj in selectedPolicy.premium_payer.entity.bank_account_details.filter(bank => { return bank.deleted === false })"
                                                    :key="bankObj._id"
                                                    :label="`${bankObj.bank.name} - ${bankObj.account_number}`"
                                                    :value="bankObj._id"
                                                    color="secondary"
                                                ></v-radio>
                                            </v-radio-group>
                                            <h1
                                                style="color:red"
                                                v-if="selectedPolicy.premium_payer.entity.bank_account_details.filter(bank => { return bank.deleted === false }).length === 0"
                                            >
                                                No Banking Details Captured
                                            </h1>
                                        </v-col>
                                    </v-row>
                                </div>
                                <br>

                                <!-- Physical Address -->
                                <div style="background:#e6e6e6; border:1px #aaaaaa solid; padding:10px; border-radius:10px;">
                                    <h3>Physical Address</h3>
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <v-text-field
                                                v-model="selectedPolicy.premium_payer.physical_address.line1"
                                                color="secondary"
                                                hint="Line 1"
                                                persistent-hint
                                                required
                                                disabled
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field
                                                v-model="selectedPolicy.premium_payer.physical_address.line2"
                                                color="secondary"
                                                hint="Line 2"
                                                persistent-hint
                                                required
                                                disabled
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="2">
                                            <v-text-field
                                                v-model="selectedPolicy.premium_payer.physical_address.line3"
                                                color="secondary"
                                                hint="Line 3"
                                                persistent-hint
                                                required
                                                disabled
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="2">
                                            <v-text-field
                                                v-model="selectedPolicy.premium_payer.physical_address.line4"
                                                type="number"
                                                color="secondary"
                                                hint="Postal Code"
                                                persistent-hint
                                                required
                                                disabled
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </div>
                                <br>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>

                    <!-- Policy Members Sub-Tab Content -->
                    <v-tab-item key="policy_members_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="policy_member_details_headers"
                            :items="policy_members"
                            item-key="_id"
                            class="elevation-1"
                            :single-select="true"
                            @click:row="selectPolicyMemberTabRow"
                            item-class="secondary"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Principal to spouse -->
                            <template v-slot:[`item.spouse_to_principal`]="{ item }">
                                <td v-if="item.spouse_to_principal === true"><v-icon color="success">mdi-check</v-icon></td>
                                <td v-else><v-icon color="red">mdi-close</v-icon></td>
                            </template>
                            <!-- Elected dependent -->
                            <template v-slot:[`item.elected_dependent`]="{ item }">
                                <td v-if="item.elected_dependent === true"><v-icon color="success">mdi-check</v-icon></td>
                                <td v-else><v-icon color="red">mdi-close</v-icon></td>
                            </template>
                            <!-- Cover Amount -->
                            <template v-slot:[`item.cover_amount`]="{ item }" >
                                <td>R {{ formatNumber(item.cover_amount) }}</td>
                            </template>
                        </v-data-table>
                    </v-tab-item>

                    <!-- Payment Details Sub-Tab Content -->
                    <v-tab-item key="documents_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="document_details_headers"
                            :items="policy_documents"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectDocumentsTabRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Display create date -->
                            <template v-slot:[`item.created`]="{ item }">
                                <td>{{item.created.split('T')[0]}}</td>
                            </template>
                            <!-- User who captured the details -->
                            <template v-slot:[`item.captured_by`]="{ item }" >
                                <td v-if="item.captured_by !== null">
                                    {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </multipane>
        <AddPolicyDialog
            :dialog="open_add_policy_dialog"
        />
        <EditPolicyDetailsDialog
            v-if="selectedPolicy !== null"
            :dialog="open_edit_policy_details_dialog"
            :policy_original="selectedPolicy"
            :policy_copy="selectedPolicyCopy"
        />
        <EditPrincipalMemberDialog
            v-if="selectedPolicy !== null"
            :dialog="open_edit_principal_member_dialog"
            :policy_original="selectedPolicy"
            :policy_copy="selectedPolicyCopy"
        />
        <EditPremiumPayerDialog
            v-if="selectedPolicy !== null"
            :dialog="open_edit_premium_payer_dialog"
            :policy_original="selectedPolicy"
            :policy_copy="selectedPolicyCopy"
        />
        <EditDependentDialog
            v-if="selectedPolicy !== null && selectedPolicyMember !== null"
            :policy_id="selectedPolicy._id"
            :dialog="open_edit_dependent_dialog"
            :dependent_original="selectedPolicyMember"
            :dependent_copy="selectedPolicyMemberCopy"
            :product="selectedPolicy.product"
        />
        <AddDependentsDialog
            v-if="selectedPolicy !== null"
            :dialog="open_add_dependents_dialog"
            :policy_id="selectedPolicy._id"
            :product="selectedPolicy.product"
        />
        <CaptureSignatureDialog
            v-if="selectedPolicy !== null"
            :dialog="open_capture_signature_dialog"
            :policy="selectedPolicyCopy"
        />
        <SearchMembersDialog
            :dialog="open_search_policies_dialog"
        />
        <DeleteDraftedPolicyDialog
            v-if="selectedPolicy !== null"
            :dialog="open_delete_drafted_policy_dialog"
            :policy="selectedPolicy"
        />

        <!-- DOCUMENT DIALOGS -->
        <AddPolicyDocumentDialog
            v-if="selectedPolicy !== null"
            :dialog="open_add_document_dialog"
            :policy="selectedPolicy"
        />
        <ViewDocumentDialog
            v-if="selectedDocument !== null"
            :dialog="open_view_policy_document_dialog"
            :document="selectedDocument"
            param_path="prospect_policy"
            :param_id="selectedPolicy._id"
        />
        <ViewPrincipalDocumentDialog
            v-if="selectedPolicy !== null"
            :dialog="open_view_principal_member_document_dialog"
            :document="selectedPolicy.principal_member.id_doc"
            param_path="entity"
            :param_id="selectedPolicy.principal_member.entity._id"
        />
        <DeletePolicyDocumentDialog
            v-if="selectedDocument !== null"
            :dialog="open_delete_policy_document_dialog"
            :policy_original="selectedPolicy"
            :policy_copy="selectedPolicyCopy"
            :document="selectedDocument"
        />
        <DocPreviewDialog
            :dialog="open_policy_certificate_preview_dialog"
            :url="policy_certificate_url"
            :doc_type="policy_certificate_doc_type"
            @CLOSE_DOC_PREVIEW_DIALOG="closePolicyCertificatePreviewDialog()"
        />

        <!-- Life Cycle Management -->
        <ValidateDraftedPolicyDialog
            v-if="selectedPolicy !== null"
            :dialog="open_validate_drafted_policy_dialog"
            :policy="selectedPolicy"
        />
        <ApproveDraftedPolicyDialog
            v-if="selectedPolicy !== null"
            :dialog="open_approve_drafted_policy_dialog"
            :policy="selectedPolicy"
        />
        <UnderwriterApprovalDialog
            v-if="selectedPolicy !== null"
            :dialog="open_underwriter_approval_dialog"
            :policy="selectedPolicy"
        />
    </div>
</template>
<script>
import { Multipane, MultipaneResizer } from 'vue-multipane';
import AddPolicyDialog from './dialogs/add_prospect_policy';
import EditPolicyDetailsDialog from './dialogs/edit_policy_details';
import SearchMembersDialog from './dialogs/search_policies';
import DeleteDraftedPolicyDialog from './dialogs/delete_drafted_policy';
import DocPreviewDialog from '../../../../components/doc_preview';

import AddPolicyDocumentDialog from './dialogs/add_policy_documents';
import ViewDocumentDialog from '../../../../components/common_dialogs/view_document_info';
import ViewPrincipalDocumentDialog from '../../../../components/common_dialogs/view_document_info';
import DeletePolicyDocumentDialog from './dialogs/delete_policy_document';

// Edit Dialogs
import EditPrincipalMemberDialog from './dialogs/edit_principal_member.vue';
import EditPremiumPayerDialog from './dialogs/edit_premium_payer.vue';
import EditDependentDialog from './dialogs/edit_dependent.vue';
import AddDependentsDialog from './dialogs/add_dependents.vue';

import CaptureSignatureDialog from './dialogs/capture_signature.vue';

// Life cycle managers
import ValidateDraftedPolicyDialog from './dialogs/life_cycle/draft/validate_drafted_policy';
import ApproveDraftedPolicyDialog from './dialogs/life_cycle/pending/approve_pending_policy';
import UnderwriterApprovalDialog from './dialogs/life_cycle/pending/underwriter_approval';

import { eventBus } from "../../../../main";
import { emitAlert, emitSuccess, generatePolicyApplicationForm } from "../../../../utils/api";
import { POLICY_PROSPECT_STATUSES, payment_methods, BUSINESS_PARTNER_TYPES } from "../../../../../config";

import converter from "../../../../mixins/converter";

import { store } from "../../../../../store";
import { getPolicyProspects } from "./gql/queries";
import { businessProcessProspectPolicy } from "./gql/mutations";

export default {
    mixins: [converter],
    components: {
        Multipane,
        MultipaneResizer,
        AddPolicyDialog,
        EditPolicyDetailsDialog,
        SearchMembersDialog,
        DeleteDraftedPolicyDialog,
        DocPreviewDialog,

        AddPolicyDocumentDialog,
        ViewDocumentDialog,
        ViewPrincipalDocumentDialog,
        DeletePolicyDocumentDialog,

        EditPrincipalMemberDialog,
        EditPremiumPayerDialog,
        EditDependentDialog,
        AddDependentsDialog,

        CaptureSignatureDialog,

        // Policy Life Cycle Dialogs
        ValidateDraftedPolicyDialog,
        ApproveDraftedPolicyDialog,
        UnderwriterApprovalDialog,
    },

    computed: {
        isCentralUnderwriterAdminUser() {
            const result = store.state.currentUserAccount.roles.filter(role => {
                                                            return (
                                                                role.role.level.includes(BUSINESS_PARTNER_TYPES.CENTRAL_UNDERWRITER) &&
                                                                role.role.name.toLowerCase() ===  'admin - central underwriter'
                                                            );
                                                        }).length > 0;

            return result;
        },
        isPolicyAdministrator() {
            const result = store.state.currentUserAccount.roles.filter(role => {
                                                            return (
                                                                role.role.level.includes(BUSINESS_PARTNER_TYPES.CENTRAL_UNDERWRITER) &&
                                                                role.role.name.toLowerCase() ===  'policy administration'
                                                            );
                                                        }).length > 0;

            return result;
        }
    },

    data: () => ({
        sub_tabs: null,
        main_tab_height: '250px',
        sub_tab_height:'180',

        // main data-table row
        selectedPolicy: null,
        selectedRow: null,

        // Policy members sub-tab data-table row
        selectedPolicyMember: null,
        selectedPolicyMemberRow: null,

        selectedPolicyMemberCopy: null,

        // documents sub-tab data-table row
        selectedDocument: null,
        selectedDocumentRow: null,

        policy_details_headers: [
            { text: 'Prospect Number', value: 'prospect_number', width: '5%' },
            // { text: 'Date Captured', value: 'created' },
            { text: 'Payment Method', value: 'payment_information.premium_payer.payment_method' },
            { text: 'Principal Member', value: 'principal_member', width: '15%' },
            { text: 'Payment Date', value: 'payment_date', width: '5%' },
            { text: 'Product', value: 'product.name' },
            { text: 'Premium', value: 'premium' },
            // { text: 'Scheme/Group', value: 'scheme.name' },
            // { text: 'Scheme Payment', value: 'scheme_level_payment' },
            { text: 'Agent', value: 'agent', width: '15%' },
            { text: 'Policy Status', value: 'status' },
            { text: 'Total Cover', value: 'total_cover_amount' },
            // { text: 'Commission Status', value: 'commission_status' },
            { text: 'Created', value: 'created', width: '7%' },
            { text: 'Created By', value: 'captured_by' },
        ],
        policy_member_details_headers: [
            { text: 'Name', value: 'entity.name' },
            { text: 'Surname', value: 'entity.surname' },
            { text: 'ID Number', value: 'entity.id_number' },
            { text: 'Gender', value: 'gender' },
            { text: 'Spouse To Principal', value: 'spouse_to_principal' },
            { text: 'Elected Dependent', value: 'elected_dependent' },
            { text: 'Cover Amount', value: 'cover_amount' },
        ],
        document_details_headers: [
            { text: 'Document Type', value: 'type' },
            { text: 'Name', value: 'name' },
            { text: 'Date Captured', value: 'created' },
            { text: 'Captured By', value: 'captured_by' },
        ],

        policy_certificate_doc_type: 'pdf',
        policy_certificate_url: null,
        
        // Table data models
        policy_members: [],
        policy_documents: [],

        // Dialogs
        
        policies: [],
        open_add_policy_dialog: false,
        open_edit_policy_details_dialog: false,
        open_search_policies_dialog: false,
        open_add_document_dialog: false,
        open_view_policy_document_dialog: false,
        open_delete_policy_document_dialog: false,
        open_delete_drafted_policy_dialog: false,
        open_policy_certificate_preview_dialog: false,
        open_edit_principal_member_dialog: false,
        open_edit_premium_payer_dialog: false,
        open_edit_dependent_dialog: false,
        open_add_dependents_dialog: false,
        open_capture_signature_dialog: false,

        open_view_principal_member_document_dialog: false,

        // Life cycle management
        open_validate_drafted_policy_dialog: false,
        open_approve_drafted_policy_dialog: false,
        open_underwriter_approval_dialog: false,

        data_table_loading: false,
        SEARCH_MODE: false,
        page: 1,
        limit: 50,

        POLICY_PROSPECT_STATUSES: POLICY_PROSPECT_STATUSES,
        
        selectedPolicyCopy: null,

        payment_methods: payment_methods,
    }),

    methods: {
        //-------------------- OPEN DIALOGS ---------------------//
        openAddPolicyDialog() {
            this.open_add_policy_dialog = true;
        },
        editTopLevelPolicyDetails() {
            this.open_edit_policy_details_dialog = true;
        },
        editPrincipalMemberDetails() {
            this.open_edit_principal_member_dialog = true;
        },
        editPremiumPayerDetails() {
            this.open_edit_premium_payer_dialog = true;
        },
        editDependentDetails() {
            this.open_edit_dependent_dialog = true;
        },
        addDependentsDetails() {
            this.open_add_dependents_dialog = true;
        },
        openCaptureSignatureDialog() {
            this.open_capture_signature_dialog = true;
        },
        openSearchPolicyDialog() {
            this.unselectSelectedRow();
            this.open_search_policies_dialog = true;
        },
        deleteDraftedPolicy() {
            this.open_delete_drafted_policy_dialog = true;
        },
        openAddDocumentsDialog() {
            this.open_add_document_dialog = true;
        },
        openViewDocumentDialog() {
            this.open_view_policy_document_dialog = true;
        },
        openDeletePolicyDocumentDialog() {
            this.open_delete_policy_document_dialog = true;
        },
        openViewPolicyCertificateDialog(policy_id) {
            generatePolicyApplicationForm(policy_id).then(res => {
                const url = window.URL.createObjectURL(new Blob([res], {type: 'application/pdf'}));
                this.policy_certificate_url =  url;
                
                this.open_policy_certificate_preview_dialog = true;
            });
        },

        openViewPrincipalMemberDocumentDialog() {
            this.open_view_principal_member_document_dialog = true;
        },

        // eslint-disable-next-line no-unused-vars
        resetPolicyEditForm(updatedPolicy) {
            this.selectedPolicyCopy = JSON.parse( JSON.stringify(this.selectedPolicy) );
        },
        // eslint-disable-next-line no-unused-vars
        resetDependentEditForm(updatedPolicy) {
            this.selectedPolicyMemberCopy = JSON.parse( JSON.stringify(this.selectedPolicyMember) );
        },

        //------------------------------------------------------------------------------------//
        //-------------------------------- LIFE CYCLE MANAGEMENT -----------------------------//
        //------------------------------------------------------------------------------------//

        // Draft
        openValidateDraftedPolicyDialog() {
            this.open_validate_drafted_policy_dialog = true;
        },
        openApproveDraftedPolicyDialog() {
            this.open_approve_drafted_policy_dialog = true;
        },
        openUnderwriterApprovalDialog() {
            this.open_underwriter_approval_dialog = true;
        },

        //------------------- CLOSE DIALOGS---------------------//
        updateUITableModel(new_policy) {
            if (new_policy !== null) {
                const obj_index = this.policies.map(obj => { return obj._id; }).indexOf(new_policy._id)
                this.policies[obj_index] = new_policy;

                this.selectedPolicy = new_policy;
                this.selectedPolicyCopy = JSON.parse( JSON.stringify(new_policy) );

                 // Deep copy policies for refreshing main data table
                const temp_arr = Array.from(this.policies);
                this.policies = temp_arr;

                // Also update the documents tab
                this.policy_documents = new_policy.documents.filter(doc => { return !doc.deleted });
                this.policy_members = new_policy.dependents;

                // populate convenience fields
                this.populateSelectedPolicyConvenienceFields();

                // Sanitize dependent fields
                new_policy.dependents.forEach(dep => {
                    dep.member = dep.entity;
                    dep.member.display_value = `${dep.entity.name} ${dep.entity.surname} (${dep.entity.id_number})`;

                    dep.name = dep.entity.name;
                    dep.surname = dep.entity.surname;
                    dep.id_number = dep.entity.id_number;
                });
            }
        },
        closeAddPolicyDialog(new_policy) {
            if (new_policy !== null) {
                this.policies.unshift(new_policy);
            }
            this.open_add_policy_dialog = false;
        },
        closeEditPolicyDetailsDialog(new_policy) {
            this.updateUITableModel(new_policy);
            this.open_edit_policy_details_dialog = false;
        },
        closeEditPrincipalMemberDialog(new_policy) {
            this.updateUITableModel(new_policy);
            this.open_edit_principal_member_dialog = false;
        },
        closeEditPremiumPayerDialog(new_policy) {
            this.updateUITableModel(new_policy);
            this.open_edit_premium_payer_dialog = false;
        },
        closeEditDependentDialog(new_policy) {
            this.updateUITableModel(new_policy);

            if (new_policy !== null) {
                this.unselectSubTabRows();
            }

            this.open_edit_dependent_dialog = false;
        },
        closeAddDependentsDialog(new_policy) {
            this.updateUITableModel(new_policy);
            this.open_add_dependents_dialog = false;
        },
        closeCaptureSignatureDialog(new_policy) {
            this.updateUITableModel(new_policy);
            this.open_capture_signature_dialog = false;
        },
        closeAddPolicyDocumentsDialog(new_policy) {
            this.updateUITableModel(new_policy);
            this.open_add_document_dialog = false;
        },
        openViewServerDocumentDialog() {
            this.open_view_policy_document_dialog = false;
            this.open_view_principal_member_document_dialog = false;
        },
        closeDeletePolicyDocumentDialog(new_policy) {
            if (new_policy !== null) {
                // Then a delete action actually happened
                this.selectedDocument = null;
                this.selectedDocumentRow = null;
            }
            this.updateUITableModel(new_policy);
            this.open_delete_policy_document_dialog = false;
        },
        closeSearchPoliciesDialog(searchResults) {
            if (searchResults !== null) {
                this.policies = searchResults;
                this.SEARCH_MODE = true;

                // Update member policy-members model
                this.policy_members = [];
                // Update member documents model
                this.policy_documents = [];
            }
            this.open_search_policies_dialog = false;
        },
        closeDeleteDraftedPolicyDialog(canceled) {
            if (canceled === false) {
                this.selectedPolicy = null;
                this.selectedPolicyMemberRow = null;

                this.selectedDocument = null;
                this.selectedDocumentRow = null;

                // Also update the documents tab
                this.policy_documents = [];

                this.policy_members = [];

                this.refreshPolicies();
            }
            
            this.open_delete_drafted_policy_dialog = false;
        },
        closeValidateDraftedPolicyDialog(new_policy) {
            this.updateUITableModel(new_policy);
            this.open_validate_drafted_policy_dialog = false;
        },
        closeApproveDraftedPolicyDialog(respoObj) {
            if (respoObj.cancelled === false) {
                const new_policy = respoObj.policy;
                this.updateUITableModel(new_policy);
            }
            this.open_approve_drafted_policy_dialog = false;
        },
        closeUnderwriterApprovalDialog(respoObj) {
            if (respoObj.cancelled === false) {
                const new_policy = respoObj.policy;
                this.updateUITableModel(new_policy);
            }
            this.open_underwriter_approval_dialog = false;
        },
        closePolicyCertificatePreviewDialog() {
            this.policy_certificate_url = null;
            this.open_policy_certificate_preview_dialog = false;
        },

        
        // Row Selector Control
        unselectSubTabRows() {
            // Unselect policy-members sub-tab row
            this.selectedPolicyMember = null;
            if (this.selectedPolicyMemberRow !== null) {
                this.selectedPolicyMemberRow.select(false);
            }

            // Unselect documents sub-tab row
            this.selectedDocument = null;
            if (this.selectedDocumentRow !== null) {
                this.selectedDocumentRow.select(false);
            }
        },

        selectMainTableRow(item, item_metadata) {
            this.unselectSubTabRows();

            this.selectedPolicyCopy = JSON.parse( JSON.stringify(item) );

            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                this.selectedRow = item_metadata;

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                this.selectedPolicy = item;
                // Update policy members model
                this.policy_members = item.dependents;
                // Update member documents model
                this.policy_documents = item.documents.filter(doc => { return !doc.deleted });
            } else {
                this.selectedPolicy = null;
                // Unighlight selected row
                item_metadata.select(false);
                this.selectedRow = null;

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                // Update member policy-members model
                this.policy_members = [];
                // Update member documents model
                this.policy_documents = [];
            }

            this.populateSelectedPolicyConvenienceFields();            
        },
        populateSelectedPolicyConvenienceFields() {
            // Populate convenience fields
            if (this.selectedPolicy !== null) {
                // ---------------------------------------------------
                // ----------- PRINCIPAL CONTACT DETAILS -------------
                // ---------------------------------------------------
                this.selectedPolicy.principal_member.member = null;

                const principalPhone = this.selectedPolicy.principal_member.entity.contact_details.filter(cont => {
                    return (
                        cont.type === 'Mobile' &&
                        cont.deleted === false &&
                        cont.primary === true
                    );
                })[0];
                const principalEmail = this.selectedPolicy.principal_member.entity.contact_details.filter(cont => {
                    return (
                        cont.type === 'Email' &&
                        cont.deleted === false
                    );
                })[0];

                const principalAddress = this.selectedPolicy.principal_member.entity.addresses.filter(addr => {
                    return (
                        (
                            addr.type === 'Residential' ||
                            addr.type === 'Postal + Residential'
                        ) &&
                        addr.deleted === false
                    );
                })[0];

                this.selectedPolicy.principal_member.physical_address = {};
                if (principalAddress !== undefined) {
                    this.selectedPolicy.principal_member.physical_address.line1 = principalAddress.line1;
                    this.selectedPolicy.principal_member.physical_address.line2 = principalAddress.line2;
                    this.selectedPolicy.principal_member.physical_address.line3 = principalAddress.line3;
                    this.selectedPolicy.principal_member.physical_address.line4 = principalAddress.line4;
                } else {
                    this.selectedPolicy.principal_member.physical_address.line1 = '';
                    this.selectedPolicy.principal_member.physical_address.line2 = '';
                    this.selectedPolicy.principal_member.physical_address.line3 = '';
                    this.selectedPolicy.principal_member.physical_address.line4 = '';
                }

                this.selectedPolicy.principal_member.phone = principalPhone === undefined ? '' : principalPhone.details;
                this.selectedPolicy.principal_member.email = principalEmail === undefined ? '' : principalEmail.details;

                // Sanitize phone and email
                if (this.selectedPolicy.principal_member.phone !== null) {
                    this.selectedPolicy.principal_member.phone.replace(/\s/g, ''); // remove all spaces
                }
                if (this.selectedPolicy.principal_member.email !== null) {
                    this.selectedPolicy.principal_member.email.replace(/\s/g, ''); // remove all spaces
                }


                // ---------------------------------------------------
                // ------------ PRINCIPAL MEMBER ID DOC --------------
                // ---------------------------------------------------
                const principalMemberIDDoc = this.selectedPolicy.principal_member.entity.documents.filter(doc => {
                    return (
                        doc.type === 'ID Document' &&
                        doc.deleted === false
                    );
                })[0];

                this.selectedPolicy.principal_member.id_doc = principalMemberIDDoc === undefined ? null : principalMemberIDDoc;

                // ---------------------------------------------------------------------------------------------------------
                // ---------------------------------------------------
                // ----------- PREM PAYER CONTACT DETAILS ------------
                // ---------------------------------------------------
                this.selectedPolicy.premium_payer.member = null;

                const premPayerPhone = this.selectedPolicy.premium_payer.entity.contact_details.filter(cont => {
                    return (
                        cont.type === 'Mobile' &&
                        cont.deleted === false &&
                        cont.primary === true
                    );
                })[0];
                const premPayerEmail = this.selectedPolicy.premium_payer.entity.contact_details.filter(cont => {
                    return (
                        cont.type === 'Email' &&
                        cont.deleted === false
                    );
                })[0];

                const premPayerAddress = this.selectedPolicy.premium_payer.entity.addresses.filter(addr => {
                    return (
                        (
                            addr.type === 'Residential' ||
                            addr.type === 'Postal + Residential'
                        ) &&
                        addr.deleted === false
                    );
                })[0];

                this.selectedPolicy.premium_payer.physical_address = {};
                if (premPayerAddress !== undefined) {
                    this.selectedPolicy.premium_payer.physical_address.line1 = premPayerAddress.line1;
                    this.selectedPolicy.premium_payer.physical_address.line2 = premPayerAddress.line2;
                    this.selectedPolicy.premium_payer.physical_address.line3 = premPayerAddress.line3;
                    this.selectedPolicy.premium_payer.physical_address.line4 = premPayerAddress.line4;
                } else {
                    this.selectedPolicy.premium_payer.physical_address.line1 = '';
                    this.selectedPolicy.premium_payer.physical_address.line2 = '';
                    this.selectedPolicy.premium_payer.physical_address.line3 = '';
                    this.selectedPolicy.premium_payer.physical_address.line4 = '';
                }

                this.selectedPolicy.premium_payer.phone = premPayerPhone === undefined ? '' : premPayerPhone.details;
                this.selectedPolicy.premium_payer.email = premPayerEmail === undefined ? '' : premPayerEmail.details;

                if (this.selectedPolicy.premium_payer.phone !== null) {
                    this.selectedPolicy.premium_payer.phone.replace(/\s/g, ''); // remove all spaces
                }
                if (this.selectedPolicy.premium_payer.email !== null) {
                    this.selectedPolicy.premium_payer.email.replace(/\s/g, ''); // remove all spaces
                }

                // Recreate policy copy
                this.selectedPolicyCopy = JSON.parse( JSON.stringify(this.selectedPolicy) );
            }
        },
        selectPolicyMemberTabRow(item, item_metadata) {
            this.selectedPolicyMemberCopy = JSON.parse( JSON.stringify(item) );

            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);

                this.selectedPolicyMember = item;
                this.selectedPolicyMemberRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedPolicyMember = null;
                this.selectedPolicyMemberRow = null;
            }
        },
        selectDocumentsTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedDocument = item;
                this.selectedDocumentRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedDocument = null;
                this.selectedDocumentRow = null;
            }
        },
        tobeDefined(){},
        clearSearchResults() {
            // Unselect a row if there was any
            this.unselectSelectedRow();

            this.SEARCH_MODE = false;
            this.refreshPolicies();
        },

        async removeDraftedPolicyMember() {
            try {
                this.data_table_loading = true;
                // const res = await deletePolicyProspectMember(this.selectedPolicy._id, this.selectedPolicyMember._id);
                // if (res.errors === true) {
                //     emitAlert(res.message);
                //     this.data_table_loading = false;
                //     return;
                // }

                const payloadObj = {
                    OP_TYPE: 'REMOVE_DEPENDENT',
                    _id: this.selectedPolicy._id,

                    dependents: [
                        {
                            _id: this.selectedPolicyMember._id,

                            // placeholder fields
                            entity: '',
                            elected_dependent: false,
                            spouse_to_principal: false,
                        }
                    ]
                };

                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcessProspectPolicy,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.businessProcessProspectPolicy.errors === true) {
                    emitAlert(res.data.businessProcessProspectPolicy.message);
                    this.submitting = false;
                    return;
                }

                emitSuccess(res.data.businessProcessProspectPolicy.message);
                this.data_table_loading = false;
                
                // ----------- UPDATE UI MODEL -------------

                this.selectedPolicyMember = null;
                this.selectedPolicyMemberRow = null;

                const responsePolicy = res.data.businessProcessProspectPolicy.business_object;
                this.policy_members = responsePolicy.dependents;

                // refresh main data table
                const obj_index = this.policies.map(obj => { return obj._id; }).indexOf(responsePolicy._id);
                this.policies[obj_index] = responsePolicy;

                // Deep copy policies for refreshing main data table
                const temp_arr = Array.from(this.policies);
                this.policies = temp_arr;
                
                this.selectedPolicy = responsePolicy;
                this.populateSelectedPolicyConvenienceFields();
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessProspectPolicy.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        unselectSelectedRow() {
            if (this.selectedRow !== null) {
                this.selectedRow.select(false);
                this.selectedRow = null;

                // Update member policy-members model
                this.policy_members = [];
                // Update member documents model
                this.policy_documents = [];
            }
            this.selectedPolicy = null;
        },

        async loadMoreData() {
            this.data_table_loading = true;
            this.page += 1;

            try {
                const res = await this.$apollo
                                    .query({
                                        query: getPolicyProspects,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'PROSPECT_POLICY',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }

                this.data_table_loading = false;
                res.data.getBusinessObject.business_objects.forEach(policy => {
                    policy.dependents.forEach(dep => {
                        dep.member = dep.entity;
                        dep.member.display_value = `${dep.entity.name} ${dep.entity.surname} (${dep.entity.id_number})`;

                        dep.name = dep.entity.name;
                        dep.surname = dep.entity.surname;
                        dep.id_number = dep.entity.id_number;
                    });
                });
                this.policies = this.policies.concat( res.data.getBusinessObject.business_objects );
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        async refreshPolicies() {
            this.data_table_loading = true;
            this.policies = [];

            this.page = 1; //, limit = 20;

            try {
                const res = await this.$apollo
                                    .query({
                                        query: getPolicyProspects,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'PROSPECT_POLICY',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }

                this.data_table_loading = false;
                res.data.getBusinessObject.business_objects.forEach(policy => {
                    policy.dependents.forEach(dep => {
                        dep.member = dep.entity;
                        dep.member.display_value = `${dep.entity.name} ${dep.entity.surname} (${dep.entity.id_number})`;

                        dep.name = dep.entity.name;
                        dep.surname = dep.entity.surname;
                        dep.id_number = dep.entity.id_number;
                    });
                });
                this.policies = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
        paneResizing(pane, container, size) {
            const size_int = parseInt(size);

            this.main_tab_height = size;
            const max_screen_height = window.innerHeight - 300;

            const sub_tab_percentage = 1 - (size_int/max_screen_height);
            this.sub_tab_height = sub_tab_percentage * max_screen_height;
        },
    },

    async mounted() {
        eventBus.$on('CLOSE_ADD_POLICY_DIALOG', this.closeAddPolicyDialog);
        eventBus.$on('CLOSE_EDIT_POLICY_DETAILS_DIALOG', this.closeEditPolicyDetailsDialog);
        eventBus.$on('CLOSE_DELETE_POLICY_DRAFTED_POLICY_DIALOG', this.closeDeleteDraftedPolicyDialog);
        eventBus.$on('CLOSE_POLICY_SEARCH_DIALOG', this.closeSearchPoliciesDialog);
        eventBus.$on('CLOSE_EDIT_PRINCIPAL_MEMBER_DIALOG', this.closeEditPrincipalMemberDialog);
        eventBus.$on('CLOSE_EDIT_PREMIUM_PAYER_DIALOG', this.closeEditPremiumPayerDialog);
        eventBus.$on('CLOSE_EDIT_DEPENDENT_DIALOG', this.closeEditDependentDialog);
        eventBus.$on('CLOSE_ADD_DEPENDENTS_DIALOG', this.closeAddDependentsDialog);
        eventBus.$on('CLOSE_CAPTURE_SIGNATURE_DIALOG', this.closeCaptureSignatureDialog);

        eventBus.$on('CLOSE_ADD_POLICY_DOCUMENTS_DIALOG', this.closeAddPolicyDocumentsDialog);
        eventBus.$on('CLOSE_DELETE_POLICY_DOCUMENT_DIALOG', this.closeDeletePolicyDocumentDialog);
        eventBus.$on('CLOSE_VIEW_DOCUMENT_DIALOG', this.openViewServerDocumentDialog);

        // Life cycle
        eventBus.$on('CLOSE_VALIDATE_POLICY_DIALOG', this.closeValidateDraftedPolicyDialog);
        eventBus.$on('CLOSE_APPROVE_POLICY_DRAFTED_POLICY_DIALOG', this.closeApproveDraftedPolicyDialog);
        eventBus.$on('CLOSE_UNDERWRITER_APPROVAL_DIALOG', this.closeUnderwriterApprovalDialog);

        // Form reseters
        eventBus.$on('RESET_POLICY_EDIT_FORM', this.resetPolicyEditForm);
        eventBus.$on('RESET_EDIT_PAYMENT_INFORMATION_FORM', this.resetPolicyEditForm);
        eventBus.$on('RESET_EDIT_PRINCIPAL_MEMBER_FORM', this.resetPolicyEditForm);
        eventBus.$on('RESET_EDIT_PREMIUM_PAYER_FORM', this.resetPolicyEditForm);
        eventBus.$on('RESET_EDIT_DEPENDENT_FORM', this.resetDependentEditForm);
        eventBus.$on('RESET_EDIT_BANKING_DETAILS_FORM', this.resetPolicyEditForm);
        eventBus.$on('RESET_EDIT_SETTLEMENT_BANKING_FORM', this.resetPolicyEditForm);


        /**
         * The following logic is for manually triggering a pane-divider movement
         * so as to get the windows to properly resize
         */
        this.paneResizing(null, null, 250);

        try {
            this.data_table_loading = true;
            const res = await this.$apollo
                                    .query({
                                        query: getPolicyProspects,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'PROSPECT_POLICY',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.data_table_loading = false;
                return;
            }

            this.data_table_loading = false;
            res.data.getBusinessObject.business_objects.forEach(policy => {
                policy.dependents.forEach(dep => {
                    dep.member = dep.entity;
                    dep.member.display_value = `${dep.entity.name} ${dep.entity.surname} (${dep.entity.id_number})`;

                    dep.name = dep.entity.name;
                    dep.surname = dep.entity.surname;
                    dep.id_number = dep.entity.id_number;
                });
            });
            this.policies = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.data_table_loading = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
        }
    },

    watch: {
        
    }
}
</script>
<style scoped>
/* Overriding of textbox min height */
 .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
}
</style>
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Associate Loan Transaction to Bank Feed</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row style="background:#eaeaea; padding-bottom:10px">
                        <!-- Bank Feed -->
                        <v-col cols="12" sm="12">
                            <BankFeedSearch
                                :item="bank_feed_search"
                                :unclassified_filter="false"
                            />
                        </v-col>
                    </v-row>
                    <v-row
                        v-if="bank_feed_search.bank_feed_item !== null"
                        style="background:#eaeaea; padding-bottom:10px"
                    >
                        <v-col cols="12" sm="12">
                            <h2>Bank Feed Details</h2>
                        </v-col>
                        <!-- Date -->
                        <v-col cols="12" sm="2">
                            <v-text-field
                                :value="bank_feed_search.bank_feed_item.date.split('T')[0]"
                                color="secondary"
                                label="Transaction Date"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <!-- Description -->
                        <v-col cols="12" sm="4">
                            <v-text-field
                                :value="bank_feed_search.bank_feed_item.description"
                                color="secondary"
                                label="Transaction Description"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <!-- Description -->
                        <v-col cols="12" sm="3">
                            <v-text-field
                                :value="bank_feed_search.bank_feed_item.reference"
                                color="secondary"
                                label="Transaction Reference"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <!-- Amount -->
                        <v-col cols="12" sm="2">
                            <v-text-field
                                :value="bank_feed_search.bank_feed_item.amount"
                                color="secondary"
                                label="Amount"
                                prefix="R"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>

                        <!-- Classified -->
                        <v-col
                            cols="12"
                            sm="1"
                            :style="bank_feed_search.bank_feed_item.classification !== null ? 'background-color: rgb(234, 121, 121);' : 'background-color: green'">
                            <v-text-field
                                :value="bank_feed_search.bank_feed_item.classification === null ? 'NO' : 'YES'"
                                color="secondary"
                                label="Classified"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <!-- Progress Section -->
                <v-progress-linear
                    color="green"
                    class="mb-0"
                    height="10"
                    indeterminate
                    :active="submitting"
                ></v-progress-linear>
                <v-list flat v-if="submitting">
                    <v-subheader>Saving Transaction</v-subheader>
                    <!-- <v-list-item-group v-model="item" color="primary"> -->
                    <v-list-item-group color="primary">
                        <v-list-item
                            v-for="(item, i) in backendActionItems"
                            :key="i"
                        >
                            <v-list-item-icon>
                                <v-icon>done</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ item }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>

                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        large
                        color="success"
                        :disabled="saveAssociationDisabled()"
                        @click="saveAssociation()"
                    >
                        <v-icon large>save</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import BankFeedSearch from "../components/bank_feed_search.vue";
import { emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";

import { createFinanceTransaction } from "../gql/mutations";

export default {
    components: {
        BankFeedSearch
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        transaction: {
            type: Object,
            required: false,
            default: () => {
                return {
                    // name,
                    documents: []
                }
            }
        },
    },
    data: () => ({
        server_resp_obj: null,
        submitting: false,

        backendActionItems: [],

        bank_feed_search: {
            bank_feed_item: null
        },
    }),
    methods: {
        closeDialog() {
            eventBus.$emit('CLOSE_LINK_TRANSACTION_BANK_FEED_DIALOG', this.server_resp_obj);
        },
        
        saveAssociationDisabled() {
            if (this.bank_feed_search.bank_feed_item === null) {
                return true;
            }
            return false;
        },
        async saveAssociation() {
            this.submitting = true;
            try {
                const transactionObj = {
                    OP_TYPE: 'ASSOCIATE_TO_BANK_FEED',

                    _id: this.transaction._id,
                    bank_feed_id: this.bank_feed_search.bank_feed_item._id,

                    from: '',
                    to: '',
                    reference: 'LOAN',
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: createFinanceTransaction,
                                                variables: {
                                                    input: transactionObj
                                                },
                                            });

                this.submitting = false;
                if (res.data.finProcessFinanceAccountTransaction.errors === true) {
                    emitAlert(res.data.finProcessFinanceAccountTransaction.message);
                    return;
                }

                emitSuccess(res.data.finProcessFinanceAccountTransaction.message);

                this.server_resp_obj = res.data.finProcessFinanceAccountTransaction.business_object;
                this.closeDialog();

                // Reset
                this.server_resp_obj = null;
                this.bank_feed_search.bank_feed_item = null;
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.finProcessFinanceAccountTransaction.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
    },
    async mounted() {},
    watch: {
        
    }
}
</script>
<style scoped>

</style>
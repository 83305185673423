<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Edit Relationship Type</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeEditRelTypeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <!-- Name -->
                        <v-col cols="12" sm="12">
                            <v-text-field
                                v-model="selected_rel_type_copy.name"
                                label="Name"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <!-- Description -->
                        <v-col cols="12" sm="12">
                            <v-text-field
                                v-model="selected_rel_type_copy.description"
                                label="Description"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <!-- Branch Code -->
                        <v-col cols="12" sm="3">
                            <v-select
                                v-model="selected_rel_type_copy.gender_map"
                                :items="entity_gender"
                                label="Gender Mapping"
                                required
                                disabled
                            ></v-select>
                        </v-col>
                        <!-- Opposite Relationship -->
                        <v-col cols="12" sm="3">
                            <v-select
                                v-model="selected_rel_type_copy.opposite_relationship"
                                :items="rel_type_list"
                                color="secondary"
                                item-text="name"
                                item-value="_id"
                                label="Opposite Relationship"
                                required
                            ></v-select>
                        </v-col>
                        <!-- Payout Category Target Role For Principal -->
                        <v-col cols="12" sm="6" >
                            <v-select
                                v-model="selected_rel_type_copy.payout_target_roles_for_principal"
                                :items="membership_categories"
                                label="Payout Target Roles For Principal"
                                hint="Optional: If main relation was of the principal member (to dependent), what payout category target role would be mapped"
                                persistent-hint
                                chips
                                multiple
                                color="secondary"
                                required
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting Relationship Type
                        <v-progress-linear
                            indeterminate
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submitEditRelTypeBtnDisabled()"
                                @click="submitEditRelType()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Submit Details</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../../utils/api";
import { eventBus } from "../../../../main";
import { entity_gender, membership_categories } from "../../../../../config";
import { lookupProcessEntityRelationshipType } from "../gql/mutations";

export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        rel_type_list: {
            type: Array,
            required: true,
            default: () => { return [] }
        },
        selected_rel_type: {
            type: Object,
            required: true,
            default: () => { 
                return {
                    name: null,
                    description: null,
                    gender_map: null,
                    opposite_relationship: null,
                    payout_target_roles_for_principal: []
                }
            }
        },
        selected_rel_type_copy: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data: () => ({
        // name: null,
        // gender_map: null,
        // opposite_relationship: null,
        server_resp_obj: null,
        submitting: false,

        entity_gender: entity_gender,
        membership_categories: membership_categories
    }),
    methods: {
        closeEditRelTypeDialog() {
            eventBus.$emit('CLOSE_EDIT_REL_TYPE_DIALOG', this.server_resp_obj);
        },
        submitEditRelTypeBtnDisabled() {
            let difference = this.selected_rel_type_copy.payout_target_roles_for_principal.filter(x => !this.selected_rel_type.payout_target_roles_for_principal.includes(x))
                                    .concat(this.selected_rel_type.payout_target_roles_for_principal.filter(x => !this.selected_rel_type_copy.payout_target_roles_for_principal.includes(x)));

            if (
                this.selected_rel_type_copy.opposite_relationship._id === null &&
                this.selected_rel_type_copy.opposite_relationship._id === this.selected_rel_type.opposite_relationship._id &&
                difference.length === 0
            ) {
                return true;
            }
            return false;
        },
        resetForm() {
            this.server_resp_obj = null;
            this.submitting = false;
            eventBus.$emit('RESET_REL_TYPE_EDIT_FORM', this.selected_rel_type);
        },
        async submitEditRelType() {
            // Validate
            if (this.selected_rel_type_copy.opposite_relationship === null) {
                emitAlert("Please fill in opposite relationship");
                return;
            }

            this.submitting = true;
            try {
                const payloadObj = {
                    OP_TYPE: 'UPDATE',
                    _id: this.selected_rel_type_copy._id,

                    // The following fields won't be edited
                    name: this.selected_rel_type_copy.name,
                    description: this.selected_rel_type_copy.description,
                    gender_map: this.selected_rel_type_copy.gender_map,

                    // The following fields will be edited
                    opposite_relationship: this.selected_rel_type_copy.opposite_relationship,
                    payout_target_roles_for_principal: this.selected_rel_type_copy.payout_target_roles_for_principal,
                };

                // const res = await editEntityRelationshipType(this.selected_rel_type_copy._id, payloadObj);
                const res = await this.$apollo
                                            .mutate({
                                                mutation: lookupProcessEntityRelationshipType,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.lookupProcessEntityRelationshipType.errors === true) {
                    emitAlert(res.data.lookupProcessEntityRelationshipType.message);
                    this.submitting = false;
                    return;
                }

                emitSuccess(res.data.lookupProcessEntityRelationshipType.message);

                this.server_resp_obj = res.data.lookupProcessEntityRelationshipType.business_object;
                this.closeEditRelTypeDialog();

                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.lookupProcessEntityRelationshipType.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        }
    },
    mounted() {
        
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"80%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-toolbar',{staticStyle:{"border":"1px solid #07ad2d"},attrs:{"dark":"","color":"secondary"}},[_c('v-toolbar-title',[_c('b',[_vm._v("Add Agent Contract")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.resetForm()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Reset Form")])]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card',[_c('v-card-text',[_c('v-row',{staticStyle:{"background":"#eaeaea","padding-bottom":"10px"}},[_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-text-field',{attrs:{"label":"Document Type","required":"","disabled":""},model:{value:(_vm.agent_details.contract_file.type),callback:function ($$v) {_vm.$set(_vm.agent_details.contract_file, "type", $$v)},expression:"agent_details.contract_file.type"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-file-input',{attrs:{"color":"secondary","accept":"application/pdf, image/*","label":"File/Document"},model:{value:(_vm.agent_details.contract_file.file),callback:function ($$v) {_vm.$set(_vm.agent_details.contract_file, "file", $$v)},expression:"agent_details.contract_file.file"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","color":"amber","disabled":_vm.agent_details.contract_file.file===null || _vm.agent_details.contract_file.file===undefined},on:{"click":function($event){return _vm.previewFile(_vm.agent_details.contract_file)}}},on),[_c('v-icon',[_vm._v("remove_red_eye")])],1)]}}])},[_c('span',[_vm._v("Preview File")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"color":"secondary","label":"Start Date","readonly":""},model:{value:(_vm.agent_details.start_date),callback:function ($$v) {_vm.$set(_vm.agent_details, "start_date", $$v)},expression:"agent_details.start_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.start_date_menu),callback:function ($$v) {_vm.start_date_menu=$$v},expression:"start_date_menu"}},[_c('v-date-picker',{attrs:{"color":"secondary"},on:{"input":function($event){_vm.start_date_menu = false}},model:{value:(_vm.agent_details.start_date),callback:function ($$v) {_vm.$set(_vm.agent_details, "start_date", $$v)},expression:"agent_details.start_date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"color":"secondary","label":"End Date","readonly":""},model:{value:(_vm.agent_details.end_date),callback:function ($$v) {_vm.$set(_vm.agent_details, "end_date", $$v)},expression:"agent_details.end_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.end_date_menu),callback:function ($$v) {_vm.end_date_menu=$$v},expression:"end_date_menu"}},[_c('v-date-picker',{attrs:{"color":"secondary"},on:{"input":function($event){_vm.end_date_menu = false}},model:{value:(_vm.agent_details.end_date),callback:function ($$v) {_vm.$set(_vm.agent_details, "end_date", $$v)},expression:"agent_details.end_date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"1"}},[_c('v-checkbox',{attrs:{"color":"secondary","label":"Approved","required":""},model:{value:(_vm.agent_details.contract_file.approved),callback:function ($$v) {_vm.$set(_vm.agent_details.contract_file, "approved", $$v)},expression:"agent_details.contract_file.approved"}})],1)],1)],1),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"color":"green","height":"10","indeterminate":"","active":_vm.submitting}}),(_vm.submitting)?_c('v-list',{attrs:{"flat":""}},[_c('v-subheader',[_vm._v("Saving Agent Info")]),_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.backendActionItems),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("done")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item)}})],1)],1)}),1)],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"large":"","color":"success","disabled":_vm.saveDocumentsBtnDisabled()},on:{"click":function($event){return _vm.saveDocuments()}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("save")])],1)],1)],1)],1),_c('DocPreviewDialog',{attrs:{"dialog":_vm.open_doc_preview_dialog,"url":_vm.file_url,"doc_type":_vm.doc_type},on:{"CLOSE_DOC_PREVIEW_DIALOG":function($event){return _vm.closeDocPreviewDialog()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
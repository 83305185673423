<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Delete Document</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeDeleteDocumentDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <div v-if="document !== null">
                        <v-row>
                            <v-col cols="12" sm="3">
                                <v-text-field
                                    v-if="document !== null"
                                    v-model="document.type"
                                    label="Document Type"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="5">
                                <v-text-field
                                    v-if="document !== null"
                                    v-model="document.name"
                                    label="Document Name"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-text-field
                                    v-if="document !== null"
                                    :value="document.created.split('T')[0] + '  ' + document.created.split('T')[1].substring(0,8)"
                                    label="Created At"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12">
                                <v-textarea
                                    color="secondary"
                                    name="Delete Reason"
                                    filled
                                    label="Delete Reason"
                                    auto-grow
                                    v-model="document.delete_reason"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </div>
                    <v-card-text class="text-center" v-if="submitting">
                        Deactivating Document
                        <v-progress-linear
                            indeterminate
                            :active="submitting"
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submitDeleteLoanDocumentBtnDisabled()"
                                @click="submitDeleteAction()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Submit Delete Action</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { eventBus } from "../../../../main";
import { deleteLoanDocument, emitAlert, emitSuccess } from '../../../../utils/api';
import { businessProcessLoanQuote } from "../gql/mutations";
export default {
    components: {},
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        document: {
            type: Object,
            required: false,
            default: () => {
                return {
                    type: null,
                    name: null,
                    mime_type: null,
                    created: null,
                    deleted: null,
                    deleted_by: null,
                    delete_reason: null,
                }
            }
        },
        loan: {
            type: Object,
            required: false,
            default: () => {
                return { _id: null }
            }
        }
    },
    data: () => ({
        server_resp_obj: null,
        submitting: false,
    }),
    methods: {
        closeDeleteDocumentDialog() {
            eventBus.$emit('CLOSE_DELETE_LOAN_DOCUMENT_DIALOG', this.server_resp_obj);
        },
        submitDeleteLoanDocumentBtnDisabled() {
            if (this.document.delete_reason === null || this.document.delete_reason === undefined) {
                return true;
            }

            if (typeof(this.document.delete_reason) === 'string') {
                if (this.document.delete_reason.trim() === '') {
                    this.document.delete_reason = null;
                    return true;
                }
            }

            return false;
        },
        resetForm() {
            this.submitting = false;
            this.server_resp_obj = null;
            this.document.delete_reason = null;

        },
        async submitDeleteActionOld() {
            if (this.document.delete_reason !== null && this.document.delete_reason !== undefined) {
                this.submitting = true;
                const deleteDetails = {
                    delete_reason: this.document.delete_reason
                };

                try {
                    const deleteResponse = await deleteLoanDocument(
                        this.loan._id,
                        this.document._id,
                        deleteDetails
                    );

                    if (deleteResponse.errors === true) {
                        emitAlert(deleteResponse.message);
                        this.submitting = false;
                        return;
                    }

                    this.submitting = false;
                    emitSuccess(deleteResponse.message);

                    this.server_resp_obj = deleteResponse.loan;
                    this.closeDeleteDocumentDialog();

                    this.resetForm();
                } catch (error) {
                    this.submitting = false;
                    console.log(error);
                    if (error !== undefined) {
                        emitAlert(error.data.message);
                    } else {
                        emitAlert('The server has taken too long in responding to your request')
                    }
                    
                    return;
                }
            } else {
                emitAlert('Please capture the reason for deleting the document');
            }
            
        },

        async submitDeleteAction() {
            if (this.document.delete_reason !== null && this.document.delete_reason !== undefined) {
                this.submitting = true;

                try {
                    const payloadObj = {
                        OP_TYPE: 'REMOVE_DOCUMENT',
                        _id: this.loan._id,

                        documents: [
                            {
                                _id: this.document._id,
                                delete_reason: this.document.delete_reason,

                                // Placeholder fields
                                type: '',
                                mime_type: '',
                                name: '',
                            }
                        ]
                    };

                    const res = await this.$apollo
                                                .mutate({
                                                    mutation: businessProcessLoanQuote,
                                                    variables: {
                                                        input: payloadObj
                                                    },
                                                });
                    if (res.data.businessProcessLoanQuote.errors === true) {
                        emitAlert(res.data.businessProcessLoanQuote.message);
                        this.submitting = false;
                        return;
                    }

                    this.submitting = false;
                    emitSuccess(res.data.businessProcessLoanQuote.message);

                    this.server_resp_obj = res.data.businessProcessLoanQuote.business_object;
                    this.closeDeleteDocumentDialog();

                    this.resetForm();
                } catch (error) {
                    this.submitting = false;
                    console.log(error);
                    if (error !== undefined) {
                        emitAlert(error.data.businessProcessLoanQuote.message);
                    } else {
                        emitAlert('The server has taken too long in responding to your request')
                    }
                    
                    return;
                }
            }
        }
    },
    mounted() {
        
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
import gql from 'graphql-tag'

export const calculateBondPricing = gql`
    mutation calculateBondPricing($input: BondPricingConfigInput!) {
        calculateBondPricing(input: $input) {
            bond_type
            bond_term
            principal_amount
            effective_daily_compound_interest
            amount_at_end_of_term
        }
    }
`

export const businessProcessCorporateBond = gql`
    mutation businessProcessCorporateBond($input: CorporateBondInput!) {
        businessProcessCorporateBond(input: $input) {
            errors
            message
            business_object {
                ... on CorporateBond {
                    _id
                    business_partner {
                        _id
                        name
                    }
                    bond_number

                    agent {
                        _id
                        agent_number
                        entity {
                            _id
                            name
                            surname
                            id_number
                        }
                    }
                    start_date
                    end_date

                    status
                    agent_status
                    close_reason

                    beneficiary_entity {
                        _id
                        name
                        surname
                        id_number
                    }

                    main_entity {
                        _id
                        name
                        surname
                        id_number

                        bank_account_details {
                            _id
                            purpose
                            bank {
                                _id
                                name
                                universal_branch_code
                            }
                            account_type
                            account_number

                            captured_by {
                                _id
                                entity {
                                    _id
                                    name
                                    surname
                                }
                            }
                            created
                            deleted
                            delete_date
                            deleted_by {
                                _id
                                entity {
                                    _id
                                    name
                                    surname
                                }
                            }
                            delete_reason
                        }
                    }
                    collection_banking
                    payout_banking

                    created
                    approved_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }
                    captured_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }

                    pricing_config {
                        bond_type

                        principal_amount
                        bond_term_units
                        bond_term

                        annual_interest_rate
                        effective_daily_compound_interest
                    }

                    annuity_payout_amount
                    annuity_elected_monthly_payout_date

                    bond_monthly_payment_config {
                        payment_method
                        payment_frequency
                        elected_payment_date
                    }

                    documents {
                        _id
                        type
                        mime_type
                        name
                        captured_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }
                }
            }
        }
    }
`

export const businessValidateLoanQuote = gql`
    mutation businessValidateLoanQuote($loan_quote_id: String!) {
        businessValidateLoanQuote(loan_quote_id: $loan_quote_id) {
            loan_quote {
                _id
                quote_number
            }
            overall_status
            validation_results {
                id
                rule_id
                rule_name
                loan_quote_param_value
                min
                max
                valid
                waived
                fail_reason
            }
        }
    }
`

export const createFinanceTransaction = gql`
    mutation finProcessFinanceAccountTransaction($input: FinanceAccountTransactionInput) {
        finProcessFinanceAccountTransaction(input: $input) {
            errors
            message
            business_object {
                ... on FinanceAccountTransaction {
                    __typename
                    _id
                    transaction_date

                    amount
                    from {
                        _id
                        name
                        balance
                        last_updated
                    }
                    to {
                        _id
                        name
                        balance
                        last_updated
                    }
                    reference
                    additional_reference

                    balance_from
                    balance_to

                    created
                    created_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }
                }
            }
        }
    }
`
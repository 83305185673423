import gql from 'graphql-tag'

export const lookupProcessPayoutCategory = gql`
    mutation lookupProcessPayoutCategory($input: PayoutCategoryInput) {
        lookupProcessPayoutCategory(input: $input) {
            errors
            message
            business_object {
                ... on PayoutCategory {
                    __typename
                    _id
                    name
                    membership_category
                    start_age
                    end_age
                }
            }
        }
    }
`
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Add Report</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeAddReportDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <!-- Name -->
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="report.name"
                                label="Report Name"
                                color="secondary"
                                required
                            ></v-text-field>
                        </v-col>
                        <!-- Group -->
                        <v-col cols="12" sm="3">
                            <v-select
                                v-model="report.group"
                                :items="['Policy', 'Prospect Policy', 'Claim', 'Agent', 'Finance', 'Loan', 'Business', 'System']"
                                label="Report Group"
                                color="secondary"
                                required
                            ></v-select>
                        </v-col>
                        <!-- Report Number -->
                        <v-col cols="12" sm="3">
                            <v-text-field
                                v-model="report.report_number"
                                :rules="report_number_date_rules"
                                ref="report_number_field"
                                label="Report Number"
                                type="number"
                                color="secondary"
                                required
                            ></v-text-field>
                        </v-col>
                        <!-- Description -->
                        <v-col cols="12" sm="12">
                            <v-textarea
                                v-model="report.description"
                                color="secondary"
                                label="Description"
                                filled
                                auto-grow
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting Report Details
                        <v-progress-linear
                            indeterminate
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                @click="submitReport()"
                                :disabled="submitReportBtnDisabled()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Submit Report Details</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../../utils/api";
import { eventBus } from "../../../../main";

import { createReport } from "../gql/mutations";

export default {
    name: 'SearchMembersDialog',
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        report: {
            name: null,
            group: null,
            description: null,
            report_number: '',
        },
        
        server_resp_report: null,
        submitting: false,

        report_number_date_rules: [
            v => !!v || 'Report number is required',
            v => parseFloat(v) > 0 || 'Must be greater than 0'
        ],
    }),
    methods: {
        closeAddReportDialog() {
            eventBus.$emit('CLOSE_ADD_REPORT_DIALOG', this.server_resp_report);
        },
        resetForm() {
            this.report = {
                name: null,
                group: null,
                description: null,
                report_number: '',
            };
            
            this.server_resp_report = null;
            this.submitting = false;
            this.$refs.report_number_field.resetValidation();
        },
        async submitReport() {
            this.submitting = true;

            this.report.name = this.report.name.trim();
            this.report.description = this.report.description.trim();
            this.report.report_number = parseFloat(this.report.report_number);

            try {
                // const res = await createReport(this.report);
                const res = await this.$apollo
                                            .mutate({
                                                mutation: createReport,
                                                variables: {
                                                    input: this.report
                                                },
                                            });
                if (res.data.lookupProcessReport.errors === true) {
                    emitAlert(res.data.lookupProcessReport.message);
                    this.submitting = false;
                    return;
                }

                emitSuccess(res.data.lookupProcessReport.message);
                
                this.submitting = false;
                this.server_resp_report = res.data.lookupProcessReport.business_object;
                this.closeAddReportDialog();

                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.lookupProcessReport.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
        submitReportBtnDisabled() {
            if (this.report.name === null || this.report.group === null || this.report.description === null) {
                return true;
            }

            if (typeof(this.report.name) === 'string') {
                if (this.report.name.trim() === '') {
                    this.report.name = null;
                    return true;
                }
            }

            if (typeof(this.report.description) === 'string') {
                if (this.report.description.trim() === '') {
                    this.report.description = null;
                    return true;
                }
            }

            if (
                isNaN(parseFloat(this.report.report_number)) ||
                parseFloat(this.report.report_number) < 1
            ) {
                return true;
            }

            return false;
        }
    },
    mounted() {
        
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
import gql from 'graphql-tag'

export const getSoftyCompFeed = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on SoftyCompFeed {
                    _id
                    contract_code
                    reference
                    transaction_type

                    account_name
                    account_number

                    amount
                    transaction_date
                    status_description

                    classification {
                        _id
                        name
                    }
                    created
                }
            }
        }
    }
`

export const getClassifications = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on BankStatementClassification {
                    __typename
                    _id
                    name
                    category
                    created
                    captured_by {
                        __typename
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }
                    key_words_filters {
                        __typename
                        _id
                        match_key_word
                    }
                }
            }
        }
    }
`
<template>
    <v-row justify="center">
        <!-- <v-dialog
            v-model="dialog"
            fullscreen
            persistent
        > -->
        <v-dialog
            v-model="dialog"
            persistent
            width="70%"
        >
            <div style="background:white; overflow-y:auto; height:100%">
                <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d;">
                    <v-spacer></v-spacer>
                    <v-toolbar-title><b>Trigger A Business Process Run</b></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                class="mx-2"
                                color="red"
                                small
                                v-on="on"
                                @click="resetForm()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Reset Form</span>
                    </v-tooltip>
                    <v-btn icon @click="closeCreateBusinessProcessRunDialog()" v-if="server_response_obj === null">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-select
                                    v-model="selected_fin_calendar_month"
                                    :items="fin_calendar_months_lookup"
                                    color="secondary"
                                    item-text="name"
                                    item-value="_id"
                                    label="Financial Calendar Month"
                                    :disabled="refreshing_fin_calendar_month_list"

                                    hint="You can click the refresh icon next to the field if the financial calendar months list is incomplete"
                                    persistent-hint

                                    required
                                >
                                    <!-- Format prepend inner icon -->
                                    <template v-slot:prepend-inner>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-icon
                                                    v-on="on"
                                                    color="success"
                                                    @click="refreshFinCalendarMonthsLookupList()"
                                                >
                                                    mdi-refresh
                                                </v-icon>
                                            </template>
                                            <span>Refresh System Accounts List</span>
                                        </v-tooltip>
                                    </template>
                                </v-select>
                                <v-progress-linear
                                    color="green"
                                    class="mb-0"
                                    height="4"
                                    indeterminate
                                    :active="refreshing_fin_calendar_month_list"
                                ></v-progress-linear>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select
                                    v-model="selected_process"
                                    :items="business_processes"
                                    color="secondary"
                                    item-text="name"
                                    item-value="_id"
                                    label="Select Business Process"
                                    hint="Select a business-process in order to proceed with the download process"
                                    persistent-hint
                                    return-object
                                    required
                                    :disabled="selected_fin_calendar_month === null"
                                >
                                    <!-- CUSTOM RENDERING TEMPLATE -->
                                    <template
                                        v-slot:selection="{ item }"
                                    >
                                        <v-chip
                                            text-color="white"
                                            :color="color_map[item.group]"
                                        >
                                            {{ item.name }}
                                        </v-chip>
                                    </template>
                                    <!-- Template for render data when the select is expanded -->
                                    <template v-slot:item="{ item, attrs, on }">
                                        <v-list-item v-on="on" v-bind="attrs">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <v-row no-gutters align="center">
                                                    <span>{{ item.name }}</span>
                                                    <v-spacer></v-spacer>
                                                    <v-chip text-color="white" :color="color_map[item.group]" small
                                                    >{{ item.group }}</v-chip>
                                                    </v-row>
                                                </v-list-item-title>
                                                <!-- <v-list-item-subtitle>
                                                    {{item.description}}
                                                </v-list-item-subtitle> -->
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-select>
                            </v-col>
                        </v-row>
                        
                        <v-row v-if="selected_process !== null">
                            <v-col cols="12" sm="12">
                                <v-row style="background: #f6c20b">
                                    <v-col cols="12" sm="12">
                                        <h3>Business Process Details</h3>
                                    </v-col>
                                    <v-col cols="12" sm="12">
                                        <v-textarea
                                            :value="selected_process.description"
                                            label="Description"
                                            color="secondary"
                                            required
                                            rows="1"
                                            auto-grow
                                            disabled
                                        >

                                        </v-textarea>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            :value="selected_process.process_number"
                                            label="Business Process Number"
                                            color="secondary"
                                            required
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            :value="selected_process.group"
                                            label="Business Process Group"
                                            color="secondary"
                                            required
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12">
                                        <v-btn
                                            block
                                            class="ma-0"
                                            color="secondary"
                                            @click="triggerProcessRun()"
                                        >
                                            <v-spacer></v-spacer>
                                            Submit Business Process Trigger
                                            <v-spacer></v-spacer>
                                            <v-icon
                                                large
                                                right
                                            >
                                                mdi-run-fast
                                            </v-icon>
                                            <v-spacer></v-spacer>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </div>
        </v-dialog>
    </v-row>
</template>
<script>
import { store } from "../../../../store";
import { eventBus } from '../../../main';
import { emitAlert, emitSuccess } from '../../../utils/api';
import { businessProcess } from "../gql/mutations";
import { getFinancialCalendarMonths } from "../gql/queries";

export default {
    components: {},
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        selected_process: null,
        server_response_obj: null,
        business_processes: [],

        clearTimeFunction: null,

        currentUserAccount: store.state.currentUserAccount,

        selected_fin_calendar_month: null,
        refreshing_fin_calendar_month_list: false,
        fin_calendar_months_lookup: [],

        color_map: {
            'Finance': 'success',
            'Claim': 'red',
            'Agent': 'amber',
            // 'Finance': '#0e6db1',
            'Commissions': '#0e6db1',
            'Payment Instructions': '#0e6db1',
        },
    }),
    computed: {},
    methods: {
        closeCreateBusinessProcessRunDialog() {
            eventBus.$emit('CLOSE_CREATE_BUSINESS_PROCESS_RUN_DIALOG', this.server_response_obj);
        },

        resetForm() {
            this.selected_process = null;
            this.selected_fin_calendar_month = null;
        },

        async triggerProcessRun() {
            // Now send a server request
            try {
                const payloadObj = {
                    process_group: 'BUSINESS_PROCESS',
                    business_process_id: this.selected_process._id,
                    fin_calendar_month: this.selected_fin_calendar_month
                }

                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcess,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.businessProcess.errors === true) {
                    emitAlert(res.data.businessProcess.message);
                    return;
                }
                
                emitSuccess(res.data.businessProcess.message);
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
            
        },

        async refreshFinCalendarMonthsLookupList() {
            this.refreshing_fin_calendar_month_list = true;
            try {
                // Load Opened Fin Calendar Months
                const res = await this.$apollo
                                    .query({
                                        query: getFinancialCalendarMonths,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'FINANCE_CALENDAR_MONTH',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            },
                                            searchInput: {
                                                status: 'OPEN'
                                            }
                                        },
                                    });
                this.refreshing_fin_calendar_month_list = false;
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }

                if (res.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }
                this.fin_calendar_months_lookup = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.refreshing_fin_calendar_month_list = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
    },
    async mounted() {
        // user business process
        this.business_processes = this.currentUserAccount.roles[0].role.allowed_business_processes.map(allowed_process => {
            return allowed_process.process;
        });

        await this.refreshFinCalendarMonthsLookupList();
    },
    watch: {}
}
</script>
import gql from 'graphql-tag'

export const businessProcessManagementAccounts = gql`
    mutation businessProcessManagementAccounts($input: ManagementAccountInput!) {
        businessProcessManagementAccounts(input: $input) {
            errors
            message
            business_objects {
                ... on BankStatementClassification {
                    __typename
                    _id
                    name
                    category
                    created
                    captured_by {
                        __typename
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }
                    key_words_filters {
                        __typename
                        _id
                        match_key_word
                    }
                }
            }
        }
    }
`
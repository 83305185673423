var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding":"0px","width":"100%","border-top":"3px solid #4590a0","border-bottom":"3px solid #4590a0"}},[_c('v-toolbar',{attrs:{"color":"secondary","dark":"","height":"50"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"amber black--text","small":""},on:{"click":function($event){return _vm.refreshCallRecordings()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh Call Recordings")])]),_c('v-spacer'),((
                _vm.selectedRecording !== null &&
                //selectedRecording.recording_imported === false
                _vm.selectedRecording.twilio_call_obj === null
            ))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"amber black--text","small":""},on:{"click":function($event){return _vm.downloadCallRecording()}}},on),[_c('v-icon',[_vm._v("mdi-download")]),_vm._v(" Download Recording ")],1)]}}],null,false,1404490506)},[_c('span',[_vm._v("Download Call Recording")])]):_vm._e(),((
                _vm.selectedRecording !== null &&
                _vm.selectedRecording.recording_imported === true
            ))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"black white--text","small":""},on:{"click":function($event){return _vm.playCallRecording()}}},on),[_c('v-icon',[_vm._v("mdi-play")]),_vm._v(" Play Recording ")],1)]}}],null,false,2842491940)},[_c('span',[_vm._v("Play Call Recording")])]):_vm._e(),((
                _vm.selectedRecording !== null &&
                _vm.selectedRecording.recording_imported === true &&
                _vm.selectedRecording.call_rating === null && 
                (
                    _vm.isCentralUnderwriterAdminUser ||
                    _vm.isCallQualityAssurer
                )/*&&
                selectedRecording.call_rating === null*/
            ))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"margin":"5px"},attrs:{"color":"red white--text","small":""},on:{"click":function($event){return _vm.openCallRatingDialog()}}},on),[_c('v-icon',[_vm._v("mdi-marker-check")]),_vm._v(" Rate Call ")],1)]}}],null,false,2045309632)},[_c('span',[_vm._v("Rate Call")])]):_vm._e(),((
                _vm.selectedRecording !== null &&
                _vm.selectedRecording.recording_imported === true &&
                _vm.selectedRecording.call_rating !== null
            ))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"margin":"5px"},attrs:{"color":"green white--text","small":""},on:{"click":function($event){return _vm.openViewCallRatingResultsDialog()}}},on),[_c('v-icon',[_vm._v("mdi-marker-check")]),_vm._v(" View Call Rating ")],1)]}}],null,false,1112474231)},[_c('span',[_vm._v("View Call Rating")])]):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.sub_tab_height,"headers":_vm.document_details_headers,"items":_vm.call_recordings,"item-key":"_id","single-select":true,"hide-default-footer":"","loading":_vm.recordings_loading,"disable-pagination":true},on:{"click:row":_vm.selectDocumentsTabRow},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
            var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.created.split('T')[0]))])]}},{key:"item.recording_imported",fn:function(ref){
            var item = ref.item;
return [(item.recording_imported === true && item.twilio_call_obj !== null)?_c('td',[_vm._v("YES")]):(item.recording_imported === false && item.twilio_call_obj !== null)?_c('td',[_vm._v("NO")]):_c('td',[_vm._v("NOT-YET")])]}},{key:"item.call_rating",fn:function(ref){
            var item = ref.item;
return [(item.call_rating !== null)?_c('td',[_vm._v("YES")]):_c('td',[_vm._v("NO")])]}},{key:"item._id",fn:function(ref){
            var item = ref.item;
return [(item.call_rating !== null)?_c('td',{staticStyle:{"background":"green","color":"white"}},[_vm._v(" RATING-DONE ")]):((
                    item.call_rating === null &&
                    item.recording_imported === false &&
                    item.twilio_call_obj !== null
                ))?_c('td',{staticStyle:{"background":"green","color":"white"}},[_vm._v(" CALL-NOT-ANSWERED ")]):((
                    item.call_rating === null &&
                    item.recording_imported === true &&
                    item.twilio_call_obj !== null
                ))?_c('td',{staticStyle:{"background":"red","color":"white"}},[_vm._v(" PENDING-RATING ")]):((
                    item.call_rating === null &&
                    item.recording_imported === false &&
                    item.twilio_call_obj === null
                ))?_c('td',{staticStyle:{"background":"red","color":"white"}},[_vm._v(" PENDING-DOWNLOAND-AND-RATING ")]):_c('td',[_vm._v("NO")])]}},{key:"item.score",fn:function(ref){
                var item = ref.item;
return [(item.call_rating !== null)?_c('td',[_vm._v(_vm._s(item.call_rating.total_score))]):_c('td',[_vm._v("N/A")])]}},{key:"item.captured_by",fn:function(ref){
                var item = ref.item;
return [(item.captured_by !== null)?_c('td',[(item.captured_by.entity !== null)?_c('span',[_vm._v(" "+_vm._s(item.captured_by.entity.name)+" "+_vm._s(item.captured_by.entity.surname)+" ")]):_c('span',[_vm._v("N/A")])]):_vm._e()]}}])}),(_vm.selectedRecording !== null && _vm.recordingData !== null)?_c('ListenToCall',{attrs:{"dialog":_vm.open_listen_to_call_dialog,"recording_data":_vm.recordingData}}):_vm._e(),(_vm.collectionObj !== null && _vm.selectedRecording !== null && _vm.recordingData !== null)?_c('CallRatingDialog',{attrs:{"dialog":_vm.open_call_rating_dialog,"recording_data":_vm.recordingData,"recording_obj":_vm.selectedRecording,"entity_id":_vm.collectionObj.entity._id}}):_vm._e(),(_vm.selectedRecording !== null && _vm.recordingData !== null)?_c('ViewCallRatingResultsDialog',{attrs:{"dialog":_vm.open_view_call_rating_results_dialog,"recording_data":_vm.recordingData,"recording_obj":_vm.selectedRecording}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
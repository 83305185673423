var differenceInMonths = require('date-fns').differenceInMonths;

function calculateEntityAge(birthDateString) {
    let today = new Date();
    let birthDate = new Date(birthDateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    if (age === 0) {
        // Then axpress it as months
        let monthAge = differenceInMonths(today, birthDate);
        age = parseFloat((monthAge/12).toFixed(4))
    }

    return age;
}

function calculateEntityAgeAtDate(birthDateString, targetDate) {
    let birthDate = new Date(birthDateString);
    let age = targetDate.getFullYear() - birthDate.getFullYear();
    let m = targetDate.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && targetDate.getDate() < birthDate.getDate())) {
        age--;
    }

    if (age === 0) {
        // Then axpress it as months
        let monthAge = differenceInMonths(targetDate, birthDate);
        age = parseFloat((monthAge/12).toFixed(4))
    }

    return age;
}

/**
 * 
 * @param {Date} effectiveDate 
 */
function calculatePolicyAge(effectiveDate) {
    const age = {
        days: 0,
        months: 0,
        years: 0
    };

    if (effectiveDate === null) {
        return age;
    }

    let today = new Date();

    // Years Age
    let yearsAge = today.getFullYear() - effectiveDate.getFullYear();
    let m = today.getMonth() - effectiveDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < effectiveDate.getDate())) {
        yearsAge--;
    }

    // Months Age
    let monthAge = differenceInMonths(today, effectiveDate);


    if (yearsAge === 0) {
        // Then axpress it as months decimal
        yearsAge = parseFloat((monthAge/12).toFixed(4))
    }

    // Days Age
    const millisecondsPerDay = (1000 * 60 * 60 * 24);
    let daysAge = (today - effectiveDate) / millisecondsPerDay;
    age.years = yearsAge;
    age.months = monthAge;
    age.days = daysAge;

    return age;
}

module.exports = {
    calculateEntityAge,
    calculateEntityAgeAtDate,
    calculatePolicyAge
}
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Add Member Contacts</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row
                        v-for="contact in contact_details"
                        :key="contact.id"
                        style="background:#eaeaea; padding-bottom:10px"
                    >
                        <v-col cols="12" sm="4">
                            <v-select
                                v-model="contact.type"
                                :items="contact_types"
                                color="secondary"
                                label="Contact Type"
                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-select
                                v-model="contact.primary"
                                color="secondary"
                                :items="['Yes', 'No']"
                                label="Primary"
                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field
                                v-model="contact.details"
                                color="secondary"
                                label="Contact Details"
                                autocomplete="some-random-text"
                                type="text"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <!-- Buttons for Adding/Removing Contact -->
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting Member Contact
                        <v-progress-linear
                            indeterminate
                            :active="submitting"
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submitBtnDisabled()"
                                @click="submitData()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Save Member Contact Details</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";
import { contact_types } from "../../../../config";

import { entityProcessEntity } from "../gql/mutations";

export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        entity_id: {
            type: String,
            default: null
        },
    },
    data: () => ({
        server_response_obj: null,
        submitting: false,
        contact_details: [
            {
                id: 1,
                type: null,
                primary: null,
                details: null
            }
        ],

        contact_types: contact_types,
    }),
    methods: {
        closeDialog() {
            eventBus.$emit('CLOSE_ADD_MEMBER_CONTACTS_DIALOG', this.server_response_obj);
        },

        resetForm() {
            this.submitting = false;
            this.contact_details = [
                {
                    id: 1,
                    type: null,
                    primary: null,
                    details: null
                }
            ];
        },

        submitBtnDisabled() {
            if (
                this.contact_details[0].type === null ||
                this.contact_details[0].primary === null ||
                this.contact_details[0].details === null
            ) {
                return true;
            }
            
            if (typeof(this.contact_details[0].details) === 'string') {
                if (this.contact_details[0].details.trim().length === 0) {
                    return true;
                }
            }

            return false;
        },

        async submitData() {
            const validContacts = this.contact_details
                                    .filter(cont => { return cont.type !== null })
                                    .map(cont => {
                                        delete cont.id;
                                        cont.primary = (cont.primary === 'Yes') ? true : false;
                                        return cont;
                                    });

            if (validContacts.length === 0) {
                emitAlert('Please capture valid contact details');
                return;
            }

            // Quickly sanitize the payload
            validContacts.forEach(item => {
                item.details = item.details.trim()
            });

            try {
                this.submitting = true;

                const payload = {
                        OP_TYPE: 'ADD_CONTACT',
                        _id: this.entity_id,
                        contact_details: validContacts
                    };
                const res = await this.$apollo
                                        .mutate({
                                            mutation: entityProcessEntity,
                                            variables: {
                                                input: payload
                                            },
                                        });
                if (res.data.entityProcessEntity.errors === true) {
                    emitAlert(res.data.entityProcessEntity.message);
                    this.submitting = false;
                    return;
                }

                // Sanitize version history
                res.data.entityProcessEntity.business_object.version_history.changes.forEach(change => {
                    change.old = JSON.parse(change.old);
                    change.new = JSON.parse(change.new);
                });
                
                this.submitting = false;
                this.server_response_obj = res.data.entityProcessEntity.business_object;

                this.closeDialog();
                emitSuccess(res.data.entityProcessEntity.message);

                // Reset fields
                this.server_response_obj = null;
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.entityProcessEntity.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
    },
    mounted() {
        
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
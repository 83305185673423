<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Add Commission Rule</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row
                        v-for="commission in commission_rules"
                        :key="commission.id"
                        style="background:#eaeaea; padding-bottom:10px"
                    >
                        <v-col cols="12" sm="6">
                            <v-select
                                v-model="commission.type"
                                :items="agent_commission_rules"
                                label="Commission Rule Type"
                                color="secondary"
                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="commission.amount"
                                :prefix="commission.type === 'PERCENTAGE_PER_POLICY' ? '%' : 'ZAR'"
                                label="Amount"
                                autocomplete="some-random-text"
                                type="number"
                                color="secondary"
                                required
                            ></v-text-field>
                        </v-col>
                        <!-- <div style="background:white; height: 10px; width: 100%"></div> -->
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting Agent Commission Rule
                        <v-progress-linear
                            indeterminate
                            :active="submitting"
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                    <!-- Buttons for Adding/Removing Banking Details -->
                    <!-- <v-row>
                        <v-col sm="10"></v-col>
                        <v-col sm="2">
                            <v-tooltip bottom v-if="commission_rules.length > 1">
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        class="mx-2"
                                        color="secondary"
                                        small
                                        v-on="on"
                                        @click="removeCommissionRule()"
                                    >
                                        <v-icon>remove</v-icon>
                                    </v-btn>
                                </template>
                                <span>Remove Commission Rule</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        color="secondary"
                                        small
                                        v-on="on"
                                        @click="addCommissionRules()"
                                    >
                                        <v-icon>add</v-icon>
                                    </v-btn>
                                </template>
                                <span>Add Another Commission Rule</span>
                            </v-tooltip>
                        </v-col>
                    </v-row> -->
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submitAgentBtnDisabled()"
                                @click="submitAgent()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Submit Agent's Details</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";
import { agent_commission_rules } from "../../../../config";

import { entityProcessAgent } from "../gql/mutations";

export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        agent_id: {
            type: String,
            default: null
        },
    },
    data: () => ({
        server_response_obj: null,
        submitting: false,
        commission_rules: [
            {
                id: 1,
                type: null,
                amount: 0,
            }
        ],
        agent_commission_rules: agent_commission_rules
    }),
    methods: {
        closeDialog() {
            eventBus.$emit('CLOSE_ADD_COMMISSION_RULE_DIALOG', this.server_response_obj);
        },
        resetForm() {
            this.submitting = false;
            this.commission_rules = [
                {
                    id: 1,
                    type: null,
                    amount: 0,
                }
            ];
        },
        
        //--------------------ADD/REMOVE COMMISSION RULES--------------------------//
        addCommissionRules() {
            const size = this.commission_rules.length;

            this.commission_rules.push({
                id: size + 1,
                type: null,
                amount: 0
            });
        },
        removeCommissionRule() {
            this.commission_rules.pop();
        },

        submitAgentBtnDisabled() {
            const validCommissionRules = this.commission_rules.filter(com_rule => {
                return (com_rule.type !== null && (parseFloat(com_rule.amount)) > 0)
            });

            if (validCommissionRules.length === 0) {
                return true;
            }

            return false;
        },

        async submitAgent() {
             const validCommissionRules = this.commission_rules.filter(com_rule => {
                return (com_rule.type !== null && (parseFloat(com_rule.amount)) > 0)
            });

            this.submitting = true;

            const arrCopy = JSON.parse(JSON.stringify(validCommissionRules))
                                    .map(rule => {
                                        delete rule.id;
                                        rule.amount = parseFloat(rule.amount);

                                        return rule;
                                    });

            try {
                const res = await this.$apollo
                                                .mutate({
                                                    mutation: entityProcessAgent,
                                                    variables: {
                                                        input: {
                                                            OP_TYPE: 'ADD_COMMISSION_RULE',
                                                            _id: this.agent_id,
                                                            
                                                            commission_rules: arrCopy
                                                        }
                                                    },
                                                });
                if (res.data.entityProcessAgent.errors === true) {
                    emitAlert(res.data.entityProcessAgent.message);
                    this.submitting = false;
                    return;
                }
                
                this.server_response_obj = res.data.entityProcessAgent.business_object;
                this.closeDialog();
                emitSuccess(res.data.entityProcessAgent.message);

                // Reset fields
                this.server_response_obj = null;
                this.submitting = false;
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.entityProcessAgent.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request')
                }
                
                return;
            }
        },
    },
    mounted() {
        
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
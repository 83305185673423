import gql from 'graphql-tag'

export const getProducts = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on InsuranceProductSecondary {
                    __typename
                    _id
                    name
                    markup_fixed
                    markup_percentage
                    created
                    captured_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }

                    primary_product {
                        __typename
                        _id
                        name

                        underwriter {
                            _id
                            name
                        }
                        underwriter_premium
                        broker_commission
                        broker_markup
                        active

                        payout_structure {
                            _id
                            category {
                                _id
                                name
                                membership_category
                                start_age
                                end_age
                            }
                            payout_amount
                        }
                        rules {
                            _id
                            rule_parameter {
                                _id
                                id
                                level
                                name
                                description
                                object_key
                            }
                            min
                            max
                        }
                        vas_services {
                            _id
                            limit_model
                            limit
                            created
                            vas_service {
                                _id

                                name
                                # category {
                                #     _id
                                #     name
                                # }
                                description
                                captured_by {
                                    _id
                                    entity {
                                        _id
                                        name
                                        surname
                                    }
                                }
                                created
                            }
                        }
                    }
                }
            }
        }
    }
`
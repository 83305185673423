<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Add Document</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row
                        v-for="file in file_details"
                        :key="file.id"
                        style="background:#eaeaea; padding-bottom:10px"
                    >
                        <v-col cols="12" sm="5">
                            <v-select
                                v-model="file.type"
                                :items="document_types"
                                color="secondary"
                                item-value="name"
                                item-text="name"
                                label="Document Type"
                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-file-input
                                v-model="file.file"
                                color="secondary"
                                accept="application/pdf, image/*"
                                label="File/Document"
                            ></v-file-input>
                        </v-col>
                        <v-col cols="12" sm="1">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        fab
                                        color="amber"
                                        v-on="on"
                                        @click="previewFile(file)"
                                        :disabled="file.file===null || file.file===undefined"
                                    >
                                        <v-icon>remove_red_eye</v-icon>
                                    </v-btn>
                                </template>
                                <span>Preview File</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <!-- Buttons for Adding/Removing Document -->
                    <!-- <v-row>
                        <v-col sm="10"></v-col>
                        <v-col sm="2">
                            <v-tooltip bottom v-if="file_details.length > 1">
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        class="mx-2"
                                        color="secondary"
                                        small
                                        v-on="on"
                                        @click="removeFile()"
                                    >
                                        <v-icon>remove</v-icon>
                                    </v-btn>
                                </template>
                                <span>Remove Document</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        color="secondary"
                                        small
                                        v-on="on"
                                        @click="addFile()"
                                    >
                                        <v-icon>add</v-icon>
                                    </v-btn>
                                </template>
                                <span>Add Another Document</span>
                            </v-tooltip>
                        </v-col>
                    </v-row> -->
                </v-card-text>
                <!-- Progress Section -->
                <v-progress-linear
                    color="green"
                    class="mb-0"
                    height="6"
                    indeterminate
                    :active="submitting"
                ></v-progress-linear>
                <v-list flat v-if="submitting">
                    <v-subheader>Saving Policy Info</v-subheader>
                    <!-- <v-list-item-group v-model="item" color="primary"> -->
                    <v-list-item-group color="primary">
                        <v-list-item
                            v-for="(item, i) in backendActionItems"
                            :key="i"
                        >
                            <v-list-item-icon>
                                <v-icon>done</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="item"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>

                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        large
                        color="success"
                        :disabled="submitPolicyDocumentBtnDisabled()"
                        @click="saveDocuments()"
                    >
                        <v-icon large>save</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <DocPreviewDialog
            :dialog="open_doc_preview_dialog"
            :url="file_url"
            :doc_type="doc_type"
            @CLOSE_DOC_PREVIEW_DIALOG="closeDocPreviewDialog()"
        />
    </v-row>
</template>
<script>
import DocPreviewDialog from '../../../components/doc_preview';
import { emitAlert, submitDocumentumFile } from "../../../utils/api";
import { eventBus } from "../../../main";
import ObjectID from "bson-objectid";
// import config, { document_types } from "../../../../config";
import { document_types } from "../../../../config";

import { businessProcessPolicy } from "../gql/mutations";

export default {
    components: {
        DocPreviewDialog
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        policy: {
            type: Object,
            required: false,
            default: () => {
                return {
                    // name,
                    documents: []
                }
            }
        },
    },
    data: () => ({
        server_resp_obj: null,
        submitting: false,

        // File props
        file: '',
        file_url: null,
        doc_type: null,
        file_details: [
            {
                id: 1,
                type: null,
                file: null,
                valid: false,
            }
        ],

        db_documents: [],

        open_doc_preview_dialog: false,

        backendActionItems: [],
        document_types: document_types.filter(doc_type => { return doc_type.type === 'policy' })

    }),
    methods: {
        closeDialog() {
            eventBus.$emit('CLOSE_ADD_POLICY_DOCUMENTS_DIALOG', this.server_resp_obj);
        },
        closeDocPreviewDialog() {
            this.open_doc_preview_dialog = false;
        },
        resetForm() {
            this.file_details = [
                {
                    id: 1,
                    type: null,
                    file: null,
                    valid: false,
                }
            ];
            this.server_resp_obj = null;
            this.backendActionItems = [];
            this.db_documents = [];
        },

        submitPolicyDocumentBtnDisabled() {
            if (
                this.file_details[0].file === null ||
                this.file_details[0].file === undefined ||
                this.file_details[0].type === null ||
                this.file_details[0].type === undefined
            ) {
                return true;
            }
            
            return false;
        },
        
        saveDocuments() {
            // if (this.policy.status === config.POLICY_PROSPECT_STATUSES.APPROVED) {
            //     emitAlert(`Adding a document is not allowed for a prospect-policy in status: ${this.policy.status}`);
            //     return;
            // }

            this.file_details.forEach(file => {
                if ((file.file !== null) && (file.type !== null)) {
                    const objID = ObjectID();

                    file.id = objID.toString();
                    file.valid = true;

                    this.db_documents.push({
                        _id: objID, //Generate mngodb id
                        type: file.type,
                        name: file.file.name,
                        mime_type: file.file.type,
                    });
                }
            });

            if (this.db_documents.length === 0) {
                emitAlert('There are no valid documents to save/submit');
                return;
            }
            
            this.submitDocumentsToDB();
        },

        //--------------------ADD/REMOVE FILES--------------------------//
        addFile() {
            const files_size = this.file_details.length;

            this.file_details.push({
                id: files_size + 1,
                type: null,
                file: null
            });
        },
        removeFile() {
            this.file_details.pop();
        },

        //--------------------PREVIEW FILES--------------------------//
        previewFile(fileObject) {
            // Set file oobject so we can trigger the readAsDataURL function
            const reader = new FileReader();

            reader.addEventListener("load", () => {
                // Set PDF-Viewer URL to the selected file Base64 String
                this.file_url = reader.result;
                if (fileObject.file.type === 'application/pdf') {
                    this.doc_type = 'pdf';
                } else {
                    this.doc_type = 'image';
                }

                // Display the preview dialog
                this.open_doc_preview_dialog = true;
            }, false);

            reader.readAsDataURL(fileObject.file);
        },

        async submitDocumentsToDB() {
            this.submitting = true;
            this.backendActionItems.push("Saving policy documents");

            try {
                // First Upload file to documentum
                const file = this.file_details[0];
                if (file.valid) {
                    const policyId = this.policy._id;
                    const uploadRes = await submitDocumentumFile(file.id, file.file, 'policy', policyId);
                    if (uploadRes.errors === true) {
                        emitAlert(uploadRes.message);
                        this.submitting = false;
                        return;
                    }

                    
                    const doc = this.db_documents[0];
                    // const recRes = await addProspectPolicyDocument(policyId, doc);
                    // if (recRes.errors === true) {
                    //     emitAlert(recRes.message);
                    //     this.submitting = false;
                    //     return;
                    // }
                    const payloadObj = {
                        OP_TYPE: 'ADD_DOCUMENT',
                        _id: policyId,

                        documents: [doc]
                    };

                    const recRes = await this.$apollo
                                                .mutate({
                                                    mutation: businessProcessPolicy,
                                                    variables: {
                                                        input: payloadObj
                                                    },
                                                });
                    if (recRes.data.businessProcessPolicy.errors === true) {
                        emitAlert(recRes.data.businessProcessPolicy.message);
                        this.submitting = false;
                        return;
                    }

                    this.submitting = false;
                    this.server_resp_obj = recRes.data.businessProcessPolicy.business_object;
                    this.closeDialog();

                    // reset form
                    this.resetForm();
                }
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessPolicy.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request')
                }
                
                return;
            }
        }
    },
    mounted() {
        
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Upload Softy-Comp Feed</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeAddSoftyCompFeedDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <!-- Statement CSV File -->
                        <v-col cols="12" sm="6" offset-sm="3">
                            <v-file-input
                                v-model="file.file"
                                color="secondary"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                label="File/Document"
                            ></v-file-input>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting Softy-Comp Feed...
                        <v-progress-linear
                            indeterminate
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submitSoftyCompFeedBtnDisabled()"
                                @click="submitSoftyCompFeed()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Submit Softy-Comp Feed Details</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../../utils/api";
import { eventBus } from "../../../../main";
import { captureSoftyCompFeed } from "../gql/mutations";
import * as XLSX from 'xlsx';
export default {
    name: 'CaptureSoftyCompFeedDialog',
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        server_resp: null,
        transactions_list: [],
        submitting: false,

        file: {
            file: null
        },
    }),
    methods: {
        closeAddSoftyCompFeedDialog() {
            eventBus.$emit('CLOSE_CAPTURE_SOFTY_COMP_FEED_DIALOG', this.server_resp);
        },
        resetForm() {
            this.server_resp = null;
            this.file.file = null;
            this.transactions_list = [];
        },
        submitSoftyCompFeedBtnDisabled() {
            if (this.file.file === null) {
                return true;
            }

            if (this.transactions_list.length === 0) {
                return true;
            }

            return false;
        },
        async submitSoftyCompFeed() {
            this.submitting = true;

            
            try {
                let serverMessage = null;
                let server_resp_objects = [];
                for (const transactionsList of this.transactions_list) {
                    const res = await this.$apollo
                                                .mutate({
                                                    mutation: captureSoftyCompFeed,
                                                    variables: {
                                                        input: {
                                                            OP_TYPE: 'PROCESS_SOFTY_COMP_FEED',
                                                            // softy_comp_feed: this.transactions_list,
                                                            softy_comp_feed: transactionsList,
                                                        }
                                                    },
                                                });
                    if (res.data.businessProcessManagementAccounts.errors === true) {
                        emitAlert(res.data.businessProcessManagementAccounts.message);
                        this.submitting = false;
                        return;
                    }

                    serverMessage = res.data.businessProcessManagementAccounts.message;
                    // emitSuccess(res.data.businessProcessManagementAccounts.message);
                    
                    // this.submitting = false;
                    // this.server_resp = res.data.businessProcessManagementAccounts.business_objects;
                    server_resp_objects = server_resp_objects.concat(res.data.businessProcessManagementAccounts.business_objects);
                    // this.closeAddSoftyCompFeedDialog();

                    // this.resetForm();
                }

                emitSuccess(serverMessage);
                this.submitting = false;
                this.server_resp = server_resp_objects;
                this.closeAddSoftyCompFeedDialog();

                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessManagementAccounts.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
            
        },

        isValidDate(d) {
            return d instanceof Date && !isNaN(d);
        }
    },
    mounted() {
        
    },
    watch: {
        'file.file': function(file) {
            this.transactions_list = [];
            console.log(file)

            if (file !== null) {
                const reader = new FileReader();
                // reader.readAsText(file)
                reader.readAsBinaryString(file)

                reader.onload = (event) => {
                    const binaryData = event.target.result;
                    const workbook = XLSX.read(binaryData, {
                        type: 'binary',
                        cellDates: true
                    });
                    // console.log(workbook)

                    let process = false;
                    const resultTransactions = [];
                    workbook.SheetNames.forEach(function(sheetName) {
                        if (sheetName.trim() === 'TransactionsReport') {
                            console.log(sheetName);
                            const XL_row_object_list = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

                            for (const rowItem of XL_row_object_list) {
                                if (rowItem.__EMPTY_1 === 'Contract Code') {
                                    process = true;
                                    continue;
                                }

                                if (process === true) {
                                    const transDate = rowItem.__EMPTY_7.toLocaleDateString().split('/');

                                    if (typeof(rowItem.__EMPTY_6) === 'string') {
                                        rowItem.__EMPTY_6 = parseFloat(rowItem.__EMPTY_6.replace('R', ''));
                                    }

                                    resultTransactions.push({
                                        contract_code: rowItem.__EMPTY_1.trim(),
                                        reference: rowItem.__EMPTY_2.trim(),
                                        transaction_type: rowItem.__EMPTY_3.trim(),
                                        account_name: rowItem.__EMPTY_4.trim(),
                                        account_number: rowItem.__EMPTY_5.trim(),
                                        amount: rowItem.__EMPTY_6,
                                        // transaction_date: rowItem.__EMPTY_7,
                                        transaction_date: `${transDate[2]}-${transDate[1]}-${transDate[0]}`,
                                        status_description: rowItem.__EMPTY_10.trim(),
                                    });
                                }
                            }
                        }
                    });

                    console.log(resultTransactions);

                    // Split array into chunks
                    const chunkSize = 100;
                    for (let i = 0; i < resultTransactions.length; i += chunkSize) {
                        const chunkArr = resultTransactions.slice(i, i + chunkSize);
                        
                        this.transactions_list.push( chunkArr );
                    }


                    // this.transactions_list = resultTransactions;
                    console.log(this.transactions_list);
                };
            }
        }
    }
}
</script>
<style scoped>

</style>
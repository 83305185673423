var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"45%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-toolbar',{staticStyle:{"border":"1px solid #07ad2d"},attrs:{"dark":"","color":"secondary"}},[_c('v-toolbar-title',[_c('b',[_vm._v("Underwriter Approved")])]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.closeDialog(true)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card',[_c('v-card-text',[(_vm.policy !== null)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('h3',[_vm._v(" Please capture the date the policy was approved by the underwriter below: ")])])],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"color":"secondary","label":"Approval Date","readonly":""},model:{value:(_vm.approval_date),callback:function ($$v) {_vm.approval_date=$$v},expression:"approval_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.approval_date_menu),callback:function ($$v) {_vm.approval_date_menu=$$v},expression:"approval_date_menu"}},[_c('v-date-picker',{attrs:{"color":"secondary"},on:{"input":function($event){_vm.approval_date_menu = false}},model:{value:(_vm.approval_date),callback:function ($$v) {_vm.approval_date=$$v},expression:"approval_date"}})],1)],1)],1),(_vm.submitting)?_c('v-card-text',{staticClass:"text-center"},[_vm._v(" Submitting... "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"success"}})],1):_vm._e()],1),_c('v-divider'),_c('v-card-actions',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"large":"","color":"red white--text"},on:{"click":function($event){return _vm.closeDialog(true)}}},on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("close")]),_c('span',[_vm._v("Cancel")])],1)]}}])},[_c('span',[_vm._v("Cancel")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"large":"","color":"success"},on:{"click":function($event){return _vm.submitApproveAction()}}},on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("save")])],1)]}}])},[_c('span',[_vm._v("Proceed to approve policy")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
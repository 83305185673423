<template>
    <v-row justify="center">
        <!-- <v-dialog
            v-model="dialog"
            fullscreen
            persistent
        > -->
        <v-dialog
            v-model="dialog"
            persistent
        >
            <div style="background:white; overflow-y:auto; height:100%">
                <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d;">
                    <v-spacer></v-spacer>
                    <v-toolbar-title><b>Edit Policy Basic Details</b></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                class="mx-2"
                                color="red"
                                small
                                v-on="on"
                                @click="resetForm()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Reset Form</span>
                    </v-tooltip>
                    <v-btn icon @click="closeAddPolicyDialog()" v-if="server_response_obj === null">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card>
                    <!-- BASIC DETAILS -->
                    <v-card-text>
                        <v-row style="background:#4490a030">
                            <v-col cols="12" sm="12" >
                                <h2>Basic Details</h2>
                                <hr style="height:1px !important; background-color:grey !important; border: none !important;">
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select
                                    v-model="policy_copy.product"
                                    :items="products"
                                    item-value="_id"
                                    item-text="name"
                                    label="Product"
                                    color="secondary"
                                    return-object
                                    :disabled="refreshing_product_lookup_list"

                                    hint="You can click the refresh icon next to the field if the product list is incomplete"
                                    persistent-hint

                                    required
                                >
                                    <!-- Format prepend inner icon -->
                                    <template v-slot:prepend-inner>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-icon
                                                    v-on="on"
                                                    color="success"
                                                    @click="refreshProductLookupList()"
                                                >
                                                    mdi-refresh
                                                </v-icon>
                                            </template>
                                            <span>Refresh Product List</span>
                                        </v-tooltip>
                                    </template>
                                </v-select>
                                <v-progress-linear
                                    color="green"
                                    class="mb-0"
                                    height="4"
                                    indeterminate
                                    :active="refreshing_product_lookup_list"
                                ></v-progress-linear>
                            </v-col>
                            <!-- <v-col cols="12" sm="4" >
                                <v-select
                                    v-model="policy_copy.scheme"
                                    :items="schemes"
                                    item-value="_id"
                                    item-text="name"
                                    label="Scheme / Group (Optional)"
                                    color="secondary"
                                    :disabled="refreshing_scheme_lookup_list"

                                    hint="You can click the refresh icon next to the field if the scheme list is incomplete"
                                    persistent-hint

                                    required
                                > -->
                                    <!-- Format prepend inner icon -->
                                    <!-- <template v-slot:prepend-inner>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-icon
                                                    v-on="on"
                                                    color="success"
                                                    @click="refreshSchemesLookupList()"
                                                >
                                                    mdi-refresh
                                                </v-icon>
                                            </template>
                                            <span>Refresh Scheme List</span>
                                        </v-tooltip>
                                    </template>
                                </v-select>
                                <v-progress-linear
                                    color="green"
                                    class="mb-0"
                                    height="4"
                                    indeterminate
                                    :active="refreshing_scheme_lookup_list"
                                ></v-progress-linear>
                            </v-col> -->
                            <v-col cols="12" sm="3" >
                                <v-text-field
                                    v-model="policy_copy.status"
                                    label="Status"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-text-field
                                    :value="selected_product_premium"
                                    prefix="ZAR"
                                    color="secondary"
                                    label="Product Premium"
                                    hint="The premium of the selected product"
                                    persistent-hint
                                    type="number"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <!-- PAYMENT DETAILS -->
                    <v-card-text v-if="policy_copy.product !== null">
                        <v-row style="background:#f6c20a73; padding-bottom:10px">
                            <v-col cols="12" sm="12" >
                                <h2>Policy Payment Details</h2>
                                <!-- <hr style="height:1px !important; background-color:grey !important; border: none !important;"> -->
                            </v-col>
                            <v-col cols="12" sm="2">
                                <v-text-field
                                    :value="selected_product_premium"
                                    prefix="ZAR"
                                    color="secondary"
                                    label="Montly Premium"
                                    hint="The premium of the selected product"
                                    persistent-hint
                                    type="number"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="5">
                                <v-text-field
                                    v-model="policy_copy.payment_date"
                                    :rules="elected_payment_date_rules"
                                    ref="elected_payment_date_field"
                                    color="secondary"
                                    label="Elected Payment Date"
                                    hint="Between 1 and 31"
                                    persistent-hint
                                    type="number"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="5">
                                <v-select
                                    v-if="parseFloat(policy_copy.payment_information.premium_payer.percentage_amount) > 0"
                                    v-model="policy_copy.payment_information.premium_payer.payment_method"
                                    :items="payment_methods"
                                    label="Payment Method"
                                    color="secondary"
                                    hint="Premium Payer Payment method"
                                    persistent-hint
                                    required
                                ></v-select>
                                <v-text-field
                                    v-else
                                    value="NOT APPLICABLE"
                                    hint="Member Payment method"
                                    persistent-hint
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            
                        </v-row>
                        <!-- SCHEME PAYMENT OPTION -->
                        <!-- <v-row
                            v-if="parseFloat(policy_details.payment_information.premium_payer.percentage_amount) < 100"
                            style="background:#4490a030; padding-bottom:10px"
                        >
                            <v-col cols="12" sm="4">
                                <v-text-field
                                    value="SCHEME"
                                    label="Payer Type"
                                    color="secondary"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-select
                                    v-if="policy_details.payment_information.scheme.percentage_amount > 0"
                                    v-model="policy_details.payment_information.scheme.payment_method"
                                    :items="payment_methods"
                                    label="Payment Method"
                                    color="secondary"
                                    hint="Scheme Payment method"
                                    persistent-hint
                                    required
                                ></v-select>
                                <v-text-field
                                    v-else
                                    value="NOT APPLICABLE"
                                    hint="Scheme Payment method"
                                    persistent-hint
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" >
                                <v-text-field
                                    v-model="policy_details.payment_information.scheme.percentage_amount"
                                    prefix="%"
                                    color="secondary"
                                    label="Percentage Amount"
                                    type="number"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                        </v-row> -->
                    </v-card-text>
                    <br>
                    <!-- Button Section -->
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            large
                            color="success"
                            :disabled="submitBtnDisabled()"
                            @click="savePolicy()"
                        >
                            <v-icon large>save</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
                <!-- Submission Results Section -->
                <v-card>
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting Policy Details
                        <v-progress-linear
                            indeterminate
                            color="green"
                            class="mb-0"
                            height="5"
                            :active="submitting"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card>
            </div>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../../../utils/api";

import { eventBus } from "../../../../../main";

import { payment_methods } from "../../../../../../config";

import { getProducts, getSchemes } from "../gql/queries";
import { businessProcessProspectPolicy } from "../gql/mutations";

export default {
    components: {},
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        policy_original: {
            type: Object,
            default: null
        },
        policy_copy: {
            type: Object,
            default: null
        },
    },
    data: () => ({
        strokeOptions: {
            size: 16,
            thinning: 0.75,
            smoothing: 0.5,
            streamline: 0.5,
        },
        
        products: [],
        schemes: [],

        status: 'DRAFT',
        server_response_obj: null,
        submitting: false,

        refreshing_product_lookup_list: false,
        refreshing_scheme_lookup_list: false,

        payment_methods: payment_methods,

        elected_payment_date_rules: [
            v => !!v || 'Elected payment date is required',
            v => (parseFloat(v) > 0 && parseFloat(v) < 32) || 'Must be between 1 and 31'
        ],
    }),
    computed: {
        selected_product_premium() {
            if (this.policy_copy.product !== null) {
                const primary_product = this.policy_copy.product.primary_product;
                const underwriter_price = primary_product.underwriter_premium + primary_product.broker_markup;

                const secondary_product = this.policy_copy.product;

                const reseller_markup_percentage = underwriter_price * (secondary_product.markup_percentage/100);
                const reseller_markup_fixed = secondary_product.markup_fixed;
                
                const reseller_price = underwriter_price + reseller_markup_percentage + reseller_markup_fixed;
                return reseller_price;
            }

            return 0;
        }
    },
    methods: {
        closeAddPolicyDialog() {
            eventBus.$emit('CLOSE_EDIT_POLICY_DETAILS_DIALOG', this.server_response_obj);
        },

        submitBtnDisabled() {
            // Avoid null-pointer dereferencing
            let productCopy = { _id: null }
            let productOriginal = { _id: null }
            
            if (this.policy_copy.product !== null) productCopy = this.policy_copy.product;
            if (this.policy_original.product !== null) productOriginal = this.policy_original.product;

            if (
                // top level details
                productOriginal._id === productCopy._id &&
                parseFloat(this.policy_copy.payment_date) === parseFloat(this.policy_original.payment_date) &&
                this.policy_copy.payment_information.premium_payer.payment_method === this.policy_original.payment_information.premium_payer.payment_method
            ) {
                // Nothing has changed
                return true;
            }

            
            // Check correctness of payment date
            if (
                isNaN(parseFloat(this.policy_copy.payment_date)) ||
                parseFloat(this.policy_copy.payment_date) < 1 ||
                parseFloat(this.policy_copy.payment_date) > 31
            ) {
                return true;
            }

            return false;
        },

        resetForm() {
            eventBus.$emit('RESET_POLICY_EDIT_FORM', this.policy_copy);
            
            this.submitting = false;
            this.server_response_obj = null;
        },

        async savePolicy() {
            this.submitting = true;
            
            // Prepare payload
            const extractedPayloadObj = this.extractPolicyPayload();
            
            // Then submit the policy
            try {
                const payloadObj = {
                    OP_TYPE: 'UPDATE',
                    _id: this.policy_copy._id,

                    ...extractedPayloadObj
                };

                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcessProspectPolicy,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.businessProcessProspectPolicy.errors === true) {
                    emitAlert(res.data.businessProcessProspectPolicy.message);
                    this.submitting = false;
                    return;
                }

                this.server_response_obj = res.data.businessProcessProspectPolicy.business_object;

                emitSuccess(res.data.businessProcessProspectPolicy.message);
                this.closeAddPolicyDialog();

                // Reset form
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessProspectPolicy.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },

        extractPolicyPayload() {
            // Prepare payload
            const payloadObj = {
                // Top level info
                product: this.policy_copy.product === null ? null : this.policy_copy.product._id,
                payment_date: parseFloat(this.policy_copy.payment_date),
                payment_information: {
                    premium_payer: {
                        payment_method: this.policy_copy.payment_information.premium_payer.payment_method,
                        percentage_amount: 100,
                    },
                    scheme: {
                        payment_method: 'NOT APPLICABLE',
                        percentage_amount: 0,
                    }
                }
            };

            return payloadObj;
        },

        async refreshProductLookupList() {
            this.refreshing_product_lookup_list = true;

            try {
                // Load Products
                const res = await this.$apollo
                                    .query({
                                        query: getProducts,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'INSURANCE_PRODUCT_SECONDARY',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.submitting = false;
                    return;
                }
                this.products = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
        async refreshSchemesLookupList() {
            this.refreshing_scheme_lookup_list = true;

            try {
                // Load Schemes
                const res = await this.$apollo
                                    .query({
                                        query: getSchemes,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'SCHEME',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                this.refreshing_scheme_lookup_list = false;
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }
                
                this.schemes = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

    },
    async mounted() {
        // Load lookup data
        this.submitting = true;

        try {
            // Load Products
            let res = await this.$apollo
                                    .query({
                                        query: getProducts,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'INSURANCE_PRODUCT_SECONDARY',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.submitting = false;
                return;
            }
            this.products = res.data.getBusinessObject.business_objects;

            // Load Schemes
            res = await this.$apollo
                                    .query({
                                        query: getSchemes,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'SCHEME',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.submitting = false;
                return;
            }
            this.schemes = res.data.getBusinessObject.business_objects;

            this.submitting = false;
        } catch (error) {
            this.submitting = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
        }
    },
    watch: {}
}
</script>
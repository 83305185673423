import gql from 'graphql-tag'

export const createBank = gql`
    mutation lookupProcessBank($input: BankInput) {
        lookupProcessBank(input: $input) {
            errors
            message
            business_object {
                ... on Bank {
                    _id
                    name
                    universal_branch_code
                }
            }
        }
    }
`
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Associate Batch Payment with Bank Feed</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeBankFeedAssociationDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card v-if="batch_payment !== null">
                <v-card-text>
                    <v-row style="background:#eaeaea; padding-bottom:10px">
                        <!-- Batch Amount -->
                        <v-col cols="12" sm="2">
                            <v-text-field
                                :value="formatNumber(batch_payment.total_amount)"
                                color="secondary"
                                label="Batch Payment Amount"
                                prefix="R"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <!-- Bank Feed -->
                        <v-col cols="12" sm="10">
                            <BankFeedSearch
                                :item="bank_feed_search"
                                :unclassified_filter="true"
                            />
                        </v-col>
                    </v-row>
                    <br><br>
                    <v-row
                        v-if="bank_feed_search.bank_feed_item !== null"
                        style="background:#eaeaea; padding-bottom:10px"
                    >
                        <v-col cols="12" sm="12">
                            <h2>Bank Feed Details</h2>
                        </v-col>
                        <!-- Date -->
                        <v-col cols="12" sm="2">
                            <v-text-field
                                :value="bank_feed_search.bank_feed_item.date.split('T')[0]"
                                color="secondary"
                                label="Transaction Date"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <!-- Description -->
                        <v-col cols="12" sm="4">
                            <v-text-field
                                :value="bank_feed_search.bank_feed_item.description"
                                color="secondary"
                                label="Transaction Description"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <!-- Description -->
                        <v-col cols="12" sm="3">
                            <v-text-field
                                :value="bank_feed_search.bank_feed_item.reference"
                                color="secondary"
                                label="Transaction Reference"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <!-- Amount -->
                        <v-col cols="12" sm="2">
                            <v-text-field
                                :value="bank_feed_search.bank_feed_item.amount"
                                color="secondary"
                                label="Amount"
                                prefix="R"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>

                        <!-- Classified -->
                        <v-col
                            cols="12"
                            sm="1"
                            :style="bank_feed_search.bank_feed_item.classification !== null ? 'background-color: rgb(234, 121, 121);' : 'background-color: green'">
                            <v-text-field
                                :value="bank_feed_search.bank_feed_item.classification === null ? 'NO' : 'YES'"
                                color="secondary"
                                label="Classified"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <!-- Progress Section -->
                <v-progress-linear
                    color="green"
                    class="mb-0"
                    height="10"
                    indeterminate
                    :active="submitting"
                ></v-progress-linear>
                <v-list flat v-if="submitting">
                    <v-subheader>Saving Bank Feed Association</v-subheader>
                    <!-- <v-list-item-group v-model="item" color="primary"> -->
                    <v-list-item-group color="primary">
                        <v-list-item
                            v-for="(item, i) in backendActionItems"
                            :key="i"
                        >
                            <v-list-item-icon>
                                <v-icon>done</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ item }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>

                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        large
                        color="success"
                        :disabled="submitBankFeedAssociationBtnDisabled()"
                        @click="saveBankFeedAssociation()"
                    >
                        <v-icon large>save</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import BankFeedSearch from "../components/bank_feed_search.vue";
import { emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";
// import config from "../../../../config";

import converter from "../../../mixins/converter";

import { createFinanceTransaction } from "../gql/mutations";

export default {
    mixins: [converter],
    components: {
        BankFeedSearch,
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        batch_payment: {
            type: Object,
            required: false,
            default: () => {
                return {
                    // name,
                    documents: []
                }
            }
        },
    },
    data: () => ({
        server_resp_obj: null,
        transaction_date_menu: false,
        submitting: false,

        premium_details: {
            transaction_date: null,
            additional_reference: null,
            amount: 0,
        },

        bank_feed_search: {
            bank_feed_item: null
        },

        backendActionItems: [],
    }),
    methods: {
        closeBankFeedAssociationDialog() {
            eventBus.$emit('CLOSE_BANK_FEED_ASSOCIATION_DIALOG', this.server_resp_obj);
        },
        resetForm() {
            this.premium_details = {
                transaction_date: null,
                additional_reference: null,
                amount: 0,
            };
            this.bank_feed_search.bank_feed_item = null;
            this.server_resp_obj = null;
            this.backendActionItems = [];
        },

        submitBankFeedAssociationBtnDisabled() {
            if (this.bank_feed_search.bank_feed_item !== null) {
                if (this.bank_feed_search.bank_feed_item.amount >= 0) {
                    return true;
                }
                if (Math.abs(this.batch_payment.total_amount) !== Math.abs(this.bank_feed_search.bank_feed_item.amount)) {
                    return true;
                }
            }
            
            return false;
        },
        
        async saveBankFeedAssociation() {
            this.submitting = true;
            try {
                let transactionRefAndDescr = null;
                if (this.bank_feed_search.bank_feed_item.reference.trim() === '') {
                    transactionRefAndDescr = this.bank_feed_search.bank_feed_item.description;
                } else {
                    transactionRefAndDescr = this.bank_feed_search.bank_feed_item.description + ' - ' + this.bank_feed_search.bank_feed_item.reference;
                }

                const transactionObj = {
                    OP_TYPE: 'CREATE',
                    
                    transaction_date: this.bank_feed_search.bank_feed_item.date,
                    // from: this.premium_details.payment_method,
                    to: this.batch_payment._id,
                    amount: parseFloat(this.bank_feed_search.bank_feed_item.amount),
                    reference: 'CUSTOMER PAYMENT',
                    additional_reference: transactionRefAndDescr.trim(),

                    bank_feed_id: this.bank_feed_search.bank_feed_item._id,
                    bank_feed_classification: 'Loan Repayment',
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: createFinanceTransaction,
                                                variables: {
                                                    input: transactionObj
                                                },
                                            });

                this.submitting = false;
                if (res.data.finProcessFinanceAccountTransaction.errors === true) {
                    emitAlert(res.data.finProcessFinanceAccountTransaction.message);
                    return;
                }

                emitSuccess(res.data.finProcessFinanceAccountTransaction.message);

                this.server_resp_obj = res.data.finProcessFinanceAccountTransaction.business_object;
                this.closeBankFeedAssociationDialog();

                // Reset form
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.finProcessFinanceAccountTransaction.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
    },
    async mounted() {},
    watch: {
        'batch_payment': function() {
            this.bank_feed_search.bank_feed_item = null;
        }
    }
}
</script>
<style scoped>

</style>
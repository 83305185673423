import axios from 'axios'

axios.defaults.baseURL = '/'
axios.defaults.timeout = 60000

const axiosOverride = axios.create()

async function getSocketUrl () {
  const url = '/socketurl'
  const res = await axiosOverride.get(url)
  return res.data
}

export {
  getSocketUrl
}

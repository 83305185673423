<template>
    <div>
        <v-toolbar
            color="secondary"
            dark
            class="pa-0"
            height="50"
            extension-height="3"
        >
            <v-row>
                <!-- Grid Buttons -->
                <v-col sm="8" style="margin-top:10px">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="refreshDataTable()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh</span>
                    </v-tooltip>
                </v-col>


                <!-- Action Buttons -->
                <v-col sm="4" style="margin-top:10px">
                    <div class="text-right">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="openAddBranchDialog()"
                                    v-on="on"
                                >
                                    <v-icon medium>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Branch</span>
                        </v-tooltip>
                    </div>
                </v-col>
            </v-row>
        </v-toolbar>
        <multipane class="horizontal-panes" layout="horizontal" style="height: 100%" @paneResize="paneResizing">
            <div class="pane" :style="{ height: '50%', maxHeight: '50%'}">
                <v-data-table
                    fixed-header
                    :height="main_tab_height"
                    :headers="branches_headers"
                    :items="branches"
                    item-key="_id"
                    class="elevation-1"
                    :single-select="true"
                    @click:row="selectMainTableRow"
                    item-class="secondary"
                    hide-default-footer
                    :disable-pagination="true"
                    :loading="data_table_loading"
                    loading-text="Loading... Please wait"
                >
                    <!-- Display Formated branch number -->
                    <template v-slot:item.branch_number="{ item }">
                        <td>BR-{{item.branch_number}}</td>
                    </template>
                    <!-- Display Formated start date -->
                    <template v-slot:item.start_date="{ item }">
                        <td>{{item.start_date.split('T')[0]}}</td>
                    </template>
                    <!-- Display Formated start date -->
                    <template v-slot:item.end_date="{ item }">
                        <td v-if="item.end_date !== null">{{item.end_date.split('T')[0]}}</td>
                    </template>
                    <!-- User who captured the details -->
                    <template v-slot:item.captured_by="{ item }" >
                        <td v-if="item.captured_by !== null">
                            <span v-if="item.captured_by.entity !== null">
                                {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                            </span>
                            <span v-else>N/A</span>
                        </td>
                    </template>
                </v-data-table>
            </div>
            <multipane-resizer></multipane-resizer>
            <div class="pane tab-nav" :style="{ height: '30%', maxHeight: '50%', minHeight: '30%' }">
                <v-tabs
                    v-model="sub_tabs"
                    color="transparent"
                    class="tab-nav"
                    hide-slider
                    height="35px"
                    grow
                >
                    <!-- Address Sub Tab -->
                    <template>
                        <v-tab key="address_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Address
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>
                    <!-- Management Sub Tab -->
                    <template>
                        <v-tab key="management_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Management
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>
                </v-tabs>
                <v-tabs-items v-model="sub_tabs">
                    <!-- Sub-Tab Toolbar -->
                    <v-toolbar
                        color="secondary"
                        dark
                        class="pa-0"
                        height="50"
                        extension-height="3"
                    >
                        <!-- Buttons for the ADDRESSES sub-tab -->
                        <!-- <v-tooltip bottom v-if="sub_tabs === 0 && selectedBranch !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddAddressDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Branch Address</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 0  && selectedAddress !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openDeleteAddressDialog()"
                                    v-on="on"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete Branch Address</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 0  && selectedAddress !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openEditAddressDialog()"
                                    v-on="on"
                                >
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <span>Edit Branch Address</span>
                        </v-tooltip> -->
                    </v-toolbar>
                    <!-- Address Sub-Tab Content -->
                    <v-tab-item key="address_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="address_details_headers"
                            :items="branch_addresses"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectAddressTabRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Display create date -->
                            <template v-slot:[`item.created`]="{ item }">
                                <td>{{item.created !== null ? item.created.split('T')[0] : ''}}</td>
                            </template>
                            <!-- User who captured the details -->
                            <template v-slot:[`item.captured_by`]="{ item }" >
                                <td v-if="item.captured_by !== null && item.captured_by !== undefined">
                                    {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Management Details Sub-Tab Content -->
                    <v-tab-item key="management_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="branch_management_headers"
                            :items="branch_management"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectContactsTabRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Display Formated agent -->
                            <template v-slot:item.agent="{ item }">
                                <td>
                                    {{
                                        item.agent !== null && item.agent !== undefined ? 
                                        item.agent.entity.name + ' ' + item.agent.entity.surname + ' (' + item.agent.agent_number + ')':
                                        ''
                                    }}
                                </td>
                            </template>
                            <template v-slot:[`item.created`]="{ item }">
                                <td>{{item.created !== null ? item.created.split('T')[0] : ''}}</td>
                            </template>
                            <!-- User who captured the details -->
                            <template v-slot:[`item.captured_by`]="{ item }" >
                                <td v-if="item.captured_by !== null">
                                    {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                                </td>
                            </template>
                            <!-- Display Formated start date -->
                            <template v-slot:item.start_date="{ item }">
                                <td>{{item.start_date.split('T')[0]}}</td>
                            </template>
                            <!-- Display Formated start date -->
                            <template v-slot:item.end_date="{ item }">
                                <td v-if="item.end_date !== null">{{item.end_date.split('T')[0]}}</td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </multipane>
        <AddBranchDialog
            :dialog="open_add_branch_dialog"
        />

        <!-- Address -->
        <AddAddressesDialog
            v-if="selectedBranch !== null"
            :dialog="open_add_address_dialog"
            :entity_id="selectedBranch._id"
        />
        <EditAddressDialog
            v-if="selectedBranch !== null && selectedAddress !== null"
            :dialog="open_edit_address_dialog"
            :entity_id="selectedBranch._id"
            :address_original="selectedAddress"
            :address_copy="selectedAddressCopy"
        />
        <DeleteAddressesDialog
            v-if="selectedBranch !== null"
            :dialog="open_delete_address_dialog"
            :entity="selectedBranch"
            :address="selectedAddress"
        />
        <!-- Leadership -->
        <AddContactsDialog
            v-if="selectedBranch !== null"
            :dialog="open_add_contact_dialog"
            :entity_id="selectedBranch._id"
        />
        <DeleteContactsDialog
            v-if="selectedContact !== null"
            :dialog="open_delete_contact_dialog"
            :entity="selectedBranch"
            :contact="selectedContact"
        />
        <EditContactDialog
            v-if="selectedBranch !== null && selectedContact !== null"
            :dialog="open_edit_contact_dialog"
            :entity_id="selectedBranch._id"
            :contact_original="selectedContact"
            :contact_copy="selectedContactCopy"
        />
    </div>
</template>
<script>
import { Multipane, MultipaneResizer } from 'vue-multipane';
import { emitAlert } from '../../utils/api';
import AddBranchDialog from './dialogs/add_branch';

import AddAddressesDialog from './dialogs/add_member_addresses';
import EditAddressDialog from './dialogs/edit_member_address';
import DeleteAddressesDialog from './dialogs/delete_member_address';

import AddContactsDialog from './dialogs/add_member_contacts';
import EditContactDialog from './dialogs/edit_member_contact';
import DeleteContactsDialog from './dialogs/delete_member_contact';


import { eventBus } from "../../main";

import { getBranches } from "./gql/queries";

export default {
    components: {
        Multipane,
        MultipaneResizer,
        AddBranchDialog,

        AddAddressesDialog,
        EditAddressDialog,
        DeleteAddressesDialog,

        AddContactsDialog,
        EditContactDialog,
        DeleteContactsDialog,
    },

    data: () => ({
        sub_tabs: null,
        main_tab_height: '250px',
        sub_tab_height:'180',

        selectedBranch: null,
        selectedRow: null,
        data_table_loading: false,

        branches_headers: [
            { text: 'Branch Number', value: 'branch_number' },
            { text: 'Name', value: 'name' },
            { text: 'Start Date', value: 'start_date' },
            { text: 'End Date', value: 'end_date' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        address_details_headers: [
            { text: 'Address Type', value: 'type' },
            { text: 'Line 1', value: 'line1' },
            { text: 'Line 2', value: 'line2' },
            { text: 'Line 3', value: 'line3' },
            { text: 'Postal Code', value: 'line4' },
            { text: 'Date Captured', value: 'created' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        branch_management_headers: [
            { text: 'Agent', value: 'agent' },
            { text: 'Role', value: 'role' },
            { text: 'Start Date', value: 'start_date' },
            { text: 'End Date', value: 'end_date' },
            { text: 'Date Captured', value: 'created' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        
        // Table data models
        branches: [],
        branch_addresses: [],
        branch_management: [],

        // Dialogs
        open_add_branch_dialog: false,
        open_add_address_dialog: false,
        open_edit_address_dialog: false,
        open_delete_address_dialog: false,
        open_add_contact_dialog: false,
        open_edit_contact_dialog: false,
        open_delete_contact_dialog: false,


        // addresses sub-tab data-table row
        selectedAddress: null,
        selectedAddressRow: null,
        selectedAddressCopy: {
            type:  null,
            line1: null,
            line2: null,
            line3: null,
            line4: null
        },

        selectedContact: null,
        selectedContactRow: null,
        selectedContactCopy: {
            type:  null,
            primary: null,
            details: null
        },
    }),

    methods: {
        //-------------------- OPEN DIALOGS ---------------------//
        openAddBranchDialog() {
            // Open onboarding dialog
            this.open_add_branch_dialog = true;
        },

        openAddAddressDialog() {
            this.open_add_address_dialog = true;
        },
        openDeleteAddressDialog() {
            this.open_delete_address_dialog = true;
        },
        openEditAddressDialog() {
            this.selectedAddressCopy = Object.assign({}, this.selectedAddress);
            this.open_edit_address_dialog = true;
        },

        openEditContactDialog() {
            this.selectedContactCopy = Object.assign({}, this.selectedContact);
            this.open_edit_contact_dialog = true;
        },
        resetContactEditForm(updatedContact) {
            if (
                updatedContact.type !== this.selectedContact.type ||
                updatedContact.primary !== this.selectedContact.primary ||
                updatedContact.details !== this.selectedContact.details
            ) {
                this.selectedContactCopy = Object.assign({}, this.selectedContact);
            }
        },

        //------------------- CLOSE DIALOGS---------------------//
        updateUITableModel(new_branch) {
            if (new_branch !== null) {
                const obj_index = this.branches.map(obj => { return obj._id; }).indexOf(new_branch._id)
                this.branches[obj_index] = new_branch;

                 // Deep copy branches for refreshing main data table
                const temp_arr = Array.from(this.branches);
                this.branches = temp_arr;

                // Also update the addresses sub-tab
                this.branch_addresses = new_branch.addresses.filter(addr => { return !addr.deleted });                
                this.selectedBranch = new_branch;
            }
        },
        closeAddBranchDialog(branchObj) {
            if (branchObj !== null) {
                this.branches.push(branchObj);
            }

            this.open_add_branch_dialog = false;
        },
        unselectSubTabRows() {
            // Unselect addresses sub-tab row
            this.selectedAddress = null;
            if (this.selectedAddressRow !== null) {
                this.selectedAddressRow.select(false);
            }

            // Unselect contacts sub-tab row
            this.selectedContact = null;
            if (this.selectedContactRow !== null) {
                this.selectedContactRow.select(false);
            }
        },
        selectMainTableRow(item, item_metadata) {
            this.unselectSubTabRows();

            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);

                this.selectedBranch = item;

                this.branch_addresses = item.addresses.filter(doc => { return !doc.deleted });
                this.branch_management = item.management;
            } else {
                this.selectedBranch = null;
                // Unighlight selected row
                item_metadata.select(false);

                this.branch_addresses = [];
                this.branch_management = [];
            }
        },
        selectAddressTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedAddress = item;
                this.selectedAddressRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedAddress = null;
                this.selectedAddressRow = null;
            }
        },
        selectContactsTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedContact = item;
                this.selectedContactRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedContact = null;
                this.selectedContactRow = null;
            }
        },
        async refreshDataTable() {
            this.data_table_loading = true;
            this.branches = [];
            
            try {
                const res = await this.$apollo
                                    .query({
                                        query: getBranches,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'BRANCH',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }

                this.data_table_loading = false;
                this.branches = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        paneResizing(pane, container, size) {
            const size_int = parseInt(size);

            this.main_tab_height = size;
            const max_screen_height = window.innerHeight - 300;

            const sub_tab_percentage = 1 - (size_int/max_screen_height);
            this.sub_tab_height = sub_tab_percentage * max_screen_height;
        },
    },

    async mounted() {
        eventBus.$on('CLOSE_ADD_BRANCH_DIALOG', this.closeAddBranchDialog);

        /**
         * The following logic is for manually triggering a pane-divider movement
         * so as to get the windows to properly resize
         */
        this.paneResizing(null, null, 250);

        try {
            this.data_table_loading = true;
            const res = await this.$apollo
                                    .query({
                                        query: getBranches,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'BRANCH',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.data_table_loading = false;
                return;
            }

            this.data_table_loading = false;
            this.branches = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.data_table_loading = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
        }
    },

    watch: {
        
    }
}
</script>
<style scoped>
/* Overriding of textbox min height */
 .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
}
</style>
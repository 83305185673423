import axios from 'axios';
// import { eventBus } from '../main';

axios.defaults.baseURL = '/api';
axios.defaults.timeout = 60000;

// error overides
const axiosOverride = axios.create();

function loginStage1(authData) {
  const url = '/v1/auth/login/stage1';
  return axiosOverride.post(url, authData)
    .then(res => res.data);
}

function registerUser(username, password) {
  const url = '/v1/auth/login/registration/';
  return axiosOverride.post(url, {
    username: username,
    password: password
  }).then(res => res.data);
}

function userLogin(username, password, context) {
  const url = '/v1/auth/login/';
  return axiosOverride.post(url, {
    username: username,
    password: password,
    context: context
  }).then(res => res.data);
}

function systemInitialized() {
  const url = '/v1/auth/system/init/';
  return axiosOverride.get(url).then(res => res.data);
}

function initializeSystem(systemInitPayloadObj) {
  const url = '/v1/auth/system/init/';
  return axiosOverride.post(url, systemInitPayloadObj).then(res => res.data);
}

export {
  loginStage1,
  registerUser,
  userLogin,

  systemInitialized,
  initializeSystem
};

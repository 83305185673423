<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Delete Business Partner Address</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeDeleteBPAddressDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <div v-if="address !== null">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-if="address !== null"
                                    v-model="address.type"
                                    label="Address Type"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-if="address !== null"
                                    :value="address.created.split('T')[0]"
                                    label="Date Captured"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-text-field
                                    v-if="address !== null"
                                    :value="address.line1"
                                    label="Line 1"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-text-field
                                    v-if="address !== null"
                                    :value="address.line2"
                                    label="Line 2"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-text-field
                                    v-if="address !== null"
                                    :value="address.line3"
                                    label="Line 3"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-text-field
                                    v-if="address !== null"
                                    :value="address.line4"
                                    label="Line 4"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12">
                                <v-textarea
                                    name="Delete Reason"
                                    color="secondary"
                                    filled
                                    label="Delete Reason"
                                    auto-grow
                                    v-model="delete_reason"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </div>
                    <v-card-text class="text-center" v-if="submitting">
                        Deleting Address
                        <v-progress-linear
                            indeterminate
                            :active="submitting"
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submitDeleteAddressBtnDisabled()"
                                @click="submitDeleteAction()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Submit Delete Action</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";

import { entityProcessBusinessPartner } from "../gql/mutations";

export default {
    components: {},
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        business_partner: {
            type: Object,
            required: false,
            default: () => {
                return { _id: null }
            }
        },
        address: {
            type: Object,
            required: false,
            default: () => {
                return { _id: null }
            }
        }
    },
    data: () => ({
        server_resp_obj: null,
        submitting: false,
        delete_reason: null
    }),
    methods: {
        closeDeleteBPAddressDialog() {
            eventBus.$emit('CLOSE_DELETE_BP_ADDRESS_DIALOG', this.server_resp_obj);
        },
        resetForm() {
            this.delete_reason = null;
            this.server_resp_obj = null;
        },

        submitDeleteAddressBtnDisabled() {
            if (this.delete_reason === null || this.delete_reason === undefined) {
                return true;
            }

            if (typeof(this.delete_reason) === 'string') {
                if (this.delete_reason.trim() === '') {
                    this.delete_reason = null;
                    return true;
                }
            }

            return false;
        },
        
        async submitDeleteAction() {
            if (this.delete_reason !== null && this.delete_reason !== undefined) {
                try {
                    this.submitting = true;
                    const addrObj = {
                        _id: this.address._id,
                        delete_reason: this.delete_reason,

                        // placeholder field
                        type: ''
                    };

                    const payloadObj = {
                        OP_TYPE: 'REMOVE_ADDRESS',
                        _id: this.business_partner._id,

                        addresses: [addrObj]
                    };

                    const res = await this.$apollo
                                                .mutate({
                                                    mutation: entityProcessBusinessPartner,
                                                    variables: {
                                                        input: payloadObj
                                                    },
                                                });
                    if (res.data.entityProcessBusinessPartner.errors === true) {
                        emitAlert(res.data.entityProcessBusinessPartner.message);
                        this.submitting = false;
                        return;
                    }

                    this.submitting = false;
                    emitSuccess(res.data.entityProcessBusinessPartner.message);

                    this.server_resp_obj = res.data.entityProcessBusinessPartner.business_object;
                    this.closeDeleteBPAddressDialog();

                    // Reset Form
                    this.resetForm();
                } catch (error) {
                    this.submitting = false;
                    console.log(error);
                    if (error !== undefined) {
                        emitAlert(error.data.entityProcessBusinessPartner.message);
                    } else {
                        emitAlert('The server has taken too long in responding to your request');
                    }
                    
                    return;
                }
            } else {
                emitAlert('Please capture the reason for deleting the address.');
            }
            
        }
    },
    mounted() {
        
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Add Contact Person</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row
                        v-for="contact in contact_people"
                        :key="contact.id"
                        style="background:#eaeaea; padding-bottom:10px"
                    >
                        <v-col cols="12" sm="2">
                            <v-select
                                v-model="contact.role"
                                :items="['DIRECTOR', 'CEO', 'CFO', 'CTO']"
                                label="Role / Position"
                                color="secondary"
                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field
                                v-model="contact.name"
                                :rules="name_rules"
                                :ref="'contact_person_name_field_'+contact.id"
                                color="secondary"
                                label="Name"
                                autocomplete="some-random-text"
                                type="text"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                v-model="contact.surname"
                                :rules="surname_rules"
                                :ref="'contact_person_surname_field_'+contact.id"
                                color="secondary"
                                label="Surname"
                                autocomplete="some-random-text"
                                type="text"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-select
                                v-model="contact.marital_status"
                                color="secondary"
                                :items="marital_statuses"
                                label="Marital Status"
                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                v-model="contact.phone"
                                :rules="phone_rules"
                                :ref="'contact_person_phone_field_'+contact.id"
                                color="secondary"
                                label="Phone"
                                hint="e.g. 0721234567"
                                persistent-hint
                                autocomplete="some-random-text"
                                type="number"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-text-field
                                v-model="contact.email"
                                :rules="email_rules"
                                :ref="'contact_person_email_field_'+contact.id"
                                color="secondary"
                                label="Email"
                                autocomplete="some-random-text"
                                type="text"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-select
                                v-model="contact.system_access_role"
                                :items="user_roles"
                                color="secondary"
                                item-value="_id"
                                item-text="name"
                                label="System Access Role"
                                :disabled="refreshing_user_roles_list"

                                hint="You can click the refresh icon next to the field if the system access roles list is incomplete"
                                persistent-hint

                                required
                            >
                                <!-- Format prepend inner icon -->
                                <template v-slot:prepend-inner>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon
                                                v-on="on"
                                                color="success"
                                                @click="refreshUserRolesList()"
                                            >
                                                mdi-refresh
                                            </v-icon>
                                        </template>
                                        <span>Refresh User Roles List</span>
                                    </v-tooltip>
                                </template>
                            </v-select>
                            <v-progress-linear
                                color="green"
                                class="mb-0"
                                height="4"
                                indeterminate
                                :active="refreshing_user_roles_list"
                            ></v-progress-linear>
                        </v-col>
                        
                        <v-row style="background:#b2d9c7" no-gutters>
                            <v-col cols="12" sm="3" >
                                <v-text-field
                                    v-model="contact.id_number_field"
                                    :rules="user_id_number_rules"
                                    :ref="'contact_person_id_number_field_'+contact.id"
                                    label="ID Number"
                                    autocomplete="some-random-text"
                                    type="number"
                                    color="secondary"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="2">
                                <v-text-field
                                    v-model="contact.id_number_field_valid"
                                    label="ID Number Valid"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="2">
                                <v-select
                                    v-model="contact.birth_date_century_field"
                                    :items="['19', '20']"
                                    label="Birth Century"
                                    disabled
                                    required
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="2">
                                <v-text-field
                                    v-model="contact.partial_birth_date_field"
                                    label="Birth Date"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" >
                                <v-select
                                    v-model="contact.gender"
                                    :items="entity_gender"
                                    label="Gender"
                                    disabled
                                    required
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-row>
                    <!-- Buttons for Adding/Removing Contact -->
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting Business Partner Contact
                        <v-progress-linear
                            indeterminate
                            :active="submitting"
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submitBtnDisabled()"
                                @click="submitData()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Save Business Partner Contact Details</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";
import { idValid, extractBirthDate, extractGender } from "../../../utils/id_number_check";

import { getUserRoles } from "../gql/queries";
import { entityProcessBusinessPartner } from "../gql/mutations";

import {
    marital_statuses,
    entity_gender,
} from "../../../../config";

export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        business_partner_id: {
            type: String,
            default: null
        },
    },
    data: () => ({
        server_response_obj: null,
        submitting: false,
        
        contact_people: [
            {
                id: 1,
                role: null,
                name: '',
                surname: '',
                marital_status: null,
                phone: '',
                email: '',
                system_access_role: null,
                gender: null,

                id_number_field: '',
                id_number_field_valid: 'NO',
                birth_date_century_field: null,
                partial_birth_date_field: null,
            }
        ],

        user_roles: [],
        refreshing_user_roles_list: false,

        marital_statuses: marital_statuses,
        entity_gender: entity_gender,

        name_rules: [
            v => !!v || 'Name is required',
            v => v.trim().length >= 2 || 'Min 2 characters'
        ],
        surname_rules: [
            v => !!v || 'Surname is required',
            v => v.trim().length >= 2 || 'Min 2 characters'
        ],
        user_id_number_rules: [
            v => !!v || 'ID number is required',
            v => v.trim().length === 13 || '13 characters required'
        ],
        phone_rules: [
            v => !!v || 'Phone is required',
            v => v.trim().length >= 10 || 'Min 10 characters'
        ],
        email_rules: [
            v => !!v || 'Email is required',
            v => {
                // eslint-disable-next-line
                const emailformat = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (v.match(emailformat)) {
                    return true;
                } else {
                    return 'Invalid Email';
                }
            }
        ],
    }),
    methods: {
        closeDialog() {
            eventBus.$emit('CLOSE_ADD_BP_CONTACTS_DIALOG', this.server_response_obj);
        },

        resetForm() {
            this.submitting = false;
            this.server_response_obj = null;
            this.contact_people = [
                {
                    id: 1,
                    role: null,
                    name: '',
                    surname: '',
                    marital_status: null,
                    phone: '',
                    email: '',
                    system_access_role: null,
                    gender: null,

                    id_number_field: '',
                    id_number_field_valid: 'NO',
                    birth_date_century_field: null,
                    partial_birth_date_field: null,
                }
            ];

            // For some reason, $refs in a v-for are stored as arrays, hence the following array dereferencing
            this.$refs.contact_person_name_field_1[0].resetValidation();
            this.$refs.contact_person_surname_field_1[0].resetValidation();
            this.$refs.contact_person_phone_field_1[0].resetValidation();
            this.$refs.contact_person_email_field_1[0].resetValidation();
            this.$refs.contact_person_id_number_field_1[0].resetValidation();
        },

        submitBtnDisabled() {
            // If all fields are filled-in, then make sure all id numbers are valid
            let invalidIdExists = false;
            this.contact_people.forEach(contact_person => {
                // Check if valid
                if (contact_person.id_number_field.length === 13) {
                    const valid = idValid(contact_person.id_number_field);

                    if (valid) {
                        contact_person.id_number_field_valid = 'YES';
                    } else {
                        contact_person.id_number_field_valid = 'NO';
                        invalidIdExists = true;
                    }

                    const birthDate = extractBirthDate(contact_person.id_number_field);
                    
                    contact_person.partial_birth_date_field = birthDate.partial_date_string;
                    contact_person.birth_date_century_field = birthDate.century_string;
                    
                    contact_person.gender = extractGender(contact_person.id_number_field);
                } else {
                    contact_person.id_number_field_valid = 'NO';
                    invalidIdExists = true;
                }

                // Extract Birth date
                if (contact_person.id_number_field.length === 6) {
                    const birthDate = extractBirthDate(contact_person.id_number_field);
                    contact_person.partial_birth_date_field = birthDate.partial_date_string;
                    contact_person.birth_date_century_field = birthDate.century_string;
                }

                if (contact_person.id_number_field.length < 6) {
                    contact_person.partial_birth_date_field = null;
                    contact_person.birth_date_century_field = null;
                }

                // extract gender
                if (contact_person.id_number_field.length === 10) {
                    contact_person.gender = extractGender(contact_person.id_number_field);
                }

                if (contact_person.id_number_field.length < 10) {
                    contact_person.gender = null;
                }
            });

            if (invalidIdExists) {
                return true;
            }

            const invalidContactPeople = this.contact_people.filter(contact_person => {
                return (
                    contact_person.role === null ||

                    contact_person.name.trim() === '' ||
                    contact_person.name.trim().length < 2 ||

                    contact_person.surname.trim() === '' ||
                    contact_person.surname.trim().length < 2 ||

                    contact_person.marital_status === null ||

                    contact_person.phone.trim() === '' ||
                    contact_person.phone.trim().length < 10 ||

                    contact_person.email.trim() === '' ||
                    contact_person.system_access_role === null ||
                    contact_person.id_number_field.trim() === '' ||
                    contact_person.id_number_field.trim().length !== 13
                );
            });

            // Make sure all fields are field-in
            if (invalidContactPeople.length > 0) {
                return true;
            }

            return false;
        },

        async submitData() {
            const contact_people = [];
            const validContactPeople = this.contact_people.filter(contact_person => {
                return (
                    contact_person.role !== null &&

                    contact_person.name.trim() !== '' &&
                    contact_person.name.trim().length >= 2 &&

                    contact_person.surname.trim() !== '' &&
                    contact_person.surname.trim().length >= 2 &&

                    contact_person.marital_status !== null &&

                    contact_person.phone.trim() !== '' &&
                    contact_person.phone.trim().length >= 10 &&

                    contact_person.email.trim() !== '' &&
                    contact_person.system_access_role !== null &&
                    contact_person.id_number_field.trim() !== '' &&
                    contact_person.id_number_field.trim().length === 13 &&

                    contact_person.id_number_field_valid.trim() === 'YES'
                );
            });

            if (validContactPeople.length > 0) {
                validContactPeople.forEach(cont => {
                    contact_people.push({
                        role: cont.role,
                        name: cont.name,
                        surname: cont.surname,
                        gender: cont.gender,
                        date_of_birth: cont.birth_date_century_field + cont.partial_birth_date_field,
                        id_number: cont.id_number_field,
                        phone: cont.phone,
                        email: cont.email,
                        marital_status: cont.marital_status,
                        system_access_role: cont.system_access_role,
                    });
                });
            }

            if (validContactPeople.length === 0) {
                emitAlert('Please capture valid contact details');
                return;
            }

            try {
                this.submitting = true;

                const payloadObj = {
                    OP_TYPE: 'ADD_CONTACT_PERSON',
                    _id: this.business_partner_id,

                    contact_people: contact_people
                };

                const res = await this.$apollo
                                            .mutate({
                                                mutation: entityProcessBusinessPartner,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.entityProcessBusinessPartner.errors === true) {
                    emitAlert(res.data.entityProcessBusinessPartner.message);
                    this.submitting = false;
                    return;
                }
                
                this.submitting = false;
                this.server_response_obj = res.data.entityProcessBusinessPartner.business_object;

                this.closeDialog();
                emitSuccess(res.data.entityProcessBusinessPartner.message);

                // Reset fields
                this.server_response_obj = null;
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.entityProcessBusinessPartner.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
        async refreshUserRolesList() {
            this.refreshing_user_roles_list = true;
            try {
                // Load user roles list
                const res = await this.$apollo
                                    .query({
                                        query: getUserRoles,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'USER_ROLE',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                this.refreshing_user_roles_list = false;
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }

                this.user_roles = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.refreshing_user_roles_list = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
    },
    async mounted() {
        this.submitting = true;
        try {
            // Get system roles lookup list
            const res = await this.$apollo
                                    .query({
                                        query: getUserRoles,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'USER_ROLE',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.submitting = false;
                return;
            }
            this.user_roles = res.data.getBusinessObject.business_objects;

            // Reset fields
            this.submitting = false;
        } catch (error) {
            this.submitting = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
            
            return;
        }
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
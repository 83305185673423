<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Loan Quote Search</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeMemberSearchDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <!-- Loan Number -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.quote_number.search"
                                color="success"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-text-field
                                v-model="search_fields.quote_number.value"
                                label="Quote Number"
                                prefix="L"
                                type="number"
                                color="secondary"
                                required
                                :disabled="!search_fields.quote_number.search"
                            ></v-text-field>
                        </v-col>

                        <!-- Loan Status -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.status.search"
                                color="success"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-select
                                v-model="search_fields.status.value"
                                :items="[
                                    'DRAFT',
                                    'PENDING_APPROVAL',
                                    'PROCESSING-APPROVAL',
                                    'DECLINED',
                                    'APPROVED - PENDING PAYOUT',
                                    'APPROVED - PAID OUT',
                                ]"
                                label="Loan Status"
                                color="secondary"
                                :disabled="!search_fields.status.search"
                                required
                            ></v-select>
                        </v-col>

                        <!-- Entity Id Number -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.loan_entity_id_number.search"
                                color="success"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-text-field
                                v-model="search_fields.loan_entity_id_number.value"
                                label="Loan Entity Id Number"
                                color="secondary"
                                required
                                :disabled="!search_fields.loan_entity_id_number.search"
                            ></v-text-field>
                        </v-col>

                        <!-- Elected Payment Date -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.elected_payment_date.search"
                                color="success"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-text-field
                                v-model="search_fields.elected_payment_date.value"
                                type="number"
                                label="Elected Monthly Repayment Date"
                                color="secondary"
                                :disabled="!search_fields.elected_payment_date.search"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Searching Loan Quotes
                        <v-progress-linear
                            indeterminate
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom v-if="search_field_selected">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                @click="submitSearchQuery()"
                            >
                                <v-icon large>search</v-icon>
                            </v-btn>
                        </template>
                        <span>Submit Search Query</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { eventBus } from '../../../../main';
import { emitAlert } from '../../../../utils/api';
import { getLoanQuotes } from "../gql/queries";
export default {
    name: 'SearchLoansDialog',
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        search_fields: {
            quote_number: {
                search: false,
                value: null,
            },
            status: {
                search: false,
                value: null,
            },
            loan_entity_id_number: {
                search: false,
                value: null,
            },
            elected_payment_date: {
                search: false,
                value: null,
            },
        },
        submitting: false
    }),
    methods: {
        closeMemberSearchDialog() {
            eventBus.$emit('CLOSE_LOAN_SEARCH_DIALOG', null);
        },
        resetForm() {
            this.search_fields = {
                quote_number: {
                    search: false,
                    value: null,
                },
                status: {
                    search: false,
                    value: null,
                },
                loan_entity_id_number: {
                    search: false,
                    value: null,
                },
                elected_payment_date: {
                    search: false,
                    value: null,
                },
            };
        },
        async submitSearchQuery() {
            this.submitting = true;

            const searchObj = {};

            Object.keys(this.search_fields).forEach(searchField => {
                if (this.search_fields[ searchField ].search === true) {
                    if (
                        this.search_fields[ searchField ].value !== null &&
                        this.search_fields[ searchField ].value !== undefined
                    ) {
                        searchObj[ searchField ] = this.search_fields[ searchField ].value;
                    }

                    // Make sure we don't send empty strings
                    if (typeof(this.search_fields[ searchField ].value) === 'string') {
                        if (this.search_fields[ searchField ].value.trim().length === 0) {
                            delete searchObj[ searchField ];
                        }
                    }
                }
            });

            if (Object.keys(searchObj).length === 0) {
                this.submitting = false;
                emitAlert('Please capture valid search field values');
                return;
            }
            
            // const searchRes = await searchLoan(searchObj);
            // this.submitting = false;

            try {
            const res = await this.$apollo
                                    .query({
                                        query: getLoanQuotes,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'LOAN_QUOTE',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            },
                                            searchInput: searchObj
                                        },
                                    });
            this.submitting = false;
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                return;
            }

            eventBus.$emit('CLOSE_LOAN_SEARCH_DIALOG', res.data.getBusinessObject.business_objects);
            // this.loans = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.submitting = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
        }

            // eventBus.$emit('CLOSE_LOAN_SEARCH_DIALOG', searchRes.loans);
        },
    },
    mounted() {
        
    },
    computed: {
        search_field_selected() {
            return (
                this.search_fields.quote_number.search ||
                this.search_fields.status.search ||
                this.search_fields.loan_entity_id_number.search ||
                this.search_fields.elected_payment_date.search
            )
        }
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Add New Primary Product</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeAddProductDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-stepper v-model="e1">
                <v-stepper-header>
                    <v-stepper-step :complete="e1 > 1" step="1" color="success">Details</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 2" step="2" color="success">Payout Structure</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="3" color="success">Processing</v-stepper-step>
                </v-stepper-header>
            
                <v-stepper-items>
                    <!-- Basic Details -->
                    <v-stepper-content step="1">
                        <v-card>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            v-model="product_details.name"
                                            :rules="name_rules"
                                            ref="name_field"
                                            color="secondary"
                                            label="Product Name"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" >
                                        <v-select
                                            v-model="product_details.underwriter"
                                            :items="underwriter_select_items"
                                            color="secondary"
                                            item-text="name"
                                            item-value="_id"
                                            label="Underwriter"
                                            :disabled="refreshing_underwriter_list"

                                            hint="You can click the refresh icon next to the field if the underwriter list is incomplete"
                                            persistent-hint

                                            required
                                        >
                                            <!-- Format prepend inner icon -->
                                            <template v-slot:prepend-inner>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon
                                                            v-on="on"
                                                            color="success"
                                                            @click="refreshUnderwriterLookupList()"
                                                        >
                                                            mdi-refresh
                                                        </v-icon>
                                                    </template>
                                                    <span>Refresh Underwriter List</span>
                                                </v-tooltip>
                                            </template>
                                        </v-select>
                                        <v-progress-linear
                                            color="green"
                                            class="mb-0"
                                            height="4"
                                            indeterminate
                                            :active="refreshing_underwriter_list"
                                        ></v-progress-linear>
                                    </v-col>
                                    <v-col cols="12" sm="4" >
                                        <v-text-field
                                            v-model="product_details.underwriter_premium"
                                            prefix="ZAR"
                                            color="secondary"
                                            label="Underwriter Premium"
                                            type="number"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" >
                                        <v-text-field
                                            v-model="product_details.broker_commission"
                                            color="secondary"
                                            label="Broker Commission (%)"
                                            type="number"
                                            prefix="%"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4" >
                                        <v-text-field
                                            v-model="product_details.broker_markup"
                                            prefix="ZAR"
                                            color="secondary"
                                            label="Broker Markup"
                                            type="number"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    fab
                                    color="amber"
                                    :disabled="moveToStep2BtnDisabled()"
                                    @click="moveToStep2()"
                                >
                                    <v-icon large>keyboard_arrow_right</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Payout Structure Section -->
                    <v-stepper-content step="2">
                        <v-card>
                            <v-card-text>
                                <v-row
                                    v-for="payout_structure in payout_structure_details"
                                    :key="payout_structure.id"
                                    style="background:#eaeaea; padding-bottom:10px"
                                >
                                    <v-col cols="12" sm="6">
                                        <v-select
                                            v-model="payout_structure.category"
                                            :items="payout_category_select_items"
                                            color="secondary"
                                            item-text="name"
                                            item-value="_id"
                                            label="Payout Age Category"
                                            
                                            :disabled="refreshing_payout_category_list"

                                            hint="You can click the refresh icon next to the field if the payout-category list is incomplete"
                                            persistent-hint

                                            required
                                        >
                                            <!-- Format prepend inner icon -->
                                            <template v-slot:prepend-inner>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon
                                                            v-on="on"
                                                            color="success"
                                                            @click="refreshPayoutCategoryLookupList()"
                                                            >
                                                                mdi-refresh
                                                            </v-icon>
                                                    </template>
                                                    <span>Refresh Payout Category List</span>
                                                </v-tooltip>
                                            </template>
                                        </v-select>
                                        <v-progress-linear
                                            color="green"
                                            class="mb-0"
                                            height="4"
                                            indeterminate
                                            :active="refreshing_payout_category_list"
                                        ></v-progress-linear>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            v-model="payout_structure.payout_amount"
                                            color="secondary"
                                            prefix="ZAR"
                                            type="number"
                                            label="Payout Amount"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <div style="background:white; height: 10px; width: 100%"></div>
                                </v-row>
                                <!-- Buttons for Adding/Removing Payout Structure -->
                                <v-row>
                                    <v-col sm="10"></v-col>
                                    <v-col sm="2">
                                        <v-tooltip bottom v-if="payout_structure_details.length > 1">
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    class="mx-2"
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="removePayoutStructure()"
                                                >
                                                    <v-icon>remove</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Remove Payout Item</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="addPayoutStructure()"
                                                >
                                                    <v-icon>add</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Add Another Payout Item</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="e1 = 1"
                                >
                                    <v-icon large>keyboard_arrow_left</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    large
                                    color="success"
                                    :disabled="submitBtnDisabled()"
                                    @click="submitProduct()"
                                >
                                    <v-icon large>save</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Submission Results Section -->
                    <v-stepper-content step="3">
                        <v-card>
                            <v-card-text class="text-center">
                                <v-progress-linear
                                    color="green"
                                    class="mb-0"
                                    height="10"
                                    indeterminate
                                    :active="submitting"
                                ></v-progress-linear>
                            </v-card-text>
                            <v-list flat>
                                <v-subheader>Saving Product Info</v-subheader>
                                <!-- <v-list-item-group v-model="item" color="primary"> -->
                                <v-list-item-group color="primary">
                                    <v-list-item
                                        v-for="(item, i) in backendActionItems"
                                        :key="i"
                                    >
                                        <v-list-item-icon>
                                            <v-icon>done</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ item }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    large
                                    color="success"
                                    @click="closeAddProductDialog()"
                                >
                                    <v-icon large>done</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>
    </v-row>
</template>
<script>

import { emitAlert, emitSuccess } from "../../../../../utils/api";
import { eventBus } from "../../../../../main";

import { getUnderwriters, getPayoutAgeCategories } from "../gql/queries";
import { productProcessInsuranceProductPrimary } from "../gql/mutations";

export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        e1: 1,
        
        product_details: {
            name: '',
            underwriter: null,
            underwriter_premium: 0,
            broker_commission: 0,
            broker_markup: 0,
            payout_structure: []
        },
        
        payout_structure_details: [
            {
                id: 1,
                category: null,
                payout_amount: 0,
            }
        ],

        underwriter_select_items: [],
        payout_category_select_items: [],

        server_response_obj: null,
        submitting: false,
        backendActionItems: [],

        name_rules: [
            v => !!v || 'Name is required',
            v => v.length >= 2 || 'Min 2 characters'
        ],

        refreshing_underwriter_list: false,
        refreshing_payout_category_list: false
    }),
    methods: {
        closeAddProductDialog() {
            eventBus.$emit('CLOSE_ADD_PRODUCT_DIALOG', this.server_response_obj);
        },

        //--------------------ADD/REMOVE PAYOUT STRUCTURE--------------------------//
        addPayoutStructure() {
            const size = this.payout_structure_details.length;

            this.payout_structure_details.push({
                id: size + 1,
                category: null,
                payout_amount: 0,
            });
        },
        removePayoutStructure() {
            this.payout_structure_details.pop();
        },

        async refreshUnderwriterLookupList() {
            this.refreshing_underwriter_list = true;
            try {
                // Load Underwriters
                const res = await this.$apollo
                                    .query({
                                        query: getUnderwriters,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'UNDERWRITER',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                this.refreshing_underwriter_list = false;

                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }
                this.underwriter_select_items = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.refreshing_underwriter_list = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
        async refreshPayoutCategoryLookupList() {
            this.refreshing_payout_category_list = true;
            try {
                // Load Underwriters
                const res = await this.$apollo
                                    .query({
                                        query: getPayoutAgeCategories,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'PAYOUT_CATEGORY',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                this.refreshing_payout_category_list = false;
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }

                this.payout_category_select_items = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.refreshing_payout_category_list = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },

        resetForm() {
            this.e1 = 1;

            this.submitting = false;
            this.server_response_obj = null;

            this.backendActionItems = [];
            this.product_details = {
                name: '',
                underwriter: null,
                underwriter_premium: 0,
                broker_commission: 0,
                broker_markup: 0,
                payout_structure: []
            };
            this.payout_structure_details = [
                {
                    id: 1,
                    category: null,
                    payout_amount: 0,
                }
            ];

            this.$refs.name_field.resetValidation();
        },

        moveToStep2BtnDisabled() {
            // NB: We will not enforce broker commission and markup to be greate than zero
            if (
                this.product_details.name.trim().length < 2 ||
                this.product_details.underwriter === null ||
                parseFloat(this.product_details.underwriter_premium) === 0 ||
                isNaN(parseFloat(this.product_details.underwriter_premium))
            ) {
                return true;
            }

            this.product_details.name = this.product_details.name.trim();

            return false;
        },
        moveToStep2() {
            this.e1 = 2;
        },

        submitBtnDisabled() {
            const validPayoutStructures = this.payout_structure_details.filter(detail => {
                return detail.category !== null && parseFloat(detail.payout_amount) > 0
            });

            if (validPayoutStructures.length === 0) {
                return true;
            }

            return false;
        },
        
        async submitProduct() {
            this.product_details.payout_structure = this.payout_structure_details
                                            .filter(detail => {
                                                return detail.category !== null && parseFloat(detail.payout_amount) > 0
                                            })
                                            .map(detail => {
                                                return {
                                                    category: detail.category,
                                                    payout_amount: parseFloat(detail.payout_amount)
                                                }
                                            });

            // Move to next page
            this.e1 = 3;
            this.submitting = true;

            // Now submit the product to the server
            this.backendActionItems.push("Submiting product");

            try {
                this.submitting = true;

                this.product_details.underwriter_premium = parseFloat(this.product_details.underwriter_premium);
                this.product_details.broker_commission = parseFloat(this.product_details.broker_commission);
                this.product_details.broker_markup = parseFloat(this.product_details.broker_markup);

                const payloadObj = {
                    OP_TYPE: 'CREATE',

                    ...this.product_details                    
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: productProcessInsuranceProductPrimary,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.productProcessInsuranceProductPrimary.errors === true) {
                    emitAlert(res.data.productProcessInsuranceProductPrimary.message);
                    this.submitting = false;
                    return;
                }
                
                this.submitting = false;
                this.server_response_obj = res.data.productProcessInsuranceProductPrimary.business_object;

                this.closeAddProductDialog();
                emitSuccess(res.data.productProcessInsuranceProductPrimary.message);

                // Reset fields
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.productProcessInsuranceProductPrimary.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        }
    },
    async mounted() {
        // Load lookup data
        try {
            // Load Underwriters
            let res = await this.$apollo
                                    .query({
                                        query: getUnderwriters,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'UNDERWRITER',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.submitting = false;
                return;
            }
            this.underwriter_select_items = res.data.getBusinessObject.business_objects;
            
            // Load Payout Categories
            res = await this.$apollo
                                    .query({
                                        query: getPayoutAgeCategories,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'PAYOUT_CATEGORY',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.submitting = false;
                return;
            }
            this.payout_category_select_items = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.submitting = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
            
            return;
        }
    },
    watch: {
        
    }
}
</script>
<style scoped>
.error-multi-line {
  white-space: pre-line;
}

</style>
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Delete Scheme Banking Details</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeDeleteSchemeBankingDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <div v-if="banking !== null">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-if="banking !== null"
                                    v-model="banking.type"
                                    label="Banking Detail Type"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-if="banking !== null"
                                    :value="banking.created.split('T')[0]"
                                    label="Date Captured"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-text-field
                                    v-if="banking !== null"
                                    :value="banking.bank.name"
                                    label="Bank Name"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-text-field
                                    v-if="banking !== null"
                                    :value="banking.account_type"
                                    label="Account Type"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-text-field
                                    v-if="banking !== null"
                                    :value="banking.account_number"
                                    label="Account Number"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12">
                                <v-textarea
                                    color="secondary"
                                    name="Delete Reason"
                                    filled
                                    label="Delete Reason"
                                    auto-grow
                                    v-model="delete_reason"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </div>
                    <v-card-text class="text-center" v-if="submitting">
                        Deleting Banking Details
                        <v-progress-linear
                            indeterminate
                            :active="submitting"
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submitBtnDisabled()"
                                @click="submitDeleteAction()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Submit Delete Action</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";

import { entityProcessScheme } from "../gql/mutations";

export default {
    components: {},
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        scheme: {
            type: Object,
            required: false,
            default: () => {
                return { _id: null }
            }
        },
        banking: {
            type: Object,
            required: false,
            default: () => {
                return { _id: null }
            }
        }
    },
    data: () => ({
        server_response_obj: null,
        submitting: false,
        delete_reason: null
    }),
    methods: {
        closeDeleteSchemeBankingDialog() {
            eventBus.$emit('CLOSE_DELETE_SCHEME_BANKING_DIALOG', this.server_response_obj);
        },
        resetForm() {
            this.delete_reason = null;
            this.server_response_obj = null;
        },

        submitBtnDisabled() {
            if (this.delete_reason === null || this.delete_reason === undefined) {
                return true;
            }

            if (typeof(this.delete_reason) === 'string') {
                if (this.delete_reason.trim().length === 0) {
                    this.delete_reason = null;
                    return true;
                }
            }
            return false;
        },
        
        async submitDeleteAction() {
            if (this.delete_reason !== null && this.delete_reason !== undefined) {
                this.submitting = true;
                // const deleteDetails = {
                //     delete_reason: this.delete_reason
                // };

                try {
                    const payload = {
                        OP_TYPE: 'REMOVE_BANKING',
                        _id: this.scheme._id,
                        bank_account_details: [
                            {
                                _id: this.banking._id,
                                delete_reason: this.delete_reason,

                                // Placeholder fields
                                purpose: '',
                            }
                        ]
                    };

                    const res = await this.$apollo
                                        .mutate({
                                            mutation: entityProcessScheme,
                                            variables: {
                                                input: payload
                                            },
                                        });

                    if (res.data.entityProcessScheme.errors === true) {
                        emitAlert(res.data.entityProcessScheme.message);
                        this.submitting = false;
                        return;
                    }
                    
                    this.server_response_obj = res.data.entityProcessScheme.business_object;

                    this.closeDeleteSchemeBankingDialog();
                    emitSuccess(res.data.entityProcessScheme.message);

                    // Reset fields
                    this.server_response_obj = null;
                    this.submitting = false;
                    // reset form
                    this.delete_reason = null;
                } catch (error) {
                    this.submitting = false;
                    console.log(error);
                    if (error !== undefined) {
                        emitAlert(error.data.entityProcessScheme.message);
                    } else {
                        emitAlert('The server has taken too long in responding to your request');
                    }
                    
                    return;
                }
            } else {
                emitAlert('Please capture the reason for deleting the banking details.');
            }
            
        }
    },
    mounted() {
        
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
import gql from 'graphql-tag'

export const createDashboard = gql`
    mutation lookupProcessDashboard($input: DashboardInput) {
        lookupProcessDashboard(input: $input) {
            errors
            message
            business_object {
                ... on Dashboard {
                    __typename
                    _id
                    name
                    group
                    dashboard_number
                    description
                }
            }
        }
    }
`
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Generate Scheme Allocation Form</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn
                    icon
                    @click="closeEditSchemeDialog()"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="scheme.name"
                                color="secondary"
                                label="Scheme Name"
                                autocomplete="some-random-text"
                                type="text"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-autocomplete
                                v-model="fin_calendar_month"
                                :items="fin_calendar_months_lookup"
                                color="secondary"
                                label="Financial Calendar Month"
                                item-value="_id"
                                item-text="name"
                            ></v-autocomplete>
                        </v-col>
                        <!-- <v-col cols="12" sm="6">
                            <v-select
                                v-model="fin_calendar_month"
                                :items="fin_calendar_months_lookup"
                                color="secondary"
                                item-text="name"
                                item-value="_id"
                                label="Financial Calendar Month"
                                :disabled="refreshing_fin_calendar_month_list"

                                hint="You can click the refresh icon next to the field if the financial calendar months list is incomplete"
                                persistent-hint

                                required
                            >
                                <template v-slot:prepend-inner>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon
                                                v-on="on"
                                                color="success"
                                                @click="getFinCalendarMonthsLookupList()"
                                            >
                                                mdi-refresh
                                            </v-icon>
                                        </template>
                                        <span>Refresh Fin Calendar List</span>
                                    </v-tooltip>
                                </template>
                            </v-select>
                            <v-progress-linear
                                color="green"
                                class="mb-0"
                                height="4"
                                indeterminate
                                :active="refreshing_fin_calendar_month_list"
                            ></v-progress-linear>
                        </v-col> -->
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Generating Form...
                        <v-progress-linear
                            indeterminate
                            :active="submitting"
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="success"
                        :disabled="submitBtnDisabled()"
                        @click="openViewSchemeAllocationFormDialog()"
                    >
                        <v-icon large>save</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <DocPreviewDialog
            :dialog="open_scheme_allocation_form_preview_dialog"
            :url="scheme_allocation_form_url"
            :doc_type="scheme_allocation_form_doc_type"
            @CLOSE_DOC_PREVIEW_DIALOG="closeSchemeAllocationFormPreviewDialog()"
        />
    </v-row>
</template>
<script>
import DocPreviewDialog from '../../../components/doc_preview';
import { emitAlert, emitSuccess, generateSchemeAllocationForm } from "../../../utils/api";
import { eventBus } from "../../../main";

import { getFinancialCalendarMonths } from "../gql/queries";
import { entityProcessScheme } from "../gql/mutations";

export default {
    components: {
        DocPreviewDialog,
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        scheme: {
            type: Object,
            required: false,
            default: () => {
                return {
                    marketing_channels: []
                }
            },
        },
    },
    data: () => ({
        submitting: false,
        server_response_obj: null,

        fin_calendar_month: null,
        refreshing_fin_calendar_month_list: false,
        fin_calendar_months_lookup: [],

        open_scheme_allocation_form_preview_dialog: false,
        scheme_allocation_form_doc_type: 'pdf',
        scheme_allocation_form_url: null,
    }),
        
    methods: {
        closeEditSchemeDialog() {
            eventBus.$emit('CLOSE_GENERATE_SCHEME_ALLOCATION_FORM_DIALOG', this.server_response_obj);
        },
        resetForm() {
            this.server_response_obj = null;
            this.fin_calendar_month = null;
        },

        closeSchemeAllocationFormPreviewDialog() {
            this.scheme_allocation_form_url = null;
            this.open_scheme_allocation_form_preview_dialog = false;
        },
        
        submitBtnDisabled() {
            if (this.fin_calendar_month === null) {
                return true;
            }
            return false;
        },

        async openViewSchemeAllocationFormDialog() {
            this.submitting = true;
            try {
                const res = await generateSchemeAllocationForm(this.scheme._id, this.fin_calendar_month);
                const url = window.URL.createObjectURL(new Blob([res], {type: 'application/pdf'}));
                
                this.scheme_allocation_form_url =  url;
                this.open_scheme_allocation_form_preview_dialog = true;
                this.submitting = false;
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.entityProcessScheme.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
        },

        capturingDone() {
            this.closeEditSchemeDialog();
            this.resetForm();
        },
        async submitScheme() {
            this.submitting = true;

            try {
                // Payload payload
                const submitObj = {
                    // name: this.scheme_copy.name,
                };

                const payloadObj = {
                    OP_TYPE: 'UPDATE',
                    _id: this.scheme._id,

                    ...submitObj
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: entityProcessScheme,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.entityProcessScheme.errors === true) {
                    emitAlert(res.data.entityProcessScheme.message);
                    this.submitting = false;
                    return;
                }
                
                this.server_response_obj = res.data.entityProcessScheme.business_object;
                this.closeEditSchemeDialog();
                emitSuccess(res.data.entityProcessScheme.message);

                // Reset fields
                this.server_response_obj = null;
                this.submitting = false;
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.entityProcessScheme.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
        },
        async getFinCalendarMonthsLookupList() {
            this.refreshing_fin_calendar_month_list = true;
            try {
                // Load Opened Fin Calendar Months
                const res = await this.$apollo
                                    .query({
                                        query: getFinancialCalendarMonths,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'FINANCE_CALENDAR_MONTH',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            },
                                            searchInput: {
                                                // status: 'OPEN'
                                            }
                                        },
                                    });
                this.refreshing_fin_calendar_month_list = false;
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }

                if (res.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }
                this.fin_calendar_months_lookup = res.data.getBusinessObject.business_objects;
                // console.log(this.fin_calendar_months_lookup)

                // const monthsMap = {}; // e.g. {2010:FinMonthObj}
                // for (const finMonth of res.data.getBusinessObject.business_objects) {
                //     if (monthsMap[ finMonth.finance_year.value ] === undefined) {
                //         monthsMap[ finMonth.finance_year.value ] = [];
                //     }
                //     monthsMap[ finMonth.finance_year.value ].push(finMonth);
                // }

                // console.log(monthsMap)
                // const finYearsAsc = (Object.keys(monthsMap)).sort((a, b) => a - b).map(item => { return parseInt(item); });
                
                // // finYearsAsc.forEach(item => parseInt(item))
                // // console.log(finYearsAsc)
                
                // for (let year of finYearsAsc) {
                //     // year = parseInt(year);
                //     const monthsAsc = (monthsMap[year]).sort((a, b) => { return (a.value - b.value); })

                //     // console.log('YEAR', year)
                //     // console.log(monthsAsc)

                //     // this.fin_calendar_months_lookup = this.fin_calendar_months_lookup.concat(monthsAsc);

                //     for (const month of monthsAsc) {
                //         this.fin_calendar_months_lookup.push(month);
                //     }
                // }



                // this.fin_calendar_months_lookup.sort((a, b) => { return (a.finance_year.value - b.finance_year.value); })
            } catch (error) {
                this.refreshing_fin_calendar_month_list = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
    },
    async mounted() {
        await this.getFinCalendarMonthsLookupList();
    },
}
</script>
<style scoped>

</style>
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Add Payout Age Category</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeAddPayoutAgeCatDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <!-- Name -->
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="name"
                                color="secondary"
                                label="Name"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-select
                                v-model="membership_category"
                                :items="membership_categories"
                                color="secondary"
                                label="Membership Category"
                                required
                            ></v-select>
                        </v-col>
                        <!-- Start Age -->
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="start_age"
                                color="secondary"
                                label="Start Age"
                                type="number"
                                required
                            ></v-text-field>
                        </v-col>
                        <!-- End Age -->
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="end_age"
                                color="secondary"
                                label="End Age"
                                type="number"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting Payout Age Category
                        <v-progress-linear
                            indeterminate
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submitPayoutAgeCatTypeBtnDisabled()"
                                @click="submitPayoutAgeCatType()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Submit Details</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../../utils/api";
import { eventBus } from "../../../../main";
import { membership_categories } from "../../../../../config";

import { lookupProcessPayoutCategory } from "../gql/mutations";
export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
    },
    data: () => ({
        name: null,
        membership_category: null,
        start_age: null,
        end_age: null,
        
        server_resp_obj: null,
        submitting: false,

        membership_categories: membership_categories
    }),
    methods: {
        closeAddPayoutAgeCatDialog() {
            eventBus.$emit('CLOSE_ADD_PAYOUT_AGE_CAT_DIALOG', this.server_resp_obj);
        },
        resetForm() {
            this.name = null;
            this.membership_category = null;
            this.start_age = null;
            this.end_age = null;

            this.submitting = false;
            this.server_resp_obj = null;
        },
        submitPayoutAgeCatTypeBtnDisabled() {
            if (this.name === null || this.start_age === null || this.end_age === null || this.membership_category === null) {
                return true;
            }

            if (typeof(this.name) === 'string') {
                if (this.name.trim() === '') {
                    this.name = null;
                    return true;
                }
            }

            if (typeof(this.start_age) === 'string') {
                if (this.start_age.trim() === '') {
                    this.start_age = null;
                    return true;
                }
            }

            if (typeof(this.end_age) === 'string') {
                if (this.end_age.trim() === '') {
                    this.end_age = null;
                    return true;
                }
            }

            return false;
        },
        async submitPayoutAgeCatType() {
            this.submitting = true;

            try {
                const payloadObj = {
                    OP_TYPE: 'CREATE',

                    name: this.name,
                    membership_category: this.membership_category,
                    start_age: parseFloat(this.start_age),
                    end_age: parseFloat(this.end_age),
                };

                const res = await this.$apollo
                                            .mutate({
                                                mutation: lookupProcessPayoutCategory,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.lookupProcessPayoutCategory.errors === true) {
                    emitAlert(res.data.lookupProcessPayoutCategory.message);
                    this.submitting = false;
                    return;
                }

                emitSuccess(res.data.lookupProcessPayoutCategory.message);

                this.server_resp_obj = res.data.lookupProcessPayoutCategory.business_object;
                this.closeAddPayoutAgeCatDialog();

                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.lookupProcessPayoutCategory.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        }
    },
    mounted() {
        
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
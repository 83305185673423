<template>
    <div>
        <v-toolbar
            color="secondary"
            dark
            class="pa-0"
            height="50"
            extension-height="3"
        >
            <v-row>
                <!-- Grid Buttons -->
                <v-col sm="5" style="margin-top:10px">
                    <v-tooltip bottom v-if="SEARCH_MODE === false">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                @click="loadMoreData()"
                                v-on="on"
                            >
                                <v-icon>cloud_download</v-icon>
                            </v-btn>
                        </template>
                        <span>Load More Data</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="openSearchCasesDialog()"
                            >
                                <v-icon>search</v-icon>
                            </v-btn>
                        </template>
                        <span>Search for Cases</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="SEARCH_MODE === false">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="refreshCases()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh</span>
                    </v-tooltip>

                    <!-- <v-tooltip bottom v-if="selectedCase !== null">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="openEditCaseDialog()"
                            >
                                <v-icon>edit</v-icon>
                            </v-btn>
                        </template>
                        <span>Edit Record</span>
                    </v-tooltip> -->
                </v-col>

                <!-- Search Result Indicator -->
                <v-col sm="3">
                    <v-card-actions v-if="SEARCH_MODE === true">
                        <h3>Search Results</h3>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="clearSearchResults()"
                                    v-on="on"
                                >
                                    <v-icon small>search</v-icon>
                                    <v-icon small>close</v-icon>
                                </v-btn>
                            </template>
                            <span>Clear Search Results</span>
                        </v-tooltip>
                    </v-card-actions>
                </v-col>

                <!-- Action Buttons -->
                <v-col sm="4" style="margin-top:10px">
                    <!-- <v-spacer></v-spacer> -->
                    <div class="text-right">
                        
                        <!-------------------------------------------------------------------------------->
                        <!----------------------------------LIFE CYCLE BUTTONS---------------------------->
                        <!-------------------------------------------------------------------------------->

                        <v-tooltip bottom v-if="selectedCase !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="amber primary--text"
                                    small
                                    v-on="on"
                                    @click="openEditCaseDialog()"
                                >
                                    <v-icon>mdi-eye-circle</v-icon> View Case
                                </v-btn>
                            </template>
                            <span>Edit Record</span>
                        </v-tooltip>

                        <!-- GET DEBIT ORDER STATUS -->
                        <!-- <v-tooltip
                            bottom
                            v-if="(
                                selectedCase !== null &&
                                (
                                    selectedCase.status === BOND_STATUSES.ACTIVE &&
                                    selectedCase.repayment_info.payment_method === 'DEBIT ORDER'
                                )
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber primary--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="openViewDebitOrderStatus()"
                                >
                                    <v-icon medium>mdi-cash-100</v-icon>
                                </v-btn>
                            </template>
                            <span>Get Debit Order Status</span>
                        </v-tooltip> -->
                    </div>
                </v-col>
            </v-row>
        </v-toolbar>
        <multipane class="horizontal-panes" layout="horizontal" style="height: 100%" @paneResize="paneResizing">
            <div class="pane" :style="{ height: '50%', maxHeight: '50%'}">
                <v-data-table
                    fixed-header
                    :height="main_tab_height"
                    :headers="case_details_headers"
                    :items="cases"
                    item-key="case_number"
                    class="elevation-1"
                    :single-select="true"
                    @click:row="selectMainTableRow"
                    item-class="secondary"
                    hide-default-footer
                    :loading="data_table_loading"
                    :disable-pagination="true"
                >
                    <!-- Display Formated case number -->
                    <template v-slot:item.case_number="{ item }">
                        <td>CS-{{item.case_number}}</td>
                    </template>
                    <!-- Display Formated next action date -->
                    <template v-slot:item.next_action_date="{ item }">
                        <td>{{item.next_action_date === null ? '' : item.next_action_date.split('T')[0]}}</td>
                    </template>
                    <!-- Display Formated close date -->
                    <template v-slot:item.close_date="{ item }">
                        <td>{{item.close_date === null ? '' : item.close_date.split('T')[0]}}</td>
                    </template>
                    <!-- Display Formated capture date -->
                    <template v-slot:item.created="{ item }">
                        <td>{{item.created.split('T')[0]}}</td>
                    </template>

                    <!-- Display Formated Loan -->
                    <template v-slot:item.loan="{ item }">
                        <td v-if="item.loan !== null">L{{ item.loan.loan_number }}</td>
                        <td v-else></td>
                    </template>
                    <!-- Display Formated Policy -->
                    <template v-slot:item.policy="{ item }">
                        <td v-if="item.policy !== null">{{ item.policy.policy_number }}</td>
                        <td v-else></td>
                    </template>
                    <!-- Display Formated entity -->
                    <template v-slot:item.entity="{ item }">
                        <td>
                            {{
                                item.entity !== null && item.entity !== undefined ? 
                                item.entity.name + ' ' + item.entity.surname + ' (' + item.entity.id_number + ')':
                                ''
                            }}
                        </td>
                    </template>
                    <!-- Display Formated agent -->
                    <template v-slot:item.agent="{ item }">
                        <td>
                            {{
                                item.agent !== null && item.agent !== undefined ? 
                                item.agent.entity.name + ' ' + item.agent.entity.surname + ' (' + item.agent.agent_number + ')':
                                ''
                            }}
                        </td>
                    </template>
                    <!-- Display Formated is_in_disciplinary_action -->
                    <template v-slot:item.is_in_disciplinary_action="{ item }">
                        <td v-if="item.is_in_disciplinary_action === true" style="background:red; color:white; font-weight:bold; font-size:20px; border: 1px solid white">YES</td>
                        <td v-else style="background:green; color:white; font-weight:bold; font-size:20px; border: 1px solid white">NO</td>
                    </template>
                    <!-- Display Formated is_in_litigation -->
                    <template v-slot:item.is_in_litigation="{ item }">
                        <td v-if="item.is_in_litigation === true" style="background:red; color:white; font-weight:bold; font-size:20px; border: 1px solid white">YES</td>
                        <td v-else style="background:green; color:white; font-weight:bold; font-size:20px; border: 1px solid white">NO</td>
                    </template>
                </v-data-table>
            </div>
            <multipane-resizer></multipane-resizer>
            <div class="pane tab-nav" :style="{ height: '30%', maxHeight: '50%', minHeight: '30%' }">
                <v-tabs
                    v-model="sub_tabs"
                    color="transparent"
                    class="tab-nav"
                    hide-slider
                    height="35px"
                    grow
                >
                    <!-- Notes Sub Tab -->
                    <template>
                        <v-tab key="case_notes_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Case Notes
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>
                </v-tabs>
                <v-tabs-items v-model="sub_tabs">
                    <!-- Sub-Tab Toolbar -->
                    <v-toolbar
                        color="secondary"
                        dark
                        class="pa-0"
                        height="50"
                        extension-height="3"
                    >
                        <!-- Buttons for the ADDRESSES sub-tab -->
                        <!-- <v-tooltip bottom v-if="sub_tabs === 0 && selectedMember !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddAddressDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Member Address</span>
                        </v-tooltip> -->
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <!-- Case-Notes Sub-Tab Content -->
                    <v-tab-item key="case_notes_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="case_note_details_headers"
                            :items="case_notes"
                            item-key="_id"
                            :single-select="true"
                            @click:row="tobeDefined"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Display create date -->
                            <template v-slot:item.created="{ item }">
                                <!-- <td>{{item.created !== null ? item.created.split('T')[0] : ''}}</td> -->
                                <td>{{item.created !== null ? item.created.split('T')[0] + '  @  ' + item.created.split('T')[1].substr(0,8) : ''}}</td>
                            </template>
                            <!-- User who captured the details -->
                            <template v-slot:item.captured_by="{ item }" >
                                <td v-if="item.captured_by !== null && item.captured_by !== undefined">
                                    {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </multipane>
        <SearchLoansDialog
            :dialog="open_search_cases_dialog"
        />
        <EditCaseDialog
            v-if="selectedCase !== null"
            :dialog="open_edit_case_dialog"
            :case_original="selectedCase"
            :case_copy="selectedCaseCopy"
        />
    </div>
</template>
<script>
import { Multipane, MultipaneResizer } from 'vue-multipane';
import SearchLoansDialog from './dialogs/search_cases';
import EditCaseDialog from './dialogs/edit_case_details.vue';

import { eventBus } from "../../main";
import { emitAlert } from "../../utils/api";
import { BOND_STATUSES, BUSINESS_PARTNER_TYPES } from "../../../config";

import converter from "../../mixins/converter";
import { getOperationalExcellenceCases } from "./gql/queries";

import { store } from "../../../store";

export default {
    mixins: [converter],
    components: {
        Multipane,
        MultipaneResizer,
        SearchLoansDialog,
        EditCaseDialog,
    },

    computed: {
        isIndoorAgent() {
            const result = store.state.currentUserAccount.roles.filter(role => {
                                                            return (role.role.name.toLowerCase() ===  'indoor agent');
                                                        }).length > 0;

            return result;
        },
        isCentralUnderwriterAdminUser() {
            const result = store.state.currentUserAccount.roles.filter(role => {
                                                            return (
                                                                role.role.level.includes(BUSINESS_PARTNER_TYPES.CENTRAL_UNDERWRITER) &&
                                                                role.role.name.toLowerCase() ===  'admin - central underwriter'
                                                            );
                                                        }).length > 0;

            return result;
        },
        isLegalAdministratorUser() {
            const result = store.state.currentUserAccount.roles.filter(role => {
                                                            return (
                                                                role.role.level.includes(BUSINESS_PARTNER_TYPES.CENTRAL_UNDERWRITER) &&
                                                                role.role.name.toLowerCase() ===  'legal administrator'
                                                            );
                                                        }).length > 0;

            return result;
        },
        isOperationsManagerUser() {
            const result = store.state.currentUserAccount.roles.filter(role => {
                                                            return (
                                                                role.role.level.includes(BUSINESS_PARTNER_TYPES.CENTRAL_UNDERWRITER) &&
                                                                role.role.name.toLowerCase() ===  'operations manager'
                                                            );
                                                        }).length > 0;

            return result;
        },
        isLoanCollectionsAdministratorUser() {
            const result = store.state.currentUserAccount.roles.filter(role => {
                                                            return (
                                                                role.role.level.includes(BUSINESS_PARTNER_TYPES.CENTRAL_UNDERWRITER) &&
                                                                role.role.name.toLowerCase() ===  'loan collections administrator'
                                                            );
                                                        }).length > 0;

            return result;
        },
    },

    data: () => ({
        sub_tabs: null,
        main_tab_height: '350px',
        sub_tab_height:'180',

        // main data-table row
        selectedCase: null,
        selectedRow: null,

        // documents sub-tab data-table row
        selectedDocument: null,
        selectedDocumentRow: null,

        case_details_headers: [
            { text: 'Case Number', value: 'case_number' },
            { text: 'Month', value: 'calendar_month.name' },
            { text: 'Status', value: 'status' },
            { text: 'Category', value: 'category' },
            { text: 'Next Action Date', value: 'next_action_date' },
            { text: 'Close Date', value: 'close_date' },

            { text: 'Loan', value: 'loan' },
            // { text: 'Policy', value: 'policy' },
            // { text: 'Entity', value: 'entity' },
            // { text: 'Agent', value: 'agent' },

            { text: 'Date Created', value: 'created' },

            { text: 'Agent Disciplinary Action', value: 'is_in_disciplinary_action', width: '8%' },
            { text: 'In Litigation', value: 'is_in_litigation', width: '8%' },
        ],
        case_note_details_headers: [
            { text: 'Created', value: 'created' },
            { text: 'Notes', value: 'notes' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        document_details_headers: [
            { text: 'Document Type', value: 'type' },
            { text: 'Name', value: 'name' },
            { text: 'Date Captured', value: 'created' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        transactions_headers: [
            { text: 'Transaction Date', value: 'transaction_date' },
            { text: 'Created', value: 'created' },
            { text: 'Payment Method', value: 'from.name' },
            // { text: 'Financial Calendar Month', value: 'fin_calendar_month.name' },
            { text: 'System Reference', value: 'reference' },
            { text: 'Customer Reference', value: 'additional_reference' },
            { text: 'Amount', value: 'amount' },
            { text: 'Running Balance', value: 'balance' },
        ],
        installment_schedule_headers: [
            { text: 'Installment Number', value: 'installment_number' },
            { text: 'Deadline Date', value: 'deadline_date' },
            { text: 'Installment Status', value: 'installment_status' },
            { text: 'Carried Over', value: 'installment_item_carried_over' },

            { text: 'Principal', value: 'premium_principal' },
            { text: 'Admin Fee', value: 'premium_admin_fee' },
            { text: 'Init Fee', value: 'premium_initiation_fee' },
            { text: 'Interest', value: 'premium_interest' },

            { text: 'Total Expected', value: 'premium_total_theoretical' },
            { text: 'Total Collected', value: 'premium_total_collected' },
        ],

        // Dialogs
        
        cases: [],
        case_notes: [],
        open_search_cases_dialog: false,
        open_edit_case_dialog: false,

        data_table_loading: false,
        SEARCH_MODE: false,
        page: 1,
        limit: 50,

        BOND_STATUSES: BOND_STATUSES,
        
        selectedCaseCopy: null,

        amortization_schedule_loading: false,
        transactions_loading: false,
    }),

    methods: {
        //-------------------- OPEN DIALOGS ---------------------//
        openSearchCasesDialog() {
            this.unselectSelectedRow();
            this.open_search_cases_dialog = true;
        },
        openEditCaseDialog() {
            this.open_edit_case_dialog = true;
        },

        resetCaseEditForm(updatedCase) {
            if (
                parseFloat(updatedCase.amount) !== this.selectedCase.amount ||
                parseFloat(updatedCase.installments) !== this.selectedCase.installments ||
                parseFloat(updatedCase.elected_monthly_repayment_date) !== this.selectedCase.elected_monthly_repayment_date ||
                updatedCase.payment_method !== this.selectedCase.payment_method ||

                updatedCase.elected_monthly_repayment_date !== this.selectedCase.elected_monthly_repayment_date
            ) {
                // this.selectedCaseCopy = Object.assign({}, this.selectedCase);
                this.selectedCaseCopy = JSON.parse( JSON.stringify(this.selectedCase) );
            }
        },
        //------------------------------------------------------------------------------------//
        //-------------------------------- LIFE CYCLE MANAGEMENT -----------------------------//
        //------------------------------------------------------------------------------------//

        //------------------- CLOSE DIALOGS---------------------//
        updateUITableModel(new_case) {
            if (new_case !== null) {
                const obj_index = this.cases.map(obj => { return obj._id; }).indexOf(new_case._id)
                this.cases[obj_index] = new_case;

                this.selectedCase = new_case;
                this.selectedCaseCopy = JSON.parse( JSON.stringify(new_case) );

                // Update sub-tab model
                this.case_notes = new_case.notes;

                 // Deep copy cases for refreshing main data table
                const temp_arr = Array.from(this.cases);
                this.cases = temp_arr;
            }
        },
        closeEditCaseDialog(new_case) {
            this.updateUITableModel(new_case);
            
            this.selectedCase = null;
            this.selectedCaseCopy = null;
            this.unselectSelectedRow();

            if (new_case !== null) {
                if (this.SEARCH_MODE === true) {
                    this.clearSearchResults();
                } else {
                    this.refreshCases();
                }
            }

            this.open_edit_case_dialog = false;
        },
        closeSearchCasesDialog(searchResults) {
            if (searchResults !== null) {
                this.cases = searchResults;
                this.SEARCH_MODE = true;

                this.case_notes = [];
            }
            this.open_search_cases_dialog = false;
        },

        tobeDefined(){},
        selectMainTableRow(item, item_metadata) {
            this.selectedCaseCopy = JSON.parse( JSON.stringify(item) );

            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                this.selectedRow = item_metadata;
                this.selectedCase = item;

                this.case_notes = item.notes;
            } else {
                this.selectedCase = null;
                // Unighlight selected row
                item_metadata.select(false);
                this.selectedRow = null;

                this.case_notes = [];
            }
        },

        clearSearchResults() {
            // Unselect a row if there was any
            this.unselectSelectedRow();

            this.SEARCH_MODE = false;
            this.refreshCases();
        },

        unselectSelectedRow() {
            if (this.selectedRow !== null) {
                this.selectedRow.select(false);
                this.selectedRow = null;

                this.case_notes = [];
            }
            this.selectedCase = null;
        },

        async loadMoreData() {
            this.data_table_loading = true;

            this.page += 1;

            try {
                const res = await this.$apollo
                                        .query({
                                            query: getOperationalExcellenceCases,
                                            // fetchPolicy: 'network-only',
                                            fetchPolicy: 'no-cache',
                                            errorPolicy: 'all',
                                            variables: {
                                                objectType: 'OPERATIONAL_EXCELLENCE',
                                                pagination: {
                                                    page: this.page,
                                                    limit: this.limit
                                                }
                                            },
                                        });
                this.data_table_loading = false;
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }
    
                this.cases = this.cases.concat( res.data.getBusinessObject.business_objects );
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        async refreshCases() {
            this.data_table_loading = true;
            this.cases = [];

            this.page = 1; //, limit = 20;

            try {
                const res = await this.$apollo
                                        .query({
                                            query: getOperationalExcellenceCases,
                                            // fetchPolicy: 'network-only',
                                            fetchPolicy: 'no-cache',
                                            errorPolicy: 'all',
                                            variables: {
                                                objectType: 'OPERATIONAL_EXCELLENCE',
                                                pagination: {
                                                    page: this.page,
                                                    limit: this.limit
                                                }
                                            },
                                        });
                this.data_table_loading = false;
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }
    
                this.cases = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        paneResizing(pane, container, size) {
            const size_int = parseInt(size);

            this.main_tab_height = size;
            const max_screen_height = window.innerHeight - 300;

            const sub_tab_percentage = 1 - (size_int/max_screen_height);
            this.sub_tab_height = sub_tab_percentage * max_screen_height;
        }
    },

    async mounted() {
        eventBus.$on('CLOSE_CASE_SEARCH_DIALOG', this.closeSearchCasesDialog);
        eventBus.$on('CLOSE_EDIT_CASE_DIALOG', this.closeEditCaseDialog);

        // Form reseters
        eventBus.$on('RESET_LOAN_EDIT_FORM', this.resetCaseEditForm);
        
        // console.log('isIndoorAgent: ', this.isIndoorAgent);
        // console.log('isLoanCollectionsAdministratorUser: ', this.isLoanCollectionsAdministratorUser);
        // console.log('isOperationsManagerUser: ', this.isOperationsManagerUser);
        // console.log('isLegalAdministratorUser: ', this.isLegalAdministratorUser);
        // console.log('isCentralUnderwriterAdminUser: ', this.isCentralUnderwriterAdminUser);

        /**
         * The following logic is for manually triggering a pane-divider movement
         * so as to get the windows to properly resize
         */
         this.paneResizing(null, null, 250);

        try {
            this.data_table_loading = true;
            const res = await this.$apollo
                                    .query({
                                        query: getOperationalExcellenceCases,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'OPERATIONAL_EXCELLENCE',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            this.data_table_loading = false;
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                return;
            }

            this.cases = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.data_table_loading = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
        }
    },

    watch: {
        
    }
}
</script>
<style scoped>
/* Overriding of textbox min height */
 .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
}
</style>
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Edit Basic Agent Info</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                    icon
                    @click="closeEditPersonDialog()"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <!-- Error -->
                    <v-row v-if="error_message !== null">
                        <v-col cols="12" sm="12">
                            <v-alert type="error" class="error-multi-line">{{error_message}}</v-alert>
                        </v-col>
                    </v-row>
                    <v-row v-if="agent !== null">
                        <v-col cols="12" sm="4">
                            <v-text-field
                                v-model="agent.entity.name"
                                label="First Name"
                                autocomplete="some-random-text"
                                type="text"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" >
                            <v-text-field
                                v-model="agent.entity.surname"
                                label="Surname"
                                autocomplete="some-random-text"
                                type="text"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" >
                            <v-text-field
                                v-model="agent.entity.id_number"
                                label="ID Number"
                                autocomplete="some-random-text"
                                type="number"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <AgentSearch :member="agent_field" label="Agent Who Recruited This Member" />
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Updating Member Details
                        <v-progress-linear
                            indeterminate
                            :active="submitting"
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        large
                        color="success"
                        @click="submitPerson()"
                    >
                        <v-icon large>save</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import AgentSearch from "../components/agent_search";
import { emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";

import { entityProcessAgent } from "../gql/mutations";

export default {
    components: {
        AgentSearch,
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        agent: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data: () => ({
        error_message: null,
        submitting: false,
        server_response_obj: null,
    }),
    computed: {
        member_details() {
            return Object.assign({}, this.member);
        },
        agent_field() {
            return {
                agent: this.agent !== null ? this.agent.recruiting_agent : null
            }
        },
    },
    methods: {
        closeEditPersonDialog() {
            eventBus.$emit('CLOSE_EDIT_AGENT_DIALOG', this.server_response_obj);
        },

        /**
         * Run validation of step 1 form
         */
        validateBasicDetails() {
            // if (
            //     this.member_details.name === null ||
            //     this.member_details.surname === null ||
            //     this.member_details.id_number === null ||
            //     this.member_details.marital_status === null
            // ) {
            //     this.error_message = 'Please capture all fields';
            //     return;
            // }


            // if (typeof(this.member_details.name) === "string" && this.member_details.name.trim() === '') {
            //     this.error_message = 'Please capture the First Name(s) field';
            //     return;
            // }
            // if (typeof(this.member_details.surname) === "string" && this.member_details.surname.trim() === '') {
            //     this.error_message = 'Please capture the Surname field';
            //     return;
            // }
            // if (typeof(this.member_details.marital_status) === "string" && this.member_details.marital_status.trim() === '') {
            //     this.error_message = 'Please capture the Marital Status field';
            //     return;
            // }
        },
        capturingDone() {
            this.closeEditPersonDialog();
            this.resetForm();
        },
        resetForm() {
            this.server_response_obj = null;
            this.error_message = null;
        },
        async submitPerson() {
            this.error_message = null;
            this.validateBasicDetails();

            if (this.error_message === null) {
                this.submitting = true;

                const submitObj = {
                    recruiting_agent: (
                        this.agent_field.agent !== null && this.agent_field.agent !== undefined
                        ) ? this.agent_field.agent._id : null,
                };

                try {
                    const res = await this.$apollo
                                                .mutate({
                                                    mutation: entityProcessAgent,
                                                    variables: {
                                                        input: {
                                                            OP_TYPE: 'UPDATE',
                                                            _id: this.agent._id,
                                                            
                                                            ...submitObj
                                                        }
                                                    },
                                                });
                    this.submitting = false;
                    if (res.data.entityProcessAgent.errors === true) {
                        emitAlert(res.data.entityProcessAgent.message);
                        return;
                    }
    
                    emitSuccess(res.data.entityProcessAgent.message);

                    this.server_response_obj = res.data.entityProcessAgent.business_object;
                    this.closeEditPersonDialog();
                    this.resetForm();
                } catch (error) {
                    this.submitting = false;
                    console.log(error);
                    if (error !== undefined) {
                        emitAlert(error.data.entityProcessAgent.message);
                    } else {
                        emitAlert('The server has taken too long in responding to your request');
                    }
                }
            }
        },
    },
    mounted() {

    },
}
</script>
<style scoped>

</style>
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Add Policy To Scheme</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn
                    icon
                    @click="closeDialog()"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <!-- <v-text-field
                                v-model="scheme.name"
                                color="secondary"
                                label="Scheme Name"
                                autocomplete="some-random-text"
                                type="text"
                                disabled
                                required
                            ></v-text-field> -->

                            <PolicySearch
                                :policy_search="policy_search"
                            />
                        </v-col>
                    </v-row>
                    <v-row v-if="policy_search.policy !== null">
                        <span v-if="policy_search.policy.scheme !== null">
                            <h1 v-if="policy_search.policy.scheme._id === scheme._id" style="color:red">The policy is already on the scheme</h1>
                        </span>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting...
                        <v-progress-linear
                            indeterminate
                            :active="submitting"
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="success"
                        :disabled="submitBtnDisabled()"
                        @click="submitPolicy()"
                    >
                        <v-icon large>save</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { eventBus } from '../../../main';
import { emitAlert, emitSuccess } from '../../../utils/api';
import PolicySearch from '../components/policy_search.vue';
import { businessProcessPolicy } from "../gql/mutations";

export default {
    components: {
        PolicySearch,
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        scheme: {
            type: Object,
            required: false,
            default: () => {
                return {
                    marketing_channels: []
                }
            },
        },
    },
    data: () => ({
        submitting: false,
        server_response_obj: null,
        
        policy_search: {
            policy: null
        }
    }),

    methods: {
        closeDialog() {
            eventBus.$emit('CLOSE_ADD_POLICY_TO_SCHEME_DIALOG', this.server_response_obj);
        },
        resetForm() {
            this.server_response_obj = null;
            this.policy_search.policy = null;
        },

        submitBtnDisabled() {
            if (this.policy_search.policy === null) {
                return true;
            }
            if (this.policy_search.policy !== null) {
                if (this.policy_search.policy.scheme !== null) {
                    if (this.policy_search.policy.scheme._id === this.scheme._id) { 
                        return true;
                    }
                }
            }
            return false;
        },

        async submitPolicy() {
            this.submitting = true;

            try {
                const payloadObj = {
                    OP_TYPE: 'UPDATE_POLICY_SCHEME',
                    _id: this.policy_search.policy._id,

                    scheme: this.scheme._id
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcessPolicy,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.businessProcessPolicy.errors === true) {
                    emitAlert(res.data.businessProcessPolicy.message);
                    this.submitting = false;
                    return;
                }

                this.server_response_obj = res.data.businessProcessPolicy.business_object;
                this.closeDialog();

                emitSuccess(res.data.businessProcessPolicy.message);
                this.submitting = false;
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessPolicy.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
        },
    }
}
</script>

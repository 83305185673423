<template>
    <div>
        <v-toolbar
            color="secondary white--text"
            dark
            class="pa-0"
            height="50"
            extension-height="3"
        ></v-toolbar>

        <!-- <v-divider class="my-6" color="primary"></v-divider> -->
        <!-- <h3>Policy Reports</h3>
        <v-row>
            <v-col
                sm="2"
                v-for="(report, i) in reports.filter(rep => {return rep.group==='Policy'})"
                :key="i"
                class="text-center"
            >
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            small
                            v-on="on"
                            color="primary"
                            @click="openReportDownloadDialog(report)"
                        >
                            <v-icon>mdi-file-excel</v-icon>
                            
                        </v-btn>
                        <div>{{report.name}}</div>
                    </template>
                    <span>{{report.description}}</span>
                </v-tooltip>
            </v-col>
        </v-row> -->
        <v-card height="600">
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="6" offset-sm="3">
                        <v-select
                            v-model="selected_report"
                            :items="user_premitted_reports"
                            color="secondary"
                            item-text="name"
                            item-value="_id"
                            label="Select Report"
                            hint="Select a report in order to proceed with the download process"
                            persistent-hint
                            return-object
                            required
                            @change="reportChanged"
                        >
                            <!-- CUSTOM RENDERING TEMPLATE -->
                            <template
                                v-slot:selection="{ item }"
                            >
                                <v-chip
                                    :text-color="color_map[item.group].text"
                                    :color="color_map[item.group].background"
                                >
                                    {{ item.name }}
                                </v-chip>
                            </template>
                            <!-- Template for render data when the select is expanded -->
                            <template v-slot:item="{ item, attrs, on }">
                                <v-list-item v-on="on" v-bind="attrs">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-row no-gutters align="center">
                                            <span>{{ item.name }}</span>
                                            <v-spacer></v-spacer>
                                            <v-chip
                                                :text-color="color_map[item.group].text"
                                                :color="color_map[item.group].background"
                                                small
                                            >{{ item.group }}</v-chip>
                                            </v-row>
                                        </v-list-item-title>
                                        <!-- <v-list-item-subtitle>
                                            {{item.description}}
                                        </v-list-item-subtitle> -->
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
                <!-- <v-row v-if="selected_report !== null" style="background: #4590a0"> -->
                <v-row v-if="selected_report !== null">
                    <v-col cols="12" sm="6" offset-sm="3">
                        <v-row style="background: #f6c20b">
                            <v-col cols="12" sm="12">
                                <h3>Report Details</h3>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    :value="selected_report.report_number"
                                    label="Report Number"
                                    color="secondary"
                                    required
                                    disabled
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    :value="selected_report.group"
                                    label="Report Group"
                                    color="secondary"
                                    required
                                    disabled
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" :sm="is_central_underwriter_admin ? '10' : '12'">
                                <v-textarea
                                    :value="selected_report.description"
                                    label="Description"
                                    color="secondary"
                                    required
                                    rows="1"
                                    auto-grow
                                    disabled
                                ></v-textarea>
                            </v-col>
                            <v-col cols="12" sm="2" v-if="is_central_underwriter_admin">
                                <v-checkbox
                                    v-model="underwriter_level"
                                    label="Underwriter Level"
                                    color="secondary"
                                    required
                                ></v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="12">
                                <v-btn
                                    block
                                    class="ma-0"
                                    color="secondary"
                                    :disabled="reportPageDisabled()"
                                    @click="triggerReportDownload()"
                                >
                                    <v-spacer></v-spacer>
                                    Start Report Process
                                    <v-spacer></v-spacer>
                                    <v-icon
                                        large
                                        right
                                    >
                                        mdi-run-fast
                                    </v-icon>
                                    <v-spacer></v-spacer>
                                </v-btn>
                            </v-col>
                            <v-col cols="12" sm="12" v-if="reportSavable()">
                                <v-btn
                                    block
                                    class="ma-0"
                                    color="success"
                                    :disabled="reportPageDisabled()"
                                    @click="saveDownloadedReport()"
                                >
                                    <v-spacer></v-spacer>
                                    Save Generated Report
                                    <v-spacer></v-spacer>
                                    <v-icon
                                        large
                                        right
                                    >
                                        mdi-download
                                    </v-icon>
                                    <v-spacer></v-spacer>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row v-if="reportPageDisabled()">
                    <v-col cols="12" sm="6" offset-sm="3">
                        <v-row>
                            <!-- <v-col cols="12" sm="12">
                                <h4>Report download in progress...</h4>
                            </v-col> -->
                            <v-col cols="12" sm="12">
                                <h4>Report download in progress...</h4>
                                <v-progress-linear
                                    v-model="selected_report_download_progress"
                                    stream
                                    color="primary"
                                    height="25"
                                >
                                    <template v-slot:default="{ value }">
                                        <strong style="color:#ffffff">{{ Math.ceil(value) }}%</strong>
                                    </template>
                                </v-progress-linear>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
            <!-- <v-progress-linear
                color="green"
                class="mb-0"
                height="10"
                indeterminate
                :active="backend_check"
            ></v-progress-linear> -->
            <!-- Button Section -->
        </v-card>
    </div>
</template>
<script>
import { store } from "../../../store";
import config from "../../../config";
import { emitAlert } from '../../utils/api';
import { businessProcess } from "./gql/mutations";

export default {
    components: {
        
    },

    data: () => ({
        is_central_underwriter_admin: false,
        underwriter_level: false,

        selected_report: null,
        selected_report_download_progress: 0,
        user_premitted_reports: [],

        clearTimeFunction: null,

        currentUserAccount: store.state.currentUserAccount,

        color_map: {
            'Policy': {
                background: 'success',
                text: 'white',
            },
            'Prospect Policy': {
                background: 'purple',
                text: 'white',
            },
            'Claim': {
                background: 'red',
                text: 'white',
            },
            'Agent': {
                background: 'amber',
                text: 'white',
            },
            'Finance': {
                background: '#dede00',
                text: 'black',
            },
            'Loan': {
                background: '#0e6db1',
                text: 'white',
            },
        },
    }),

    methods: {
        reportChanged() {
            this.underwriter_level = false;
        },

        reportSavable() {
            let result = false;

            if (this.selected_report !== null) {
                const REPORT_DOWNLOAD_PROGRESS_MAP = store.state.REPORT_DOWNLOAD_PROGRESS_MAP;

                const report_number = this.selected_report.report_number;
                const report_progress = REPORT_DOWNLOAD_PROGRESS_MAP[ report_number ];

                if (report_progress.downloaded_file !== null) {
                    result = true;
                }
            }

            return result;
        },

        reportPageDisabled() {
            let result = false;

            if (this.selected_report !== null) {
                const REPORT_DOWNLOAD_PROGRESS_MAP = store.state.REPORT_DOWNLOAD_PROGRESS_MAP;

                const report_number = this.selected_report.report_number;
                const report_progress = REPORT_DOWNLOAD_PROGRESS_MAP[ report_number ];

                if (report_progress.downloading === true) {
                    result = true;
                }
            }

            return result;
        },

        async triggerReportDownload() {
            const report_number = this.selected_report.report_number;

            // Now send a server request
            try {
                const payloadObj = {
                    process_group: 'REPORT',
                    report_number: report_number,
                    contextualized: !this.underwriter_level,
                }
                // const res = await submitServerProcessTrigger(payloadObj);
                // if (res.errors === true) {
                //     emitAlert(res.message);
                //     return;
                // }

                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcess,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.businessProcess.errors === true) {
                    emitAlert(res.data.businessProcess.message);
                    return;
                }

                // If we're here, the the trigger succeeded
                const REPORT_DOWNLOAD_PROGRESS_MAP = store.state.REPORT_DOWNLOAD_PROGRESS_MAP;

                const report_progress = REPORT_DOWNLOAD_PROGRESS_MAP[ report_number ];
                report_progress.downloading = true;

                REPORT_DOWNLOAD_PROGRESS_MAP[ report_number ] = report_progress;

                store.commit('update_report_download_progress_map', REPORT_DOWNLOAD_PROGRESS_MAP);

                // Ensure that if in max 5 seconds the server is unresponsive, then revert to original state
                const BACKEND_TIMEOUT = 5000;
                this.clearTimeFunction = setTimeout(() => {
                    const REPORT_DOWNLOAD_PROGRESS_MAP = store.state.REPORT_DOWNLOAD_PROGRESS_MAP;

                    // Make new progress report object
                    const newReportProgress = {
                        downloading: false,
                        download_progress: 0,
                        downloaded_file: null
                    };

                    REPORT_DOWNLOAD_PROGRESS_MAP[ report_number ] = newReportProgress;

                    store.commit('update_report_download_progress_map', REPORT_DOWNLOAD_PROGRESS_MAP);
                }, BACKEND_TIMEOUT);
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcess.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
            
        },

        saveDownloadedReport() {
            const report_number = this.selected_report.report_number;
            const report_name = this.selected_report.name;

            const REPORT_DOWNLOAD_PROGRESS_MAP = store.state.REPORT_DOWNLOAD_PROGRESS_MAP;
            const report_progress = REPORT_DOWNLOAD_PROGRESS_MAP[ report_number ];
            
            const downloaded_file = report_progress.downloaded_file;
            
            // Trigger browser download
            var a = document.createElement("a");
            a.href = downloaded_file;
            a.setAttribute("download", report_name);
            a.click();

            // Reset download map
            report_progress.downloaded_file = null;
            report_progress.download_progress = 0;
            report_progress.downloading = false;

            REPORT_DOWNLOAD_PROGRESS_MAP[ report_number ] = report_progress;
            store.commit('update_report_download_progress_map', REPORT_DOWNLOAD_PROGRESS_MAP);

            this.selected_report_download_progress = 0;
        }
    },

    async mounted() {
        const allowed_reports = this.currentUserAccount.roles[0].role.allowed_reports.map(allowed_report => {
            return allowed_report.report;
        });

        // Order reports by group
        this.user_premitted_reports = this.user_premitted_reports.concat(allowed_reports.filter(report => { return report.group === 'Policy' }));
        this.user_premitted_reports = this.user_premitted_reports.concat(allowed_reports.filter(report => { return report.group === 'Prospect Policy' }));
        this.user_premitted_reports = this.user_premitted_reports.concat(allowed_reports.filter(report => { return report.group === 'Finance' }));
        this.user_premitted_reports = this.user_premitted_reports.concat(allowed_reports.filter(report => { return report.group === 'Loan' }));
        this.user_premitted_reports = this.user_premitted_reports.concat(allowed_reports.filter(report => { return report.group === 'Claim' }));
        this.user_premitted_reports = this.user_premitted_reports.concat(allowed_reports.filter(report => { return report.group === 'Agent' }));

        this.$socket.client.on('server_message', (data) => {
            const message_data = data.data;
            const report_number = message_data.report_number;

            if (message_data.error) {
                emitAlert(message_data.error);

                // Update application data
                const REPORT_DOWNLOAD_PROGRESS_MAP = store.state.REPORT_DOWNLOAD_PROGRESS_MAP;
                
                // Make new progress report object
                const newReportProgress = {
                    downloading: false,
                    download_progress: 0,
                    downloaded_file: null
                };

                REPORT_DOWNLOAD_PROGRESS_MAP[ report_number ] = newReportProgress;
                store.commit('update_report_download_progress_map', REPORT_DOWNLOAD_PROGRESS_MAP);

                this.selected_report_download_progress = 0;

                return;
            }

            const downloading = message_data.downloading;
            const progress = message_data.progress;
            const file = message_data.file;

            // Update application data
            const REPORT_DOWNLOAD_PROGRESS_MAP = store.state.REPORT_DOWNLOAD_PROGRESS_MAP;

            // Make new progress report object
            const newReportProgress = {
                downloading: downloading,
                download_progress: progress,
                downloaded_file: file
            };

            REPORT_DOWNLOAD_PROGRESS_MAP[ report_number ] = newReportProgress;
            store.commit('update_report_download_progress_map', REPORT_DOWNLOAD_PROGRESS_MAP);

            // Update progress indicator
            this.selected_report_download_progress = progress;

            if (progress === 100) {
                this.selected_report_download_progress = 0;
                // Disable clearing function
                if (this.clearTimeFunction !== null) {
                    clearTimeout(this.clearTimeFunction);
                }
            }
        });


        if (store.state.context_list.length > 0) {
            const isCentralUnderwriterAdminUser = store.state.currentUserAccount.roles.filter(role => {
                                                        return (
                                                            role.role.level.includes(config.BUSINESS_PARTNER_TYPES.CENTRAL_UNDERWRITER) &&
                                                            role.role.name.toLowerCase() ===  'admin - central underwriter'
                                                        );
                                                    }).length > 0;

            if (isCentralUnderwriterAdminUser) {
                this.is_central_underwriter_admin = true;
            }
        }
    },

    watch: {
        
    }
}
</script>
<style scoped>
/* Overriding of textbox min height */
 .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
}
</style>
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="40%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Allocate Transaction to Loan</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeTransactionAllocationDialog(true)">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row v-if="loan !== null">
                        <v-col cols="12" sm="12">
                            <h3>You are about to allocate the selected transaction (R{{ transaction.amount }}) to the loan L{{ loan.loan_number }}. Are you sure you want to proceed?</h3>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Allocating transaction...
                        <v-progress-linear
                            indeterminate
                            color="success"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>

                <v-divider></v-divider>

                <!-- Button Section -->
                <v-card-actions>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="red white--text"
                                v-on="on"
                                @click="closeTransactionAllocationDialog(true)"
                            >
                                <v-icon large>close</v-icon>
                                <span>Cancel</span>
                            </v-btn>
                        </template>
                        <span>Cancel</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                @click="submitAllocationAction()"
                            >
                                <v-icon large>done</v-icon>
                                <span>Proceed</span>
                            </v-btn>
                        </template>
                        <span>Proceed to allocate transaction</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { eventBus } from '../../../main';
import { emitAlert, emitSuccess } from '../../../utils/api';
import { businessProcessLoan } from "../gql/mutations";
export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        loan: {
            type: Object,
            default: null
        },
        transaction: {
            type: Object,
            default: null
        },
    },
    data: () => ({
        submitting: false,
        server_response_obj: null
    }),
    methods: {
        closeTransactionAllocationDialog(cancelled) {
            eventBus.$emit('CLOSE_LOAN_TRANSACTION_ALLOCATION_DIALOG', cancelled, this.server_response_obj);
        },
        async submitAllocationAction() {
            this.submitting = true;

            try {
                const payloadObj = {
                    OP_TYPE: 'ALLOCATE_LOAN_TRANSACTION',
                    _id: this.loan._id,

                    // Payload
                    transaction_id: this.transaction._id

                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcessLoan,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                this.submitting = false;
                if (res.data.businessProcessLoan.errors === true) {
                    emitAlert(res.data.businessProcessLoan.message);
                    return;
                }

                emitSuccess(res.data.businessProcessLoan.message);

                // Update UI model
                // this.updateUITableModel(res.data.businessProcessLoan.business_object);
                this.server_response_obj = res.data.businessProcessLoan.business_object;
                this.closeTransactionAllocationDialog(false);

                // Reset model
                this.server_response_obj = null;
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessLoan.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
        },
    },
    mounted() {
        
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
<template>
    <div>
        <v-toolbar
            color="secondary"
            dark
            class="pa-0"
            height="50"
            extension-height="3"
        >
            <v-row>
                <!-- Grid Buttons -->
                <v-col sm="4" style="margin-top:10px">
                    <v-tooltip>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                @click="loadMoreData()"
                                v-on="on"
                            >
                                <v-icon>cloud_download</v-icon>
                            </v-btn>
                        </template>
                        <span>Load More Data</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="refreshBankStatements()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh</span>
                    </v-tooltip>
                </v-col>

                <!-- Search Result Indicator -->
                <v-col sm="4">
                    <!-- <v-card-actions>
                        <h3>Search Results</h3>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    v-on="on"
                                >
                                    <v-icon small>search</v-icon>
                                    <v-icon small>close</v-icon>
                                </v-btn>
                            </template>
                            <span>Clear Search Results</span>
                        </v-tooltip>
                    </v-card-actions> -->
                </v-col>

                <!-- Action Buttons -->
                <v-col sm="4" style="margin-top:10px">
                    <div class="text-right">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="openOnboardingDialog()"
                                    v-on="on"
                                >
                                    <v-icon medium>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Upload Bank Statement</span>
                        </v-tooltip>
                    </div>
                </v-col>
            </v-row>
        </v-toolbar>
        <multipane class="horizontal-panes" layout="horizontal" style="height: 100%" @paneResize="paneResizing">
            <div class="pane" :style="{ height: '50%', maxHeight: '50%'}">
                <v-data-table
                    fixed-header
                    :height="main_tab_height"
                    :headers="bank_statement_details_headers"
                    :items="bank_statement"
                    item-key="_id"
                    class="elevation-1"
                    :single-select="true"
                    @click:row="selectMainTableRow"
                    item-class="secondary"
                    hide-default-footer
                    :loading="data_table_loading"
                    :disable-pagination="true"
                >
                    <!-- Created -->
                    <template v-slot:[`item.created`]="{ item }">
                        <td>{{item.created.split('T')[0]}}</td>
                    </template>
                    <!-- Captured By -->
                    <template v-slot:[`item.captured_by`]="{ item }" >
                        <td v-if="item.captured_by !== null && item.captured_by !== undefined">
                            {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                        </td>
                    </template>
                </v-data-table>
            </div>
            <multipane-resizer></multipane-resizer>
            <div class="pane tab-nav" :style="{ height: '30%', maxHeight: '50%', minHeight: '30%' }">
                <v-tabs
                    v-model="sub_tabs"
                    color="transparent"
                    class="tab-nav"
                    hide-slider
                    height="35px"
                    grow
                >
                    <!-- Documents Sub Tab -->
                    <template>
                        <v-tab key="documents_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Documents
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>
                    <!-- Transactions History Sub Tab -->
                    <template>
                        <v-tab key="transactions_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Statement Transactions
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>
                </v-tabs>
                <v-tabs-items v-model="sub_tabs">
                    <!-- Sub-Tab Toolbar -->
                    <v-toolbar
                        color="secondary"
                        dark
                        class="pa-0"
                        height="50"
                        extension-height="3"
                    >
                        <!-- Show following buttons only for the DOCUMENTS sub-tab -->
                        <v-tooltip bottom v-if="sub_tabs === 0  && selectedDocument !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    v-on="on"
                                    @click="openViewDocumentDialog()"
                                >
                                    <v-icon>content_paste</v-icon>
                                </v-btn>
                            </template>
                            <span>View Document Info</span>
                        </v-tooltip>

                        <!-- statement transactions -->
                        <v-tooltip bottom v-if="sub_tabs === 1 && selected_transactions.length > 0">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    style="margin-left: 10px; margin-right: 10px;"
                                    color="purple"
                                    small
                                    @click="openClassifyTransactionsDialog()"
                                    v-on="on"
                                >
                                    <!-- <v-icon>add</v-icon> --> Classify
                                </v-btn>
                            </template>
                            <span>Classify Selected Transactions</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 1 && selected_transactions.length === 1">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    style="margin-left: 10px; margin-right: 10px;"
                                    color="amber black--text"
                                    small
                                    @click="updateSelectedTransactions()"
                                    v-on="on"
                                >
                                    <v-icon>mdi-edit</v-icon> Update / Correct
                                </v-btn>
                            </template>
                            <span>Update/Correct Selected Transaction</span>
                        </v-tooltip>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <!-- Documents Sub-Tab Content -->
                    <v-tab-item key="documents_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="document_details_headers"
                            :items="documents"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectDocumentsTabRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Created -->
                            <template v-slot:[`item.created`]="{ item }">
                                <td>{{item.created.split('T')[0]}}</td>
                            </template>
                            <!-- Captured By -->
                            <template v-slot:[`item.captured_by`]="{ item }" >
                                <td v-if="item.captured_by !== null && item.captured_by !== undefined">
                                    {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Transactions History Sub-Tab Content -->
                    <v-tab-item key="transactions_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="transactions_headers"
                            :items="transactions"
                            item-key="_id"
                            class="elevation-1"
                            @click:row="tobeDefined"
                            item-class="secondary"
                            hide-default-footer
                            :loading="transactions_loading"
                            :disable-pagination="true"

                            v-model="selected_transactions"
                            show-select
                            :single-select="false"
                        >
                            <!-- Display Formated transaction date -->
                            <template v-slot:[`item.transaction_date`]="{ item }">
                                <td>{{item.transaction_date !== null ? item.transaction_date.split('T')[0] : ''}}</td>
                            </template>
                            <!-- Display Formated money-in -->
                            <template v-slot:[`item.money_in`]="{ item }">
                                <td>R {{item.money_in}}</td>
                            </template>
                            <!-- Display Formated money-out -->
                            <template v-slot:[`item.money_out`]="{ item }">
                                <td>R {{item.money_out}}</td>
                            </template>
                            <!-- Display Formated balance -->
                            <template v-slot:[`item.balance`]="{ item }">
                                <td>R {{item.balance}}</td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </multipane>
        <OnboardingDialog
            :dialog="open_onboarding_dialog"
        />

        <!-- Document Dialogs -->
        <ViewDocumentDialog
            v-if="selectedDocument !== null"
            :dialog="open_view_document_dialog"
            :document="selectedDocument"
            param_path="bank_statement"
            :param_id="selectedBankStatement._id"
        />

        <!-- Transaction History -->
        <ClassifyTransactionsDialog
            v-if="selectedBankStatement !== null"
            :dialog="open_classify_transactions_dialog"
            :selected_transactions="selectedBankStatement"
        />
    </div>
</template>
<script>
import { Multipane, MultipaneResizer } from 'vue-multipane';
import OnboardingDialog from './dialogs/add_bank_statement';


import ViewDocumentDialog from '../../../components/common_dialogs/view_document_info';

import ClassifyTransactionsDialog from './dialogs/classify_transactions.vue';

import { emitAlert } from "../../../utils/api";
import { eventBus } from "../../../main";

import { getBankStatements, getFinanceTransactionsList } from "./gql/queries";

export default {
    components: {
        Multipane,
        MultipaneResizer,
        OnboardingDialog,

        ViewDocumentDialog,

        ClassifyTransactionsDialog
    },

    data: () => ({
        sub_tabs: null,
        main_tab_height: '250px',
        sub_tab_height:'180',

        // main data-table row
        selectedBankStatement: null,
        selectedRow: null,

        // documents sub-tab data-table row
        selectedDocument: null,
        selectedDocumentRow: null,

        selectedBankStatementCopy: {
            name:  null,
        },
        
        selected_transactions: [],

        bank_statement_details_headers: [
            { text: 'Bank Account Name', value: 'bank_account_name' },
            { text: 'Finance Month', value: 'finance_month.name' },
            { text: 'Status', value: 'status' },
            { text: 'Created', value: 'created' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        document_details_headers: [
            { text: 'Document Type', value: 'type' },
            { text: 'Name', value: 'name' },
            { text: 'Created Date', value: 'created' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        transactions_headers: [
            { text: 'Item Number', value: 'item_number' },
            { text: 'Transaction Date', value: 'transaction_date' },
            { text: 'Description', value: 'description' },
            { text: 'Reference', value: 'reference' },
            { text: 'Money In', value: 'money_in' },
            { text: 'Money Out', value: 'money_out' },
            { text: 'Running Balance', value: 'balance' },

            { text: 'Classification', value: 'classification' },
        ],
        
        // Table data models
        documents: [],
        transactions: [],
        

        // Dialogs
        
        bank_statement: [],
        open_onboarding_dialog: false,

        // Documents dialogs
        open_view_document_dialog: false,
        // Premium
        open_classify_transactions_dialog: false,
        
        data_table_loading: false,

        transactions_loading: false,


        page: 1,
        limit: 50,
    }),

    methods: {
        //-------------------- OPEN DIALOGS ---------------------//
        openOnboardingDialog() {
            this.open_onboarding_dialog = true;
        },
        openViewDocumentDialog() {
            this.open_view_document_dialog = true;
        },
        openClassifyTransactionsDialog() {
            this.open_classify_transactions_dialog = true;
        },

        //------------------- CLOSE DIALOGS---------------------//
        closeOnboardingDialog(new_statement) {
            if (new_statement !== null) {
                this.bank_statement.push(new_statement);
            }
            this.open_onboarding_dialog = false;
        },

        updateUITableModel(new_statement) {
            if (new_statement !== null) {
                const obj_index = this.bank_statement.map(obj => { return obj._id; }).indexOf(new_statement._id)
                this.bank_statement[obj_index] = new_statement;

                 // Deep copy bank-statements for refreshing main data table
                const temp_arr = Array.from(this.bank_statement);
                this.bank_statement = temp_arr;

                // Also update the sub-tabs data
                this.documents = new_statement.documents.filter(doc => { return !doc.deleted });

                // Reset data-table items
                this.selectedBankStatement = new_statement;
                // this.unselectSubTabRows();
            }
        },
        closeViewServerDocumentsDialog() {
            this.open_view_document_dialog = false;
        },
        closeClassifyTransactionsDialog(new_transaction) {
            if (new_transaction !== null) {
                // Prepend to transactions array
                this.transactions.unshift(new_transaction);

                // Prepare balance
                if (new_transaction.from._id === this.selectedBankStatement._id) {
                    // new_transaction.balance = new_transaction.from_running_balance;
                    new_transaction.balance = new_transaction.balance_from;
                }

                if (new_transaction.to._id === this.selectedBankStatement._id) {
                    // new_transaction.balance = new_transaction.to_running_balance;
                    new_transaction.balance = new_transaction.balance_to;
                }
            }
            this.open_classify_transactions_dialog = false;
        },
        unselectSubTabRows() {
            // Unselect documents sub-tab row
            this.selectedDocument = null;
            if (this.selectedDocumentRow !== null) {
                this.selectedDocumentRow.select(false);
            }
        },
        selectMainTableRow(item, item_metadata) {
            this.unselectSubTabRows();

            // Clear selected transactions
            this.selected_transactions = [];

            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                this.selectedBankStatement = item;
                // Update documents model
                this.documents = item.documents.filter(doc => { return !doc.deleted });
                // Transactions
                this.transactions = item.transactions;

                // Set selected copy
                this.selectedBankStatementCopy = Object.assign({}, this.selectedBankStatement);
            } else {
                this.selectedBankStatement = null;
                // Unighlight selected row
                item_metadata.select(false);

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                // Update documents model
                this.documents = [];
                // Transactions
                this.transactions = [];

                // ----------------------UPDATE VISIBILITY OF ACTION BUTTONS -----------------------//
            }
        },
        selectDocumentsTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedDocument = item;
                this.selectedDocumentRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedDocument = null;
                this.selectedDocumentRow = null;
            }
        },
        tobeDefined(){},

        async loadMoreData() {
            this.data_table_loading = true;
            this.page += 1;
            
            try {
                this.data_table_loading = true;
                const res = await this.$apollo
                                    .query({
                                        query: getBankStatements,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'BANK_STATEMENT',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }

                this.data_table_loading = false;
                this.bank_statement = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
    
        async refreshBankStatements() {
            this.data_table_loading = true;
            this.bank_statement = [];

            this.page = 1;
            this.limit = 50;

            try {
                const res = await this.$apollo
                                    .query({
                                        query: getBankStatements,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'BANK_STATEMENT',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }

                this.data_table_loading = false;
                this.bank_statement = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        async updateSelectedTransactions() {
            try {
                this.transactions_loading = true;
                const res = await this.$apollo
                                    .query({
                                        query: getFinanceTransactionsList,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'FINANCE_ACCOUNT_TRANSACTION',
                                            pagination: {
                                                page: 1,
                                                limit: 50
                                            },
                                            searchInput: {
                                                account_id: this.selectedBankStatement._id
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.transactions_loading = false;
                    return;
                }


                this.transactions_loading = false;
                this.transactions = res.data.getBusinessObject.business_objects;

                // Prepare balance
                // let runningBalance = 0;
                // let runningAmount = 0;
                for (const trans of this.transactions) {
                    if (trans.from._id === this.selectedBankStatement._id) {
                        trans.amount = -trans.amount;
                        trans.balance = trans.balance_from;
                    }

                    if (trans.to._id === this.selectedBankStatement._id) {
                        trans.balance = trans.balance_to;
                    }

                    // if (this.transactions.indexOf(trans) === 0) {
                    //     if (trans.from._id === this.selectedBankStatement._id) {
                    //         // trans.balance = trans.from_running_balance;
                    //         trans.balance = trans.from.balance;
                    //     }
    
                    //     if (trans.to._id === this.selectedBankStatement._id) {
                    //         // trans.balance = trans.to_running_balance;
                    //         trans.balance = trans.to.balance;
                    //     }

                    //     runningBalance = trans.balance;
                    //     runningAmount = trans.amount;
                    // } else {
                    //     // NB: We're working the running balances backwards, so we've filpped the signs


                    //     if (trans.from._id === this.selectedBankStatement._id) {
                    //         // trans.balance = trans.from_running_balance;

                    //         // runningBalance = runningBalance + trans.amount;
                    //         runningBalance = runningBalance + runningAmount;
                    //         // trans.balance = trans.from.balance;
                    //         // trans.balance = runningBalance;

                    //     }
    
                    //     if (trans.to._id === this.selectedBankStatement._id) {
                    //         // trans.balance = trans.to_running_balance;

                    //         // runningBalance = runningBalance - trans.amount;
                    //         runningBalance = runningBalance - runningAmount;
                    //         // trans.balance = trans.to.balance;
                    //         // trans.balance = runningBalance;
                    //     }
                    //     trans.balance = runningBalance;
                    //     runningAmount = trans.amount;
                    // }
                }
            } catch (error) {
                this.transactions_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        paneResizing(pane, container, size) {
            const size_int = parseInt(size);

            this.main_tab_height = size;
            const max_screen_height = window.innerHeight - 300;

            const sub_tab_percentage = 1 - (size_int/max_screen_height);
            this.sub_tab_height = sub_tab_percentage * max_screen_height;
        }
    },

    async mounted() {
        eventBus.$on('CLOSE_ONBOARDING_DIALOG', this.closeOnboardingDialog);

        eventBus.$on('CLOSE_VIEW_DOCUMENT_DIALOG', this.closeViewServerDocumentsDialog);

        eventBus.$on('CLOSE_CLASSIFY_TRANSACTIONS_DIALOG', this.closeClassifyTransactionsDialog);

        /**
         * The following logic is for manually triggering a pane-divider movement
         * so as to get the windows to properly resize
         */
        this.paneResizing(null, null, 250);

        try {
            this.data_table_loading = true;
            const res = await this.$apollo
                                    .query({
                                        query: getBankStatements,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'BANK_STATEMENT',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.data_table_loading = false;
                return;
            }

            this.data_table_loading = false;
            this.bank_statement = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.data_table_loading = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
        }
    },

    watch: {
        
    }
}
</script>
<style scoped>
/* Overriding of textbox min height */
 .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
}
</style>
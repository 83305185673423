<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Updated Payment Arrangement Details</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <!-- #eaeaea -->
                    <!-- PREMIUM_PAYER PAYMENT OPTION -->
                    <v-row
                        style="background:#4490a030; padding-bottom:10px"
                    >
                        <v-col cols="12" sm="4">
                            <v-text-field
                                value="PREMIUM_PAYER"
                                label="Payer Type"
                                color="secondary"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-select
                                v-if="parseFloat(policy_copy.payment_information.premium_payer.percentage_amount) > 0"
                                v-model="policy_copy.payment_information.premium_payer.payment_method"
                                :items="[
                                    'CASH',
                                    'EFT',
                                    'STOP ORDER',
                                ]"
                                label="Payment Method"
                                color="secondary"
                                hint="Premium Payer Payment method"
                                persistent-hint
                                required
                            ></v-select>
                            <v-text-field
                                v-else
                                value="NOT APPLICABLE"
                                hint="Member Payment method"
                                persistent-hint
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" >
                            <v-text-field
                                v-model="policy_copy.payment_information.premium_payer.percentage_amount"
                                prefix="%"
                                color="secondary"
                                label="Percentage Amount"
                                type="number"
                                required
                            ></v-text-field>
                            <!-- <v-text-field
                                v-model="policy_copy.payment_information.premium_payer.percentage_amount"
                                prefix="%"
                                color="secondary"
                                label="Percentage Amount"
                                type="number"
                                :disabled="!policy_copy.scheme_level_payment"
                                required
                            ></v-text-field> -->
                        </v-col>
                    </v-row>
                    <!-- SCHEME PAYMENT OPTION -->
                    <v-row
                        v-if="parseFloat(policy_copy.payment_information.premium_payer.percentage_amount) < 100"
                        style="background:#4490a030; padding-bottom:10px"
                    >
                        <v-col cols="12" sm="4">
                            <v-text-field
                                value="SCHEME"
                                label="Payer Type"
                                color="secondary"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-select
                                v-if="policy_copy.payment_information.scheme.percentage_amount > 0"
                                v-model="policy_copy.payment_information.scheme.payment_method"
                                :items="[
                                    'CASH',
                                    'EFT',
                                    'STOP ORDER',
                                ]"
                                label="Payment Method"
                                color="secondary"
                                hint="Scheme Payment method"
                                persistent-hint
                                required
                            ></v-select>
                            <v-text-field
                                v-else
                                value="NOT APPLICABLE"
                                hint="Scheme Payment method"
                                persistent-hint
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field
                                v-model="policy_copy.payment_information.scheme.percentage_amount"
                                prefix="%"
                                color="secondary"
                                label="Percentage Amount"
                                type="number"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <!-- Progress Section -->
                <v-progress-linear
                    color="green"
                    class="mb-0"
                    height="10"
                    indeterminate
                    :active="submitting"
                ></v-progress-linear>
                <v-list flat v-if="submitting">
                    <v-subheader>Saving Policy Transaction</v-subheader>
                    <!-- <v-list-item-group v-model="item" color="primary"> -->
                    <v-list-item-group color="primary">
                        <v-list-item
                            v-for="(item, i) in backendActionItems"
                            :key="i"
                        >
                            <v-list-item-icon>
                                <v-icon>done</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ item }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>

                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        large
                        color="success"
                        :disabled="submitBtnDisabled()"
                        @click="submitUpdate()"
                    >
                        <v-icon large>save</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";
import config from "../../../../config";

import { businessProcessPolicy } from "../gql/mutations";

export default {
    components: {
        
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        policy_original: {
            type: Object,
            required: false,
            default: () => {
                return {
                    // name,
                    documents: []
                }
            }
        },
        policy_copy: {
            type: Object,
            required: false,
            default: () => {
                return {
                    // name,
                    documents: []
                }
            }
        },
    },
    data: () => ({
        server_resp_obj: null,
        transaction_date_menu: false,
        submitting: false,

        backendActionItems: [],

        payment_methods: config.payment_methods,
    }),
    methods: {
        closeDialog() {
            eventBus.$emit('CLOSE_UPDATE_PAYMENT_ARRANGEMENT_DIALOG', this.server_resp_obj);
        },
        resetForm() {
            this.server_resp_obj = null;
            this.backendActionItems = [];

            eventBus.$emit('RESET_POLICY_EDIT_FORM', this.policy_copy);
        },

        submitBtnDisabled() {
            // Premium payer percentage amount cannot be greater than 100
            if (parseFloat(this.policy_copy.payment_information.premium_payer.percentage_amount) > 100) {
                return true;
            }
            if (parseFloat(this.policy_copy.payment_information.premium_payer.percentage_amount) < 0) {
                return true;
            }


            // if scheme-level payment
            if (
                parseFloat(this.policy_copy.payment_information.scheme.percentage_amount) > 0 &&
                (
                    this.policy_copy.payment_information.scheme.payment_method === null ||
                    this.policy_copy.payment_information.scheme.payment_method === 'NOT APPLICABLE'
                )
            ) {
                this.policy_copy.payment_information.scheme.payment_method = null;
                return true;
            }

            // if premium-payer-level payment
            if (
                parseFloat(this.policy_copy.payment_information.premium_payer.percentage_amount) > 0 &&
                (
                    this.policy_copy.payment_information.premium_payer.payment_method === null ||
                    this.policy_copy.payment_information.premium_payer.payment_method === 'NOT APPLICABLE'
                )
            ) {
                this.policy_copy.payment_information.premium_payer.payment_method = null;
                return true;
            }
            
            return false;
        },
        
        async submitUpdate() {
            const paymentInformationObj = this.extractPaymentInformationPayload();

            this.submitting = true;
            try {
                const transactionObj = {
                    OP_TYPE: 'UPDATE_PAYMENT_ARRANGEMENT',
                    
                    _id: this.policy_copy._id,

                    payment_information: paymentInformationObj
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcessPolicy,
                                                variables: {
                                                    input: transactionObj
                                                },
                                            });

                this.submitting = false;
                if (res.data.businessProcessPolicy.errors === true) {
                    emitAlert(res.data.businessProcessPolicy.message);
                    return;
                }

                emitSuccess(res.data.businessProcessPolicy.message);

                this.server_resp_obj = res.data.businessProcessPolicy.business_object;
                this.closeDialog();

                // Reset form
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessPolicy.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
        extractPaymentInformationPayload() {
            const resultObj = {
                premium_payer: {
                    payment_method: null,
                    percentage_amount: 0,
                },
                scheme: {
                    payment_method: null,
                    percentage_amount: 0,
                }
            };
            resultObj.premium_payer.payment_method = this.policy_copy.payment_information.premium_payer.payment_method;
            resultObj.premium_payer.percentage_amount = parseFloat(this.policy_copy.payment_information.premium_payer.percentage_amount);
            
            resultObj.scheme.payment_method = this.policy_copy.payment_information.scheme.payment_method;
            resultObj.scheme.percentage_amount = parseFloat(this.policy_copy.payment_information.scheme.percentage_amount);

            // PAYMENT-METHODS
            if (resultObj.premium_payer.payment_method === null) {
                resultObj.premium_payer.payment_method = 'NOT APPLICABLE';
            }

            if (resultObj.scheme.payment_method === null) {
                resultObj.scheme.payment_method = 'NOT APPLICABLE';
            }

            // PERCENTAGE-AMOUNTS
            if (
                (parseFloat(this.policy_copy.payment_information.premium_payer.percentage_amount) <= 0) ||
                (parseFloat(this.policy_copy.payment_information.premium_payer.percentage_amount) > 100)
            ) {
                resultObj.premium_payer.payment_method = 'NOT APPLICABLE';
            }

            if (
                (parseFloat(this.policy_copy.payment_information.scheme.percentage_amount) <= 0) ||
                (parseFloat(this.policy_copy.payment_information.scheme.percentage_amount) > 100)
            ) {
                resultObj.scheme.payment_method = 'NOT APPLICABLE';
            }

            return resultObj;
        }
    },
    async mounted() {},
    watch: {
        'policy_copy.payment_information.premium_payer.percentage_amount': function(val) {
            const schemePercentageVal = 100 - parseFloat(val);
            this.policy_copy.payment_information.scheme.percentage_amount = schemePercentageVal;

            if (schemePercentageVal <= 0) {
                this.policy_copy.payment_information.scheme.payment_method = null;

                // if (schemePercentageVal < 0) {
                //     this.policy_copy.payment_information.premium_payer.percentage_amount = 100;
                //     console.log(val)
                // }
            }
        },
    }
}
</script>
<style scoped>

</style>
import { render, staticRenderFns } from "./collections_management.vue?vue&type=template&id=5943e12c&scoped=true&"
import script from "./collections_management.vue?vue&type=script&lang=js&"
export * from "./collections_management.vue?vue&type=script&lang=js&"
import style0 from "./collections_management.vue?vue&type=style&index=0&id=5943e12c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5943e12c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VIcon,VTab,VTabItem,VTabs,VTabsItems,VTabsSlider,VToolbar})

<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="40%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Resubmit Debit Order for Installment #{{ installment_schedule_item.installment_number }}</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row v-if="loan !== null">
                        <v-col cols="12" sm="8" offset-sm="1">
                            <v-menu
                                v-model="action_date_menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="action_date"
                                    label="Debit Order Action Date"
                                    hint="The date on which the debit order will be actioned"
                                    persistent-hint
                                    color="secondary"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    color="secondary"
                                    v-model="action_date"
                                    :min="getMinimumDate()"
                                    :allowed-dates="allowedActionDates"
                                    @input="action_date_menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting loan debit order
                        <v-progress-linear
                            indeterminate
                            color="success"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>

                <v-divider></v-divider>

                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                :disabled="action_date === null"
                                large
                                color="success"
                                v-on="on"
                                @click="submitDeleteAction()"
                            >
                                <v-icon large>save</v-icon>
                                <span>Proceed</span>
                            </v-btn>
                        </template>
                        <span>Proceed to delete loan</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { eventBus } from '../../../main';
import { emitAlert, emitSuccess } from '../../../utils/api';
import { businessProcessLoan } from '../gql/mutations';
export default {
    name: 'LoanPayoutDialog',
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        loan: {
            type: Object,
            default: null
        },
        installment_schedule_item: {
            type: Object,
            required: false,
        },
    },
    data: () => ({
        submitting: false,
        action_date_menu: false,
        action_date: null,

        server_response_obj: null,
        updated_schedule_item: null,
    }),
    methods: {
        closeDialog() {
            eventBus.$emit('CLOSE_SUBMIT_DEBIT_ORDER_DIALOG', this.server_response_obj, this.updated_schedule_item);
        },

        resetForm() {
            this.action_date = null;
        },

        getMinimumDate() {
            const today = new Date();

            const currentDateDay = today.getDate();
            
            // Choose 3 days in advance
            const actionDate = currentDateDay + 3;
            today.setDate(actionDate);

            return today.toISOString().substr(0, 10)
        },

        allowedActionDates(val) {
            // Exclude saturday and sunday
            const dayOfWeek = new Date(val).getDay();    
            return (
                dayOfWeek !== 0 &&
                dayOfWeek !== 6
            );
        },

        async submitDeleteAction() {
            this.submitting = true;

            const payloadObj = {
                OP_TYPE: 'SUBMIT_INSTALLMENT_ITEM_DEBIT_ORDER',
                _id: this.loan._id,

                installment_schedule_item_id: this.installment_schedule_item._id,
                effective_date: this.action_date
            };

            try {
                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcessLoan,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                this.submitting = false;
                if (res.data.businessProcessLoan.errors === true) {
                    emitAlert(res.data.businessProcessLoan.message);
                    return;
                }

                emitSuccess(res.data.businessProcessLoan.message);

                this.server_response_obj = res.data.businessProcessLoan.business_object;

                // Update installment schedule item
                const updatedInstallmentSchedule = this.server_response_obj.installment_schedule;
                this.updated_schedule_item = updatedInstallmentSchedule.filter(item => { return item._id.trim() === this.installment_schedule_item._id.trim() })[0];

                // Show Results Dialog
                this.closeDialog();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessLoan);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
    },
    mounted() {
        
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="90%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Validate Prospect Policy</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeValidateDraftedPolicyDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card v-if="policy !== null && policy !== undefined">
                <v-expansion-panels focusable :value="0">
                    <!-- Basic Details -->
                    <v-expansion-panel style="background: #f6c20a8f" >
                        <v-expansion-panel-header>
                            <h3>Basic Details</h3><br>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row
                                style="background:#ffffff; padding-top:30px"
                            >
                                <v-col cols="12" sm="2" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="policy.prospect_number"
                                        label="Prospect Number"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2" style="padding-top: 0px">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="policy.created.split('T')[0]"
                                        label="Date Captured"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        :value="formatNumber(policy.total_cover_amount)"
                                        label="Total Cover Amount"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" style="padding-top: 0px">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        :value="policy.principal_member.entity.name + ' ' + policy.principal_member.entity.surname + '  ('+policy.principal_member.entity.id_number+')'"
                                        label="Principal Member"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3" style="padding-top: 0px">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        :value="policy.product !== null ? policy.product.name : ''"
                                        label="Product"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3" style="padding-top: 0px">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        :value="formatNumber(policy.premium)"
                                        prefix="R"
                                        label="Premium"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="policy.payment_information.premium_payer.payment_method"
                                        label="Payment Method"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3" style="padding-top: 0px">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="policy.status"
                                        label="Status"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <div style="background:white; height: 10px; width: 100%"></div>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- Principal Member -->
                    <v-expansion-panel style="background: #f6c20a8f" >
                        <v-expansion-panel-header>
                            <h3>Principal Member</h3><br>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row style="background:#ffffff; padding-top:10px">
                                <v-col cols="12" sm="12" style="padding-top: 0px;">
                                    <h3>Basic Details</h3>
                                </v-col>
                            </v-row>
                            <v-row
                                style="background:#ffffff; padding-top:0px"
                            >
                                <v-col cols="12" sm="4" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="policy.principal_member.entity.name"
                                        label="Name"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="policy.principal_member.entity.surname"
                                        label="Surname"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="policy.principal_member.entity.id_number"
                                        label="ID Number"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="policy.principal_member.phone"
                                        label="Phone"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="policy.principal_member.email"
                                        label="Email"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <div style="background:white; height: 10px; width: 100%"></div>
                            </v-row>

                            <v-row style="background:#ffffff; padding-top:0px">
                                <v-col cols="12" sm="12" style="padding-top: 0px;">
                                    <h3>Address Details</h3>
                                </v-col>
                            </v-row>
                            <v-row
                                style="background:#ffffff; padding-top:0px"
                            >
                                <v-col cols="12" sm="3" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="policy.principal_member.physical_address.line1"
                                        label="Line 1"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="policy.principal_member.physical_address.line1"
                                        label="Line 2"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="policy.principal_member.physical_address.line1"
                                        label="Line 3"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="policy.principal_member.physical_address.line1"
                                        label="Postal Code"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <div style="background:white; height: 10px; width: 100%"></div>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- Premium Payer -->
                    <v-expansion-panel style="background: #f6c20a8f" >
                        <v-expansion-panel-header>
                            <h3>Premium Payer</h3><br>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row style="background:#ffffff; padding-top:10px">
                                <v-col cols="12" sm="12" style="padding-top: 0px;">
                                    <h3>Basic Details</h3>
                                </v-col>
                            </v-row>
                            <v-row
                                style="background:#ffffff; padding-top:0px"
                            >
                                <v-col cols="12" sm="4" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="policy.premium_payer.entity.name"
                                        label="Name"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="policy.premium_payer.entity.surname"
                                        label="Surname"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="policy.premium_payer.entity.id_number"
                                        label="ID Number"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="policy.premium_payer.phone"
                                        label="Phone"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="policy.premium_payer.email"
                                        label="Email"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <div style="background:white; height: 10px; width: 100%"></div>
                            </v-row>

                            <v-row style="background:#ffffff; padding-top:0px">
                                <v-col cols="12" sm="12" style="padding-top: 0px;">
                                    <h3>Address Details</h3>
                                </v-col>
                            </v-row>
                            <v-row
                                style="background:#ffffff; padding-top:0px"
                            >
                                <v-col cols="12" sm="3" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="policy.premium_payer.physical_address.line1"
                                        label="Line 1"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="policy.premium_payer.physical_address.line1"
                                        label="Line 2"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="policy.premium_payer.physical_address.line1"
                                        label="Line 3"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="policy.premium_payer.physical_address.line1"
                                        label="Postal Code"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <div style="background:white; height: 10px; width: 100%"></div>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- Dependents -->
                    <v-expansion-panel style="background: #f6c20a8f" expand>
                        <v-expansion-panel-header>
                            <h3>Dependents</h3><br>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row
                                v-for="(dependent, index) in policy.dependents"
                                :key="dependent._id"
                                dense
                                style="background:#ffffff; padding-top:10px; padding-bottom:0px;"
                            >
                                <v-col cols="12" sm="4" style="padding-top: 0px">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        :value="dependent.entity.name + ' ' + dependent.entity.surname + '  (' + dependent.entity.id_number + ')'"
                                        :label="index === 0 ? 'Dependent' : null"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="1" style="padding-top: 0px">
                                    <v-text-field 
                                        style="padding-top: 0px"
                                        :value="calculateIdValidity(dependent)"
                                        :label="index === 0 ? 'ID Valid' : null"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="1" style="padding-top: 0px">
                                    <v-text-field 
                                        style="padding-top: 0px"
                                        :value="calculateMemberAge(dependent)"
                                        :label="index === 0 ? 'Age' : null"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2" style="padding-top: 0px">
                                    <v-text-field
                                        style="padding-top: 0px; color: red"
                                        :value="dependent.elected_dependent === true ? 'YES' : 'NO'"
                                        :label="index === 0 ? 'Elected Dependent' : null"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>


                                    <!-- <span v-if="dependent.elected_dependent === true"><v-icon color="success">mdi-check</v-icon></span>
                                    <span v-else><v-icon color="red">mdi-close</v-icon></span> -->
                                </v-col>
                                <v-col cols="12" sm="2" style="padding-top: 0px">
                                    <v-text-field 
                                        style="padding-top: 0px; color: red"
                                        :value="dependent.spouse_to_principal === true ? 'YES' : 'NO'"
                                        :label="index === 0 ? 'Spouse To Principal' : null"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>


                                    <!-- <span v-if="dependent.spouse_to_principal === true"><v-icon color="success">mdi-check</v-icon></span>
                                    <span v-else><v-icon color="red">mdi-close</v-icon></span> -->
                                </v-col>
                                <v-col cols="12" sm="2" style="padding-top: 0px">
                                    <v-text-field 
                                        style="padding-top: 0px"
                                        :value="formatNumber(dependent.cover_amount)"
                                        prefix="R"
                                        :label="index === 0 ? 'Cover Amount' : null"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                <v-card-text>
                    <div style="background:white; height: 20px; width: 100%;"></div>
                    <!-- VALIDATION RESULTS -->
                    <div v-if="validation_results !== null">
                        <v-row style="background:#4490a052;">
                            <v-col cols="12" sm="12">
                                <h1>Validation Results</h1>
                            </v-col>
                        </v-row>
                        <v-row style="background:#4490a052; ">
                            <v-col cols="12" sm="12" style="padding-top:0px">
                                <h3>Overall Status: {{validation_overall_status ? 'VALID' : 'INVALID'}}</h3>
                            </v-col>
                        </v-row>
                        <v-row
                            v-for="(result, index) in validation_results"
                            :key="result._id"

                            style="background:#4490a052; padding-bottom:0px; height: 45px"
                        >
                            <v-col cols="12" sm="1" style="padding-top: 0px">
                                <v-text-field
                                    style="padding-top: 0px"
                                    :value="result.rule_id"
                                    :label="index === 0 ? 'Rule ID' : null"
                                    type="text"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="7" style="padding-top: 0px">
                                <v-text-field 
                                    style="padding-top: 0px"
                                    v-model="result.rule_name"
                                    :label="index === 0 ? 'Rule Name' : null"
                                    type="text"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="1" style="padding-top: 0px">
                                <v-text-field
                                    style="padding-top: 0px"
                                    :value="result.min"
                                    :label="index === 0 ? 'Min' : null"
                                    type="text"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="1" style="padding-top: 0px">
                                <v-text-field
                                    style="padding-top: 0px"
                                    :value="result.max"
                                    :label="index === 0 ? 'Max' : null"
                                    type="text"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="1" style="padding-top: 0px">
                                <v-text-field
                                    style="padding-top: 0px"
                                    :value="result.policy_param_value"
                                    :label="index === 0 ? 'Value' : null"
                                    type="text"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="1" style="padding-top: 0px">
                                <v-tooltip bottom v-if="result.valid">
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        color="success"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                    >done</v-icon>
                                    </template>
                                    <span>Rule ran successfully</span>
                                </v-tooltip>
                                <v-tooltip bottom v-else-if="result.waived">
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        color="primary"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                    >mdi-cancel</v-icon>
                                    </template>
                                    <span>Rule was waived</span>
                                </v-tooltip>
                                <v-tooltip bottom v-else>
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        style="color:red"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                    >mdi-close</v-icon>
                                    </template>
                                    <span>{{result.fail_reason}}</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </div>
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting Policy Validation
                        <v-progress-linear
                            indeterminate
                            :active="submitting"
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>

                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="amber"
                                v-on="on"
                                @click="submitPolicyValidation()"
                            >
                                <v-icon large>done</v-icon>
                            </v-btn>
                        </template>
                        <span>Run Policy Validation</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom v-if="validation_overall_status === true">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                class="mx-2"
                                v-on="on"
                                @click="upgradePolicyStatus()"
                            >
                                <v-icon large>mdi-checkbox-marked-circle-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Upgrade Policy Status</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../../../../../utils/api";
import { eventBus } from "../../../../../../../main";
import converter from "../../../../../../../mixins/converter";
import { PROSPECT_POLICY_STATUS_ACTIONS } from '../../../../../../../../config';
import { extractBirthDate, idValid } from '../../../../../../../utils/id_number_check';
import { calculateEntityAge } from '../../../../../../../utils/age';

import { businessValidatePolicy, businessProcessProspectPolicy } from "../../../gql/mutations";

export default {
    mixins: [converter],
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        policy: {
            type: Object,
            default: null
        },
    },
    data: () => ({
        submitting: false,
        server_response_obj: null,
        validation_results: null,
        validation_overall_status: null,
    }),
    methods: {
        closeValidateDraftedPolicyDialog() {
            this.validation_overall_status = false;
            this.validation_results = null;
            eventBus.$emit('CLOSE_VALIDATE_POLICY_DIALOG', this.server_response_obj);
        },


        calculateIdValidity(dependent) {
            let result = false;
            const id_number = dependent.entity.id_number;
            
            if (id_number.length === 13) {
                result = idValid(id_number);
            }

            return result;
        },

        calculateMemberAge(dependent) {
            let age = 0;

            const id_number = dependent.entity.id_number;
            
            if (id_number.length > 6) {
                const birthDateObj = extractBirthDate(id_number);
                age = calculateEntityAge(birthDateObj.century_string + birthDateObj.partial_date_string);
            }

            return age;
        },
        calculateMemberCoverAmount(dependent) {
            let result = 0;

            const id_number = dependent.entity.id_number;
            
            if (id_number.length > 6) {
                const birthDateObj = extractBirthDate(id_number);
                const memberAge = calculateEntityAge(birthDateObj.century_string + birthDateObj.partial_date_string);

                if (this.policy.product !== null) {
                    const benefitPayout = this.policy.product.primary_product.payout_structure.filter(payoutObj => {
                        return (
                            payoutObj.category.membership_category === 'Principal Member' &&
                            memberAge >= payoutObj.category.start_age &&
                            memberAge <= payoutObj.category.end_age
                        );
                    });

                    if (benefitPayout[0])
                        result = benefitPayout[0].payout_amount;
                }
            }

            return result;
        },




        async submitPolicyValidation() {
            // Then submit the policy validation
            this.submitting = true;
            
            // Submit policy validation to server
            try {
                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessValidatePolicy,
                                                variables: {
                                                    policy_id: this.policy._id
                                                },
                                            });
                this.submitting = false;
                if (res.data.businessValidatePolicy.errors === true) {
                    emitAlert(res.data.businessValidatePolicy.message);
                    return;
                }
                
                this.validation_overall_status = res.data.businessValidatePolicy.overall_status;
                this.validation_results = res.data.businessValidatePolicy.validation_results;
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessValidatePolicy.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
        },
        async upgradePolicyStatus() {
            this.submitting = true;

            try {
                const payloadObj = {
                    OP_TYPE: 'STATUS',
                    _id: this.policy._id,

                    action_code: PROSPECT_POLICY_STATUS_ACTIONS.DRAFT_TO_PENDING
                };

                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcessProspectPolicy,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                this.submitting = false;
                if (res.data.businessProcessProspectPolicy.errors === true) {
                    emitAlert(res.data.businessProcessProspectPolicy.message);
                    return;
                }

                this.server_response_obj = res.data.businessProcessProspectPolicy.business_object;
                this.closeValidateDraftedPolicyDialog();
                this.server_response_obj = null;

                emitSuccess(res.data.businessProcessProspectPolicy.message);
                return;
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessProspectPolicy.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
        }
    },
    computed: {},
    mounted() {
    }
}
</script>
<style scoped>

</style>
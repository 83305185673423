var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticClass:"pa-0",attrs:{"color":"secondary","dark":"","height":"50","extension-height":"3"}},[_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"10px"},attrs:{"sm":"8"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.refreshDataTable()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh")])]),(_vm.selectedRelType != null)?_c('v-tooltip',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.openEditRelTypeDialog()}}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,false,2579731027)},[_c('span',[_vm._v("Edit Record")])]):_vm._e()],1),_c('v-col',{staticStyle:{"margin-top":"10px"},attrs:{"sm":"4"}},[_c('div',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"amber black--text"},on:{"click":function($event){return _vm.openAddRelTypeDialog()}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v("Add Relationship")])])],1)])],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":"600","headers":_vm.rel_type_headers,"items":_vm.relationship_types_list,"item-key":"_id","single-select":true,"item-class":"secondary","hide-default-footer":"","disable-pagination":true,"loading":_vm.data_table_loading,"loading-text":"Loading... Please wait"},on:{"click:row":_vm.selectMainTableRow},scopedSlots:_vm._u([{key:"item.payout_target_roles_for_principal",fn:function(ref){
var item = ref.item;
return [_c('td',_vm._l((item.payout_target_roles_for_principal),function(role){return _c('span',{key:role._id},[_vm._v(" # "+_vm._s(role)),_c('br')])}),0)]}}],null,true)}),_c('AddRelTypeDialog',{attrs:{"dialog":_vm.open_add_rel_type_dialog,"rel_type_list":_vm.relationship_types_list}}),(_vm.selectedRelType !== null)?_c('EditRelTypeDialog',{attrs:{"dialog":_vm.open_edit_rel_type_dialog,"selected_rel_type":_vm.selectedRelType,"selected_rel_type_copy":_vm.selectedRelTypeCopy,"rel_type_list":_vm.relationship_types_list}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticStyle:{"background":"white","overflow-y":"auto","height":"100%"}},[_c('v-toolbar',{staticStyle:{"border":"1px solid #07ad2d"},attrs:{"dark":"","color":"secondary"}},[_c('v-spacer'),_c('v-toolbar-title',[_c('b',[_vm._v("Capture Signatures")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.resetForm()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Reset Form")])]),(_vm.server_response_obj === null)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.closeAddPolicyDialog()}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1),_c('v-card',[_c('v-card-text',[_c('v-row',{staticStyle:{"background":"#f4000030"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"color":"secondary","label":"Signature Location","type":"text","hint":"Name of Location where signature is being captured","persistent-hint":"","required":""},model:{value:(_vm.signature_location),callback:function ($$v) {_vm.signature_location=$$v},expression:"signature_location"}})],1)],1),_c('br'),_c('br'),_c('br'),_c('v-expansion-panels',{attrs:{"focusable":""}},[_c('v-expansion-panel',{key:"principal_member",staticStyle:{"background":"#eaeaea","padding-bottom":"10px"},attrs:{"expand":""}},[_c('v-expansion-panel-header',[_c('h3',[_vm._v("Principal Member Signature And Initials")])]),_c('v-expansion-panel-content',[_c('br'),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('h1',[_vm._v("Principal Member Signature")]),_c('br'),_c('Signature',{attrs:{"unique_identifier":_vm.principal_member.signature.id,"signature_purpose":'PRINCIPAL_MEMBER_SIGNATURE'}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('h1',[_vm._v("Principal Member Initials")]),_c('br'),_c('Signature',{attrs:{"unique_identifier":_vm.principal_member.initials.id,"signature_purpose":'PRINCIPAL_MEMBER_INITIALS'}})],1)],1),_c('br')],1)],1),((
                                _vm.premiumPayerSameAsPrincipalMember === false &&
                                _vm.policy.payment_information.premium_payer.payment_method === 'DEBIT ORDER'
                            ))?_c('v-expansion-panel',{key:"premium_payer",staticStyle:{"background":"#eaeaea","padding-bottom":"10px"},attrs:{"expand":""}},[_c('v-expansion-panel-header',[_c('h3',[_vm._v("Premium Payer Signature And Initials")])]),_c('v-expansion-panel-content',[_c('br'),_c('v-row',[((
                                            _vm.premiumPayerSameAsPrincipalMember === false &&
                                            _vm.policy.payment_information.premium_payer.payment_method === 'DEBIT ORDER'
                                        ))?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('h1',[_vm._v("Premium Payer Signature")]),_c('br'),_c('Signature',{attrs:{"unique_identifier":_vm.premium_payer.signature.id,"signature_purpose":'PREMIUM_PAYER_SIGNATURE'}})],1):_vm._e(),((
                                            _vm.premiumPayerSameAsPrincipalMember === false &&
                                            _vm.policy.payment_information.premium_payer.payment_method === 'DEBIT ORDER'
                                        ))?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('h1',[_vm._v("Premium Payer Initials")]),_c('br'),_c('Signature',{attrs:{"unique_identifier":_vm.premium_payer.initials.id,"signature_purpose":'PREMIUM_PAYER_INITIALS'}})],1):_vm._e()],1),_c('br')],1)],1):_vm._e()],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"large":"","color":"success","disabled":_vm.submitBtnDisabled()},on:{"click":function($event){return _vm.savePolicy()}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("save")])],1)],1)],1),_c('v-card',[(_vm.submitting)?_c('v-card-text',{staticClass:"text-center"},[_vm._v(" Submitting Policy Details "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"green","height":"5","active":_vm.submitting}})],1):_vm._e(),(_vm.submitting)?_c('v-list',{attrs:{"flat":""}},[_c('v-subheader',[_vm._v("Saving Policy Signatures")])],1):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import gql from 'graphql-tag'

export const getSystemFinanceAccounts = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on FinanceAccount {
                    __typename
                    _id
                    name
                    description
                    type
                }
            }
        }
    }
`
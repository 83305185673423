import gql from 'graphql-tag'

export const createFinanceTransaction = gql`
    mutation finProcessFinanceAccountTransaction($input: FinanceAccountTransactionInput) {
        finProcessFinanceAccountTransaction(input: $input) {
            errors
            message
            business_object {
                ... on FinanceAccountTransaction {
                    __typename
                    _id
                    transaction_date

                    amount
                    from {
                        _id
                        name
                        balance
                        last_updated
                    }
                    to {
                        _id
                        name
                        balance
                        last_updated
                    }
                    reference
                    additional_reference

                    balance_from
                    balance_to

                    created
                    created_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }
                }
            }
        }
    }
`

export const businessProcessSchemeAllocation = gql`
    mutation businessProcessSchemeAllocation($input: SchemeAllocationInput!) {
        businessProcessSchemeAllocation(input: $input) {
            errors
            message
            business_object {
                ... on SchemeAllocation {
                    __typename
                    _id
                    allocation_number

                    scheme {
                        _id
                        scheme_number
                        name
                    }

                    transaction_date
                    transaction_amount
                    transaction_description
                    transaction_reference

                    allocation_amount
                    bank_feed_item {
                        _id
                    }
                    payment_method {
                        _id
                        name
                    }
                    status

                    captured_by {
                        __typename
                        _id
                        entity {
                            __typename
                            _id
                            name
                            surname
                        }
                    }
                    created

                    policies {
                        policy {
                            _id
                            policy_number
                            principal_member {
                                _id
                                name
                                surname
                                id_number
                            }
                            premium
                        }
                        premium_amount
                        scheme_contribution_amount
                    }
                    documents {
                        __typename
                        _id
                        type
                        mime_type
                        name

                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }
                }
            }
        }
    }
`

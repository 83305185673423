<template>
    <div>
        <v-toolbar
            color="secondary"
            dark
            class="pa-0"
            height="50"
            extension-height="3"
        >
            <v-row>
                <!-- Grid Buttons -->
                <v-col sm="8" style="margin-top:10px">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="refreshProducts()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh</span>
                    </v-tooltip>
                    <v-tooltip v-if="selectedProduct != null">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="openEditProductDetailsDialog()"
                            >
                                <v-icon>edit</v-icon>
                            </v-btn>
                        </template>
                        <span>Edit Record</span>
                    </v-tooltip>
                </v-col>

                <!-- Action Buttons -->
                <v-col sm="4" style="margin-top:10px">
                    <!-- <v-spacer></v-spacer> -->
                    <div class="text-right">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="openAddProductDialog()"
                                    v-on="on"
                                >
                                    <v-icon medium>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Product</span>
                        </v-tooltip>
                    </div>
                </v-col>
            </v-row>
        </v-toolbar>
        <multipane class="horizontal-panes" layout="horizontal" style="height: 100%" @paneResize="paneResizing">
            <div class="pane" :style="{ height: '50%', maxHeight: '50%'}">
                <v-data-table
                    fixed-header
                    :height="main_tab_height"
                    :headers="product_details_headers"
                    :items="products"
                    item-key="_id"
                    class="elevation-1"
                    :single-select="true"
                    @click:row="selectMainTableRow"
                    item-class="secondary"
                    :disable-pagination="true"
                    :loading="data_table_loading"
                    hide-default-footer
                >
                    <!-- <template v-slot:[`item.customer_premium`]="{ item }" >
                        <td>
                            {{item.underwriter_premium + item.broker_markup}}
                        </td>
                    </template> -->
                </v-data-table>
            </div>
            <multipane-resizer></multipane-resizer>
            <div class="pane tab-nav" :style="{ height: '30%', maxHeight: '50%', minHeight: '30%' }">
                <v-tabs
                    v-model="sub_tabs"
                    color="transparent"
                    class="tab-nav"
                    hide-slider
                    height="35px"
                    grow
                >
                    <!-- Credit Rules Sub Tab -->
                    <template>
                        <v-tab key="credit_rules_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Credit Rules
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>
                </v-tabs>
                <v-tabs-items v-model="sub_tabs">
                    <!-- Sub-Tab Toolbar -->
                    <v-toolbar
                        color="secondary"
                        dark
                        class="pa-0"
                        height="50"
                        extension-height="3"
                    >
                        <!-- Action Buttons for the Credit-Rules sub-tab -->
                        <v-tooltip bottom v-if="sub_tabs == 0 && selectedProduct != null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddProductCreditRulesDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Credit Rules</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs == 0  && selectedCreditRule != null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    v-on="on"
                                    @click="removeCreditRuleItem()"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Remove Credit Rule</span>
                        </v-tooltip>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <!-- Credit Rules Sub-Tab Content -->
                    <v-tab-item key="credit_rules_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="credit_rules_headers"
                            :items="credit_rules"
                            item-key="_id"
                            class="elevation-1"
                            :single-select="true"
                            @click:row="selectCreditRulesRow"
                            item-class="secondary"
                            :disable-pagination="true"
                            hide-default-footer
                        ></v-data-table>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </multipane>
        <AddProductDialog
            :dialog="open_add_product_dialog"
        />
        <EditProductDetailsDialog
            :dialog="open_edit_product_details_dialog"
            :product="selectedProduct"
            :product_copy="selectedProductCopy"
        />
        <AddProductRuleTypesDialog
            v-if="selectedProduct !== null"
            :dialog="open_product_rule_parameters_dialog"
            :product_id="selectedProduct._id"
            :rule_level="product_rule_level"
        />
    </div>
</template>
<script>
import { Multipane, MultipaneResizer } from 'vue-multipane';

import AddProductDialog from './dialogs/add_product';
import EditProductDetailsDialog from './dialogs/edit_product_details';
import AddProductRuleTypesDialog from './dialogs/add_product_rules';
import { eventBus } from "../../../../main";
import { emitAlert, emitSuccess } from '../../../../utils/api';

import { getCreditProducts } from "./gql/queries";
import { productProcessLoanProductPrimary } from "./gql/mutations";

export default {
    components: {
        Multipane,

        MultipaneResizer,
        AddProductDialog,
        EditProductDetailsDialog,
        AddProductRuleTypesDialog
    },

    data: () => ({
        sub_tabs: null,
        main_tab_height: '250px',
        sub_tab_height:'180',

        // main data-table row
        selectedProduct: null,
        selectedProductMetaData: null,
        selectedProductCopy: null,

        // credit rule data-table row
        selectedCreditRule: null,
        selectedCreditRuleMetaData: null,

        product_details_headers: [
            { text: 'Name', value: 'name' },

            // { text: 'Max Payment Holidays', value: 'max_payment_holidays' },

            // { text: 'Min Loan Amount', value: 'min_loan_amount' },
            // { text: 'Max Loan Amount', value: 'max_loan_amount' },

            // { text: 'Min Installments', value: 'min_number_of_installments' },

            // { text: 'Monthly Fixed Fee', value: 'monthly_fixed_fee' },
            // { text: 'Monthly Finance Charge %', value: 'monthly_finance_charge_percentage' },
            // { text: 'Monthly Finance Charge', value: 'monthly_finance_charge_amount' },
        ],
        credit_rules_headers: [
            { text: 'Rule ID', value: 'rule_parameter.id' },
            { text: 'Rule Type', value: 'rule_parameter.name' },
            { text: 'Min', value: 'min' },
            { text: 'Max', value: 'max' },
        ],
        
        // Table data models
        credit_rules: [],
        products: [],

        product_rule_level: null,

        // Dialogs
        data_table_loading: false,

        open_add_product_dialog: false,
        open_edit_product_details_dialog: false,
        open_product_rule_parameters_dialog: false,
    }),

    methods: {
        //-------------------- OPEN DIALOGS ---------------------//
        openAddProductDialog() {
            this.open_add_product_dialog = true;
        },
        openEditProductDetailsDialog() {
            this.open_edit_product_details_dialog = true;
        },
        openAddProductCreditRulesDialog() {
            this.product_rule_level = 'loan';
            this.open_product_rule_parameters_dialog = true;
        },

        //------------------- CLOSE DIALOGS---------------------//
        updateUITableModel(new_product) {
            if (new_product !== null) {
                const obj_index = this.products.map(obj => { return obj._id; }).indexOf(new_product._id);
                this.products[obj_index] = new_product;

                // Deep copy products for refreshing main data table
                const temp_arr = Array.from(this.products);
                this.products = temp_arr;
                
                this.selectedProduct = new_product;
                // this.selectedProductMetaData = null;

                // Update loan/credit rules model
                this.credit_rules = new_product.rules.filter(rule => { return rule.rule_parameter.level === "loan" });
            }
        },
        closeAddProductDialog(new_product) {
            if (new_product !== null) {
                this.products.push(new_product);
                this.selectedProduct = null;
            }

            this.open_add_product_dialog = false;
        },
        closeEditProductDetailsDialog(new_product) {
            this.updateUITableModel(new_product);
            this.open_edit_product_details_dialog = false;
        },
        closeAddProductRuleTypesDialog(new_product) {
            this.updateUITableModel(new_product);
            this.open_product_rule_parameters_dialog = false;
        },

        selectMainTableRow(item, item_metadata) {
            // Unselect credit rule sub-tab row
            this.selectedCreditRule = null;
            if (this.selectedCreditRuleMetaData !== null) {
                this.selectedCreditRuleMetaData.select(false);
            }

            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                this.selectedProduct = item;
                this.selectedProductMetaData = item_metadata;
                // Update credit rules model
                this.credit_rules = item.rules.filter(rule => { return rule.rule_parameter.level === "loan" });

                // Make product copy
                this.selectedProductCopy = Object.assign({}, this.selectedProduct);
            } else {
                this.selectedProduct = null;
                this.selectedProductMetaData = null;
                // Unighlight selected row
                item_metadata.select(false);

                // Make product copy
                this.selectedProductCopy = null;

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                // Update credit rules model
                this.credit_rules = [];

                // ----------------------UPDATE VISIBILITY OF ACTION BUTTONS -----------------------//
            }
        },
        selectCreditRulesRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);

                this.selectedCreditRule = item;
                this.selectedCreditRuleMetaData = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedCreditRule = null;
                this.selectedCreditRuleMetaData = null;
            }
        },

        async removeCreditRuleItem() {
            try {
                this.data_table_loading = true;

                const payloadObj = {
                    OP_TYPE: 'REMOVE_PRODUCT_RULE',
                    _id: this.selectedProduct._id,

                    rules: [
                        {
                            _id: this.selectedCreditRule._id,

                            // Placeholder fields
                            level: '',
                            rule_parameter: '',
                            min: 0,
                            max: 0,
                        }
                    ],

                    // // Placeholder fields
                    name: '',
                    // max_payment_holidays: 0,
                    // min_loan_amount: 0,
                    // max_loan_amount: 0,
                    // min_number_of_installments: 0,

                    // monthly_fixed_fee: 0,
                    // monthly_finance_charge_percentage: 0,
                    // monthly_finance_charge_amount: 0,
                };

                const res = await this.$apollo
                                            .mutate({
                                                mutation: productProcessLoanProductPrimary,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.productProcessLoanProductPrimary.errors === true) {
                    emitAlert(res.data.productProcessLoanProductPrimary.message);
                    this.submitting = false;
                    return;
                }
                
                this.data_table_loading = false;
                emitSuccess(res.data.productProcessLoanProductPrimary.message);

                // Prepare data returned
                this.selectedCreditRule = null;
                this.selectedCreditRuleMetaData = null;
                this.credit_rules = res.data.productProcessLoanProductPrimary.business_object.rules.filter(rule => { return rule.rule_parameter.level === "loan" });

                // refresh main data table
                const obj_index = this.products.map(obj => { return obj._id; }).indexOf(res.data.productProcessLoanProductPrimary.business_object._id);
                this.products[obj_index] = res.data.productProcessLoanProductPrimary.business_object;

                // Deep copy products for refreshing main data table
                const temp_arr = Array.from(this.products);
                this.products = temp_arr;
                
                this.selectedProduct = res.data.productProcessLoanProductPrimary.business_object;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.productProcessLoanProductPrimary.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },

        async refreshProducts() {
            try {
                this.products = [];
                this.data_table_loading = true;
                const res = await this.$apollo
                                        .query({
                                            query: getCreditProducts,
                                            // fetchPolicy: 'network-only',
                                            fetchPolicy: 'no-cache',
                                            errorPolicy: 'all',
                                            variables: {
                                                objectType: 'LOAN_PRODUCT_PRIMARY',
                                                pagination: {
                                                    page: this.page,
                                                    limit: this.limit
                                                }
                                            },
                                        });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }

                this.data_table_loading = false;
                this.products = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
        
        resetProductEditForm(updatedProduct) {
            if (
                updatedProduct.name !==                             this.selectedProduct.name ||

                parseFloat(updatedProduct.max_payment_holidays) !==    parseFloat(this.selectedProduct.max_payment_holidays) ||

                parseFloat(updatedProduct.min_loan_amount) !==    parseFloat(this.selectedProduct.min_loan_amount) ||
                parseFloat(updatedProduct.max_loan_amount) !==    parseFloat(this.selectedProduct.max_loan_amount) ||

                parseFloat(updatedProduct.min_number_of_installments) !==    parseFloat(this.selectedProduct.min_number_of_installments) ||

                parseFloat(updatedProduct.monthly_fixed_fee) !==    parseFloat(this.selectedProduct.monthly_fixed_fee) ||
                parseFloat(updatedProduct.monthly_finance_charge_percentage) !==    parseFloat(this.selectedProduct.monthly_finance_charge_percentage) ||
                parseFloat(updatedProduct.monthly_finance_charge_amount) !==        parseFloat(this.selectedProduct.monthly_finance_charge_amount)
            ) {
                this.selectedProductCopy = Object.assign({}, this.selectedProduct);
            }
        },

        paneResizing(pane, container, size) {
            const size_int = parseInt(size);

            this.main_tab_height = size;
            const max_screen_height = window.innerHeight - 300;

            const sub_tab_percentage = 1 - (size_int/max_screen_height);
            this.sub_tab_height = sub_tab_percentage * max_screen_height;
        }
    },

    async mounted() {
        eventBus.$on('CLOSE_ADD_PRODUCT_DIALOG', this.closeAddProductDialog);
        eventBus.$on('CLOSE_EDIT_PRODUCT_DETAILS_DIALOG', this.closeEditProductDetailsDialog);

        eventBus.$on('CLOSE_ADD_LOAN_PRODUCT_RULE_PARAMETERS_DIALOG', this.closeAddProductRuleTypesDialog);

        // Edit form resetter
        eventBus.$on('RESET_PRIMARY_PRODUCT_EDIT_FORM', this.resetProductEditForm);

        /**
         * The following logic is for manually triggering a pane-divider movement
         * so as to get the windows to properly resize
         */
        this.paneResizing(null, null, 250);

        try {
            this.data_table_loading = true;
            const res = await this.$apollo
                                        .query({
                                            query: getCreditProducts,
                                            // fetchPolicy: 'network-only',
                                            fetchPolicy: 'no-cache',
                                            errorPolicy: 'all',
                                            variables: {
                                                objectType: 'LOAN_PRODUCT_PRIMARY',
                                                pagination: {
                                                    page: this.page,
                                                    limit: this.limit
                                                }
                                            },
                                        });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.data_table_loading = false;
                return;
            }

            this.data_table_loading = false;
            this.products = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.data_table_loading = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
        }
    },

    watch: {
        
    }
}
</script>
<style scoped>
/* Overriding of textbox min height */
 .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
}
</style>
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Edit Scheme Address</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row style="background:#eaeaea; padding-bottom:10px">
                        <v-col cols="12" sm="3">
                            <v-select
                                v-model="address_copy.type"
                                :items="address_types"
                                color="secondary"
                                label="Address Type"
                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                v-model="address_copy.line1"
                                color="secondary"
                                label="Line 1"
                                autocomplete="some-random-text2"
                                type="text"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                v-model="address_copy.line2"
                                color="secondary"
                                label="Line 2"
                                autocomplete="some-random-text2"
                                type="text"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                v-model="address_copy.line3"
                                color="secondary"
                                label="Line 3"
                                autocomplete="some-random-text2"
                                type="text"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                v-model="address_copy.line4"
                                color="secondary"
                                label="Postal Code"
                                autocomplete="some-random-text314"
                                type="text"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <!-- Buttons for Adding/Removing Addresses -->
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting Scheme Address Update
                        <v-progress-linear
                            indeterminate
                            :active="submitting"
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submit_disabled()"
                                @click="submitData()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Update Scheme Address</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";
import { address_types } from "../../../../config";

import { entityProcessScheme } from "../gql/mutations";

export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        scheme_id: {
            type: String,
            default: null
        },
        address_original: {
            type: Object,
            required: false,
            default: null,
        },
        address_copy: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data: () => ({
        server_response_obj: null,
        submitting: false,
        // submit_disabled: true,

        address_types: address_types,
    }),
    computed: {
        
    },
    methods: {
        submit_disabled() {
            if (
                (this.address_copy.type + '').trim() === (this.address_original.type + '').trim() &&
                (this.address_copy.line1 + '').trim() === (this.address_original.line1 + '').trim() &&
                (this.address_copy.line2 + '').trim() === (this.address_original.line2 + '').trim() &&
                (this.address_copy.line3 + '').trim() === (this.address_original.line3 + '').trim() &&
                (this.address_copy.line4 + '').trim() === (this.address_original.line4 + '').trim()
            ) {
                return true;
            } else {
                // Cater for if line 1 is an empty string
                if (typeof(this.address_copy.line1) === 'string') {
                    if (
                        this.address_copy.line1.trim() === '' &&
                        this.address_original.line1 === null    
                    ) {
                        this.address_copy.line1 = null;
                        return true;
                    }
                }
                // Cater for if line 2 is an empty string
                if (typeof(this.address_copy.line2) === 'string') {
                    if (
                        this.address_copy.line2.trim() === '' &&
                        this.address_original.line2 === null    
                    ) {
                        this.address_copy.line2 = null;
                        return true;
                    }
                }
                // Cater for if line 3 is an empty string
                if (typeof(this.address_copy.line3) === 'string') {
                    if (
                        this.address_copy.line3.trim() === '' &&
                        this.address_original.line3 === null    
                    ) {
                        this.address_copy.line3 = null;
                        return true;
                    }
                }
                // Cater for if line 4 is an empty string
                if (typeof(this.address_copy.line4) === 'string') {
                    if (
                        this.address_copy.line4.trim() === '' &&
                        this.address_original.line4 === null    
                    ) {
                        this.address_copy.line4 = null;
                        return true;
                    }
                }
            }
            return false;
        },
        closeDialog() {
            eventBus.$emit('CLOSE_EDIT_SCHEME_ADDRESS_DIALOG', this.server_response_obj);
        },
        resetForm() {
            eventBus.$emit('RESET_ADDRESS_EDIT_FORM', this.address_copy);
        },

        async submitData() {
            this.submitting = true;

            try {
                // Payload with old values
                const payload = {
                    type: this.address_copy.type,
                    line1: this.address_copy.line1,
                    line2: this.address_copy.line2,
                    line3: this.address_copy.line3,
                    line4: this.address_copy.line4
                };

                const payloadObj = {
                    OP_TYPE: 'UPDATE_ADDRESS',
                    _id: this.scheme_id,

                    addresses: [
                        {
                            _id: this.address_original._id,
                            ...payload
                        }
                    ]
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: entityProcessScheme,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.entityProcessScheme.errors === true) {
                    emitAlert(res.data.entityProcessScheme.message);
                    this.submitting = false;
                    return;
                }


                this.submitting = false;
                this.server_response_obj = res.data.entityProcessScheme.business_object;
                this.closeDialog();
                emitSuccess(res.data.entityProcessScheme.message);

                // Reset fields
                this.server_response_obj = null;
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.entityProcessScheme.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
    },
    mounted() {
        
    },
    // updated()
    watch: {
        
    }
}
</script>
<style scoped>

</style>
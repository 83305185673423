<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Underwriter Document Info</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeViewUnderwiterDocumentDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <div v-if="document !== null">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-if="document !== null"
                                    v-model="document.type"
                                    label="Document Type"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-if="document !== null"
                                    v-model="document.name"
                                    label="Document Name"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-if="document !== null"
                                    :value="document.created.split('T')[0] + '   ' + document.created.split('T')[1].substr(0, 8)"
                                    label="Created At"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-if="document !== null && document.captured_by !== null"
                                    :value="document.captured_by.entity.name + ' ' + document.captured_by.entity.surname"
                                    label="Captured By"
                                    disabled
                                    required
                                ></v-text-field>
                                <v-text-field
                                    v-else
                                    value="Pule Kenneth Mojanaga"
                                    label="Captured By"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-if="document !== null"
                                    :value="document.deleted ? 'Yes' : 'No'"
                                    label="Deleted"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="document.deleted">
                                <v-text-field
                                    v-if="document !== null && document.deleted_by !== null"
                                    :value="document.deleted_by.entity.name + ' ' + document.deleted_by.entity.surname"
                                    label="Deleted By"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="document.deleted">
                                <v-text-field
                                    v-if="document !== null"
                                    v-model="document.delete_reason"
                                    label="Delete Reason"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                color="amber"
                                v-on="on"
                                @click="previewServerFile()"
                            >
                                <v-icon>remove_red_eye</v-icon>
                            </v-btn>
                        </template>
                        <span>Preview File From Server</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <DocPreviewDialog
            :dialog="open_doc_preview_dialog"
            :url="file_url"
            :doc_type="doc_type"
            @CLOSE_DOC_PREVIEW_DIALOG="closeDocPreviewDialog()"
        />
    </v-row>
</template>
<script>
import DocPreviewDialog from '../../../../components/doc_preview';
import { getDocumentumFile } from "../../../../utils/api";
import { eventBus } from "../../../../main";
export default {
    components: {
        DocPreviewDialog
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        document: {
            type: Object,
            required: false,
            default: () => {
                return {
                    type: null,
                    name: null,
                    mime_type: null,
                    created: null,
                    deleted: null,
                    deleted_by: null,
                    delete_reason: null,
                }
            }
        },
        param_path: {
            type: String,
            required: false,
            default: ''
        },
        param_id: {
            type: String,
            required: false,
            default: ''
        }
    },
    data: () => ({
        server_resp_obj: null,

        // File props
        file: '',
        file_url: null,
        doc_type: null,
        open_doc_preview_dialog: false,
        
    }),
    methods: {
        closeViewUnderwiterDocumentDialog() {
            eventBus.$emit('CLOSE_VIEW_UNDERWRITER_DOCUMENT_DIALOG', this.server_resp_obj);
        },
        closeDocPreviewDialog() {
            this.open_doc_preview_dialog = false;
        },

        previewServerFile() {
            const file_path = `${this.param_path}/${this.param_id}/${this.document._id}`;
            
            getDocumentumFile(file_path).then(res => {
                this.doc_type = 'image';
                if (res.data['content_type'] === 'application/pdf') {
                    this.doc_type = 'pdf';
                }

                // prepare base64 string
                this.file_url =  `data:${res.data['content_type']};base64,` + res.data.base64;
                
                this.open_doc_preview_dialog = true;
            });
        },
        
        
        //--------------------PREVIEW FILES--------------------------//
        previewFile(fileObject) {
            // Set file oobject so we can trigger the readAsDataURL function
            const reader = new FileReader();

            reader.addEventListener("load", () => {
                // Set PDF-Viewer URL to the selected file Base64 String
                this.file_url = reader.result;
                if (fileObject.file.type === 'application/pdf') {
                    this.doc_type = 'pdf';
                } else {
                    this.doc_type = 'image';
                }

                // Display the preview dialog
                this.open_doc_preview_dialog = true;
            }, false);

            reader.readAsDataURL(fileObject.file);
        },
    },
    mounted() {
        
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="60%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Create Batch Payment</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeAddBatchPaymentDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="9" offset-sm="2">
                            <v-menu
                                v-model="processing_date_menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="processing_date"
                                    color="secondary"
                                    label="Processing Date"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    color="secondary"
                                    v-model="processing_date"
                                    :allowed-dates="allowedEffectiveDates"
                                    @input="processing_date_menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting Batch Payment...
                        <v-progress-linear
                            indeterminate
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submitBankBtnDisabled()"
                                @click="submitBank()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Submit Bank Details</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";
import { businessProcessUnderwriterBatchPayment } from "../gql/mutations";
export default {
    name: 'CaptureUnderwriterBatchPaymentDialog',
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        server_resp_bank: null,
        submitting: false,

        processing_date_menu: false,
        processing_date: null,
    }),
    methods: {
        allowedEffectiveDates(val) {
            // console.log(val)
            val = new Date(val)
            val.setHours(0, 0, 0, 0);

            const today = new Date();
            today.setHours(0, 0, 0, 0);

            return val <= today;
        },
        closeAddBatchPaymentDialog() {
            eventBus.$emit('CLOSE_CAPTURE_BATCH_PAYMENT_DIALOG', this.server_resp_bank);
        },
        resetForm() {
            this.server_resp_bank = null;
            this.processing_date = null;
        },
        submitBankBtnDisabled() {
            if (this.processing_date === null) {
                return true;
            }

            return false;
        },
        async submitBank() {
            this.submitting = true;

            try {
                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcessUnderwriterBatchPayment,
                                                variables: {
                                                    input: {
                                                        OP_TYPE: 'CREATE',
                                                        processing_date: this.processing_date,
                                                    }
                                                },
                                            });
                if (res.data.businessProcessUnderwriterBatchPayment.errors === true) {
                    emitAlert(res.data.businessProcessUnderwriterBatchPayment.message);
                    this.submitting = false;
                    return;
                }

                emitSuccess(res.data.businessProcessUnderwriterBatchPayment.message);
                
                this.submitting = false;
                this.server_resp_bank = res.data.businessProcessUnderwriterBatchPayment.business_object;
                this.closeAddBatchPaymentDialog();

                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessUnderwriterBatchPayment.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        isValidDate(d) {
            return d instanceof Date && !isNaN(d);
        }
    },
    mounted() {
        
    },
}
</script>
<style scoped>

</style>
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="40%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Generate Financial Calendar</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeGenerateFinCalendarDialog()" v-if="server_response_obj === null">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="10" offset-sm="1">
                            <v-select
                                v-model="year"
                                :items="years_lookup"
                                item-text="year"
                                item-value="year"
                                color="secondary"
                                label="Calendar Year"

                                :disabled="refreshing_year_lookup_list"

                                hint="You can click the refresh icon next to the field if the calendar year list is incomplete"
                                persistent-hint

                                required
                            >
                                <!-- Format prepend inner icon -->
                                <template v-slot:prepend-inner>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon
                                                v-on="on"
                                                color="success"
                                                @click="refreshYearLookupList()"
                                            >
                                                mdi-refresh
                                            </v-icon>
                                        </template>
                                        <span>Refresh Year Lookup List</span>
                                    </v-tooltip>
                                </template>
                            </v-select>
                            <v-progress-linear
                                color="green"
                                class="mb-0"
                                height="4"
                                indeterminate
                                :active="refreshing_year_lookup_list"
                            ></v-progress-linear>
                        </v-col>
                    </v-row>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        large
                        color="success"
                        :disabled="generateCalendarBtnDisabled()"
                        @click="generateCalendar()"
                    >
                        <v-icon large>save</v-icon>
                    </v-btn>
                </v-card-actions>

                <v-card-text class="text-center" v-if="submitting">
                    Generating Financial Calendar
                    <v-progress-linear
                        indeterminate
                        color="green"
                        class="mb-0"
                        height="5"
                        :active="submitting"
                    ></v-progress-linear>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { eventBus } from "../../../../main";
import { emitAlert, emitSuccess } from "../../../../utils/api";

import { getFinCalendarYearRangeLookup } from "../gql/queries";
import { finProcessFinanceCalendarYear } from "../gql/mutations";

export default {
    components: {
        
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        year: null,
        years_lookup: [],
        
        server_response_obj: null,
        submitting: false,

        refreshing_year_lookup_list: false,
    }),
    methods: {
        closeGenerateFinCalendarDialog() {
            eventBus.$emit('CLOSE_GENERATE_FIN_CALENDAR_DIALOG', this.server_response_obj);
        },
        resetForm() {
            this.year = null;
            
            this.server_response_obj = null;
            this.submitting = false;
        },

        generateCalendarBtnDisabled() {
            if (this.year === null) {
                return true;
            }
            return false;
        },

        async generateCalendar() {
            // Then submit the calendar
            this.submitting = true;

            const payload = {
                OP_TYPE: 'CREATE',

                // _id: '',
                year: this.year
            };

            try {
                const res = await this.$apollo
                                            .mutate({
                                                mutation: finProcessFinanceCalendarYear,
                                                variables: {
                                                    input: payload
                                                },
                                            });
                if (res.data.finProcessFinanceCalendarYear.errors === true) {
                    emitAlert(res.data.finProcessFinanceCalendarYear.message);
                    this.submitting = false;
                    return;
                }

                emitSuccess(res.data.finProcessFinanceCalendarYear.message);

                this.server_response_obj = res.data.finProcessFinanceCalendarYear.business_object;
                this.closeGenerateFinCalendarDialog();

                // Reset
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.finProcessFinanceCalendarYear.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },

        async refreshYearLookupList() {
            this.refreshing_year_lookup_list = true;
            try {
                // Load year lookup list
                const res = await this.$apollo
                                            .query({
                                                query: getFinCalendarYearRangeLookup,
                                                // fetchPolicy: 'network-only',
                                                fetchPolicy: 'no-cache',
                                                errorPolicy: 'all',
                                                variables: {
                                                    objectType: 'FINANCE_CALENDAR_RANGE',
                                                    pagination: {
                                                        page: this.page,
                                                        limit: this.limit
                                                    }
                                                },
                                            });
                this.refreshing_year_lookup_list = false;
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }

                this.years_lookup = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.refreshing_year_lookup_list = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        }
    },
    async mounted() {
        try {
            // Load year lookup list
            const res = await this.$apollo
                                            .query({
                                                query: getFinCalendarYearRangeLookup,
                                                // fetchPolicy: 'network-only',
                                                fetchPolicy: 'no-cache',
                                                errorPolicy: 'all',
                                                variables: {
                                                    objectType: 'FINANCE_CALENDAR_RANGE',
                                                    pagination: {
                                                        page: this.page,
                                                        limit: this.limit
                                                    }
                                                },
                                            });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.submitting = false;
                return;
            }
            this.years_lookup = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.submitting = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
            
            return;
        }
    },
}
</script>
<style scoped>

</style>
<template>
    <div>
        <v-toolbar
            color="secondary"
            dark
            class="pa-0"
            height="50"
            extension-height="3"
        >
            <v-row>
                <!-- Grid Buttons -->
                <v-col sm="4" style="margin-top:10px">
                    <v-tooltip>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                @click="loadMoreData()"
                                v-on="on"
                            >
                                <v-icon>cloud_download</v-icon>
                            </v-btn>
                        </template>
                        <span>Load More Data</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="refreshBusinessPartner()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="selectedBusinessPartner !== null">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="openEditBusinessPartnerDetailsDialog()"
                            >
                                <v-icon>edit</v-icon>
                            </v-btn>
                        </template>
                        <span>Edit Record</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="selectedBusinessPartner !== null">
                        <template v-slot:activator="{ on }">
                            <v-badge
                                :color="selectedBusinessPartner.version_history.changes.length > 0 ? 'primary' : 'secondary'"
                                :content="selectedBusinessPartner.version_history.changes.length"
                            >
                                <v-btn
                                    color="amber black--text"
                                    small
                                    v-on="on"
                                    :disabled="selectedBusinessPartner.version_history.changes.length === 0"
                                    @click="openViewBusinessPartnerChangeHistory()"
                                >
                                    <v-icon>mdi-history</v-icon>
                                </v-btn>
                            </v-badge>
                        </template>
                        <span>View Change History</span>
                    </v-tooltip>
                </v-col>

                <!-- Search Result Indicator -->
                <v-col sm="4">
                    <!-- <v-card-actions>
                        <h3>Search Results</h3>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    v-on="on"
                                >
                                    <v-icon small>search</v-icon>
                                    <v-icon small>close</v-icon>
                                </v-btn>
                            </template>
                            <span>Clear Search Results</span>
                        </v-tooltip>
                    </v-card-actions> -->
                </v-col>

                <!-- Action Buttons -->
                <v-col sm="4" style="margin-top:10px">
                    <div class="text-right">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="openOnboardingDialog()"
                                    v-on="on"
                                >
                                    <v-icon medium>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Onboard a Business Partner</span>
                        </v-tooltip>
                    </div>
                </v-col>
            </v-row>
        </v-toolbar>
        <multipane class="horizontal-panes" layout="horizontal" style="height: 100%" @paneResize="paneResizing">
            <div class="pane" :style="{ height: '50%', maxHeight: '50%'}">
                <v-data-table
                    fixed-header
                    :height="main_tab_height"
                    :headers="bp_details_headers"
                    :items="business_partners"
                    item-key="_id"
                    class="elevation-1"
                    :single-select="true"
                    @click:row="selectMainTableRow"
                    item-class="secondary"
                    hide-default-footer
                    :loading="data_table_loading"
                    :disable-pagination="true"
                >
                    <!-- Created -->
                    <template v-slot:[`item.created`]="{ item }">
                        <td>{{item.created.split('T')[0]}}</td>
                    </template>
                    <!-- Captured By -->
                    <template v-slot:[`item.captured_by`]="{ item }" >
                        <td v-if="item.captured_by !== null && item.captured_by !== undefined">
                            {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                        </td>
                    </template>
                </v-data-table>
            </div>
            <multipane-resizer></multipane-resizer>
            <div class="pane tab-nav" :style="{ height: '30%', maxHeight: '50%', minHeight: '30%' }">
                <v-tabs
                    v-model="sub_tabs"
                    color="transparent"
                    class="tab-nav"
                    hide-slider
                    height="35px"
                    grow
                >
                    <!-- VAS Sub Tab -->
                    <template>
                        <v-tab key="vas_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                VAS Services
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Address Sub Tab -->
                    <template>
                        <v-tab key="address_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Address
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Contact People Sub Tab -->
                    <template>
                        <v-tab key="contacts_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Contact People
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Documents Sub Tab -->
                    <template>
                        <v-tab key="documents_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Documents
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Banking Details Sub Tab -->
                    <template>
                        <v-tab key="bank_account_details_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Bank Account Details
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Finance Transactions Sub Tab -->
                    <template>
                        <v-tab key="finance_transaction_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Finance Transactions
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>
                </v-tabs>
                <v-tabs-items v-model="sub_tabs">
                    <!-- Sub-Tab Toolbar -->
                    <v-toolbar
                        color="secondary"
                        dark
                        class="pa-0"
                        height="50"
                        extension-height="3"
                    >
                        <!-- Buttons for the VAS services sub-tab -->
                        <v-tooltip bottom v-if="sub_tabs === 0 && selectedBusinessPartner !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddVASDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Business Partner VAS Service</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 0  && selectedVAS !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openDeleteVASDialog()"
                                    v-on="on"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Remove Business Partner VAS Service</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 0  && selectedVAS !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openEditVASDialog()"
                                    v-on="on"
                                >
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <span>Update Business Partner VAS Service</span>
                        </v-tooltip>
                        
                        <!-- Buttons for the ADDRESSES sub-tab -->
                        <v-tooltip bottom v-if="sub_tabs === 1 && selectedBusinessPartner !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddAddressDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Business Partner Address</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 1  && selectedAddress !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openDeleteAddressDialog()"
                                    v-on="on"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Remove Business Partner Address</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 1  && selectedAddress !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openEditAddressDialog()"
                                    v-on="on"
                                >
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <span>Update Business Partner Address</span>
                        </v-tooltip>

                        <!-- Buttons for the CONTACTS sub-tab -->
                        <v-tooltip bottom v-if="sub_tabs === 2 && selectedBusinessPartner !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddContactsDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Business Partner Contacts</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 2  && selectedContact !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openDeleteContactsDialog()"
                                    v-on="on"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete Business Partner Contact</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 2  && selectedContact !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    v-on="on"
                                    @click="openEditContactDialog()"
                                >
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <span>Update Business Partner Contacts</span>
                        </v-tooltip>

                        <!-- Show following buttons only for the DOCUMENTS sub-tab -->
                        <v-tooltip bottom v-if="sub_tabs === 3 && selectedBusinessPartner !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddDocumentsDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Business Partner Document(s)</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 3  && selectedDocument !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    v-on="on"
                                    @click="openDeleteBusinessPartnerDocumentDialog()"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete Business Partner Document(s)</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 3  && selectedDocument !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    v-on="on"
                                    @click="openViewDocumentDialog()"
                                >
                                    <v-icon>content_paste</v-icon>
                                </v-btn>
                            </template>
                            <span>View Document Info</span>
                        </v-tooltip>

                        <!-- Buttons for the BANKING sub-tab -->
                        <v-tooltip bottom v-if="sub_tabs === 4 && selectedBusinessPartner !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddBankingDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Business Partner Banking Details</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 4  && selectedBanking !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    v-on="on"
                                    @click="openDeleteBankingDialog()"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete Business Partner Banking Details</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 4  && selectedBanking !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    v-on="on"
                                    @click="openEditBankingDialog()"
                                >
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <span>Update Business Partner Banking Details</span>
                        </v-tooltip>

                        <!-- Transactions History Sub-Tab-Buttons -->
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 5 &&
                                selectedBusinessPartner !== null
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="amber black--text"
                                    small
                                    @click="refreshBusinessPartnerTransactions()"
                                    v-on="on"
                                >
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>Refresh Payment Events</span>
                        </v-tooltip>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <!-- VAS Sub-Tab Content -->
                    <v-tab-item key="vas_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="vas_details_headers"
                            :items="bp_vas_services"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectVASTabRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Created -->
                            <template v-slot:[`item.created`]="{ item }">
                                <td>{{item.created.split('T')[0]}}</td>
                            </template>
                            <!-- Captured By -->
                            <template v-slot:[`item.captured_by`]="{ item }" >
                                <td v-if="item.captured_by !== null && item.captured_by !== undefined">
                                    {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                                </td>
                            </template>
                            <!-- Fee Amount -->
                            <template v-slot:[`item.fee_amount`]="{ item }" >
                                <td>
                                    R {{item.fee_amount}}
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Address Sub-Tab Content -->
                    <v-tab-item key="address_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="address_details_headers"
                            :items="bp_addresses"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectAddressTabRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Created -->
                            <template v-slot:[`item.created`]="{ item }">
                                <td>{{item.created.split('T')[0]}}</td>
                            </template>
                            <!-- Captured By -->
                            <template v-slot:[`item.captured_by`]="{ item }" >
                                <td v-if="item.captured_by !== null && item.captured_by !== undefined">
                                    {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Contact People Sub-Tab Content -->
                    <v-tab-item key="contacts_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="contact_people_headers"
                            :items="bp_contact_people"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectContactsTabRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Primary Contact -->
                            <template v-slot:[`item.primary`]="{ item }">
                                <td>{{item.primary ? 'Yes' : 'No'}}</td>
                            </template>
                            <!-- Captured By -->
                            <!-- <template v-slot:[`item.captured_by`]="{ item }" >
                                <td v-if="item.captured_by !== null && item.captured_by !== undefined">
                                    {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                                </td>
                            </template> -->
                            <!-- Contact Person -->
                            <template v-slot:[`item.contact_person`]="{ item }" >
                                <td v-if="item.contact_person !== null && item.contact_person !== undefined">
                                    {{item.contact_person.name}} {{item.contact_person.surname}} ( {{item.contact_person.id_number}} )
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Documents Sub-Tab Content -->
                    <v-tab-item key="documents_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="document_details_headers"
                            :items="bp_documents"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectDocumentsTabRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Created -->
                            <template v-slot:[`item.created`]="{ item }">
                                <td>{{item.created.split('T')[0]}}</td>
                            </template>
                            <!-- Captured By -->
                            <template v-slot:[`item.captured_by`]="{ item }" >
                                <td v-if="item.captured_by !== null && item.captured_by !== undefined">
                                    {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Bankind Details Sub-Tab Content -->
                    <v-tab-item key="bank_account_details_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="bank_account_details_headers"
                            :items="bp_bank_account_details"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectBankingTabRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Captured By -->
                            <template v-slot:[`item.captured_by`]="{ item }" >
                                <td v-if="item.captured_by !== null && item.captured_by !== undefined">
                                    {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Finance Transaction History Sub-Tab Content -->
                    <v-tab-item key="finance_transaction_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="transactions_headers"
                            :items="transactions"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectTransactionHistoryRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Display Formated transaction date -->
                            <template v-slot:[`item.transaction_date`]="{ item }">
                                <td>{{item.transaction_date !== null ? item.transaction_date.split('T')[0] : ''}}</td>
                            </template>
                            <!-- Display Formated created date -->
                            <template v-slot:[`item.created`]="{ item }">
                                <td>{{item.created !== null ? item.created.split('T')[0] + '  @  ' + item.created.split('T')[1].substr(0,8) : ''}}</td>
                            </template>
                            <!-- Display Formated amount -->
                            <template v-slot:[`item.amount`]="{ item }">
                                <td>R {{item.amount}}</td>
                            </template>
                            <!-- Display Formated balance -->
                            <template v-slot:[`item.balance`]="{ item }">
                                <td>R {{item.balance}}</td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </multipane>
        <OnboardingDialog
            :dialog="open_onboarding_dialog"
        />
        <EditBusinessPartnerDetailsDialog
            v-if="selectedBusinessPartner !== null"
            :dialog="open_edit_business_partner_dialog"
            :business_partner_original="selectedBusinessPartner"
            :business_partner_copy="selectedBusinessPartnerCopy"
        />

        <!-- VAS dialogs -->
        <AddVASDialog
            v-if="selectedBusinessPartner !== null"
            :dialog="open_add_vas_dialog"
            :business_partner_id="selectedBusinessPartner._id"
        />
        <EditVASDialog
            v-if="selectedBusinessPartner !== null && selectedVAS !== null"
            :dialog="open_edit_vas_dialog"
            :business_partner_id="selectedBusinessPartner._id"
            :vas_original="selectedVAS"
            :vas_copy="selectedVASCopy"
        />
        <DeleteVASDialog
            v-if="selectedBusinessPartner !== null"
            :dialog="open_delete_vas_dialog"
            :business_partner="selectedBusinessPartner"
            :vas="selectedVAS"
        />

        <!-- Address dialogs -->
        <AddAddressesDialog
            v-if="selectedBusinessPartner !== null"
            :dialog="open_add_address_dialog"
            :business_partner_id="selectedBusinessPartner._id"
        />
        <EditAddressDialog
            v-if="selectedBusinessPartner !== null && selectedAddress !== null"
            :dialog="open_edit_address_dialog"
            :business_partner_id="selectedBusinessPartner._id"
            :address_original="selectedAddress"
            :address_copy="selectedAddressCopy"
        />
        <DeleteAddressesDialog
            v-if="selectedBusinessPartner !== null"
            :dialog="open_delete_address_dialog"
            :business_partner="selectedBusinessPartner"
            :address="selectedAddress"
        />

        <!-- Contacts dialogs -->
        <AddContactsDialog
            v-if="selectedBusinessPartner !== null"
            :dialog="open_add_contact_dialog"
            :business_partner_id="selectedBusinessPartner._id"
        />
        <EditContactDialog
            v-if="selectedBusinessPartner !== null && selectedContact !== null"
            :dialog="open_edit_contact_dialog"
            :business_partner_id="selectedBusinessPartner._id"
            :contact_original="selectedContact"
            :contact_copy="selectedContactCopy"
        />
        <DeleteContactsDialog
            v-if="selectedContact !== null"
            :dialog="open_delete_contact_dialog"
            :business_partner="selectedBusinessPartner"
            :contact="selectedContact"
        />

        <!-- Document Dialogs -->
        <AddBusinessPartnerDocumentDialog
            v-if="selectedBusinessPartner !== null"
            :dialog="open_add_document_dialog"
            :business_partner="selectedBusinessPartner"
        />
        <ViewDocumentDialog
            v-if="selectedDocument !== null"
            :dialog="open_view_bp_document_dialog"
            :document="selectedDocument"
            param_path="business_partner"
            :param_id="selectedBusinessPartner._id"
        />
        <DeleteBusinessPartnerDocumentDialog
            v-if="selectedDocument !== null"
            :dialog="open_delete_bp_document_dialog"
            :business_partner="selectedBusinessPartner"
            :document="selectedDocument"
        />

        <!-- Banking Dialogs -->
        <AddBankingDialog
            v-if="selectedBusinessPartner !== null"
            :dialog="open_add_banking_dialog"
            :business_partner_id="selectedBusinessPartner._id"
        />
        <EditBankingDialog
            v-if="selectedBusinessPartner !== null && selectedBanking !== null"
            :dialog="open_edit_banking_dialog"
            :business_partner_id="selectedBusinessPartner._id"
            :banking_original="selectedBanking"
            :banking_copy="selectedBankingCopy"
        />
        <DeleteBankingDialog
            v-if="selectedBanking !== null"
            :dialog="open_delete_banking_dialog"
            :business_partner="selectedBusinessPartner"
            :banking="selectedBanking"
        />

        <SearchBusinessPartnerDialog
            :dialog="open_search_bp_dialog"
            @CLOSE_BP_SEARCH_DIALOG="closeSearchBusinessPartnerDialog()"
        />
        
        <ViewBusinessPartnerChangeHistoryDialog
            v-if="selectedBusinessPartner !== null"
            :dialog="open_view_bp_change_history_dialog"
            :change_history="selectedBusinessPartner.version_history"
        />
    </div>
</template>
<script>
import { Multipane, MultipaneResizer } from 'vue-multipane';
import OnboardingDialog from './dialogs/add_bp';
import EditBusinessPartnerDetailsDialog from './dialogs/edit_bp_details';

import AddVASDialog from './dialogs/add_bp_vas';
import EditVASDialog from './dialogs/edit_bp_vas';
import DeleteVASDialog from './dialogs/delete_bp_vas';

import AddAddressesDialog from './dialogs/add_bp_addresses';
import EditAddressDialog from './dialogs/edit_bp_address';
import DeleteAddressesDialog from './dialogs/delete_bp_address';

import AddContactsDialog from './dialogs/add_bp_contacts';
import EditContactDialog from './dialogs/edit_bp_contact';
import DeleteContactsDialog from './dialogs/delete_bp_contact';

import AddBusinessPartnerDocumentDialog from './dialogs/add_bp_documents';
import DeleteBusinessPartnerDocumentDialog from './dialogs/delete_bp_document';
import ViewDocumentDialog from '../../components/common_dialogs/view_document_info';

import AddBankingDialog from './dialogs/add_bp_banking';
import EditBankingDialog from './dialogs/edit_bp_banking';
import DeleteBankingDialog from './dialogs/delete_bp_banking';

import SearchBusinessPartnerDialog from './dialogs/search_bp';
import ViewBusinessPartnerChangeHistoryDialog from './dialogs/view_bp_change_history';

import { emitAlert } from "../../utils/api";
import { eventBus } from "../../main";

import { getBusinessPartners, getFinanceTransactionsList } from "./gql/queries";

export default {
    components: {
        Multipane,
        MultipaneResizer,
        OnboardingDialog,
        SearchBusinessPartnerDialog,
        EditBusinessPartnerDetailsDialog,
        ViewBusinessPartnerChangeHistoryDialog,

        AddVASDialog,
        EditVASDialog,
        DeleteVASDialog,

        AddAddressesDialog,
        EditAddressDialog,
        DeleteAddressesDialog,

        AddContactsDialog,
        EditContactDialog,
        DeleteContactsDialog,

        AddBusinessPartnerDocumentDialog,
        DeleteBusinessPartnerDocumentDialog,
        ViewDocumentDialog,

        AddBankingDialog,
        EditBankingDialog,
        DeleteBankingDialog,
    },

    data: () => ({
        sub_tabs: null,
        main_tab_height: '250px',
        sub_tab_height:'180',

        // main data-table row
        selectedBusinessPartner: null,
        selectedRow: null,

        // addresses sub-tab data-table row
        selectedVAS: null,
        selectedVASRow: null,
        selectedVASCopy: {
            vas_service:  {
                name: null
            },
            fee_amount: null,
            fee_billing_model: null,
        },

        // addresses sub-tab data-table row
        selectedAddress: null,
        selectedAddressRow: null,
        selectedAddressCopy: {
            type:  null,
            line1: null,
            line2: null,
            line3: null,
            line4: null
        },
        
        // contacts sub-tab data-table row
        selectedContact: null,
        selectedContactRow: null,

        // documents sub-tab data-table row
        selectedDocument: null,
        selectedDocumentRow: null,
        
        // banking sub-tab data-table row
        selectedBanking: null,
        selectedBankingRow: null,

        // transaction-history sub-tab data-table row
        selectedTransactionHistory: null,
        selectedTransactionHistoryRow: null,

        selectedContactCopy: {
            contact_person: {
                name: null,
                surname: null,
                id_number: null,
            },
            role: null,
        },
        selectedBankingCopy: {
            type:  null,
            bank: null,
            account_type: null,
            account_number: null
        },
        selectedBusinessPartnerCopy: {
            name:  null,
        },
        

        bp_details_headers: [
            { text: 'BP Number', value: 'bp_number' },
            { text: 'Name', value: 'name' },
            { text: 'Company Reg Number', value: 'company_reg_number' },
            { text: 'Type', value: 'type' },
            { text: 'Status', value: 'status' },
            { text: 'Admin Option', value: 'administration_option' },
            { text: 'Created', value: 'created' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        vas_details_headers: [
            { text: 'VAS Service', value: 'vas_service.name' },
            { text: 'Fee Amount', value: 'fee_amount' },
            { text: 'Fee Billing Model', value: 'fee_billing_model' },
            { text: 'Date Captured', value: 'created' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        address_details_headers: [
            { text: 'Address Type', value: 'type' },
            { text: 'Line 1', value: 'line1' },
            { text: 'Line 2', value: 'line2' },
            { text: 'Line 3', value: 'line3' },
            { text: 'Postal Code', value: 'line4' },
            { text: 'Date Captured', value: 'created' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        bank_account_details_headers: [
            { text: 'Purpose', value: 'purpose' },
            { text: 'Bank', value: 'bank.name' },
            { text: 'Account Type', value: 'account_type' },
            { text: 'Account Number', value: 'account_number' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        contact_people_headers: [
            { text: 'Contact Person', value: 'contact_person' },
            { text: 'Role', value: 'role' },
        ],
        document_details_headers: [
            { text: 'Document Type', value: 'type' },
            { text: 'Name', value: 'name' },
            { text: 'Created Date', value: 'created' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        transactions_headers: [
            { text: 'Transaction Date', value: 'transaction_date' },
            { text: 'Created', value: 'created' },
            { text: 'Payment Method', value: 'from.name' },
            // { text: 'Financial Calendar Month', value: 'fin_calendar_month.name' },
            { text: 'Reference', value: 'reference' },
            { text: 'Amount', value: 'amount' },
            { text: 'Running Balance', value: 'balance' },
        ],
        
        // Table data models
        bp_vas_services: [],
        bp_addresses: [],
        bp_bank_account_details: [],
        bp_contact_people: [],
        bp_documents: [],
        transactions: [],
        

        // Dialogs
        
        business_partners: [],
        open_onboarding_dialog: false,
        open_edit_business_partner_dialog: false,
        open_search_bp_dialog: false,
        
        // VAS dialogs
        open_add_vas_dialog: false,
        open_delete_vas_dialog: false,
        open_edit_vas_dialog: false,

        // Address dialogs
        open_add_address_dialog: false,
        open_delete_address_dialog: false,
        open_edit_address_dialog: false,

        // Contacts dialogs
        open_add_contact_dialog: false,
        open_edit_contact_dialog: false,
        open_delete_contact_dialog: false,
        // Documents dialogs
        open_add_document_dialog: false,
        open_delete_bp_document_dialog: false,
        open_view_bp_document_dialog: false,
        // Banking dialogs
        open_add_banking_dialog: false,
        open_edit_banking_dialog: false,
        open_delete_banking_dialog: false,

        open_view_bp_change_history_dialog: false,
        
        data_table_loading: false,
        transactions_loading: false,


        page: 1,
        limit: 50,
    }),

    methods: {
        //-------------------- OPEN DIALOGS ---------------------//
        openOnboardingDialog() {
            this.open_onboarding_dialog = true;
        },
        openEditBusinessPartnerDetailsDialog() {
            this.open_edit_business_partner_dialog = true;
        },
        openSearchBusinessPartnerDialog() {
            this.open_search_bp_dialog = true;
        },
        openAddVASDialog() {
            this.open_add_vas_dialog = true;
        },
        openDeleteVASDialog() {
            this.open_delete_vas_dialog = true;
        },
        openAddAddressDialog() {
            this.open_add_address_dialog = true;
        },
        openDeleteAddressDialog() {
            this.open_delete_address_dialog = true;
        },
        openEditVASDialog() {
            this.selectedVASCopy = Object.assign({}, this.selectedVAS);
            this.open_edit_vas_dialog = true;
        },
        openEditAddressDialog() {
            this.selectedAddressCopy = Object.assign({}, this.selectedAddress);
            this.open_edit_address_dialog = true;
        },
        openAddContactsDialog() {
            this.open_add_contact_dialog = true;
        },
        openEditContactDialog() {
            this.selectedContactCopy = Object.assign({}, this.selectedContact);
            this.open_edit_contact_dialog = true;
        },
        openDeleteContactsDialog() {
            this.open_delete_contact_dialog = true;
        },
        openAddDocumentsDialog() {
            this.open_add_document_dialog = true;
        },
        openDeleteBusinessPartnerDocumentDialog() {
            this.open_delete_bp_document_dialog = true;
        },
        openViewDocumentDialog() {
            this.open_view_bp_document_dialog = true;
        },
        openAddBankingDialog() {
            this.open_add_banking_dialog = true;
        },
        openEditBankingDialog() {
            this.selectedBankingCopy = Object.assign({}, this.selectedBanking);
            this.open_edit_banking_dialog = true;
        },
        openDeleteBankingDialog() {
            this.open_delete_banking_dialog = true;
        },
        openViewBusinessPartnerChangeHistory() {
            this.open_view_bp_change_history_dialog = true;
        },


        // Edit form reseters
        resetVASEditForm(updatedVAS) {
            if (
                updatedVAS.vas_service !== this.selectedVAS.vas_service ||
                updatedVAS.fee_amount !== this.selectedVAS.fee_amount ||
                updatedVAS.fee_billing_model !== this.selectedVAS.fee_billing_model
            ) {
                this.selectedVASCopy = Object.assign({}, this.selectedVAS);
            }
        },
        resetAddressEditForm(updatedAddress) {
            if (
                updatedAddress.type !== this.selectedAddress.type ||
                updatedAddress.line1 !== this.selectedAddress.line1 ||
                updatedAddress.line2 !== this.selectedAddress.line2 ||
                updatedAddress.line3 !== this.selectedAddress.line3 ||
                updatedAddress.line4 !== this.selectedAddress.line4
            ) {
                this.selectedAddressCopy = Object.assign({}, this.selectedAddress);
            }
        },
        resetContactEditForm(updatedContact) {
            if (
                updatedContact.role !== this.selectedContact.role
            ) {
                this.selectedContactCopy = Object.assign({}, this.selectedContact);
            }
        },
        resetBankingEditForm(updatedBanking) {
            if (
                updatedBanking.purpose !== this.selectedBanking.purpose ||
                updatedBanking.bank !== this.selectedBanking.bank ||
                updatedBanking.account_type !== this.selectedBanking.account_type ||
                updatedBanking.account_number !== this.selectedBanking.account_number
            ) {
                this.selectedBankingCopy = Object.assign({}, this.selectedBanking);
            }
        },
        resetBusinessPartnerEditForm(updatedBP) {
            if (
                updatedBP.name !== this.selectedBusinessPartner.name ||
                updatedBP.type !== this.selectedBusinessPartner.type ||
                updatedBP.administration_option !== this.selectedBusinessPartner.administration_option ||
                updatedBP.status !== this.selectedBusinessPartner.status
            ) {
                this.selectedBusinessPartnerCopy = Object.assign({}, this.selectedBusinessPartner);
            }
        },

        //------------------- CLOSE DIALOGS---------------------//
        closeOnboardingDialog(new_business_partner) {
            if (new_business_partner !== null) {
                this.business_partners.push(new_business_partner);
            }
            this.open_onboarding_dialog = false;
        },
        closeEditBusinessPartnerDialog(new_business_partner) {
            this.updateUITableModel(new_business_partner);
            this.open_edit_business_partner_dialog = false;
        },

        updateUITableModel(new_business_partner) {
            if (new_business_partner !== null) {
                const obj_index = this.business_partners.map(obj => { return obj._id; }).indexOf(new_business_partner._id)
                this.business_partners[obj_index] = new_business_partner;

                 // Deep copy business_partners for refreshing main data table
                const temp_arr = Array.from(this.business_partners);
                this.business_partners = temp_arr;

                // Also update the sub-tabs data
                this.bp_vas_services = new_business_partner.vas_services.filter(vas => { return !vas.deleted });
                this.bp_addresses = new_business_partner.addresses.filter(addr => { return !addr.deleted });
                this.bp_contact_people = new_business_partner.contact_people.filter(contact => { return !contact.deleted });
                this.bp_documents = new_business_partner.documents.filter(doc => { return !doc.deleted });
                this.bp_bank_account_details = new_business_partner.bank_account_details.filter(banking => { return !banking.deleted });

                // Reset data-table items
                this.selectedBusinessPartner = new_business_partner;
                // this.unselectSubTabRows();

                // Process changes
                new_business_partner.version_history.changes.forEach(change => {
                    // console.log(typeof(change.new))
                    if (change.old !== null) if (typeof(change.old) === 'string') change.old = JSON.parse(change.old);
                    if (change.new !== null) if (typeof(change.new) === 'string') change.new = JSON.parse(change.new);
                });
            }
        },
        closeAddVASDialog(new_business_partner) {
            this.updateUITableModel(new_business_partner);
            this.open_add_vas_dialog = false;
        },
        closeAddAddressesDialog(new_business_partner) {
            this.updateUITableModel(new_business_partner);
            this.open_add_address_dialog = false;
        },
        closeDeleteVASDialog(new_business_partner) {
            this.updateUITableModel(new_business_partner);
            this.open_delete_vas_dialog = false;

            // Unselect the deleted row
            if (new_business_partner !== null) {
                this.selectedVAS = null;
                this.selectedVASRow = null;
            }
        },
        closeDeleteAddressesDialog(new_business_partner) {
            this.updateUITableModel(new_business_partner);
            this.open_delete_address_dialog = false;

            // Unselect the deleted row
            if (new_business_partner !== null) {
                this.selectedAddress = null;
                this.selectedAddressRow = null;

                this.selectedAddressCopy = {
                    type:  null,
                    line1: null,
                    line2: null,
                    line3: null,
                    line4: null
                };
            }
        },
        closeEditVASDialog(new_business_partner) {
            this.updateUITableModel(new_business_partner);
            this.open_edit_vas_dialog = false;

            // Update the selected vas row
            if (new_business_partner !== null) {
                const newVAS = this.bp_vas_services.filter(vas => { return vas._id === this.selectedVAS._id })[0];
                this.selectedVAS = newVAS;
            }
        },
        closeEditAddressDialog(new_business_partner) {
            this.updateUITableModel(new_business_partner);
            this.open_edit_address_dialog = false;

            // Update the selected address row
            if (new_business_partner !== null) {
                const newAddress = this.bp_addresses.filter(addr => { return addr._id === this.selectedAddress._id })[0];
                this.selectedAddress = newAddress;
            }
        },
        closeViewBusinessPartnerChangeHistoryDialog() {
            this.open_view_bp_change_history_dialog = false;
        },
        closeAddContactsDialog(new_business_partner) {
            this.updateUITableModel(new_business_partner);
            this.open_add_contact_dialog = false;
        },
        closeEditContactDialog(new_business_partner) {
            this.updateUITableModel(new_business_partner);
            this.open_edit_contact_dialog = false;
        },
        closeDeleteContactDialog(new_business_partner) {
            this.updateUITableModel(new_business_partner);
            this.open_delete_contact_dialog = false;

            // Unselect the deleted row
            if (new_business_partner !== null) {
                this.selectedContact = null;
                this.selectedContactRow = null;
            }
        },
        closeAddBusinessPartnerDocumentsDialog(new_business_partner) {
            this.updateUITableModel(new_business_partner);
            this.open_add_document_dialog = false;
        },
        closeDeleteBusinessPartnerDocumentDialog(new_business_partner) {
            this.updateUITableModel(new_business_partner);
            this.open_delete_bp_document_dialog = false;

            // Unselect the deleted row
            if (new_business_partner !== null) {
                this.selectedDocument = null;
                this.selectedDocumentRow = null;
            }
        },
        closeViewServerDocumentsDialog() {
            this.open_view_bp_document_dialog = false;
        },
        closeAddBankingDialog(new_business_partner) {
            this.updateUITableModel(new_business_partner);
            this.open_add_banking_dialog = false;
        },
        closeEditBankingDialog(new_business_partner) {
            this.updateUITableModel(new_business_partner);
            this.open_edit_banking_dialog = false;

            // Update the selected banking row
            if (new_business_partner !== null) {
                const newBanking = this.bp_bank_account_details.filter(bank => { return bank._id === this.selectedBanking._id })[0];
                this.selectedBanking = newBanking;
            }
        },
        closeDeleteBankingDialog(new_business_partner) {
            this.updateUITableModel(new_business_partner);
            this.open_delete_banking_dialog = false;

            // Unselect the deleted row
            if (new_business_partner !== null) {
                this.selectedBanking = null;
                this.selectedBankingRow = null;
            }
        },
        closeSearchBusinessPartnerDialog() {
            this.open_search_bp_dialog = false;
        },
        unselectSubTabRows() {
            // Unselect vas sub-tab row
            this.selectedVAS = null;
            if (this.selectedVASRow !== null) {
                this.selectedVASRow.select(false);
            }

            // Unselect addresses sub-tab row
            this.selectedAddress = null;
            if (this.selectedAddressRow !== null) {
                this.selectedAddressRow.select(false);
            }

            // Unselect contacts sub-tab row
            this.selectedContact = null;
            if (this.selectedContactRow !== null) {
                this.selectedContactRow.select(false);
            }

            // Unselect documents sub-tab row
            this.selectedDocument = null;
            if (this.selectedDocumentRow !== null) {
                this.selectedDocumentRow.select(false);
            }

            // Unselect banking sub-tab row
            this.selectedBanking = null;
            if (this.selectedBankingRow !== null) {
                this.selectedBankingRow.select(false);
            }

            // Unselect transaction sub-tab row
            this.selectedTransactionHistory = null;
            if (this.selectedTransactionHistoryRow !== null) {
                this.selectedTransactionHistoryRow.select(false);
            }
        },
        selectMainTableRow(item, item_metadata) {
            this.unselectSubTabRows();

            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                this.selectedBusinessPartner = item;
                // Update bp vas services model
                this.bp_vas_services = item.vas_services.filter(vas => { return !vas.deleted });
                // Update bp addresses model
                this.bp_addresses = item.addresses.filter(addr => { return !addr.deleted });
                // Update bp contact people model
                this.bp_contact_people = item.contact_people.filter(contact => { return !contact.deleted });
                // Update bp documents model
                this.bp_documents = item.documents.filter(doc => { return !doc.deleted });
                // Update bp banking details
                this.bp_bank_account_details = item.bank_account_details.filter(banking => { return !banking.deleted });

                // Set selected bp copy
                this.selectedBusinessPartnerCopy = Object.assign({}, this.selectedBusinessPartner);
                // Update transaction history
                this.transactions = [];
            } else {
                this.selectedBusinessPartner = null;
                // Unighlight selected row
                item_metadata.select(false);

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                // Update bp vas services model
                this.bp_vas_services = [];
                // Update bp addresses model
                this.bp_addresses = [];
                // Update bp contact details model
                this.bp_contact_people = [];
                // Update bp documents model
                this.bp_documents = [];
                // Update bp banking details
                this.bp_bank_account_details = [];
                // Update transaction history
                this.transactions = [];
            }
        },
        selectVASTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedVAS = item;
                this.selectedVASRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedVAS = null;
                this.selectedVASRow = null;
            }
        },
        selectAddressTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedAddress = item;
                this.selectedAddressRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedAddress = null;
                this.selectedAddressRow = null;
            }
        },
        selectContactsTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedContact = item;
                this.selectedContactRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedContact = null;
                this.selectedContactRow = null;
            }
        },
        selectDocumentsTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedDocument = item;
                this.selectedDocumentRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedDocument = null;
                this.selectedDocumentRow = null;
            }
        },
        selectBankingTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedBanking = item;
                this.selectedBankingRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedBanking = null;
                this.selectedBankingRow = null;
            }
        },
        selectTransactionHistoryRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedTransactionHistory = item;
                this.selectedTransactionHistoryRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedTransactionHistory = null;
                this.selectedTransactionHistoryRow = null;
            }
        },

        async loadMoreData() {
            this.data_table_loading = true;
            this.page += 1;
            
            try {
                this.data_table_loading = true;
                const res = await this.$apollo
                                    .query({
                                        query: getBusinessPartners,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'BUSINESS_PARTNER',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }

                this.data_table_loading = false;
                this.business_partners = this.business_partners.concat( res.data.getBusinessObject.business_objects );
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
    
        async refreshBusinessPartner() {
            this.data_table_loading = true;
            this.business_partners = [];

            this.page = 1;
            this.limit = 50;

            try {
                const res = await this.$apollo
                                    .query({
                                        query: getBusinessPartners,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'BUSINESS_PARTNER',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }

                this.data_table_loading = false;

                res.data.getBusinessObject.business_objects.forEach(bp => {
                    bp.version_history.changes.forEach(change => {
                        // console.log(typeof(change.new))
                        if (change.old !== null) {
                            if (typeof(change.old) === 'string') {
                                try {
                                    change.old = JSON.parse(change.old);
                                } catch (error) {
                                    // eslint-disable-line
                                }
                            }
                        }


                        if (change.new !== null) {
                            if (typeof(change.new) === 'string') {
                                try {
                                    change.new = JSON.parse(change.new);
                                } catch (error) {
                                    // eslint-disable-line
                                }
                            }
                        }
                    });
                });

                this.business_partners = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        async refreshBusinessPartnerTransactions() {
            try {
                this.transactions_loading = true;
                const res = await this.$apollo
                                    .query({
                                        query: getFinanceTransactionsList,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'FINANCE_ACCOUNT_TRANSACTION',
                                            pagination: {
                                                page: 1,
                                                limit: 50
                                            },
                                            searchInput: {
                                                account_id: this.selectedBusinessPartner._id
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.transactions_loading = false;
                    return;
                }


                this.transactions_loading = false;
                this.transactions = res.data.getBusinessObject.business_objects;

                // Prepare balance
                let runningBalance = 0;
                let runningAmount = 0;
                for (const trans of this.transactions) {
                    if (this.transactions.indexOf(trans) === 0) {
                        if (trans.from._id === this.selectedBusinessPartner._id) {
                            trans.balance = trans.from.balance;
                        }
    
                        if (trans.to._id === this.selectedBusinessPartner._id) {
                            trans.balance = trans.to.balance;
                        }

                        runningBalance = trans.balance;
                        runningAmount = trans.amount;
                    } else {
                        // NB: We're working the running balances backwards, so we've filpped the signs


                        if (trans.from._id === this.selectedBusinessPartner._id) {
                            runningBalance = runningBalance + runningAmount;
                        }
    
                        if (trans.to._id === this.selectedBusinessPartner._id) {
                            runningBalance = runningBalance - runningAmount;
                        }
                        trans.balance = runningBalance;
                        runningAmount = trans.amount;
                    }
                }
            } catch (error) {
                this.transactions_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        paneResizing(pane, container, size) {
            const size_int = parseInt(size);

            this.main_tab_height = size;
            const max_screen_height = window.innerHeight - 300;

            const sub_tab_percentage = 1 - (size_int/max_screen_height);
            this.sub_tab_height = sub_tab_percentage * max_screen_height;
        }
    },

    async mounted() {
        eventBus.$on('CLOSE_ONBOARDING_DIALOG', this.closeOnboardingDialog);
        eventBus.$on('CLOSE_EDIT_BP_DIALOG', this.closeEditBusinessPartnerDialog);
        eventBus.$on('CLOSE_ADD_BP_ADDRESS_DIALOG', this.closeAddAddressesDialog);
        eventBus.$on('CLOSE_EDIT_BP_ADDRESS_DIALOG', this.closeEditAddressDialog);
        eventBus.$on('CLOSE_ADD_BP_VAS_DIALOG', this.closeAddVASDialog);
        eventBus.$on('CLOSE_EDIT_BP_VAS_DIALOG', this.closeEditVASDialog);
        eventBus.$on('CLOSE_DELETE_BP_VAS_DIALOG', this.closeDeleteVASDialog);
        eventBus.$on('CLOSE_DELETE_BP_ADDRESS_DIALOG', this.closeDeleteAddressesDialog);

        eventBus.$on('CLOSE_ADD_BP_CONTACTS_DIALOG', this.closeAddContactsDialog);
        eventBus.$on('CLOSE_EDIT_BP_CONTACT_DIALOG', this.closeEditContactDialog);
        eventBus.$on('CLOSE_DELETE_BP_CONTACT_DIALOG', this.closeDeleteContactDialog);

        eventBus.$on('CLOSE_ADD_BP_DOCUMENTS_DIALOG', this.closeAddBusinessPartnerDocumentsDialog);
        eventBus.$on('CLOSE_DELETE_BP_DOCUMENT_DIALOG', this.closeDeleteBusinessPartnerDocumentDialog);
        eventBus.$on('CLOSE_VIEW_DOCUMENT_DIALOG', this.closeViewServerDocumentsDialog);

        eventBus.$on('CLOSE_ADD_BP_BANKING_DIALOG', this.closeAddBankingDialog);
        eventBus.$on('CLOSE_EDIT_BP_BANKING_DIALOG', this.closeEditBankingDialog);
        eventBus.$on('CLOSE_DELETE_BP_BANKING_DIALOG', this.closeDeleteBankingDialog);

        eventBus.$on('RESET_BP_EDIT_FORM', this.resetBusinessPartnerEditForm);
        eventBus.$on('RESET_VAS_EDIT_FORM', this.resetVASEditForm);
        eventBus.$on('RESET_ADDRESS_EDIT_FORM', this.resetAddressEditForm);
        eventBus.$on('RESET_CONTACT_EDIT_FORM', this.resetContactEditForm);
        eventBus.$on('RESET_BANKING_EDIT_FORM', this.resetBankingEditForm);

        eventBus.$on('CLOSE_VIEW_BP_CHANGE_HISTORY_DIALOG', this.closeViewBusinessPartnerChangeHistoryDialog);

        /**
         * The following logic is for manually triggering a pane-divider movement
         * so as to get the windows to properly resize
         */
        this.paneResizing(null, null, 250);

        try {
            this.data_table_loading = true;
            // const res = await getBusinessPartners(this.page, this.limit);
            // if (res.errors === true) {
            //     emitAlert(res.message);
            //     this.data_table_loading = false;
            //     return;
            // }

            const res = await this.$apollo
                                    .query({
                                        query: getBusinessPartners,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'BUSINESS_PARTNER',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.data_table_loading = false;
                return;
            }

            this.data_table_loading = false;

            res.data.getBusinessObject.business_objects.forEach(entity => {
                entity.version_history.changes.forEach(change => {
                    // console.log(typeof(change.new))
                    // if (change.old !== null) if (typeof(change.old) === 'string') change.old = JSON.parse(change.old);
                    // if (change.new !== null) if (typeof(change.new) === 'string') change.new = JSON.parse(change.new);

                    if (change.old !== null) {
                        if (typeof(change.old) === 'string') {
                            try {
                                change.old = JSON.parse(change.old);
                            } catch (error) {
                                // eslint-disable-line
                            }
                        }
                    }


                    if (change.new !== null) {
                        if (typeof(change.new) === 'string') {
                            try {
                                change.new = JSON.parse(change.new);
                            } catch (error) {
                                // eslint-disable-line
                            }
                        }
                    }
                });
            });

            this.business_partners = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.data_table_loading = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
        }
    },

    watch: {
        
    }
}
</script>
<style scoped>
/* Overriding of textbox min height */
 .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
}
</style>
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Add a Scheme</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeOnboardingDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-stepper v-model="e1">
                <v-stepper-header>
                    <v-stepper-step :complete="e1 > 1" step="1" color="success">Details</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 2" step="2" color="success">Address</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 3" step="3" color="success">Contact</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 4" step="4" color="success">Documents</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 5" step="5" color="success">Banking</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="6" color="success">Processing</v-stepper-step>
                </v-stepper-header>
            
                <v-stepper-items>
                    <!-- Basic Details -->
                    <v-stepper-content step="1">
                        <v-card>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            v-model="scheme_details.name"
                                            :rules="name_rules"
                                            ref="name_field"
                                            color="secondary"
                                            label="Scheme Name"
                                            autocomplete="some-random-text"
                                            type="text"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-select
                                            v-model="scheme_details.scheme_type"
                                            :items="['LOAN', 'INSURANCE']"
                                            color="secondary"
                                            label="Scheme Type"
                                            required
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-progress-linear
                                color="green"
                                class="mb-0"
                                height="10"
                                indeterminate
                                :active="backend_check"
                            ></v-progress-linear>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    fab
                                    color="amber"
                                    :disabled="moveToStep2BtnDisabled()"
                                    @click="moveToStep2()"
                                >
                                    <v-icon large>keyboard_arrow_right</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Address Section -->
                    <v-stepper-content step="2">
                        <v-card>
                            <v-card-text>
                                <v-row
                                    v-for="address in addresses"
                                    :key="address.id"
                                    style="background:#eaeaea; padding-bottom:10px"
                                >
                                    <v-col cols="12" sm="3">
                                        <v-select
                                            v-model="address.type"
                                            :items="address_types"
                                            color="secondary"
                                            label="Address Type"
                                            required
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-text-field
                                            v-model="address.line1"
                                            color="secondary"
                                            label="Line 1"
                                            autocomplete="some-random-text"
                                            type="text"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-text-field
                                            v-model="address.line2"
                                            color="secondary"
                                            label="Line 2"
                                            autocomplete="some-random-text"
                                            type="text"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-text-field
                                            v-model="address.line3"
                                            color="secondary"
                                            label="Line 3"
                                            autocomplete="some-random-text"
                                            type="text"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-text-field
                                            v-model="address.line4"
                                            color="secondary"
                                            label="Postal Code"
                                            autocomplete="some-random-text"
                                            type="text"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <div style="background:white; height: 10px; width: 100%"></div>
                                </v-row>
                                <!-- Buttons for Adding/Removing Addresses -->
                                <v-row>
                                    <v-col sm="10"></v-col>
                                    <v-col sm="2">
                                        <v-tooltip bottom v-if="addresses.length > 1">
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    class="mx-2"
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="removeAddress()"
                                                >
                                                    <v-icon>remove</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Remove Address</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="addAddress()"
                                                >
                                                    <v-icon>add</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Add Another Address</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="e1 = 1"
                                >
                                    <v-icon large>keyboard_arrow_left</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="validateAddresses()"
                                >
                                    <v-icon large>keyboard_arrow_right</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Contact Section -->
                    <v-stepper-content step="3">
                        <v-card>
                            <v-card-text>
                                <v-row
                                    v-for="contact in contact_details"
                                    :key="contact.id"
                                    style="background:#eaeaea; padding-bottom:10px"
                                >
                                    <v-col cols="12" sm="4">
                                        <v-select
                                            v-model="contact.type"
                                            :items="contact_types"
                                            color="secondary"
                                            label="Contact Type"
                                            required
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-select
                                            v-model="contact.primary"
                                            color="secondary"
                                            :items="['Yes', 'No']"
                                            label="Primary"
                                            required
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            v-model="contact.details"
                                            color="secondary"
                                            label="Contact Details"
                                            autocomplete="some-random-text"
                                            type="text"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <div style="background:white; height: 10px; width: 100%"></div>
                                </v-row>
                                <!-- Buttons for Adding/Removing Contact -->
                                <v-row>
                                    <v-col sm="10"></v-col>
                                    <v-col sm="2">
                                        <v-tooltip bottom v-if="contact_details.length > 1">
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    class="mx-2"
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="removeContact()"
                                                >
                                                    <v-icon>remove</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Remove Address</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="addContact()"
                                                >
                                                    <v-icon>add</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Add Another Address</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="e1 = 2"
                                >
                                    <v-icon large>keyboard_arrow_left</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="validateContacts()"
                                >
                                    <v-icon large>keyboard_arrow_right</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Documents Section -->
                    <v-stepper-content step="4">
                        <v-card>
                            <v-card-text>
                                <v-row
                                    v-for="file in file_details"
                                    :key="file.id"
                                    style="background:#eaeaea; padding-bottom:10px"
                                >
                                    <v-col cols="12" sm="5">
                                        <v-select
                                            v-model="file.type"
                                            :items="document_types"
                                            color="secondary"
                                            item-value="name"
                                            item-text="name"
                                            label="Document Type"
                                            required
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-file-input
                                            v-model="file.file"
                                            color="secondary"
                                            accept="application/pdf, image/*"
                                            label="File/Document"
                                        ></v-file-input>
                                    </v-col>
                                    <v-col cols="12" sm="1">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    fab
                                                    color="amber"
                                                    v-on="on"
                                                    @click="previewFile(file)"
                                                    :disabled="file.file===null || file.file===undefined"
                                                >
                                                    <v-icon>remove_red_eye</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Preview File</span>
                                        </v-tooltip>
                                    </v-col>
                                    <div style="background:white; height: 10px; width: 100%"></div>
                                </v-row>
                                <!-- Buttons for Adding/Removing Contact -->
                                <v-row>
                                    <v-col sm="10"></v-col>
                                    <v-col sm="2">
                                        <v-tooltip bottom v-if="file_details.length > 1">
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    class="mx-2"
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="removeFile()"
                                                >
                                                    <v-icon>remove</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Remove Document</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="addFile()"
                                                >
                                                    <v-icon>add</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Add Another Document</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="e1 = 3"
                                >
                                    <v-icon large>keyboard_arrow_left</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="e1 = 5"
                                >
                                    <v-icon large>keyboard_arrow_right</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Banking Section -->
                    <v-stepper-content step="5">
                        <v-card>
                            <v-card-text>
                                <v-row
                                    v-for="banking in bank_account_details"
                                    :key="banking.id"
                                    style="background:#eaeaea; padding-bottom:10px"
                                >
                                    <v-col cols="12" sm="3">
                                        <v-select
                                            v-model="banking.purpose"
                                            :items="banking_types"
                                            color="secondary"
                                            label="Purpose"
                                            required
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-select
                                            v-model="banking.bank"
                                            :items="banks_list"
                                            item-value="_id"
                                            item-text="name"
                                            color="secondary"
                                            label="Bank"
                                            required
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-select
                                            v-model="banking.account_type"
                                            :items="bank_account_types"
                                            color="secondary"
                                            label="Account Type"
                                            required
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-text-field
                                            v-model="banking.account_number"
                                            color="secondary"
                                            label="Account Number"
                                            autocomplete="some-random-text"
                                            type="text"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <div style="background:white; height: 10px; width: 100%"></div>
                                </v-row>
                                <!-- Buttons for Adding/Removing Banking Details -->
                                <v-row>
                                    <v-col sm="10"></v-col>
                                    <v-col sm="2">
                                        <v-tooltip bottom v-if="bank_account_details.length > 1">
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    class="mx-2"
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="removeBankingDetails()"
                                                >
                                                    <v-icon>remove</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Remove Address</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="addBankingDetails()"
                                                >
                                                    <v-icon>add</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Add Another Address</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="e1 = 4"
                                >
                                    <v-icon large>keyboard_arrow_left</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    large
                                    color="success"
                                    @click="submitScheme()"
                                >
                                    <v-icon large>save</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Submission Results Section -->
                    <v-stepper-content step="6">
                         <v-card light >
                            <v-card-text class="text-center">
                                <v-progress-linear
                                    color="green"
                                    class="mb-0"
                                    height="10"
                                    indeterminate
                                    :active="submitting"
                                ></v-progress-linear>
                            </v-card-text>
                            <v-list flat>
                                <v-subheader>Saving Scheme Info</v-subheader>
                                <v-list-item-group color="primary">
                                    <v-list-item
                                        v-for="(item, i) in backendActionItems"
                                        :key="i"
                                    >
                                        <v-list-item-icon>
                                            <v-icon>done</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ item }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    large
                                    color="success"
                                    @click="closeOnboardingDialog()"
                                >
                                    <v-icon large>done</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>
        <DocPreviewDialog
            :dialog="open_doc_preview_dialog"
            :url="file_url"
            :doc_type="doc_type"
            @CLOSE_DOC_PREVIEW_DIALOG="closeDocPreviewDialog()"
        />
    </v-row>
</template>
<script>
import DocPreviewDialog from '../../../components/doc_preview';
import ObjectID from "bson-objectid";

import { submitDocumentumFile, emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";
import {
    document_types,
    address_types,
    contact_types,
    banking_types,
    bank_account_types,
} from "../../../../config";

import { getBanks, getSchemes } from "../gql/queries";
import { entityProcessScheme } from "../gql/mutations";

export default {
    components: {
        DocPreviewDialog,
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        backend_check: false,
        submitting: false,
        backendActionItems: [],
        server_response_obj: null,

        file: '',

        // File Preview Dialog
        file_url: null,
        doc_type: null,

        e1: 1,
        scheme_details: {
            name: '',
            scheme_type: null
        },
        
        addresses: [
            {
                id: 1,
                type: null,
                line1: null,
                line2: null,
                line3: null,
                line4: null,
            }
        ],
        contact_details: [
            {
                id: 1,
                type: null,
                primary: null,
                details: null
            }
        ],
        file_details: [
            {
                id: 1,
                type: null,
                file: null,
                valid: false,
            }
        ],
        bank_account_details: [
            {
                id: 1,
                purpose: null,
                bank: null,
                account_type: null,
                account_number: null
            }
        ],

        open_doc_preview_dialog: false,

        address_types: address_types,
        contact_types: contact_types,
        banking_types: banking_types,
        banks_list: [],
        bank_account_types: bank_account_types,
        document_types: document_types.filter(doc_type => { return doc_type.type === 'scheme' }),

        documents_map: {},

        name_rules: [
            v => !!v || 'Name is required',
            v => v.trim().length >= 2 || 'Min 2 characters'
        ],
    }),
    methods: {
        closeOnboardingDialog() {
            eventBus.$emit('CLOSE_ONBOARDING_DIALOG', this.server_response_obj);
        },
        resetForm() {
            this.e1 = 1;
            this.server_response_obj = null;
            this.submitting = false;

            this.documents_map = {};

            this.scheme_details = {
                name: '',
                scheme_type: null
            };
            
            this.addresses = [
                {
                    id: 1,
                    type: null,
                    line1: null,
                    line2: null,
                    line3: null,
                    line4: null,
                }
            ];
            this.contact_details = [
                {
                    id: 1,
                    type: null,
                    primary: null,
                    details: null
                }
            ];
            this.file_details = [
                {
                    id: 1,
                    type: null,
                    file: null,
                    valid: false,
                }
            ];
            this.bank_account_details = [
                {
                    id: 1,
                    purpose: null,
                    bank: null,
                    account_type: null,
                    account_number: null
                }
            ];

            this.$refs.name_field.resetValidation();
        },

        //--------------------ADD/REMOVE ADDRESSES--------------------------//
        addAddress() {
            const address_size = this.addresses.length;

            this.addresses.push({
                id: address_size + 1,
                type: null,
                line1: null,
                line2: null,
                line3: null,
                line4: null,
            });
        },
        removeAddress() {
            this.addresses.pop();
        },

        //--------------------ADD/REMOVE CONTACTS--------------------------//
        addContact() {
            const contacts_size = this.contact_details.length;

            this.contact_details.push({
                id: contacts_size + 1,
                type: null,
                primary: null,
                details: null
            });
        },
        removeContact() {
            this.contact_details.pop();
        },

        //--------------------ADD/REMOVE FILES--------------------------//
        addFile() {
            const files_size = this.file_details.length;

            this.file_details.push({
                id: files_size + 1,
                type: null,
                file: null
            });
        },
        removeFile() {
            this.file_details.pop();
        },

        //--------------------ADD/REMOVE BANKING--------------------------//
        addBankingDetails() {
            const size = this.bank_account_details.length;

            this.bank_account_details.push({
                id: size + 1,
                purpose: null,
                bank: null,
                account_type: null,
                account_number: null,
            });
        },
        removeBankingDetails() {
            this.bank_account_details.pop();
        },

        previewFile(fileObject) {
            // Set file oobject so we can trigger the readAsDataURL function
            const reader = new FileReader();

            reader.addEventListener("load", () => {
                // Set PDF-Viewer URL to the selected file Base64 String
                this.file_url = reader.result;
                if (fileObject.file.type === 'application/pdf') {
                    this.doc_type = 'pdf';
                } else {
                    this.doc_type = 'image';
                }

                // Display the preview dialog
                this.open_doc_preview_dialog = true;
            }, false);

            reader.readAsDataURL(fileObject.file);
        },
        closeDocPreviewDialog() {
            this.open_doc_preview_dialog = false;
        },

        moveToStep2BtnDisabled() {
            if (this.scheme_details.name.trim().length < 2) {
                return true;
            }
            if (this.scheme_details.scheme_type === null) {
                return true;
            }
            return false;
        },
        async moveToStep2() {
            // Check if scheme with name entered already exists
            this.backend_check = true;

            try {
                const res = await this.$apollo
                                    .query({
                                        query: getSchemes,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'SCHEME',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            },
                                            searchInput: {
                                                name: this.scheme_details.name.trim()
                                            }
                                        },
                                    });
                this.backend_check = false;

                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.submitting = false;
                    return;
                }
                
                if (res.data.getBusinessObject.business_objects.length > 0) {
                    emitAlert(`There is already a scheme with the name ${this.scheme_details.name} onboarded on the system`);
                    return;
                }

                // Move to next page
                this.e1 = 2;
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },

        validateAddresses() {
            // We're no longer enforcing that a scheme should have address details....e.g. if its a baby???
            this.e1 = 3;
        },

        validateContacts() {
            // We're no longer enforcing that a scheme should have contact details....e.g. if its a baby???
            this.e1 = 4;
        },
        
        validateDocuments() {
            this.e1 = 5;
        },
        async submitScheme() {
            this.e1 = 6;

            this.submitting = true;
            const schemeObj = this.extractSchemeInfo();

            try {
                this.backendActionItems.push("Saving scheme info");

                // First create the scheme
                // const res = await createScheme(schemeObj);
                // if (res.errors === true) {
                //     emitAlert(res.message);
                //     this.submitting = false;
                //     this.backendActionItems = [];
                //     return;
                // }
                const payloadObj = {
                    OP_TYPE: 'CREATE',
                    // _id: this.scheme_original._id,

                    ...schemeObj
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: entityProcessScheme,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.entityProcessScheme.errors === true) {
                    emitAlert(res.data.entityProcessScheme.message);
                    this.submitting = false;
                    this.backendActionItems = [];
                    return;
                }

                this.backendActionItems.push("Saving scheme info succeeded");


                // Now upload documents to documentum
                const schemeId = res.data.entityProcessScheme.business_object._id;

                if (this.file_details.filter(file => { return file.valid === true; }).length > 0) {
                    this.backendActionItems.push("Saving scheme's documents");
                }
                
                for (const file of this.file_details) {
                    if (file.valid) {
                        const doc = this.documents_map[ file.id ];


                        const uploadRes = await submitDocumentumFile(file.id, file.file, 'scheme', schemeId);
                        if (uploadRes.errors === true) {
                            emitAlert(uploadRes.message);
                            this.submitting = false;
                            this.backendActionItems.push(`  --> Uploading ${doc.name} failed`);
                            return;
                        }
                        
                        this.backendActionItems.push(`  --> Uploading ${doc.name} succeeded`);
                    }
                }

                this.server_response_obj = res.data.entityProcessScheme.business_object;
                this.closeOnboardingDialog();
                emitSuccess(res.data.entityProcessScheme.message);
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.entityProcessScheme.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
        extractSchemeInfo() {
            const schemeObj = {
                name: null,
                scheme_type: null,
            };

            schemeObj.name = this.scheme_details.name;
            schemeObj.scheme_type = this.scheme_details.scheme_type;

            // ----------------------------------Extract Addresses if there are any
            const addresses = [];
            const validAddresses = this.addresses.filter(det => {
                return det.type !== null
            });

            if (validAddresses.length > 0) {
                validAddresses.forEach(addr => {
                    addresses.push({
                        type: addr.type,
                        line1: addr.line1,
                        line2: addr.line2,
                        line3: addr.line3,
                        line4: addr.line4,
                    });
                });

                schemeObj.addresses = addresses;
            }

            // ----------------------------------Extract contact details if there are any
            const contacts = [];
            const validContacts = this.contact_details.filter(cont => {
                return cont.type !== null && cont.primary
            });

            if (validContacts.length > 0) {
                validContacts.forEach(cont => {
                    contacts.push({
                        type: cont.type,
                        primary: (cont.primary === 'Yes') ? true : false,
                        details: cont.details,
                    });
                });

                schemeObj.contact_details = contacts;
            }


            // ----------------------------------Extract documents if there are any
            const documents = [];
            this.file_details.forEach(file => {
                if ((file.file !== null) && (file.type !== null)) {
                    const objID = ObjectID();

                    file.id = objID.toString();
                    file.valid = true;

                    documents.push({
                        _id: objID, //Generate mngodb id
                        type: file.type,
                        name: file.file.name,
                        mime_type: file.file.type,
                    });

                    this.documents_map[objID.toString()] = {
                        _id: objID, //Generate mngodb id
                        type: file.type,
                        name: file.file.name,
                        mime_type: file.file.type,
                    };
                }

                schemeObj.documents = documents;
            });

            // ----------------------------------Extract banking details if there are any
            const banking = [];
            const validBanking = this.bank_account_details.filter(bank => {
                return bank.purpose !== null
            });

            if (validBanking.length > 0) {
                validBanking.forEach(bank => {
                    banking.push({
                        purpose: bank.purpose,
                        bank: bank.bank,
                        account_type: bank.account_type,
                        account_number: bank.account_number,
                    });
                });

                schemeObj.bank_account_details = banking;
            }

            return schemeObj;
        }
    },
    async mounted() {
        this.submitting = true;
        try {
            const res = await this.$apollo
                                    .query({
                                        query: getBanks,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'BANK',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.submitting = false;
                return;
            }
            
            this.banks_list = res.data.getBusinessObject.business_objects;

            // Reset fields
            this.submitting = false;
        } catch (error) {
            this.submitting = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
            
            return;
        }
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
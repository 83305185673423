import Vue from 'vue';
// import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueCookies from 'vue-cookies';
import App from './App.vue'
import vuetify from './plugins/vuetify';
import appRouter from './router';
import { createProvider } from './vue-apollo'
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@mdi/font/css/materialdesignicons.css';

import VueSocketIOExt from 'vue-socket.io-extended';
import { io } from 'socket.io-client';
import { getSocketUrl } from './utils/api_env_vars';

// eslint-disable-next-line
export const eventBus = new Vue();

setTimeout(async () => {
    const SOCKET_URL = await getSocketUrl();

    const socket = io(SOCKET_URL, {
        transports: ['websocket'],
        path: '/socket.io'
    });

    Vue.config.productionTip = false;
    // Vue.config.devtools = false;


    Vue.use(VueAxios, axios);
    Vue.use(VueCookies);
    Vue.use(VueSocketIOExt, socket);

    new Vue({
        router: appRouter,
        vuetify,
        // apolloProvider,
        apolloProvider: createProvider(),
        render: h => h(App)
    }).$mount('#app')
});

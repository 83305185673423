import gql from 'graphql-tag'

export const getPolicies = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on Policy {
                    __typename
                    _id
                    business_partner {
                        _id
                        bp_number
                        name
                    }
                    group
                    policy_number
                    underwriter_policy_number
                    effective_date
                    end_date
                    high_risk

                    principal_member {
                        _id
                        name
                        surname
                        id_number
                    }
                    premium_payer {
                        _id
                        name
                        surname
                        id_number
                    }
                    elected_dependent {
                        _id
                        name
                        surname
                        id_number
                    }
                    
                    agent {
                        _id
                        agent_number
                        entity {
                            _id
                            name
                            surname
                            id_number
                        }
                    }
                    product {
                        _id
                        name
                        markup_fixed
                        markup_percentage
                        primary_product {
                            _id
                            name
                            underwriter_premium
                            broker_commission
                            broker_markup
                            
                            payout_structure {
                                _id
                                payout_amount
                                category {
                                    _id
                                    name
                                    membership_category
                                    start_age
                                    end_age
                                }
                            }
                        }
                    }
                    scheme {
                        _id
                        scheme_number
                        name
                    }
                    premium
                    total_cover_amount
                    elected_payment_date
                    # scheme_level_payment
                    status
                    # agent_status
                    underwriter_status
                    decline_reason
                    close_reason
                    created
                    captured_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                            id_number
                        }
                    }
                    payment_information {
                        premium_payer {
                            payment_method
                            percentage_amount
                        }
                        scheme {
                            payment_method
                            percentage_amount
                        }
                    }
                    members {
                        _id
                        entity {
                            _id
                            name
                            surname
                            id_number
                        }
                        role
                        covered_by_underwriter
                        created
                        start_date
                        end_date
                        termination_reason

                        cover_amount
                        gender

                        captured_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                                id_number
                            }
                        }
                        activated_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                                id_number
                            }
                        }
                        updated_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                                id_number
                            }
                        }

                        # Derived
                        age_inception
                        age_current
                    }
                    documents {
                        _id
                        type
                        mime_type
                        name
                        created
                        deleted
                        delete_date
                        delete_reason
                        captured_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                                id_number
                            }
                        }
                        deleted_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                                id_number
                            }
                        }
                    }
                }
            }
        }
    }
`

export const getSystemFinanceAccounts = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on FinanceAccount {
                    __typename
                    _id
                    name
                    description
                    type
                }
            }
        }
    }
`

export const getFinanceTransactionsList = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on FinanceAccountTransaction {
                    __typename
                    _id
                    transaction_date

                    amount
                    from {
                        _id
                        name
                        balance
                        last_updated
                    }
                    to {
                        _id
                        name
                        balance
                        last_updated
                    }
                    bank_feed {
                        _id
                        bank_account_name
                        date
                        amount
                        description
                        reference
                        created
                        classification {
                            _id
                            name
                            category
                        }
                    }
                    reference
                    additional_reference

                    balance_from
                    balance_to

                    created
                    created_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }
                }
            }
        }
    }
`

export const getInstallmentScheduleList = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on PolicyInstallmentScheduleItem {
                    __typename
                    _id
                    installment_number
                    deadline_date
                    created

                    premium_underwriter
                    premium_underwriter_collected
                    premium_underwriter_target

                    premium_broker_markup
                    premium_broker_markup_collected
                    premium_broker_markup_target

                    premium_total_expected
                    premium_total_collected

                    installment_status
                }
            }
        }
    }
`

export const finGetDebitOrderStatus = gql`
    query finGetDebitOrderStatus($input: DebitOrderStatusInput!) {
        finGetDebitOrderStatus(input: $input) {
            date
            amount
            status
            days_until_confirmation
        }
    }
`

export const searchBankFeedAutocomplete = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on BankFeed {
                    __typename
                    _id
                    bank_account_name
                    date
                    amount
                    description
                    reference
                    classification {
                        _id
                        name
                        category
                    }
                    created
                }
            }
        }
    }
`
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticClass:"pa-0",attrs:{"color":"secondary","dark":"","height":"50","extension-height":"3"}},[_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"10px"},attrs:{"sm":"8"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.refreshDataTable()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh")])])],1),_c('v-col',{staticStyle:{"margin-top":"10px"},attrs:{"sm":"4"}},[_c('div',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"amber black--text"},on:{"click":function($event){return _vm.openAddBranchDialog()}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v("Add Branch")])])],1)])],1)],1),_c('multipane',{staticClass:"horizontal-panes",staticStyle:{"height":"100%"},attrs:{"layout":"horizontal"},on:{"paneResize":_vm.paneResizing}},[_c('div',{staticClass:"pane",style:({ height: '50%', maxHeight: '50%'})},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.main_tab_height,"headers":_vm.branches_headers,"items":_vm.branches,"item-key":"_id","single-select":true,"item-class":"secondary","hide-default-footer":"","disable-pagination":true,"loading":_vm.data_table_loading,"loading-text":"Loading... Please wait"},on:{"click:row":_vm.selectMainTableRow},scopedSlots:_vm._u([{key:"item.branch_number",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v("BR-"+_vm._s(item.branch_number))])]}},{key:"item.start_date",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.start_date.split('T')[0]))])]}},{key:"item.end_date",fn:function(ref){
var item = ref.item;
return [(item.end_date !== null)?_c('td',[_vm._v(_vm._s(item.end_date.split('T')[0]))]):_vm._e()]}},{key:"item.captured_by",fn:function(ref){
var item = ref.item;
return [(item.captured_by !== null)?_c('td',[(item.captured_by.entity !== null)?_c('span',[_vm._v(" "+_vm._s(item.captured_by.entity.name)+" "+_vm._s(item.captured_by.entity.surname)+" ")]):_c('span',[_vm._v("N/A")])]):_vm._e()]}}])})],1),_c('multipane-resizer'),_c('div',{staticClass:"pane tab-nav",style:({ height: '30%', maxHeight: '50%', minHeight: '30%' })},[_c('v-tabs',{staticClass:"tab-nav",attrs:{"color":"transparent","hide-slider":"","height":"35px","grow":""},model:{value:(_vm.sub_tabs),callback:function ($$v) {_vm.sub_tabs=$$v},expression:"sub_tabs"}},[[_c('v-tab',{key:"address_sub_tab",staticStyle:{"max-width":"210px","font-size":"12px"},attrs:{"active-class":"secondary white--text"}},[_c('span',{staticClass:"tab-title text-truncate"},[_vm._v(" Address ")]),_c('div',{staticClass:"spacer"})])],[_c('v-tab',{key:"management_sub_tab",staticStyle:{"max-width":"210px","font-size":"12px"},attrs:{"active-class":"secondary white--text"}},[_c('span',{staticClass:"tab-title text-truncate"},[_vm._v(" Management ")]),_c('div',{staticClass:"spacer"})])]],2),_c('v-tabs-items',{model:{value:(_vm.sub_tabs),callback:function ($$v) {_vm.sub_tabs=$$v},expression:"sub_tabs"}},[_c('v-toolbar',{staticClass:"pa-0",attrs:{"color":"secondary","dark":"","height":"50","extension-height":"3"}}),_c('v-tab-item',{key:"address_sub_tab"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.sub_tab_height,"headers":_vm.address_details_headers,"items":_vm.branch_addresses,"item-key":"_id","single-select":true,"hide-default-footer":"","disable-pagination":true},on:{"click:row":_vm.selectAddressTabRow},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.created !== null ? item.created.split('T')[0] : ''))])]}},{key:"item.captured_by",fn:function(ref){
var item = ref.item;
return [(item.captured_by !== null && item.captured_by !== undefined)?_c('td',[_vm._v(" "+_vm._s(item.captured_by.entity.name)+" "+_vm._s(item.captured_by.entity.surname)+" ")]):_vm._e()]}}],null,true)})],1),_c('v-tab-item',{key:"management_sub_tab"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.sub_tab_height,"headers":_vm.branch_management_headers,"items":_vm.branch_management,"item-key":"_id","single-select":true,"hide-default-footer":"","disable-pagination":true},on:{"click:row":_vm.selectContactsTabRow},scopedSlots:_vm._u([{key:"item.agent",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.agent !== null && item.agent !== undefined ? item.agent.entity.name + ' ' + item.agent.entity.surname + ' (' + item.agent.agent_number + ')': '')+" ")])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.created !== null ? item.created.split('T')[0] : ''))])]}},{key:"item.captured_by",fn:function(ref){
var item = ref.item;
return [(item.captured_by !== null)?_c('td',[_vm._v(" "+_vm._s(item.captured_by.entity.name)+" "+_vm._s(item.captured_by.entity.surname)+" ")]):_vm._e()]}},{key:"item.start_date",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.start_date.split('T')[0]))])]}},{key:"item.end_date",fn:function(ref){
var item = ref.item;
return [(item.end_date !== null)?_c('td',[_vm._v(_vm._s(item.end_date.split('T')[0]))]):_vm._e()]}}],null,true)})],1)],1)],1)],1),_c('AddBranchDialog',{attrs:{"dialog":_vm.open_add_branch_dialog}}),(_vm.selectedBranch !== null)?_c('AddAddressesDialog',{attrs:{"dialog":_vm.open_add_address_dialog,"entity_id":_vm.selectedBranch._id}}):_vm._e(),(_vm.selectedBranch !== null && _vm.selectedAddress !== null)?_c('EditAddressDialog',{attrs:{"dialog":_vm.open_edit_address_dialog,"entity_id":_vm.selectedBranch._id,"address_original":_vm.selectedAddress,"address_copy":_vm.selectedAddressCopy}}):_vm._e(),(_vm.selectedBranch !== null)?_c('DeleteAddressesDialog',{attrs:{"dialog":_vm.open_delete_address_dialog,"entity":_vm.selectedBranch,"address":_vm.selectedAddress}}):_vm._e(),(_vm.selectedBranch !== null)?_c('AddContactsDialog',{attrs:{"dialog":_vm.open_add_contact_dialog,"entity_id":_vm.selectedBranch._id}}):_vm._e(),(_vm.selectedContact !== null)?_c('DeleteContactsDialog',{attrs:{"dialog":_vm.open_delete_contact_dialog,"entity":_vm.selectedBranch,"contact":_vm.selectedContact}}):_vm._e(),(_vm.selectedBranch !== null && _vm.selectedContact !== null)?_c('EditContactDialog',{attrs:{"dialog":_vm.open_edit_contact_dialog,"entity_id":_vm.selectedBranch._id,"contact_original":_vm.selectedContact,"contact_copy":_vm.selectedContactCopy}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticClass:"pa-0",attrs:{"color":"secondary","dark":"","height":"50","extension-height":"3"}},[_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"10px"},attrs:{"sm":"3"}},[_c('v-tooltip',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.loadMoreData()}}},on),[_c('v-icon',[_vm._v("cloud_download")])],1)]}}])},[_c('span',[_vm._v("Load More Data")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.refreshSchemeAllocations()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh")])])],1),_c('v-col',{attrs:{"sm":"4"}}),_c('v-col',{staticStyle:{"margin-top":"10px"},attrs:{"sm":"5"}},[_c('div',{staticClass:"text-right"},[((
                            _vm.selectedSchemeAllocation !== null/* &&
                            selectedSchemeAllocation.status === 'NOT-PROCESSED'*/
                        ))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"primary white--text"},on:{"click":function($event){return _vm.processSchemeAllocation()}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-cog")]),_vm._v(" Process Scheme Allocation ")],1)]}}],null,false,560637529)},[_c('span',[_vm._v("Process Scheme Allocation")])]):_vm._e(),((
                            _vm.isCentralUnderwriterAdminUser &&
                            _vm.selectedSchemeAllocation !== null &&
                            _vm.selectedSchemeAllocation.status === 'PROCESSED'
                        ))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"red white--text"},on:{"click":function($event){return _vm.reverseSchemeAllocation()}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-cog")]),_vm._v(" Reverse Scheme Allocation ")],1)]}}],null,false,1094560006)},[_c('span',[_vm._v("Reverse Scheme Allocation")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"amber black--text"},on:{"click":function($event){return _vm.openOnboardingDialog()}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("add")]),_vm._v(" Scheme-Allocation ")],1)]}}])},[_c('span',[_vm._v("Capture Scheme-Allocation")])])],1)])],1)],1),_c('multipane',{staticClass:"horizontal-panes",staticStyle:{"height":"100%"},attrs:{"layout":"horizontal"},on:{"paneResize":_vm.paneResizing}},[_c('div',{staticClass:"pane",style:({ height: '50%', maxHeight: '50%'})},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.main_tab_height,"headers":_vm.scheme_allocation_details_headers,"items":_vm.scheme_allocations,"item-key":"_id","single-select":true,"item-class":"secondary","hide-default-footer":"","loading":_vm.data_table_loading,"disable-pagination":true},on:{"click:row":_vm.selectMainTableRow},scopedSlots:_vm._u([{key:"item.allocation_number",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v("SA-"+_vm._s(item.allocation_number))])]}},{key:"item.transaction_date",fn:function(ref){
                        var item = ref.item;
return [(item.transaction_date !== null)?_c('td',[_vm._v(_vm._s(item.transaction_date.split('T')[0]))]):_vm._e()]}},{key:"item.created",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.created.split('T')[0]))])]}},{key:"item.captured_by",fn:function(ref){
                        var item = ref.item;
return [(item.captured_by !== null && item.captured_by !== undefined)?_c('td',[_vm._v(" "+_vm._s(item.captured_by.entity.name)+" "+_vm._s(item.captured_by.entity.surname)+" ")]):_vm._e()]}},{key:"item.scheme",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.scheme.name)+" ("+_vm._s(item.scheme.scheme_number)+")")])]}},{key:"item.bank_feed_item",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.bank_feed_item !== null ? 'BANK-FEED' : 'SCHEME-BALANCE'))])]}},{key:"item.transaction_amount",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v("R "+_vm._s(item.transaction_amount))])]}},{key:"item.allocation_amount",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v("R "+_vm._s(item.allocation_amount))])]}},{key:"item.payment_method",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.payment_method.name))])]}}],null,true)})],1),_c('multipane-resizer'),_c('div',{staticClass:"pane tab-nav",style:({ height: '30%', maxHeight: '50%', minHeight: '30%' })},[_c('v-tabs',{staticClass:"tab-nav",attrs:{"color":"transparent","hide-slider":"","height":"35px","grow":""},model:{value:(_vm.sub_tabs),callback:function ($$v) {_vm.sub_tabs=$$v},expression:"sub_tabs"}},[[_c('v-tab',{key:"policies_sub_tab",staticStyle:{"max-width":"210px","font-size":"12px"},attrs:{"active-class":"secondary white--text"}},[_c('span',{staticClass:"tab-title text-truncate"},[_vm._v(" Policies ")]),_c('div',{staticClass:"spacer"})])],[_c('v-tab',{key:"transactions_sub_tab",staticStyle:{"max-width":"210px","font-size":"12px"},attrs:{"active-class":"secondary white--text"}},[_c('span',{staticClass:"tab-title text-truncate"},[_vm._v(" Transactions History ")]),_c('div',{staticClass:"spacer"})])],[_c('v-tab',{key:"documents_sub_tab",staticStyle:{"max-width":"210px","font-size":"12px"},attrs:{"active-class":"secondary white--text"}},[_c('span',{staticClass:"tab-title text-truncate"},[_vm._v(" Documents ")]),_c('div',{staticClass:"spacer"})])]],2),_c('v-tabs-items',{model:{value:(_vm.sub_tabs),callback:function ($$v) {_vm.sub_tabs=$$v},expression:"sub_tabs"}},[_c('v-toolbar',{staticClass:"pa-0",attrs:{"color":"secondary","dark":"","height":"50","extension-height":"3"}},[(_vm.sub_tabs === 2 && _vm.selectedSchemeAllocation !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.openAddDocumentsDialog()}}},on),[_c('v-icon',[_vm._v("add")])],1)]}}],null,false,2302782764)},[_c('span',[_vm._v("Add Scheme-Allocation Document(s)")])]):_vm._e(),(_vm.sub_tabs === 2  && _vm.selectedDocument !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.openDeleteSchemeDocumentDialog()}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,false,562523082)},[_c('span',[_vm._v("Delete Scheme-Allocation Document(s)")])]):_vm._e(),(_vm.sub_tabs === 2  && _vm.selectedDocument !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.openViewDocumentDialog()}}},on),[_c('v-icon',[_vm._v("content_paste")])],1)]}}],null,false,3437369687)},[_c('span',[_vm._v("View Document Info")])]):_vm._e(),(_vm.sub_tabs === 1 && _vm.selectedSchemeAllocation !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.openAddPremiumPaymentEventDialog()}}},on),[_c('v-icon',[_vm._v("add")])],1)]}}],null,false,2113422493)},[_c('span',[_vm._v("Capture Payment Event")])]):_vm._e(),(_vm.sub_tabs === 1 && _vm.selectedSchemeAllocation !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"amber black--text","small":""},on:{"click":function($event){return _vm.refreshSchemeTransactions()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}],null,false,896030906)},[_c('span',[_vm._v("Refresh Payment Events")])]):_vm._e(),_c('v-spacer')],1),_c('v-tab-item',{key:"policies_sub_tab"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.sub_tab_height,"headers":_vm.policies_details_headers,"items":_vm.scheme_policies,"item-key":"_id","single-select":true,"hide-default-footer":"","disable-pagination":true},on:{"click:row":_vm.tobeDefined},scopedSlots:_vm._u([{key:"item.policy",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.policy.policy_number)+" ("+_vm._s(item.policy.principal_member.name)+" "+_vm._s(item.policy.principal_member.surname)+" - "+_vm._s(item.policy.principal_member.id_number)+")")])]}},{key:"item.premium_amount",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v("R "+_vm._s(item.premium_amount))])]}},{key:"item.scheme_contribution_amount",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v("R "+_vm._s(item.scheme_contribution_amount))])]}}],null,true)})],1),_c('v-tab-item',{key:"transactions_sub_tab"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.sub_tab_height,"headers":_vm.transactions_headers,"items":_vm.transactions,"item-key":"_id","single-select":true,"item-class":"secondary","hide-default-footer":"","loading":_vm.transactions_loading,"disable-pagination":true},on:{"click:row":_vm.tobeDefined},scopedSlots:_vm._u([{key:"item.transaction_date",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.transaction_date !== null ? item.transaction_date.split('T')[0] : ''))])]}},{key:"item.created",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.created !== null ? item.created.split('T')[0] + ' @ ' + item.created.split('T')[1].substr(0,8) : ''))])]}},{key:"item.amount",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v("R "+_vm._s(item.amount))])]}},{key:"item.balance",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v("R "+_vm._s(item.balance))])]}}],null,true)})],1),_c('v-tab-item',{key:"documents_sub_tab"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.sub_tab_height,"headers":_vm.document_details_headers,"items":_vm.scheme_documents,"item-key":"_id","single-select":true,"hide-default-footer":"","disable-pagination":true},on:{"click:row":_vm.selectDocumentsTabRow},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
                        var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.created.split('T')[0]))])]}},{key:"item.captured_by",fn:function(ref){
                        var item = ref.item;
return [(item.captured_by !== null && item.captured_by !== undefined)?_c('td',[_vm._v(" "+_vm._s(item.captured_by.entity.name)+" "+_vm._s(item.captured_by.entity.surname)+" ")]):_vm._e()]}}],null,true)})],1)],1)],1)],1),_c('OnboardingDialog',{attrs:{"dialog":_vm.open_onboarding_dialog}}),(_vm.selectedSchemeAllocation !== null)?_c('AddSchemeDocumentDialog',{attrs:{"dialog":_vm.open_add_document_dialog,"scheme":_vm.selectedSchemeAllocation}}):_vm._e(),(_vm.selectedDocument !== null)?_c('ViewDocumentDialog',{attrs:{"dialog":_vm.open_view_scheme_document_dialog,"document":_vm.selectedDocument,"param_path":"scheme_allocation","param_id":_vm.selectedSchemeAllocation._id}}):_vm._e(),(_vm.selectedDocument !== null)?_c('DeleteSchemeDocumentDialog',{attrs:{"dialog":_vm.open_delete_scheme_document_dialog,"scheme":_vm.selectedSchemeAllocation,"document":_vm.selectedDocument}}):_vm._e(),(_vm.selectedSchemeAllocation !== null)?_c('AddSchemePremiumPaymentDialog',{attrs:{"dialog":_vm.open_add_premium_payment_dialog,"scheme":_vm.selectedSchemeAllocation}}):_vm._e(),_c('SearchSchemesDialog',{attrs:{"dialog":_vm.open_search_schemes_dialog},on:{"CLOSE_SCHEME_SEARCH_DIALOG":function($event){return _vm.closeSearchSchemesDialog()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
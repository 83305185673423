<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Business Process Run Search</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeMemberSearchDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <!-- Fin Calendar Month -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.fin_calendar_month.search"
                                color="success"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-select
                                v-model="search_fields.fin_calendar_month.value"
                                :items="fin_calendar_months"
                                color="secondary"
                                item-text="name"
                                item-value="_id"
                                label="Financial Calendar Month"
                                :disabled="refreshing_fin_calendar_month_list || !search_fields.fin_calendar_month.search"

                                hint="You can click the refresh icon next to the field if the financial calendar months list is incomplete"
                                persistent-hint

                                required
                            >
                                <!-- Format prepend inner icon -->
                                <template v-slot:prepend-inner>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon
                                                v-on="on"
                                                color="success"
                                                @click="refreshFinCalendarMonthsLookupList()"
                                            >
                                                mdi-refresh
                                            </v-icon>
                                        </template>
                                        <span>Refresh Fin Calendar List</span>
                                    </v-tooltip>
                                </template>
                            </v-select>
                            <v-progress-linear
                                color="green"
                                class="mb-0"
                                height="4"
                                indeterminate
                                :active="refreshing_fin_calendar_month_list"
                            ></v-progress-linear>
                        </v-col>

                        <!-- Business Process -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.business_process.search"
                                color="success"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-select
                                v-model="search_fields.business_process.value"
                                :items="business_process_lookup_list"
                                color="secondary"
                                item-text="name"
                                item-value="_id"
                                label="Business Process"
                                :disabled="refreshing_business_process_lookup_list || !search_fields.business_process.search"

                                hint="You can click the refresh icon next to the field if the business process list is incomplete"
                                persistent-hint

                                required
                            >
                                <!-- Format prepend inner icon -->
                                <template v-slot:prepend-inner>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon
                                                v-on="on"
                                                color="success"
                                                @click="refreshBusinessProcessesLookupList()"
                                            >
                                                mdi-refresh
                                            </v-icon>
                                        </template>
                                        <span>Refresh Business Process List</span>
                                    </v-tooltip>
                                </template>
                            </v-select>
                            <v-progress-linear
                                color="green"
                                class="mb-0"
                                height="4"
                                indeterminate
                                :active="refreshing_business_process_lookup_list"
                            ></v-progress-linear>
                        </v-col>

                        <!-- Status -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.status.search"
                                color="success"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-select
                                v-model="search_fields.status.value"
                                :items="['IN-PROGRESS', 'COMPLETED']"
                                label="Process Status"
                                color="secondary"
                                :disabled="!search_fields.status.search"
                                required
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Searching Business Process Runs
                        <v-progress-linear
                            indeterminate
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom v-if="search_field_selected">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                @click="submitSearchQuery()"
                            >
                                <v-icon large>search</v-icon>
                            </v-btn>
                        </template>
                        <span>Submit Search Query</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { eventBus } from '../../../main';
import { emitAlert } from '../../../utils/api';
import { getBusinessProcessRuns, getFinancialCalendarMonths, getBusinessProcesses } from "../gql/queries";
export default {
    components: {},
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        fin_calendar_months: [],
        refreshing_fin_calendar_month_list: false,

        business_process_lookup_list: [],
        refreshing_business_process_lookup_list: false,

        search_fields: {
            fin_calendar_month: {
                search: false,
                value: null,
            },
            business_process: {
                search: false,
                value: null,
            },
            status: {
                search: false,
                value: null,
            },
        },
        submitting: false
    }),
    methods: {
        closeMemberSearchDialog() {
            eventBus.$emit('CLOSE_SEARCH_BUSINESS_PROCESS_RUN_DIALOG', null);
        },
        async submitSearchQuery() {
            this.submitting = true;

            const searchObj = {};

            Object.keys(this.search_fields).forEach(searchField => {
                if (this.search_fields[ searchField ].search === true) {
                    searchObj[ searchField ] = this.search_fields[ searchField ].value;
                }
            });

            try {
                const res = await this.$apollo
                                        .query({
                                            query: getBusinessProcessRuns,
                                            // fetchPolicy: 'network-only',
                                            fetchPolicy: 'no-cache',
                                            errorPolicy: 'all',
                                            variables: {
                                                objectType: 'PROSPECT_POLICY',
                                                pagination: {
                                                    page: this.page,
                                                    limit: this.limit
                                                },
                                                searchInput: searchObj
                                            },
                                        });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.submitting = false;
                    return;
                }

                this.submitting = false;
                eventBus.$emit('CLOSE_POLICY_SEARCH_DIALOG', res.data.getBusinessObject.business_objects);
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        async refreshFinCalendarMonthsLookupList() {
            this.refreshing_fin_calendar_month_list = true;
            try {
                // Load Opened Fin Calendar Months
                // const PAGE = 1;
                // const LIMIT = 20;
                // let res = await getOpenedFinancialCalendarMonths(PAGE, LIMIT);
                // this.refreshing_fin_calendar_month_list = false;

                // if (res.errors === true) {
                //     emitAlert(res.message);
                //     return;
                // }

                const res = await this.$apollo
                                        .query({
                                            query: getFinancialCalendarMonths,
                                            // fetchPolicy: 'network-only',
                                            fetchPolicy: 'no-cache',
                                            errorPolicy: 'all',
                                            variables: {
                                                objectType: 'FINANCE_CALENDAR_MONTH',
                                                pagination: {
                                                    page: this.page,
                                                    limit: this.limit
                                                }
                                            },
                                        });
                this.refreshing_fin_calendar_month_list = false;
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }

                res.data.getBusinessObject.business_objects.forEach(month => {
                    month.name = month.name + ' ------- ' + month.status;
                })
                this.fin_calendar_months = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.refreshing_fin_calendar_month_list = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },

        async refreshBusinessProcessesLookupList() {
            this.refreshing_business_process_lookup_list = true;
            try {
                const res = await this.$apollo
                                        .query({
                                            query: getBusinessProcesses,
                                            // fetchPolicy: 'network-only',
                                            fetchPolicy: 'no-cache',
                                            errorPolicy: 'all',
                                            variables: {
                                                objectType: 'BUSINESS_PROCESS',
                                                pagination: {
                                                    page: this.page,
                                                    limit: this.limit
                                                }
                                            },
                                        });
                this.refreshing_business_process_lookup_list = false;
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }

                this.business_process_lookup_list = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.refreshing_business_process_lookup_list = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
    },
    async mounted() {
        await this.refreshFinCalendarMonthsLookupList();
        await this.refreshBusinessProcessesLookupList();
    },
    computed: {
        search_field_selected() {
            return (
                this.search_fields.fin_calendar_month.search ||
                this.search_fields.status.search ||
                this.search_fields.business_process.search
            )
        }
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
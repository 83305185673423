<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Add Classification Keyword</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeAddPolicyMemberDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row
                        v-for="keyword in keywords"
                        :key="keyword.id"
                        style="background:#eaeaea; padding-bottom:10px"
                    >
                        <v-col cols="12" sm="12">
                            <v-text-field
                                v-model="keyword.keyword"
                                label="Keyword"
                                color="secondary"
                                hint="e.g. ADT Cash Deposi 94859DS9"
                                persistent-hint
                                required
                            ></v-text-field>
                        </v-col>
                        <div style="background:white; height: 10px; width: 100%"></div>
                    </v-row>
                    <!-- Buttons for Adding/Removing Keywords -->
                    <v-row>
                        <v-col sm="10"></v-col>
                        <v-col sm="2">
                            <v-tooltip bottom v-if="keywords.length > 1">
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        class="mx-2"
                                        color="secondary"
                                        small
                                        v-on="on"
                                        @click="removeKeyword()"
                                    >
                                        <v-icon>remove</v-icon>
                                    </v-btn>
                                </template>
                                <span>Remove Keyword</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        color="secondary"
                                        small
                                        v-on="on"
                                        @click="addKeyword()"
                                    >
                                        <v-icon>add</v-icon>
                                    </v-btn>
                                </template>
                                <span>Add Another Keyword</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting Policy Members
                        <v-progress-linear
                            indeterminate
                            :active="submitting"
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submitKeywordsBtnDisabled()"
                                @click="submitKeywords()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Save Policy Member</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../../utils/api";
import { eventBus } from "../../../../main";

import { getUserRolesContextualized } from "../gql/queries";
import { businessProcessManagementAccounts } from "../gql/mutations";

export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        classification_id: {
            type: String,
            default: null
        },
    },
    data: () => ({
        keywords: [
            {
                id: 1,
                keyword: null,
            }
        ],

        final_keywords: [],
        
        error_message: null,
        submitting: false,
        server_response_obj: null,
        refreshing_user_roles_list: false,

        user_roles: []
    }),
    methods: {
        closeAddPolicyMemberDialog() {
            eventBus.$emit('CLOSE_ADD_CLASSIFICATION_KEYWORDS_DIALOG', this.server_response_obj);
        },
        resetForm() {
            this.server_response_obj = null;
            this.final_keywords = [];
            this.keywords = [
                {
                    id: 1,
                    keyword: null,
                }
            ];
        },

        //--------------------ADD/REMOVE KEYWORD--------------------------//
        addKeyword() {
            const keywords_size = this.keywords.length;

            this.keywords.push({
                id: keywords_size + 1,
                keyword: null
            });
        },
        removeKeyword() {
            this.keywords.pop();
        },
        
        submitKeywordsBtnDisabled() {
            const invalidKeywords = this.keywords.filter(item => {
                let invalid = false;

                if (typeof(item.keyword) === 'string') {
                    if (item.keyword.trim() === '') {
                        invalid = true;
                    }
                }

                if (
                    item.keyword === null ||
                    item.keyword === undefined
                ) {
                    invalid = true;
                }

                return invalid;
            });

            if (invalidKeywords.length > 0) {
                return true;
            }
            return false;
        },
        async submitKeywords() {
            // Then submit the policy
            this.submitting = true;
            
            for (const keyword of this.keywords) {
                if (keyword.keyword !== null) {
                    this.final_keywords.push({
                        match_key_word: keyword.keyword
                    });
                }
            }

            try {
                const payload = {
                    OP_TYPE: 'ADD_CLASSIFICATION_KEYWORD',
                    _id: this.classification_id,
                    key_words_filters: this.final_keywords
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcessManagementAccounts,
                                                variables: {
                                                    input: payload
                                                },
                                            });
                this.submitting = false;

                if (res.data.businessProcessManagementAccounts.errors === true) {
                    emitAlert(res.data.businessProcessManagementAccounts.message);
                    return;
                }
                
                emitSuccess(res.data.businessProcessManagementAccounts.message);

                this.server_response_obj = res.data.businessProcessManagementAccounts.business_objects[0];
                this.closeAddPolicyMemberDialog();

                // Reset
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessManagementAccounts.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },

        async refreshUserRolesList() {
            this.refreshing_user_roles_list = true;
            try {
                // Load Contextualized user roles
                const res = await this.$apollo
                                    .query({
                                        query: getUserRolesContextualized,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'USER_ROLE_CONTEXTUALIZED',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                this.refreshing_user_roles_list = false;

                if (res.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }
                this.user_roles = res.data.getBusinessObject.business_objects
            } catch (error) {
                this.refreshing_user_roles_list = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        }
    },
    computed: {
    },
    async mounted() {
        try {
            // Load Contextualized user roles
            const res = await this.$apollo
                                    .query({
                                        query: getUserRolesContextualized,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'USER_ROLE_CONTEXTUALIZED',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.submitting = false;
                return;
            }
            this.user_roles = res.data.getBusinessObject.business_objects
        } catch (error) {
            this.submitting = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
            
            return;
        }
    }
}
</script>
<style scoped>

</style>
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Update Agent Branch</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <!-- Branch -->
                        <v-col cols="12" sm="6">
                            <BranchSearch :member="branch_details" label="Afrosurance Branch" />
                        </v-col>
                        <!-- Start Date -->
                        <v-col cols="12" sm="6">
                            <v-menu
                                v-model="start_date_menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="branch_details.start_date"
                                    color="secondary"
                                    label="Start Date"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    color="secondary"
                                    v-model="branch_details.start_date"
                                    @input="start_date_menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting Agent Branch Info
                        <v-progress-linear
                            indeterminate
                            :active="submitting"
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submitAgentBtnDisabled()"
                                @click="submitAgent()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Submit Agent's Details</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import BranchSearch from "../components/branch_search.vue";
import { emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";

import { entityProcessAgent } from "../gql/mutations";

export default {
    components: {
        BranchSearch
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        agent: {
            type: Object,
            default: null
        },
    },
    data: () => ({
        server_response_obj: null,
        submitting: false,

        start_date_menu: false,

        branch_details: {
            branch: null,
            start_date: null,
        },
    }),
    methods: {
        closeDialog() {
            eventBus.$emit('CLOSE_AGENT_ASSIGNMENT_DIALOG', this.server_response_obj);
        },
        resetForm() {
            this.submitting = false;
            this.branch_details = {
                branch: null,
                start_date: null,
            };
        },

        submitAgentBtnDisabled() {
            if (this.branch_details.branch === null) {
                return true;
            }
            if (this.branch_details.start_date === null) {
                return true;
            }

            return false;
        },

        async submitAgent() {
            this.submitting = true;

            try {
                const res = await this.$apollo
                                                .mutate({
                                                    mutation: entityProcessAgent,
                                                    variables: {
                                                        input: {
                                                            OP_TYPE: 'UPDATE_BRANCH_ASSIGNMENT',
                                                            _id: this.agent._id,
                                                            
                                                            branch_history: [
                                                                {
                                                                    branch: this.branch_details.branch._id,
                                                                    start_date: this.branch_details.start_date
                                                                }
                                                            ]
                                                        }
                                                    },
                                                });
                if (res.data.entityProcessAgent.errors === true) {
                    emitAlert(res.data.entityProcessAgent.message);
                    this.submitting = false;
                    return;
                }
                
                this.server_response_obj = res.data.entityProcessAgent.business_object;
                this.closeDialog();
                emitSuccess(res.data.entityProcessAgent.message);

                // Reset fields
                this.server_response_obj = null;
                this.submitting = false;
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.entityProcessAgent.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request')
                }
                
                return;
            }
        },
    },
    mounted() {
        
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
<template>
    <div
        style="
            padding:0px;
            width:100%;
            border-top:3px solid #4590a0;
            border-bottom:3px solid #4590a0;
        "
    >
        <v-toolbar
            color="secondary"
            dark
            height="50"
        >
            <v-tooltip
                bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        color="amber black--text"
                        small
                        @click="refreshLoanTransactions()"
                        v-on="on"
                    >
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                </template>
                <span>Refresh Payment Events</span>
            </v-tooltip>
        </v-toolbar>

        <v-data-table
            fixed-header
            :height="sub_tab_height"
            :headers="transactions_headers"
            :items="transactions"
            item-key="_id"
            class="elevation-1"
            :single-select="true"
            @click:row="selectTransactionTabRow"
            item-class="secondary"
            hide-default-footer
            :loading="transactions_loading"
            :disable-pagination="true"
        >
            <!-- Display create date -->
            <template v-slot:item.transaction_date="{ item }">
                <td>{{item.transaction_date.split('T')[0]}}</td>
            </template>
            <!-- Display Formated created date -->
            <template v-slot:item.created="{ item }">
                <td>{{item.created !== null ? item.created.split('T')[0] + '  @  ' + item.created.split('T')[1].substr(0,8) : ''}}</td>
            </template>
            <!-- Display Formated amount -->
            <template v-slot:item.amount="{ item }">
                <td>R {{item.amount}}</td>
            </template>
            <!-- Display Formated balance -->
            <template v-slot:item.balance="{ item }">
                <td>R {{item.balance}}</td>
            </template>
            <!-- User who created the details -->
            <template v-slot:item.created_by="{ item }" >
                <td v-if="item.created_by !== null">
                    <span v-if="item.created_by.entity !== null">
                        {{item.created_by.entity.name}} {{item.created_by.entity.surname}}
                    </span>
                    <span v-else>N/A</span>
                </td>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import { eventBus } from "../../../../../../main";
import { store } from "../../../../../../../store";
import { emitAlert } from '../../../../../../utils/api';
import { LOAN_STATUSES, BUSINESS_PARTNER_TYPES } from "../../../../../../../config";
import { getFinanceTransactionsList } from "../../../../gql/queries";

export default {
    name: "TransactionsHistory",
    props: {
        entityObj: {
            required: true,
        },
    },
    components: {},
    data: () => ({
        sub_tab_height:'450',

        transactions_headers: [
            { text: 'Transaction Date', value: 'transaction_date' },
            // { text: 'Created', value: 'created' },
            { text: 'Loan', value: 'to.name' },
            { text: 'Payment Method', value: 'from.name' },
            // { text: 'Financial Calendar Month', value: 'fin_calendar_month.name' },
            { text: 'System Reference', value: 'reference' },
            { text: 'Customer Reference', value: 'additional_reference' },
            { text: 'Amount', value: 'amount' },
            // { text: 'Running Balance', value: 'balance' },
            { text: 'Captured By', value: 'created_by' },
        ],

        transactions: [],

        transactions_loading: false,
        selectedTransaction: null,
        selectedTransactionRow: null,

        LOAN_STATUSES: LOAN_STATUSES,
        BUSINESS_PARTNER_TYPES: BUSINESS_PARTNER_TYPES,

        open_add_premium_payment_dialog: false,
    }),
    computed: {
        isCentralUnderwriterAdminUser() {
            const result = store.state.currentUserAccount.roles.filter(role => {
                                                            return (
                                                                role.role.level.includes(BUSINESS_PARTNER_TYPES.CENTRAL_UNDERWRITER) &&
                                                                role.role.name.toLowerCase() ===  'admin - central underwriter'
                                                            );
                                                        }).length > 0;
            return result;
        },
        isCollectionsManager() {
            const result = store.state.currentUserAccount.roles.filter(role => {
                                                            return (
                                                                role.role.level.includes(BUSINESS_PARTNER_TYPES.CENTRAL_UNDERWRITER) &&
                                                                role.role.name.toLowerCase() ===  'collections manager'
                                                            );
                                                        }).length > 0;

            return result;
        },
    },
    mounted() {
        eventBus.$on('CLOSE_ADD_PREMIUM_PAYMENT_DIALOG', this.closeAddPremiumPaymentDialog);
    },
    methods: {
        selectTransactionTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedTransaction = item;
                this.selectedTransactionRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedTransaction = null;
                this.selectedTransactionRow = null;
            }
        },

        openAddPremiumPaymentEventDialog() {
            this.open_add_premium_payment_dialog = true;
        },

        async refreshLoanTransactions() {
            this.transactions = [];
            try {
                this.transactions_loading = true;

                const distinctLoans = {};
                for (const schedule of this.entityObj.upcoming_loan_schedules) {
                    distinctLoans[ schedule.loan._id ] = 1;
                }
                for (const schedule of this.entityObj.outstanding_loan_schedules) {
                    distinctLoans[ schedule.loan._id ] = 1;
                }

                for (const loanId of Object.keys(distinctLoans)) {
                    const res = await this.$apollo
                                        .query({
                                            query: getFinanceTransactionsList,
                                            // fetchPolicy: 'network-only',
                                            fetchPolicy: 'no-cache',
                                            errorPolicy: 'all',
                                            variables: {
                                                objectType: 'FINANCE_ACCOUNT_TRANSACTION',
                                                pagination: {
                                                    page: 1,
                                                    limit: 50
                                                },
                                                searchInput: {
                                                    account_id: loanId
                                                }
                                            },
                                        });

                    if (res.data.getBusinessObject.errors === true) {
                        emitAlert(res.data.getBusinessObject.message);
                        this.transactions_loading = false;
                        return;
                    }

                    const allTransactions = res.data.getBusinessObject.business_objects;
                    for (const transObj of allTransactions) {
                        if (transObj.to._id === loanId) {
                            this.transactions.push(transObj);
                        }
                    }
                }

                this.transactions_loading = false;
            } catch (error) {
                this.transactions_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
        closeAddPremiumPaymentDialog(new_transaction) {
            if (new_transaction !== null) {
                // Prepend to transactions array
                this.transactions.unshift(new_transaction);

                // Prepare balance
                if (new_transaction.from._id === this.entityObj._id) {
                    new_transaction.balance = new_transaction.balance_from;
                }

                if (new_transaction.to._id === this.entityObj._id) {
                    new_transaction.balance = new_transaction.balance_to;
                }
            }
            this.open_add_premium_payment_dialog = false;
        },
    },
    watch: {
        async entityObj(val) {
            // console.log(val)
            if (val !== null) {
                await this.refreshLoanTransactions()
            }

            if (this.selectedTransactionRow !== null) {
                // Unselect currently selected row
                this.selectedTransactionRow.select(false);
            }
            this.selectedTransaction = null;
            this.selectedTransactionRow = null;

            // this.transactions = [];
        }
    }
};
</script>
<style>
  .v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
    transform: rotate(180deg);
  }
</style>
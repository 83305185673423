<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Update Scheme Loan Premium Allocation</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn
                    icon
                    @click="closeEditSchemeDialog()"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="scheme_copy.loan_premium_allocation_priority.principal"
                                color="secondary"
                                label="Principal Amount"
                                autocomplete="some-random-text"
                                type="number"
                                hint="Principal Amount"
                                persistent-hint
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="scheme_copy.loan_premium_allocation_priority.interest"
                                color="secondary"
                                label="Interest"
                                autocomplete="some-random-text"
                                type="number"
                                hint="Interest"
                                persistent-hint
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="scheme_copy.loan_premium_allocation_priority.monthly_admin_fees"
                                color="secondary"
                                label="Monthly Admin Fee"
                                autocomplete="some-random-text"
                                type="number"
                                hint="Monthly Admin Fee"
                                persistent-hint
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="scheme_copy.loan_premium_allocation_priority.initiation_fees"
                                color="secondary"
                                label="Initiation Fee"
                                autocomplete="some-random-text"
                                type="number"
                                hint="Initiation Fee"
                                persistent-hint
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Updating Scheme Details
                        <v-progress-linear
                            indeterminate
                            :active="submitting"
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="success"
                        :disabled="submitBtnDisabled()"
                        @click="submitScheme()"
                    >
                        <v-icon large>save</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";

import { entityProcessScheme } from "../gql/mutations";

export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        scheme_original: {
            type: Object,
            required: false,
            default: () => {
                return {
                    marketing_channels: []
                }
            },
        },
        scheme_copy: {
            type: Object,
            required: false,
            default: () => {
                return {
                    marketing_channels: []
                }
            },
        },
    },
    data: () => ({
        submitting: false,
        server_response_obj: null,
    }),
        
    methods: {
        closeEditSchemeDialog() {
            eventBus.$emit('CLOSE_EDIT_LOAN_PREMIUM_ALLOCATION_DIALOG', this.server_response_obj);
        },
        resetForm() {
            this.server_response_obj = null;
            eventBus.$emit('RESET_LOAN_PREMIUM_ALLOCATION_EDIT_FORM', this.scheme_copy);
        },
        
        submitBtnDisabled() {
            // Check numerical validity
            if (
                isNaN(parseInt(this.scheme_copy.loan_premium_allocation_priority.initiation_fees)) ||
                isNaN(parseInt(this.scheme_copy.loan_premium_allocation_priority.interest)) ||
                isNaN(parseInt(this.scheme_copy.loan_premium_allocation_priority.monthly_admin_fees)) ||
                isNaN(parseInt(this.scheme_copy.loan_premium_allocation_priority.principal))
            ) {
                return true;
            }

            // Make sure that numbers are greater than zero
            if (
                parseInt(this.scheme_copy.loan_premium_allocation_priority.initiation_fees) <= 0 ||
                parseInt(this.scheme_copy.loan_premium_allocation_priority.interest) <= 0 ||
                parseInt(this.scheme_copy.loan_premium_allocation_priority.monthly_admin_fees) <= 0 ||
                parseInt(this.scheme_copy.loan_premium_allocation_priority.principal) <= 0
            ) {
                return true;
            }

            const priorityArray = [
                parseInt(this.scheme_copy.loan_premium_allocation_priority.initiation_fees),
                parseInt(this.scheme_copy.loan_premium_allocation_priority.interest),
                parseInt(this.scheme_copy.loan_premium_allocation_priority.monthly_admin_fees),
                parseInt(this.scheme_copy.loan_premium_allocation_priority.principal),
            ];

            // Check for no duplicates
            if ((new Set(priorityArray)).size !== priorityArray.length) {
                return true;
            }

            return false;
        },

        capturingDone() {
            this.closeEditSchemeDialog();
            this.resetForm();
        },
        async submitScheme() {
            this.submitting = true;

            try {
                // Payload payload
                const submitObj = {
                    loan_premium_allocation_priority: {
                        principal: parseInt(this.scheme_copy.loan_premium_allocation_priority.principal),
                        initiation_fees: parseInt(this.scheme_copy.loan_premium_allocation_priority.initiation_fees),
                        interest: parseInt(this.scheme_copy.loan_premium_allocation_priority.interest),
                        monthly_admin_fees: parseInt(this.scheme_copy.loan_premium_allocation_priority.monthly_admin_fees),
                    },
                };

                const payloadObj = {
                    OP_TYPE: 'UPDATE_PREMIUM_ALLOCATION_PRIORITY',
                    _id: this.scheme_original._id,

                    ...submitObj
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: entityProcessScheme,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.entityProcessScheme.errors === true) {
                    emitAlert(res.data.entityProcessScheme.message);
                    this.submitting = false;
                    return;
                }
                
                this.server_response_obj = res.data.entityProcessScheme.business_object;
                this.closeEditSchemeDialog();
                emitSuccess(res.data.entityProcessScheme.message);

                // Reset fields
                this.resetForm();
                this.server_response_obj = null;
                this.submitting = false;
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.entityProcessScheme.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
        },
    },
    mounted() {

    },
}
</script>
<style scoped>

</style>
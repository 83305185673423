<template>
    <v-autocomplete
        v-model="member.member"
        :items="search_entries"
        :loading="isLoading"
        :search-input.sync="search"
        hide-no-data
        small-chips
        color="secondary"
        item-text="display_value"
        item-value="_id"
        label="Search Member"
        placeholder="Start typing to Search"
        prepend-icon="search"
        return-object
    ></v-autocomplete>
</template>

<script>
// import { searchEntityAutocomplete } from "../../../utils/api";
import { searchEntityAutocomplete } from "../gql/queries";
export default {
    name: "MemberSearch",
    props: {
        member: {
            required: false,
            default: null
        },
    },
    data: () => ({
        search: null,
        search_entries: [],
        isLoading: false
    }),
    computed: {
        items () {
            return this.search_entries.map(entry => {
                const display_value = `${entry.name}  ${entry.surname} (${entry.id_number})`;
                return Object.assign({}, entry, { display_value })
            })
        },
    },
    mounted() {
        // Set selected chip if member entity is already initialized
        if (this.member.member !== null && this.member.member !== undefined) {
            this.search_entries.push(this.member.member);
        }
    },
    watch: {
        search (val) {
            // Items have already been loaded
            // if (this.items.length > 0) return

            // // Items have already been requested
            // if (this.isLoading) return

            this.isLoading = true

            if (val !== null) {
                if (val.length > 0) {
                    const searchQuery = {
                        name: val,
                        surname: val,
                        id_number: val,
                    };

                    // searchEntityAutocomplete(searchQuery).then(res => {
                    //     this.isLoading = false;
                    //     res.entities.map(entry => {
                    //         const display_value = `${entry.name}  ${entry.surname} (${entry.id_number})`;
                    //         entry.display_value = display_value;
                    //         return entry;
                    //     });
                    //     this.search_entries = res.entities;
                    // });


                    this.$apollo.query({
                                    query: searchEntityAutocomplete,
                                    // fetchPolicy: 'network-only',
                                    fetchPolicy: 'no-cache',
                                    errorPolicy: 'all',
                                    variables: {
                                        objectType: 'ENTITY_AUTOCOMPLETE',
                                        pagination: {
                                            page: this.page,
                                            limit: this.limit
                                        },
                                        searchInput: searchQuery
                                    },
                                })
                                .then(res => {
                                    this.isLoading = false;
                                    res.data.getBusinessObject.business_objects.map(entry => {
                                        const display_value = `${entry.name}  ${entry.surname} (${entry.id_number})`;
                                        entry.display_value = display_value;
                                        return entry;
                                    });
                                    this.search_entries = res.data.getBusinessObject.business_objects;
                                });

                } else {
                    this.isLoading = false;
                    this.search_entries = [];
                }
            } else {
                this.isLoading = false;
            }
            
        },
    }
};
</script>
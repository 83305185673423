import { eventBus } from '../main';

export default {
    data() {
        return {
        alert: {},
        hideAlert: false,
        };
    },
    methods: {
        showAlert(type, message) {
            this.clearAlert();

            if (type === 'error') {
                this.alert.icon = 'warning';
                this.alert.color = 'warning';
                this.alert.timeout = 3000;
            } else if (type === 'success') {
                this.alert.icon = 'check_circle';
                this.alert.color = 'success';
                this.alert.timeout = 3000;
            } else {
                this.alert.icon = 'warning';
                this.alert.color = 'warning';
                this.alert.timeout = 5000;
            }
            this.hideAlert = true;
            this.alert.message = message;
        },
        clearAlert() {
            this.alert = {};
        },
    },
    mounted() {
        eventBus.$on('alertError', (alertMessage) => {
            this.showAlert('error', alertMessage);
        });
        eventBus.$on('alertSuccess', (alertMessage) => {
            this.showAlert('success', alertMessage);
        });
        eventBus.$on('alertClear', () => {
            this.clearAlert();
        });
        eventBus.$on('resetAlert', () => {
            this.hideAlert = false;
        });
    },
    watch: {
        $route() {
        this.clearAlert();
        },
    },
};

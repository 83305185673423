<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="50%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b style="color:white">User Registration Result</b></v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12" style="text-align:center">
                            <h3>The registration was successful. <br>You will now need to login using your new credentials</h3>
                        </v-col>
                        
                    </v-row>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                     <v-col cols="12" sm="8" offset="2" style="text-align:center">
                        <v-btn
                            large
                            block
                            color="amber"
                            class="mx-2"
                            @click="closeRegistrationSuccessDialog()"
                        >
                            Ok
                        </v-btn>
                     </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { eventBus } from "../../../main";

export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
    },
    data: () => ({
        
    }),
    methods: {
        closeRegistrationSuccessDialog() {
            eventBus.$emit('CLOSE_REG_SUCCESS_DIALOG');
        },
    },
    computed: {
    },
    mounted() {
    }
}
</script>
<style scoped>

</style>
<template>
    <div>
        <v-toolbar
            color="secondary"
            dark
            class="pa-0"
        >
            <!-- Function Sub-Tabs -->
            <template v-slot:extension>
                <v-tabs
                    v-model="sub_tab"
                    align-with-title
                    active-class="amber--text"
                >
                    <v-tabs-slider color="amber"></v-tabs-slider>

                    <v-tab>
                        <v-icon medium style="padding-right: 5px;">mdi-view-grid</v-icon>
                        <b>Overview</b>
                    </v-tab>
                    <v-tab>
                        <v-icon medium style="padding-right: 5px;">mdi-magnify</v-icon>
                        <b>Collections</b>
                    </v-tab>
                </v-tabs>
            </template>
        </v-toolbar>

        <v-tabs-items v-model="sub_tab">
            <!-- Overview Sub-Tab -->
            <v-tab-item>
                <OverviewSubTab />
            </v-tab-item>
            <!-- Collections Sub-Tab -->
            <v-tab-item>
                <CollectionsSubTab />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>
<script>
import OverviewSubTab from "./components/tabs/overview_tab/overview_tab.vue";
import CollectionsSubTab from "./components/tabs/collections_sub_tab/collections_sub_tab.vue";

import { BUSINESS_PARTNER_TYPES } from "../../../config";
import converter from "../../mixins/converter";
import { store } from "../../../store";

export default {
    mixins: [converter],
    components: {
        OverviewSubTab,
        CollectionsSubTab,
    },

    computed: {
        isCentralUnderwriterAdminUser() {
            const result = store.state.currentUserAccount.roles.filter(role => {
                                                            return (
                                                                role.role.level.includes(BUSINESS_PARTNER_TYPES.CENTRAL_UNDERWRITER) &&
                                                                role.role.name.toLowerCase() ===  'admin - central underwriter'
                                                            );
                                                        }).length > 0;

            return result;
        },
        isCollectionsManager() {
            const result = store.state.currentUserAccount.roles.filter(role => {
                                                            return (
                                                                role.role.level.includes(BUSINESS_PARTNER_TYPES.CENTRAL_UNDERWRITER) &&
                                                                role.role.name.toLowerCase() ===  'collections manager'
                                                            );
                                                        }).length > 0;

            return result;
        },
    },

    data: () => ({
        sub_tab: null,
    }),

    methods: {},

    async mounted() {},

    watch: {}
}
</script>
<style scoped>
/* Overriding of textbox min height */
 .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
}

.tab-nav .v-tabs {
	display: initial;
	/* padding: initial; */
	margin-left: initial;
	position: initial;
	z-index: initial;
}

a, .v-tab {
    background: #4590a0 !important;
    border-top: initial;
    border-left: initial;
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticClass:"pa-0",attrs:{"color":"secondary","dark":"","height":"50","extension-height":"3"}},[_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"10px"},attrs:{"sm":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.refreshRoles()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh")])]),(_vm.selectedRole !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.openEditRoleDetailsDialog()}}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,false,3527532230)},[_c('span',[_vm._v("Edit Record")])]):_vm._e()],1),_c('v-col',{attrs:{"sm":"4"}}),_c('v-col',{staticStyle:{"margin-top":"10px"},attrs:{"sm":"4"}},[_c('div',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"amber black--text"},on:{"click":function($event){return _vm.openAddRoleDialog()}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v("Add a User Role")])])],1)])],1)],1),_c('multipane',{staticClass:"horizontal-panes",staticStyle:{"height":"100%"},attrs:{"layout":"horizontal"},on:{"paneResize":_vm.paneResizing}},[_c('div',{staticClass:"pane",style:({ height: '50%', maxHeight: '50%'})},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.main_tab_height,"headers":_vm.role_details_headers,"items":_vm.user_roles,"item-key":"_id","single-select":true,"item-class":"secondary","hide-default-footer":"","loading":_vm.data_table_loading,"disable-pagination":true},on:{"click:row":_vm.selectMainTableRow},scopedSlots:_vm._u([{key:"item.level",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.level.join(', '))+" ")])]}}],null,true)})],1),_c('multipane-resizer'),_c('div',{staticClass:"pane tab-nav",style:({ height: '30%', maxHeight: '50%', minHeight: '30%' })},[_c('v-tabs',{staticClass:"tab-nav",attrs:{"color":"transparent","hide-slider":"","height":"35px","grow":""},model:{value:(_vm.sub_tabs),callback:function ($$v) {_vm.sub_tabs=$$v},expression:"sub_tabs"}},[[_c('v-tab',{key:"allowed_screens_sub_tab",staticStyle:{"max-width":"210px","font-size":"12px"},attrs:{"active-class":"secondary white--text"}},[_c('span',{staticClass:"tab-title text-truncate"},[_vm._v(" Allowed Screens ")]),_c('div',{staticClass:"spacer"})])],[_c('v-tab',{key:"allowed_reports_sub_tab",staticStyle:{"max-width":"210px","font-size":"12px"},attrs:{"active-class":"secondary white--text"}},[_c('span',{staticClass:"tab-title text-truncate"},[_vm._v(" Allowed Reports ")]),_c('div',{staticClass:"spacer"})])],[_c('v-tab',{key:"allowed_dashboards_sub_tab",staticStyle:{"max-width":"210px","font-size":"12px"},attrs:{"active-class":"secondary white--text"}},[_c('span',{staticClass:"tab-title text-truncate"},[_vm._v(" Allowed Dashboards ")]),_c('div',{staticClass:"spacer"})])],[_c('v-tab',{key:"allowed_business_processes_sub_tab",staticStyle:{"max-width":"210px","font-size":"12px"},attrs:{"active-class":"secondary white--text"}},[_c('span',{staticClass:"tab-title text-truncate"},[_vm._v(" Allowed Business Processes ")]),_c('div',{staticClass:"spacer"})])]],2),_c('v-tabs-items',{model:{value:(_vm.sub_tabs),callback:function ($$v) {_vm.sub_tabs=$$v},expression:"sub_tabs"}},[_c('v-toolbar',{staticClass:"pa-0",attrs:{"color":"secondary","dark":"","height":"50","extension-height":"3"}},[(_vm.sub_tabs === 0 && _vm.selectedRole !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.openAddAllowedScreensDialog()}}},on),[_c('v-icon',[_vm._v("add")])],1)]}}],null,false,3329109879)},[_c('span',[_vm._v("Add Allowed Screens")])]):_vm._e(),(_vm.sub_tabs === 0  && _vm.selectedAllowedScreen !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.removeRoleAllowedScreen()}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,false,3081113843)},[_c('span',[_vm._v("Delete Allowed Screen")])]):_vm._e(),(_vm.sub_tabs === 1 && _vm.selectedRole !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.openAddAllowedReportsDialog()}}},on),[_c('v-icon',[_vm._v("add")])],1)]}}],null,false,800351733)},[_c('span',[_vm._v("Add Allowed Reports")])]):_vm._e(),(_vm.sub_tabs === 1  && _vm.selectedAllowedReport !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.removeRoleAllowedReport()}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,false,1032844465)},[_c('span',[_vm._v("Delete Allowed Report")])]):_vm._e(),(_vm.sub_tabs === 2 && _vm.selectedRole !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.openAddAllowedDashboardsDialog()}}},on),[_c('v-icon',[_vm._v("add")])],1)]}}],null,false,1573260159)},[_c('span',[_vm._v("Add Allowed Dashboards")])]):_vm._e(),(_vm.sub_tabs === 2  && _vm.selectedAllowedDashboard !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.removeRoleAllowedDashboard()}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,false,2182537307)},[_c('span',[_vm._v("Delete Allowed Dashboard")])]):_vm._e(),(_vm.sub_tabs === 3 && _vm.selectedRole !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.openAddAllowedBusinessProcessesDialog()}}},on),[_c('v-icon',[_vm._v("add")])],1)]}}],null,false,3569237107)},[_c('span',[_vm._v("Add Allowed Dashboards")])]):_vm._e(),(_vm.sub_tabs === 3  && _vm.selectedAllowedBusinessProcess !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.removeAllowedBusinessProcessesDialog()}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,false,3269659800)},[_c('span',[_vm._v("Delete Allowed Dashboard")])]):_vm._e(),_c('v-spacer')],1),_c('v-tab-item',{key:"allowed_screens_sub_tab"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.sub_tab_height,"headers":_vm.allowed_screens_details_headers,"items":_vm.role_allowed_screens,"item-key":"_id","single-select":true,"hide-default-footer":"","disable-pagination":true},on:{"click:row":_vm.selectAllowedScreensTabRow},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.created.split('T')[0]))])]}}],null,true)})],1),_c('v-tab-item',{key:"allowed_reports_sub_tab"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.sub_tab_height,"headers":_vm.allowed_reports_details_headers,"items":_vm.role_allowed_reports,"item-key":"_id","single-select":true,"sort-by":"report.report_number","sort-desc":false,"hide-default-footer":"","disable-pagination":true},on:{"click:row":_vm.selectAllowedReportsTabRow},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.created.split('T')[0]))])]}}],null,true)})],1),_c('v-tab-item',{key:"allowed_dashboards_sub_tab"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.sub_tab_height,"headers":_vm.allowed_dashboards_details_headers,"items":_vm.role_allowed_dashboards,"item-key":"_id","single-select":true,"hide-default-footer":"","disable-pagination":true},on:{"click:row":_vm.selectAllowedDashboardsTabRow},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.created.split('T')[0]))])]}}],null,true)})],1),_c('v-tab-item',{key:"allowed_business_processes_sub_tab"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.sub_tab_height,"headers":_vm.allowed_business_processes_details_headers,"items":_vm.role_allowed_business_processes,"item-key":"_id","single-select":true,"hide-default-footer":"","disable-pagination":true},on:{"click:row":_vm.selectAllowedBusinessProcessesTabRow},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.created.split('T')[0]))])]}}],null,true)})],1)],1)],1)],1),_c('AddRoleDialog',{attrs:{"dialog":_vm.open_onboarding_dialog}}),(_vm.selectedRole !== null)?_c('EditRoleDetailsDialog',{attrs:{"dialog":_vm.open_edit_role_dialog,"role_original":_vm.selectedRole,"role_copy":_vm.selectedRoleCopy}}):_vm._e(),(_vm.selectedRole !== null)?_c('AddAllowedScreensDialog',{attrs:{"dialog":_vm.open_add_allowed_screens_dialog,"role_id":_vm.selectedRole._id}}):_vm._e(),(_vm.selectedRole !== null)?_c('AddAllowedReportsDialog',{attrs:{"dialog":_vm.open_add_allowed_reports_dialog,"role_id":_vm.selectedRole._id}}):_vm._e(),(_vm.selectedRole !== null)?_c('AddAllowedDashboardsDialog',{attrs:{"dialog":_vm.open_add_allowed_dashboards_dialog,"role_id":_vm.selectedRole._id}}):_vm._e(),(_vm.selectedRole !== null)?_c('AddAllowedBusinessProcessesDialog',{attrs:{"dialog":_vm.open_add_allowed_business_processes_dialog,"role_id":_vm.selectedRole._id}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
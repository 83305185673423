<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="45%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Underwriter Approved</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeDialog(true)">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row v-if="policy !== null">
                        <v-col cols="12" sm="12">
                            <h3>
                                Please capture the date the policy was approved by the underwriter below:
                            </h3>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-menu
                                v-model="approval_date_menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="approval_date"
                                    color="secondary"
                                    label="Approval Date"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    color="secondary"
                                    v-model="approval_date"
                                    @input="approval_date_menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting...
                        <v-progress-linear
                            indeterminate
                            color="success"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>

                <v-divider></v-divider>

                <!-- Button Section -->
                <v-card-actions>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="red white--text"
                                v-on="on"
                                @click="closeDialog(true)"
                            >
                                <v-icon large>close</v-icon>
                                <span>Cancel</span>
                            </v-btn>
                        </template>
                        <span>Cancel</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                @click="submitApproveAction()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Proceed to approve policy</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { elected_payment_dates, PROSPECT_POLICY_STATUS_ACTIONS } from '../../../../../../../../config';
import { eventBus } from '../../../../../../../main';
import { emitAlert, emitSuccess } from '../../../../../../../utils/api';

import { businessProcessProspectPolicy } from "../../../gql/mutations";

export default {
    name: 'SearchMembersDialog',
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        policy: {
            type: Object,
            default: null
        }
    },
    data: () => ({
        approval_date_menu: false,
        approval_date: null,

        elected_payment_dates: elected_payment_dates,
        submitting: false
    }),
    methods: {
        closeDialog(cancelled) {
            const respObj = {
                cancelled: cancelled,
                policy: this.server_response_obj
            };
            eventBus.$emit('CLOSE_UNDERWRITER_APPROVAL_DIALOG', respObj);
        },
        async submitApproveAction() {
            this.submitting = true;

            const payloadObj = {
                        OP_TYPE: 'STATUS',
                        _id: this.policy._id,

                        action_code: PROSPECT_POLICY_STATUS_ACTIONS.AWAITING_UNDERWRITER_APPROVAL_TO_APPROVED,
                        underwriter_approval_date: this.approval_date
                    };

            try {
                const res = await this.$apollo
                        .mutate({
                            mutation: businessProcessProspectPolicy,
                            variables: {
                                input: payloadObj
                            },
                        });
                this.submitting = false;
                this.server_response_obj = res.data.businessProcessProspectPolicy.business_object;
                if (res.data.businessProcessProspectPolicy.business_object !== null) {
                    emitSuccess(res.data.businessProcessProspectPolicy.message);

                    // Reset
                    this.approval_date = null;
                } else {
                    emitAlert(res.data.businessProcessProspectPolicy.message);
                }
                this.closeDialog(false);
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessValidatePolicy.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
            
        },
    },
    mounted() {
        
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
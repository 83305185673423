<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Add Business Partner</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeOnboardingDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-stepper v-model="e1">
                <v-stepper-header>
                    <v-stepper-step :complete="e1 > 1" step="1" color="success">Details</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 2" step="2" color="success">Contact People</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 3" step="3" color="success">Address</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 4" step="4" color="success">Documents</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 5" step="5" color="success">Banking</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="6" color="success">Processing</v-stepper-step>
                </v-stepper-header>
            
                <v-stepper-items>
                    <!-- Basic Details -->
                    <v-stepper-content step="1">
                        <v-card>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            v-model="bp_details.name"
                                            :rules="name_rules"
                                            ref="name_field"
                                            color="secondary"
                                            label="Business Partner Name"
                                            autocomplete="some-random-text"
                                            type="text"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            v-model="bp_details.company_reg_number"
                                            :rules="reg_number_rules"
                                            ref="company_reg_number_field"
                                            color="secondary"
                                            label="Company Reg Number"
                                            autocomplete="some-random-text"
                                            type="text"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-select
                                            v-model="bp_details.type"
                                            :items="business_partner_types"
                                            label="Type"
                                            color="secondary"
                                            required
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6" v-if="bp_details.type === 'BUSINESS_INTRODUCER'">
                                        <v-select
                                            v-model="bp_details.administration_option"
                                            :items="bp_administration_options"
                                            item-text="text"
                                            item-value="value"
                                            label="Administration Option"
                                            color="secondary"
                                            required
                                        ></v-select>
                                    </v-col>
                                </v-row>

                                <div v-if="bp_details.type === 'VAS_PROVIDER'">
                                    <v-row style="background:#b9d0d5">
                                        <v-col cols="12" sm="12">
                                            <h2>VAS Services</h2>
                                        </v-col>
                                    </v-row>
                                    <v-row
                                        style="background:#b9d0d5; padding-bottom:10px"
                                        v-for="vas_service in bp_details.vas_services"
                                        :key="vas_service.id"
                                    >
                                        <v-col cols="12" sm="7">
                                            <v-select
                                                v-model="vas_service.vas_service"
                                                :items="vas_services_list"
                                                color="secondary"
                                                item-value="_id"
                                                item-text="name"
                                                label="VAS Service"
                                                :disabled="refreshing_vas_services_list"

                                                hint="You can click the refresh icon next to the field if the VAS services list is incomplete"
                                                persistent-hint

                                                required
                                            >
                                                <!-- Format prepend inner icon -->
                                                <template v-slot:prepend-inner>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon
                                                                v-on="on"
                                                                color="success"
                                                                @click="refreshVASServicessList()"
                                                            >
                                                                mdi-refresh
                                                            </v-icon>
                                                        </template>
                                                        <span>Refresh VAS Services List</span>
                                                    </v-tooltip>
                                                </template>
                                            </v-select>
                                            <v-progress-linear
                                                color="green"
                                                class="mb-0"
                                                height="4"
                                                indeterminate
                                                :active="refreshing_vas_services_list"
                                            ></v-progress-linear>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <v-select
                                                v-model="vas_service.fee_billing_model"
                                                :items="['PER_MONTH', 'PER_ANNUM', 'PER_CLAIM']"
                                                label="Billing Model"
                                                color="secondary"
                                                required
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12" sm="2">
                                            <v-text-field
                                                v-model="vas_service.fee_amount"
                                                :rules="vas_fee_rules"
                                                color="secondary"
                                                label="Fee Amount"
                                                prefix="ZAR"
                                                autocomplete="some-random-text"
                                                type="number"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col sm="10"></v-col>
                                        <v-col sm="2">
                                            <v-tooltip bottom v-if="bp_details.vas_services.length > 1">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        class="mx-2"
                                                        color="secondary"
                                                        small
                                                        v-on="on"
                                                        @click="removeVASService()"
                                                    >
                                                        <v-icon>remove</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Remove VAS Service</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        color="secondary"
                                                        small
                                                        v-on="on"
                                                        @click="addVASService()"
                                                    >
                                                        <v-icon>add</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Add Another VAS Service</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-card-text>
                            <v-progress-linear
                                color="green"
                                class="mb-0"
                                height="10"
                                indeterminate
                                :active="backend_check"
                            ></v-progress-linear>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    fab
                                    color="amber"
                                    :disabled="moveToStep2BtnDisabled()"
                                    @click="moveToStep2()"
                                >
                                    <v-icon large>keyboard_arrow_right</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>


                    <!-- Contact People Section -->
                    <v-stepper-content step="2">
                        <v-card>
                            <v-card-text>
                                <v-row
                                    v-for="contact in contact_people"
                                    :key="contact.id"
                                    style="background:#eaeaea; padding-bottom:10px"
                                >
                                    <v-col cols="12" sm="2">
                                        <v-select
                                            v-model="contact.role"
                                            :items="['DIRECTOR', 'CEO', 'CFO', 'CTO']"
                                            label="Role / Position"
                                            color="secondary"
                                            required
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            v-model="contact.name"
                                            :rules="name_rules"
                                            :ref="'contact_person_name_field_'+contact.id"
                                            color="secondary"
                                            label="Name"
                                            autocomplete="some-random-text"
                                            type="text"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-text-field
                                            v-model="contact.surname"
                                            :rules="surname_rules"
                                            :ref="'contact_person_surname_field_'+contact.id"
                                            color="secondary"
                                            label="Surname"
                                            autocomplete="some-random-text"
                                            type="text"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-select
                                            v-model="contact.marital_status"
                                            color="secondary"
                                            :items="marital_statuses"
                                            label="Marital Status"
                                            required
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-text-field
                                            v-model="contact.phone"
                                            :rules="phone_rules"
                                            :ref="'contact_person_phone_field_'+contact.id"
                                            color="secondary"
                                            label="Phone"
                                            hint="e.g. 0721234567"
                                            persistent-hint
                                            autocomplete="some-random-text"
                                            type="number"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="5">
                                        <v-text-field
                                            v-model="contact.email"
                                            :rules="email_rules"
                                            :ref="'contact_person_email_field_'+contact.id"
                                            color="secondary"
                                            label="Email"
                                            autocomplete="some-random-text"
                                            type="text"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-select
                                            v-model="contact.system_access_role"
                                            :items="user_roles"
                                            color="secondary"
                                            item-value="_id"
                                            item-text="name"
                                            label="System Access Role"
                                            :disabled="refreshing_user_roles_list"

                                            hint="You can click the refresh icon next to the field if the system access roles list is incomplete"
                                            persistent-hint

                                            required
                                        >
                                            <!-- Format prepend inner icon -->
                                            <template v-slot:prepend-inner>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon
                                                            v-on="on"
                                                            color="success"
                                                            @click="refreshUserRolesList()"
                                                        >
                                                            mdi-refresh
                                                        </v-icon>
                                                    </template>
                                                    <span>Refresh User Roles List</span>
                                                </v-tooltip>
                                            </template>
                                        </v-select>
                                        <v-progress-linear
                                            color="green"
                                            class="mb-0"
                                            height="4"
                                            indeterminate
                                            :active="refreshing_user_roles_list"
                                        ></v-progress-linear>
                                    </v-col>
                                    
                                    <v-row style="background:#b2d9c7" no-gutters>
                                        <v-col cols="12" sm="3" >
                                            <v-text-field
                                                v-model="contact.id_number_field"
                                                :rules="user_id_number_rules"
                                                :ref="'contact_person_id_number_field_'+contact.id"
                                                label="ID Number"
                                                autocomplete="some-random-text"
                                                type="number"
                                                color="secondary"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="2">
                                            <v-text-field
                                                v-model="contact.id_number_field_valid"
                                                label="ID Number Valid"
                                                disabled
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="2">
                                            <v-select
                                                v-model="contact.birth_date_century_field"
                                                :items="['19', '20']"
                                                label="Birth Century"
                                                disabled
                                                required
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12" sm="2">
                                            <v-text-field
                                                v-model="contact.partial_birth_date_field"
                                                label="Birth Date"
                                                disabled
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="3" >
                                            <v-select
                                                v-model="contact.gender"
                                                :items="entity_gender"
                                                label="Gender"
                                                disabled
                                                required
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                    <div style="background:white; height: 10px; width: 100%"></div>
                                </v-row>
                                <!-- Buttons for Adding/Removing Contact -->
                                <v-row>
                                    <v-col sm="10"></v-col>
                                    <v-col sm="2">
                                        <v-tooltip bottom v-if="contact_people.length > 1">
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    class="mx-2"
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="removeContact()"
                                                >
                                                    <v-icon>remove</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Remove Address</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="addContact()"
                                                >
                                                    <v-icon>add</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Add Another Address</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="e1 = 1"
                                >
                                    <v-icon large>keyboard_arrow_left</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="goToStep3()"
                                    :disabled="goToStep3BtnDisabled()"
                                >
                                    <v-icon large>keyboard_arrow_right</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Address Section -->
                    <v-stepper-content step="3">
                        <v-card>
                            <v-card-text>
                                <v-row
                                    v-for="address in addresses"
                                    :key="address.id"
                                    style="background:#eaeaea; padding-bottom:10px"
                                >
                                    <v-col cols="12" sm="3">
                                        <v-select
                                            v-model="address.type"
                                            :items="address_types"
                                            color="secondary"
                                            label="Address Type"
                                            required
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-text-field
                                            v-model="address.line1"
                                            color="secondary"
                                            label="Line 1"
                                            autocomplete="some-random-text"
                                            type="text"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-text-field
                                            v-model="address.line2"
                                            color="secondary"
                                            label="Line 2"
                                            autocomplete="some-random-text"
                                            type="text"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-text-field
                                            v-model="address.line3"
                                            color="secondary"
                                            label="Line 3"
                                            autocomplete="some-random-text"
                                            type="text"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-text-field
                                            v-model="address.line4"
                                            color="secondary"
                                            label="Postal Code"
                                            autocomplete="some-random-text"
                                            type="text"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <div style="background:white; height: 10px; width: 100%"></div>
                                </v-row>
                                <!-- Buttons for Adding/Removing Addresses -->
                                <v-row>
                                    <v-col sm="10"></v-col>
                                    <v-col sm="2">
                                        <v-tooltip bottom v-if="addresses.length > 1">
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    class="mx-2"
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="removeAddress()"
                                                >
                                                    <v-icon>remove</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Remove Address</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="addAddress()"
                                                >
                                                    <v-icon>add</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Add Another Address</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="e1 = 2"
                                >
                                    <v-icon large>keyboard_arrow_left</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="validateAddresses()"
                                >
                                    <v-icon large>keyboard_arrow_right</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Documents Section -->
                    <v-stepper-content step="4">
                        <v-card>
                            <v-card-text>
                                <v-row
                                    v-for="file in file_details"
                                    :key="file.id"
                                    style="background:#eaeaea; padding-bottom:10px"
                                >
                                    <v-col cols="12" sm="5">
                                        <v-select
                                            v-model="file.type"
                                            :items="document_types"
                                            color="secondary"
                                            item-value="name"
                                            item-text="name"
                                            label="Document Type"
                                            required
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-file-input
                                            v-model="file.file"
                                            color="secondary"
                                            accept="application/pdf, image/*"
                                            label="File/Document"
                                        ></v-file-input>
                                    </v-col>
                                    <v-col cols="12" sm="1">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    fab
                                                    color="amber"
                                                    v-on="on"
                                                    @click="previewFile(file)"
                                                    :disabled="file.file===null || file.file===undefined"
                                                >
                                                    <v-icon>remove_red_eye</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Preview File</span>
                                        </v-tooltip>
                                    </v-col>
                                    <div style="background:white; height: 10px; width: 100%"></div>
                                </v-row>
                                <!-- Buttons for Adding/Removing Contact -->
                                <v-row>
                                    <v-col sm="10"></v-col>
                                    <v-col sm="2">
                                        <v-tooltip bottom v-if="file_details.length > 1">
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    class="mx-2"
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="removeFile()"
                                                >
                                                    <v-icon>remove</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Remove Document</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="addFile()"
                                                >
                                                    <v-icon>add</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Add Another Document</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="e1 = 3"
                                >
                                    <v-icon large>keyboard_arrow_left</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="e1 = 5"
                                >
                                    <v-icon large>keyboard_arrow_right</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Banking Section -->
                    <v-stepper-content step="5">
                        <v-card>
                            <v-card-text>
                                <v-row
                                    v-for="banking in bank_account_details"
                                    :key="banking.id"
                                    style="background:#eaeaea; padding-bottom:10px"
                                >
                                    <v-col cols="12" sm="3">
                                        <v-select
                                            v-model="banking.purpose"
                                            :items="banking_types"
                                            color="secondary"
                                            label="Purpose"
                                            required
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-select
                                            v-model="banking.bank"
                                            :items="banks_list"
                                            item-value="_id"
                                            item-text="name"
                                            color="secondary"
                                            label="Bank"
                                            required
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-select
                                            v-model="banking.account_type"
                                            :items="bank_account_types"
                                            color="secondary"
                                            label="Account Type"
                                            required
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-text-field
                                            v-model="banking.account_number"
                                            color="secondary"
                                            label="Account Number"
                                            autocomplete="some-random-text"
                                            type="text"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <div style="background:white; height: 10px; width: 100%"></div>
                                </v-row>
                                <!-- Buttons for Adding/Removing Banking Details -->
                                <v-row>
                                    <v-col sm="10"></v-col>
                                    <v-col sm="2">
                                        <v-tooltip bottom v-if="bank_account_details.length > 1">
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    class="mx-2"
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="removeBankingDetails()"
                                                >
                                                    <v-icon>remove</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Remove Address</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="addBankingDetails()"
                                                >
                                                    <v-icon>add</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Add Another Address</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="e1 = 4"
                                >
                                    <v-icon large>keyboard_arrow_left</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    large
                                    color="success"
                                    @click="submitBP()"
                                >
                                    <v-icon large>save</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Submission Results Section -->
                    <v-stepper-content step="6">
                         <v-card light >
                            <v-card-text class="text-center">
                                <v-progress-linear
                                    color="green"
                                    class="mb-0"
                                    height="10"
                                    indeterminate
                                    :active="submitting"
                                ></v-progress-linear>
                            </v-card-text>
                            <v-list flat>
                                <v-subheader>Saving Business Partner Info</v-subheader>
                                <v-list-item-group color="primary">
                                    <v-list-item
                                        v-for="(item, i) in backendActionItems"
                                        :key="i"
                                    >
                                        <v-list-item-icon>
                                            <v-icon>done</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ item }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    large
                                    color="success"
                                    @click="closeOnboardingDialog()"
                                >
                                    <v-icon large>done</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>
        <DocPreviewDialog
            :dialog="open_doc_preview_dialog"
            :url="file_url"
            :doc_type="doc_type"
            @CLOSE_DOC_PREVIEW_DIALOG="closeDocPreviewDialog()"
        />
    </v-row>
</template>
<script>
import DocPreviewDialog from '../../../components/doc_preview';
import ObjectID from "bson-objectid";
import { idValid, extractBirthDate, extractGender } from "../../../utils/id_number_check";

import { submitDocumentumFile, emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";

import {
    document_types,
    address_types,
    contact_types,
    banking_types,
    bank_account_types,
    marital_statuses,
    entity_gender,
    BUSINESS_PARTNER_TYPES_LIST,
    BP_ADMINISTRATION_OPTIONS
} from "../../../../config";

import { getBanks, getUserRoles, getVASList, getBusinessPartners } from "../gql/queries";
import { entityProcessBusinessPartner } from "../gql/mutations";

export default {
    components: {
        DocPreviewDialog,
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        backend_check: false,
        submitting: false,
        backendActionItems: [],
        server_response_obj: null,

        file: '',

        business_partner_types: BUSINESS_PARTNER_TYPES_LIST,
        bp_administration_options: BP_ADMINISTRATION_OPTIONS,

        // File Preview Dialog
        file_url: null,
        doc_type: null,

        e1: 1,
        bp_details: {
            name: '',
            company_reg_number: '',
            type: null,
            administration_option: null,

            vas_services: [
                {
                    id: 1,
                    vas_service: null,
                    fee_billing_model: null,
                    fee_amount: 0,
                }
            ]
        },
        
        addresses: [
            {
                id: 1,
                type: null,
                line1: null,
                line2: null,
                line3: null,
                line4: null,
            }
        ],
        contact_people: [
            {
                id: 1,
                role: null,
                name: '',
                surname: '',
                marital_status: null,
                phone: '',
                email: '',
                system_access_role: null,
                gender: null,

                id_number_field: '',
                id_number_field_valid: 'NO',
                birth_date_century_field: null,
                partial_birth_date_field: null,
            }
        ],
        file_details: [
            {
                id: 1,
                type: null,
                file: null,
                valid: false,
            }
        ],
        bank_account_details: [
            {
                id: 1,
                purpose: null,
                bank: null,
                account_type: null,
                account_number: null
            }
        ],

        open_doc_preview_dialog: false,

        address_types: address_types,
        contact_types: contact_types,
        banking_types: banking_types,
        marital_statuses: marital_statuses,
        entity_gender: entity_gender,
        banks_list: [],
        bank_account_types: bank_account_types,
        document_types: document_types.filter(doc_type => { return doc_type.type === 'business_partner' }),

        refreshing_user_roles_list: false,
        user_roles: [],

        refreshing_vas_services_list: false,
        vas_services_list: [],

        documents_map: {},

        name_rules: [
            v => !!v || 'Name is required',
            v => v.trim().length >= 2 || 'Min 2 characters'
        ],
        surname_rules: [
            v => !!v || 'Surname is required',
            v => v.trim().length >= 2 || 'Min 2 characters'
        ],
        reg_number_rules: [
            v => !!v || 'Reg number is required',
            v => v.trim().length >= 5 || 'Min 5 characters'
        ],
        user_id_number_rules: [
            v => !!v || 'ID number is required',
            v => v.trim().length === 13 || '13 characters required'
        ],
        vas_fee_rules: [
            v => !!v || 'Fee amount is required',
            v => v > 0 || 'Please enter a valid fee amount'
        ],

        phone_rules: [
            v => !!v || 'Phone is required',
            v => v.trim().length >= 10 || 'Min 10 characters'
        ],
        email_rules: [
            v => !!v || 'Email is required',
            v => {
                // eslint-disable-next-line
                const emailformat = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (v.match(emailformat)) {
                    return true;
                } else {
                    return 'Invalid Email';
                }
            }
        ],
    }),
    methods: {
        closeOnboardingDialog() {
            eventBus.$emit('CLOSE_ONBOARDING_DIALOG', this.server_response_obj);
        },
        resetForm() {
            this.e1 = 1;
            this.server_response_obj = null;
            this.submitting = false;

            this.documents_map = {};

            this.bp_details = {
                name: '',
                company_reg_number: '',
                type: null,
                administration_option: null,

                vas_services: [
                    {
                        id: 1,
                        vas_service: null,
                        fee_billing_model: null,
                        fee_amount: 0,
                    }
                ]
            };
            
            this.addresses = [
                {
                    id: 1,
                    type: null,
                    line1: null,
                    line2: null,
                    line3: null,
                    line4: null,
                }
            ];
            this.contact_people = [
                {
                    id: 1,
                    role: null,
                    name: '',
                    surname: '',
                    marital_status: null,
                    phone: '',
                    email: '',
                    system_access_role: null,
                    gender: null,

                    id_number_field: '',
                    id_number_field_valid: 'NO',
                    birth_date_century_field: null,
                    partial_birth_date_field: null,
                }
            ];

            this.file_details = [
                {
                    id: 1,
                    type: null,
                    file: null,
                    valid: false,
                }
            ];
            this.bank_account_details = [
                {
                    id: 1,
                    purpose: null,
                    bank: null,
                    account_type: null,
                    account_number: null
                }
            ];

            this.$refs.name_field.resetValidation();
            this.$refs.company_reg_number_field.resetValidation();

            // For some reason, $refs in a v-for are stored as arrays, hence the following array dereferencing
            this.$refs.contact_person_name_field_1[0].resetValidation();
            this.$refs.contact_person_surname_field_1[0].resetValidation();
            this.$refs.contact_person_phone_field_1[0].resetValidation();
            this.$refs.contact_person_email_field_1[0].resetValidation();
            this.$refs.contact_person_id_number_field_1[0].resetValidation();
            
        },

        //--------------------ADD/REMOVE ADDRESSES--------------------------//
        addAddress() {
            const address_size = this.addresses.length;

            this.addresses.push({
                id: address_size + 1,
                type: null,
                line1: null,
                line2: null,
                line3: null,
                line4: null,
            });
        },
        removeAddress() {
            this.addresses.pop();
        },

        //--------------------ADD/REMOVE CONTACTS--------------------------//
        addContact() {
            const contacts_size = this.contact_people.length;

            this.contact_people.push({
                id: contacts_size + 1,
                role: null,
                name: '',
                surname: '',
                marital_status: null,
                phone: '',
                email: '',
                system_access_role: null,
                gender: null,

                id_number_field: '',
                id_number_field_valid: 'NO',
                birth_date_century_field: null,
                partial_birth_date_field: null,
            });
        },
        removeContact() {
            this.contact_people.pop();
        },

        //--------------------ADD/REMOVE VAS SERVICE--------------------------//
        addVASService() {
            const services_size = this.bp_details.vas_services.length;

            this.bp_details.vas_services.push({
                id: services_size + 1,
                vas_service: null,
                fee_billing_model: null,
                fee_amount: 0,
            });
        },
        removeVASService() {
            this.bp_details.vas_services.pop();
        },

        //--------------------ADD/REMOVE FILES--------------------------//
        addFile() {
            const files_size = this.file_details.length;

            this.file_details.push({
                id: files_size + 1,
                type: null,
                file: null
            });
        },
        removeFile() {
            this.file_details.pop();
        },

        //--------------------ADD/REMOVE BANKING--------------------------//
        addBankingDetails() {
            const size = this.bank_account_details.length;

            this.bank_account_details.push({
                id: size + 1,
                purpose: null,
                bank: null,
                account_type: null,
                account_number: null,
            });
        },
        removeBankingDetails() {
            this.bank_account_details.pop();
        },

        previewFile(fileObject) {
            // Set file oobject so we can trigger the readAsDataURL function
            const reader = new FileReader();

            reader.addEventListener("load", () => {
                // Set PDF-Viewer URL to the selected file Base64 String
                this.file_url = reader.result;
                if (fileObject.file.type === 'application/pdf') {
                    this.doc_type = 'pdf';
                } else {
                    this.doc_type = 'image';
                }

                // Display the preview dialog
                this.open_doc_preview_dialog = true;
            }, false);

            reader.readAsDataURL(fileObject.file);
        },
        closeDocPreviewDialog() {
            this.open_doc_preview_dialog = false;
        },

        moveToStep2BtnDisabled() {
            if (this.bp_details.name.trim().length < 2) {
                return true;
            }

            if (this.bp_details.company_reg_number.trim().length < 5) {
                return true;
            }

            if (this.bp_details.type === null) {
                return true;
            }

            if (this.bp_details.type === 'BUSINESS_INTRODUCER') {
                if (this.bp_details.administration_option === null) {
                    return true;
                }
            }

            if (this.bp_details.type === 'VAS_PROVIDER') {
                const invalidVASServices = this.bp_details.vas_services.filter(vas => {
                    return (
                        vas.vas_service === null ||
                        vas.fee_billing_model === null ||
                        vas.fee_amount === null ||
                        vas.fee_amount === undefined ||
                        isNaN(parseFloat(vas.fee_amount)) ||
                        parseFloat(vas.fee_amount) <= 0
                    );
                });

                if (invalidVASServices.length > 0) {
                    return true;
                }
            }

            return false;
        },
        async moveToStep2() {
            // Check if business partner with name entered already exists
            this.backend_check = true;

            try {
                const res1 = await this.$apollo
                                    .query({
                                        query: getBusinessPartners,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'BUSINESS_PARTNER',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            },
                                            searchInput: {
                                                name: this.bp_details.name.trim()
                                            }
                                        },
                                    });

                const res2 = await this.$apollo
                                    .query({
                                        query: getBusinessPartners,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'BUSINESS_PARTNER',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            },
                                            searchInput: {
                                                company_reg_number: this.bp_details.company_reg_number.trim()
                                            }
                                        },
                                    });

                this.backend_check = false;

                if (res1.errors === true) {
                    emitAlert(res1.data.getBusinessObject.message);
                    this.submitting = false;
                    return;
                }

                if (res2.errors === true) {
                    emitAlert(res2.data.getBusinessObject.message);
                    this.submitting = false;
                    return;
                }
                
                if (res1.data.getBusinessObject.business_objects.length > 0) {
                    emitAlert(`There is already a business partner with the name ${this.bp_details.name} onboarded on the system`);
                    return;
                }
                if (res2.data.getBusinessObject.business_objects.length > 0) {
                    emitAlert(`There is already a business partner with the registration number ${this.bp_details.company_reg_number} onboarded on the system`);
                    return;
                }

                // Move to next page
                this.e1 = 2;
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },

        goToStep3() {
            this.e1 = 3;
        },

        goToStep3BtnDisabled() {
            // If all fields are filled-in, then make sure all id numbers are valid
            let invalidIdExists = false;
            this.contact_people.forEach(contact_person => {
                // Check if valid
                if (contact_person.id_number_field.length === 13) {
                    const valid = idValid(contact_person.id_number_field);

                    if (valid) {
                        contact_person.id_number_field_valid = 'YES';
                    } else {
                        contact_person.id_number_field_valid = 'NO';
                        invalidIdExists = true;
                    }

                    const birthDate = extractBirthDate(contact_person.id_number_field);
                    
                    contact_person.partial_birth_date_field = birthDate.partial_date_string;
                    contact_person.birth_date_century_field = birthDate.century_string;
                    
                    contact_person.gender = extractGender(contact_person.id_number_field);
                } else {
                    contact_person.id_number_field_valid = 'NO';
                    invalidIdExists = true;
                }

                // Extract Birth date
                if (contact_person.id_number_field.length === 6) {
                    const birthDate = extractBirthDate(contact_person.id_number_field);
                    contact_person.partial_birth_date_field = birthDate.partial_date_string;
                    contact_person.birth_date_century_field = birthDate.century_string;
                }

                if (contact_person.id_number_field.length < 6) {
                    contact_person.partial_birth_date_field = null;
                    contact_person.birth_date_century_field = null;
                }

                // extract gender
                if (contact_person.id_number_field.length === 10) {
                    contact_person.gender = extractGender(contact_person.id_number_field);
                }

                if (contact_person.id_number_field.length < 10) {
                    contact_person.gender = null;
                }
            });

            if (invalidIdExists) {
                return true;
            }

            const invalidContactPeople = this.contact_people.filter(contact_person => {
                return (
                    contact_person.role === null ||

                    contact_person.name.trim() === '' ||
                    contact_person.name.trim().length < 2 ||

                    contact_person.surname.trim() === '' ||
                    contact_person.surname.trim().length < 2 ||

                    contact_person.marital_status === null ||

                    contact_person.phone.trim() === '' ||
                    contact_person.phone.trim().length < 10 ||

                    contact_person.email.trim() === '' ||
                    contact_person.system_access_role === null ||
                    contact_person.id_number_field.trim() === '' ||
                    contact_person.id_number_field.trim().length !== 13
                );
            });

            // Make sure all fields are field-in
            if (invalidContactPeople.length > 0) {
                return true;
            }

            // Check for id number duplicates
            const duplMap = {};
            for (const contact_person of this.contact_people) {
                duplMap[contact_person.id_number_field] = 0;
            }
            for (const contact_person of this.contact_people) {
                duplMap[contact_person.id_number_field] += 1;
            }
            const duplVals = Object.values(duplMap);
            for (const duplVal of duplVals) {
                if (duplVal > 1) {
                    return true;
                }
            }

            return false;
        },

        validateAddresses() {
            // We're no longer enforcing that a bp should have address details....e.g. if its a baby???
            this.e1 = 4;
        },
        
        validateDocuments() {
            this.e1 = 5;
        },
        async submitBP() {
            this.e1 = 6;

            this.submitting = true;
            const bpObj = this.extractBPInfo();


            try {
                this.backendActionItems.push("Saving business partner info");

                // First create the business_partner
                const payloadObj = {
                    OP_TYPE: 'CREATE',

                    ...bpObj
                };

                const res = await this.$apollo
                                            .mutate({
                                                mutation: entityProcessBusinessPartner,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.entityProcessBusinessPartner.errors === true) {
                    emitAlert(res.data.entityProcessBusinessPartner.message);
                    this.submitting = false;
                    this.backendActionItems = [];
                    return;
                }

                this.backendActionItems.push("Saving business partner info succeeded");


                // Now upload documents to documentum
                const business_partner_id = res.data.entityProcessBusinessPartner.business_object._id;

                if (this.file_details.filter(file => { return file.valid === true; }).length > 0) {
                    this.backendActionItems.push("Saving business-partner's documents");
                }
                
                for (const file of this.file_details) {
                    if (file.valid) {
                        const doc = this.documents_map[ file.id ];


                        const uploadRes = await submitDocumentumFile(file.id, file.file, 'business_partner', business_partner_id);
                        if (uploadRes.errors === true) {
                            emitAlert(uploadRes.message);
                            this.submitting = false;
                            this.backendActionItems.push(`  --> Uploading ${doc.name} failed`);
                            return;
                        }
                        
                        this.backendActionItems.push(`  --> Uploading ${doc.name} succeeded`);
                    }
                }

                this.server_response_obj = res.data.entityProcessBusinessPartner.business_object;
                this.closeOnboardingDialog();
                emitSuccess(res.data.entityProcessBusinessPartner.message);
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.entityProcessBusinessPartner.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
        extractBPInfo() {
            const bpObj = {
                name: null,
                company_reg_number: null,
                type: null,
                administration_option: null,

                vas_services: [],
                contact_people: [],
                addresses: [],
                documents: [],
                bank_account_details: []
            };

            bpObj.name = this.bp_details.name;
            bpObj.company_reg_number = this.bp_details.company_reg_number;
            bpObj.type = this.bp_details.type;

            bpObj.administration_option = this.bp_details.administration_option;
            // Change admin option if vas provider
            if (this.bp_details.type === 'VAS_PROVIDER') {
                bpObj.administration_option = 'VAS_PROVIDER';
            }

            // ----------------------------------Extract VAS services if there are any
            const vas_services = [];
            const validVASServices = this.bp_details.vas_services.filter(vas => {
                return (
                    vas.vas_service !== null &&
                    vas.fee_billing_model !== null &&
                    vas.fee_amount !== null &&
                    vas.fee_amount !== undefined &&
                    !isNaN(parseFloat(vas.fee_amount)) &&
                    parseFloat(vas.fee_amount) > 0
                );
            });

            if (validVASServices.length > 0) {
                validVASServices.forEach(vas => {
                    vas_services.push({
                        vas_service: vas.vas_service,
                        fee_billing_model: vas.fee_billing_model,
                        fee_amount: parseFloat(vas.fee_amount)
                    });
                });

                bpObj.vas_services = vas_services;
            }

            // ----------------------------------Extract contact people if there are any
            const contact_people = [];
            const validContactPeople = this.contact_people.filter(contact_person => {
                return (
                    contact_person.role !== null &&

                    contact_person.name.trim() !== '' &&
                    contact_person.name.trim().length >= 2 &&

                    contact_person.surname.trim() !== '' &&
                    contact_person.surname.trim().length >= 2 &&

                    contact_person.marital_status !== null &&

                    contact_person.phone.trim() !== '' &&
                    contact_person.phone.trim().length >= 10 &&

                    contact_person.email.trim() !== '' &&
                    contact_person.system_access_role !== null &&
                    contact_person.id_number_field.trim() !== '' &&
                    contact_person.id_number_field.trim().length === 13 &&

                    contact_person.id_number_field_valid.trim() === 'YES'
                );
            });

            if (validContactPeople.length > 0) {
                validContactPeople.forEach(cont => {
                    contact_people.push({
                        role: cont.role,
                        name: cont.name,
                        surname: cont.surname,
                        gender: cont.gender,
                        date_of_birth: cont.birth_date_century_field + cont.partial_birth_date_field,
                        id_number: cont.id_number_field,
                        phone: cont.phone,
                        email: cont.email,
                        marital_status: cont.marital_status,
                        system_access_role: cont.system_access_role,
                    });
                });

                bpObj.contact_people = contact_people;
            }


            // ----------------------------------Extract Addresses if there are any
            const addresses = [];
            const validAddresses = this.addresses.filter(det => {
                return det.type !== null
            });

            if (validAddresses.length > 0) {
                validAddresses.forEach(addr => {
                    addresses.push({
                        type: addr.type,
                        line1: addr.line1,
                        line2: addr.line2,
                        line3: addr.line3,
                        line4: addr.line4,
                    });
                });

                bpObj.addresses = addresses;
            }

            // ----------------------------------Extract documents if there are any
            const documents = [];
            this.file_details.forEach(file => {
                if ((file.file !== null) && (file.type !== null)) {
                    const objID = ObjectID();

                    file.id = objID.toString();
                    file.valid = true;

                    documents.push({
                        _id: objID, //Generate mngodb id
                        type: file.type,
                        name: file.file.name,
                        mime_type: file.file.type,
                    });

                    this.documents_map[objID.toString()] = {
                        _id: objID, //Generate mngodb id
                        type: file.type,
                        name: file.file.name,
                        mime_type: file.file.type,
                    };
                }

                bpObj.documents = documents;
            });

            // ----------------------------------Extract banking details if there are any
            const banking = [];
            const validBanking = this.bank_account_details.filter(bank => {
                return bank.purpose !== null
            });

            if (validBanking.length > 0) {
                validBanking.forEach(bank => {
                    banking.push({
                        purpose: bank.purpose,
                        bank: bank.bank,
                        account_type: bank.account_type,
                        account_number: bank.account_number,
                    });
                });

                bpObj.bank_account_details = banking;
            }

            return bpObj;
        },

        async refreshUserRolesList() {
            this.refreshing_user_roles_list = true;
            try {
                // Load user roles list
                this.refreshing_user_roles_list = false;
                const res = await this.$apollo
                                    .query({
                                        query: getUserRoles,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'USER_ROLE',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                this.refreshing_user_roles_list = false;
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }



                this.user_roles = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.refreshing_user_roles_list = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },

        async refreshVASServicessList() {
            this.refreshing_vas_services_list = true;
            try {
                // Load VAS services list
                const res = await this.$apollo
                                    .query({
                                        query: getVASList,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'VAS_SERVICE',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                this.refreshing_vas_services_list = false;
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }
                this.vas_services_list = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.refreshing_vas_services_list = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
    },
    async mounted() {
        this.submitting = true;
        try {
            // Get banks lookup list
            let res = await this.$apollo
                                    .query({
                                        query: getBanks,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'BANK',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.submitting = false;
                return;
            }
            
            this.banks_list = res.data.getBusinessObject.business_objects;

            // Get system roles lookup list
            res = await this.$apollo
                                    .query({
                                        query: getUserRoles,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'USER_ROLE',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.submitting = false;
                return;
            }
            this.user_roles = res.data.getBusinessObject.business_objects;

            // Get VAS Services list
            res = await this.$apollo
                                    .query({
                                        query: getVASList,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'VAS_SERVICE',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.submitting = false;
                return;
            }
            this.vas_services_list = res.data.getBusinessObject.business_objects;

            // Reset fields
            this.submitting = false;
        } catch (error) {
            this.submitting = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
            
            return;
        }
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
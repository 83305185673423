var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"80%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-toolbar',{staticStyle:{"border":"1px solid #07ad2d"},attrs:{"dark":"","color":"secondary"}},[_c('v-toolbar-title',[_c('b',[_vm._v("Add Screen")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.resetForm()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Reset Form")])]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.closeAddScreen()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"color":"secondary","label":"Screen Name","required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":[
                                'Core Insurance System',
                                'Dashboard',
                                'Lookups',
                                'Settings',
                                'Finance Management',
                                'Credit',
                                'Central Underwriting',
                                'VAS',
                                'Management Accounts'
                            ],"color":"secondary","label":"Screen Category","required":""},model:{value:(_vm.screen_category),callback:function ($$v) {_vm.screen_category=$$v},expression:"screen_category"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"color":"secondary","label":"Description","type":"text","required":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1),(_vm.submitting)?_c('v-card-text',{staticClass:"text-center"},[_vm._v(" Submitting Screen Details "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"green"}})],1):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"large":"","color":"success","disabled":_vm.submitScreenBtnDisabled()},on:{"click":function($event){return _vm.submitScreen()}}},on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("save")])],1)]}}])},[_c('span',[_vm._v("Submit Screen Details")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="90%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Business Partner Change History</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeViewBPChangeHistoryDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text style="height: 500px; overflow-y:scroll; overflow-x:none">
                    <div v-if="change_history !== null" >
                        <v-timeline
                            v-for="(change, index) in change_history.changes"
                            :key="index"
                            dense
                        >
                            <v-timeline-item
                                color="red"
                                small
                            >
                                <v-row class="pt-2" :style="
                                        change.type === 'added'? 
                                        'background:#65ad61' :
                                        change.type === 'deleted' ? 'background:#f6c209' : 'background:#189eec'">
                                    <v-col cols="2">
                                        <strong>Date</strong>
                                        <div class="caption">
                                            {{ change.created.split('T')[0] + '    ' + change.created.split('T')[1].substr(0,5) }}
                                        </div>
                                    </v-col>
                                    <v-col cols="2">
                                        <strong>Change Type</strong>
                                        <div class="caption">
                                            {{ change.description }}
                                        </div>
                                    </v-col>
                                    <v-col cols="3">
                                        <strong>Captured By</strong>
                                        <div class="caption">
                                            {{ change.captured_by.entity.name + ' ' + change.captured_by.entity.surname }}
                                        </div>
                                    </v-col>
                                    <v-col>
                                        <strong>Change Details</strong>
                                        <!-- VAS Service -->
                                        <div class="caption" v-if="change.description === 'VAS Service Added' || change.description === 'VAS Service Deleted'">
                                            <div><b>Fee Amount:</b> R {{ change.new.fee_amount }}</div>
                                            <div><b>Fee Billing Model:</b> {{ change.new.fee_billing_model }}</div>
                                            <div v-if="change.description === 'VAS Service Deleted'">
                                                <hr style="border: 1px black solid"/>
                                                <b>Delete Reason:</b> {{ change.new.delete_reason }}
                                            </div>
                                        </div>
                                        <div class="caption" v-if="change.description === 'VAS Service Updated'">
                                            <div><b>Old Fee Amount:</b> R {{ change.old.fee_amount }}</div>
                                            <div><b>Old Fee Billing Model:</b> {{ change.old.fee_billing_model }}</div>
                                            <hr style="border: 1px black solid"/>
                                            <div><b>New Fee Amount:</b> R {{ change.new.fee_amount }}</div>
                                            <div><b>New Fee Billing Model:</b> {{ change.new.fee_billing_model }}</div>
                                        </div>
                                        <!-- Addresses -->
                                        <div class="caption" v-if="change.description === 'Address Added' || change.description === 'Address Deleted'">
                                            <div><b>Address Type:</b> {{ change.new.type }}</div>
                                            <div><b>Line 1:</b> {{ change.new.line1 }}</div>
                                            <div><b>Line 2:</b> {{ change.new.line2 }}</div>
                                            <div><b>Line 3:</b> {{ change.new.line3 }}</div>
                                            <div><b>Postal Code:</b> {{ change.new.line4 }}</div>
                                            <div v-if="change.description === 'Address Deleted'">
                                                <hr style="border: 1px black solid"/>
                                                <b>Delete Reason:</b> {{ change.new.delete_reason }}
                                            </div>
                                        </div>
                                        <div class="caption" v-if="change.description === 'Address Updated'">
                                            <div><b>Old Address Type:</b> {{ change.old.type }}</div>
                                            <div><b>Old Line 1:</b> {{ change.old.line1 }}</div>
                                            <div><b>Old Line 2:</b> {{ change.old.line2 }}</div>
                                            <div><b>Old Line 3:</b> {{ change.old.line3 }}</div>
                                            <div><b>Old Postal Code:</b> {{ change.old.line4 }}</div>
                                            <hr style="border: 1px black solid"/>
                                            <div><b>New Address Type:</b> {{ change.new.type }}</div>
                                            <div><b>New Line 1:</b> {{ change.new.line1 }}</div>
                                            <div><b>New Line 2:</b> {{ change.new.line2 }}</div>
                                            <div><b>New Line 3:</b> {{ change.new.line3 }}</div>
                                            <div><b>New Postal Code:</b> {{ change.new.line4 }}</div>
                                        </div>
                                        <!-- Contact People -->
                                        <div class="caption" v-if="change.description === 'Contact Person Added' || change.description === 'Contact Person Deleted'">
                                            <div><b>Contact Role:</b> {{ change.new.role }}</div>
                                            <div><b>Contact Person:</b> {{ change.new.name }}</div>
                                            <div v-if="change.description === 'Contact Person Deleted'">
                                                <hr style="border: 1px black solid"/>
                                                <b>Delete Reason:</b> {{ change.new.delete_reason }}
                                            </div>
                                        </div>
                                        <div class="caption" v-if="change.description === 'Contact Person Updated'">
                                            <div><b>Old Contact Role:</b> {{ change.old.role }}</div>
                                            <div><b>Old Contact Person:</b> {{ change.old.name }}</div>
                                            <hr style="border: 1px black solid">
                                            <div><b>New Contact Role:</b> {{ change.new.role }}</div>
                                            <div><b>New Contact Person:</b> {{ change.new.name }}</div>
                                        </div>
                                        <!-- Documents -->
                                        <div class="caption" v-if="change.description === 'Document Added' || change.description === 'Document Deleted' ">
                                            <div><b>Document Type:</b> {{ change.new.type }}</div>
                                            <div><b>Document Name:</b> {{ change.new.name }}</div>
                                            <div v-if="change.description === 'Document Deleted'">
                                                <hr style="border: 1px black solid"/>
                                                <b>Delete Reason:</b> {{ change.new.delete_reason }}
                                            </div>
                                        </div>
                                        <!-- Banking -->
                                        <div class="caption" v-if="change.description === 'Banking Details Added' || change.description === 'Banking Details Deleted'">
                                            <div><b>Banking Detail Purpose:</b> {{ change.new.purpose }}</div>
                                            <div><b>Bank:</b> {{ change.new.bank.name }}</div>
                                            <div><b>Account Type:</b> {{ change.new.account_type }}</div>
                                            <div><b>Account Number:</b> {{ change.new.account_number }}</div>
                                            <div v-if="change.description === 'Banking Details Deleted'">
                                                <hr style="border: 1px black solid"/>
                                                <b>Delete Reason:</b> {{ change.new.delete_reason }}
                                            </div>
                                        </div>
                                        <div class="caption" v-if="change.description === 'Banking Details Updated'">
                                            <div><b>Old Banking Detail Purpose:</b> {{ change.old.purpose }}</div>
                                            <div><b>Old Bank:</b> {{ change.old.bank.name }}</div>
                                            <div><b>Old Account Type:</b> {{ change.old.account_type }}</div>
                                            <div><b>Old Account Number:</b> {{ change.old.account_number }}</div>
                                            <hr style="border: 1px black solid"/>
                                            <div><b>New Banking Detail Purpose:</b> {{ change.new.purpose }}</div>
                                            <div><b>New Bank:</b> {{ change.new.bank.name }}</div>
                                            <div><b>New Account Type:</b> {{ change.new.account_type }}</div>
                                            <div><b>New Account Number:</b> {{ change.new.account_number }}</div>
                                        </div>
                                        <!-- Name Update -->
                                        <div class="caption" v-if="change.description === 'Name Updated'">
                                            <div><b>Old Value:</b> {{ change.old }}</div>
                                            <div><b>New Value:</b> {{ change.new }}</div>
                                        </div>
                                        <!-- Business Partner Type Update -->
                                        <div class="caption" v-if="change.description === 'Business Partner Type Updated'">
                                            <div><b>Old Value:</b> {{ change.old }}</div>
                                            <div><b>New Value:</b> {{ change.new }}</div>
                                        </div>
                                        <!-- Administration Option Update -->
                                        <div class="caption" v-if="change.description === 'Administration Option Updated'">
                                            <div><b>Old Value:</b> {{ change.old }}</div>
                                            <div><b>New Value:</b> {{ change.new }}</div>
                                        </div>
                                        <!-- Status Update -->
                                        <div class="caption" v-if="change.description === 'Status Updated'">
                                            <div><b>Old Value:</b> {{ change.old }}</div>
                                            <div><b>New Value:</b> {{ change.new }}</div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-timeline-item>
                        </v-timeline>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { eventBus } from "../../../main";
export default {
    components: {},
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        change_history: {
            type: Object,
            required: false,
            default: () => {
                return {}
            }
        },
    },
    data: () => ({
        server_resp_obj: null,

        // File props
        file: '',
        file_url: null,
        doc_type: null,
        
    }),
    methods: {
        closeViewBPChangeHistoryDialog() {
            eventBus.$emit('CLOSE_VIEW_BP_CHANGE_HISTORY_DIALOG', this.server_resp_obj);
        },
    },
    mounted() {
        
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
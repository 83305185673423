<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Edit Basic Role Details</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn
                    icon
                    @click="closeEditRoleDialog()"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="role_copy.name"
                                color="secondary"
                                label="Name"
                                autocomplete="some-random-text"
                                type="text"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-select
                                v-model="role_copy.level"
                                :items="role_levels"
                                color="secondary"
                                label="Role Level"
                                chips
                                multiple
                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="12">
                            <v-text-field
                                v-model="role_copy.description"
                                color="secondary"
                                label="Description"
                                autocomplete="some-random-text"
                                type="text"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Updating Role Details
                        <v-progress-linear
                            indeterminate
                            :active="submitting"
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="success"
                        :disabled="submitBtnDisabled()"
                        @click="submitRole()"
                    >
                        <v-icon large>save</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";

import { lookupProcessUserRole } from "../gql/mutations";

export default {
    components: {},
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        role_original: {
            type: Object,
            required: false,
            default: () => {
                return {
                    marketing_channels: []
                }
            },
        },
        role_copy: {
            type: Object,
            required: false,
            default: () => {
                return {
                    marketing_channels: []
                }
            },
        },
    },
    data: () => ({
        submitting: false,
        server_response_obj: null,

        role_levels: [
            'CENTRAL_UNDERWRITER',
            'BUSINESS_INTRODUCER',
            'SALES_AGENCY',
            'VAS_PROVIDER',
            'SYSTEM'
        ],
    }),

    methods: {
        closeEditRoleDialog() {
            eventBus.$emit('CLOSE_EDIT_ROLE_DIALOG', this.server_response_obj);
        },
        resetForm() {
            this.server_response_obj = null;
            eventBus.$emit('RESET_ROLE_EDIT_FORM', this.role_copy);
        },
        
        submitBtnDisabled() {
            let difference = this.role_copy.level.filter(x => !this.role_original.level.includes(x))
                                    .concat(this.role_original.level.filter(x => !this.role_copy.level.includes(x)));

            if (
                this.role_copy.name.trim() === this.role_original.name &&
                this.role_copy.description.trim() === this.role_original.description &&
                difference.length === 0
            ) {
                return true;
            } else {
                // Cater for if name is an empty string
                if (typeof(this.role_copy.name) === 'string') {
                    if (
                        this.role_copy.name.trim() === '' &&
                        this.role_original.name === null    
                    ) {
                        this.role_copy.name = null;
                        return true;
                    }
                }
                // Cater for if description is an empty string
                if (typeof(this.role_copy.description) === 'string') {
                    if (
                        this.role_copy.description.trim() === '' &&
                        this.role_original.description === null    
                    ) {
                        this.role_copy.description = null;
                        return true;
                    }
                }
            }
            return false;
        },

        capturingDone() {
            this.closeEditRoleDialog();
            this.resetForm();
        },
        async submitRole() {
            this.submitting = true;

            try {
                // Payload payload
                const submitObj = {
                    OP_TYPE: 'UPDATE',
                    _id: this.role_original._id,

                    name: this.role_copy.name,
                    description: this.role_copy.description,
                    level: this.role_copy.level
                };

                // const res = await updateUserRole(this.role_original._id, submitObj);
                const res = await this.$apollo
                                            .mutate({
                                                mutation: lookupProcessUserRole,
                                                variables: {
                                                    input: submitObj
                                                },
                                            });
                if (res.data.lookupProcessUserRole.errors === true) {
                    emitAlert(res.data.lookupProcessUserRole.message);
                    this.submitting = false;
                    return;
                }
                
                this.server_response_obj = res.data.lookupProcessUserRole.business_object;
                this.closeEditRoleDialog();
                emitSuccess(res.data.lookupProcessUserRole.message);

                // Reset fields
                this.server_response_obj = null;
                this.submitting = false;
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.lookupProcessUserRole.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
        },
    },
    mounted() {

    },
}
</script>
<style scoped>

</style>
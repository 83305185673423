<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Add Member Addresses</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row
                        v-for="address in addresses"
                        :key="address.id"
                        style="background:#eaeaea; padding-bottom:10px"
                    >
                        <v-col cols="12" sm="3">
                            <v-select
                                v-model="address.type"
                                :items="address_types"
                                color="secondary"
                                label="Address Type"
                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                v-model="address.line1"
                                color="secondary"
                                label="Line 1"
                                autocomplete="some-random-text2"
                                type="text"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                v-model="address.line2"
                                color="secondary"
                                label="Line 2"
                                autocomplete="some-random-text2"
                                type="text"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                v-model="address.line3"
                                color="secondary"
                                label="Line 3"
                                autocomplete="some-random-text2"
                                type="text"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                v-model="address.line4"
                                color="secondary"
                                label="Postal Code"
                                autocomplete="some-random-text2"
                                type="text"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <!-- Buttons for Adding/Removing Addresses -->
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting Member Address
                        <v-progress-linear
                            indeterminate
                            :active="submitting"
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="validAddressCaptured()"
                                @click="submitData()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Save Member Address</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";
import { address_types } from "../../../../config";

import { entityProcessEntity } from "../gql/mutations";

export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        entity_id: {
            type: String,
            default: null
        },
    },
    data: () => ({
        server_response_obj: null,
        submitting: false,
        addresses: [
            {
                id: 1,
                type: null,
                line1: null,
                line2: null,
                line3: null,
                line4: null,
            }
        ],

        address_types: address_types
    }),
    methods: {
        closeDialog() {
            eventBus.$emit('CLOSE_ADD_MEMBER_ADDRESS_DIALOG', this.server_response_obj);
        },
        resetForm() {
            this.submitting = false;
            this.addresses = [
                {
                    id: 1,
                    type: null,
                    line1: null,
                    line2: null,
                    line3: null,
                    line4: null,
                }
            ];
        },
        
        //--------------------ADD/REMOVE ADDRESSES--------------------------//
        addAddress() {
            const address_size = this.addresses.length;

            this.addresses.push({
                id: address_size + 1,
                type: null,
                line1: null,
                line2: null,
                line3: null,
                line4: null,
            });
        },
        removeAddress() {
            this.addresses.pop();
        },

        validAddressCaptured() {
             const validAddresses = this.addresses
                                    .filter(addr => { return addr.type !== null });
            if (validAddresses.length === 0) {
                return true;
            }
            return false;
        },

        async submitData() {
            const validAddresses = this.addresses
                                    .filter(addr => { return addr.type !== null });

            // deep copy the validAddresses array
            const arrCopy = JSON.parse(JSON.stringify(validAddresses))
                                    .map(addr => {
                                        delete addr.id;
                                        return addr;
                                    });

            if (arrCopy.length === 0) {
                emitAlert('Please capture atleast one valid address');
                return;
            }

            this.submitting = true;

            try {
                const payload = {
                        OP_TYPE: 'ADD_ADDRESS',
                        _id: this.entity_id,
                        addresses: arrCopy
                    };
                const res = await this.$apollo
                                        .mutate({
                                            mutation: entityProcessEntity,
                                            variables: {
                                                input: payload
                                            },
                                        });
                if (res.data.entityProcessEntity.errors === true) {
                    emitAlert(res.data.entityProcessEntity.message);
                    this.submitting = false;
                    return;
                }

                // Sanitize version history
                res.data.entityProcessEntity.business_object.version_history.changes.forEach(change => {
                    change.old = JSON.parse(change.old);
                    change.new = JSON.parse(change.new);
                });
                
                this.server_response_obj = res.data.entityProcessEntity.business_object;
                this.closeDialog();
                emitSuccess(res.data.entityProcessEntity.message);

                // Reset fields
                this.server_response_obj = null;
                this.submitting = false;
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.entityProcessEntity.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request')
                }
                
                return;
            }
        },
    },
    mounted() {
        
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
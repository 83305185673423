import gql from 'graphql-tag'

export const createBranch = gql`
    mutation entityProcessBranch($input: BranchInput!) {
        entityProcessBranch(input: $input) {
            errors
            message
            business_object {
                ... on Branch {
                    __typename
                    _id
                    branch_number

                    name
                    start_date
                    end_date
                    created
                    captured_by {
                        __typename
                        _id
                        entity {
                            __typename
                            _id
                            name
                            surname
                        }
                    }

                    addresses {
                        __typename
                        _id
                        type
                        line1
                        line2
                        line3
                        line4
                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }

                    management {
                        _id

                        agent {
                            agent_number
                            entity {
                                _id
                                name
                                surname
                                id_number
                            }
                        }
                        role

                        start_date
                        end_date

                        # deleted
                        # deleted_by {
                        #     __typename
                        #     _id
                        #     entity {
                        #         __typename
                        #         _id
                        #         name
                        #         surname
                        #     }
                        # }
                        # delete_reason
                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                    }
                }
            }
        }
    }
`
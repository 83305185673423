<template>
    <div>
        <v-toolbar
            color="secondary"
            dark
            class="pa-0"
            height="50"
            extension-height="3"
        >
            <v-row>
                <!-- Grid Buttons -->
                <v-col sm="4" style="margin-top:10px">
                    <v-tooltip bottom v-if="SEARCH_MODE === false">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                @click="loadMoreData()"
                                v-on="on"
                            >
                                <v-icon>cloud_download</v-icon>
                            </v-btn>
                        </template>
                        <span>Load More Data</span>
                    </v-tooltip>
                    
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="openSearchMembersDialog()"
                            >
                                <v-icon>search</v-icon>
                            </v-btn>
                        </template>
                        <span>Search for member(s)</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="SEARCH_MODE === false">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="refreshMembers()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="selectedMember !== null">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="openEditMemberDetailsDialog()"
                            >
                                <v-icon>edit</v-icon>
                            </v-btn>
                        </template>
                        <span>Edit Record</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="selectedMember !== null">
                        <template v-slot:activator="{ on }">
                            <v-badge
                                :color="selectedMember.version_history.changes.length > 0 ? 'primary' : 'secondary'"
                                :content="selectedMember.version_history.changes.length"
                            >
                                <v-btn
                                    color="amber black--text"
                                    small
                                    v-on="on"
                                    :disabled="selectedMember.version_history.changes.length === 0"
                                    @click="openViewEntityChangeHistory()"
                                >
                                    <v-icon>mdi-history</v-icon>
                                </v-btn>
                            </v-badge>
                        </template>
                        <span>View Change History</span>
                    </v-tooltip>
                </v-col>

                <!-- Search Result Indicator -->
                <v-col sm="4">
                    <v-card-actions v-if="SEARCH_MODE === true">
                        <h3>Search Results</h3>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="clearSearchResults()"
                                    v-on="on"
                                >
                                    <v-icon small>search</v-icon>
                                    <v-icon small>close</v-icon>
                                </v-btn>
                            </template>
                            <span>Clear Search Results</span>
                        </v-tooltip>
                    </v-card-actions>
                </v-col>

                <!-- Action Buttons -->
                <v-col sm="4" style="margin-top:10px">
                    <!-- <v-spacer></v-spacer> -->
                    <div class="text-right">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="openOnboardingDialog()"
                                    v-on="on"
                                >
                                    <v-icon medium>person_add</v-icon>
                                </v-btn>
                            </template>
                            <span>Onboard a Person</span>
                        </v-tooltip>

                        <!-- <v-tooltip bottom v-if="selectedMember !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="purple white--text"
                                    class="mx-2"
                                    @click="openProductProfileDialog()"
                                    v-on="on"
                                >
                                    <v-icon medium>mdi-chart-bar</v-icon>
                                </v-btn>
                            </template>
                            <span>Get Product Profile</span>
                        </v-tooltip> -->
                    </div>
                </v-col>
            </v-row>
        </v-toolbar>
        <multipane class="horizontal-panes" layout="horizontal" style="height: 100%" @paneResize="paneResizing">
            <div class="pane" :style="{ height: '50%', maxHeight: '50%'}">
                <v-data-table
                    fixed-header
                    :height="main_tab_height"
                    :headers="member_details_headers"
                    :items="members"
                    item-key="_id"
                    class="elevation-1"
                    :single-select="true"
                    @click:row="selectMainTableRow"
                    item-class="secondary"
                    hide-default-footer
                    :loading="data_table_loading"
                    :disable-pagination="true"
                >
                    <template v-slot:[`item.created`]="{ item }">
                        <td>{{item.created !== null ? item.created.split('T')[0] : ''}}</td>
                    </template>
                    <!-- Display whether markenting consent has been given -->
                    <template v-slot:[`item.marketing_channels`]="{ item }" >
                        <td v-if="item.marketing_channels.length > 0">
                            Yes
                        </td>
                        <td v-else>
                            No
                        </td>
                    </template>
                    <template v-slot:[`item.captured_by`]="{ item }" >
                        <td v-if="item.captured_by !== null && item.captured_by !== undefined">
                            {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                        </td>
                    </template>
                </v-data-table>
            </div>
            <multipane-resizer></multipane-resizer>
            <div class="pane tab-nav" :style="{ height: '30%', maxHeight: '50%', minHeight: '30%' }">
                <v-tabs
                    v-model="sub_tabs"
                    color="transparent"
                    class="tab-nav"
                    hide-slider
                    height="35px"
                    grow
                >
                    <!-- Address Sub Tab -->
                    <template>
                        <v-tab key="address_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Address
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Contact Details Sub Tab -->
                    <template>
                        <v-tab key="contacts_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Contact Details
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Documents Sub Tab -->
                    <template>
                        <v-tab key="documents_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Documents
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Banking Details Sub Tab -->
                    <template>
                        <v-tab key="bank_account_details_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Bank Account Details
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Loans Sub Tab -->
                    <template>
                        <v-tab key="loans_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Loans
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>
                    <!-- Policies Sub Tab -->
                    <template>
                        <v-tab key="policies_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Policies
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>
                </v-tabs>
                <v-tabs-items v-model="sub_tabs">
                    <!-- Sub-Tab Toolbar -->
                    <v-toolbar
                        color="secondary"
                        dark
                        class="pa-0"
                        height="50"
                        extension-height="3"
                    >
                        <!-- Buttons for the ADDRESSES sub-tab -->
                        <v-tooltip bottom v-if="sub_tabs === 0 && selectedMember !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddAddressDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Member Address</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 0  && selectedAddress !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openDeleteAddressDialog()"
                                    v-on="on"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete Member Address</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 0  && selectedAddress !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openEditAddressDialog()"
                                    v-on="on"
                                >
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <span>Edit Member Address</span>
                        </v-tooltip>

                        <!-- Buttons for the CONTACTS sub-tab -->
                        <v-tooltip bottom v-if="sub_tabs === 1 && selectedMember !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddContactsDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Member Contacts</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 1  && selectedContact !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openDeleteContactsDialog()"
                                    v-on="on"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete Member Contacts</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 1  && selectedContact !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openEditContactDialog()"
                                    v-on="on"
                                >
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <span>Edit Member Contacts</span>
                        </v-tooltip>

                        <!-- Show following buttons only for the DOCUMENTS sub-tab -->
                        <v-tooltip bottom v-if="sub_tabs === 2 && selectedMember !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddDocumentsDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Member Document(s)</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 2  && selectedDocument !== null && selectedDocument.deleted === false">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    v-on="on"
                                    @click="openDeleteMemberDocumentDialog()"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete Member Document(s)</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 2  && selectedDocument !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    v-on="on"
                                    @click="openViewDocumentDialog()"
                                >
                                    <v-icon>content_paste</v-icon>
                                </v-btn>
                            </template>
                            <span>View Document Info</span>
                        </v-tooltip>

                        <!-- Buttons for the BANKING sub-tab -->
                        <v-tooltip bottom v-if="sub_tabs === 3 && selectedMember !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddBankingDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Member Banking Details</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 3  && selectedBanking !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openDeleteBankingDialog()"
                                    v-on="on"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete Member Banking Details</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 3  && selectedBanking !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openEditBankingDialog()"
                                    v-on="on"
                                >
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <span>Edit Member Banking Details</span>
                        </v-tooltip>
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 4 &&
                                selectedMember !== null
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="amber black--text"
                                    small
                                    @click="refreshEntityLoans()"
                                    v-on="on"
                                >
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>Refresh Entity Loans</span>
                        </v-tooltip>
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 5 &&
                                selectedMember !== null
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="amber black--text"
                                    small
                                    @click="refreshEntityPolicies()"
                                    v-on="on"
                                >
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>Refresh Entity Policies</span>
                        </v-tooltip>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <!-- Address Sub-Tab Content -->
                    <v-tab-item key="address_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="address_details_headers"
                            :items="member_addresses"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectAddressTabRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Display create date -->
                            <template v-slot:[`item.created`]="{ item }">
                                <td>{{item.created !== null ? item.created.split('T')[0] : ''}}</td>
                            </template>
                            <!-- User who captured the details -->
                            <template v-slot:[`item.captured_by`]="{ item }" >
                                <td v-if="item.captured_by !== null && item.captured_by !== undefined">
                                    {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Contact Details Sub-Tab Content -->
                    <v-tab-item key="contacts_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="contact_details_headers"
                            :items="member_contact_details"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectContactsTabRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Display full name and surname for member name -->
                            <template v-slot:[`item.primary`]="{ item }">
                                <td>{{item.primary ? 'Yes' : 'No'}}</td>
                            </template>
                            <template v-slot:[`item.created`]="{ item }">
                                <td>{{item.created !== null ? item.created.split('T')[0] : ''}}</td>
                            </template>
                            <!-- User who captured the details -->
                            <template v-slot:[`item.captured_by`]="{ item }" >
                                <td v-if="item.captured_by !== null">
                                    {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Documents Sub-Tab Content -->
                    <v-tab-item key="documents_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="document_details_headers"
                            :items="member_documents"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectDocumentsTabRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Display create date -->
                            <template v-slot:[`item.created`]="{ item }">
                                <td>{{item.created !== null ? item.created.split('T')[0] : ''}}</td>
                            </template>
                            <!-- User who captured the details -->
                            <template v-slot:[`item.captured_by`]="{ item }" >
                                <td v-if="item.captured_by !== null">
                                    {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Banking Details Sub-Tab Content -->
                    <v-tab-item key="bank_account_details_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="bank_account_details_headers"
                            :items="member_bank_account_details"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectBankingTabRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Display create date -->
                            <template v-slot:[`item.created`]="{ item }">
                                <td>{{item.created !== null ? item.created.split('T')[0] : ''}}</td>
                            </template>
                            <!-- User who captured the details -->
                            <template v-slot:[`item.captured_by`]="{ item }" >
                                <td v-if="item.captured_by !== null">
                                    {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Loans Sub-Tab Content -->
                    <v-tab-item key="loans_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="loan_details_headers"
                            :items="entity_loans"
                            item-key="_id"
                            :single-select="true"
                            @click:row="tobeDefined"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                            :loading="entity_loans_loading"
                        >
                            <!-- Status -->
                            <template v-slot:item.loan_number="{ item }">
                                <td>L{{item.loan_number}}</td>
                            </template>
                            <!-- Status -->
                            <template v-slot:item.status="{ item }">
                                <td>{{item.status}}</td>
                            </template>
                            <!-- Principal Amount -->
                            <template v-slot:item.pricing_input.principal_amount="{ item }">
                                <td>R {{item.pricing_input.principal_amount}}</td>
                            </template>
                            <!-- Premium -->
                            <template v-slot:item.premium="{ item }">
                                <td>R {{item.premium}}</td>
                            </template>
                            <!-- Effective date -->
                            <template v-slot:item.effective_date="{ item }">
                                <td>{{item.effective_date.split('T')[0]}}</td>
                            </template>
                            <!-- End date -->
                            <template v-slot:item.created="{ item }">
                                <td>{{item.installment_schedule[ item.installment_schedule.length - 1 ].deadline_date.split('T')[0]}}</td>
                            </template>
                            <!-- Loan Term -->
                            <template v-slot:item.pricing_input.loan_term="{ item }">
                                <td>{{item.pricing_input.loan_term}} MONTHS</td>
                            </template>
                            <!-- Payment method -->
                            <template v-slot:item.repayment_info.payment_method="{ item }">
                                <td>{{item.repayment_info.payment_method}}</td>
                            </template>
                            <!-- Agent who sold the loan -->
                            <template v-slot:item.agent="{ item }" >
                                <td v-if="item.agent !== null">
                                    <span v-if="item.agent.entity !== null">
                                        {{item.agent.entity.name}} {{item.agent.entity.surname}} ({{ item.agent.agent_number }})
                                    </span>
                                    <span v-else>N/A</span>
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Policies Sub-Tab Content -->
                    <v-tab-item key="policies_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="policy_details_headers"
                            :items="entity_policies"
                            item-key="ui_id"
                            :single-select="true"
                            @click:row="tobeDefined"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                            :loading="entity_policies_loading"
                        >
                            <!-- Effective date -->
                            <template v-slot:item.effective_date="{ item }">
                                <td>{{item.effective_date.split('T')[0]}}</td>
                            </template>
                            <!-- Role start date -->
                            <template v-slot:item.role_start_date="{ item }">
                                <td>{{item.role_start_date.split('T')[0]}}</td>
                            </template>
                            <!-- Role end date -->
                            <template v-slot:item.role_end_date="{ item }">
                                <td>
                                    <span v-if="item.role_end_date !== null">{{item.role_end_date.split('T')[0]}}</span>
                                    <span v-else></span>
                                </td>
                                <!-- <td>{{item.role_end_date.split('T')[0]}}</td> -->
                            </template>
                            <!-- Premium -->
                            <template v-slot:item.premium="{ item }">
                                <td>R {{item.premium}}</td>
                            </template>
                            <!-- Agent who sold the policy -->
                            <template v-slot:item.agent="{ item }" >
                                <td v-if="item.agent !== null">
                                    <span v-if="item.agent.entity !== null">
                                        {{item.agent.entity.name}} {{item.agent.entity.surname}} ({{ item.agent.agent_number }})
                                    </span>
                                    <span v-else>N/A</span>
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </multipane>
        <OnboardingDialog
            :dialog="open_onboarding_dialog"
            identifier="people_tab"
        />
        <ProductProfilingDialog
            v-if="selectedMember !== null"
            :dialog="open_product_profile_dialog"
            :entity_id="selectedMember._id"
        />
        <AddAddressesDialog
            v-if="selectedMember !== null"
            :dialog="open_add_address_dialog"
            :entity_id="selectedMember._id"
        />
        <EditAddressDialog
            v-if="selectedMember !== null && selectedAddress !== null"
            :dialog="open_edit_address_dialog"
            :entity_id="selectedMember._id"
            :address_original="selectedAddress"
            :address_copy="selectedAddressCopy"
        />
        <EditContactDialog
            v-if="selectedMember !== null && selectedContact !== null"
            :dialog="open_edit_contact_dialog"
            :entity_id="selectedMember._id"
            :contact_original="selectedContact"
            :contact_copy="selectedContactCopy"
        />
        <EditBankingDialog
            v-if="selectedMember !== null && selectedBanking !== null"
            :dialog="open_edit_banking_dialog"
            :entity_id="selectedMember._id"
            :banking_original="selectedBanking"
            :banking_copy="selectedBankingCopy"
        />
        <DeleteAddressesDialog
            v-if="selectedMember !== null"
            :dialog="open_delete_address_dialog"
            :entity="selectedMember"
            :address="selectedAddress"
        />
        <AddContactsDialog
            v-if="selectedMember !== null"
            :dialog="open_add_contact_dialog"
            :entity_id="selectedMember._id"
        />
        <DeleteContactsDialog
            v-if="selectedContact !== null"
            :dialog="open_delete_contact_dialog"
            :entity="selectedMember"
            :contact="selectedContact"
        />
        <AddMemberDocumentDialog
            v-if="selectedMember !== null"
            :dialog="open_add_document_dialog"
            :member="selectedMember"
        />
        <ViewDocumentDialog
            v-if="selectedDocument !== null"
            :dialog="open_view_member_document_dialog"
            :document="selectedDocument"
            param_path="entity"
            :param_id="selectedMember._id"
        />
        <DeleteMemberDocumentDialog
            v-if="selectedDocument !== null"
            :dialog="open_delete_member_document_dialog"
            :entity="selectedMember"
            :document="selectedDocument"
        />
        <AddBankingDialog
            v-if="selectedMember !== null"
            :dialog="open_add_banking_dialog"
            :entity_id="selectedMember._id"
        />
        <DeleteBankingDialog
            v-if="selectedBanking !== null"
            :dialog="open_delete_banking_dialog"
            :entity="selectedMember"
            :banking="selectedBanking"
        />
        <SearchMembersDialog
            :dialog="open_search_members_dialog"
        />
        <EditMemberDetailsDialog
            v-if="selectedMember !== null"
            :dialog="open_edit_member_dialog"
            :member_original="selectedMember"
            :member_copy="selectedMemberCopy"
        />
        <ViewEntityChangeHistoryDialog
            v-if="selectedMember !== null"
            :dialog="open_view_entity_change_history_dialog"
            :change_history="selectedMember.version_history"
        />
    </div>
</template>
<script>
import { Multipane, MultipaneResizer } from 'vue-multipane';
import OnboardingDialog from './dialogs/onboarding';
import ProductProfilingDialog from './dialogs/product_profiling';
import AddAddressesDialog from './dialogs/add_member_addresses';
import EditAddressDialog from './dialogs/edit_member_address';
import EditContactDialog from './dialogs/edit_member_contact';
import EditBankingDialog from './dialogs/edit_member_banking';
import DeleteAddressesDialog from './dialogs/delete_member_address';
import EditMemberDetailsDialog from './dialogs/edit_member_details';
import AddContactsDialog from './dialogs/add_member_contacts';
import DeleteContactsDialog from './dialogs/delete_member_contact';
import AddMemberDocumentDialog from './dialogs/add_member_documents';
import DeleteMemberDocumentDialog from './dialogs/delete_member_document';
import ViewDocumentDialog from '../../components/common_dialogs/view_document_info';
import AddBankingDialog from './dialogs/add_member_banking';
import DeleteBankingDialog from './dialogs/delete_member_banking';
import SearchMembersDialog from './dialogs/search_members';
import ViewEntityChangeHistoryDialog from './dialogs/view_entity_change_history';
import { emitAlert } from "../../utils/api";
import { eventBus } from "../../main";
import { differenceInMonths } from "date-fns";

import { getEntities, getEntityLoans, getEntityPolicies } from "./gql/queries";

export default {
    components: {
        Multipane,
        MultipaneResizer,
        OnboardingDialog,
        ProductProfilingDialog,
        SearchMembersDialog,
        ViewEntityChangeHistoryDialog,

        AddAddressesDialog,
        EditAddressDialog,
        EditContactDialog,
        EditBankingDialog,
        DeleteAddressesDialog,
        EditMemberDetailsDialog,
        AddContactsDialog,
        DeleteContactsDialog,
        AddMemberDocumentDialog,
        DeleteMemberDocumentDialog,
        ViewDocumentDialog,
        AddBankingDialog,
        DeleteBankingDialog,
    },

    data: () => ({
        sub_tabs: null,
        // main_tab_height: '300px',
        main_tab_height: '250px',
        // sub_tab_height:'316',
        sub_tab_height:'180',

        // main data-table row
        selectedMember: null,
        selectedRow: null,

        // addresses sub-tab data-table row
        selectedAddress: null,
        selectedAddressRow: null,
        
        // contacts sub-tab data-table row
        selectedContact: null,
        selectedContactRow: null,

        // documents sub-tab data-table row
        selectedDocument: null,
        selectedDocumentRow: null,
        
        // banking sub-tab data-table row
        selectedBanking: null,
        selectedBankingRow: null,

        // bank card sub-tab data-table row
        selectedBankCard: null,
        selectedBankCardRow: null,

        member_details_headers: [
            { text: 'Name(s)', value: 'name' },
            { text: 'Surname', value: 'surname' },
            { text: 'ID Number', value: 'id_number' },
            { text: 'Gender', value: 'gender' },
            { text: 'Birth Date', value: 'date_of_birth' },
            { text: 'Marital Status', value: 'marital_status' },
            { text: 'Life Status', value: 'life_status' },
            // { text: 'Marketing Consent', value: 'marketing_channels' },
            { text: 'Date Captured', value: 'created' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        address_details_headers: [
            { text: 'Address Type', value: 'type' },
            { text: 'Line 1', value: 'line1' },
            { text: 'Line 2', value: 'line2' },
            { text: 'Line 3', value: 'line3' },
            { text: 'Postal Code', value: 'line4' },
            { text: 'Date Captured', value: 'created' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        bank_account_details_headers: [
            // { text: 'Purpose', value: 'purpose' },
            { text: 'Bank', value: 'bank.name' },
            { text: 'Account Type', value: 'account_type' },
            { text: 'Account Number', value: 'account_number' },
            { text: 'Date Captured', value: 'created' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        contact_details_headers: [
            { text: 'Contact Type', value: 'type' },
            { text: 'Primary Contact', value: 'primary' },
            { text: 'Contact Details', value: 'details' },
            { text: 'Date Captured', value: 'created' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        document_details_headers: [
            { text: 'Document Type', value: 'type' },
            { text: 'Name', value: 'name' },
            { text: 'Date Captured', value: 'created' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        loan_details_headers: [
            // { text: 'Purpose', value: 'purpose' },
            { text: 'Loan Number', value: 'loan_number' },
            { text: 'Status', value: 'status' },
            { text: 'Principal Amount', value: 'pricing_input.principal_amount' },
            { text: 'Premium', value: 'premium' },
            { text: 'Effective Date', value: 'effective_date' },
            { text: 'End Date', value: 'created' },
            { text: 'Loan Term', value: 'pricing_input.loan_term' },
            { text: 'Payment Method', value: 'repayment_info.payment_method' },
            { text: 'Agent', value: 'agent' },
        ],
        policy_details_headers: [
            // { text: 'Purpose', value: 'purpose' },
            { text: 'Policy Number', value: 'policy_number' },
            { text: 'Status', value: 'status' },
            { text: 'Effective Date', value: 'effective_date' },
            { text: 'Policy Age', value: 'age' },
            { text: 'Product', value: 'product.name' },
            { text: 'Premium', value: 'premium' },
            { text: 'Role', value: 'role' },
            { text: 'Role Start Date', value: 'role_start_date' },
            { text: 'Role End Date', value: 'role_end_date' },
            { text: 'Agent', value: 'agent' },
        ],
        
        // Table data models
        member_addresses: [],
        member_bank_account_details: [],
        member_contact_details: [],
        member_documents: [],
        entity_loans: [],
        entity_policies: [],

        // Dialogs
        
        members: [],
        open_onboarding_dialog: false,
        open_product_profile_dialog: false,
        open_search_members_dialog: false,
        
        open_add_address_dialog: false,
        open_edit_address_dialog: false,
        open_edit_contact_dialog: false,
        open_edit_banking_dialog: false,
        open_delete_address_dialog: false,
        open_add_contact_dialog: false,
        open_delete_contact_dialog: false,
        open_add_document_dialog: false,
        open_delete_member_document_dialog: false,
        open_view_member_document_dialog: false,
        open_add_banking_dialog: false,
        open_delete_banking_dialog: false,
        open_edit_member_dialog: false,
        open_view_entity_change_history_dialog: false,
        
        data_table_loading: false,
        entity_loans_loading: false,
        entity_policies_loading: false,
        SEARCH_MODE: false,

        offsetTop: 0,

        page: 1,
        limit: 50,

        selectedAddressCopy: {
            type:  null,
            line1: null,
            line2: null,
            line3: null,
            line4: null
        },
        selectedContactCopy: {
            type:  null,
            primary: null,
            details: null
        },
        selectedBankingCopy: {
            type:  null,
            bank: null,
            account_type: null,
            account_number: null
        },
        selectedMemberCopy: {
            name:  null,
            surname: null,
            id_number: null,
            gender: null,
            marital_status: null,
            marketing_channels: []
        },
    }),

    methods: {
        //-------------------- OPEN DIALOGS ---------------------//
        openOnboardingDialog() {
            this.open_onboarding_dialog = true;
        },
        openProductProfileDialog() {
            this.open_product_profile_dialog = true;
        },
        openEditMemberDetailsDialog() {
            this.open_edit_member_dialog = true;
        },
        openSearchMembersDialog() {
            this.unselectSelectedRow();
            this.open_search_members_dialog = true;
        },
        openAddAddressDialog() {
            this.open_add_address_dialog = true;
        },
        openEditAddressDialog() {
            this.selectedAddressCopy = Object.assign({}, this.selectedAddress);
            this.open_edit_address_dialog = true;
        },
        openEditContactDialog() {
            this.selectedContactCopy = Object.assign({}, this.selectedContact);
            this.open_edit_contact_dialog = true;
        },
        openEditBankingDialog() {
            this.selectedBankingCopy = Object.assign({}, this.selectedBanking);
            this.open_edit_banking_dialog = true;
        },
        openDeleteAddressDialog() {
            this.open_delete_address_dialog = true;
        },
        openAddContactsDialog() {
            this.open_add_contact_dialog = true;
        },
        openDeleteContactsDialog() {
            this.open_delete_contact_dialog = true;
        },
        openAddDocumentsDialog() {
            this.open_add_document_dialog = true;
        },
        openDeleteMemberDocumentDialog() {
            this.open_delete_member_document_dialog = true;
        },
        openViewDocumentDialog() {
            this.open_view_member_document_dialog = true;
        },
        openAddBankingDialog() {
            this.open_add_banking_dialog = true;
        },
        openDeleteBankingDialog() {
            this.open_delete_banking_dialog = true;
        },
        openViewEntityChangeHistory() {
            this.open_view_entity_change_history_dialog = true;
        },

        // Edit form reseters
        resetAddressEditForm(updatedAddress) {
            if (
                updatedAddress.type !== this.selectedAddress.type ||
                updatedAddress.line1 !== this.selectedAddress.line1 ||
                updatedAddress.line2 !== this.selectedAddress.line2 ||
                updatedAddress.line3 !== this.selectedAddress.line3 ||
                updatedAddress.line4 !== this.selectedAddress.line4
            ) {
                this.selectedAddressCopy = Object.assign({}, this.selectedAddress);
            }
        },
        resetContactEditForm(updatedContact) {
            if (
                updatedContact.type !== this.selectedContact.type ||
                updatedContact.primary !== this.selectedContact.primary ||
                updatedContact.details !== this.selectedContact.details
            ) {
                this.selectedContactCopy = Object.assign({}, this.selectedContact);
            }
        },
        resetBankingEditForm(updatedBanking) {
            if (
                // updatedBanking.purpose !== this.selectedBanking.purpose ||
                updatedBanking.bank !== this.selectedBanking.bank ||
                updatedBanking.account_type !== this.selectedBanking.account_type ||
                updatedBanking.account_number !== this.selectedBanking.account_number
            ) {
                this.selectedBankingCopy = Object.assign({}, this.selectedBanking);
            }
        },
        resetMemberEditForm(updatedMember) {
            let difference = updatedMember.marketing_channels.filter(x => !this.selectedMember.marketing_channels.includes(x))
                                    .concat(this.selectedMember.marketing_channels.filter(x => !updatedMember.marketing_channels.includes(x)));
            if (
                updatedMember.name !== this.selectedMember.name ||
                updatedMember.surname !== this.selectedMember.surname ||
                updatedMember.is_number !== this.selectedMember.is_number ||
                updatedMember.gender !== this.selectedMember.gender ||
                updatedMember.marital_status !== this.selectedMember.marital_status ||
                difference.length > 0
            ) {
                this.selectedMemberCopy = Object.assign({}, this.selectedMember);
            }
        },

        //------------------- CLOSE DIALOGS---------------------//
        updateUITableModel(new_member) {
            if (new_member !== null) {
                const obj_index = this.members.map(obj => { return obj._id; }).indexOf(new_member._id)
                this.members[obj_index] = new_member;

                 // Deep copy members for refreshing main data table
                const temp_arr = Array.from(this.members);
                this.members = temp_arr;

                // Also update the addresses sub-tab
                this.member_addresses = new_member.addresses.filter(addr => { return !addr.deleted });
                this.member_contact_details = new_member.contact_details.filter(contact => { return !contact.deleted });
                this.member_documents = new_member.documents.filter(doc => { return !doc.deleted });
                this.member_bank_account_details = new_member.bank_account_details.filter(banking => { return !banking.deleted });
                // this.selectedMember.version_history = new_member.version_history;
                this.selectedMember = new_member;
            }
        },
        closeOnboardingDialog(new_member) {
            if (new_member !== null) {
                this.members.push(new_member);
            }
            this.open_onboarding_dialog = false;
        },
        closeProductProfilingDialog() {
            this.open_product_profile_dialog = false;
        },
        closeAddAddressesDialog(new_member) {
            this.updateUITableModel(new_member);
            this.open_add_address_dialog = false;
        },
        closeEditAddressDialog(new_address) {
            this.updateUITableModel(new_address);
            this.open_edit_address_dialog = false;
        },
        closeEditContactDialog(new_contact) {
            this.updateUITableModel(new_contact);
            this.open_edit_contact_dialog = false;
        },
        closeEditBankingDialog(new_banking) {
            this.updateUITableModel(new_banking);
            this.open_edit_banking_dialog = false;
        },
        closeDeleteAddressesDialog(new_member) {
            this.selectedAddress = null;
            this.updateUITableModel(new_member);
            this.open_delete_address_dialog = false;
        },
        closeEditMemberDialog(new_member) {
            if (new_member !== null) {
                const obj_index = this.members.map(obj => { return obj._id; }).indexOf(new_member._id)
                this.members[obj_index] = new_member;

                 // Deep copy members for refreshing main data table
                const temp_arr = Array.from(this.members);
                this.members = temp_arr;

                // Clear sub-tabs as the main row get automatically unselected
                this.member_addresses = [];
                this.member_contact_details = [];
                this.member_documents = [];
                this.member_bank_account_details = [];

                this.selectedMember = new_member;
            }

            this.open_edit_member_dialog = false;
        },
        closeAddContactsDialog(new_member) {
            this.updateUITableModel(new_member);
            this.open_add_contact_dialog = false;
        },
        closeDeleteContactDialog(new_member) {
            this.updateUITableModel(new_member);
            this.open_delete_contact_dialog = false;
        },
        closeAddMemberDocumentsDialog(new_member) {
            this.updateUITableModel(new_member);
            this.open_add_document_dialog = false;
        },
        closeDeleteMemberDocumentDialog(new_member) {
            if (new_member !== null) {
                // Then a delete action actually happened
                this.selectedDocument = null;
                this.selectedDocumentRow = null;
            }
            this.updateUITableModel(new_member);
            this.open_delete_member_document_dialog = false;
        },
        closeViewServerDocumentsDialog() {
            this.open_view_member_document_dialog = false;
        },
        closeAddBankingDialog(new_member) {
            this.updateUITableModel(new_member);
            this.open_add_banking_dialog = false;
        },
        closeDeleteBankingDialog(new_member) {
            this.updateUITableModel(new_member);
            this.open_delete_banking_dialog = false;
        },
        closeSearchMembersDialog(searchResults) {
            if (searchResults !== null) {
                this.members = searchResults;
                this.SEARCH_MODE = true;

                // Update member addresses model
                this.member_addresses = [];
                // Update member contact details model
                this.member_contact_details = [];
                // Update member documents model
                this.member_documents = [];
                // Update member bank account details
                this.member_bank_account_details = [];
            }
            this.open_search_members_dialog = false;
        },
        closeViewMemberChangeHistoryDialog(new_member) {
            this.updateUITableModel(new_member);
            this.open_view_entity_change_history_dialog = false;
        },
        unselectSubTabRows() {
            // Unselect addresses sub-tab row
            this.selectedAddress = null;
            if (this.selectedAddressRow !== null) {
                this.selectedAddressRow.select(false);
            }

            // Unselect contacts sub-tab row
            this.selectedContact = null;
            if (this.selectedContactRow !== null) {
                this.selectedContactRow.select(false);
            }

            // Unselect documents sub-tab row
            this.selectedDocument = null;
            if (this.selectedDocumentRow !== null) {
                this.selectedDocumentRow.select(false);
            }

            // Unselect banking sub-tab row
            this.selectedBanking = null;
            if (this.selectedBankingRow !== null) {
                this.selectedBankingRow.select(false);
            }
        },
        selectMainTableRow(item, item_metadata) {
            this.unselectSubTabRows();

            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                this.selectedRow = item_metadata;

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                this.selectedMember = item;
                // Update member addresses model
                this.member_addresses = item.addresses.filter(addr => { return !addr.deleted });
                // Update member contact details model
                this.member_contact_details = item.contact_details.filter(contact => { return !contact.deleted });
                // Update member documents model
                this.member_documents = item.documents.filter(doc => { return !doc.deleted });
                // Update member bank account details
                this.member_bank_account_details = item.bank_account_details.filter(banking => { return !banking.deleted });

                this.entity_loans = [];
                this.entity_policies = [];

                // Set selected member copy
                this.selectedMemberCopy = Object.assign({}, this.selectedMember);
            } else {
                this.selectedMember = null;
                // Unighlight selected row
                item_metadata.select(false);
                this.selectedRow = null;

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                // Update member addresses model
                this.member_addresses = [];
                // Update member contact details model
                this.member_contact_details = [];
                // Update member documents model
                this.member_documents = [];
                // Update member bank account details
                this.member_bank_account_details = [];

                this.entity_loans = [];
                this.entity_policies = [];

                // ----------------------UPDATE VISIBILITY OF ACTION BUTTONS -----------------------//
            }
        },
        selectAddressTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedAddress = item;
                this.selectedAddressRow = item_metadata;
            } else {
                // this.selectedMember = null;
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedAddress = null;
                this.selectedAddressRow = null;
            }
        },
        selectContactsTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedContact = item;
                this.selectedContactRow = item_metadata;
            } else {
                // this.selectedMember = null;
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedContact = null;
                this.selectedContactRow = null;
            }
        },
        selectDocumentsTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedDocument = item;
                this.selectedDocumentRow = item_metadata;
            } else {
                // this.selectedMember = null;
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedDocument = null;
                this.selectedDocumentRow = null;
            }
        },
        selectBankingTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedBanking = item;
                this.selectedBankingRow = item_metadata;
            } else {
                // this.selectedMember = null;
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedBanking = null;
                this.selectedBankingRow = null;
            }
        },
        selectBankCardTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedBankCard = item;
                this.selectedBankCardRow = item_metadata;
            } else {
                // this.selectedMember = null;
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedBankCard = null;
                this.selectedBankCardRow = null;
            }
        },

        clearSearchResults() {
            // Unselect a row if there was any
            this.unselectSelectedRow();

            this.SEARCH_MODE = false;
            this.refreshMembers();
        },

        unselectSelectedRow() {
            if (this.selectedRow !== null) {
                this.selectedRow.select(false);
                this.selectedRow = null;

                // Update member addresses model
                this.member_addresses = [];
                // Update member contact details model
                this.member_contact_details = [];
                // Update member documents model
                this.member_documents = [];
                // Update member bank account details
                this.member_bank_account_details = [];

                this.entity_loans = [];
                this.entity_policies = [];
            }
            this.selectedMember = null;
        },

        async loadMoreData() {
            this.data_table_loading = true;
            this.page += 1;
            
            try {
                // const res = await getEntities(this.page, this.limit);
                const res = await this.$apollo
                                            .query({
                                                query: getEntities,
                                                // fetchPolicy: 'network-only',
                                                fetchPolicy: 'no-cache',
                                                errorPolicy: 'all',
                                                variables: {
                                                    objectType: 'ENTITY',
                                                    pagination: {
                                                        page: this.page,
                                                        limit: this.limit
                                                    }
                                                },
                                            });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }

                this.data_table_loading = false;

                res.data.getBusinessObject.business_objects.forEach(entity => {
                    entity.version_history.changes.forEach(change => {
                        if (change.old !== null) change.old = JSON.parse(change.old);
                        if (change.new !== null) change.new = JSON.parse(change.new);
                    });
                });

                this.members = this.members.concat(res.data.getBusinessObject.business_objects);
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
    
        async refreshMembers() {
            this.data_table_loading = true;
            this.members = [];
            this.page = 1;
            
            try {
                // const res = await getEntities(this.page, this.limit);
                const res = await this.$apollo
                                            .query({
                                                query: getEntities,
                                                // fetchPolicy: 'network-only',
                                                fetchPolicy: 'no-cache',
                                                errorPolicy: 'all',
                                                variables: {
                                                    objectType: 'ENTITY',
                                                    pagination: {
                                                        page: this.page,
                                                        limit: this.limit
                                                    }
                                                },
                                            });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }

                this.data_table_loading = false;
                
                // res.data.getBusinessObject.business_objects.forEach(entity => {
                //     entity.version_history.changes.forEach(change => {
                //         console.log(typeof(change.new))
                //         if (change.old !== null) if (typeof(change.old) === 'string') change.old = JSON.parse(change.old);
                //         if (change.new !== null) if (typeof(change.new) === 'string') change.new = JSON.parse(change.new);
                //     });
                // });


                this.members = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        async refreshEntityLoans() {
            try {
                this.entity_loans_loading = true;
                const res = await this.$apollo
                                    .query({
                                        query: getEntityLoans,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'LOAN',
                                            pagination: {
                                                page: 1,
                                                limit: 50
                                            },
                                            searchInput: {
                                                entity: this.selectedMember._id
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.entity_loans_loading = false;
                    return;
                }


                this.entity_loans_loading = false;
                this.entity_loans = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.entity_loans_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        async refreshEntityPolicies() {
            this.entity_policies = [];
            this.entity_policies_loading = true;
            try {
                const res = await this.$apollo
                                    .query({
                                        query: getEntityPolicies,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'POLICY',
                                            pagination: {
                                                page: 1,
                                                limit: 50
                                            },
                                            searchInput: {
                                                member_id_number: this.selectedMember.id_number
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.entity_policies_loading = false;
                    return;
                }


                this.entity_policies_loading = false;
                // this.entity_policies = res.data.getBusinessObject.business_objects;
                const policies = res.data.getBusinessObject.business_objects;

                let policyCount = 0;
                let memberCount = 0;
                for (const policyObj of policies) {
                    policyCount += 1;
                    for (const memberObj of policyObj.members) {
                        memberCount += 1;

                        if (memberObj.entity._id === this.selectedMember._id) {
                            const underwriterPremium = policyObj.product.primary_product.underwriter_premium;
                            const brokerMarkup = policyObj.product.primary_product.broker_markup;
                            const PRIMARY_PREMIUM = underwriterPremium + brokerMarkup;

                            const finalPremium = 
                                PRIMARY_PREMIUM +
                                (PRIMARY_PREMIUM * policyObj.product.markup_percentage/100) +
                                policyObj.product.markup_fixed;

                            const policyObjClone = Object.assign({}, policyObj);
                            policyObjClone.premium = finalPremium;

                            // const now = new Date();

                            policyObjClone.ui_id = `${policyCount}_${memberCount}`;
                            // policyObjClone.ui_id = now.getTime();
                            policyObjClone.role = memberObj.role;
                            policyObjClone.role_start_date = memberObj.start_date;
                            policyObjClone.role_end_date = memberObj.end_date;

                            const effDate = new Date(policyObjClone.effective_date);
                            const policyAge = this.calculateAge(effDate);

                            policyObjClone.age = `${policyAge.months} MONTHS`;

                            this.entity_policies.push(policyObjClone);
                        }
                    }
                }
            } catch (error) {
                this.entity_policies_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        calculateAge(dateInTime) {
            const age = {
                days: 0,
                months: 0,
                years: 0
            };

            if (dateInTime === null) {
                return age;
            }

            let today = new Date();

            // Years Age
            let yearsAge = today.getFullYear() - dateInTime.getFullYear();
            let m = today.getMonth() - dateInTime.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < dateInTime.getDate())) {
                yearsAge--;
            }

            // Months Age
            let monthAge = differenceInMonths(today, dateInTime);


            if (yearsAge === 0) {
                // Then axpress it as months decimal
                yearsAge = parseFloat((monthAge/12).toFixed(4))
            }

            // Days Age
            const millisecondsPerDay = (1000 * 60 * 60 * 24);
            let daysAge = (today - dateInTime) / millisecondsPerDay;
            age.years = yearsAge;
            age.months = monthAge;
            age.days = daysAge;

            return age;
        },

        paneResizing(pane, container, size) {
            const size_int = parseInt(size);

            this.main_tab_height = size;
            const max_screen_height = window.innerHeight - 300;

            const sub_tab_percentage = 1 - (size_int/max_screen_height);
            this.sub_tab_height = sub_tab_percentage * max_screen_height;
        },

        tobeDefined(){},
    },

    async mounted() {
        eventBus.$on('CLOSE_ONBOARDING_DIALOG', this.closeOnboardingDialog);
        eventBus.$on('CLOSE_PRODUCT_PROFILING_DIALOG', this.closeProductProfilingDialog);
        eventBus.$on('CLOSE_ADD_MEMBER_ADDRESS_DIALOG', this.closeAddAddressesDialog);
        eventBus.$on('CLOSE_EDIT_MEMBER_ADDRESS_DIALOG', this.closeEditAddressDialog);
        eventBus.$on('CLOSE_EDIT_MEMBER_CONTACT_DIALOG', this.closeEditContactDialog);
        eventBus.$on('CLOSE_EDIT_MEMBER_BANKING_DIALOG', this.closeEditBankingDialog);
        eventBus.$on('CLOSE_DELETE_MEMBER_ADDRESS_DIALOG', this.closeDeleteAddressesDialog);
        eventBus.$on('CLOSE_EDIT_PERSON_DIALOG', this.closeEditMemberDialog);
        eventBus.$on('CLOSE_ADD_MEMBER_CONTACTS_DIALOG', this.closeAddContactsDialog);
        eventBus.$on('CLOSE_DELETE_MEMBER_CONTACT_DIALOG', this.closeDeleteContactDialog);
        eventBus.$on('CLOSE_ADD_MEMBER_DOCUMENTS_DIALOG', this.closeAddMemberDocumentsDialog);
        eventBus.$on('CLOSE_DELETE_MEMBER_DOCUMENT_DIALOG', this.closeDeleteMemberDocumentDialog);
        eventBus.$on('CLOSE_VIEW_DOCUMENT_DIALOG', this.closeViewServerDocumentsDialog);
        eventBus.$on('CLOSE_ADD_MEMBER_BANKING_DIALOG', this.closeAddBankingDialog);
        eventBus.$on('CLOSE_DELETE_MEMBER_BANKING_DIALOG', this.closeDeleteBankingDialog);
        eventBus.$on('CLOSE_MEMBER_SEARCH_DIALOG', this.closeSearchMembersDialog);
        eventBus.$on('CLOSE_VIEW_MEMBER_CHANGE_HISTORY_DIALOG', this.closeViewMemberChangeHistoryDialog);
        
        eventBus.$on('RESET_ADDRESS_EDIT_FORM', this.resetAddressEditForm);
        eventBus.$on('RESET_CONTACT_EDIT_FORM', this.resetContactEditForm);
        eventBus.$on('RESET_BANKING_EDIT_FORM', this.resetBankingEditForm);
        eventBus.$on('RESET_MEMBER_EDIT_FORM', this.resetMemberEditForm);


        /**
         * The following logic is for manually triggering a pane-divider movement
         * so as to get the windows to properly resize
         */
        this.paneResizing(null, null, 250);

        try {
            this.data_table_loading = true;
            // const res = await getEntities(this.page, this.limit);
            const res = await this.$apollo
                                            .query({
                                                query: getEntities,
                                                // fetchPolicy: 'network-only',
                                                fetchPolicy: 'no-cache',
                                                errorPolicy: 'all',
                                                variables: {
                                                    objectType: 'ENTITY',
                                                    pagination: {
                                                        page: this.page,
                                                        limit: this.limit
                                                    }
                                                },
                                            });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.data_table_loading = false;
                return;
            }

            this.data_table_loading = false;

            res.data.getBusinessObject.business_objects.forEach(entity => {
                entity.version_history.changes.forEach(change => {
                    // console.log(typeof(change.new))
                    if (change.old !== null) if (typeof(change.old) === 'string') change.old = JSON.parse(change.old);
                    if (change.new !== null) if (typeof(change.new) === 'string') change.new = JSON.parse(change.new);
                });
            });

            this.members = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.data_table_loading = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
        }
    },

    watch: {
        
    }
}
</script>
<style scoped>
/* Overriding of textbox min height */
 .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
}
</style>
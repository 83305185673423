var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticClass:"pa-0",attrs:{"color":"secondary","dark":"","height":"50","extension-height":"3"}},[_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"10px"},attrs:{"sm":"4"}},[_c('v-tooltip',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.loadMoreData()}}},on),[_c('v-icon',[_vm._v("cloud_download")])],1)]}}])},[_c('span',[_vm._v("Load More Data")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.refreshBankStatements()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh")])])],1),_c('v-col',{attrs:{"sm":"4"}}),_c('v-col',{staticStyle:{"margin-top":"10px"},attrs:{"sm":"4"}},[_c('div',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"amber black--text"},on:{"click":function($event){return _vm.openOnboardingDialog()}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v("Upload Bank Statement")])])],1)])],1)],1),_c('multipane',{staticClass:"horizontal-panes",staticStyle:{"height":"100%"},attrs:{"layout":"horizontal"},on:{"paneResize":_vm.paneResizing}},[_c('div',{staticClass:"pane",style:({ height: '50%', maxHeight: '50%'})},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.main_tab_height,"headers":_vm.bank_statement_details_headers,"items":_vm.bank_statement,"item-key":"_id","single-select":true,"item-class":"secondary","hide-default-footer":"","loading":_vm.data_table_loading,"disable-pagination":true},on:{"click:row":_vm.selectMainTableRow},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.created.split('T')[0]))])]}},{key:"item.captured_by",fn:function(ref){
var item = ref.item;
return [(item.captured_by !== null && item.captured_by !== undefined)?_c('td',[_vm._v(" "+_vm._s(item.captured_by.entity.name)+" "+_vm._s(item.captured_by.entity.surname)+" ")]):_vm._e()]}}],null,true)})],1),_c('multipane-resizer'),_c('div',{staticClass:"pane tab-nav",style:({ height: '30%', maxHeight: '50%', minHeight: '30%' })},[_c('v-tabs',{staticClass:"tab-nav",attrs:{"color":"transparent","hide-slider":"","height":"35px","grow":""},model:{value:(_vm.sub_tabs),callback:function ($$v) {_vm.sub_tabs=$$v},expression:"sub_tabs"}},[[_c('v-tab',{key:"documents_sub_tab",staticStyle:{"max-width":"210px","font-size":"12px"},attrs:{"active-class":"secondary white--text"}},[_c('span',{staticClass:"tab-title text-truncate"},[_vm._v(" Documents ")]),_c('div',{staticClass:"spacer"})])],[_c('v-tab',{key:"transactions_sub_tab",staticStyle:{"max-width":"210px","font-size":"12px"},attrs:{"active-class":"secondary white--text"}},[_c('span',{staticClass:"tab-title text-truncate"},[_vm._v(" Statement Transactions ")]),_c('div',{staticClass:"spacer"})])]],2),_c('v-tabs-items',{model:{value:(_vm.sub_tabs),callback:function ($$v) {_vm.sub_tabs=$$v},expression:"sub_tabs"}},[_c('v-toolbar',{staticClass:"pa-0",attrs:{"color":"secondary","dark":"","height":"50","extension-height":"3"}},[(_vm.sub_tabs === 0  && _vm.selectedDocument !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.openViewDocumentDialog()}}},on),[_c('v-icon',[_vm._v("content_paste")])],1)]}}],null,false,3437369687)},[_c('span',[_vm._v("View Document Info")])]):_vm._e(),(_vm.sub_tabs === 1 && _vm.selected_transactions.length > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"margin-left":"10px","margin-right":"10px"},attrs:{"color":"purple","small":""},on:{"click":function($event){return _vm.openClassifyTransactionsDialog()}}},on),[_vm._v(" Classify ")])]}}],null,false,1595679521)},[_c('span',[_vm._v("Classify Selected Transactions")])]):_vm._e(),(_vm.sub_tabs === 1 && _vm.selected_transactions.length === 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"margin-left":"10px","margin-right":"10px"},attrs:{"color":"amber black--text","small":""},on:{"click":function($event){return _vm.updateSelectedTransactions()}}},on),[_c('v-icon',[_vm._v("mdi-edit")]),_vm._v(" Update / Correct ")],1)]}}],null,false,1828555111)},[_c('span',[_vm._v("Update/Correct Selected Transaction")])]):_vm._e(),_c('v-spacer')],1),_c('v-tab-item',{key:"documents_sub_tab"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.sub_tab_height,"headers":_vm.document_details_headers,"items":_vm.documents,"item-key":"_id","single-select":true,"hide-default-footer":"","disable-pagination":true},on:{"click:row":_vm.selectDocumentsTabRow},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.created.split('T')[0]))])]}},{key:"item.captured_by",fn:function(ref){
var item = ref.item;
return [(item.captured_by !== null && item.captured_by !== undefined)?_c('td',[_vm._v(" "+_vm._s(item.captured_by.entity.name)+" "+_vm._s(item.captured_by.entity.surname)+" ")]):_vm._e()]}}],null,true)})],1),_c('v-tab-item',{key:"transactions_sub_tab"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.sub_tab_height,"headers":_vm.transactions_headers,"items":_vm.transactions,"item-key":"_id","item-class":"secondary","hide-default-footer":"","loading":_vm.transactions_loading,"disable-pagination":true,"show-select":"","single-select":false},on:{"click:row":_vm.tobeDefined},scopedSlots:_vm._u([{key:"item.transaction_date",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.transaction_date !== null ? item.transaction_date.split('T')[0] : ''))])]}},{key:"item.money_in",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v("R "+_vm._s(item.money_in))])]}},{key:"item.money_out",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v("R "+_vm._s(item.money_out))])]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v("R "+_vm._s(item.balance))])]}}],null,true),model:{value:(_vm.selected_transactions),callback:function ($$v) {_vm.selected_transactions=$$v},expression:"selected_transactions"}})],1)],1)],1)],1),_c('OnboardingDialog',{attrs:{"dialog":_vm.open_onboarding_dialog}}),(_vm.selectedDocument !== null)?_c('ViewDocumentDialog',{attrs:{"dialog":_vm.open_view_document_dialog,"document":_vm.selectedDocument,"param_path":"bank_statement","param_id":_vm.selectedBankStatement._id}}):_vm._e(),(_vm.selectedBankStatement !== null)?_c('ClassifyTransactionsDialog',{attrs:{"dialog":_vm.open_classify_transactions_dialog,"selected_transactions":_vm.selectedBankStatement}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="viewing_section" style="height:97%">
        <div v-if="doc_type=='pdf'" style="height:100%">
            <!-- <PDFJS v-if="doc_type=='pdf'" height="100%" :binary_data="url"></PDFJS> -->
            <VuePDFViewer v-if="doc_type=='pdf'" height="100%" :url="url"></VuePDFViewer>
        </div>

        <div v-if="doc_type=='image'" style="height:600px">
            <v-img :src="url" ></v-img>
        </div>
    </div>
</template>

<script>
// import PDFJS from "./PDFJS.vue";
import VuePDFViewer from 'vue-instant-pdf-viewer';

export default {
    components: {
        VuePDFViewer
        // PDFJS
    },
    props: ['url', 'doc_type'],

    data() {
        return {
            
        };
    },

    methods: {

    },
}
</script>
import gql from 'graphql-tag'

export const captureBankFeed = gql`
    mutation businessProcessManagementAccounts($input: ManagementAccountInput!) {
        businessProcessManagementAccounts(input: $input) {
            errors
            message
            business_objects {
                ... on BankFeed {
                    _id
                    date
                    amount
                    description
                    reference

                    classification {
                        _id
                        name
                    }
                    created
                }
            }
        }
    }
`
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="900%" scrollable>
            <v-card color="amber" class="pa-0 ma-0">
                <!-- <v-card-title v-if="doc_type==='pdf'"><b>PDF File Preview</b></v-card-title>
                <v-card-title v-else-if="doc_type==='image'"><b>Image Preview</b></v-card-title> -->
                <v-card-title>
                    <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                        <v-toolbar-title v-if="doc_type==='pdf'"><b>PDF File Preview</b></v-toolbar-title>
                        <v-toolbar-title v-else-if="doc_type==='image'"><b>Image Preview</b></v-toolbar-title>
                        <v-spacer></v-spacer>
                        
                        <v-btn icon @click="closeDocPreviewDialog()">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                </v-card-title>
                <v-divider></v-divider>

                <v-card-text style="height: 600px;">
                    <DocumentPreviewer :url="url" :doc_type="doc_type"/>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions></v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import DocumentPreviewer from './DocumentPreviewer';

export default {
    components: {
        DocumentPreviewer,
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        url: {
            required: true,
        },
        doc_type: {
            required: true
        }
    },
    data: () => ({
        
    }),
    methods: {
        closeDocPreviewDialog() {
            this.$emit('CLOSE_DOC_PREVIEW_DIALOG');
        },
        
    },
    mounted() {
        
    }
}
</script>
<style scoped>

</style>
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Outbound Call</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <!-- <v-btn icon @click="closeMemberSearchDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn> -->
            </v-toolbar>
            <v-card v-if="entityObj !== null">
                <v-card-text class="text-center" v-if="submitting">
                    <h3>Calling {{ recepient_numbers }}</h3>
                    <v-progress-linear
                        indeterminate
                        color="green"
                        class="mb-0"
                    ></v-progress-linear>
                </v-card-text>
                <!-- <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="1">
                            <h1>Calling {{ recepient_numbers }}</h1>

                            <v-progress-linear
                                indeterminate
                                color="green"
                                class="mb-0"
                            ></v-progress-linear>
                        </v-col>
                    </v-row>
                </v-card-text> -->
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { eventBus } from '../../../main';
import { emitAlert } from '../../../utils/api';
import { opsProcessOperationalExcellence } from "../gql/mutations";
export default {
    name: 'SearchLoansDialog',
    components: {},
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        entityObj: {
            type: Object,
            required: true,
            default: null
        },
        caller_number: {
            type: String,
            required: true,
            default: null
        }
    },
    data: () => ({
        submitting: false
    }),
    methods: {
        closeMemberSearchDialog() {
            eventBus.$emit('CLOSE_CALL_DIALOG', null);
        },
        async submitCall() {
            this.submitting = true;

            const activeNumbers = this.entityObj.entity.contact_details.filter(cont => {
                                                        return (
                                                            cont.type === 'Mobile' &&
                                                            cont.deleted === false
                                                        )
                                                    })
                                                    .map(cont => {
                                                        return cont.details
                                                    });

            const payloadObj = {
                OP_TYPE: 'CALL_CLIENT',
                callee_entity_id: this.entityObj._id,
                caller_number: this.caller_number,
                callee_numbers: activeNumbers,
            };

            try {
                const res = await this.$apollo
                                            .mutate({
                                                mutation: opsProcessOperationalExcellence,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                this.submitting = false;
                if (res.data.opsProcessOperationalExcellence.errors === true) {
                    emitAlert(res.data.opsProcessOperationalExcellence.message);
                    return;
                }

                eventBus.$emit('CLOSE_CALL_DIALOG');
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
    },
    mounted() {
        
    },
    computed: {
        recepient_numbers() {
            // console.log(this.entityObj)
            const activenumbers = this.entityObj.entity.contact_details.filter(cont => {
                                                        return (
                                                            cont.type === 'Mobile' &&
                                                            cont.deleted === false
                                                        )
                                                    })
                                                    .map(cont => {
                                                        return cont.details
                                                    });

            return activenumbers.join(', ');
        }
    },
    watch: {
        "dialog": async function(val) {
            if (val === true) {
                await this.submitCall();
            }
        },
    }
}
</script>
<style scoped>

</style>
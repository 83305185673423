<template>
    <div>
        <v-toolbar
            color="secondary"
            dark
            class="pa-0"
            height="50"
            extension-height="3"
        >
            <v-row>
                <!-- Grid Buttons -->
                <v-col sm="4" style="margin-top:10px">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                @click="loadMoreData()"
                                v-on="on"
                            >
                                <v-icon>cloud_download</v-icon>
                            </v-btn>
                        </template>
                        <span>Load More Data</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="refreshUsers()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh</span>
                    </v-tooltip>
                </v-col>

                <!-- Search Result Indicator -->
                <v-col sm="4">
                </v-col>

                <!-- Action Buttons -->
                <v-col sm="4" style="margin-top:10px">
                    <!-- <v-spacer></v-spacer> -->
                    <div class="text-right">
                        <!-- DEACTIVATE ACTIVE USER -->
                        <v-tooltip bottom v-if="selectedClassification !== null && selectedClassification.active === true">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="red black--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="openDeactivateUserDialog()"
                                >
                                    <v-icon medium>mdi-close</v-icon>
                                </v-btn>
                            </template>
                            <span>Deactivate User</span>
                        </v-tooltip>

                        <!-- REACTIVATE INACTIVE USER -->
                        <v-tooltip bottom v-if="selectedClassification !== null && selectedClassification.active === false">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="success white--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="openReactivateUserDialog()"
                                >
                                    <v-icon medium>check</v-icon>
                                </v-btn>
                            </template>
                            <span>Re-Activate User</span>
                        </v-tooltip>

                        <!-- RESET USER PASSWORD -->
                        <v-tooltip bottom v-if="selectedClassification !== null && selectedClassification.active === true">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="openResetUserPasswordDialog()"
                                >
                                    <v-icon medium>mdi-lock</v-icon>
                                </v-btn>
                            </template>
                            <span>Reset User Password</span>
                        </v-tooltip>
                        
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="openAddClassificationDialog()"
                                    v-on="on"
                                >
                                    <v-icon medium>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add a New User</span>
                        </v-tooltip>
                    </div>
                </v-col>
            </v-row>
        </v-toolbar>
        <multipane class="horizontal-panes" layout="horizontal" style="height: 100%" @paneResize="paneResizing">
            <div class="pane" :style="{ height: '50%', maxHeight: '50%'}">
                <v-data-table
                    fixed-header
                    :height="main_tab_height"
                    :headers="user_details_headers"
                    :items="users"
                    item-key="_id"
                    class="elevation-1"
                    :single-select="true"
                    @click:row="selectMainTableRow"
                    item-class="secondary"
                    hide-default-footer
                    :loading="data_table_loading"
                    :disable-pagination="true"
                >
                    <!-- Display create date -->
                    <template v-slot:[`item.created`]="{ item }">
                        <td>{{item.created.split('T')[0]}}</td>
                    </template>
                    <!-- Captured By -->
                    <template v-slot:[`item.captured_by`]="{ item }" >
                        <td v-if="item.captured_by !== null && item.captured_by !== undefined">
                            {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                        </td>
                    </template>
                </v-data-table>
            </div>
            <multipane-resizer></multipane-resizer>
            <div class="pane tab-nav" :style="{ height: '30%', maxHeight: '50%', minHeight: '30%' }">
                <v-tabs
                    v-model="sub_tabs"
                    color="transparent"
                    class="tab-nav"
                    hide-slider
                    height="35px"
                    grow
                >
                    <!-- Keywords Sub Tab -->
                    <template>
                        <v-tab key="statement_keywords_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Statement Keywords
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>
                </v-tabs>
                <v-tabs-items v-model="sub_tabs">
                    <!-- Sub-Tab Toolbar -->
                    <v-toolbar
                        color="secondary"
                        dark
                        class="pa-0"
                        height="50"
                        extension-height="3"
                    >
                        <!-- Show following buttons only for the statement-keywords sub-tab -->
                        <v-tooltip bottom v-if="sub_tabs === 0 && selectedClassification !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddKeywordDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add User Role</span>
                        </v-tooltip>
                        <!-- do an actual delete on users with DRAFT status  -->
                        <div v-if="selectedClassification !== null && selectedClassificationKeyword !== null">
                            <v-tooltip bottom v-if="sub_tabs === 0  && selectedClassificationKeyword !== null">
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        color="secondary"
                                        small
                                        v-on="on"
                                        @click="removeClassificationKeyword()"
                                    >
                                        <v-icon>delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>Remove Keyword</span>
                            </v-tooltip>
                        </div>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <!-- Statement Keywords Sub-Tab Content -->
                    <v-tab-item key="statement_keywords_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="user_role_details_headers"
                            :items="statement_keywords"
                            item-key="_id"
                            class="elevation-1"
                            :single-select="true"
                            @click:row="selectUserRoleTabRow"
                            item-class="secondary"
                            hide-default-footer
                        ></v-data-table>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </multipane>
        <AddClassificationDialog
            :dialog="open_add_user_dialog"
        />
        <AddKeywordDialog
            v-if="selectedClassification !== null"
            :dialog="open_add_user_role_dialog"
            :classification_id="selectedClassification._id"
        />
        <DeactivateUserDialog
            v-if="selectedClassification !== null"
            :dialog="open_deactivate_user_dialog"
            :user="selectedClassification"
        />
        <ReactivateUserDialog
            v-if="selectedClassification !== null"
            :dialog="open_reactivate_user_dialog"
            :user="selectedClassification"
        />
        <ResetUserPasswordDialog
            v-if="selectedClassification !== null"
            :dialog="open_reset_user_password_dialog"
            :user="selectedClassification"
        />
    </div>
</template>
<script>
import { Multipane, MultipaneResizer } from 'vue-multipane';
import AddClassificationDialog from './dialogs/add_classification';
import DeactivateUserDialog from './dialogs/deactivate_user';
import ReactivateUserDialog from './dialogs/reactivate_user';
import ResetUserPasswordDialog from './dialogs/reset_user_password';
import AddKeywordDialog from './dialogs/add_classification_keyword';

import { eventBus } from "../../../main";
import { emitAlert, emitSuccess } from "../../../utils/api";

import { getClassifications } from "./gql/queries";
import { businessProcessManagementAccounts } from "./gql/mutations";

export default {
    components: {
        Multipane,
        MultipaneResizer,
        AddClassificationDialog,
        DeactivateUserDialog,
        ReactivateUserDialog,
        ResetUserPasswordDialog,
        AddKeywordDialog,
    },

    data: () => ({
        sub_tabs: null,
        main_tab_height: '250px',
        sub_tab_height:'180',

        // main data-table row
        selectedClassification: null,

        // Keywords sub-tab data-table row
        selectedClassificationKeyword: null,
        selectedClassificationKeywordRow: null,

        user_details_headers: [
            { text: 'Name', value: 'name' },
            { text: 'Category', value: 'category' },
            { text: 'Created', value: 'created' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        user_role_details_headers: [
            { text: 'Keyword', value: 'match_key_word' },
        ],
        
        // Table data models
        statement_keywords: [],

        // Dialogs
        
        users: [],
        open_add_user_dialog: false,
        open_add_user_role_dialog: false,
        open_deactivate_user_dialog: false,
        open_reactivate_user_dialog: false,
        open_reset_user_password_dialog: false,

        data_table_loading: false,

        page: 1,
        limit: 50,
    }),

    methods: {
        //-------------------- OPEN DIALOGS ---------------------//
        openAddClassificationDialog() {
            this.open_add_user_dialog = true;
        },
        openAddKeywordDialog() {
            this.open_add_user_role_dialog = true;
        },
        openDeactivateUserDialog() {
            this.open_deactivate_user_dialog = true;
        },
        openReactivateUserDialog() {
            this.open_reactivate_user_dialog = true;
        },
        openResetUserPasswordDialog() {
            this.open_reset_user_password_dialog = true;
        },

        //------------------- CLOSE DIALOGS---------------------//
        closeAddClassificationDialog(new_classification) {
            if (new_classification !== null) {
                this.users.push(new_classification);
            }
            this.open_add_user_dialog = false;
        },
        closeAddKeywordDialog(new_classification) {
            if (new_classification !== null) {
                const obj_index = this.users.map(obj => { return obj._id; }).indexOf(new_classification._id)
                this.users[obj_index] = new_classification;

                this.selectedClassification = new_classification
                
                const temp_arr = Array.from(this.users);
                this.users = temp_arr;
                this.statement_keywords = new_classification.key_words_filters;
            }

            this.open_add_user_role_dialog = false;
        },
        closeDeactivateUserDialog(new_classification) {
            if (new_classification !== null) {
                const obj_index = this.users.map(obj => { return obj._id; }).indexOf(new_classification._id)
                this.users[obj_index] = new_classification;

                this.selectedClassification = new_classification
                
                const temp_arr = Array.from(this.users);
                this.users = temp_arr;
                this.statement_keywords = new_classification.key_words_filters;
            }

            this.open_deactivate_user_dialog = false;
        },
        closeReactivateUserDialog(new_classification) {
            if (new_classification !== null) {
                const obj_index = this.users.map(obj => { return obj._id; }).indexOf(new_classification._id)
                this.users[obj_index] = new_classification;

                this.selectedClassification = new_classification
                
                const temp_arr = Array.from(this.users);
                this.users = temp_arr;
                this.statement_keywords = new_classification.key_words_filters;
            }

            this.open_reactivate_user_dialog = false;
        },
        closeResetUserPasswordDialog(new_classification) {
            if (new_classification !== null) {
                const obj_index = this.users.map(obj => { return obj._id; }).indexOf(new_classification._id)
                this.users[obj_index] = new_classification;

                this.selectedClassification = new_classification
                
                const temp_arr = Array.from(this.users);
                this.users = temp_arr;
                this.statement_keywords = new_classification.key_words_filters;
            }

            this.open_reset_user_password_dialog = false;
        },

        unselectSubTabRows() {
            // Unselect statement-keywords sub-tab row
            this.selectedClassificationKeyword = null;
            if (this.selectedClassificationKeywordRow !== null) {
                this.selectedClassificationKeywordRow.select(false);
            }
        },
        selectMainTableRow(item, item_metadata) {
            this.unselectSubTabRows();

            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                this.selectedClassification = item;
                // Update statement-keywords model
                this.statement_keywords = item.key_words_filters;
            } else {
                this.selectedClassification = null;
                // Unighlight selected row
                item_metadata.select(false);

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                // Update member statement-keywords model
                this.statement_keywords = [];
            }
        },
        selectUserRoleTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);

                this.selectedClassificationKeyword = item;
                this.selectedClassificationKeywordRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedClassificationKeyword = null;
                this.selectedClassificationKeywordRow = null;
            }
        },
        async removeClassificationKeyword() {
            this.data_table_loading = true;
            
            try {
                const payload = {
                    OP_TYPE: 'REMOVE_CLASSIFICATION_KEYWORD',
                    _id: this.selectedClassification._id,
                    key_words_filters: [
                        { _id: this.selectedClassificationKeyword._id }
                    ]
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcessManagementAccounts,
                                                variables: {
                                                    input: payload
                                                },
                                            });
                this.data_table_loading = false;

                if (res.data.businessProcessManagementAccounts.errors === true) {
                    emitAlert(res.data.businessProcessManagementAccounts.message);
                    return;
                }
                
                emitSuccess(res.data.businessProcessManagementAccounts.message);

                this.selectedClassification = res.data.businessProcessManagementAccounts.business_object;
                
                this.selectedClassificationKeyword = null;
                this.selectedClassificationKeywordRow = null;
                this.statement_keywords = res.data.businessProcessManagementAccounts.business_object.key_words_filters;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessManagementAccounts.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        async loadMoreData() {
            this.data_table_loading = true;
            this.page += 1;
            
            try {
                const res = await this.$apollo
                                    .query({
                                        query: getClassifications,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'BANK_STATEMENT_CLASSIFICATION',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }
                
                this.data_table_loading = false;
                this.users = this.users.concat(res.data.getBusinessObject.business_objects);
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        async refreshUsers() {
            this.data_table_loading = true;
            this.users = [];

            this.page = 1;

            try {
                const res = await this.$apollo
                                    .query({
                                        query: getClassifications,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'BANK_STATEMENT_CLASSIFICATION',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }

                this.data_table_loading = false;
                this.users = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        paneResizing(pane, container, size) {
            const size_int = parseInt(size);

            this.main_tab_height = size;
            const max_screen_height = window.innerHeight - 300;

            const sub_tab_percentage = 1 - (size_int/max_screen_height);
            this.sub_tab_height = sub_tab_percentage * max_screen_height;
        }
    },

    async mounted() {
        eventBus.$on('CLOSE_ADD_CLASSIFICATION_DIALOG', this.closeAddClassificationDialog);
        eventBus.$on('CLOSE_ADD_CLASSIFICATION_KEYWORDS_DIALOG', this.closeAddKeywordDialog);
        eventBus.$on('CLOSE_DEACTIVATE_USER_DIALOG', this.closeDeactivateUserDialog);
        eventBus.$on('CLOSE_REACTIVATE_USER_DIALOG', this.closeReactivateUserDialog);
        eventBus.$on('CLOSE_RESET_USER_PASSWORD_DIALOG', this.closeResetUserPasswordDialog);

        /**
         * The following logic is for manually triggering a pane-divider movement
         * so as to get the windows to properly resize
         */
        this.paneResizing(null, null, 250);

        try {
            this.data_table_loading = true;
            const res = await this.$apollo
                                    .query({
                                        query: getClassifications,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'BANK_STATEMENT_CLASSIFICATION',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.data_table_loading = false;
                return;
            }

            this.data_table_loading = false;
            this.users = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.data_table_loading = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
        }
    },

    watch: {
        
    }
}
</script>
<style scoped>
/* Overriding of textbox min height */
 .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
}
</style>
import gql from 'graphql-tag'

export const getUnderwriterBatchPayments = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on UnderwriterBatchPayment {
                    __typename
                    _id
                    
                    batch_number
                    total_amount

                    processing_date
                    date_paid

                    bank_feed {
                        __typename
                        _id
                    }
                    created
                    captured_by {
                        __typename
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }
                    processed_by {
                        __typename
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }
                }
            }
        }
    }
`

export const getInstallmentScheduleList = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on PolicyInstallmentScheduleItem {
                    __typename
                    _id
                    installment_number
                    deadline_date
                    created

                    premium_underwriter
                    premium_underwriter_collected
                    premium_underwriter_target

                    premium_broker_markup
                    premium_broker_markup_collected
                    premium_broker_markup_target

                    premium_total_expected
                    premium_total_collected

                    installment_status

                    policy {
                        policy_number
                        principal_member {
                            _id
                            name
                            surname
                            id_number
                        }
                        product {
                            _id
                            name
                            markup_fixed
                            markup_percentage
                            primary_product {
                                _id
                                name
                                underwriter_premium
                                broker_commission
                                broker_markup
                                
                                payout_structure {
                                    _id
                                    payout_amount
                                    category {
                                        _id
                                        name
                                        membership_category
                                        start_age
                                        end_age
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export const searchBankFeedAutocomplete = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on BankFeed {
                    __typename
                    _id
                    bank_account_name
                    date
                    amount
                    description
                    reference
                    classification {
                        _id
                        name
                        category
                    }
                    created
                }
            }
        }
    }
`

export const getSystemFinanceAccounts = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on FinanceAccount {
                    __typename
                    _id
                    name
                    description
                    type
                }
            }
        }
    }
`

export const getClassifications = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on BankStatementClassification {
                    __typename
                    _id
                    name
                    category
                    created
                    captured_by {
                        __typename
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }
                    key_words_filters {
                        __typename
                        _id
                        match_key_word
                    }
                }
            }
        }
    }
`
<script>
// CommitChart.js
import { Doughnut } from 'vue-chartjs'

// var plugin = function(chart) {
//         var width = chart.chart.width;
//         var height = chart.chart.height;
//         var ctx = chart.chart.ctx;

//         ctx.restore();
//         // var fontSize = (height / 114).toFixed(2);
//         var fontSize = '1.5';
//         ctx.font = fontSize + "em sans-serif";
//         ctx.textBaseline = "middle";

//         var text = 'Sales Per Month';
//         var textX = Math.round((width - ctx.measureText(text).width) / 2);
//         var textY = height / 2;

//          ctx.fillText(text, textX, textY);
//          ctx.save();
// }

export default {
  extends: Doughnut,
  mounted () {
  //   this.addPlugin({
  //   id: 'my-plugin',
  //   beforeDraw: plugin
  // });

    // Overwriting base render method with actual data.
    this.renderChart({
      title: {
        display: true,
        text: 'Custom Chart Title'
      },
    //   options: {
    //     title: {
    //         display: true,
    //         text: 'Custom Chart Title'
    //     }
    // },
      labels: ['January', 'February', 'March'],
      datasets: [
        {
          label: 'GitHub Commits',
          // backgroundColor: '#f87979',
          backgroundColor: [
            '#d88200',
            '#009012',
            '#2b3136'
          ],
          borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
          ],
          borderWidth: 1,
          data: [40, 20, 12]
        }
      ]
    })
  }
}
</script>
<template>
    <v-autocomplete
        v-model="parent.scheme"
        :items="search_entries"
        :loading="isLoading"
        :search-input.sync="search"
        hide-no-data
        small-chips
        color="secondary"
        item-text="display_value"
        item-value="_id"
        :label="label"
        placeholder="Start typing to Search"
        prepend-icon="search"
        return-object
    ></v-autocomplete>
</template>

<script>
import { emitAlert } from '../../../../utils/api';
import { searchSchemesAutocomplete } from "../gql/queries";
export default {
    name: "SchemeSearch",
    props: {
        parent: {
            required: false,
            default: null
        },
        label: {
            required: false,
            default: 'Search Scheme'
        }
    },
    data: () => ({
        search: null,
        search_entries: [],
        isLoading: false
    }),
    computed: {
        items () {
            return this.search_entries.map(entry => {
                const display_value = `${entry.name}  ${entry.surname} (${entry.id_number})`;
                return Object.assign({}, entry, { display_value })
            })
        },
    },
    mounted() {
        // Set selected chip if parent scheme is already initialized
        if (this.parent.scheme !== null && this.parent.scheme !== undefined) {
            this.search_entries.push(this.parent.scheme);
        }
    },
    watch: {
        'parent.scheme': {
            handler(val) {
                // console.log(val)
                if (val !== null) {
                    this.search_entries = [];
                    this.search_entries.push(val);
                    // if (this.search_entries.length === 0) {
                    // }
                }
            },
            deep: true
        },
        async search (val) {
            // Items have already been loaded
            // if (this.items.length > 0) return

            // // Items have already been requested
            // if (this.isLoading) return

            this.isLoading = true

            if (val !== null) {
                if (val.length > 0) {
                    const searchQuery = {
                        name: val,
                        scheme_type: 'LOAN'
                    };

                    const res = await this.$apollo
                                            .query({
                                                query: searchSchemesAutocomplete,
                                                // fetchPolicy: 'network-only',
                                                fetchPolicy: 'no-cache',
                                                errorPolicy: 'all',
                                                variables: {
                                                    objectType: 'SCHEME',
                                                    pagination: {
                                                        page: this.page,
                                                        limit: this.limit
                                                    },
                                                    searchInput: searchQuery
                                                },
                                            });
                    this.isLoading = false;
                    if (res.data.getBusinessObject.errors === true) {
                        emitAlert(res.data.getBusinessObject.message);
                        return;
                    }

                    res.data.getBusinessObject.business_objects.map(entry => {
                        const display_value = `${entry.name} (${entry.scheme_number})`;
                        entry.display_value = display_value;
                        entry.entity = entry;
                        return entry;
                    });
                    this.search_entries = res.data.getBusinessObject.business_objects;
                } else {
                    this.isLoading = false;
                    this.search_entries = [];
                }
            } else {
                this.isLoading = false;
            }
            
        },
    }
};
</script>
<style>
  .v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
    transform: rotate(180deg);
  }
</style>
import gql from 'graphql-tag'

export const createFinanceTransaction = gql`
    mutation finProcessFinanceAccountTransaction($input: FinanceAccountTransactionInput) {
        finProcessFinanceAccountTransaction(input: $input) {
            errors
            message
            business_objects {
                ... on FinanceAccountTransaction {
                    __typename
                    _id
                    transaction_date

                    amount
                    from {
                        _id
                        name
                        balance
                        last_updated
                    }
                    to {
                        _id
                        name
                        balance
                        last_updated
                    }
                    reference
                    additional_reference

                    balance_from
                    balance_to

                    created
                    created_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }
                }
            }
        }
    }
`

export const businessProcessManagementAccounts = gql`
    mutation businessProcessManagementAccounts($input: ManagementAccountInput!) {
        businessProcessManagementAccounts(input: $input) {
            errors
            message
            business_objects {
                ... on BankStatement {
                    __typename
                    _id

                    bank_account_name
                    status
                    finance_month {
                        _id
                        name
                        value
                    }

                    captured_by {
                        __typename
                        _id
                        entity {
                            __typename
                            _id
                            name
                            surname
                        }
                    }
                    created
                    documents {
                        __typename
                        _id
                        type
                        mime_type
                        name

                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }
                    transactions {
                        _id
                        transaction_date

                        item_number

                        money_in
                        money_out
                        balance

                        reference
                        description
                    }
                }
            }
        }
    }
`

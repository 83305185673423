import gql from 'graphql-tag'

export const captureSoftyCompFeed = gql`
    mutation businessProcessManagementAccounts($input: ManagementAccountInput!) {
        businessProcessManagementAccounts(input: $input) {
            errors
            message
            business_objects {
                ... on SoftyCompFeed {
                    _id
                    contract_code
                    reference
                    transaction_type

                    account_name
                    account_number

                    amount
                    transaction_date
                    status_description

                    classification {
                        _id
                        name
                    }
                    created
                }
            }
        }
    }
`
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Edit Member Contact Details</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row style="background:#eaeaea; padding-bottom:10px">
                        <v-col cols="12" sm="4">
                            <v-select
                                v-model="contact_copy.type"
                                :items="contact_types"
                                color="secondary"
                                label="Contact Type"
                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-select
                                v-model="contact_copy.primary"
                                :items="[true, false]"
                                color="secondary"
                                label="Primary"
                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field
                                v-model="contact_copy.details"
                                color="secondary"
                                label="Details"
                                autocomplete="some-random-text2"
                                type="text"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting Member Contact Update
                        <v-progress-linear
                            indeterminate
                            :active="submitting"
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submit_disabled()"
                                @click="submitData()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Update Member Contact Details</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";
import { contact_types } from "../../../../config";
import { entityProcessEntity } from "../gql/mutations";

export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        entity_id: {
            type: String,
            default: null
        },
        contact_original: {
            type: Object,
            required: false,
            default: null,
        },
        contact_copy: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data: () => ({
        server_response_obj: null,
        submitting: false,

        contact_types: contact_types,
    }),
    computed: {
        
    },
    methods: {
        submit_disabled() {
            if (
                this.contact_copy.type === this.contact_original.type &&
                this.contact_copy.primary === this.contact_original.primary &&
                this.contact_copy.details.trim() === this.contact_original.details
            ) {
                return true;
            }

            // Cater for if details is an empty string
            if (typeof(this.contact_copy.details) === 'string') {
                if (
                    this.contact_copy.details.trim() === '' &&
                    this.contact_original.details === null    
                ) {
                    this.contact_copy.details = null;
                    return true;
                }
            }

            return false;
        },
        closeDialog() {
            eventBus.$emit('CLOSE_EDIT_MEMBER_CONTACT_DIALOG', this.server_response_obj);
        },
        resetForm() {
            eventBus.$emit('RESET_CONTACT_EDIT_FORM', this.contact_copy);
        },

        async submitData() {
            this.submitting = true;

            try {
                // Payload with old values
                const addPayloadObj = {
                    type: this.contact_copy.type,
                    primary: this.contact_copy.primary,
                    details: this.contact_copy.details
                };

                const payload = {
                        OP_TYPE: 'UPDATE_CONTACT',
                        _id: this.entity_id,
                        contact_details: [
                            {
                                _id: this.contact_original._id,
                                ...addPayloadObj
                            }
                        ]
                    };
                    
                const res = await this.$apollo
                                        .mutate({
                                            mutation: entityProcessEntity,
                                            variables: {
                                                input: payload
                                            },
                                        });

                if (res.data.entityProcessEntity.errors === true) {
                    emitAlert(res.data.entityProcessEntity.message);
                    this.submitting = false;
                    return;
                }

                // Sanitize version history
                res.data.entityProcessEntity.business_object.version_history.changes.forEach(change => {
                    change.old = JSON.parse(change.old);
                    change.new = JSON.parse(change.new);
                });

                this.submitting = false;
                this.server_response_obj = res.data.entityProcessEntity.business_object;

                this.closeDialog();
                emitSuccess(res.data.entityProcessEntity.message);

                // Reset fields
                this.server_response_obj = null;
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.entityProcessEntity.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
    },
    mounted() {
        
    },
    // updated()
    watch: {
        
    }
}
</script>
<style scoped>

</style>
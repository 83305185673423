<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Delete Product VAS</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip> -->
                <v-btn icon @click="closeDeleteBPVASDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <div v-if="vas !== null">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-if="vas !== null"
                                    :value="vas.vas_service.name"
                                    label="VAS Service"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-if="vas !== null"
                                    :value="vas.fee_amount"
                                    label="VAS Service"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-if="vas !== null"
                                    :value="vas.fee_billing_model"
                                    label="VAS Service"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-if="vas !== null"
                                    :value="vas.created.split('T')[0]"
                                    label="Date Captured"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                    <v-card-text class="text-center" v-if="submitting">
                        Deleting VAS Service
                        <v-progress-linear
                            indeterminate
                            :active="submitting"
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submitDeleteVASBtnDisabled()"
                                @click="submitDeleteAction()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Submit Delete Action</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../../../utils/api";
import { eventBus } from "../../../../../main";

import { productProcessInsuranceProductPrimary } from "../gql/mutations";

export default {
    components: {},
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        product: {
            type: Object,
            required: false,
            default: () => {
                return { _id: null }
            }
        },
        vas: {
            type: Object,
            required: false,
            default: () => {
                return { _id: null }
            }
        }
    },
    data: () => ({
        server_resp_obj: null,
        submitting: false,
    }),
    methods: {
        closeDeleteBPVASDialog() {
            eventBus.$emit('CLOSE_DELETE_PRODUCT_VAS_DIALOG', this.server_resp_obj);
        },
        resetForm() {
            this.server_resp_obj = null;
        },

        submitDeleteVASBtnDisabled() {
            return false;
        },
        
        async submitDeleteAction() {
            try {
                this.submitting = true;

                const payloadObj = {
                    OP_TYPE: 'REMOVE_VAS',
                    _id: this.product._id,

                    // Placeholder fields
                    name: '',
                    underwriter_premium: 0,
                    broker_commission: 0,
                    broker_markup: 0,

                    vas_services: [
                        {
                            _id: this.vas._id,

                            // placeholder fields
                            vas_service: '',
                            limit_model: '',
                            limit: 0
                        }
                    ]
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: productProcessInsuranceProductPrimary,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.productProcessInsuranceProductPrimary.errors === true) {
                    emitAlert(res.data.productProcessInsuranceProductPrimary.message);
                    this.submitting = false;
                    return;
                }

                this.submitting = false;
                emitSuccess(res.data.productProcessInsuranceProductPrimary.message);

                this.server_resp_obj = res.data.productProcessInsuranceProductPrimary.business_object;
                this.closeDeleteBPVASDialog();

                // Reset Form
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.productProcessInsuranceProductPrimary.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        }
    },
    mounted() {
        
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
import gql from 'graphql-tag'

export const getUserRoles = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on UserRole {
                    __typename
                    _id
                    name
                    description
                    level
                    allowed_screens {
                        _id
                        created
                        screen {
                            _id
                            name
                            description
                            category
                            description
                        }
                    }
                    allowed_reports{
                        _id
                        created
                        report {
                            _id
                            name
                            group
                            report_number
                            description
                        }
                    }
                    allowed_business_processes {
                        _id
                        created
                        process {
                            _id
                            name
                            group
                            description
                            process_number
                        }
                    }
                    allowed_dashboards {
                        _id
                        created
                        dashboard {
                            _id
                            dashboard_number
                            name
                            group
                            description
                        }
                    }
                }
            }
        }
    }
`

export const getUserScreens = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on UserScreen {
                    __typename
                    _id
                    name
                    description
                    category
                }
            }
        }
    }
`

export const getReports = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on Report {
                    __typename
                    _id
                    name
                    description
                    group
                    report_number
                }
            }
        }
    }
`

export const getDashboards = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on Dashboard {
                    __typename
                    _id
                    name
                    group
                    dashboard_number
                    description
                }
            }
        }
    }
`

export const getBusinessProcesses = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on BusinessProcess {
                    __typename
                    _id
                    name
                    group
                    process_number
                    description
                }
            }
        }
    }
`
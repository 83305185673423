import gql from 'graphql-tag'

export const opsProcessOperationalExcellence = gql`
    mutation opsProcessOperationalExcellence($input: OperationalExcellenceInput!) {
        opsProcessOperationalExcellence(input: $input) {
            errors
            message
            business_object {
                ... on OperationalExcellence {
                    _id
                    is_in_disciplinary_action
                    is_in_litigation
                    calendar_month {
                        _id
                        name
                        value
                        status
                    }
                    case_number
                    created
                    created_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }

                    # LOAN-QUOTE
                    loan_quote {
                        quote_number
                        status
                    }
                    # LOAN
                    loan {
                        loan_number
                        status
                        repayment_info {
                            payment_method
                            repayment_frequency
                            elected_repayment_time_point
                        }
                    }
                    # POLICY
                    policy {
                        _id
                        policy_number
                        payment_information {
                            premium_payer {
                                payment_method
                                percentage_amount
                            }
                            scheme {
                                payment_method
                                percentage_amount
                            }
                        }
                    }
                    # PROSPECT-POLICY
                    prospect_policy {
                        prospect_number
                        payment_date
                        scheme_level_payment
                        payment_information {
                            premium_payer {
                                payment_method
                                percentage_amount
                            }
                            scheme {
                                payment_method
                                percentage_amount
                            }
                        }
                    }
                    # ENTITY
                    entity {
                        _id
                        name
                        surname
                        id_number
                    }
                    # AGENT
                    agent {
                        _id
                        agent_number
                        entity {
                            _id
                            name
                            surname
                            id_number
                        }
                    }


                    required_action
                    description
                    status
                    category

                    closed_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }
                    close_date
                    # close_reason
                    next_action_date
                    
                    notes {
                        notes
                        created
                        captured_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                    }

                    contact_person {
                        name
                        surname
                        phone
                        email
                        banking_details
                    }
                }
            }
        }
    }
`

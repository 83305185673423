import gql from 'graphql-tag'

export const createUserScreen = gql`
    mutation lookupProcessUserScreen($input: UserScreenInput) {
        lookupProcessUserScreen(input: $input) {
            errors
            message
            business_object {
                ... on UserScreen {
                    __typename
                    _id
                    name
                    description
                    category
                }
            }
        }
    }
`
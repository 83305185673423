<template>
    <v-app id="keep">
        <v-snackbar
            :value="hideAlert"
            :color="alert.color"
            :timeout="alert.timeout"
            @input="resetSnackBarValue"
            multi-line
            top
        >
            <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ alert.icon }}</v-icon>
                <v-layout column>
                    <div>
                        <strong>{{ alert.title }}</strong>
                    </div>
                    <div>{{ alert.message }}</div>
                </v-layout>
            </v-layout>

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    icon
                    dark
                    text
                    v-bind="attrs"
                    @click="resetSnackBarValue"
                >
                    <v-icon>clear</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <!-------------------------- NAVIGATION DRAWER -------------------------->
        <AppNav />

        <!-- Sizes your content based upon application components -->
        <v-main>

            <!-- Provides the application the proper gutter -->
            <v-container fluid >
                <!-- System Tabs -->
                <div class="tab-nav" v-if="!hide">
                    <v-tabs
                        v-model="tab"
                        color="transparent"
                        class="tab-nav"
                        height="35px"
                        hide-slider
                        grow
                    >

                        <template v-for="(tabItem, i) in tabItems">
                            <template>
                                <v-tab
                                    :key="i"
                                    :to="{path: tabItem.path}"
                                    active-class="secondary white--text"
                                    style="max-width: 210px; font-size: 12px;"
                                >
                                    <span class="tab-title text-truncate">
                                        {{ tabItem.title }}
                                        <!-- <span
                                            v-if="tabItem.name"
                                            class="font-weight-bold"> | {{tabItem.name}}
                                        </span> -->
                                    </span>
                                    <div class="spacer"></div>
                                </v-tab>
                                <v-icon
                                    v-if="tabItem.path !== '/'"
                                    :key="tabItem.name"
                                    class="close"
                                    dark color="white"
                                    size="12"
                                    @click="closeTab(tabItem)"
                                ></v-icon>
                            </template>
                        </template>
                    </v-tabs>
                </div>
                
                <keep-alive>
                    <router-view :key="$route.fullPath"/>
                </keep-alive>
                <!-- <keep-alive include="Search">
                <router-view
                :key="$route.fullPath"
                @updatetab="updateTitle($event)"
                :userData="userData"/>
                </keep-alive> -->
            </v-container>
        </v-main>

        <!-- <v-footer app> -->
        <v-footer padless v-if="!hide" color="primary">
            <v-col class="text-center" cols="12" style="color:#f6c209">
                Copyright &copy; <strong>afrosurance</strong> {{ new Date().getFullYear() }}
            </v-col>
        </v-footer>
    </v-app>
</template>

<script>
import AppNav from "./AppNav";
import auth_mixin from "./mixins/auth";
import alert_mixin from "./mixins/alerts";
import { eventBus } from './main';
import { store } from "../store";
import "regenerator-runtime";


export default {
    name: 'App',
    components: {
        AppNav,
    },
    mixins: [auth_mixin, alert_mixin],

    data: () => ({
        progressBar: true,

        // Tab System
        tab: null,
        active_tab: null,
        tabItems: store.state.tabItems,
        tabsLoaded: [],
        current: null,
        userData: '',

    }),

    sockets: {
        connect() {
            console.log('socket connected');

            // Socket IO join context room
            if (store.state.currentUserAccount !== null && store.state.currentUserAccount !== undefined) {
                const user_context = store.state.currentUserAccount.business_partner._id;
                console.log('Joining socket room ...');
                this.$socket.client.emit('join_room', user_context);
            }
        },
        customEmit(val) {
            console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)', val)
        },
    },

    methods: {
        updateData() {
        // Get core user infor which will act as placeholder info
        },

        updateTitle(data) {
            if (data.search) {
                store.state.tabItems.forEach((tab, i) => {
                    if (tab.path === this.current) {
                        store.state.tabItems[i].title = data.title;
                    }
                });
            } else if (data.client) {
                this.addClient(data);
            }
        },
        closeTab(tabToRemove) {
            const index = store.state.tabItems.indexOf(tabToRemove);

            // Remove the X'd tab from the array
            store.state.tabItems.splice(index, 1);

            const nextIndex = Math.min(index, store.state.tabItems.length - 1);
            if (this.$route.path === tabToRemove.path) {
                this.$router.push(store.state.tabItems[nextIndex].path);
            }

            // Save to state
            store.commit('update_tabs', store.state.tabItems);
        },
        resetSnackBarValue() {
            // Reset snackbar alert so it can show again when another error is triggered
            eventBus.$emit('resetAlert');
        }
    },

    computed: {
        hide() {
            return this.$route.path === '/auth/' || this.$route.path === '/register'; 
        },
    },

    mounted() {
        eventBus.$on('progressBar', (toggle) => {
        this.progressBar = toggle;
        });

        this.updateData();
    },

    watch: {
            
    }
};
</script>

<style>
/* Tab titles to normal case */
.v-tab, .v-btn {
    text-transform: none !important;
}

.multipane-resizer {
    background: #06738b;
}

.layout-h > .multipane-resizer {
    height: 10px;
}

.tab-nav .v-tabs .close {
	display: inline-block;
	padding: 0.7em;
	margin-left: -30px;
	position: relative;
	z-index: 1;
}

/* Overriding fixed table header */
th {
    background-color: #b9b9b9 !important;
    color: black !important;
}

/* Overriding selected table row */
.v-data-table__selected {
    background-color: #f6c209 !important;
}

/** Overriding menu dropdown */
.v-list .v-list-item--active {
    color: #f6c209 !important;
}

/* Override background color of unselected tabs */
a, .v-tab {
    background: #bfbfbf !important;
    border-top: 1px solid #282e33;
    border-left: 1px solid #282e33;
}

/* #keep .v-navigation-drawer__border {
  display: none
} */
</style>
<template>
    <div>
        <v-toolbar
            color="secondary"
            dark
            class="pa-0"
            height="50"
            extension-height="3"
        >
            <v-row>
                <!-- Grid Buttons -->
                <v-col sm="4" style="margin-top:10px">
                    <v-tooltip bottom v-if="SEARCH_MODE === false">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                @click="loadMoreData()"
                                v-on="on"
                            >
                                <v-icon>cloud_download</v-icon>
                            </v-btn>
                        </template>
                        <span>Load More Data</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="refreshDataTable()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh</span>
                    </v-tooltip>
                    
                    <!-- DOWNLOAD SAFRICAN SPREADSHEET -->
                    <v-tooltip
                        bottom
                        v-if="(
                            selectedBatchPayment !== null &&
                            selectedBatchPayment.total_amount > 0
                        )"
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="primary"
                                small
                                v-on="on"
                                @click="downloadSafricanExcelReport()"
                            >
                                <v-icon>mdi-file-excel</v-icon> Safrican
                            </v-btn>
                        </template>
                        <span>Download Safrican Spreadsheet</span>
                    </v-tooltip>
                </v-col>

                <!-- Search Result Indicator -->
                <!-- <v-col sm="1"> -->
                    <!-- <v-card-actions v-if="SEARCH_MODE === true">
                        <h3>Search Results <span style="color: #f6c209;">[ {{ this.batch_payments_list.length }} records ]</span></h3>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="clearSearchResults()"
                                    v-on="on"
                                >
                                    <v-icon small>search</v-icon>
                                    <v-icon small>close</v-icon>
                                </v-btn>
                            </template>
                            <span>Clear Search Results</span>
                        </v-tooltip>
                    </v-card-actions> -->
                <!-- </v-col> -->

                <!-- Action Buttons -->
                <v-col sm="8" style="margin-top:10px">
                    <div class="text-right">
                        <!-- PROCESS BATCH PAYMENT -->
                        <v-tooltip
                            v-if="(
                                selectedBatchPayment !== null &&
                                selectedBatchPayment.date_paid === null
                            )"
                            bottom
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="purple white--text"
                                    class="mx-2"
                                    @click="processBatchPayment()"
                                    v-on="on"
                                >
                                    Process Batch Payment
                                </v-btn>
                            </template>
                            <span>Process Batch Payment</span>
                        </v-tooltip>

                        <!-- ASSOCIATE BANK FEED -->
                        <v-tooltip
                            v-if="(
                                selectedBatchPayment !== null &&
                                selectedBatchPayment.bank_feed === null
                            )"
                            bottom
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="green white--text"
                                    class="mx-2"
                                    @click="openBankFeedAssociationDialog()"
                                    v-on="on"
                                >
                                    Associate Bank Feed
                                </v-btn>
                            </template>
                            <span>Associate Bank Feed</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="openCaptureBatchPaymentDialog()"
                                    v-on="on"
                                >
                                    <v-icon medium>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Batch Payment</span>
                        </v-tooltip>
                    </div>
                </v-col>
            </v-row>
        </v-toolbar>
        <multipane class="horizontal-panes" layout="horizontal" style="height: 100%">
            <div class="pane" :style="{ height: '50%', maxHeight: '50%'}">
                <v-data-table
                    fixed-header
                    :height="main_tab_height"
                    :headers="batch_payment_details_headers"
                    :items="batch_payments_list"
                    item-key="_id"
                    class="elevation-1"
                    :single-select="true"
                    @click:row="selectMainTableRow"
                    item-class="secondary"
                    hide-default-footer
                    :disable-pagination="true"
                    :loading="data_table_loading"
                    loading-text="Loading... Please wait"
                >
                    <!-- Display Formated Classification -->
                    <!-- <template v-slot:item.classification="{ item }">
                        <td v-if="item.classification !== null">{{item.classification.name}}</td>
                    </template> -->
                    <!-- Display Formated batch number -->
                    <template v-slot:item.batch_number="{ item }">
                        <td>UB-{{item.batch_number}}</td>
                    </template>
                    <!-- Display Formated total amount -->
                    <template v-slot:item.total_amount="{ item }">
                        <td>R {{formatNumber(item.total_amount) }}</td>
                    </template>
                    <template v-slot:item.captured_by="{ item }" >
                        <td v-if="item.captured_by !== null">
                            <span v-if="item.captured_by.entity !== null">
                                {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                            </span>
                            <span v-else>N/A</span>
                        </td>
                    </template>
                    <template v-slot:item.processed_by="{ item }" >
                        <td v-if="item.processed_by !== null">
                            <span v-if="item.processed_by.entity !== null">
                                {{item.processed_by.entity.name}} {{item.processed_by.entity.surname}}
                            </span>
                            <span v-else>N/A</span>
                        </td>
                    </template>
                    <!-- Display Formated date -->
                    <template v-slot:item.date="{ item }">
                        <td>{{item.date.split('T')[0]}}</td>
                    </template>
                    <!-- Display Formated created date -->
                    <template v-slot:item.created="{ item }">
                        <td>{{item.created !== null ? item.created.split('T')[0] + '  @  ' + item.created.split('T')[1].substr(0,8) : ''}}</td>
                    </template>
                    <!-- Display Formated processing date -->
                    <template v-slot:item.processing_date="{ item }">
                        <td>{{item.processing_date.split('T')[0]}}</td>
                    </template>
                    <!-- Display Formated date paid -->
                    <template v-slot:item.date_paid="{ item }">
                        <td v-if="item.date_paid !== null">{{item.date_paid.split('T')[0]}}</td>
                    </template>
                </v-data-table>
            </div>

            <multipane-resizer></multipane-resizer>
            <div class="pane tab-nav" :style="{ height: '30%', maxHeight: '50%', minHeight: '30%' }">
                <v-tabs
                    v-model="sub_tabs"
                    color="transparent"
                    class="tab-nav"
                    hide-slider
                    height="35px"
                    grow
                >
                    <!-- Amortization Schedule Sub Tab -->
                    <template>
                        <v-tab key="batch_payment_policies_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Policies
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>
                </v-tabs>
                <v-tabs-items v-model="sub_tabs">
                    <!-- Sub-Tab Toolbar -->
                    <v-toolbar
                        color="secondary"
                        dark
                        class="pa-0"
                        height="50"
                        extension-height="3"
                    >
                        <v-tooltip
                            bottom
                            v-if="(
                                sub_tabs === 0 &&
                                selectedBatchPayment !== null
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="amber black--text"
                                    small
                                    @click="refreshBatchPaymentPolicies()"
                                    v-on="on"
                                >
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>Refresh Policies</span>
                        </v-tooltip>
                    </v-toolbar>
                    <!-- Policies Sub-Tab Content -->
                    <v-tab-item key="batch_payment_policies_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="batch_payment_policy_headers"
                            :items="batch_payment_policies"
                            item-key="_id"
                            :single-select="true"
                            @click:row="toBeDefined"
                            class="elevation-1"
                            hide-default-footer
                            :loading="policies_loading"
                            :disable-pagination="true"
                        >
                            <!-- Display Formated capture date -->
                            <template v-slot:item.policy.principal_member="{ item }">
                                <td v-if="item.policy !== null">{{item.policy.principal_member.name + ' ' + item.policy.principal_member.surname + ' (' + item.policy.principal_member.id_number + ')'}}</td>
                            </template>
                            <!-- Premium -->
                            <template v-slot:item.policy="{ item }" >
                                <td v-if="item.policy !== null">R {{ item.policy.product.primary_product.underwriter_premium }}</td>
                            </template>
                            <template v-slot:item.created="{ item }">
                                <td>{{item.created.split('T')[0]}}</td>
                            </template>
                            <!-- User who captured the details -->
                            <template v-slot:item.captured_by="{ item }" >
                                <td v-if="item.captured_by !== null">
                                    <span v-if="item.captured_by.entity !== null">
                                        {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                                    </span>
                                    <span v-else>N/A</span>
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </multipane>
        <CaptureBatchPaymentDialog
            :dialog="open_add_batch_payment_dialog"
        />
        <BankFeedAssociationDialog
            v-if="selectedBatchPayment !== null"
            :id="selectedBatchPayment._id"
            :batch_payment="selectedBatchPayment"
            :dialog="open_bank_feed_association_dialog"
        />
    </div>
</template>
<script>
import { Multipane, MultipaneResizer } from 'vue-multipane';
import { emitAlert, emitSuccess, downloadSafricanReport } from '../../utils/api';
import CaptureBatchPaymentDialog from './dialogs/create_batch_payment.vue';
import BankFeedAssociationDialog from './dialogs/associate_with_bank_feed.vue';
import { eventBus } from "../../main";

import converter from "../../mixins/converter";

import { getUnderwriterBatchPayments, getInstallmentScheduleList } from "./gql/queries";
import { businessProcessUnderwriterBatchPayment } from "./gql/mutations";

export default {
    mixins: [converter],
    components: {
        Multipane,
        MultipaneResizer,
        CaptureBatchPaymentDialog,
        BankFeedAssociationDialog,
    },

    data: () => ({
        sub_tabs: null,
        main_tab_height: '250px',
        sub_tab_height:'180',

        selectedBatchPayment: null,
        selectedRow: null,
        data_table_loading: false,
        policies_loading: false,

        batch_payment_details_headers: [
            { text: 'Batch Number', value: 'batch_number' },
            { text: 'Total Amount', value: 'total_amount' },
            { text: 'Processing Date', value: 'processing_date' },
            { text: 'Date Paid', value: 'date_paid' },
            { text: 'Created', value: 'created' },
            { text: 'Captured By', value: 'captured_by' },
            { text: 'Processed By', value: 'processed_by' },
        ],
        batch_payment_policy_headers: [
            { text: 'Policy Number', value: 'policy.policy_number' },
            { text: 'Principal Member', value: 'policy.principal_member' },
            { text: 'Premium Amount', value: 'policy' },
        ],
        
        // Table data models
        batch_payments_list: [],
        batch_payment_policies: [],

        // Dialogs
        open_add_batch_payment_dialog: false,
        open_bank_feed_association_dialog: false,

        page: 1,
        limit: 2000,
        SEARCH_MODE: false,
    }),

    methods: {
        //-------------------- OPEN DIALOGS ---------------------//
        openCaptureBatchPaymentDialog() {
            // Open onboarding dialog
            this.open_add_batch_payment_dialog = true;
        },
        openBankFeedAssociationDialog() {
            this.open_bank_feed_association_dialog = true;
        },

        //------------------- CLOSE DIALOGS---------------------//
        closeCaptureBatchPaymentDialog(batchPaymentObj) {
            if (batchPaymentObj !== null) {
                let tempArr = [batchPaymentObj];
                tempArr = tempArr.concat(this.batch_payments_list);
                // this.batch_payments_list = this.batch_payments_list.concat(batchPaymentObj);
                this.batch_payments_list = tempArr;
            }

            this.open_add_batch_payment_dialog = false;
        },
        closeBankFeedAssociationDialog(new_batch_payment_item) {
            this.updateUITableModel(new_batch_payment_item);
            this.open_bank_feed_association_dialog = false;
        },
        async selectMainTableRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);

                this.selectedBatchPayment = item;

                if (this.sub_tabs === 0) { // transactions-history
                    await this.refreshBatchPaymentPolicies();
                }
            } else {
                this.selectedBatchPayment = null;
                // Unighlight selected row
                item_metadata.select(false);
            }
        },
        unselectSelectedRow() {
            if (this.selectedRow !== null) {
                this.selectedRow.select(false);
                this.selectedRow = null;
            }
            this.selectedBatchPayment = null;
        },
        updateUITableModel(new_batch_payment_item) {
            if (new_batch_payment_item !== null) {
                const obj_index = this.batch_payments_list.map(obj => { return obj._id; }).indexOf(new_batch_payment_item._id)
                this.batch_payments_list[obj_index] = new_batch_payment_item;

                this.selectedLoan = new_batch_payment_item;
                this.selectedLoanCopy = JSON.parse( JSON.stringify(new_batch_payment_item) );

                 // Deep copy batch_payments_list for refreshing main data table
                const temp_arr = Array.from(this.batch_payments_list);
                this.batch_payments_list = temp_arr;
            }
        },
        async refreshDataTable() {
            this.batch_payments_list = [];
            this.page = 1;

            try {
                this.data_table_loading = true;
                const res = await this.$apollo
                                    .query({
                                        query: getUnderwriterBatchPayments,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'UNDERWRITER_BATCH_PAYMENT',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }

                this.data_table_loading = false;
                this.batch_payments_list = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        async loadMoreData() {
            this.page += 1;

            try {
                this.data_table_loading = true;
                const res = await this.$apollo
                                    .query({
                                        query: getUnderwriterBatchPayments,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'UNDERWRITER_BATCH_PAYMENT',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }

                this.data_table_loading = false;
                this.batch_payments_list = this.batch_payments_list.concat(res.data.getBusinessObject.business_objects);
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
        async processBatchPayment() {
            this.data_table_loading = true;
            try {
                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcessUnderwriterBatchPayment,
                                                variables: {
                                                    input: {
                                                        OP_TYPE: 'PROCESS_BATCH_PAYMENT',
                                                        _id: this.selectedBatchPayment._id,
                                                    }
                                                },
                                            });
                if (res.data.businessProcessUnderwriterBatchPayment.errors === true) {
                    emitAlert(res.data.businessProcessUnderwriterBatchPayment.message);
                    this.data_table_loading = false;
                    return;
                }

                emitSuccess(res.data.businessProcessUnderwriterBatchPayment.message);
                
                this.data_table_loading = false;

                const newBatchPayment = res.data.businessProcessUnderwriterBatchPayment.business_object;
                this.updateUITableModel(newBatchPayment);
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessUnderwriterBatchPayment.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
        async refreshBatchPaymentPolicies() {
            this.policies_loading = true;
            this.batch_payment_policies = [];
            try {
                const res = await this.$apollo
                                    .query({
                                        query: getInstallmentScheduleList,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'POLICY_INSTALLMENT_SCHEDULE_ITEM',
                                            pagination: {
                                                page: 1,
                                                limit: 50
                                            },
                                            searchInput: {
                                                underwriter_batch_payment: this.selectedBatchPayment._id
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.policies_loading = false;
                    return;
                }


                this.policies_loading = false;
                this.batch_payment_policies = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.transactions_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
        paneResizing(pane, container, size) {
            const size_int = parseInt(size);

            this.main_tab_height = size;
            const max_screen_height = window.innerHeight - 300;

            const sub_tab_percentage = 1 - (size_int/max_screen_height);
            this.sub_tab_height = sub_tab_percentage * max_screen_height;
        },
        toBeDefined(){},

        async downloadSafricanExcelReport() {
            this.data_table_loading = true;
            try {
                const res = await downloadSafricanReport(this.selectedBatchPayment._id);
                const report_name = `UB-${this.selectedBatchPayment.batch_number}.xlsx`;
                this.data_table_loading = false;

                // create file link in browser's memory
                const href = URL.createObjectURL(res.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', report_name); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            } catch (error) {
                this.data_table_loading = false;
                console.log(error)
            }
        }
    },

    async mounted() {
        try {
            this.data_table_loading = true;
            const res = await this.$apollo
                                    .query({
                                        query: getUnderwriterBatchPayments,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'UNDERWRITER_BATCH_PAYMENT',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.data_table_loading = false;
                return;
            }

            this.data_table_loading = false;
            this.batch_payments_list = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.data_table_loading = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
        }

        eventBus.$on('CLOSE_CAPTURE_BATCH_PAYMENT_DIALOG', this.closeCaptureBatchPaymentDialog);
        eventBus.$on('CLOSE_BANK_FEED_ASSOCIATION_DIALOG', this.closeBankFeedAssociationDialog);

        /**
         * The following logic is for manually triggering a pane-divider movement
         * so as to get the windows to properly resize
         */
        this.paneResizing(null, null, 250);
    },

    watch: {
        'sub_tabs': async function(val) {
            if (val === 0) { // transactions-history
                if (this.selectedBatchPayment !== null) {
                    await this.refreshBatchPaymentPolicies();
                }
            }
        }
    }
}
</script>
<style scoped>
/* Overriding of textbox min height */
 .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
}
</style>
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Edit Basic Personal Details</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn
                    icon
                    @click="closeEditPersonDialog()"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="member_copy.name"
                                color="secondary"
                                label="First Name(s)"
                                autocomplete="some-random-text"
                                type="text"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" >
                            <v-text-field
                                v-model="member_copy.surname"
                                color="secondary"
                                label="Surname"
                                autocomplete="some-random-text"
                                type="text"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3" >
                            <v-text-field
                                v-model="member_copy.id_number"
                                color="secondary"
                                label="ID Number"
                                autocomplete="some-random-text"
                                type="number"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2" >
                            <v-select
                                v-model="member_copy.gender"
                                :items="entity_gender"
                                color="secondary"
                                label="Gender"
                                disabled
                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="2" >
                            <v-select
                                v-model="member_copy.marital_status"
                                :items="marital_statuses"
                                color="secondary"
                                label="Martial Status"
                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="5" >
                            <v-select
                                v-model="member_copy.marketing_channels"
                                :items="['Email', 'SMS', 'Postal']"
                                label="Marketing Material Channels"
                                hint="If the person is consenting to receive marketing material"
                                persistent-hint
                                chips
                                multiple
                                color="secondary"
                                required
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Updating Member Details
                        <v-progress-linear
                            indeterminate
                            :active="submitting"
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="success"
                        :disabled="submitBtnDisabled()"
                        @click="submitPerson()"
                    >
                        <v-icon large>save</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";
import {
    entity_gender,
    marital_statuses,
} from "../../../../config";

import { entityProcessEntity } from "../gql/mutations";

export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        member_original: {
            type: Object,
            required: false,
            default: () => {
                return {
                    marketing_channels: []
                }
            },
        },
        member_copy: {
            type: Object,
            required: false,
            default: () => {
                return {
                    marketing_channels: []
                }
            },
        },
    },
    data: () => ({
        submitting: false,
        server_response_obj: null,

        marital_statuses: marital_statuses,
        entity_gender: entity_gender,
    }),
        
    methods: {
        closeEditPersonDialog() {
            eventBus.$emit('CLOSE_EDIT_PERSON_DIALOG', this.server_response_obj);
        },
        resetForm() {
            this.server_response_obj = null;
            eventBus.$emit('RESET_MEMBER_EDIT_FORM', this.member_copy);
        },
        
        submitBtnDisabled() {
            let difference = this.member_copy.marketing_channels.filter(x => !this.member_original.marketing_channels.includes(x))
                                    .concat(this.member_original.marketing_channels.filter(x => !this.member_copy.marketing_channels.includes(x)));

            if (
                this.member_copy.name.trim() === this.member_original.name &&
                this.member_copy.surname.trim() === this.member_original.surname &&
                this.member_copy.marital_status === this.member_original.marital_status &&
                difference.length === 0
            ) {
                return true;
            } else {
                // Cater for if name is an empty string
                if (typeof(this.member_copy.name) === 'string') {
                    if (
                        this.member_copy.name.trim() === '' &&
                        this.member_original.name === null    
                    ) {
                        this.member_copy.name = null;
                        return true;
                    }
                }
                // Cater for if surname is an empty string
                if (typeof(this.member_copy.surname) === 'string') {
                    if (
                        this.member_copy.surname.trim() === '' &&
                        this.member_original.surname === null    
                    ) {
                        this.member_copy.surname = null;
                        return true;
                    }
                }
            }
            return false;
        },

        capturingDone() {
            this.closeEditPersonDialog();
            this.resetForm();
        },
        async submitPerson() {
            this.submitting = true;

            try {
                // Payload payload
                const submitObj = {
                    OP_TYPE: 'UPDATE',
                    _id: this.member_original._id,

                    name: this.member_copy.name,
                    surname: this.member_copy.surname,
                    marital_status: this.member_copy.marital_status,
                    marketing_channels: this.member_copy.marketing_channels,
                };

                const res = await this.$apollo
                                            .mutate({
                                                mutation: entityProcessEntity,
                                                variables: {
                                                    input: submitObj
                                                },
                                            });
                if (res.data.entityProcessEntity.errors === true) {
                    emitAlert(res.data.entityProcessEntity.message);
                    this.submitting = false;
                    return;
                }
                
                this.server_response_obj = res.data.entityProcessEntity.business_object;
                this.closeEditPersonDialog();
                emitSuccess(res.data.entityProcessEntity.message);

                // Reset fields
                this.server_response_obj = null;
                this.submitting = false;
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.entityProcessEntity.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
        },
    },
    mounted() {

    },
}
</script>
<style scoped>

</style>
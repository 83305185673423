import gql from 'graphql-tag'

export const createBusinessProcess = gql`
    mutation lookupProcessBusinessProcess($input: BusinessProcessInput) {
        lookupProcessBusinessProcess(input: $input) {
            errors
            message
            business_object {
                ... on BusinessProcess {
                    __typename
                    _id
                    name
                    group
                    process_number
                    description
                }
            }
        }
    }
`
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Edit Basic Loan Details</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn
                    icon
                    @click="closeEditLoanDialog()"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                :value="loan_original.scheme.name + ' (' + loan_original.scheme.scheme_number + ')'"
                                color="secondary"
                                label="Current Scheme"
                                autocomplete="some-random-text"
                                type="text"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <h2>Update/Change Scheme</h2>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <SchemeSearch
                                :parent="screem_model"
                                :label="'New Scheme'"
                            />
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Updating Loan Details
                        <v-progress-linear
                            indeterminate
                            :active="submitting"
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="success"
                        :disabled="submitBtnDisabled()"
                        @click="submitLoan()"
                    >
                        <v-icon large>save</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";
import SchemeSearch from "../components/scheme_search.vue";

import { businessProcessLoan } from "../gql/mutations";

export default {
    components: {
        SchemeSearch
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        loan_original: {
            type: Object,
            required: false,
        },
        loan_copy: {
            type: Object,
            required: false,
        },
    },
    data: () => ({
        submitting: false,
        server_response_obj: null,

        screem_model: {
            scheme: null
        }
    }),
        
    methods: {
        closeEditLoanDialog() {
            eventBus.$emit('CLOSE_EDIT_LOAN_DIALOG', this.server_response_obj);
        },
        resetForm() {
            this.server_response_obj = null;
            this.screem_model.scheme = null;
        },
        
        submitBtnDisabled() {
            if (this.screem_model.scheme === null) {
                return true;
            }

            if (this.screem_model.scheme._id === this.loan_original.scheme._id) {
                return true;
            }

            return false;
        },

        capturingDone() {
            this.closeEditLoanDialog();
            this.resetForm();
        },
        async submitLoan() {
            this.submitting = true;

            try {
                // Payload payload
                const submitObj = {
                    scheme: this.screem_model.scheme._id,
                };

                const payloadObj = {
                    OP_TYPE: 'UPDATE',
                    _id: this.loan_original._id,

                    ...submitObj
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcessLoan,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.businessProcessLoan.errors === true) {
                    emitAlert(res.data.businessProcessLoan.message);
                    this.submitting = false;
                    return;
                }
                
                this.server_response_obj = res.data.businessProcessLoan.business_object;
                this.closeEditLoanDialog();
                emitSuccess(res.data.businessProcessLoan.message);

                // Reset fields
                this.server_response_obj = null;
                this.submitting = false;
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessLoan.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
        },
    },
    mounted() {

    },
}
</script>
<style scoped>

</style>
import gql from 'graphql-tag'

export const businessProcessUnderwriterBatchPayment = gql`
    mutation businessProcessUnderwriterBatchPayment($input: UnderwriterBatchPaymentInput!) {
        businessProcessUnderwriterBatchPayment(input: $input) {
            errors
            message
            business_object {
                ... on UnderwriterBatchPayment {
                    __typename
                    _id
                    
                    batch_number
                    total_amount

                    processing_date
                    date_paid

                    bank_feed {
                        __typename
                        _id
                    }
                    created
                    captured_by {
                        __typename
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }
                    processed_by {
                        __typename
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }
                }
            }
        }
    }
`

export const createFinanceTransaction = gql`
    mutation finProcessFinanceAccountTransaction($input: FinanceAccountTransactionInput) {
        finProcessFinanceAccountTransaction(input: $input) {
            errors
            message
            business_object {
                ... on FinanceAccountTransaction {
                    __typename
                    _id
                    transaction_date

                    amount
                    from {
                        _id
                        name
                        balance
                        last_updated
                    }
                    to {
                        _id
                        name
                        balance
                        last_updated
                    }
                    bank_feed {
                        _id
                        bank_account_name
                        date
                        amount
                        description
                        reference
                        created
                        classification {
                            _id
                            name
                            category
                        }
                    }
                    reference
                    additional_reference

                    balance_from
                    balance_to

                    created
                    created_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }
                }
            }
        }
    }
`
import gql from 'graphql-tag'

export const getCreditProducts = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on LoanProductPrimary {
                    __typename
                    _id
                    
                    name
                    # max_payment_holidays
                    # min_loan_amount
                    # max_loan_amount

                    # min_number_of_installments

                    # monthly_fixed_fee
                    # monthly_finance_charge_percentage
                    # monthly_finance_charge_amount

                    rules {
                        _id
                        min
                        max
                        rule_parameter {
                            _id
                            id
                            level
                            name
                            description
                            object_key
                        }
                    }
                }
            }
        }
    }
`

export const getProductRuleParameters = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on ProductRuleParameter {
                    __typename
                    _id
                    id
                    level
                    name
                    description
                    object_key
                }
            }
        }
    }
`
import gql from 'graphql-tag'

export const getEntities = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on Entity {
                    _id
                    business_partner { _id }
                    name
                    surname
                    id_number
                    gender
                    date_of_birth
                    marital_status
                    life_status
                    marketing_channels

                    version_history {
                        _id
                        changes {
                            type
                            description
                            old
                            new
                            captured_by {
                                __typename
                                _id
                                entity {
                                    __typename
                                    _id
                                    name
                                    surname
                                }
                            }
                            created
                        }
                    }

                    relationships {
                        __typename
                        _id
                        created
                        deleted
                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        member {
                            __typename
                            _id
                            name
                            surname
                        }
                        relationship_type {
                            __typename
                            _id
                            name
                            description
                            gender_map
                            opposite_relationship {
                                __typename
                                _id
                                name
                            }
                            payout_target_roles_for_principal
                        }
                    }
                    
                    addresses {
                        __typename
                        _id
                        type
                        line1
                        line2
                        line3
                        line4
                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }

                    contact_details {
                        __typename
                        _id
                        type
                        primary
                        details

                        captured_by {
                            __typename
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }

                    documents {
                        __typename
                        _id
                        type
                        mime_type
                        name

                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }

                    bank_account_details {
                        __typename
                        _id
                        # purpose
                        bank {
                            __typename
                            _id
                            name
                            universal_branch_code
                        }
                        account_type
                        account_number

                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }

                    captured_by {
                        __typename
                        _id
                        entity {
                            __typename
                            _id
                            name
                            surname
                        }
                    }
                    created
                }
            }
        }
    }
`

export const getBanks = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on Bank {
                    __typename
                    _id
                    name
                    universal_branch_code
                }
            }
        }
    }
`

export const getEntityRelationshipTypes = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on EntityRelationshipType {
                    __typename
                    _id
                    name
                    description
                    gender_map
                    opposite_relationship {
                        __typename
                        _id
                        name
                    }
                    payout_target_roles_for_principal
                }
            }
        }
    }
`

export const searchEntity = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on Entity {
                    _id
                    business_partner { _id }
                    name
                    surname
                    id_number
                }
            }
        }
    }
`

export const getEntityLoans = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on Loan {
                    _id
                    business_partner {
                        _id
                        name
                    }
                    loan_number
                    premium

                    projected_end_date
                    last_transaction_date

                    agent {
                        _id
                        agent_number
                        entity {
                            _id
                            name
                            surname
                            id_number
                        }
                    }
                    scheme {
                        _id
                        name
                        scheme_number
                    }
                    entity {
                        _id
                        name
                        surname
                        id_number

                        bank_account_details {
                            _id
                            purpose
                            bank {
                                _id
                                name
                                universal_branch_code
                            }
                            account_type
                            account_number

                            captured_by {
                                _id
                                entity {
                                    _id
                                    name
                                    surname
                                }
                            }
                            created
                            deleted
                            delete_date
                            deleted_by {
                                _id
                                entity {
                                    _id
                                    name
                                    surname
                                }
                            }
                            delete_reason
                        }
                    }
                    banking_details_id
                    bank_name
                    bank_account_number

                    effective_date
                    created
                    approved_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }
                    created_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }

                    status
                    bank_statement_status
                    agent_status
                    decline_reason

                    pricing_input {
                        loan_type

                        principal_amount
                        loan_term_units
                        loan_term

                        specialized_landing

                        fees_config {
                            monthly_admin_fee
                            initiation_fee_principal_percentage
                            initiation_fee_paid_at_inception
                        }
                        premium_allocation_priority {
                            principal
                            interest
                            admin_fee_premium
                            initiation_fee_premium
                        }
                        interest_config {
                            annual_interest_rate
                            annual_interest_rate_monthly
                        }
                        amortization_schedule_config {
                            term_start
                            term_end
                            annual_interest_amortization_min_month_term

                            principal_required
                            initiation_fee_premium_required
                            admin_fee_required
                            interest_required
                        }
                    }

                    repayment_info {
                        payment_method
                        repayment_frequency
                        elected_repayment_time_point
                    }

                    installment_schedule {
                        _id
                        installment_number
                        deadline_date

                        premium_principal
                        premium_principal_collected
                        premium_principal_target
                        # premium_principal_finance_transaction

                        premium_admin_fee
                        premium_admin_fee_collected
                        premium_admin_fee_target
                        # premium_admin_fee_finance_transaction

                        premium_initiation_fee
                        premium_initiation_fee_collected
                        premium_initiation_fee_target
                        # premium_initiation_fee_finance_transaction

                        premium_interest
                        premium_interest_collected
                        premium_interest_target
                        # premium_interest_finance_transaction

                        premium_total_theoretical
                        premium_total_collected

                        installment_item_carried_over

                        installment_status

                        softy_comp_collections {
                            _id
                            collection_id
                            frequency
                            action_date
                            created

                            local_status
                            transaction_id
                            captured_by {
                                _id
                                entity {
                                    _id
                                    name
                                    surname
                                }
                            }
                        }
                    }

                    documents {
                        _id
                        type
                        mime_type
                        name
                        captured_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }
                }
            }
        }
    }
`

export const getEntityPolicies = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on Policy {
                    __typename
                    _id
                    business_partner {
                        _id
                        bp_number
                        name
                    }
                    group
                    policy_number
                    underwriter_policy_number
                    effective_date
                    end_date
                    high_risk

                    principal_member {
                        _id
                        name
                        surname
                        id_number
                    }
                    
                    agent {
                        _id
                        agent_number
                        entity {
                            _id
                            name
                            surname
                            id_number
                        }
                    }
                    product {
                        _id
                        name
                        markup_fixed
                        markup_percentage
                        primary_product {
                            _id
                            name
                            underwriter_premium
                            broker_commission
                            broker_markup
                            
                            payout_structure {
                                _id
                                payout_amount
                                category {
                                    _id
                                    name
                                    membership_category
                                    start_age
                                    end_age
                                }
                            }
                        }
                    }
                    scheme {
                        _id
                        scheme_number
                        name
                    }
                    elected_payment_date
                    status
                    # agent_status
                    underwriter_status
                    decline_reason
                    close_reason
                    created
                    captured_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                            id_number
                        }
                    }
                    # version_history {
                    #     _id
                    #     changes {
                    #         _id
                    #         type
                    #         description
                    #         details
                    #         captured_by {
                    #             _id
                    #             entity {
                    #                 _id
                    #                 name
                    #                 surname
                    #                 id_number
                    #             }
                    #         }
                    #         created
                    #     }
                    # }
                    payment_information {
                        premium_payer {
                            payment_method
                            percentage_amount
                        }
                        scheme {
                            payment_method
                            percentage_amount
                        }
                    }
                    members {
                        _id
                        entity {
                            _id
                            name
                            surname
                            id_number
                        }
                        role
                        covered_by_underwriter
                        created
                        start_date
                        end_date
                        termination_reason
                        captured_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                                id_number
                            }
                        }
                        activated_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                                id_number
                            }
                        }
                        updated_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                                id_number
                            }
                        }

                        # Derived
                        age_inception
                        age_current
                    }
                    documents {
                        _id
                        type
                        mime_type
                        name
                        created
                        deleted
                        delete_date
                        delete_reason
                        captured_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                                id_number
                            }
                        }
                        deleted_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                                id_number
                            }
                        }
                    }
                }
            }
        }
    }
`

export const getEntityProfile = gql`
    query profilingGetEntityProductProfile($entity_id: String!) {
        profilingGetEntityProductProfile(entity_id: $entity_id) {
            credit_profile {
                total_principal_amount
                total_principal_amount_collected_to_date
                total_active_premium

                credit_score {
                    overall_credit_score
                    principal_repayment_performance
                    profit_performance
                }
                
                count {
                    total_loans
                    active_loans
                    repaid_loans
                    loans_in_arrears

                    stage_1__1_to_7_days
                    stage_1__8_to_30_days
                    stage_2__31_to_60_days
                    stage_3__61_to_90_days
                    stage_3__above_90_days
                }

                income {
                    expected_income_at_end
                    expected_income_to_date
                    actual_profit_to_date
                    loss_to_date
                }

                loans_list {
                    loan_number

                    principal_amount
                    premium
                    active_premium

                    status
                    current_days_in_arrears
                    oldest_days_in_arrears
                    payment_method
                    payment_date

                    credit_score {
                        overall_credit_score
                        principal_repayment_performance
                        profit_performance
                    }

                    income {
                        expected_income_at_end
                        expected_income_to_date
                        actual_profit_to_date
                        loss_to_date
                    }
                }
            }
        }
    }
`
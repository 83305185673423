<template>
    <div>
        <v-toolbar
            color="secondary"
            dark
            class="pa-0"
            height="50"
            extension-height="3"
        >
            <v-row>
                <!-- Grid Buttons -->
                <v-col sm="4" style="margin-top:10px">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                @click="loadMoreData()"
                                v-on="on"
                            >
                                <v-icon>cloud_download</v-icon>
                            </v-btn>
                        </template>
                        <span>Load More Data</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="refreshFinancialCalendar()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh</span>
                    </v-tooltip>
                </v-col>

                <!-- Search Result Indicator -->
                <v-col sm="4">
                </v-col>

                <!-- Action Buttons -->
                <v-col sm="4" style="margin-top:10px">
                    <div class="text-right">
                        <!-- CLOSE FIN YEAR -->
                        <v-tooltip
                            bottom
                            v-if="(
                                selectedFinYear !== null &&
                                selectedFinYear.status === 'OPEN'
                            )"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="red white--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="openCloseFinYearDialog()"
                                >
                                    <v-icon medium>mdi-lock</v-icon>
                                </v-btn>
                            </template>
                            <span>Close Financial Year</span>
                        </v-tooltip>

                        <!-- OPEN FIN YEAR -->
                        <v-tooltip
                            bottom
                            v-if="(
                                selectedFinYear !== null &&
                                selectedFinYear.status === 'CLOSED'
                            )">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="success white--text"
                                    class="mx-2"
                                    v-on="on"
                                    @click="openOpenFinYearDialog()"
                                >
                                    <v-icon medium>mdi-lock-open</v-icon>
                                </v-btn>
                            </template>
                            <span>Open Financial Year</span>
                        </v-tooltip>
                        
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="openGenerateFinCalendarDialog()"
                                    v-on="on"
                                >
                                    <v-icon medium>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Generate Financial Calendar</span>
                        </v-tooltip>
                    </div>
                </v-col>
            </v-row>
        </v-toolbar>
        <multipane class="horizontal-panes" layout="horizontal" style="height: 100%" @paneResize="paneResizing">
            <div class="pane" :style="{ height: '50%', maxHeight: '50%'}">
                <v-data-table
                    fixed-header
                    :height="main_tab_height"
                    :headers="fin_year_details_headers"
                    :items="financial_years"
                    item-key="_id"
                    class="elevation-1"
                    :single-select="true"
                    @click:row="selectMainTableRow"
                    item-class="secondary"
                    hide-default-footer
                    :loading="data_table_loading"
                    :disable-pagination="true"
                >
                    <!-- Display create date -->
                    <template v-slot:[`item.created`]="{ item }">
                        <td>{{item.created.split('T')[0]}}</td>
                    </template>
                    <!-- Display Formated capture date -->
                    <template v-slot:[`item.active`]="{ item }">
                        <td>{{item.active ? 'Active' : 'Inactive'}}</td>
                    </template>
                </v-data-table>
            </div>
            <multipane-resizer></multipane-resizer>
            <div class="pane tab-nav" :style="{ height: '30%', maxHeight: '50%', minHeight: '30%' }">
                <v-tabs
                    v-model="sub_tabs"
                    color="transparent"
                    class="tab-nav"
                    hide-slider
                    height="35px"
                    grow
                >
                    <!-- Fin Months Sub Tab -->
                    <template>
                        <v-tab key="calendar_months_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Calendar Months
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>
                </v-tabs>
                <v-tabs-items v-model="sub_tabs">
                    <!-- Sub-Tab Toolbar -->
                    <v-toolbar
                        color="secondary"
                        dark
                        class="pa-0"
                        height="50"
                        extension-height="3"
                    >
                        <!-- Show following buttons only for the fin-month sub-tab -->
                        <v-tooltip
                            v-if="(
                                sub_tabs === 0 &&
                                selectedFinYear !== null &&
                                selectedFinMonth !== null &&
                                selectedFinMonth.status === 'CLOSED'
                            )"
                            bottom
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="success white--text"
                                    small
                                    @click="openOpenFinMonthDialog()"
                                    v-on="on"
                                >
                                    <v-icon>mdi-lock-open</v-icon>
                                </v-btn>
                            </template>
                            <span>Open Financial Month</span>
                        </v-tooltip>
                        <!-- close fin month  -->
                        <div v-if="selectedFinYear !== null && selectedFinMonth !== null">
                            <v-tooltip bottom v-if="sub_tabs === 0  && selectedFinMonth.status === 'OPEN'">
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        color="red white--text"
                                        small
                                        v-on="on"
                                        @click="openCloseFinMonthDialog()"
                                    >
                                        <v-icon>mdi-lock</v-icon>
                                    </v-btn>
                                </template>
                                <span>Close Financial Month</span>
                            </v-tooltip>
                        </div>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <!-- Fin Month Sub-Tab Content -->
                    <v-tab-item key="calendar_months_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="financial_month_details_headers"
                            :items="financial_months"
                            item-key="_id"
                            class="elevation-1"
                            :single-select="true"
                            @click:row="selectFinMonthTabRow"
                            sort-by="value"
                            :sort-desc="false"
                            item-class="secondary"
                            hide-default-footer
                            :disable-pagination="true"
                        ></v-data-table>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </multipane>
        <GenerateFinCalendarDialog
            :dialog="open_generate_fin_calendar_dialog"
        />
        <OpenFinMonthDialog
            v-if="selectedFinMonth !== null"
            :dialog="open_open_fin_month_dialog"
            :fin_month="selectedFinMonth"
        />
        <CloseFinYearDialog
            v-if="selectedFinYear !== null"
            :dialog="open_close_fin_year_dialog"
            :fin_year="selectedFinYear"
        />
        <OpenFinYearDialog
            v-if="selectedFinYear !== null"
            :dialog="open_open_fin_year_dialog"
            :fin_year="selectedFinYear"
        />
        <CloseFinMonthDialog
            v-if="selectedFinMonth !== null"
            :dialog="open_close_fin_month_dialog"
            :fin_month="selectedFinMonth"
        />
    </div>
</template>
<script>
import { Multipane, MultipaneResizer } from 'vue-multipane';
import GenerateFinCalendarDialog from './dialogs/generate_fin_calendar';
import OpenFinYearDialog from './dialogs/open_fin_year.vue';
import CloseFinYearDialog from './dialogs/close_fin_year.vue';
import OpenFinMonthDialog from './dialogs/open_fin_month.vue';
import CloseFinMonthDialog from './dialogs/close_fin_month.vue';
 
import { eventBus } from "../../../main";
import { emitAlert } from "../../../utils/api";

import { getFinancialCalendar } from "./gql/queries";

export default {
    components: {
        Multipane,
        MultipaneResizer,
        GenerateFinCalendarDialog,
        CloseFinYearDialog,
        OpenFinYearDialog,
        CloseFinMonthDialog,
        OpenFinMonthDialog,
    },

    data: () => ({
        sub_tabs: null,
        main_tab_height: '250px',
        sub_tab_height:'180',

        // main data-table row
        selectedFinYear: null,

        // Fin Month sub-tab data-table row
        selectedFinMonth: null,
        selectedFinMonthRow: null,

        fin_year_details_headers: [
            { text: 'Year', value: 'name' },
            { text: 'Status', value: 'status' },
        ],
        financial_month_details_headers: [
            { text: 'Month Number', value: 'value' },
            { text: 'Name', value: 'name' },
            { text: 'Status', value: 'status' },
        ],
        
        // Table data models
        financial_months: [],

        // Dialogs
        
        financial_years: [],
        open_generate_fin_calendar_dialog: false,
        open_open_fin_month_dialog: false,
        open_close_fin_year_dialog: false,
        open_open_fin_year_dialog: false,
        open_close_fin_month_dialog: false,

        data_table_loading: false,

        page: 1,
        limit: 50,
    }),

    methods: {
        //-------------------- OPEN DIALOGS ---------------------//
        openGenerateFinCalendarDialog() {
            this.open_generate_fin_calendar_dialog = true;
        },
        openOpenFinMonthDialog() {
            this.open_open_fin_month_dialog = true;
        },
        openCloseFinYearDialog() {
            this.open_close_fin_year_dialog = true;
        },
        openOpenFinYearDialog() {
            this.open_open_fin_year_dialog = true;
        },
        openCloseFinMonthDialog() {
            this.open_close_fin_month_dialog = true;
        },

        //------------------- CLOSE DIALOGS---------------------//
        closeGenerateFinCalendarDialog(new_fin_year) {
            if (new_fin_year !== null) {
                this.financial_years.push(new_fin_year);
            }
            this.open_generate_fin_calendar_dialog = false;
        },
        closeOpenFinMonthDialog(new_fin_month) {
            if (new_fin_month !== null) {
                const obj_index = this.financial_months.map(obj => { return obj._id; }).indexOf(new_fin_month._id)
                this.financial_months[obj_index] = new_fin_month;

                this.selectedFinMonth = new_fin_month
                
                const temp_arr = Array.from(this.financial_months);
                this.financial_months = temp_arr;
            }

            this.open_open_fin_month_dialog = false;
        },
        closeCloseFinYearDialog(new_fin_year) {
            if (new_fin_year !== null) {
                const obj_index = this.financial_years.map(obj => { return obj._id; }).indexOf(new_fin_year._id)
                this.financial_years[obj_index] = new_fin_year;

                this.selectedFinYear = new_fin_year
                
                const temp_arr = Array.from(this.financial_years);
                this.financial_years = temp_arr;
                this.financial_months = new_fin_year.fin_months;
            }

            this.open_close_fin_year_dialog = false;
        },
        closeOpenFinYearDialog(new_fin_year) {
            if (new_fin_year !== null) {
                const obj_index = this.financial_years.map(obj => { return obj._id; }).indexOf(new_fin_year._id)
                this.financial_years[obj_index] = new_fin_year;

                this.selectedFinYear = new_fin_year
                
                const temp_arr = Array.from(this.financial_years);
                this.financial_years = temp_arr;
                this.financial_months = new_fin_year.fin_months;
            }

            this.open_open_fin_year_dialog = false;
        },
        closeCloseFinMonthDialog(new_fin_month) {
            if (new_fin_month !== null) {
                const obj_index = this.financial_months.map(obj => { return obj._id; }).indexOf(new_fin_month._id)
                this.financial_months[obj_index] = new_fin_month;

                this.selectedFinMonth = new_fin_month
                
                const temp_arr = Array.from(this.financial_months);
                this.financial_months = temp_arr;
            }

            this.open_close_fin_month_dialog = false;
        },

        unselectSubTabRows() {
            // Unselect fin-month sub-tab row
            this.selectedFinMonth = null;
            if (this.selectedFinMonthRow !== null) {
                this.selectedFinMonthRow.select(false);
            }
        },
        selectMainTableRow(item, item_metadata) {
            this.unselectSubTabRows();

            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                this.selectedFinYear = item;
                // Update fin-month model
                this.financial_months = item.fin_months;
            } else {
                this.selectedFinYear = null;
                // Unighlight selected row
                item_metadata.select(false);

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                // Update member fin-month model
                this.financial_months = [];
            }
        },
        selectFinMonthTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);

                this.selectedFinMonth = item;
                this.selectedFinMonthRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedFinMonth = null;
                this.selectedFinMonthRow = null;
            }
        },

        async loadMoreData() {
            this.data_table_loading = true;
            this.page += 1;
            
            try {
                // const res = await getFinancialCalendar(this.page, this.limit);
                // if (res.errors === true) {
                //     emitAlert(res.message);
                //     this.data_table_loading = false;
                //     return;
                // }
                const res = await this.$apollo
                                    .query({
                                        query: getFinancialCalendar,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'FINANCE_CALENDAR_YEAR',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }
                
                this.data_table_loading = false;
                this.financial_years = this.financial_years.concat(res.data.getBusinessObject.business_objects);
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        async refreshFinancialCalendar() {
            this.data_table_loading = true;
            this.financial_years = [];

            this.page = 1;

            try {
                // const res = await getFinancialCalendar(this.page, this.limit);
                // if (res.errors === true) {
                //     emitAlert(res.message);
                //     this.data_table_loading = false;
                //     return;
                // }
                const res = await this.$apollo
                                    .query({
                                        query: getFinancialCalendar,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'FINANCE_CALENDAR_YEAR',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }

                this.data_table_loading = false;
                this.financial_years = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        paneResizing(pane, container, size) {
            const size_int = parseInt(size);

            this.main_tab_height = size;
            const max_screen_height = window.innerHeight - 300;

            const sub_tab_percentage = 1 - (size_int/max_screen_height);
            this.sub_tab_height = sub_tab_percentage * max_screen_height;
        }
    },

    async mounted() {
        eventBus.$on('CLOSE_GENERATE_FIN_CALENDAR_DIALOG', this.closeGenerateFinCalendarDialog);
        eventBus.$on('CLOSE_OPEN_FIN_MONTH_DIALOG', this.closeOpenFinMonthDialog);
        eventBus.$on('CLOSE_CLOSE_FIN_YEAR_DIALOG', this.closeCloseFinYearDialog);
        eventBus.$on('CLOSE_OPEN_FIN_YEAR_DIALOG', this.closeOpenFinYearDialog);
        eventBus.$on('CLOSE_CLOSE_FIN_MONTH_DIALOG', this.closeCloseFinMonthDialog);

        /**
         * The following logic is for manually triggering a pane-divider movement
         * so as to get the windows to properly resize
         */
        this.paneResizing(null, null, 250);

        try {
            this.data_table_loading = true;
            // const res = await getFinancialCalendar(this.page, this.limit);
            // if (res.errors === true) {
            //     emitAlert(res.message);
            //     this.data_table_loading = false;
            //     return;
            // }
            const res = await this.$apollo
                                    .query({
                                        query: getFinancialCalendar,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'FINANCE_CALENDAR_YEAR',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.data_table_loading = false;
                return;
            }

            this.data_table_loading = false;
            this.financial_years = res.data.getBusinessObject.business_objects;

            // console.log(res)
        } catch (error) {
            this.data_table_loading = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
        }
    },

    watch: {
        
    }
}
</script>
<style scoped>
/* Overriding of textbox min height */
 .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
}
</style>
import gql from 'graphql-tag'

export const getVASList = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on VASService {
                    __typename
                    _id

                    name
                    category {
                        _id
                        name
                    }
                    description
                    captured_by {
                        _id
                        entity {
                            name
                            surname
                        }
                    }
                    created

                    deleted
                    delete_date
                    deleted_by {
                        _id
                        entity {
                            name
                            surname
                        }
                    }
                    delete_reason

                    documents {
                        _id
                        type
                        mime_type
                        name

                        captured_by {
                            _id
                            entity {
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            _id
                            entity {
                                name
                                surname
                            }
                        }
                        delete_reason
                    }
                }
            }
        }
    }
`

export const getVASCategoryList = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on VASCategory {
                    __typename
                    _id
                    name
                }
            }
        }
    }
`
<template>
    <v-row justify="center">
        <!-- <v-dialog
            v-model="dialog"
            fullscreen
            persistent
        > -->
        <v-dialog
            v-model="dialog"
            persistent
        >
            <div style="background:white; overflow-y:auto; height:100%">
                <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d;">
                    <v-spacer></v-spacer>
                    <v-toolbar-title><b>Update Premium Payer Details</b></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                class="mx-2"
                                color="red"
                                small
                                v-on="on"
                                @click="resetForm()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Reset Form</span>
                    </v-tooltip>
                    <v-btn icon @click="closeDialog()" v-if="server_response_obj === null">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card>
                    <v-card-text>
                        <!-- Basic Details -->
                        <div style="background:#4490a030; border:1px #4490a030 solid; padding:10px;">
                            <v-row>
                                <v-col cols="12" sm="6" offset-sm="3">
                                    <v-row>
                                        <v-col cols="12" sm="10">
                                            <MemberSearch :member="policy_copy.premium_payer"/>
                                        </v-col>
                                        <v-col cols="12" sm="2">
                                            <v-tooltip bottom v-if="policy_copy.premium_payer.member === null">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        color="primary white--text"
                                                        fab
                                                        v-on="on"
                                                        @click="openCaptureEntityDialog('premium_payer')"
                                                    >
                                                        <v-icon>add</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Capture Entity</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </div>
                        <br>

                        <div
                            v-if="policy_copy.premium_payer.member !== null"
                            style="background:#cbcbcb; border:1px #aaaaaa solid; padding:10px; border-radius:10px;"
                        >
                            <h3>Basic Details</h3>
                            <v-row>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        v-model="policy_copy.premium_payer.name"
                                        color="secondary"
                                        label="Name"
                                        persistent-hint
                                        required
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        v-model="policy_copy.premium_payer.surname"
                                        color="secondary"
                                        label="Surname"
                                        persistent-hint
                                        required
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        v-model="policy_copy.premium_payer.phone"
                                        color="secondary"
                                        label="Phone"
                                        type="number"
                                        hint="e.g. 0721234567"
                                        persistent-hint
                                        required
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field
                                        v-model="policy_copy.premium_payer.email"
                                        color="secondary"
                                        label="Email"
                                        persistent-hint
                                        required
                                        disabled
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="5">
                                    <v-text-field
                                        v-model="policy_copy.premium_payer.id_number"
                                        type="number"
                                        color="secondary"
                                        label="ID Number"
                                        persistent-hint
                                        required
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="1">
                                    <template>
                                        <div>
                                            <span>Id Valid</span><br>
                                            <span v-if="policy_copy.premium_payer.id_number_valid === true"><v-icon color="success">mdi-check</v-icon></span>
                                            <span v-else><v-icon color="red">mdi-close</v-icon></span>
                                        </div>
                                    </template>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-text-field
                                        v-model="policy_copy.premium_payer.age"
                                        color="secondary"
                                        hint="Principal Member Age"
                                        persistent-hint
                                        required
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-text-field
                                        v-model="policy_copy.premium_payer.gender"
                                        color="secondary"
                                        hint="Gender"
                                        persistent-hint
                                        required
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-text-field
                                        v-model="policy_copy.premium_payer.cover_amount"
                                        prefix="R"
                                        color="secondary"
                                        hint="Payout Amount"
                                        persistent-hint
                                        required
                                        disabled
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </div>
                        <br>

                        <!-- Banking Details -->
                        <div
                            v-if="(
                                policy_copy.premium_payer.member !== null &&
                                policy_copy.payment_information.premium_payer.payment_method === 'DEBIT ORDER'
                            )"
                            style="background:#e9e9e9; border:1px #aaaaaa solid; padding:10px;"
                        >
                            <h3>Collection Banking Details</h3>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <v-radio-group v-model="policy_copy.premium_payer.collection_banking">
                                        <v-radio
                                            v-for="bankObj in policy_copy.premium_payer.member.bank_account_details.filter(bank => { return bank.deleted === false })"
                                            :key="bankObj._id"
                                            :label="`${bankObj.bank.name} - ${bankObj.account_number}`"
                                            :value="bankObj._id"
                                            color="secondary"
                                        ></v-radio>
                                    </v-radio-group>
                                    <h1
                                        style="color:red"
                                        v-if="policy_copy.premium_payer.member.bank_account_details.filter(bank => { return bank.deleted === false }).length === 0"
                                    >
                                        No Banking Details Captured
                                    </h1>
                                </v-col>
                            </v-row>
                        </div>
                        <br>

                        <!-- Physical Address -->
                        <div
                            v-if="policy_copy.premium_payer.member !== null"
                            style="background:#cbcbcb; border:1px #aaaaaa solid; padding:10px; border-radius:10px;"
                        >
                            <h3>Physical Address</h3>
                            <v-row>
                                <v-col cols="12" sm="4">
                                    <v-text-field
                                        v-model="policy_copy.premium_payer.physical_address.line1"
                                        color="secondary"
                                        label="Line 1"
                                        persistent-hint
                                        required
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-text-field
                                        v-model="policy_copy.premium_payer.physical_address.line2"
                                        color="secondary"
                                        label="Line 2"
                                        persistent-hint
                                        required
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-text-field
                                        v-model="policy_copy.premium_payer.physical_address.line3"
                                        color="secondary"
                                        label="Line 3"
                                        persistent-hint
                                        required
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-text-field
                                        v-model="policy_copy.premium_payer.physical_address.line4"
                                        type="number"
                                        color="secondary"
                                        label="Postal Code"
                                        persistent-hint
                                        required
                                        disabled
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </div>
                        <br>
                    </v-card-text>
                    <!-- Button Section -->
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            large
                            color="success"
                            :disabled="submitBtnDisabled()"
                            @click="savePolicy()"
                        >
                            <v-icon large>save</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
                <v-card>
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting Policy Details
                        <v-progress-linear
                            indeterminate
                            color="green"
                            class="mb-0"
                            height="5"
                            :active="submitting"
                        ></v-progress-linear>
                    </v-card-text>
                    <v-list flat v-if="submitting">
                        <v-subheader>Saving Policy Info</v-subheader>
                        <v-list-item-group color="primary">
                            <v-list-item
                                v-for="(item, i) in backendActionItems"
                                :key="i"
                            >
                                <v-list-item-icon>
                                    <v-icon>done</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title v-text="item"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </div>
        </v-dialog>
        <div v-if="policy_copy.premium_payer.member !== null">
            <ViewDocumentDialog
                v-if="policy_copy.premium_payer.id_doc.server_object !== null"
                :dialog="open_view_policy_document_dialog"
                :document="policy_copy.premium_payer.id_doc.server_object"
                param_path="entity"
                :param_id="policy_copy.premium_payer.member._id"
            />
        </div>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../../../utils/api";
import MemberSearch from "../components/member_search.vue";
import ViewDocumentDialog   from '../../../../../components/common_dialogs/view_document_info';
import { eventBus } from "../../../../../main";
import { idValid, extractBirthDate, extractGender } from "../../../../../utils/id_number_check";
import { calculateEntityAge } from "../../../../../utils/age";

import { businessProcessProspectPolicy } from "../gql/mutations";

export default {
    components: {
        MemberSearch,
        ViewDocumentDialog,
        // Signature,
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        policy_original: {
            type: Object,
            default: null
        },
        policy_copy: {
            type: Object,
            default: null
        },
    },
    data: () => ({
        strokeOptions: {
            size: 16,
            thinning: 0.75,
            smoothing: 0.5,
            streamline: 0.5,
        },

        open_view_policy_document_dialog: false,

        backendActionItems: [],

        status: 'DRAFT',
        server_response_obj: null,
        submitting: false,
    }),
    computed: {

    },
    methods: {
        closeDialog() {
            eventBus.$emit('CLOSE_EDIT_PREMIUM_PAYER_DIALOG', this.server_response_obj);
        },

        openViewDocumentDialog() {
            this.open_view_policy_document_dialog = true;
        },
        openViewServerDocumentDialog() {
            this.open_view_policy_document_dialog = false;
        },

        submitBtnDisabled() {
            if (this.policy_copy.premium_payer.member === null || this.policy_copy.premium_payer.member === undefined) {
                return true
            }

            if (this.policy_copy.payment_information.premium_payer.payment_method === 'DEBIT ORDER') {
                if (this.policy_copy.premium_payer.collection_banking === null) {
                    return true;
                }
                if (this.policy_copy.premium_payer.collection_banking === this.policy_original.premium_payer.collection_banking) {
                    return true
                }
            }

            return false;
        },

        resetForm() {
            this.submitting = false;
            this.server_response_obj = null;

            eventBus.$emit('RESET_EDIT_PREMIUM_PAYER_FORM', this.policy_copy);
        },

        async savePolicy() {
            this.submitting = true;
            
            // Prepare payload
            const extractedPayloadObj = this.extractPolicyPayload();
            
            // Then submit the policy
            try {
                const payloadObj = {
                    OP_TYPE: 'UPDATE',
                    _id: this.policy_copy._id,

                    ...extractedPayloadObj
                };

                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcessProspectPolicy,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.businessProcessProspectPolicy.errors === true) {
                    emitAlert(res.data.businessProcessProspectPolicy.message);
                    this.submitting = false;
                    return;
                }

                this.server_response_obj = res.data.businessProcessProspectPolicy.business_object;

                emitSuccess(res.data.businessProcessProspectPolicy.message);
                this.closeDialog();

                // Reset form
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessProspectPolicy.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },

        extractPolicyPayload() {
            // Prepare payload
            const payloadObj = {
                // Membership info
                premium_payer: {
                    entity: this.policy_copy.premium_payer.member._id,
                    collection_banking: this.policy_copy.premium_payer.collection_banking,
                },
            };

            return payloadObj;
        },

        extractIdMetaData() {
            const val = this.policy_copy.premium_payer.member === null ? '' : this.policy_copy.premium_payer.member.id_number;

            this.policy_copy.premium_payer.id_number_valid = false;

            // ---------------------------------------------------------
            // ---------------------- CHECK IF VALID -------------------
            // ---------------------------------------------------------
            if (val.length === 13) {
                const valid = idValid(val);

                if (valid) {
                    this.policy_copy.premium_payer.id_number_valid = true;
                }
                
                this.policy_copy.premium_payer.gender = extractGender(val);
            }

            // ---------------------------------------------------------
            // ----------------- CALCULATE AGE AND PAYOUT --------------
            // ---------------------------------------------------------
            if (val.length >= 6) {
                const birthDateObj = extractBirthDate(val);
                const memberAge = calculateEntityAge(birthDateObj.century_string + birthDateObj.partial_date_string);
                this.policy_copy.premium_payer.age = memberAge;

                if (this.policy_copy.product !== null) {
                    const benefitPayout = this.policy_copy.product.primary_product.payout_structure.filter(payoutObj => {
                        return (
                            payoutObj.category.membership_category === 'Principal Member' &&
                            memberAge >= payoutObj.category.start_age &&
                            memberAge <= payoutObj.category.end_age
                        );
                    });

                    if (benefitPayout[0])
                        this.policy_copy.premium_payer.cover_amount = benefitPayout[0].payout_amount;
                }
            }
            if (val.length < 6) {
                this.policy_copy.premium_payer.age = null;
                this.policy_copy.premium_payer.cover_amount = 0;
            }

            // ---------------------------------------------------------
            // ---------------------- EXTRACT GENDER -------------------
            // ---------------------------------------------------------
            if (val.length >= 10) {
                this.policy_copy.premium_payer.gender = extractGender(val);
            }
            if (val.length < 10) {
                this.policy_copy.premium_payer.gender = null;
            }
        },
    },
    async mounted() {
        eventBus.$on('CLOSE_VIEW_DOCUMENT_DIALOG', this.openViewServerDocumentDialog);
    },
    watch: {
        'policy_copy.premium_payer.member': {
            handler(serverEntity) {
                if (serverEntity !== null) {
                    // ---------------------------------------------------
                    // ---------------- BASIC DETAILS --------------------
                    // ---------------------------------------------------
                    this.policy_copy.premium_payer.name = serverEntity.name;
                    this.policy_copy.premium_payer.surname = serverEntity.surname;


                    // ---------------------------------------------------
                    // ---------------- CONTACT DETAILS ------------------
                    // ---------------------------------------------------
                    const serverEntityPhone = serverEntity.contact_details.filter(cont => {
                        return (
                            cont.type === 'Mobile' &&
                            cont.deleted === false &&
                            cont.primary === true
                        );
                    })[0];
                    const serverEntityEmail = serverEntity.contact_details.filter(cont => {
                        return (
                            cont.type === 'Email' &&
                            cont.deleted === false
                        );
                    })[0];

                    this.policy_copy.premium_payer.phone = serverEntityPhone === undefined ? '' : serverEntityPhone.details.replace(/\s/g, ''); // remove all spaces
                    this.policy_copy.premium_payer.email = serverEntityEmail === undefined ? '' : serverEntityEmail.details;
                    
                    this.policy_copy.premium_payer.id_number = null;
                    this.policy_copy.premium_payer.id_number = serverEntity.id_number;

                    // ---------------------------------------------------
                    // -------------------- ADDRESS ----------------------
                    // ---------------------------------------------------
                    const serverEntityAddress = serverEntity.addresses.filter(addr => {
                        return (
                            (
                                addr.type === 'Residential' ||
                                addr.type === 'Postal + Residential'
                            ) &&
                            addr.deleted === false
                        );
                    })[0];

                    if (serverEntityAddress !== undefined) {
                        this.policy_copy.premium_payer.physical_address.line1 = serverEntityAddress.line1;
                        this.policy_copy.premium_payer.physical_address.line2 = serverEntityAddress.line2;
                        this.policy_copy.premium_payer.physical_address.line3 = serverEntityAddress.line3;
                        this.policy_copy.premium_payer.physical_address.line4 = serverEntityAddress.line4;
                    } else {
                        this.policy_copy.premium_payer.physical_address.line1 = '';
                        this.policy_copy.premium_payer.physical_address.line2 = '';
                        this.policy_copy.premium_payer.physical_address.line3 = '';
                        this.policy_copy.premium_payer.physical_address.line4 = '';
                    }

                    // ---------------------------------------------------
                    // -------------------- ID DOC -----------------------
                    // ---------------------------------------------------
                    const serverEntityIDDoc = serverEntity.documents.filter(doc => {
                        return (
                            doc.type === 'ID Document' &&
                            doc.deleted === false
                        );
                    })[0];

                    this.policy_copy.premium_payer.id_doc = {};
                    this.policy_copy.premium_payer.id_doc.server_object = serverEntityIDDoc === undefined ? null : serverEntityIDDoc;
                } else {
                    this.policy_copy.premium_payer.name = '';
                    this.policy_copy.premium_payer.surname = '';

                    this.policy_copy.premium_payer.phone = '';
                    this.policy_copy.premium_payer.email = '';
                    this.policy_copy.premium_payer.id_number = '';

                    this.policy_copy.premium_payer.physical_address.line1 = '';
                    this.policy_copy.premium_payer.physical_address.line2 = '';
                    this.policy_copy.premium_payer.physical_address.line3 = '';
                    this.policy_copy.premium_payer.physical_address.line4 = '';

                    this.policy_copy.premium_payer.id_doc = {};
                    this.policy_copy.premium_payer.id_doc.server_object = null;
                }

                this.extractIdMetaData();
            },
            deep: true
        },
    },
}
</script>
<template>
    <v-app id="inspire">
        <v-main>
        <v-container
            class="fill-height"
            fluid
        >
            <!-- LOGIN FORM -->
            <v-row align="center" justify="center" v-if="system_initialized === true">
                <v-col cols="12" sm="6" md="6">
                    <v-row
                        justify="center"
                    >
                        <v-col
                            cols="12"
                            offset="2"
                            sm="6"
                            md="6"
                        >
                            <img width="70%" src="../../../public/afrosurance-logo.png"/>
                        </v-col>
                    </v-row>
                    <v-card class="elevation-6">
                        <v-toolbar
                            color="secondary"
                            dark
                            flat
                            class="text-center"
                        >
                            <v-toolbar-title style="text-align: center" class="text-center">Afrosurance Portal Login</v-toolbar-title>
                            <!-- <v-spacer></v-spacer> -->
                            <!-- <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                    :href="source"
                                    icon
                                    large
                                    target="_blank"
                                    v-on="on"
                                    >
                                    <v-icon>mdi-login</v-icon>
                                    </v-btn>
                                </template>
                                <span>Afrosurance</span>
                            </v-tooltip> -->
                        </v-toolbar>
                         <v-progress-linear
                            indeterminate
                            color="green"
                            class="mb-0"
                            height="5"
                            :active="submitting"
                        ></v-progress-linear>
                        <v-card-text>
                            <v-form>
                                <v-text-field
                                    v-model="username"
                                    label="Username"
                                    name="login"
                                    autocomplete="some-random-text"
                                    prepend-icon="mdi-account"
                                    type="text"
                                    color="secondary"
                                    @keydown="usernameStage1KeyDown"
                                ></v-text-field>

                                <v-select
                                    v-if="current_stage === stages.USER_PASSWORD || current_stage === stages.USER_REGISTRATION"
                                    v-model="selected_context"
                                    :items="context_list"
                                    color="secondary"
                                    item-text="name"
                                    item-value="_id"
                                    prepend-icon="mdi-bank"
                                    label="Selected Company"
                                    hint="This is the company you're logging in under"
                                    persistent-hint
                                    @change="loginContextSwitched"
                                    :disabled="context_list.length === 1"
                                    return-object
                                    required
                                ></v-select>
                                <!-- :disabled="context_list.length === 1" -->

                                <v-text-field
                                    v-if="current_stage === stages.USER_PASSWORD"
                                    v-model="user_password"
                                    prepend-icon="mdi-lock"
                                    :append-icon="show_password_login ? 'mdi-eye' : 'mdi-eye-off'"
                                    label="Password"
                                    autocomplete="some-random-text"
                                    :type="show_password_login ? 'text': 'password'"
                                    color="secondary"
                                    @click:append="show_password_login = !show_password_login"
                                    @keydown="passwordStage1LoginKeyDown"
                                    required
                                ></v-text-field>

                                <v-text-field
                                    v-if="current_stage === stages.USER_REGISTRATION"
                                    v-model="new_password"
                                    prepend-icon="mdi-lock"
                                    :append-icon="show_password_registration ? 'mdi-eye' : 'mdi-eye-off'"
                                    label="Password"
                                    autocomplete="some-random-text"
                                    :type="show_password_registration ? 'text': 'password'"
                                    color="secondary"
                                    :rules="user_password_rules"
                                    @click:append="show_password_registration = !show_password_registration"
                                    required
                                ></v-text-field>
                                <v-text-field
                                    v-if="current_stage === stages.USER_REGISTRATION"
                                    v-model="repeat_new_password"
                                    prepend-icon="mdi-lock"
                                    :append-icon="show_repeat_password_registration ? 'mdi-eye' : 'mdi-eye-off'"
                                    label="Password"
                                    autocomplete="some-random-text"
                                    :type="show_repeat_password_registration ? 'text': 'password'"
                                    color="secondary"
                                    :rules="user_password_rules"
                                    @click:append="show_repeat_password_registration = !show_repeat_password_registration"
                                    required
                                ></v-text-field>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn
                                v-if="current_stage === stages.STAGE1"
                                color="amber"
                                large
                                block
                                @click="submitStage1()"
                            >
                                Submit
                            </v-btn>
                            <v-btn
                                v-if="current_stage === stages.USER_PASSWORD"
                                color="amber"
                                large
                                block
                                @click="login()"
                            >
                                Login
                            </v-btn>
                            <v-btn
                                v-if="current_stage === stages.USER_REGISTRATION"
                                color="amber"
                                large
                                block
                                @click="submitRegistration()"
                            >
                                Register
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>


            <!-- SYSTEM INIT FROM -->
            <v-row align="center" justify="center" v-if="system_initialized === false">
                <v-col cols="12" sm="6" md="10">
                    <v-card class="elevation-6">
                        <v-stepper v-model="initStep">
                            <!-- Toolbar -->
                            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                                <v-toolbar-title>
                                    <img width="60px" src="../../../public/afrosurance-logo-no-text.png"/>
                                </v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-toolbar-title>Initialize System</v-toolbar-title>
                                <v-spacer></v-spacer>

                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            class="mx-2"
                                            color="amber black--text"
                                            small
                                            v-on="on"
                                            @click="resetForm()"
                                        >
                                            <v-icon>mdi-refresh</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Reset Form</span>
                                </v-tooltip>
                            </v-toolbar>
                            <v-stepper-header>
                                <v-stepper-step :complete="initStep > 1" step="1" color="success">Company Details</v-stepper-step>
                                <v-divider></v-divider>
                                <v-stepper-step :complete="initStep > 2" step="2" color="success">Director Details</v-stepper-step>
                                <v-divider></v-divider>
                                <v-stepper-step :complete="initStep > 3" step="3" color="success">Login Details</v-stepper-step>
                                <v-divider></v-divider>
                                <v-stepper-step step="6" color="success">Processing</v-stepper-step>
                            </v-stepper-header>
                        
                            <v-stepper-items>
                                <!-- Basic Details -->
                                <v-stepper-content step="1">
                                    <v-card>
                                        <v-card-text>
                                            <v-row style="background:#4490a045">
                                                <v-col cols="12" sm="12">
                                                    <h3>Company Basic Details</h3>
                                                </v-col>
                                            </v-row>
                                            <v-row style="background:#f6c20a40">
                                                <v-col cols="12" sm="6">
                                                    <v-text-field
                                                        v-model="bp_details.name"
                                                        :rules="name_rules"
                                                        label="Business Name"
                                                        autocomplete="some-random-text"
                                                        type="text"
                                                        color="secondary"
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" >
                                                    <v-text-field
                                                        v-model="bp_details.company_reg_number"
                                                        :rules="reg_number_rules"
                                                        label="Registration Number"
                                                        autocomplete="some-random-text"
                                                        type="text"
                                                        color="secondary"
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>


                                            <v-row style="background:#4490a045">
                                                <v-col cols="12" sm="12">
                                                    <h3>Physical Address</h3>
                                                </v-col>
                                            </v-row>
                                            <v-row
                                                style="background:#f6c20a40;"
                                            >
                                                <v-col cols="12" sm="4">
                                                    <v-text-field
                                                        v-model="bp_details.physical_address.line1"
                                                        label="Line 1"
                                                        autocomplete="some-random-text"
                                                        type="text"
                                                        color="secondary"
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="3">
                                                    <v-text-field
                                                        v-model="bp_details.physical_address.line2"
                                                        label="Line 2"
                                                        autocomplete="some-random-text"
                                                        type="text"
                                                        color="secondary"
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="3">
                                                    <v-text-field
                                                        v-model="bp_details.physical_address.line3"
                                                        label="Line 3"
                                                        autocomplete="some-random-text"
                                                        type="text"
                                                        color="secondary"
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="2">
                                                    <v-text-field
                                                        v-model="bp_details.physical_address.line4"
                                                        label="Postal Code"
                                                        autocomplete="some-random-text"
                                                        type="text"
                                                        color="secondary"
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>

                                            <v-row style="background:#4490a045">
                                                <v-col cols="12" sm="4">
                                                    <h3>Postal Address</h3>
                                                </v-col>
                                                <v-col cols="12" sm="6" style="padding: 0px">
                                                    <v-switch style="padding: 0px"
                                                        v-model="bp_details.same_address"
                                                        color="secondary"
                                                        label="Same As Physical Address"
                                                    ></v-switch>
                                                </v-col>
                                            </v-row>
                                            <v-row
                                                style="background:#f6c20a40;"
                                            >
                                                <v-col cols="12" sm="4">
                                                    <v-text-field
                                                        v-model="bp_details.postal_address.line1"
                                                        label="Line 1"
                                                        autocomplete="some-random-text"
                                                        type="text"
                                                        color="secondary"
                                                        required
                                                        :disabled="bp_details.same_address"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="3">
                                                    <v-text-field
                                                        v-model="bp_details.postal_address.line2"
                                                        label="Line 2"
                                                        autocomplete="some-random-text"
                                                        type="text"
                                                        color="secondary"
                                                        required
                                                        :disabled="bp_details.same_address"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="3">
                                                    <v-text-field
                                                        v-model="bp_details.postal_address.line3"
                                                        label="Line 3"
                                                        autocomplete="some-random-text"
                                                        type="text"
                                                        color="secondary"
                                                        required
                                                        :disabled="bp_details.same_address"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="2">
                                                    <v-text-field
                                                        v-model="bp_details.postal_address.line4"
                                                        label="Postal Code"
                                                        autocomplete="some-random-text"
                                                        type="text"
                                                        color="secondary"
                                                        required
                                                        :disabled="bp_details.same_address"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                        <!-- Button Section -->
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                fab
                                                color="amber"
                                                :disabled="step2BtnDisabled()"
                                                @click="moveToStep2()"
                                            >
                                                <v-icon large>keyboard_arrow_right</v-icon>
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-stepper-content>

                                <!-- Director Details Section -->
                                <v-stepper-content step="2">
                                    <v-card>
                                        <v-card-text>
                                            <v-row style="background:#4490a045">
                                                <v-col cols="12" sm="12">
                                                    <h3>Basic Details</h3>
                                                </v-col>
                                            </v-row>
                                            <v-row style="background:#f6c20a40">
                                                <v-col cols="12" sm="2">
                                                    <v-select
                                                        v-model="user_details.role"
                                                        :items="['DIRECTOR', 'CEO', 'CFO', 'CTO']"
                                                        label="Role"
                                                        color="secondary"
                                                        required
                                                    ></v-select>
                                                </v-col>
                                                <v-col cols="12" sm="4">
                                                    <v-text-field
                                                        v-model="user_details.name"
                                                        :rules="user_name_rules"
                                                        label="First Name(s)"
                                                        autocomplete="some-random-text"
                                                        type="text"
                                                        color="secondary"
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="3" >
                                                    <v-text-field
                                                        v-model="user_details.surname"
                                                        :rules="user_surname_rules"
                                                        label="Surname"
                                                        autocomplete="some-random-text"
                                                        type="text"
                                                        color="secondary"
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="3">
                                                    <v-select
                                                        v-model="user_details.marital_status"
                                                        :items="marital_statuses"
                                                        label="Martial Status"
                                                        color="secondary"
                                                        required
                                                    ></v-select>
                                                </v-col>
                                                <v-col cols="12" sm="3" >
                                                    <v-text-field
                                                        v-model="id_number_field"
                                                        :rules="user_id_number_rules"
                                                        label="ID Number"
                                                        autocomplete="some-random-text"
                                                        type="number"
                                                        color="secondary"
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="2">
                                                    <v-text-field
                                                        v-model="id_number_field_valid"
                                                        label="ID Number Valid"
                                                        disabled
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="2">
                                                    <v-select
                                                        v-model="birth_date_century_field"
                                                        :items="['19', '20']"
                                                        label="Birth Century"
                                                        disabled
                                                        required
                                                    ></v-select>
                                                </v-col>
                                                <v-col cols="12" sm="2">
                                                    <v-text-field
                                                        v-model="partial_birth_date_field"
                                                        label="Birth Date"
                                                        disabled
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="3" >
                                                    <v-select
                                                        v-model="user_details.gender"
                                                        :items="entity_gender"
                                                        label="Gender"
                                                        disabled
                                                        required
                                                    ></v-select>
                                                </v-col>
                                            </v-row>
                                            <v-row style="background:#4490a045">
                                                <v-col cols="12" sm="12">
                                                    <h3>Contact Details</h3>
                                                </v-col>
                                            </v-row>
                                            <v-row
                                                style="background:#f6c20a40; padding-bottom:10px"
                                            >
                                                <v-col cols="12" sm="4">
                                                    <v-text-field
                                                        v-model="user_details.mobile_number"
                                                        label="Mobile Number"
                                                        autocomplete="some-random-text"
                                                        type="text"
                                                        color="secondary"
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="4">
                                                    <v-text-field
                                                        v-model="user_details.landline_number"
                                                        label="Landline"
                                                        autocomplete="some-random-text"
                                                        type="text"
                                                        color="secondary"
                                                        hint="Optional"
                                                        persistent-hint
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="4">
                                                    <v-text-field
                                                        v-model="user_details.email"
                                                        label="Email Address"
                                                        autocomplete="some-random-text"
                                                        type="text"
                                                        color="secondary"
                                                        hint="Optional"
                                                        persistent-hint
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                        <!-- Button Section -->
                                        <v-card-actions>
                                            <v-btn
                                                fab
                                                color="amber"
                                                @click="initStep = 1"
                                            >
                                                <v-icon large>keyboard_arrow_right</v-icon>
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                fab
                                                color="amber"
                                                :disabled="step3BtnDisabled()"
                                                @click="moveToStep3()"
                                            >
                                                <v-icon large>keyboard_arrow_right</v-icon>
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-stepper-content>

                                <!-- Login Details Section -->
                                <v-stepper-content step="3">
                                    <v-card>
                                        <v-card-text>
                                            <v-row style="background:#4490a045">
                                                <v-col cols="12" sm="12">
                                                    <h3>Amin Login Details</h3>
                                                </v-col>
                                            </v-row>
                                            <v-row style="background:#f6c20a40">
                                                <v-col cols="12" sm="6" align-self="start">
                                                    <v-text-field
                                                        v-model="id_number_field"
                                                        prepend-icon="mdi-account"
                                                        label="Username"
                                                        autocomplete="some-random-text"
                                                        type="text"
                                                        color="secondary"
                                                        hint="Your ID number will be your username"
                                                        persistent-hint
                                                        disabled
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" align-self="start">
                                                    <v-text-field
                                                        v-model="user_details.password"
                                                        prepend-icon="mdi-lock"
                                                        :append-icon="show_password_init ? 'mdi-eye' : 'mdi-eye-off'"
                                                        label="Password"
                                                        autocomplete="some-random-text"
                                                        :type="show_password_init ? 'text': 'password'"
                                                        color="secondary"
                                                        :rules="user_password_rules"
                                                        @click:append="show_password_init = !show_password_init"
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                        <!-- Button Section -->
                                        <v-card-actions>
                                            <v-btn
                                                fab
                                                color="amber"
                                                @click="initStep = 2"
                                            >
                                                <v-icon large>keyboard_arrow_left</v-icon>
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                color="success"
                                                :disabled="submitBtnDisabled()"
                                                @click="submitBusinessDetails()"
                                            >
                                                <v-icon large>save</v-icon>
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-stepper-content>

                                <!-- Submission Results Section -->
                                <v-stepper-content step="4">
                                    <v-card light >
                                        <v-card-text class="text-center">
                                            <v-progress-linear
                                                color="green"
                                                class="mb-0"
                                                height="8"
                                                indeterminate
                                                :active="submitting"
                                            ></v-progress-linear>
                                        </v-card-text>
                                        <v-list flat v-if="submitting">
                                            <v-subheader>Saving Company Info</v-subheader>
                                            <v-list-item-group color="primary">
                                                <v-list-item
                                                    v-for="(item, i) in backendActionItems"
                                                    :key="i"
                                                >
                                                    <v-list-item-icon>
                                                        <v-icon>done</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{ item }}</v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list-item-group>
                                        </v-list>
                                        <v-card-text class="text-center" v-if="!submitting">
                                            <v-row>
                                                <v-col cols="12" sm="12" align-self="start">
                                                    <h4>The system was successfully initialized.</h4>
                                                    <h4>Please click the button below to procceed to login</h4>
                                                </v-col>
                                                <v-col cols="12" sm="6" offset-sm="3">
                                                    <v-btn
                                                        class="mx-2"
                                                        color="success white--text"
                                                        large
                                                        @click="proceedToLogin()"
                                                    >
                                                        Proceed To Login
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        </v-main>

        <RegSuccessDialog
            :dialog="open_registration_success_dialog"
        />

        <v-overlay :value="system_initialized === null">
            <v-progress-circular
                color="amber"
                indeterminate
                size="150"
                width="9"
            >
                <h3 class="text-center" style="color:white">Loading...</h3>
            </v-progress-circular>
        </v-overlay>
    </v-app>
</template>

<script>
import RegSuccessDialog from "./dialogs/registration_success";
import { idValid, extractBirthDate, extractGender } from "../../utils/id_number_check";
import { initializeSystem } from "../../utils/auth";
import { authSystemInit, authMe } from "./gql/queries";
import { authLoginStage1, authLogin, authRegisterUser } from "./gql/mutations";
import { eventBus } from "../../main";
import { store } from '../../../store';
import { emitAlert, emitSuccess, /*getLoggedInUser*/ } from '../../utils/api';
import {
    entity_gender,
    marital_statuses
} from "../../../config";

export default {
    components: {
        RegSuccessDialog
    },

    data: () => ({
        /**
         * LOGIN FORM
         */
        system_initialized: null,
        submitting: false,
        username: null,
        user_password: null,

        show_password_login: false,
        show_password_registration: false,
        show_repeat_password_registration: false,
        new_password: '',
        repeat_new_password: '',

        current_stage: 'STAGE1',

        stages: {
            STAGE1: 'STAGE1',
            USER_PASSWORD: 'USER_PASSWORD',
            USER_REGISTRATION: 'USER_REGISTRATION'
        },

        open_registration_success_dialog: false,

        selected_context: null,
        context_list: [],


        /**
         * BP - SYSTEM BOOTSTRAPPING
         */
        backendActionItems: [],
        id_number_field: '',
        id_number_field_valid: 'NO',
        partial_birth_date_field: null,
        birth_date_century_field: null,

        show_password_init: false,


        initStep: 1,
        bp_details: {
            name: '',
            company_reg_number: '',

            same_address: false,
            physical_address: {
                line1: null,
                line2: null,
                line3: null,
                line4: null
            },
            postal_address: {
                line1: null,
                line2: null,
                line3: null,
                line4: null
            },
        },
        user_details: {
            role: null,
            name: '',
            surname: '',
            id_number: '',
            gender: null,
            marital_status: null,
            
            mobile_number: null,
            landline_number: null,
            email: null,

            password: ''
        },
        name_rules: [
            v => !!v || 'Business name is required',
            v => v.trim().length >= 2 || 'Min 2 characters'
        ],
        reg_number_rules: [
            v => !!v || 'Business registration number is required',
            v => v.trim().length >= 10 || 'Min 10 characters'
        ],

        user_name_rules: [
            v => !!v || 'Name is required',
            v => v.trim().length >= 2 || 'Min 2 characters'
        ],
        user_surname_rules: [
            v => !!v || 'Surname is required',
            v => v.trim().length >= 2 || 'Min 2 characters'
        ],
        user_id_number_rules: [
            v => !!v || 'ID number is required',
            v => v.trim().length === 13 || '13 characters required'
        ],

        user_password_rules: [
            v => !!v || 'Password is required',
            v => v.trim().length >= 6 || 'Min 6 characters required'
        ],

        entity_gender: entity_gender,
        marital_statuses: marital_statuses,
    }),

    methods: {
        /**
         * LOGIN FORM
         */



        closeRegistrationSuccessDialog() {
            this.open_registration_success_dialog = false;
            this.resetLoginForm();
        },
        resetLoginForm() {
            this.username = null;
            this.user_password = null;
            
            this.new_password = null;
            this.repeat_new_password = null;
            
            this.current_stage = 'STAGE1';
        },

        usernameStage1KeyDown(event) {
            if (event.keyCode === 13) {
                // Cancel the default action, if needed
                event.preventDefault();
                // Trigger the button element with a click
                this.submitStage1();
            }
        },
        passwordStage1LoginKeyDown(event) {
            if (event.keyCode === 13) {
                // Cancel the default action, if needed
                event.preventDefault();
                // Trigger the button element with a click
                this.login();
            }
        },

        async submitStage1() {
            if (this.username === null || this.username === undefined) {
                emitAlert('Please enter your username');
                return;
            }

            if (this.username.trim() === '') {
                emitAlert('Please enter your username');
                return;
            }

            this.submitting = true;
            const stage1Res = await this.$apollo
                                            .mutate({
                                                mutation: authLoginStage1,
                                                variables: {
                                                    username: this.username
                                                },
                                            });

            this.submitting = false;
            if (stage1Res.data.authLoginStage1.errors === true) {
                emitAlert(stage1Res.data.authLoginStage1.message);
                return;
            } else {
                // console.log(stage1Res)
                if (stage1Res.data.authLoginStage1.existing_user) {
                    for (const user of stage1Res.data.authLoginStage1.users) {
                        this.context_list.push(user.business_partner);
                    }

                    // Default the selected context to the 1st user in the list
                    this.selected_context = stage1Res.data.authLoginStage1.users[0].business_partner;

                    // Set login stage
                    if (stage1Res.data.authLoginStage1.users[0].new_login === true) {
                        this.current_stage = this.stages.USER_REGISTRATION;
                    } else {
                        this.current_stage = this.stages.USER_PASSWORD;
                    }
                }
                
            }
        },

        async submitRegistration() {
            let errorOccured = false;

            if (
                (this.new_password === null ||this.new_password === undefined) &&
                (this.repeat_new_password === null || this.repeat_new_password === undefined)
            ) {
                errorOccured = true;
                emitAlert('Please make sure that you\'ve captured both of the password fields');
                return;
            }

            if (this.new_password !== this.repeat_new_password) {
                errorOccured = true;
                emitAlert('Please ensure that both the passwords are the same');
                return;
            }

            if (errorOccured === false) {
                this.submitting = true;
                // const registrationRes = await registerUser(this.username, this.new_password);
                const registrationRes = await this.$apollo
                                            .mutate({
                                                mutation: authRegisterUser,
                                                variables: {
                                                    input: {
                                                        username: this.username,
                                                        password: this.new_password
                                                    }
                                                },
                                            });
                this.submitting = false;
                if (registrationRes.data.authRegisterUser.errors === true) {
                    emitAlert(registrationRes.data.authRegisterUser.message);
                    return
                } else {
                    if (registrationRes.data.authRegisterUser.successful) {
                        this.open_registration_success_dialog = true;
                    }
                }
            }
        },

        async login() {
            let errorOccured = false;
            if (
                (this.username === null || this.username === undefined) ||
                (this.user_password === null || this.user_password === undefined)
            ) {
                errorOccured = true;
                emitAlert('Please ensure that you\'ve captured both the username and password');
                return;
            }

            if (
                (this.username.trim() === '' || this.username.trim() === '') ||
                (this.user_password.trim() === '' || this.user_password.trim() === '')
            ) {
                errorOccured = true;
                emitAlert('Please ensure that you\'ve captured both the username and password');
                return;
            }

            if (errorOccured === false) {
                // const loginRes = await userLogin(this.username, this.user_password, this.selected_context._id);

                const loginRes = await this.$apollo
                                            .mutate({
                                                mutation: authLogin,
                                                variables: {
                                                    input: {
                                                        username: this.username,
                                                        password: this.user_password,
                                                        context: this.selected_context._id,
                                                    }
                                                },
                                            });

                if (loginRes.data.authLogin.errors === true) {
                    emitAlert(loginRes.data.authLogin.message);
                } else {
                    if (loginRes.data.authLogin.token === null) {
                        emitAlert('Your password was incorrect');
                    } else {
                        store.commit('update_token', loginRes.data.authLogin.token);

                        // Temporarilly set context list - This is needed for when retrieving the logged-in-user
                        store.commit('update_context_list', [{
                            selected: true,
                            business_partner: this.selected_context
                        }]);
                        
                        // Get user logged-in
                        // const userRes = await getLoggedInUser();

                        const userRes = await this.$apollo
                                            .query({
                                                query: authMe,
                                                // fetchPolicy: 'network-only',
                                                fetchPolicy: 'no-cache',
                                                errorPolicy: 'all',
                                            });

                        const userAccount = userRes.data.authMe.user;
                        
                        store.commit('update_user', userAccount);
                        // Set ctx list
                        let ctx_list = []
                        if (userRes.data.authMe.context_list.length > 0) {
                            ctx_list = userRes.data.authMe.context_list;
                        }
                        store.commit('update_context_list', ctx_list);

                        // Set report download map
                        const user_premitted_reports = userAccount.roles[0].role.allowed_reports.map(allowed_report => {
                            return allowed_report.report;
                        });
                        const REPORT_DOWNLOAD_PROGRESS_MAP = {};

                        user_premitted_reports.forEach(report => {
                            REPORT_DOWNLOAD_PROGRESS_MAP[ report.report_number ] = {
                                downloading: false,
                                download_progress: 0,
                                downloaded_file: null
                            };
                        });

                        store.commit('update_report_download_progress_map', REPORT_DOWNLOAD_PROGRESS_MAP);


                        // Set process progress map
                        const user_premitted_business_processes = userAccount.roles[0].role.allowed_business_processes.map(allowed_business_process => {
                            return allowed_business_process.process;
                        });
                        const BUSINESS_PROCESS_PROGRESS_MAP = {};

                        user_premitted_business_processes.forEach(process => {
                            BUSINESS_PROCESS_PROGRESS_MAP[ process.process_number ] = {
                                downloading: false,
                                download_progress: 0,
                                downloaded_file: null
                            };
                        });

                        store.commit('update_business_process_progress_map', BUSINESS_PROCESS_PROGRESS_MAP);

                        window.location.replace('/');
                    }
                }
            }
        },






        /**
         * BP - SYSTEM BOOTSTRAPPING
         */


        
        resetForm() {
            this.backendActionItems = [];
            this.id_number_field = '';
            this.id_number_field_valid = 'NO';
            this.partial_birth_date_field = null;
            this.birth_date_century_field = null;


            this.initStep = 1;
            this.bp_details = {
                name: '',
                company_reg_number: '',

                same_address: false,
                physical_address: {
                    line1: null,
                    line2: null,
                    line3: null,
                    line4: null
                },
                postal_address: {
                    line1: null,
                    line2: null,
                    line3: null,
                    line4: null
                },
            };
            this.user_details = {
                role: null,
                name: '',
                surname: '',
                id_number: '',
                gender: null,
                marital_status: null,
                
                mobile_number: null,
                landline_number: null,
                email: null,

                password: ''
            };
        },
        step2BtnDisabled() {
            if (this.bp_details.name.trim().length < 2) {
                return true;
            }
            if (this.bp_details.company_reg_number.trim().length < 10) {
                return true;
            }

            if (
                this.bp_details.physical_address.line1 === null &&
                this.bp_details.physical_address.line2 === null &&
                this.bp_details.physical_address.line3 === null &&
                this.bp_details.physical_address.line4 === null
            ) {
                return true;
            }

            // Address Line 1
            if (this.bp_details.physical_address.line1 !== null) {
                if (typeof(this.bp_details.physical_address.line1) === 'string') {
                    if (this.bp_details.physical_address.line1.trim() === '') {
                        this.bp_details.physical_address.line1 = null;
                        return true;
                    }
                }
            }
            // Address Line 2
            if (this.bp_details.physical_address.line2 !== null) {
                if (typeof(this.bp_details.physical_address.line2) === 'string') {
                    if (this.bp_details.physical_address.line2.trim() === '') {
                        this.bp_details.physical_address.line2 = null;
                        return true;
                    }
                }
            }
            // Address Line 3
            if (this.bp_details.physical_address.line3 !== null) {
                if (typeof(this.bp_details.physical_address.line3) === 'string') {
                    if (this.bp_details.physical_address.line3.trim() === '') {
                        this.bp_details.physical_address.line3 = null;
                        return true;
                    }
                }
            }
            // Address Line 4
            if (this.bp_details.physical_address.line4 !== null) {
                if (typeof(this.bp_details.physical_address.line4) === 'string') {
                    if (this.bp_details.physical_address.line4.trim() === '') {
                        this.bp_details.physical_address.line4 = null;
                        return true;
                    }
                }
            }

            return false;
        },
        step3BtnDisabled() {
            if (this.user_details.role === null) {
                return true;
            }
            if (this.user_details.name.trim().length < 2) {
                return true;
            }
            if (this.user_details.surname.trim().length < 2) {
                return true;
            }
            if (this.user_details.marital_status === null) {
                return true;
            }

            // ID Number 
            if (this.id_number_field_valid === 'NO') {
                return true;
            }

            // Contact details
            if (this.user_details.mobile_number === null) {
                return true;
            }
            if (typeof(this.user_details.mobile_number) === 'string') {
                if (this.user_details.mobile_number.trim() === '') {
                    this.user_details.mobile_number = null;
                    return true;
                }
            }

            return false;
        },
        submitBtnDisabled() {
            if (this.user_details.password.length < 6) {
                return true;
            }

            return false;
        },
        moveToStep2() {
            this.initStep = 2;
        },
        moveToStep3() {
            this.initStep = 3;
        },

        async submitBusinessDetails() {
            this.initStep = 4;

            this.submitting = true;

            this.backendActionItems.push("Saving business info");

            const businessPartnerObj = this.extractBusinessPartnerInfo();

            try {
                const res = await initializeSystem(businessPartnerObj);
                if (res.errors === true) {
                    emitAlert(res.message);
                    this.submitting = false;
                    this.backendActionItems = [];
                } else {
                    this.submitting = false;
                    emitSuccess(res.message);
                    // this.resetForm();
                }
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request')
                }
                
                return;
            }
        },
        extractBusinessPartnerInfo() {
            const businessPartnerObj = {
                name: null, 
                company_reg_number: null,
                type: null,
                administration_option: null,
                similar_addresses: null,

                // Addresses
                physical_address: {
                    line1: null,
                    line2: null,
                    line3: null,
                    line4: null
                },
                postal_address: {
                    line1: null,
                    line2: null,
                    line3: null,
                    line4: null
                },

                // Director
                director: {
                    role: null,
                    name: null,
                    surname: null,
                    marital_status: null,
                    id_number: null,
                    gender: null,
                    date_of_birth: null,

                    mobile_number: null,
                    landline_number: null,
                    email: null
                },
                login_details: {
                    password: null
                }
            };

            businessPartnerObj.name = this.bp_details.name;
            businessPartnerObj.company_reg_number = this.bp_details.company_reg_number;
            businessPartnerObj.type = 'CENTRAL_UNDERWRITER';
            businessPartnerObj.administration_option = 'NOT_APPLICABLE';

            // ----------------------------------Extract director details
            businessPartnerObj.director.role            = this.user_details.role;
            businessPartnerObj.director.name            = this.user_details.name;
            businessPartnerObj.director.surname         = this.user_details.surname;
            businessPartnerObj.director.marital_status  = this.user_details.marital_status;
            businessPartnerObj.director.id_number       = this.id_number_field;
            businessPartnerObj.director.gender          = this.user_details.gender;
            businessPartnerObj.director.date_of_birth   = this.birth_date_century_field + this.partial_birth_date_field;
            businessPartnerObj.director.mobile_number   = this.user_details.mobile_number;
            businessPartnerObj.director.landline_number = this.user_details.landline_number;
            businessPartnerObj.director.email           = this.user_details.email;
            
            businessPartnerObj.login_details.password   = this.user_details.password;

            // Address
            businessPartnerObj.similar_addresses        = this.bp_details.same_address;

            businessPartnerObj.physical_address.line1   = this.bp_details.physical_address.line1;
            businessPartnerObj.physical_address.line2   = this.bp_details.physical_address.line2;
            businessPartnerObj.physical_address.line3   = this.bp_details.physical_address.line3;
            businessPartnerObj.physical_address.line4   = this.bp_details.physical_address.line4;

            businessPartnerObj.postal_address.line1     = this.bp_details.postal_address.line1;
            businessPartnerObj.postal_address.line2     = this.bp_details.postal_address.line2;
            businessPartnerObj.postal_address.line3     = this.bp_details.postal_address.line3;
            businessPartnerObj.postal_address.line4     = this.bp_details.postal_address.line4;

            return businessPartnerObj;
        },

        proceedToLogin() {
            this.system_initialized = true;
            this.resetForm();
        },

        loginContextSwitched(content) {
            this.selected_context = content;
        }
    },
    watch: {
        "bp_details.physical_address.line1": function(val) {
            if (this.bp_details.same_address) {
                this.bp_details.postal_address.line1 = val;
            } else {
                this.bp_details.postal_address.line1 = null;
            }
        },
        "bp_details.physical_address.line2": function(val) {
            if (this.bp_details.same_address) {
                this.bp_details.postal_address.line2 = val;
            } else {
                this.bp_details.postal_address.line2 = null;
            }
        },
        "bp_details.physical_address.line3": function(val) {
            if (this.bp_details.same_address) {
                this.bp_details.postal_address.line3 = val;
            } else {
                this.bp_details.postal_address.line3 = null;
            }
        },
        "bp_details.physical_address.line4": function(val) {
            if (this.bp_details.same_address) {
                this.bp_details.postal_address.line4 = val;
            } else {
                this.bp_details.postal_address.line4 = null;
            }
        },


        "bp_details.same_address": function(val) {
            if (val === true) {
                // copy currently set values
                this.bp_details.postal_address.line1 = this.bp_details.physical_address.line1;
                this.bp_details.postal_address.line2 = this.bp_details.physical_address.line2;
                this.bp_details.postal_address.line3 = this.bp_details.physical_address.line3;
                this.bp_details.postal_address.line4 = this.bp_details.physical_address.line4;
            } else {
                // Reset
                this.bp_details.postal_address.line1 = null;
                this.bp_details.postal_address.line2 = null;
                this.bp_details.postal_address.line3 = null;
                this.bp_details.postal_address.line4 = null;
            }
        },

        id_number_field: function(val) {
            this.id_number_field_valid = 'NO';

            // Check if valid
            if (val.length === 13) {
                const valid = idValid(val);

                if (valid) {
                    this.id_number_field_valid = 'YES';
                }

                // The following is to cater for events when the user just copies, and pastes the whole id fiel
                // if (this.partial_birth_date_field === null && this.birth_date_century_field === null) {
                    const birthDate = extractBirthDate(val);
                    
                    this.partial_birth_date_field = birthDate.partial_date_string;
                    this.birth_date_century_field = birthDate.century_string;
                // }

                // if (this.user_details.gender === null) {
                    this.user_details.gender = extractGender(val);
                // }
            }

            // Extract Birth date
            if (val.length === 6) {
                const birthDate = extractBirthDate(val);
                this.partial_birth_date_field = birthDate.partial_date_string;
                this.birth_date_century_field = birthDate.century_string;
            }

            if (val.length < 6) {
                this.partial_birth_date_field = null;
                this.birth_date_century_field = null;
            }

            // extract gender
            if (val.length === 10) {
                this.user_details.gender = extractGender(val);
            }

            if (val.length < 10) {
                this.user_details.gender = null;
            }
        }
    },
    async mounted() {
        eventBus.$on('CLOSE_REG_SUCCESS_DIALOG', this.closeRegistrationSuccessDialog);

        const systeInitCheckRes = await this.$apollo
                                            .query({
                                                query: authSystemInit,
                                                // fetchPolicy: 'network-only',
                                                fetchPolicy: 'no-cache',
                                                errorPolicy: 'all',
                                                // variables: {},
                                            });
        // const systeInitCheckRes = await systemInitialized(); authSystemInit
        
        if (systeInitCheckRes.data.authSystemInit.initialized === true) {
            this.system_initialized = true;
        } else {
            this.system_initialized = false;
        }
    }
  }
</script>
<template>
    <div>
        <v-toolbar
            color="secondary"
            dark
            class="pa-0"
            height="50"
            extension-height="3"
        >
            <v-row>
                <!-- Grid Buttons -->
                <v-col sm="8" style="margin-top:10px">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="refreshDataTable()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh</span>
                    </v-tooltip>

                    <v-tooltip v-if="selectedPayoutCat != null">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="openEditPayoutCatDialog()"
                            >
                                <v-icon>edit</v-icon>
                            </v-btn>
                        </template>
                        <span>Edit Record</span>
                    </v-tooltip>
                </v-col>


                <!-- Action Buttons -->
                <v-col sm="4" style="margin-top:10px">
                    <!-- <v-spacer></v-spacer> -->
                    <div class="text-right">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="openAddPayoutCatDialog()"
                                    v-on="on"
                                >
                                    <v-icon medium>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Payout Age Category</span>
                        </v-tooltip>
                    </div>
                </v-col>
            </v-row>
        </v-toolbar>
        <v-data-table
            fixed-header
            height="600"
            :headers="payout_age_cat_headers"
            :items="payout_age_cat_list"
            item-key="_id"
            class="elevation-1"
            :single-select="true"
            @click:row="selectMainTableRow"
            item-class="secondary"
            hide-default-footer
            :disable-pagination="true"
            :loading="data_table_loading"
            loading-text="Loading... Please wait"
        ></v-data-table>
        <AddPayoutAgeCatDialog
            :dialog="open_add_payout_cat_dialog"
        />
        <EditPayoutAgeCatDialog
            v-if="selectedPayoutCat !== null"
            :dialog="open_edit_payout_cat_dialog"
            :selected_age_cat="selectedPayoutCat"
            :selected_age_cat_copy="selectedPayoutCatCopy"
        />
    </div>
</template>
<script>
import { emitAlert } from '../../../utils/api';
import AddPayoutAgeCatDialog from './dialogs/add_payout_age_category';
import EditPayoutAgeCatDialog from './dialogs/edit_payout_age_category';
import { eventBus } from "../../../main";

import { getPayoutAgeCategories } from "./gql/queries";

export default {
    components: {
        // Multipane,
        AddPayoutAgeCatDialog,
        EditPayoutAgeCatDialog,
    },

    data: () => ({
        selectedPayoutCat: null,
        selectedPayoutCatCopy: null,
        selectedPayoutCatMetaData: null,
        selectedRow: null,
        data_table_loading: false,

        payout_age_cat_headers: [
            { text: 'Name', value: 'name' },
            { text: 'Membership Category', value: 'membership_category' },
            { text: 'Start Age (Years)', value: 'start_age' },
            { text: 'End Age (End Age)', value: 'end_age' },
        ],
        
        // Table data models
        payout_age_cat_list: [],

        // Dialogs
        open_add_payout_cat_dialog: false,
        open_edit_payout_cat_dialog: false,
    }),

    methods: {
        //-------------------- OPEN DIALOGS ---------------------//
        openAddPayoutCatDialog() {
            this.open_add_payout_cat_dialog = true;
        },
        openEditPayoutCatDialog() {
            this.open_edit_payout_cat_dialog = true;
        },

        //------------------- CLOSE DIALOGS---------------------//
        closeAddPayoutAgeCatDialog(payoutCatObj) {
            if (payoutCatObj !== null) {
                this.payout_age_cat_list.push(payoutCatObj);
            }
            
            this.open_add_payout_cat_dialog = false;
            // this.selectedPayoutCat = null;
            // this.selectedPayoutCatMetaData = null;
        },
        closeEditPayoutAgeCatDialog(payoutCatObj) {
            if (payoutCatObj !== null) {
                const obj_index = this.payout_age_cat_list.map(obj => { return obj._id; }).indexOf(payoutCatObj._id)
                this.payout_age_cat_list[obj_index] = payoutCatObj;
                
                 // Deep copy members for refreshing main data table
                const temp_arr = Array.from(this.payout_age_cat_list);
                this.payout_age_cat_list = temp_arr;

                this.selectedPayoutCat = payoutCatObj;
            }
            
            this.open_edit_payout_cat_dialog = false;
            // this.selectedPayoutCat = null;
            // this.selectedPayoutCatMetaData = null;
        },
        selectMainTableRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);

                this.selectedPayoutCat = item;
                this.selectedPayoutCatMetaData = item_metadata;

                // Set copy
                this.selectedPayoutCatCopy = Object.assign({}, this.selectedPayoutCat);
            } else {
                this.selectedPayoutCat = null;
                this.selectedPayoutCatCopy = null;
                this.selectedPayoutCatMetaData = null;
                // Unighlight selected row
                item_metadata.select(false);
            }
        },
        async refreshDataTable() {
            this.data_table_loading = true;
            this.payout_age_cat_list = [];
            this.selectedPayoutCat = null;
            // Unselect selected row
            if (this.selectedPayoutCatMetaData !== null) {
                this.selectedPayoutCatMetaData.select(false);
            }

            try {
                const res = await this.$apollo
                                    .query({
                                        query: getPayoutAgeCategories,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'PAYOUT_CATEGORY',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }

                this.data_table_loading = false;
                this.payout_age_cat_list = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        resetPayoutAgeCategoryForm(updatedPayoutAgeCat) {
            if (
                updatedPayoutAgeCat.name !== this.selectedPayoutCat.name ||
                updatedPayoutAgeCat.membership_category !== this.selectedPayoutCat.membership_category ||
                updatedPayoutAgeCat.start_age !== this.selectedPayoutCat.start_age ||
                updatedPayoutAgeCat.end_age !== this.selectedPayoutCat.end_age
            ) {
                this.selectedPayoutCatCopy = Object.assign({}, this.selectedPayoutCat);
            }
        },
    },

    async mounted() {
        eventBus.$on('CLOSE_ADD_PAYOUT_AGE_CAT_DIALOG', this.closeAddPayoutAgeCatDialog);
        eventBus.$on('CLOSE_EDIT_PAYOUT_AGE_CAT_DIALOG', this.closeEditPayoutAgeCatDialog);
        eventBus.$on('RESET_PAYOUT_AGE_CATEGORY_EDIT_FORM', this.resetPayoutAgeCategoryForm);


        this.data_table_loading = true;

        try {
            const res = await this.$apollo
                                    .query({
                                        query: getPayoutAgeCategories,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'PAYOUT_CATEGORY',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.data_table_loading = false;
                return;
            }

            this.data_table_loading = false;
            this.payout_age_cat_list = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.data_table_loading = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
        }
    },

    watch: {
        
    }
}
</script>
<style scoped>
/* Overriding of textbox min height */
 .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
}
</style>
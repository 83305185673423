import gql from 'graphql-tag'

export const getBusinessPartners = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on BusinessPartner {
                    __typename
                    _id
                    bp_number

                    name
                    company_reg_number
                    type
                    status
                    administration_option

                    version_history {
                        _id
                        changes {
                            type
                            description
                            old
                            new
                            captured_by {
                                __typename
                                _id
                                entity {
                                    __typename
                                    _id
                                    name
                                    surname
                                }
                            }
                            created
                        }
                    }

                    vas_services {
                        _id
                        fee_amount
                        fee_billing_model
                        vas_service {
                            _id
                            name
                            # category {
                            #     _id
                            #     name
                            # }
                            description
                        }

                        captured_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        created

                        deleted
                        delete_date
                        deleted_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }
                    fee_structure {
                        _id
                        fee_item {
                            _id
                            name
                            description
                            captured_by {
                                _id
                                entity {
                                    _id
                                    name
                                    surname
                                }
                            }
                            created
                        }
                        payment_option
                        amount
                        captured_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }
                    addresses {
                        _id
                        type
                        line1
                        line2
                        line3
                        line4
                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }
                    contact_people {
                        _id
                        contact_person {
                            _id
                            name
                            surname
                            id_number
                        }
                        role

                        captured_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }

                    documents {
                        _id
                        type
                        mime_type
                        name

                        start_date
                        end_date
                        approved
                        approved_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }

                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }

                    bank_account_details {
                        _id
                        purpose
                        bank {
                            _id
                            name
                            universal_branch_code
                        }
                        account_type
                        account_number

                        captured_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }

                    created
                    captured_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }
                }
            }
        }
    }
`

export const getBanks = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on Bank {
                    __typename
                    _id
                    name
                    universal_branch_code
                }
            }
        }
    }
`

export const getUserRoles = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on UserRole {
                    __typename
                    _id
                    name
                    description
                    level
                    allowed_screens {
                        _id
                        created
                        screen {
                            _id
                            name
                            description
                            category
                            description
                        }
                    }
                    allowed_reports{
                        _id
                        created
                        report {
                            _id
                            name
                            group
                            report_number
                            description
                        }
                    }
                    allowed_business_processes {
                        _id
                        created
                        process {
                            _id
                            name
                            group
                            description
                            process_number
                        }
                    }
                    allowed_dashboards {
                        _id
                        created
                        dashboard {
                            _id
                            dashboard_number
                            name
                            group
                            description
                        }
                    }
                }
            }
        }
    }
`

export const getVASList = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on VASService {
                    __typename
                    _id

                    name
                    category {
                        _id
                        name
                    }
                    description
                    captured_by {
                        _id
                        entity {
                            name
                            surname
                        }
                    }
                    created

                    deleted
                    delete_date
                    deleted_by {
                        _id
                        entity {
                            name
                            surname
                        }
                    }
                    delete_reason

                    documents {
                        _id
                        type
                        mime_type
                        name

                        captured_by {
                            _id
                            entity {
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            _id
                            entity {
                                name
                                surname
                            }
                        }
                        delete_reason
                    }
                }
            }
        }
    }
`

export const getFinanceTransactionsList = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on FinanceAccountTransaction {
                    __typename
                    _id
                    transaction_date

                    amount
                    from {
                        _id
                        name
                        balance
                        last_updated
                    }
                    to {
                        _id
                        name
                        balance
                        last_updated
                    }
                    reference

                    created
                    created_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }
                }
            }
        }
    }
`
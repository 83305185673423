<template>
    <div>
        <v-toolbar
            color="secondary"
            dark
            class="pa-0"
            height="50"
            extension-height="3"
        >
            <v-row>
                <!-- Grid Buttons -->
                <v-col sm="8" style="margin-top:10px">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="refreshProducts()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh</span>
                    </v-tooltip>
                    <v-tooltip v-if="selectedProduct != null">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="openEditProductDetailsDialog()"
                            >
                                <v-icon>edit</v-icon>
                            </v-btn>
                        </template>
                        <span>Edit Record</span>
                    </v-tooltip>
                </v-col>

                <!-- Action Buttons -->
                <v-col sm="4" style="margin-top:10px">
                    <!-- <v-spacer></v-spacer> -->
                    <div class="text-right">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="openAddProductDialog()"
                                    v-on="on"
                                >
                                    <v-icon medium>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Product</span>
                        </v-tooltip>
                    </div>
                </v-col>
            </v-row>
        </v-toolbar>
        <multipane class="horizontal-panes" layout="horizontal" style="height: 100%" @paneResize="paneResizing">
            <div class="pane" :style="{ height: '50%', maxHeight: '50%'}">
                <v-data-table
                    fixed-header
                    :height="main_tab_height"
                    :headers="product_details_headers"
                    :items="products"
                    item-key="_id"
                    class="elevation-1"
                    :single-select="true"
                    @click:row="selectMainTableRow"
                    item-class="secondary"
                    :disable-pagination="true"
                    :loading="data_table_loading"
                    hide-default-footer
                >
                    <!-- underwriter -->
                    <template v-slot:[`item.underwriter`]="{ item }" >
                        <td>
                            {{item.underwriter ? '' : 'Afrosurance (Pty) Ltd'}}
                        </td>
                    </template>
                    <!-- Underwriter premium -->
                    <template v-slot:[`item.underwriter_premium`]="{ item }" >
                        <td>
                            {{item.primary_product.underwriter_premium + item.primary_product.broker_markup}}
                        </td>
                    </template>
                    <!-- Percentage markup -->
                    <template v-slot:[`item.markup_percentage`]="{ item }" >
                        <td>
                            {{item.markup_percentage + ' %'}}
                        </td>
                    </template>
                    <!-- Fixed markup -->
                    <template v-slot:[`item.markup_fixed`]="{ item }" >
                        <td>
                            {{item.markup_fixed}}
                        </td>
                    </template>
                    <!-- Selling premium -->
                    <template v-slot:[`item.customer_premium`]="{ item }" >
                        <td>
                            {{
                                (item.primary_product.underwriter_premium + item.primary_product.broker_markup) +
                                (item.primary_product.underwriter_premium + item.primary_product.broker_markup) * (item.markup_percentage/100) +
                                item.markup_fixed
                            }}
                        </td>
                    </template>
                    <!-- admin_percentange_fee -->
                    <template v-slot:[`item.admin_percentange_fee`]="{ item }" >
                        <td v-if="calculateAdminPercentageFee(item) <= 9" style="background:green; color:white; font-weight:bold; font-size:20px; border: 1px solid white">
                            {{ calculateAdminPercentageFee(item) + ' %' }}
                        </td>
                        <td v-else style="background:red; color:white; font-weight:bold; font-size:20px; border: 1px solid white">{{ calculateAdminPercentageFee(item) + ' %' }}</td>
                    </template>
                    <template v-slot:[`item.primary_product.active`]="{ item }" >
                        <td>
                            <span v-if="item.primary_product.active === true"><v-icon color="success">mdi-check</v-icon></span>
                            <span v-else><v-icon color="red">mdi-close</v-icon></span>
                        </td>
                    </template>
                </v-data-table>
            </div>
            <multipane-resizer></multipane-resizer>
            <div class="pane tab-nav" :style="{ height: '30%', maxHeight: '50%', minHeight: '30%' }">
                <v-tabs
                    v-model="sub_tabs"
                    color="transparent"
                    class="tab-nav"
                    hide-slider
                    height="35px"
                    grow
                >
                    <!-- Policy Rules Sub Tab -->
                    <template>
                        <v-tab key="policy_rules_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Policy Rules
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Scheme Rules Sub Tab -->
                    <template>
                        <v-tab key="scheme_rules_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Scheme Rules
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Claim Rules Sub Tab -->
                    <template>
                        <v-tab key="claim_rules_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Claim Rules
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Payout Structure Sub Tab -->
                    <template>
                        <v-tab key="payout_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Payout Structure
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- VAS Sub Tab -->
                    <template>
                        <v-tab key="vas_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                VAS Services
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>
                </v-tabs>
                <v-tabs-items v-model="sub_tabs">
                    <!-- Sub-Tab Toolbar -->
                    <v-toolbar
                        color="secondary"
                        dark
                        class="pa-0"
                        height="50"
                        extension-height="3"
                    ></v-toolbar>
                    <!-- Policy Rules Sub-Tab Content -->
                    <v-tab-item key="policy_rules_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="policy_rules_headers"
                            :items="policy_rules"
                            item-key="_id"
                            class="elevation-1"
                            :single-select="true"
                            @click:row="selectPolicyRulesRow"
                            item-class="secondary"
                            :disable-pagination="true"
                            hide-default-footer
                        ></v-data-table>
                    </v-tab-item>
                    <!-- Scheme Rules Sub-Tab Content -->
                    <v-tab-item key="scheme_rules_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="scheme_rules_headers"
                            :items="scheme_rules"
                            item-key="_id"
                            class="elevation-1"
                            :single-select="true"
                            @click:row="selectSchemeRulesRow"
                            item-class="secondary"
                            :disable-pagination="true"
                            hide-default-footer
                        ></v-data-table>
                    </v-tab-item>
                    <!-- Claim Rules Sub-Tab Content -->
                    <v-tab-item key="claim_rules_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="claim_rules_headers"
                            :items="claim_rules"
                            item-key="_id"
                            class="elevation-1"
                            :single-select="true"
                            @click:row="selectClaimRulesRow"
                            item-class="secondary"
                            :disable-pagination="true"
                            hide-default-footer
                        ></v-data-table>
                    </v-tab-item>
                    <!-- Payout Structure Sub-Tab Content -->
                    <v-tab-item key="payout_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="payout_structure_headers"
                            :items="payout_structure"
                            item-key="_id"
                            class="elevation-1"
                            :single-select="true"
                            @click:row="selectPayoutStructureRow"
                            item-class="secondary"
                            :disable-pagination="true"
                            hide-default-footer
                        ></v-data-table>
                    </v-tab-item>
                    <!-- VAS Sub-Tab Content -->
                    <v-tab-item key="vas_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="vas_details_headers"
                            :items="vas_services"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectVASTabRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Created -->
                            <template v-slot:[`item.created`]="{ item }">
                                <td>{{item.created.split('T')[0]}}</td>
                            </template>
                            <!-- Captured By -->
                            <template v-slot:[`item.captured_by`]="{ item }" >
                                <td v-if="item.captured_by !== null && item.captured_by !== undefined">
                                    {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                                </td>
                            </template>
                            <!-- Fee Amount -->
                            <template v-slot:[`item.fee_amount`]="{ item }" >
                                <td>
                                    R {{item.fee_amount}}
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </multipane>
        <AddProductDialog
            :dialog="open_add_product_dialog"
        />
        <EditProductDetailsDialog
            :dialog="open_edit_product_details_dialog"
            :product="selectedProduct"
            :product_copy="selectedProductCopy"
        />
    </div>
</template>
<script>
import { Multipane, MultipaneResizer } from 'vue-multipane';
import AddProductDialog from './dialogs/add_product';
import EditProductDetailsDialog from './dialogs/edit_product_details';
import { eventBus } from "../../../../main";
import { emitAlert } from '../../../../utils/api';

import { getProducts } from "./gql/queries";

export default {
    components: {
        Multipane,
        MultipaneResizer,
        AddProductDialog,
        EditProductDetailsDialog
    },

    data: () => ({
        sub_tabs: null,
        main_tab_height: '250px',
        sub_tab_height:'180',

        // main data-table row
        selectedProduct: null,
        selectedProductMetaData: null,
        selectedProductCopy: null,

        // payout policy rule data-table row
        selectedPolicyRule: null,
        selectedPolicyRuleMetaData: null,

        // payout scheme rule data-table row
        selectedSchemeRule: null,
        selectedSchemeRuleMetaData: null,
        
        // payout claim rule data-table row
        selectedClaimRule: null,
        selectedClaimRuleMetaData: null,

        // payout sructure data-table row
        selectedPayoutStructure: null,
        selectedPayoutStructureMetaData: null,

        // vas data row
        selectedVAS: null,
        selectedVASRow: null,

        product_details_headers: [
            { text: 'Name', value: 'name' },
            { text: 'Primary Product', value: 'primary_product.name' },
            { text: 'Underwriter', value: 'underwriter' },
            { text: 'Underwriter Premium', value: 'underwriter_premium' },
            { text: 'Percentage Markup', value: 'markup_percentage' },
            { text: 'Fixed Markup', value: 'markup_fixed' },
            { text: 'Customer Premium', value: 'customer_premium' },
            { text: 'Active', value: 'primary_product.active' },
            { text: 'Admin % Fee', value: 'admin_percentange_fee' },
        ],
        payout_structure_headers: [
            { text: 'Payout Age Category', value: 'category.name' },
            { text: 'Target Role', value: 'category.membership_category' },
            { text: 'Payout Amount', value: 'payout_amount' },
        ],
        policy_rules_headers: [
            { text: 'Rule ID', value: 'rule_parameter.id' },
            { text: 'Rule Type', value: 'rule_parameter.name' },
            { text: 'Min', value: 'min' },
            { text: 'Max', value: 'max' },
        ],
        scheme_rules_headers: [
            { text: 'Rule ID', value: 'rule_parameter.id' },
            { text: 'Rule Type', value: 'rule_parameter.name' },
            { text: 'Min', value: 'min' },
            { text: 'Max', value: 'max' },
        ],
        claim_rules_headers: [
            { text: 'Rule ID', value: 'rule_parameter.id' },
            { text: 'Rule Type', value: 'rule_parameter.name' },
            { text: 'Min', value: 'min' },
            { text: 'Max', value: 'max' },
        ],
        vas_details_headers: [
            { text: 'VAS Service', value: 'vas_service.name' },
            { text: 'Limit', value: 'limit' },
            { text: 'Limit Model', value: 'limit_model' },
            { text: 'Date Captured', value: 'created' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        
        // Table data models
        payout_structure: [],
        policy_rules: [],
        scheme_rules: [],
        claim_rules: [],
        vas_services: [],
        products: [],

        product_rule_level: null,

        // Dialogs
        data_table_loading: false,

        open_add_product_dialog: false,
        open_edit_product_details_dialog: false,
    }),

    methods: {
        calculateAdminPercentageFee(item) {
            let result = (
                (item.primary_product.broker_markup + item.markup_fixed) / item.primary_product.underwriter_premium
            ) * 100;

            result = Math.round((result + Number.EPSILON) * 100) / 100;
            return result;
        },
        //-------------------- OPEN DIALOGS ---------------------//
        openAddProductDialog() {
            this.open_add_product_dialog = true;
        },
        openEditProductDetailsDialog() {
            this.open_edit_product_details_dialog = true;
        },

        //------------------- CLOSE DIALOGS---------------------//
        closeAddProductDialog(new_product) {
            if (new_product !== null) {
                this.products.push(new_product);
                this.selectedProduct = null;
            }

            this.open_add_product_dialog = false;
        },
        closeEditProductDetailsDialog(edited_product) {
            if (edited_product !== null) {
                const obj_index = this.products.map(obj => { return obj._id; }).indexOf(edited_product._id)
                this.products[obj_index] = edited_product;

                // Deep copy products for refreshing main data table
                const temp_arr = Array.from(this.products);
                this.products = temp_arr;
                
                this.selectedProduct = edited_product;

                // Also update the payout structure tab
                this.payout_structure = edited_product.primary_product.payout_structure;

                // Update policy rules model
                this.policy_rules = edited_product.primary_product.rules.filter(rule => { return rule.rule_parameter.level === "policy" });
                // Update scheme rules model
                this.scheme_rules = edited_product.primary_product.rules.filter(rule => { return rule.rule_parameter.level === "scheme" });
                // Update claim rules model
                this.claim_rules = edited_product.primary_product.rules.filter(rule => { return rule.rule_parameter.level === "claim" });
                // Update vas services model
                this.vas_services = edited_product.primary_product.vas_services.filter(vas => { return !vas.deleted });
            }

            this.open_edit_product_details_dialog = false;
        },
        selectMainTableRow(item, item_metadata) {
            // Unselect policy rule sub-tab row
            this.selectedPolicyRule = null;
            if (this.selectedPolicyRuleMetaData !== null) {
                this.selectedPolicyRuleMetaData.select(false);
            }
            // Unselect scheme rule sub-tab row
            this.selectedSchemeRule = null;
            if (this.selectedSchemeRuleMetaData !== null) {
                this.selectedSchemeRuleMetaData.select(false);
            }
            // Unselect claim rule sub-tab row
            this.selectedClaimRule = null;
            if (this.selectedClaimRuleMetaData !== null) {
                this.selectedClaimRuleMetaData.select(false);
            }
            // Unselect payout sub-tab row
            this.selectedPayoutStructure = null;
            if (this.selectedPayoutStructureMetaData !== null) {
                this.selectedPayoutStructureMetaData.select(false);
            }
            // Unselect vas sub-tab row
            this.selectedVAS = null;
            if (this.selectedVASRow !== null) {
                this.selectedVASRow.select(false);
            }

            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                this.selectedProduct = item;
                this.selectedProductMetaData = item_metadata;
                // Update member addresses model
                this.payout_structure = item.primary_product.payout_structure;
                // Update policy rules model
                this.policy_rules = item.primary_product.rules.filter(rule => { return rule.rule_parameter.level === "policy" });
                // Update scheme rules model
                this.scheme_rules = item.primary_product.rules.filter(rule => { return rule.rule_parameter.level === "scheme" });
                // Update claim rules model
                this.claim_rules = item.primary_product.rules.filter(rule => { return rule.rule_parameter.level === "claim" });
                // Update vas services model
                this.vas_services = item.primary_product.vas_services.filter(vas => { return !vas.deleted });

                // Make product copy
                this.selectedProductCopy = Object.assign({}, this.selectedProduct);
            } else {
                this.selectedProduct = null;
                this.selectedProductMetaData = null;
                // Unighlight selected row
                item_metadata.select(false);

                // Make product copy
                this.selectedProductCopy = null;

                // -------------------------------- UPDATE DATA MODELS -----------------------------//
                // Update member addresses model
                this.payout_structure = [];
                // Update policy rules model
                this.policy_rules = [];
                // Update scheme rules model
                this.scheme_rules = [];
                // Update claim rules model
                this.claim_rules = [];
                // Update vas services model
                this.vas_services = [];

                // ----------------------UPDATE VISIBILITY OF ACTION BUTTONS -----------------------//
            }
        },
        selectPolicyRulesRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);

                this.selectedPolicyRule = item;
                this.selectedPolicyRuleMetaData = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedPolicyRule = null;
                this.selectedPolicyRuleMetaData = null;
            }
        },
        selectSchemeRulesRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);

                this.selectedSchemeRule = item;
                this.selectedSchemeRuleMetaData = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedSchemeRule = null;
                this.selectedSchemeRuleMetaData = null;
            }
        },
        selectClaimRulesRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);

                this.selectedClaimRule = item;
                this.selectedClaimRuleMetaData = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedClaimRule = null;
                this.selectedClaimRuleMetaData = null;
            }
        },
        selectPayoutStructureRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);

                this.selectedPayoutStructure = item;
                this.selectedPayoutStructureMetaData = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedPayoutStructure = null;
                this.selectedPayoutStructureMetaData = null;
            }
        },
        selectVASTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedVAS = item;
                this.selectedVASRow = item_metadata;
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedVAS = null;
                this.selectedVASRow = null;
            }
        },

        async refreshProducts() {
            try {
                this.products = [];
                this.data_table_loading = true;

                const res = await this.$apollo
                                    .query({
                                        query: getProducts,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'INSURANCE_PRODUCT_SECONDARY',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }

                this.data_table_loading = false;
                this.products = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        resetProductEditForm(updatedProduct) {
            if (
                updatedProduct.name.trim() !==                      this.selectedProduct.name.trim() ||
                updatedProduct.primary_product._id !==              this.selectedProduct.primary_product._id ||
                parseFloat(updatedProduct.markup_fixed) !==  parseFloat(this.selectedProduct.markup_fixed) ||
                parseFloat(updatedProduct.markup_percentage) !==    parseFloat(this.selectedProduct.markup_percentage)
            ) {
                this.selectedProductCopy = Object.assign({}, this.selectedProduct);
            }
        },

        paneResizing(pane, container, size) {
            const size_int = parseInt(size);

            this.main_tab_height = size;
            const max_screen_height = window.innerHeight - 300;

            const sub_tab_percentage = 1 - (size_int/max_screen_height);
            this.sub_tab_height = sub_tab_percentage * max_screen_height;
        }
    },

    async mounted() {
        eventBus.$on('CLOSE_ADD_PRODUCT_DIALOG', this.closeAddProductDialog);
        eventBus.$on('CLOSE_EDIT_SECONDARY_PRODUCT_DETAILS_DIALOG', this.closeEditProductDetailsDialog);

        // Edit form resetter
        eventBus.$on('RESET_SECONDARY_PRODUCT_EDIT_FORM', this.resetProductEditForm);

        /**
         * The following logic is for manually triggering a pane-divider movement
         * so as to get the windows to properly resize
         */
        this.paneResizing(null, null, 250);

        try {
            this.data_table_loading = true;

            const res = await this.$apollo
                                    .query({
                                        query: getProducts,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'INSURANCE_PRODUCT_SECONDARY',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.data_table_loading = false;
                return;
            }

            this.data_table_loading = false;
            this.products = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.data_table_loading = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
        }
    },

    watch: {
        
    }
}
</script>
<style scoped>
/* Overriding of textbox min height */
 .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
}
</style>
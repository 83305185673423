<template>
    <div>
        <br>
        <v-row>
            <v-col cols="12" sm="4">
                <v-card
                    max-width="344"
                    outlined
                    style="background: #4590a08a"
                >
                    <v-list-item three-line>
                        <v-list-item-avatar
                            tile
                            size="90"
                            color="secondary"
                        >
                            <h1>15</h1>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <div class="text-overline mb-4">
                                Loans Pending Approval
                            </div>
                            <hr>
                            <v-list-item-subtitle>Loans that need approval</v-list-item-subtitle>

                            <v-list-item-subtitle>
                                <b>Branch:</b> Rapitsi
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
            </v-col>

            <v-col cols="12" sm="4">
                <v-card
                    max-width="344"
                    outlined
                    style="background: #4590a08a"
                >
                    <v-list-item three-line>
                        <v-list-item-avatar
                            tile
                            size="90"
                            color="secondary"
                        >
                            <h1>15</h1>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <div class="text-overline mb-4">
                                Loans Pending Approval
                            </div>
                            <hr>
                            <v-list-item-subtitle>Loans that need approval</v-list-item-subtitle>

                            <v-list-item-subtitle>
                                <b>Branch:</b> Rapitsi
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
            </v-col>

            <v-col cols="12" sm="4">
                <v-card
                    max-width="344"
                    outlined
                    style="background: #4590a08a"
                >
                    <v-list-item three-line>
                        <v-list-item-avatar
                            tile
                            size="90"
                            color="secondary"
                        >
                            <h1>15</h1>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <div class="text-overline mb-4">
                                Loans Pending Approval
                            </div>
                            <hr>
                            <v-list-item-subtitle>Loans that need approval</v-list-item-subtitle>

                            <v-list-item-subtitle>
                                <b>Branch:</b> Rapitsi
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    components: {},
    mixins: [],
    props: {},
    data: () => ({}),
    computed: {},
    methods: {},
    async mounted() {},
    watch: {}
}
</script>
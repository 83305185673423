var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticClass:"pa-0",attrs:{"color":"secondary","dark":"","height":"50","extension-height":"3"}},[_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"10px"},attrs:{"sm":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.loadMoreData()}}},on),[_c('v-icon',[_vm._v("cloud_download")])],1)]}}])},[_c('span',[_vm._v("Load More Data")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.refreshUsers()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh")])])],1),_c('v-col',{attrs:{"sm":"4"}}),_c('v-col',{staticStyle:{"margin-top":"10px"},attrs:{"sm":"4"}},[_c('div',{staticClass:"text-right"},[(_vm.selectedUser !== null && _vm.selectedUser.active === true)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"red black--text"},on:{"click":function($event){return _vm.openDeactivateUserDialog()}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-close")])],1)]}}],null,false,2954756106)},[_c('span',[_vm._v("Deactivate User")])]):_vm._e(),(_vm.selectedUser !== null && _vm.selectedUser.active === false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"success white--text"},on:{"click":function($event){return _vm.openReactivateUserDialog()}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("check")])],1)]}}],null,false,4067378705)},[_c('span',[_vm._v("Re-Activate User")])]):_vm._e(),(_vm.selectedUser !== null && _vm.selectedUser.active === true)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"amber black--text"},on:{"click":function($event){return _vm.openResetUserPasswordDialog()}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-lock")])],1)]}}],null,false,1229250991)},[_c('span',[_vm._v("Reset User Password")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"amber black--text"},on:{"click":function($event){return _vm.openAddUserDialog()}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v("Add a New User")])])],1)])],1)],1),_c('multipane',{staticClass:"horizontal-panes",staticStyle:{"height":"100%"},attrs:{"layout":"horizontal"},on:{"paneResize":_vm.paneResizing}},[_c('div',{staticClass:"pane",style:({ height: '50%', maxHeight: '50%'})},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.main_tab_height,"headers":_vm.user_details_headers,"items":_vm.users,"item-key":"_id","single-select":true,"item-class":"secondary","hide-default-footer":"","loading":_vm.data_table_loading,"disable-pagination":true},on:{"click:row":_vm.selectMainTableRow},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.created.split('T')[0]))])]}},{key:"item.entity",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.entity.name + ' ' + item.entity.surname + ' (' + item.entity.id_number + ')'))])]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.active ? 'Active' : 'Inactive'))])]}}],null,true)})],1),_c('multipane-resizer'),_c('div',{staticClass:"pane tab-nav",style:({ height: '30%', maxHeight: '50%', minHeight: '30%' })},[_c('v-tabs',{staticClass:"tab-nav",attrs:{"color":"transparent","hide-slider":"","height":"35px","grow":""},model:{value:(_vm.sub_tabs),callback:function ($$v) {_vm.sub_tabs=$$v},expression:"sub_tabs"}},[[_c('v-tab',{key:"user_roles_sub_tab",staticStyle:{"max-width":"210px","font-size":"12px"},attrs:{"active-class":"secondary white--text"}},[_c('span',{staticClass:"tab-title text-truncate"},[_vm._v(" Roles ")]),_c('div',{staticClass:"spacer"})])]],2),_c('v-tabs-items',{model:{value:(_vm.sub_tabs),callback:function ($$v) {_vm.sub_tabs=$$v},expression:"sub_tabs"}},[_c('v-toolbar',{staticClass:"pa-0",attrs:{"color":"secondary","dark":"","height":"50","extension-height":"3"}},[(_vm.sub_tabs === 0 && _vm.selectedUser !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.openAddUserRoleDialog()}}},on),[_c('v-icon',[_vm._v("add")])],1)]}}],null,false,528560949)},[_c('span',[_vm._v("Add User Role")])]):_vm._e(),(_vm.selectedUser !== null && _vm.selectedUserRole !== null)?_c('div',[(_vm.sub_tabs === 0  && _vm.selectedUserRole !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.removeUserRole()}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,false,3281165430)},[_c('span',[_vm._v("Remove User Role")])]):_vm._e()],1):_vm._e(),_c('v-spacer')],1),_c('v-tab-item',{key:"user_roles_sub_tab"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.sub_tab_height,"headers":_vm.user_role_details_headers,"items":_vm.user_roles,"item-key":"_id","single-select":true,"item-class":"secondary","hide-default-footer":""},on:{"click:row":_vm.selectUserRoleTabRow},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.created.split('T')[0]))])]}}],null,true)})],1)],1)],1)],1),_c('AddUserDialog',{attrs:{"dialog":_vm.open_add_user_dialog}}),(_vm.selectedUser !== null)?_c('AddUserRoleDialog',{attrs:{"dialog":_vm.open_add_user_role_dialog,"user_id":_vm.selectedUser._id}}):_vm._e(),(_vm.selectedUser !== null)?_c('DeactivateUserDialog',{attrs:{"dialog":_vm.open_deactivate_user_dialog,"user":_vm.selectedUser}}):_vm._e(),(_vm.selectedUser !== null)?_c('ReactivateUserDialog',{attrs:{"dialog":_vm.open_reactivate_user_dialog,"user":_vm.selectedUser}}):_vm._e(),(_vm.selectedUser !== null)?_c('ResetUserPasswordDialog',{attrs:{"dialog":_vm.open_reset_user_password_dialog,"user":_vm.selectedUser}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import gql from 'graphql-tag'

export const getSchemeAllocations = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on SchemeAllocation {
                    __typename
                    _id
                    allocation_number

                    scheme {
                        _id
                        scheme_number
                        name
                    }

                    transaction_date
                    transaction_amount
                    transaction_description
                    transaction_reference

                    allocation_amount
                    bank_feed_item {
                        _id
                    }
                    payment_method {
                        _id
                        name
                    }
                    status

                    captured_by {
                        __typename
                        _id
                        entity {
                            __typename
                            _id
                            name
                            surname
                        }
                    }
                    created

                    policies {
                        policy {
                            _id
                            policy_number
                            principal_member {
                                _id
                                name
                                surname
                                id_number
                            }
                            premium
                        }
                        premium_amount
                        scheme_contribution_amount
                    }
                    documents {
                        __typename
                        _id
                        type
                        mime_type
                        name

                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }
                }
            }
        }
    }
`

export const searchSchemeAutocomplete = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on Scheme {
                    __typename
                    _id

                    scheme_number
                    name
                }
            }
        }
    }
`

export const getSchemePolicies = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on Policy {
                    __typename
                    _id
                    policy_number
                    principal_member {
                        _id
                        name
                        surname
                        id_number
                    }
                    status
                    premium

                    product {
                        _id
                        name
                        markup_fixed
                        markup_percentage
                        primary_product {
                            _id
                            name
                            underwriter_premium
                            broker_commission
                            broker_markup
                        }
                    }

                    payment_information {
                        premium_payer {
                            payment_method
                            percentage_amount
                        }
                        scheme {
                            payment_method
                            percentage_amount
                        }
                    }
                }
            }
        }
    }
`

export const getBanks = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on Bank {
                    __typename
                    _id
                    name
                    universal_branch_code
                }
            }
        }
    }
`

export const getSystemFinanceAccounts = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on FinanceAccount {
                    __typename
                    _id
                    name
                    description
                    type
                }
            }
        }
    }
`

export const getFinanceTransactionsList = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on FinanceAccountTransaction {
                    __typename
                    _id
                    transaction_date

                    amount
                    from {
                        _id
                        name
                        balance
                        last_updated
                    }
                    to {
                        _id
                        name
                        balance
                        last_updated
                    }
                    reference
                    additional_reference

                    balance_from
                    balance_to

                    created
                    created_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }
                }
            }
        }
    }
`

export const searchBankFeedAutocomplete = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on BankFeed {
                    __typename
                    _id
                    bank_account_name
                    date
                    amount
                    description
                    reference
                    classification {
                        _id
                        name
                        category
                    }
                    created
                }
            }
        }
    }
`

export const finProcessFinanceGetAccountBalance = gql`
    query finProcessFinanceGetAccountBalance($account_id: String!) {
        finProcessFinanceGetAccountBalance(account_id: $account_id) {
            balance
            message
        }
    }
`
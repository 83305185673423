<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Add Agent Contract</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row style="background:#eaeaea; padding-bottom:10px">
                        <!-- Contract Document Type -->
                        <v-col cols="12" sm="5">
                            <v-text-field
                                v-model="agent_details.contract_file.type"
                                label="Document Type"
                                required
                                disabled
                            ></v-text-field>
                        </v-col>
                        <!-- Contract Document -->
                        <v-col cols="12" sm="6">
                            <v-file-input
                                v-model="agent_details.contract_file.file"
                                color="secondary"
                                accept="application/pdf, image/*"
                                label="File/Document"
                            ></v-file-input>
                        </v-col>
                        <!-- Contract Preview -->
                        <v-col cols="12" sm="1">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        fab
                                        color="amber"
                                        v-on="on"
                                        @click="previewFile(agent_details.contract_file)"
                                        :disabled="agent_details.contract_file.file===null || agent_details.contract_file.file===undefined"
                                    >
                                        <v-icon>remove_red_eye</v-icon>
                                    </v-btn>
                                </template>
                                <span>Preview File</span>
                            </v-tooltip>
                        </v-col>
                        <!-- Contract Start Date -->
                        <v-col cols="12" sm="5">
                            <v-menu
                                v-model="start_date_menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="agent_details.start_date"
                                    color="secondary"
                                    label="Start Date"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    color="secondary"
                                    v-model="agent_details.start_date"
                                    @input="start_date_menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <!-- Contract End Date -->
                        <v-col cols="12" sm="5">
                            <v-menu
                                v-model="end_date_menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="agent_details.end_date"
                                    color="secondary"
                                    label="End Date"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    color="secondary"
                                    v-model="agent_details.end_date"
                                    @input="end_date_menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <!-- Contract Approved -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="agent_details.contract_file.approved"
                                color="secondary"
                                label="Approved"
                                required
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-card-text>
                <!-- Progress Section -->
                <v-progress-linear
                    color="green"
                    class="mb-0"
                    height="10"
                    indeterminate
                    :active="submitting"
                ></v-progress-linear>
                <v-list flat v-if="submitting">
                    <v-subheader>Saving Agent Info</v-subheader>
                    <!-- <v-list-item-group v-model="item" color="primary"> -->
                    <v-list-item-group color="primary">
                        <v-list-item
                            v-for="(item, i) in backendActionItems"
                            :key="i"
                        >
                            <v-list-item-icon>
                                <v-icon>done</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="item"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>

                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        large
                        color="success"
                        :disabled="saveDocumentsBtnDisabled()"
                        @click="saveDocuments()"
                    >
                        <v-icon large>save</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <DocPreviewDialog
            :dialog="open_doc_preview_dialog"
            :url="file_url"
            :doc_type="doc_type"
            @CLOSE_DOC_PREVIEW_DIALOG="closeDocPreviewDialog()"
        />
    </v-row>
</template>
<script>
import DocPreviewDialog from '../../../components/doc_preview';
import { emitAlert, emitSuccess, submitDocumentumFile } from "../../../utils/api";
import { eventBus } from "../../../main";
import { document_types } from "../../../../config";

import { entityProcessAgent } from "../gql/mutations";

export default {
    components: {
        DocPreviewDialog
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        agent: {
            type: Object,
            required: false,
            default: null
        },
    },
    data: () => ({
        server_resp_obj: null,
        submitting: false,

        // File props
        file_url: null,
        doc_type: null,

        start_date_menu: false,
        end_date_menu: false,
        agent_details: {
            start_date: null,
            end_date: null,
            contract_file: {
                type: 'Agent Contract',
                file: null,
                approved: false,
                valid: false,
            }
        },

        open_doc_preview_dialog: false,

        backendActionItems: [],
        document_types: document_types.filter(doc_type => { return doc_type.type === 'entity' })
    }),
    methods: {
        closeDialog() {
            eventBus.$emit('CLOSE_ADD_AGENT_CONTRACTS_DIALOG', this.server_resp_obj);
        },
        closeDocPreviewDialog() {
            this.open_doc_preview_dialog = false;
        },
        resetForm() {
            this.server_resp_obj = null;
            this.submitting = false;
            this.backendActionItems = [];

            this.agent_details = {
                agent: null,
                member: null,
                start_date: null,
                end_date: null,
                contract_file: {
                    type: 'Agent Contract',
                    file: null,
                    approved: false,
                    valid: false,
                }
            };
        },

        saveDocumentsBtnDisabled() {
            if (
                this.agent_details.contract_file.file === null ||
                this.agent_details.contract_file.file === undefined ||
                this.agent_details.start_date === null ||
                this.agent_details.end_date === null ||
                this.agent_details.contract_file.approved === false
            ) {
                return true;
            }

            return false;
        },
        
        async saveDocuments() {
            this.submitting = true;

            this.backendActionItems.push("Saving agent records");

            const submitObj = {
                type:       this.agent_details.contract_file.type,
                mime_type:  this.agent_details.contract_file.file.type,
                name:       this.agent_details.contract_file.file.name,
                approved:   this.agent_details.contract_file.approved,
                start_date: this.agent_details.start_date,
                end_date:   this.agent_details.end_date,

                // placeholder
                _id: ''
            };

            try {
                // let res = await addAgentDocument(this.agent._id, submitObj);
                // if (res.errors === true) {
                //     emitAlert(res.message);
                //     this.submitting = false;
                //     this.backendActionItems = [];
                //     return;
                // }

                const res = await this.$apollo
                                                .mutate({
                                                    mutation: entityProcessAgent,
                                                    variables: {
                                                        input: {
                                                            OP_TYPE: 'ADD_DOCUMENT',
                                                            _id: this.agent._id,
                                                            
                                                            documents: [submitObj]
                                                        }
                                                    },
                                                });
                if (res.data.entityProcessAgent.errors === true) {
                    emitAlert(res.data.entityProcessAgent.message);
                    this.submitting = false;
                    this.backendActionItems = [];
                    return;
                }

                // Add document to documentum
                this.backendActionItems.push("Saving agent contract document");

                // const file_id = this.agent_details.contract_file._id;
                const file_id = res.data.entityProcessAgent.business_object.documents[ res.data.entityProcessAgent.business_object.documents.length - 1 ]._id;
                const file = this.agent_details.contract_file.file;
                const agentId = this.agent._id;

                const uploadRes = await submitDocumentumFile(file_id, file, 'agent', agentId);
                if (uploadRes.errors === true) {
                    emitAlert(uploadRes.message);
                    this.submitting = false;
                    this.backendActionItems.push(`  --> Uploading ${file.name} failed`);
                    return;
                }
                
                this.backendActionItems.push(`  --> Uploading ${file.name} succeeded`);

                this.server_resp_obj = res.data.entityProcessAgent.business_object;
                this.closeDialog();

                this.resetForm();

                emitSuccess(res.data.entityProcessAgent.message);
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.entityProcessAgent.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        //--------------------PREVIEW FILES--------------------------//
        previewFile(fileObject) {
            // Set file oobject so we can trigger the readAsDataURL function
            const reader = new FileReader();

            reader.addEventListener("load", () => {
                // Set PDF-Viewer URL to the selected file Base64 String
                this.file_url = reader.result;
                if (fileObject.file.type === 'application/pdf') {
                    this.doc_type = 'pdf';
                } else {
                    this.doc_type = 'image';
                }

                // Display the preview dialog
                this.open_doc_preview_dialog = true;
            }, false);

            reader.readAsDataURL(fileObject.file);
        },
    },
    mounted() {
        
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
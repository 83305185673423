<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Add Afrosurance Branch</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeAddBranch()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <br>
                    <v-row style="background:#4490a030; margin-right: 1px;">
                        <v-col cols="12" sm="12" >
                            <h3>Basic Details</h3>
                        </v-col>


                        <!-- Branch Name -->
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="branch.name"
                                color="secondary"
                                label="Branch Name"
                                required
                            ></v-text-field>
                        </v-col>
                        <!-- Start Date -->
                        <v-col cols="12" sm="6">
                            <v-menu
                                v-model="branch_start_date_menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="branch.start_date"
                                    color="secondary"
                                    label="Manager Start Date"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    color="secondary"
                                    v-model="branch.start_date"
                                    @input="branch_start_date_menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <br><br>

                    <v-row style="background:#f0bb81; margin-right: 1px;">
                        <v-col cols="12" sm="12">
                            <h3>Management</h3>
                        </v-col>
                        <!-- Manager -->
                        <v-col cols="12" sm="6">
                            <AgentSearch :member="branch" label="Branch Manager"/>
                        </v-col>
                        <!-- Start Date -->
                        <v-col cols="12" sm="6">
                            <v-menu
                                v-model="manager_start_date_menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="branch.manager_start_date"
                                    color="secondary"
                                    label="Manager Start Date"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    color="secondary"
                                    v-model="branch.manager_start_date"
                                    @input="manager_start_date_menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <br><br>


                    <v-row style="background:#e9e9e9; margin-right: 1px;">
                        <v-col cols="12" sm="12">
                            <h3>Address</h3>
                        </v-col>
                        <!-- Address -->
                        <v-col cols="12" sm="3">
                            <v-text-field
                                v-model="branch.address.line_1"
                                label="Line 1"
                                autocomplete="some-random-text"
                                type="text"
                                color="secondary"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                v-model="branch.address.line_2"
                                label="Line 2"
                                autocomplete="some-random-text"
                                type="text"
                                color="secondary"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                v-model="branch.address.line_3"
                                label="Line 3"
                                autocomplete="some-random-text"
                                type="text"
                                color="secondary"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                v-model="branch.address.line_4"
                                label="Postal Code"
                                autocomplete="some-random-text"
                                type="text"
                                color="secondary"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting Branch Details
                        <v-progress-linear
                            indeterminate
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submitBranchBtnDisabled()"
                                @click="submitBranch()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Submit Branch Details</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import AgentSearch from "../components/agent_search.vue";
import { emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";

import { createBranch } from "../gql/mutations";

export default {
    name: 'AddBranchDialog',
    components: {
        AgentSearch
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        manager_start_date_menu: false,
        branch_start_date_menu: false,

        branch: {
            name: null,
            start_date: null,

            agent: null,
            manager: null,
            manager_start_date: null,

            address: {
                line_1: null,
                line_2: null,
                line_3: null,
                line_4: null,
            },
        },
        server_resp_obj: null,
        submitting: false,
    }),
    methods: {
        closeAddBranch() {
            eventBus.$emit('CLOSE_ADD_BRANCH_DIALOG', this.server_resp_obj);
        },
        resetForm() {
            this.branch = {
                name: null,
                start_date: null,

                agent: null,
                manager: null,
                manager_start_date: null,

                address: {
                    line_1: null,
                    line_2: null,
                    line_3: null,
                    line_4: null,
                },
            };
            this.server_resp_obj = null;
            this.submitting = false;
        },
        submitBranchBtnDisabled() {
            if (this.branch.name === null) {
                return true;
            }
            if (this.branch.start_date === null) {
                return true;
            }
            if (this.branch.agent === null) {
                return true;
            }
            if (this.branch.manager_start_date === null) {
                return true;
            }

            if ((this.branch.name + '').trim() === '') {
                return true;
            }
            return false;
        },
        async submitBranch() {
            this.submitting = true;

            try {
                const payload = {
                    OP_TYPE: 'CREATE',

                    name: this.branch.name,
                    start_date: this.branch.start_date,

                    manager: this.branch.agent._id,
                    manager_start_date: this.branch.manager_start_date,

                    address: {
                        type: 'Physical',
                        line1: this.branch.address.line_1,
                        line2: this.branch.address.line_2,
                        line3: this.branch.address.line_3,
                        line4: this.branch.address.line_4,
                    },
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: createBranch,
                                                variables: {
                                                    input: payload
                                                },
                                            });
                if (res.data.entityProcessBranch.errors === true) {
                    emitAlert(res.data.entityProcessBranch.message);
                    this.submitting = false;
                    return;
                }

                this.server_resp_obj = res.data.entityProcessBranch.business_object;
                emitSuccess(res.data.entityProcessBranch.message);

                this.closeAddBranch();
                this.resetForm();
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.entityProcessBranch.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        }
    },
    mounted() {
        
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
import gql from 'graphql-tag'

export const getAgents = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on Agent {
                    __typename
                    _id

                    agent_number
                    branch {
                        _id
                        name
                        branch_number
                    }
                    entity {
                        _id
                        name
                        surname
                        id_number
                    }
                    recruiting_agent {
                        _id
                        agent_number
                        entity {
                            _id
                            name
                            surname
                            id_number
                        }
                    }

                    created
                    captured_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }

                    commission_rules {
                        _id
                        type
                        amount
                        captured_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        created
                    }
                    branch_history {
                        _id
                        branch {
                            _id
                            name
                            branch_number
                        }
                        start_date
                        created
                        captured_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                    }
                    documents {
                        _id
                        type
                        mime_type
                        name

                        start_date
                        end_date
                        approved
                        approved_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }

                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }
                }
            }
        }
    }
`

export const getSystemFinanceAccounts = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on FinanceAccount {
                    __typename
                    _id
                    name
                    description
                    type
                }
            }
        }
    }
`

export const getFinanceTransactionsList = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on FinanceAccountTransaction {
                    __typename
                    _id
                    transaction_date

                    amount
                    from {
                        _id
                        name
                        balance
                        last_updated
                    }
                    to {
                        _id
                        name
                        balance
                        last_updated
                    }
                    reference

                    created
                    created_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }
                }
            }
        }
    }
`

export const searchAgentAutocomplete = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on Agent {
                    __typename
                    _id

                    agent_number
                    entity {
                        _id
                        name
                        surname
                        id_number
                    }
                }
            }
        }
    }
`

export const searchBranchAutocomplete = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on Branch {
                    __typename
                    _id

                    _id
                    branch_number
                    name
                }
            }
        }
    }
`

export const searchEntityAutocomplete = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on Entity {
                    __typename
                    _id
                    name
                    surname
                    id_number
                }
            }
        }
    }
`

export const getFinancialCalendarMonths = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on FinanceCalendarMonth {
                    __typename
                    _id
                    name
                    value
                    status
                    created

                    # business_partner {
                    #     _id
                    #     bp_number
                    #     name
                    # }

                    finance_year {
                        _id
                        business_partner {
                            _id
                            bp_number
                            name
                        }

                        name
                        value
                        status
                        created
                    }
                }
            }
        }
    }
`

<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="95%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Capture Corporate Bond</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeAddBondDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-stepper v-model="e1">
                <v-stepper-header>
                    <v-stepper-step :complete="e1 > 1" step="1" color="success">Pricing</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 2" step="2" color="success">Bond Details</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="3" color="success">Processing</v-stepper-step>
                </v-stepper-header>
            
                <v-stepper-items>
                    <!-- Basic Details -->
                    <v-stepper-content step="1">
                        <v-card>
                            <v-card-text>
                                <v-row style="background:#4490a030; margin-right: 1px;">
                                    <!-- BASIC DETAILS -->
                                    <v-col cols="12" sm="12" >
                                        <h3>Basic Details</h3>
                                    </v-col>
                                    <v-col cols="12" sm="2">
                                        <v-text-field
                                            v-model="pricing_details.principal_amount"
                                            color="secondary"
                                            label="Principal Amount"
                                            type="number"
                                            prefix="ZAR"
                                            :hint="pricing_details.bond_type.principal_description"
                                            persistent-hint
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-select
                                            @change="bondTypeChanged"
                                            v-model="pricing_details.bond_type"
                                            :items="bond_types_list"
                                            item-text="name"
                                            item-value="bond_type_id"
                                            label="Bond Type"
                                            color="secondary"
                                            return-object
                                            :hint="pricing_details.bond_type.description"
                                            persistent-hint
                                            required
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-text-field
                                            v-model="pricing_details.bond_term"
                                            color="secondary"
                                            label="Bond Term"
                                            type="number"
                                            :suffix="pricing_details.bond_term_units"
                                            hint="Bond Term"
                                            persistent-hint
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="2">
                                        <v-menu
                                            v-model="start_date_menu"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="bond_details.start_date"
                                                label="Bond Start Date"
                                                hint="e.g. 2020-01-01"
                                                persistent-hint
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                color="secondary"
                                                v-model="bond_details.start_date"
                                                @input="start_date_menu = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" sm="2">
                                        <v-text-field
                                            v-model="bond_details.end_date"
                                            label="Bond End Date"
                                            hint="e.g. 2020-01-01"
                                            persistent-hint
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <br/>
                                <br/>
                                <!-- COLLECTION DETAILS -->
                                <v-row
                                    v-if="pricing_details.bond_type.bond_type_id === 2"
                                    style="background: #ffc496;"
                                >
                                    <v-col cols="12" sm="12" >
                                        <h3>Collection Details</h3>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-select
                                            v-model="bond_details.repayment_config.payment_method"
                                            :items="payment_methods"
                                            label="Repayment Method"
                                            color="secondary"
                                            hint="Payment method"
                                            persistent-hint
                                            required
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-select
                                            v-model="bond_details.repayment_config.repayment_frequency"
                                            :items="['HOURLY', 'DAILY', 'WEEKLY', 'MONTHLY']"
                                            label="Repayment Frequency"
                                            color="secondary"
                                            hint="Repayment Frequency"
                                            persistent-hint
                                            disabled
                                            required
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            v-model="bond_details.repayment_config.elected_repayment_time_point"
                                            color="secondary"
                                            label="Elected Monthly Collection Date"
                                            type="number"
                                            hint="e.g. 1 - 30"
                                            persistent-hint
                                            required
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <br v-if="pricing_details.bond_type.bond_type_id === 2"/>
                                <br v-if="pricing_details.bond_type.bond_type_id === 2"/>
                                <!-- ANNUITY DETAILS -->
                                <v-row
                                    v-if="pricing_details.bond_type.bond_type_id === 3"
                                    style="background:#db545430; margin-right: 1px;"
                                >
                                    <!-- ANNUITY DETAILS -->
                                    <v-col cols="12" sm="12" >
                                        <h3>Annuity Details</h3>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            v-model="pricing_details.annuity_monthly_payout_date"
                                            color="secondary"
                                            label="Annuity Monthly Payout Date"
                                            type="number"
                                            hint="Between 1 and 30"
                                            persistent-hint
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            v-model="pricing_details.annuity_payout_amount"
                                            color="secondary"
                                            label="Annuity Monthly Withdrawal Amount"
                                            type="number"
                                            prefix="ZAR"
                                            hint="Annuity Monthly Withdrawal Amount"
                                            persistent-hint
                                            required
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <br v-if="pricing_details.bond_type.bond_type_id === 3"/>
                                <br v-if="pricing_details.bond_type.bond_type_id === 3"/>

                                <v-row style="background: #e9e9e9">
                                    <!-- RETURNS CONFIG -->
                                    <v-col cols="12" sm="12">
                                        <h3>Returns Config</h3>
                                    </v-col>
                                    <v-col cols="12" sm="12">
                                        <v-card
                                            flat
                                            color="transparent"
                                        >
                                            <v-subheader>Return Annual Interest</v-subheader>
                                            <v-card-text>
                                                <v-slider
                                                    v-model="pricing_details.interest_config.annual_interest_rate"
                                                    step="0.1"
                                                    thumb-color="red"
                                                    thumb-label="always"
                                                >
                                                    <v-icon
                                                        v-if="parseFloat(pricing_details.interest_config.annual_interest_rate) < 100"
                                                        slot="append"
                                                        color="red"
                                                        @click="updateAnnualInterestRate('plus')"
                                                    >
                                                        mdi-plus
                                                    </v-icon>
                                                    <v-icon
                                                        v-if="parseFloat(pricing_details.interest_config.annual_interest_rate) > 0"
                                                        slot="prepend"
                                                        color="green"
                                                        @click="updateAnnualInterestRate('minus')"
                                                    >
                                                        mdi-minus
                                                    </v-icon>
                                                </v-slider>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>

                                    <!-- <v-col cols="12" sm="3">
                                        <v-text-field
                                            :value="derivedMonthlyInterestRate"
                                            color="secondary"
                                            label="Monthly Interest Rate"
                                            type="number"
                                            suffix="%"
                                            required
                                            disabled
                                            hint="Monthly interest rate earned"
                                            persistent-hint
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-text-field
                                            :value="derivedMonthlyInterestAmount"
                                            color="secondary"
                                            label="Monthly Interest Amount"
                                            type="number"
                                            prefix="ZAR"
                                            required
                                            disabled
                                            hint="Monthly interest amount earned"
                                            persistent-hint
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-text-field
                                            :value="derivedEndOfTermAmount"
                                            color="secondary"
                                            label="End Of Term Amount"
                                            type="number"
                                            prefix="ZAR"
                                            required
                                            disabled
                                            hint="Total Amount At End of Term"
                                            persistent-hint
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <v-text-field
                                            :value="derivedProjectedEarnings"
                                            color="secondary"
                                            label="Total Projected Earnings"
                                            type="number"
                                            prefix="ZAR"
                                            required
                                            disabled
                                            hint="Total Amount At End of Term"
                                            persistent-hint
                                        ></v-text-field>
                                    </v-col> -->
                                </v-row>
                                <br>
                                <br>
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    x-large
                                    :disabled="submitCalculationBtnDisabled()"
                                    @click="submitCalculation()"
                                    color="success"
                                >
                                    Calculate <v-icon x-large>mdi-calculator-variant-outline</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    fab
                                    large
                                    color="amber"
                                    :disabled="goToStep2BtnDisabled()"
                                    @click="(e1 = 2)"
                                >
                                    <v-icon large>keyboard_arrow_right</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-card>
                            <v-card-text>
                                <!-- BOND ENTITY -->
                                <!-- <v-row style="background:#4490a030" v-if="electedRepaymentTimePointValid"> -->
                                <v-row style="background:#4490a030">
                                    <v-col cols="12" sm="12" >
                                        <h3>Entity Details</h3>
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <AgentSearch :member="bond_details" label="Agent Who Brought The Bond" />
                                    </v-col>

                                    <v-col cols="12" sm="4">
                                        <BeneficiarySearch :member="bond_details" label="Beneficiary Entity" />
                                    </v-col>

                                    <v-col cols="12" sm="4">
                                        <MemberSearch :member="bond_details" label="Bond Main Entity" />
                                    </v-col>
                                    <v-col cols="12" sm="1">
                                        <v-tooltip bottom v-if="bond_details.member === null">
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    color="primary white--text"
                                                    fab
                                                    v-on="on"
                                                    @click="openCaptureEntityDialog()"
                                                >
                                                    <v-icon>add</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Capture Entity</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                                <br>
                                <br>

                                <!-- BOND ENTITY DETAILS -->
                                <v-card-text>
                                    <h2 v-if="bond_details.member !== null">Entity Details</h2><br>
                                    <!-- Basic Details -->
                                    <div
                                        v-if="bond_details.member !== null"
                                        style="background:#e9e9e9; border:1px #aaaaaa solid; padding:10px;"
                                    >
                                        <h3>Basic Details</h3>
                                        <v-row>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-model="bond_details.member.name"
                                                    color="secondary"
                                                    label="Name"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-model="bond_details.member.surname"
                                                    color="secondary"
                                                    label="Surname"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-model="getBondEntityPhone"
                                                    color="secondary"
                                                    label="Phone"
                                                    type="number"
                                                    hint="e.g. 0721234567"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-text-field
                                                    v-model="getBondEntityEmail"
                                                    color="secondary"
                                                    label="Email"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row style="background:#e0cb99;">
                                            <v-col cols="12" sm="5">
                                                <v-text-field
                                                    v-model="bond_details.member.id_number"
                                                    type="number"
                                                    color="secondary"
                                                    label="ID Number"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <!-- <v-col cols="12" sm="1">
                                                <template>
                                                    <div>
                                                        <span>Id Valid</span><br>
                                                        <span v-if="policy_details.principal_member.id_number_valid === true"><v-icon color="success">mdi-check</v-icon></span>
                                                        <span v-else><v-icon color="red">mdi-close</v-icon></span>
                                                    </div>
                                                </template>
                                            </v-col> -->
                                        </v-row>
                                    </div>
                                    <br>

                                    <!-- Payout Banking Details -->
                                    <div
                                        v-if="(
                                            bond_details.member !== null
                                        )"
                                        style="background:#e9e9e9; border:1px #aaaaaa solid; padding:10px;"
                                    >
                                        <h3>Payout Banking Details</h3>
                                        <v-row>
                                            <v-col cols="12" sm="12">
                                                <v-radio-group v-model="bond_details.payout_banking">
                                                    <v-radio
                                                        v-for="bankObj in bond_details.member.bank_account_details.filter(bank => { return bank.deleted === false })"
                                                        :key="bankObj._id"
                                                        :label="`${bankObj.bank.name} - ${bankObj.account_number}`"
                                                        :value="bankObj._id"
                                                        color="secondary"
                                                    ></v-radio>
                                                </v-radio-group>
                                                <h1
                                                    style="color:red"
                                                    v-if="bond_details.member.bank_account_details.filter(bank => { return bank.deleted === false }).length === 0"
                                                >
                                                    No Banking Details Captured
                                                </h1>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <br>

                                    <!-- Collection Banking Details -->
                                    <div
                                        v-if="(
                                            bond_details.member !== null &&
                                            bond_details.repayment_config.payment_method === 'DEBIT ORDER' &&
                                            pricing_details.bond_type.bond_type_id === 2
                                        )"
                                        style="background:#e9e9e9; border:1px #aaaaaa solid; padding:10px;"
                                    >
                                        <h3>Collection Banking Details</h3>
                                        <v-row>
                                            <v-col cols="12" sm="12">
                                                <v-radio-group v-model="bond_details.collection_banking">
                                                    <v-radio
                                                        v-for="bankObj in bond_details.member.bank_account_details.filter(bank => { return bank.deleted === false })"
                                                        :key="bankObj._id"
                                                        :label="`${bankObj.bank.name} - ${bankObj.account_number}`"
                                                        :value="bankObj._id"
                                                        color="secondary"
                                                    ></v-radio>
                                                </v-radio-group>
                                                <h1
                                                    style="color:red"
                                                    v-if="bond_details.member.bank_account_details.filter(bank => { return bank.deleted === false }).length === 0"
                                                >
                                                    No Banking Details Captured
                                                </h1>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <br>

                                    <!-- Physical Address -->
                                    <div
                                        v-if="bond_details.member !== null"
                                        style="background:#e9e9e9; border:1px #aaaaaa solid; padding:10px;"
                                    >
                                        <h3>Physical Address</h3>
                                        <v-row>
                                            <v-col cols="12" sm="4">
                                                <v-text-field
                                                    v-model="getBondEntityAddress.line1"
                                                    color="secondary"
                                                    label="Line 1"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="4">
                                                <v-text-field
                                                    v-model="getBondEntityAddress.line2"
                                                    color="secondary"
                                                    label="Line 2"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="2">
                                                <v-text-field
                                                    v-model="getBondEntityAddress.line3"
                                                    color="secondary"
                                                    label="Line 3"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="2">
                                                <v-text-field
                                                    v-model="getBondEntityAddress.line4"
                                                    type="number"
                                                    color="secondary"
                                                    label="Postal Code"
                                                    persistent-hint
                                                    required
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <br>
                                    <br>
                                </v-card-text>
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-btn
                                    fab
                                    large
                                    color="amber"
                                    @click="(e1 = 1)"
                                >
                                    <v-icon large>keyboard_arrow_left</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>

                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            color="success"
                                            x-large
                                            v-on="on"
                                            :disabled="submitBtnDisabled()"
                                            @click="submitBond()"
                                        >
                                            <v-icon x-large>save</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Submit Quote</span>
                                </v-tooltip>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Submission Results Section -->
                    <v-stepper-content step="3">
                        <v-card>
                            <v-card-text class="text-center" v-if="submitting">
                                Submitting Bond Details
                                <v-progress-linear
                                    indeterminate
                                    color="green"
                                    class="mb-0"
                                    height="5"
                                    :active="submitting"
                                ></v-progress-linear>

                            </v-card-text>
                        </v-card>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>
        <ViewCalculationResults
            :dialog="open_calc_results_dialog"
            :calculation_results="calulation_results"
        />
        <AddEntity
            :dialog="capture_entity_dialog"
            :identifier="'Bond Funder'"
        />
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../utils/api";
import MemberSearch from "../components/member_search";
import BeneficiarySearch from "../components/beneficiary_search";
import AgentSearch from "../components/agent_search";
import ViewCalculationResults from "./view_calculation_results.vue";
import AddEntity from "../../people/dialogs/onboarding.vue";

import { eventBus } from "../../../main";
import { payment_methods, BUSINESS_PARTNER_TYPES } from "../../../../config";

import { calculateBondPricing, businessProcessCorporateBond } from "../gql/mutations";

import { store } from "../../../../store";

export default {
    components: {
        MemberSearch,
        BeneficiarySearch,
        AgentSearch,
        ViewCalculationResults,
        AddEntity
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        open_calc_results_dialog: false,
        capture_entity_dialog: false,
        calulation_results: {},

        bond_types_list: [
            {
                bond_type_id: 1,
                name: 'Fixed Term',
                description: 'Withdrawal either at the end of the term OR monthly',
                principal_description: 'Once off initial lump-sum payment',
                max_annual_interest: 10
            },
            {
                bond_type_id: 2,
                name: 'Installment (Monthly Collections)',
                description: 'Withdrawal only at the end of the term',
                principal_description: 'Monthly installment amount',
                max_annual_interest: 10
            },
            {
                bond_type_id: 3,
                name: 'Annuity (Monthly Payouts)',
                description: 'The client can specify how much they want withdrawn',
                principal_description: 'Once off initial lump-sum payment',
                max_annual_interest: 10
            },
        ],

        e1: 1,
        start_date_menu: false,

        bond_details: {
            // Entites
            agent: null,
            member: null,
            beneficiary_entity: null,

            payout_banking: null,
            collection_banking: null,

            start_date: null,
            end_date: null,
            
            repayment_config: {
                payment_method: 'DEBIT ORDER',
                repayment_frequency: 'MONTHLY',
                elected_repayment_time_point: 0,
            },
        },
        pricing_details: {
            bond_type: {
                bond_type_id: 1,
                description: 'Withdrawal only at the end of the term',
                principal_description: 'Once off initial lump-sum payment',
            },

            principal_amount: 500,
            bond_term_units: 'MONTHS',
            bond_term: 9,

            annuity_monthly_payout_date: 1,
            annuity_payout_amount: 0,

            interest_config: {
                annual_interest_rate: 0,
            },
        },

        status: 'DRAFT',
        server_response_obj: null,
        submitting: false,

        payment_methods: payment_methods,


        refreshing_product_lookup_list: false,

        elected_payment_date_rules: [
            v => !!v || 'Elected payment date is required',
            v => (parseFloat(v) > 0 && parseFloat(v) < 32) || 'Must be between 1 and 31'
        ]
    }),
    computed: {
        derivedMonthlyInterestRate() {
            const bondTerm = parseFloat(this.pricing_details.bond_term);
            const result = Math.round(
                (
                    (this.pricing_details.interest_config.annual_interest_rate / bondTerm) + Number.EPSILON
                ) * 100 
            ) / 100;
            return result;
        },
        derivedMonthlyInterestAmount() {
            // const principalAmount = parseFloat(this.pricing_details.principal_amount);
            let principalAmount = 0;

            if (this.pricing_details.bond_type.bond_type_id === 2) { // Monthly collections
                principalAmount = parseFloat(this.pricing_details.principal_amount) * parseFloat(this.pricing_details.bond_term);
            } else { // Annuity and Fixed Term
                principalAmount = parseFloat(this.pricing_details.principal_amount);
            }

            const bondTerm = parseFloat(this.pricing_details.bond_term);
            const annualInterest = this.pricing_details.interest_config.annual_interest_rate / 100;

            const totalReturns = annualInterest * principalAmount;
            let result = totalReturns / bondTerm;
            result = Math.round( ( result + Number.EPSILON ) * 100 ) / 100;
            return result;
        },
        derivedEndOfTermAmount() {
            let principalAmount = 0;

            if (this.pricing_details.bond_type.bond_type_id === 2) { // Monthly collections
                principalAmount = parseFloat(this.pricing_details.principal_amount) * parseFloat(this.pricing_details.bond_term);
            } else { // Annuity and Fixed Term
                principalAmount = parseFloat(this.pricing_details.principal_amount);
            }

            const annualInterest = this.pricing_details.interest_config.annual_interest_rate / 100;

            const totalReturns = annualInterest * principalAmount;
            let result = totalReturns + principalAmount;
            result = Math.round( ( result + Number.EPSILON ) * 100 ) / 100;


            return result;
        },
        derivedProjectedEarnings() {
            // const principalAmount = parseFloat(this.pricing_details.principal_amount);

            let principalAmount = 0;

            if (this.pricing_details.bond_type.bond_type_id === 2) { // Monthly collections
                principalAmount = parseFloat(this.pricing_details.principal_amount) * parseFloat(this.pricing_details.bond_term);
            } else { // Annuity and Fixed Term
                principalAmount = parseFloat(this.pricing_details.principal_amount);
            }

            const annualInterest = this.pricing_details.interest_config.annual_interest_rate / 100;

            let totalReturns = annualInterest * principalAmount;
            totalReturns = Math.round( ( totalReturns + Number.EPSILON ) * 100 ) / 100;
            return totalReturns;
        },
        isCentralUnderwriterAdminUser() {
            const result = store.state.currentUserAccount.roles.filter(role => {
                                                            return (
                                                                role.role.level.includes(BUSINESS_PARTNER_TYPES.CENTRAL_UNDERWRITER) &&
                                                                role.role.name.toLowerCase() ===  'admin - central underwriter'
                                                            );
                                                        }).length > 0;

            return result;
        },
        electedRepaymentTimePointValid() {
            if (
                parseFloat(this.bond_details.repayment_config.elected_repayment_time_point) > 0 &&
                parseFloat(this.bond_details.repayment_config.elected_repayment_time_point) < 32
            ) {
                return true;
            }
            return false;
        },
        getBondEntityAddress() {
            const result = {
                line1: null,
                line2: null,
                line3: null,
                line4: null
            };

            if (this.bond_details.member !== null) {
                const entityAddr = this.bond_details.member.addresses.filter(addr => {
                    return (
                        (
                            addr.type === "Postal + Residential" ||
                            addr.type === "Residential"
                        ) && addr.deleted === false
                    )
                })[0];

                if (entityAddr) {
                    result.line1 = entityAddr.line1;
                    result.line2 = entityAddr.line2;
                    result.line3 = entityAddr.line3;
                    result.line4 = entityAddr.line4;
                }
            }

            return result;
        },
        getBondEntityPhone() {
            let phone = null;

            if (this.bond_details.member !== null) {
                const entityPhone = this.bond_details.member.contact_details.filter(cont => {
                    return (
                        cont.type === "Mobile" &&
                        cont.primary === true &&
                        cont.deleted === false
                    )
                })[0];

                if (entityPhone) {
                    phone = entityPhone.details;
                }
            }

            return phone;
        },
        getBondEntityEmail() {
            let phone = null;

            if (this.bond_details.member !== null) {
                const entityEmail = this.bond_details.member.contact_details.filter(cont => {
                    return (
                        cont.type === "Email" &&
                        cont.deleted === false
                    )
                })[0];

                if (entityEmail) {
                    phone = entityEmail.details;
                }
            }

            return phone;
        },
    },
    methods: {
        closeAddBondDialog() {
            eventBus.$emit('CLOSE_ADD_CORPORATE_BOND_DIALOG', this.server_response_obj);
        },

        openCaptureEntityDialog() {
            this.capture_entity_dialog = true;
        },
        closeCaptureEntityDialog(newEntity) {
            this.capture_entity_dialog = false;

            if (newEntity !== null) {
                this.pricing_details.member = newEntity;
            }
        },

        async submitCalculation() {
            try {
                const payloadObj = this.extractPricingPayload();
                const res = await this.$apollo
                                            .mutate({
                                                mutation: calculateBondPricing,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                
                // Show Results Dialog
                this.calulation_results = res.data.calculateBondPricing;
                this.open_calc_results_dialog = true;
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.calculateLoanPricing);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        submitCalculationBtnDisabled() {
            if (this.bond_details.start_date === null) {
                return true;
            }

            if (parseFloat(this.pricing_details.bond_term) <= 0) {
                return true;
            }

            return false;
        },

        goToStep2BtnDisabled() {
            if (this.bond_details.start_date === null) {
                return true;
            }

            if (parseFloat(this.pricing_details.bond_term) <= 0) {
                return true;
            }

            if (this.pricing_details.bond_type.bond_type_id === 2) { // Monthly collections
                if (
                    parseFloat(this.bond_details.repayment_config.elected_repayment_time_point) <= 0 ||
                    parseFloat(this.bond_details.repayment_config.elected_repayment_time_point) > 31
                ) {
                    return true;
                }
            }

            if (this.pricing_details.bond_type.bond_type_id === 3) { // Annuity Payouts
                if (
                    parseFloat(this.pricing_details.annuity_payout_amount) <= 0 ||
                    (
                        parseFloat(this.pricing_details.annuity_monthly_payout_date) <= 0 &&
                        parseFloat(this.pricing_details.annuity_monthly_payout_date) > 31 
                    )
                ) {
                    return true;
                }
            }

            return false;
        },

        submitBtnDisabled() {
            if (this.bond_details.member === null) {
                return true;
            }

            if (this.bond_details.beneficiary_entity === null) {
                return true;
            }

            if (this.bond_details.payout_banking === null) {
                return true;
            }

            if (this.pricing_details.bond_type.bond_type_id === 2) { // Monthly collections
                if (this.bond_details.repayment_config.payment_method === 'DEBIT ORDER') {
                    if (this.bond_details.collection_banking === null) {
                        return true;
                    }
                }
            }

            return false;
        },

        resetForm() {
            this.server_response_obj = null;

            this.e1 = 1;
            this.submitting = false;

            this.bond_details = {
                // Entites
                agent: null,
                member: null,

                payout_banking: null,
                collection_banking: null,

                start_date: null,
                end_date: null,

                repayment_config: {
                    payment_method: 'DEBIT ORDER',
                    repayment_frequency: 'MONTHLY',
                    elected_repayment_time_point: 0,
                },
            };
            this.pricing_details = {
                bond_type: {
                    bond_type_id: 1,
                    description: 'Withdrawal only at the end of the term',
                    principal_description: 'Once off initial lump-sum payment',
                },

                principal_amount: 500,
                bond_term_units: 'MONTHS',
                bond_term: 9,

                interest_config: {
                    annual_interest_rate: 0,
                },
            };

            // Re-Trigger bond type changed
            this.bondTypeChanged( this.pricing_details.bond_type );
        },

        async submitBond() {
            // Then submit the bond
            this.submitting = true;
            this.e1 = 3;
            
            // Prepare payload
            const quotePayloadObj = this.extractQuotePayload();

            // if (quotePayloadObj !== null) {
            //     console.log(quotePayloadObj)
            //     return;
            // }

            try {
                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcessCorporateBond,
                                                variables: {
                                                    input: quotePayloadObj
                                                },
                                            });
                this.submitting = false;
                if (res.data.businessProcessCorporateBond.errors === true) {
                    emitAlert(res.data.businessProcessCorporateBond.message);
                    return;
                }

                this.server_response_obj = res.data.businessProcessCorporateBond.business_object;

                emitSuccess(res.data.businessProcessCorporateBond.message);
                
                // Show Results Dialog
                this.closeAddBondDialog();
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessCorporateBond);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        extractPricingPayload() {
            const payloadObj = {
                    bond_type: parseInt(this.pricing_details.bond_type.bond_type_id),

                    start_date: this.bond_details.start_date,
                    principal_amount: parseFloat(this.pricing_details.principal_amount),
                    bond_term_units: this.pricing_details.bond_term_units,
                    bond_term: parseFloat(this.pricing_details.bond_term),

                    annual_interest_rate: parseFloat(this.pricing_details.interest_config.annual_interest_rate),
                };

            return payloadObj;
        },

        extractQuotePayload() {
            const pricingPayloadObj = this.extractPricingPayload();

            const result = {
                OP_TYPE: 'CREATE',
                // _id: null,
                
                main_entity: this.bond_details.member !== null ? this.bond_details.member._id : null,
                beneficiary_entity: this.bond_details.beneficiary_entity !== null ? this.bond_details.beneficiary_entity._id : null,
                agent: this.bond_details.agent !== null ? this.bond_details.agent._id : null,
                start_date: this.bond_details.start_date,
                end_date: this.bond_details.end_date,
                
                collection_banking: this.bond_details.collection_banking,
                payout_banking: this.bond_details.payout_banking,

                pricing_config: pricingPayloadObj,

                annuity_payout_amount: this.bond_details.annuity_payout_amount,
                annuity_elected_monthly_payout_date: this.bond_details.annuity_elected_monthly_payout_date,
                bond_monthly_payment_config: {
                    payment_method: this.bond_details.repayment_config.payment_method,
                    payment_frequency: this.bond_details.repayment_config.repayment_frequency,
                    elected_payment_date: parseInt(this.bond_details.repayment_config.elected_repayment_time_point),
                }
            };

            return result;
        },

        bondTypeChanged(bondTypeObj) {
            const bondTypeId = bondTypeObj.bond_type_id;
            const matchingBondTypeObj = this.bond_types_list.filter(item => { return item.bond_type_id === bondTypeId })[0];
            this.pricing_details.interest_config.annual_interest_rate = matchingBondTypeObj.max_annual_interest;
        },
        updateAnnualInterestRate(operation) {
            if (operation === 'plus') {
                this.pricing_details.interest_config.annual_interest_rate += 0.1;
            }

            if (operation === 'minus') {
                this.pricing_details.interest_config.annual_interest_rate -= 0.1;
            }
        },

        getTermPeriod(scheduleConfig) {
            const result = (parseFloat(scheduleConfig.term_end) - parseFloat(scheduleConfig.term_start)) + 1;
            return result;
        }
    },
    async mounted() {
        eventBus.$on('CLOSE_VIEW_BOND_CALCULATION_RESULTS_DIALOG', () => { this.open_calc_results_dialog = false; });
        eventBus.$on('CLOSE_ONBOARDING_DIALOG', this.closeCaptureEntityDialog);

        // Load interest rate
        this.bondTypeChanged( this.pricing_details.bond_type );
    },
    watch: {
        'bond_details.member': function(val) {
            if (val === null) {
                this.bond_details.payout_banking = null;
                this.bond_details.collection_banking = null;
            }
        },

        'bond_details.start_date': {
            handler(val) {
                if (val === null) {
                    this.bond_details.end_date = null;
                } else {
                    const startDate = new Date(val);
                    const bondTerm = parseFloat(this.pricing_details.bond_term);

                    const startDateMonth = startDate.getMonth();
                    startDate.setMonth(startDateMonth + bondTerm);

                    this.bond_details.end_date = startDate.toISOString().split('T')[0];
                }
            },
            deep: true
        }

    }
}
</script>
<style scoped>

</style>
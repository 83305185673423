<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Edit Basic Product Details</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeEditProductDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <div v-if="product_copy !== null">
                        <v-row style="background: #cfe4e9">
                            <v-col cols="12" sm="12">
                                <h3>Basic Details</h3>
                            </v-col>

                            <v-col cols="12" sm="12">
                                <v-text-field
                                    v-model="product_copy.name"
                                    :rules="name_rules"
                                    ref="name_field"
                                    color="secondary"
                                    label="Product Name"
                                    required
                                ></v-text-field>
                            </v-col>
                            <!-- <v-col cols="12" sm="2">
                                <v-text-field
                                    v-model="product_copy.max_payment_holidays"
                                    prefix="DAYS"
                                    color="secondary"
                                    label="Maximum Payment Holidays"
                                    type="number"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-text-field
                                    v-model="product_copy.min_loan_amount"
                                    prefix="ZAR"
                                    color="secondary"
                                    label="Minimum Loan Amount"
                                    type="number"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-text-field
                                    v-model="product_copy.max_loan_amount"
                                    prefix="ZAR"
                                    color="secondary"
                                    label="Maximum Loan Amount"
                                    type="number"
                                    required
                                ></v-text-field>
                            </v-col> -->
                        </v-row>
                        
                        <!-- <v-row style="background: #ffeeb6">
                            <v-col cols="12" sm="12" >
                                <h3>Installments</h3>
                            </v-col>
                            <v-col cols="12" sm="12">
                                <v-text-field
                                    v-model="product_copy.min_number_of_installments"
                                    color="secondary"
                                    label="Minimum Installments"
                                    type="number"
                                    hint="Minimum number of installments"
                                    required
                                ></v-text-field>
                            </v-col>
                        </v-row> -->
                        
                        <!-- <v-row style="background: #e9e9e9">
                            <v-col cols="12" sm="12" >
                                <h3>Fees</h3>
                            </v-col>
                            <v-col cols="12" sm="4" >
                                <v-text-field
                                    v-model="product_copy.monthly_fixed_fee"
                                    color="secondary"
                                    label="Fixed Monthly Fee"
                                    type="number"
                                    prefix="ZAR"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" >
                                <v-text-field
                                    v-model="product_copy.monthly_finance_charge_percentage"
                                    color="secondary"
                                    label="Monthly Finance Charge (%)"
                                    type="number"
                                    prefix="%"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" >
                                <v-text-field
                                    v-model="product_copy.monthly_finance_charge_amount"
                                    color="secondary"
                                    label="Monthly Finance Charge Amount (Fixed)"
                                    type="number"
                                    prefix="ZAR"
                                    required
                                ></v-text-field>
                            </v-col>
                        </v-row> -->
                    </div>
                </v-card-text>
                <!-- Submission Results Section -->
                <div v-if="submitting">
                    <v-card-text class="text-center">
                        <v-progress-linear
                            color="green"
                            class="mb-0"
                            height="10"
                            indeterminate
                            :active="submitting"
                        ></v-progress-linear>
                    </v-card-text>
                    <v-list flat>
                        <v-subheader>Saving Product Info</v-subheader>
                        <v-list-item-group color="primary">
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon>done</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Submiting edited product features</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </div>

                <!-- Lower Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        large
                        color="success"
                        :disabled="submitProductDetailsBtnDisabled()"
                        @click="submitProductDetails()"
                    >
                        <v-icon large>save</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../../../utils/api";
import { eventBus } from "../../../../../main";

import { productProcessLoanProductPrimary } from "../gql/mutations";

export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        product: {
            type: Object,
            default: () => {
                return {
                    _id: null,
                    name: null,
                    premium: null,
                    broker_commission: null,
                    features: {
                        principal_members_per_scheme: null,
                        dependents_size: null,
                        principal_member_max_age: null,
                        principal_member_spouse_max_age: null,
                        dependent_max_age: null,
                        general_waiting_period: null,
                        suicide_waiting_period: null,
                        accidental_cover: null,
                        repatriation: null,
                    },
                }
            }
        },
        product_copy: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data: () => ({
        server_resp_obj: null,
        submitting: false,

        name_rules: [
            v => !!v || 'Name is required',
            v => v.length >= 2 || 'Min 2 characters'
        ],
    }),
    methods: {
        closeEditProductDialog() {
            eventBus.$emit('CLOSE_EDIT_PRODUCT_DETAILS_DIALOG', this.server_resp_obj);
        },
        resetForm() {
            this.submitting = false;
            this.server_resp_obj = null;
            eventBus.$emit('RESET_PRIMARY_PRODUCT_EDIT_FORM', this.product_copy);
        },

        submitProductDetailsBtnDisabled() {
            if (this.product !== null && this.product_copy !== null) {
                if (
                    this.product_copy.name.trim() === this.product.name.trim() // &&

                    // parseFloat(this.product_copy.max_payment_holidays) === parseFloat(this.product.max_payment_holidays) &&

                    // parseFloat(this.product_copy.min_loan_amount) === parseFloat(this.product.min_loan_amount) &&
                    // parseFloat(this.product_copy.max_loan_amount) === parseFloat(this.product.max_loan_amount) &&
                    
                    // parseFloat(this.product_copy.min_number_of_installments) === parseFloat(this.product.min_number_of_installments) &&

                    // parseFloat(this.product_copy.monthly_fixed_fee) === parseFloat(this.product.monthly_fixed_fee) &&
                    // parseFloat(this.product_copy.monthly_finance_charge_percentage) === parseFloat(this.product.monthly_finance_charge_percentage) &&
                    // parseFloat(this.product_copy.monthly_finance_charge_amount) === parseFloat(this.product.monthly_finance_charge_amount)
                ) {
                    return true;
                }
            }
            
            return false;
        },

        async submitProductDetails() {

            this.submitting = true;

            try {
                // Payload with old values
                const payload = {
                    name: this.product_copy.name.trim(),

                    // max_payment_holidays: parseFloat(this.product_copy.max_payment_holidays),

                    // min_loan_amount: parseFloat(this.product_copy.min_loan_amount),
                    // max_loan_amount: parseFloat(this.product_copy.max_loan_amount),
                    
                    // min_number_of_installments: parseFloat(this.product_copy.min_number_of_installments),
                    
                    // monthly_fixed_fee: parseFloat(this.product_copy.monthly_fixed_fee),
                    // monthly_finance_charge_percentage: parseFloat(this.product_copy.monthly_finance_charge_percentage),
                    // monthly_finance_charge_amount: parseFloat(this.product_copy.monthly_finance_charge_amount),
                };

                const payloadObj = {
                    OP_TYPE: 'UPDATE',
                    _id: this.product._id,

                    ...payload
                };

                const res = await this.$apollo
                                            .mutate({
                                                mutation: productProcessLoanProductPrimary,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.productProcessLoanProductPrimary.errors === true) {
                    emitAlert(res.data.productProcessLoanProductPrimary.message);
                    this.submitting = false;
                    return;
                }

                this.server_resp_obj = res.data.productProcessLoanProductPrimary.business_object;
                this.closeEditProductDialog();
                emitSuccess(res.data.productProcessLoanProductPrimary.message);

                // Reset fields
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.productProcessLoanProductPrimary.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        }
    },
    async mounted() {
        
    },
    computed: {

    },
    watch: {
        
    }
}
</script>
<style scoped>
.error-multi-line {
  white-space: pre-line;
}

</style>
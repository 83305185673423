import gql from 'graphql-tag'

export const authDeepRefresh = gql`
    mutation authDeepRefresh {
        authDeepRefresh {
            errors
            message
            user {
                _id
                active
                created
                captured_by {
                    _id
                    entity {
                        _id
                        name
                        surname
                    }
                }
                roles {
                    role {
                        _id
                        name
                        level
                        allowed_screens {
                            _id
                            created
                            screen {
                                _id
                                name
                                description
                                category
                                description
                            }
                        }
                        allowed_reports{
                            _id
                            created
                            report {
                                _id
                                name
                                group
                                report_number
                                description
                            }
                        }
                        allowed_business_processes {
                            _id
                            created
                            process {
                                _id
                                name
                                group
                                description
                                process_number
                            }
                        }
                        allowed_dashboards {
                            _id
                            created
                            dashboard {
                                _id
                                dashboard_number
                                name
                                group
                                description
                            }
                        }
                    }
                }
                business_partner {
                    _id
                    name
                    bp_number
                }
                entity {
                    _id

                    name
                    surname
                    id_number
                    gender
                    date_of_birth
                    marital_status
                    life_status

                    contact_details {
                        _id
                        type
                        primary
                        details
                        deleted
                    }
                }
            }
            context_list {
                selected
                business_partner {
                    _id
                    name
                    bp_number
                }
            }
        }
    }
`

export const authSwitchContext = gql`
    mutation authSwitchContext($context_id: String!) {
        authSwitchContext(context_id: $context_id) {
            errors
            message
            context_list {
                selected
                business_partner {
                    _id
                    bp_number
                    name
                    company_reg_number
                    type
                    status
                }
            }
        }
    }
`

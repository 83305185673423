<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            persistent
        >
            <div style="background:white; overflow-y:auto; height:100%">
                <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d;">
                    <v-spacer></v-spacer>
                    <v-toolbar-title><b>Capture Signatures</b></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                class="mx-2"
                                color="red"
                                small
                                v-on="on"
                                @click="resetForm()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Reset Form</span>
                    </v-tooltip>
                    <v-btn icon @click="closeAddPolicyDialog()" v-if="server_response_obj === null">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card>
                    <!-- BASIC DETAILS -->
                    <v-card-text>
                        <v-row style="background:#f4000030">
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-model="signature_location"
                                    color="secondary"
                                    label="Signature Location"
                                    type="text"
                                    hint="Name of Location where signature is being captured"
                                    persistent-hint
                                    required
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <br><br><br>
                        <v-expansion-panels focusable>
                            <!-- Principal Member -->
                            <v-expansion-panel
                                expand
                                key="principal_member"
                                style="background:#eaeaea; padding-bottom:10px"
                            >
                                <v-expansion-panel-header>
                                    <h3>Principal Member Signature And Initials</h3>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <br>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <h1>Principal Member Signature</h1><br>
                                            <Signature
                                                :unique_identifier="principal_member.signature.id"
                                                :signature_purpose="'PRINCIPAL_MEMBER_SIGNATURE'"
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <h1>Principal Member Initials</h1><br>
                                            <Signature
                                                :unique_identifier="principal_member.initials.id"
                                                :signature_purpose="'PRINCIPAL_MEMBER_INITIALS'"
                                            />
                                        </v-col>
                                    </v-row>
                                    <br>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <!-- <v-expansion-panel
                                v-if="(
                                    policy.principal_member.is_premium_payer === false &&
                                    policy.payment_information.premium_payer.payment_method === 'DEBIT ORDER'
                                )"
                                expand
                                key="premium_payer"
                                style="background:#eaeaea; padding-bottom:10px"
                            > -->
                            <v-expansion-panel
                                v-if="(
                                    premiumPayerSameAsPrincipalMember === false &&
                                    policy.payment_information.premium_payer.payment_method === 'DEBIT ORDER'
                                )"
                                expand
                                key="premium_payer"
                                style="background:#eaeaea; padding-bottom:10px"
                            >
                                <!-- <v-expansion-panel-content> -->
                                <v-expansion-panel-header>
                                    <h3>Premium Payer Signature And Initials</h3>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <br>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            v-if="(
                                                premiumPayerSameAsPrincipalMember === false &&
                                                policy.payment_information.premium_payer.payment_method === 'DEBIT ORDER'
                                            )"
                                        >
                                            <h1>Premium Payer Signature</h1><br>
                                            <Signature
                                                :unique_identifier="premium_payer.signature.id"
                                                :signature_purpose="'PREMIUM_PAYER_SIGNATURE'"
                                            />
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            v-if="(
                                                premiumPayerSameAsPrincipalMember === false &&
                                                policy.payment_information.premium_payer.payment_method === 'DEBIT ORDER'
                                            )"
                                        >
                                            <h1>Premium Payer Initials</h1><br>
                                            <Signature
                                                :unique_identifier="premium_payer.initials.id"
                                                :signature_purpose="'PREMIUM_PAYER_INITIALS'"
                                            />
                                        </v-col>
                                    </v-row>
                                    <br>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>

                        
                    </v-card-text>
                    <!-- Button Section -->
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            large
                            color="success"
                            :disabled="submitBtnDisabled()"
                            @click="savePolicy()"
                        >
                            <v-icon large>save</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
                <v-card>
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting Policy Details
                        <v-progress-linear
                            indeterminate
                            color="green"
                            class="mb-0"
                            height="5"
                            :active="submitting"
                        ></v-progress-linear>
                    </v-card-text>
                    <v-list flat v-if="submitting">
                        <v-subheader>Saving Policy Signatures</v-subheader>
                    </v-list>
                </v-card>
            </div>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess, submitSignatures } from "../../../../../utils/api";
import { eventBus } from "../../../../../main";

import Signature from '../components/signature.vue';
import { Buffer } from 'buffer';

export default {
    components: {
        Signature,
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        policy: {
            type: Object,
            default: null
        },
    },
    data: () => ({
        signature_target: null,

        status: 'DRAFT',
        server_response_obj: null,
        submitting: false,

        signature_location: null,

        principal_member: {
            signature: {
                id: 100,
                data: null,
            },
            initials: {
                id: 101,
                data: null,
            },
        },
        premium_payer: {
            signature: {
                id: 200,
                data: null,
            },
            initials: {
                id: 201,
                data: null,
            },
        },
    }),
    computed: {
        premiumPayerSameAsPrincipalMember() {
            const principalEntityId = this.policy.principal_member.entity !== null ? this.policy.principal_member.entity._id.toString() : null;
            const premiumPayerEntityId = this.policy.premium_payer.entity !== null ? this.policy.premium_payer.entity._id.toString() : null;

            if (
                (principalEntityId === null) &&
                (premiumPayerEntityId === null)
            ) {
                return false;
            }

            const principaIsPremiumPayer = (principalEntityId === premiumPayerEntityId);
            return principaIsPremiumPayer;
        }
    },
    methods: {
        closeAddPolicyDialog() {
            eventBus.$emit('CLOSE_CAPTURE_SIGNATURE_DIALOG', this.server_response_obj);
        },

        submitBtnDisabled() {
            // policy.payment_information.premium_payer.payment_method === 'DEBIT ORDER'

            if (this.premiumPayerSameAsPrincipalMember === false) {
                if (this.policy.payment_information.premium_payer.payment_method === 'DEBIT ORDER') {
                    if (
                        this.principal_member.signature.data === null ||
                        this.principal_member.initials.data === null ||
                        this.premium_payer.signature.data === null ||
                        this.premium_payer.initials.data === null ||
                        this.signature_location === null
                    ) {
                        return true;
                    }
                } else {
                    if (
                        this.principal_member.signature.data === null ||
                        this.principal_member.initials.data === null ||
                        this.signature_location === null
                    ) {
                        return true;
                    }
                }
            } else {
                if (
                    this.principal_member.signature.data === null ||
                    this.principal_member.initials.data === null ||
                    this.signature_location === null
                ) {
                    return true;
                }
            }

            if (typeof(this.signature_location) === 'string') {
                if (this.signature_location.trim() === '') {
                    return true;
                }
            }
            return false;
        },

        resetForm() {
            this.server_response_obj = null;

            this.submitting = false;

            // DEPENDENTS
            // eventBus.$emit('RESET_DEPENDENT_FORM_FIELDS');

            this.principal_member.signature.data = null;
            this.principal_member.initials.data = null;
            this.premium_payer.signature.data = null;
            this.premium_payer.initials.data = null;
            this.signature_location = null;

            eventBus.$emit('CLEAR_SIGNATURE_DATA', this.principal_member.signature.id);
            eventBus.$emit('CLEAR_SIGNATURE_DATA', this.principal_member.initials.id);
            eventBus.$emit('CLEAR_SIGNATURE_DATA', this.premium_payer.signature.id);
            eventBus.$emit('CLEAR_SIGNATURE_DATA', this.premium_payer.initials.id);
        },

        async savePolicy() {
            this.submitting = true;

            try {
                if (this.premiumPayerSameAsPrincipalMember === true) {
                    this.premium_payer.signature.data = this.principal_member.signature.data;
                    this.premium_payer.initials.data = this.principal_member.initials.data;
                }

                const res = await submitSignatures(
                    this.principal_member.signature.data,
                    this.principal_member.initials.data,

                    this.premium_payer.signature.data,
                    this.premium_payer.initials.data,
                    
                    this.policy._id,
                    this.signature_location
                );

                if (res.errors === true) {
                    emitAlert(res.message);
                    this.submitting = false;
                    return;
                }

                this.server_response_obj = res.policy;

                emitSuccess(res.message);
                this.closeAddPolicyDialog();

                // // Reset form
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessProspectPolicy.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },

    },
    async mounted() {
        eventBus.$on('SIGNATURE_CHANGED', (signatureData) => {
            const signature_purpose = signatureData.signature_purpose;
            const data_url = signatureData.data_url;

            if (data_url !== null) {
                // Convert base64 data url to binary
                const array = []
                const blobBin = Buffer.from(data_url.split(',')[1], 'base64').toString('binary')
                for (let i = 0; i < blobBin.length; i++) {
                    array.push(blobBin.charCodeAt(i))
                }
                const file = new Blob([new Uint8Array(array)], {type: 'image/png'});

                // Set signature
                if (signature_purpose === 'PRINCIPAL_MEMBER_SIGNATURE') {
                    this.principal_member.signature.data = file;
                }

                if (signature_purpose === 'PREMIUM_PAYER_SIGNATURE') {
                    this.premium_payer.signature.data = file;
                }

                // Set initials
                if (signature_purpose === 'PRINCIPAL_MEMBER_INITIALS') {
                    this.principal_member.initials.data = file;
                }

                if (signature_purpose === 'PREMIUM_PAYER_INITIALS') {
                    this.premium_payer.initials.data = file;
                }
            } else {
                // Set signature
                if (signature_purpose === 'PRINCIPAL_MEMBER_SIGNATURE') {
                    this.principal_member.signature.data = null;
                }

                if (signature_purpose === 'PREMIUM_PAYER_SIGNATURE') {
                    this.premium_payer.signature.data = null;
                }

                // Set initials
                if (signature_purpose === 'PRINCIPAL_MEMBER_INITIALS') {
                    this.principal_member.initials.data = null;
                }

                if (signature_purpose === 'PREMIUM_PAYER_INITIALS') {
                    this.premium_payer.initials.data = null;
                }
            }
        });
    },
    watch: {}
}
</script>
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Debit Order Status</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="debit_order_status_details.date"
                                label="Debit Order Date"
                                color="secondary"
                                required
                                disabled
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="debit_order_status_details.amount"
                                label="Amount"
                                color="secondary"
                                required
                                disabled
                            ></v-text-field>
                        </v-col>

                        <!-- Member Id Number -->
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="debit_order_status_details.status"
                                label="Status"
                                color="secondary"
                                required
                                disabled
                            ></v-text-field>
                        </v-col>

                        <!-- Elected Payment Date -->
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="debit_order_status_details.days_until_confirmation"
                                label="Days Until Confirmation"
                                color="secondary"
                                required
                                disabled
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        <!-- Fetching Debit Order Details
                        <v-progress-linear
                            indeterminate
                            color="green"
                            class="mb-0"
                        ></v-progress-linear> -->
                        <v-overlay :value="submitting" :absolute="true">
                            <v-progress-circular
                                color="white"
                                indeterminate
                                size="150"
                                width="9"
                            >
                                <h3 class="text-center">Loading...</h3>
                            </v-progress-circular>
                        </v-overlay>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <!-- <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom v-if="search_field_selected">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                @click="submitSearchQuery()"
                            >
                                <v-icon large>search</v-icon>
                            </v-btn>
                        </template>
                        <span>Submit Search Query</span>
                    </v-tooltip>
                </v-card-actions> -->
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { eventBus } from '../../../main';
import { emitAlert } from '../../../utils/api';
import { finGetDebitOrderStatus } from "../gql/queries";
export default {
    name: 'ViewDebitOrderStatusDialog',
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        loan: {
            type: Object,
            required: true,
            default: null
        },
    },
    data: () => ({
        effective_date_menu: false,
        debit_order_status_details: {
            date: null,
            amount: null,
            status: null,
            days_until_confirmation: null
        },

        submitting: true
    }),
    methods: {
        closeDialog() {
            eventBus.$emit('CLOSE_VIEW_DEBIT_ORDER_STATUS_DIALOG', null);
        },
    },
    mounted() {
        
    },
    computed: {},
    watch: {
        async dialog(val) {
            if (val === true) {
                // load debit order details
                this.submitting = true;

                try {
                    const res = await this.$apollo
                                            .query({
                                                query: finGetDebitOrderStatus,
                                                // fetchPolicy: 'network-only',
                                                fetchPolicy: 'no-cache',
                                                errorPolicy: 'all',
                                                variables: {
                                                    input: {
                                                        business_object_type: 'LOAN',
                                                        _id: this.loan._id
                                                    }
                                                },
                                            });
                    this.submitting = false;

                    this.debit_order_status_details.date = res.data.finGetDebitOrderStatus.date;
                    this.debit_order_status_details.amount = res.data.finGetDebitOrderStatus.amount !== -1 ? res.data.finGetDebitOrderStatus.amount : '-';
                    this.debit_order_status_details.status = res.data.finGetDebitOrderStatus.status;
                    this.debit_order_status_details.days_until_confirmation = res.data.finGetDebitOrderStatus.days_until_confirmation !== -1 ? res.data.finGetDebitOrderStatus.days_until_confirmation : '-';
                } catch (error) {
                    this.submitting = false;
                    console.log(error);
                    if (error !== undefined) {
                        emitAlert(error.data.getBusinessObject.message);
                    } else {
                        emitAlert('The server has taken too long in responding to your request');
                    }
                }
            }

            if (val === false) {
                this.debit_order_status_details.date = null;
                this.debit_order_status_details.amount = null;
                this.debit_order_status_details.status = null;
                this.debit_order_status_details.days_until_confirmation = null;
            }
        }
    }
}
</script>
<style scoped>

</style>
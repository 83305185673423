<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Onboard an Agent</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn
                    v-if="e1!==6"
                    icon
                    @click="closeOnboardingDialog()"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-stepper v-model="e1">
                <v-stepper-header>
                    <v-stepper-step :complete="e1 > 1" step="1" color="success">Details</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 2" step="2" color="success">Contract</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 3" step="3" color="success">Commission Rules</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="4" color="success">Processing</v-stepper-step>
                </v-stepper-header>
            
                <v-stepper-items>
                    <!-- Basic Details -->
                    <v-stepper-content step="1">
                        <v-card>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <MemberSearch :member="agent_details" label="Member Being Onboarded" />
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <AgentSearch :member="agent_details" label="Agent Who Recruited This Member" />
                                    </v-col>
                                </v-row>
                            </v-card-text>
                             <v-progress-linear
                                color="green"
                                class="mb-0"
                                height="10"
                                indeterminate
                                :active="backend_check"
                            ></v-progress-linear>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    fab
                                    color="amber"
                                    :disabled="goToStep2BtnDisabled()"
                                    @click="goToStep2()"
                                >
                                    <v-icon large>keyboard_arrow_right</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Documents Section -->
                    <v-stepper-content step="2">
                        <v-card>
                            <v-card-text>
                                <v-row style="background:#eaeaea; padding-bottom:10px">
                                    <!-- Contract Document Type -->
                                    <v-col cols="12" sm="5">
                                        <v-text-field
                                            v-model="agent_details.contract_file.type"
                                            label="Document Type"
                                            required
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                    <!-- Contract Document -->
                                    <v-col cols="12" sm="6">
                                        <v-file-input
                                            v-model="agent_details.contract_file.file"
                                            color="secondary"
                                            accept="application/pdf, image/*"
                                            label="File/Document"
                                        ></v-file-input>
                                    </v-col>
                                    <!-- Contract Preview -->
                                    <v-col cols="12" sm="1">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    fab
                                                    color="amber"
                                                    v-on="on"
                                                    @click="previewFile(agent_details.contract_file)"
                                                    :disabled="agent_details.contract_file.file===null || agent_details.contract_file.file===undefined"
                                                >
                                                    <v-icon>remove_red_eye</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Preview File</span>
                                        </v-tooltip>
                                    </v-col>
                                    <!-- Contract Start Date -->
                                    <v-col cols="12" sm="5">
                                        <v-menu
                                            v-model="start_date_menu"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="agent_details.start_date"
                                                color="secondary"
                                                label="Start Date"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                color="secondary"
                                                v-model="agent_details.start_date"
                                                @input="start_date_menu = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <!-- Contract End Date -->
                                    <v-col cols="12" sm="5">
                                        <v-menu
                                            v-model="end_date_menu"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="agent_details.end_date"
                                                color="secondary"
                                                label="End Date"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                color="secondary"
                                                v-model="agent_details.end_date"
                                                @input="end_date_menu = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <!-- Contract Approved -->
                                    <v-col cols="12" sm="1">
                                        <v-checkbox
                                            v-model="agent_details.contract_file.approved"
                                            color="secondary"
                                            label="Approved"
                                            required
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="e1 = 1"
                                >
                                    <v-icon large>keyboard_arrow_left</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    fab
                                    color="amber"
                                    :disabled="goToStep3BtnDisabled()"
                                    @click="goToStep3()"
                                >
                                    <v-icon large>keyboard_arrow_right</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Commission Rules -->
                    <v-stepper-content step="3">
                        <v-card>
                            <v-card-text>
                                <v-row
                                    v-for="commission in commission_rules"
                                    :key="commission.id"
                                    style="background:#eaeaea; padding-bottom:10px"
                                >
                                    <v-col cols="12" sm="6">
                                        <v-select
                                            v-model="commission.type"
                                            :items="agent_commission_rules"
                                            label="Commission Rule Type"
                                            color="secondary"
                                            required
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            v-model="commission.amount"
                                            :prefix="commission.type === 'PERCENTAGE_PER_POLICY' ? '%' : 'ZAR'"
                                            label="Amount"
                                            autocomplete="some-random-text"
                                            type="number"
                                            color="secondary"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <div style="background:white; height: 10px; width: 100%"></div>
                                </v-row>
                                <!-- Buttons for Adding/Removing Banking Details -->
                                <v-row>
                                    <v-col sm="10"></v-col>
                                    <v-col sm="2">
                                        <v-tooltip bottom v-if="commission_rules.length > 1">
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    class="mx-2"
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="removeCommissionRule()"
                                                >
                                                    <v-icon>remove</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Remove Commission Rule</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="addCommissionRules()"
                                                >
                                                    <v-icon>add</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Add Another Commission Rule</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="e1 = 2"
                                >
                                    <v-icon large>keyboard_arrow_left</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            large
                                            color="success"
                                            v-on="on"
                                            :disabled="submitAgentBtnDisabled()"
                                            @click="submitAgent()"
                                        >
                                            <v-icon large>save</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Submit Agent's Details</span>
                                </v-tooltip>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Submission Results Section -->
                    <v-stepper-content step="4">
                         <v-card light >
                            <v-card-text class="text-center">
                                <v-progress-linear
                                    color="green"
                                    class="mb-0"
                                    height="10"
                                    indeterminate
                                    :active="submitting"
                                ></v-progress-linear>
                            </v-card-text>
                            <v-list flat v-if="submitting">
                                <v-subheader>Saving Agent Info</v-subheader>
                                <!-- <v-list-item-group v-model="item" color="primary"> -->
                                <v-list-item-group color="primary">
                                    <v-list-item
                                        v-for="(item, i) in backendActionItems"
                                        :key="i"
                                    >
                                        <v-list-item-icon>
                                            <v-icon>done</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ item }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="success"
                                    @click="capturingDone()"
                                >
                                    <v-icon large>done</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>
        <DocPreviewDialog
            :dialog="open_doc_preview_dialog"
            :url="file_url"
            :doc_type="doc_type"
            @CLOSE_DOC_PREVIEW_DIALOG="closeDocPreviewDialog()"
        />
    </v-row>
</template>
<script>
import AgentSearch from "../components/agent_search";
import MemberSearch from "../components/member_search";
import DocPreviewDialog from '../../../components/doc_preview';
import { emitAlert, emitSuccess, submitDocumentumFile } from "../../../utils/api";
import ObjectID from "bson-objectid";
import { eventBus } from "../../../main";

import { searchAgentAutocomplete } from "../gql/queries";
import { entityProcessAgent } from "../gql/mutations";
import {
    document_types,
    agent_commission_rules,
} from "../../../../config";

export default {
    components: {
        AgentSearch,
        MemberSearch,
        DocPreviewDialog,
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        start_date_menu: false,
        end_date_menu: false,

        error_message: null,
        submitting: false,
        backendActionItems: [],
        server_response_obj: null,

        // File Preview Dialog
        file_url: null,
        doc_type: null,

        e1: 1,
        agent_details: {
            agent: null,
            member: null,
            start_date: null,
            end_date: null,
            contract_file: {
                type: 'Agent Contract',
                file: null,
                approved: false,
                valid: false,
            }
        },

        commission_rules: [
            {
                id: 1,
                type: null,
                amount: 0,
            }
        ],

        open_doc_preview_dialog: false,
        backend_check: false,
        agent_commission_rules: agent_commission_rules,
        document_types: document_types.filter(doc_type => { return doc_type.type === 'entity' }),
    }),
    methods: {
        closeOnboardingDialog() {
            eventBus.$emit('CLOSE_CREATE_AGENT_DIALOG', this.server_response_obj);
        },

        //--------------------ADD/REMOVE COMMISSION RULES--------------------------//
        addCommissionRules() {
            const size = this.commission_rules.length;

            this.commission_rules.push({
                id: size + 1,
                type: null,
                amount: 0
            });
        },
        removeCommissionRule() {
            this.commission_rules.pop();
        },

        resetForm() {
            this.e1 = 1;
            this.server_response_obj = null;
            this.submitting = false;
            this.backendActionItems = [];

            this.agent_details = {
                agent: null,
                member: null,
                start_date: null,
                end_date: null,
                contract_file: {
                    type: 'Agent Contract',
                    file: null,
                    approved: false,
                    valid: false,
                }
            };

            this.commission_rules = [
                {
                    id: 1,
                    type: null,
                    amount: 0,
                }
            ];
        },

        previewFile(fileObject) {
            // Set file oobject so we can trigger the readAsDataURL function
            const reader = new FileReader();

            reader.addEventListener("load", () => {
                // Set PDF-Viewer URL to the selected file Base64 String
                this.file_url = reader.result;
                if (fileObject.file.type === 'application/pdf') {
                    this.doc_type = 'pdf';
                } else {
                    this.doc_type = 'image';
                }

                // Display the preview dialog
                this.open_doc_preview_dialog = true;
            }, false);

            reader.readAsDataURL(fileObject.file);
        },
        closeDocPreviewDialog() {
            this.open_doc_preview_dialog = false;
        },

        goToStep2BtnDisabled() {
            if (this.agent_details.member === null || this.agent_details.member === undefined) {
                return true;
            }

            return false;
        },
        async goToStep2() {
            // Check if the entity already has an agent profile attached to them
            this.backend_check = true;

            try {
                const res = await this.$apollo
                                            .query({
                                                query: searchAgentAutocomplete,
                                                // fetchPolicy: 'network-only',
                                                fetchPolicy: 'no-cache',
                                                errorPolicy: 'all',
                                                variables: {
                                                    objectType: 'AGENT',
                                                    pagination: {
                                                        page: this.page,
                                                        limit: this.limit
                                                    },
                                                    searchInput: {
                                                        entity: this.agent_details.member._id
                                                    }
                                                },
                                            });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.backend_check = false;
                    return;
                }

                this.backend_check = false;
                if (res.data.getBusinessObject.business_objects.length === 0) {
                    this.e1 = 2;
                    return;
                }
                
                emitAlert('An agent profile is already created for the selected person');
            } catch (error) {
                this.backend_check = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
        
        validateDocuments() {
        },
        capturingDone() {
            this.closeOnboardingDialog();
            this.resetForm();
        },
        goToStep3BtnDisabled() {
            // if (
            //     this.agent_details.contract_file.file === null ||
            //     this.agent_details.contract_file.file === undefined ||
            //     this.agent_details.start_date === null ||
            //     this.agent_details.end_date === null ||
            //     this.agent_details.contract_file.approved === false
            // ) {
            //     return true;
            // }

            return false;
        },
        goToStep3() {
            this.e1 = 3;
        },

        submitAgentBtnDisabled() {
            const validCommissionRules = this.commission_rules.filter(com_rule => {
                return (com_rule.type !== null && (parseFloat(com_rule.amount)) > 0)
            });

            if (validCommissionRules.length === 0) {
                return true;
            }

            return false;
        },

        async submitAgent() {
            this.e1 = 4;

            this.submitting = true;
            this.backendActionItems.push("Saving agent info");

            const agentObj = this.extractAgentInfo();


            try {
                // const res = await createAgent(agentObj);
                // if (res.errors === true) {
                //     emitAlert(res.message);
                //     this.submitting = false;
                //     this.backendActionItems = [];
                //     return;
                // }

                const res = await this.$apollo
                                            .mutate({
                                                mutation: entityProcessAgent,
                                                variables: {
                                                    input: {
                                                        OP_TYPE: 'CREATE',
                                                        
                                                        ...agentObj
                                                    }
                                                },
                                            });
                if (res.data.entityProcessAgent.errors === true) {
                    emitAlert(res.data.entityProcessAgent.message);
                    this.submitting = false;
                    this.backendActionItems = [];
                    return;
                }

                this.backendActionItems.push("Saving agent info succeeded");


                // Now upload documents to documentum
                const agentId = res.data.entityProcessAgent.business_object._id;

                if (agentObj.documents.length > 0) {
                    this.backendActionItems.push("Saving agent's documents");

                    const file_id = this.agent_details.contract_file._id;
                    const file = this.agent_details.contract_file.file;

                    const uploadRes = await submitDocumentumFile(file_id, file, 'agent', agentId);
                    if (uploadRes.errors === true) {
                        emitAlert(uploadRes.message);
                        this.submitting = false;
                        this.backendActionItems.push(`  --> Uploading ${file.name} failed`);
                        return;
                    }
                    
                    this.backendActionItems.push(`  --> Uploading ${file.name} succeeded`);
                }

                this.server_response_obj = res.data.entityProcessAgent.business_object;
                this.closeOnboardingDialog();

                emitSuccess(res.data.entityProcessAgent.message);
                this.resetForm();

            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.entityProcessAgent.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
        extractAgentInfo() {
            const agentObj = {
                entity: null, 
                recruiting_agent: null,
            };

            agentObj.entity = this.agent_details.member._id;
            agentObj.recruiting_agent = (this.agent_details.agent !== null && this.agent_details.agent !== undefined) ? this.agent_details.agent._id : null;


            // ----------------------------------Extract documents if there are any
            const documents = [];
            if (this.agent_details.contract_file.file !== null) {
                const objID = ObjectID();
                this.agent_details.contract_file._id = objID.toString();

                documents.push({
                    _id: objID.toString(), //Generate mngodb id
                    type: this.agent_details.contract_file.type,
                    name: this.agent_details.contract_file.file.name,
                    mime_type: this.agent_details.contract_file.file.type,
                    start_date: this.agent_details.start_date,
                    end_date: this.agent_details.end_date,
                    approved: this.agent_details.contract_file.approved,
                });
            }

            agentObj.documents = documents;

            // ----------------------------------Extract commission rules if there are any
            const validCommissionRules = this.commission_rules
                                                    .filter(com_rule => {
                                                        return (com_rule.type !== null && (parseFloat(com_rule.amount)) > 0)
                                                    });

            const arrCopy = JSON.parse(JSON.stringify(validCommissionRules))
                                    .map(rule => {
                                        delete rule.id;
                                        rule.amount = parseFloat(rule.amount);

                                        return rule;
                                    });

            agentObj.commission_rules = arrCopy;

            return agentObj;
        }
    },
    mounted() {

    },
    watch: {
    }
}
</script>
<style scoped>

</style>
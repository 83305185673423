<template>
    <div>
        <v-toolbar
            color="secondary"
            dark
            class="pa-0"
            height="50"
            extension-height="3"
        >
            <v-row>
                <!-- Grid Buttons -->
                <v-col sm="8" style="margin-top:10px">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="refreshDataTable()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh</span>
                    </v-tooltip>
                </v-col>


                <!-- Action Buttons -->
                <v-col sm="4" style="margin-top:10px">
                    <!-- <v-spacer></v-spacer> -->
                    <div class="text-right">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="openUnderwriterDialog()"
                                    v-on="on"
                                >
                                    <v-icon medium>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Underwriter</span>
                        </v-tooltip>
                    </div>
                </v-col>
            </v-row>
        </v-toolbar>
        <multipane class="horizontal-panes" layout="horizontal" style="height: 100%" @paneResize="paneResizing">
            <div class="pane" :style="{ height: '50%', maxHeight: '50%'}">
                <v-data-table
                    fixed-header
                    :height="main_tab_height"
                    :headers="underwriter_headers"
                    :items="underwriter_list"
                    item-key="_id"
                    class="elevation-1"
                    :single-select="true"
                    @click:row="selectMainTableRow"
                    item-class="secondary"
                    hide-default-footer
                    :loading="data_table_loading"
                    :disable-pagination="true"
                >
                    <!-- Format date created -->
                    <template v-slot:[`item.created`]="{ item }">
                        <td>{{item.created.split('T')[0]}}</td>
                    </template>
                    <!-- Format Captured-By Field -->
                    <template v-slot:[`item.captured_by`]="{ item }" >
                        <td v-if="item.captured_by !== null && item.captured_by !== undefined">
                            {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                        </td>
                    </template>
                </v-data-table>
            </div>
            <multipane-resizer></multipane-resizer>
        
            <div class="pane tab-nav" :style="{ height: '30%', maxHeight: '50%', minHeight: '30%' }">
                <v-tabs
                    v-model="sub_tabs"
                    color="transparent"
                    class="tab-nav"
                    hide-slider
                    height="35px"
                    grow
                >
                    <!-- Documents Sub Tab -->
                    <template>
                        <v-tab key="documents_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Documents
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>

                    <!-- Invoices Sub Tab -->
                    <!-- <template>
                        <v-tab key="invoices_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Invoices
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template> -->

                    <!-- Banking Details Sub Tab -->
                    <template>
                        <v-tab key="bank_account_details_sub_tab" active-class="secondary white--text" style="max-width: 210px; font-size: 12px;">
                            <span class="tab-title text-truncate">
                                Bank Account Details
                            </span>
                            <div class="spacer"></div>
                        </v-tab>
                    </template>
                </v-tabs>
                <v-tabs-items v-model="sub_tabs">
                    <!-- Sub-Tab Toolbar -->
                    <v-toolbar
                        color="secondary"
                        dark
                        class="pa-0"
                        height="50"
                        extension-height="3"
                    >
                        <!-- Show following buttons only for the documents sub-tab -->
                        <v-tooltip bottom v-if="sub_tabs === 0 && selectedUnderwriter !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddUnderwriterDocumentsDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Underwriter Document(s)</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 0  && selectedUnderwriterDocument !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    v-on="on"
                                    @click="openDeleteUnderwriterDocumentDialog()"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete Underwriter Document(s)</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 0  && selectedUnderwriterDocument !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    v-on="on"
                                    @click="openViewUnderwriterDocumentDialog()"
                                >
                                    <v-icon>content_paste</v-icon>
                                </v-btn>
                            </template>
                            <span>View Document Info</span>
                        </v-tooltip>
                        
                        

                        <!-- Buttons for the BANKING sub-tab -->
                        <v-tooltip bottom v-if="sub_tabs === 1 && selectedUnderwriter !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openAddBankingDialog()"
                                    v-on="on"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Underwriter Banking Details</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 1  && selectedBanking !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openDeleteBankingDialog()"
                                    v-on="on"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete Underwriter Banking Details</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="sub_tabs === 1  && selectedBanking !== null">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    color="secondary"
                                    small
                                    @click="openEditBankingDialog()"
                                    v-on="on"
                                >
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <span>Edit Underwriter Banking Details</span>
                        </v-tooltip>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <!-- Documents Sub-Tab Content -->
                    <v-tab-item key="documents_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="document_details_headers"
                            :items="underwriter_documents"
                            item-key="_id"
                            class="elevation-1"
                            :single-select="true"
                            @click:row="selectDocumentsTableRow"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Display Formated capture date -->
                            <template v-slot:[`item.created`]="{ item }">
                                <td>{{item.created.split('T')[0]}}</td>
                            </template>
                            <!-- Deleted -->
                            <template v-slot:[`item.deleted`]="{ item }" >
                                <td>
                                    {{item.deleted ? 'Yes' : 'No'}}
                                </td>
                            </template>
                            <!-- User who captured the details -->
                            <template v-slot:[`item.captured_by`]="{ item }" >
                                <td v-if="item.captured_by !== null">
                                    {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <!-- Bankind Details Sub-Tab Content -->
                    <v-tab-item key="bank_account_details_sub_tab">
                        <v-data-table
                            fixed-header
                            :height="sub_tab_height"
                            :headers="bank_account_details_headers"
                            :items="underwriter_bank_account_details"
                            item-key="_id"
                            :single-select="true"
                            @click:row="selectBankingTabRow"
                            class="elevation-1"
                            hide-default-footer
                            :disable-pagination="true"
                        >
                            <!-- Display create date -->
                            <template v-slot:[`item.created`]="{ item }">
                                <td>{{item.created.split('T')[0]}}</td>
                            </template>
                            <!-- User who captured the details -->
                            <template v-slot:[`item.captured_by`]="{ item }" >
                                <td v-if="item.captured_by !== null">
                                    {{item.captured_by.entity.name}} {{item.captured_by.entity.surname}}
                                </td>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </multipane>
        <AddUnderwriterDialog
            :dialog="open_add_underwriter_dialog"
        />
        <AddUnderwriterDocumentsDialog
            :dialog="open_add_underwriter_documents_dialog"
            :underwriter="selectedUnderwriter"
        />
        <ViewUnderwriterDocumentDialog
            v-if="selectedUnderwriterDocument !== null"
            :dialog="open_view_underwriter_document_dialog"
            :document="selectedUnderwriterDocument"
            param_path="underwriter"
            :param_id="selectedUnderwriter._id"
        />
        <DeleteUnderwriterDocumentDialog
            v-if="selectedUnderwriterDocument !== null"
            :dialog="open_delete_underwriter_document_dialog"
            :document="selectedUnderwriterDocument"
            :underwriter="selectedUnderwriter"
        />

        <!-- Banking -->
        <AddUnderwriterBankingDialog
            v-if="selectedUnderwriter !== null"
            :dialog="open_add_banking_dialog"
            :underwriter_id="selectedUnderwriter._id"
        />
        <DeleteUnderwriterBankingDialog
            v-if="selectedBanking !== null"
            :dialog="open_delete_banking_dialog"
            :underwriter="selectedUnderwriter"
            :banking="selectedBanking"
        />
        <EditUnderwriterBankingDialog
            v-if="selectedUnderwriter !== null && selectedBanking !== null"
            :dialog="open_edit_banking_dialog"
            :underwriter_id="selectedUnderwriter._id"
            :banking_original="selectedBanking"
            :banking_copy="selectedBankingCopy"
        />
    </div>
</template>
<script>
import { Multipane, MultipaneResizer } from 'vue-multipane';
import { emitAlert } from '../../../utils/api';
import AddUnderwriterDialog from './dialogs/add_underwriter';
import AddUnderwriterDocumentsDialog from './dialogs/add_underwriter_documents';
import ViewUnderwriterDocumentDialog from './dialogs/view_underwriter_document_info';
import DeleteUnderwriterDocumentDialog from './dialogs/delete_underwriter_document';

import AddUnderwriterBankingDialog from './dialogs/add_underwriter_banking';
import EditUnderwriterBankingDialog from './dialogs/edit_underwriter_banking';
import DeleteUnderwriterBankingDialog from './dialogs/delete_underwriter_banking';
import { eventBus } from "../../../main";

import { getUnderwriters } from "./gql/queries";

export default {
    components: {
        Multipane,
        MultipaneResizer,
        AddUnderwriterDialog,
        AddUnderwriterDocumentsDialog,
        ViewUnderwriterDocumentDialog,
        DeleteUnderwriterDocumentDialog,

        AddUnderwriterBankingDialog,
        EditUnderwriterBankingDialog,
        DeleteUnderwriterBankingDialog

    },

    data: () => ({
        sub_tabs: null,
        main_tab_height: '250px',
        sub_tab_height:'180',

        selectedUnderwriter: null,
        selectedUnderwriterMetaData: null,

        selectedUnderwriterDocument: null,
        selectedUnderwriterDocumentMetaData: null,

        selectedBanking: null,
        selectedBankingRow: null,
        selectedBankingCopy: null,

        underwriter_headers: [
            { text: 'Name', value: 'name' },
            { text: 'Date Captured', value: 'created' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        document_details_headers: [
            { text: 'Document Type', value: 'type' },
            { text: 'Name', value: 'name' },
            { text: 'Date Captured', value: 'created' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        bank_account_details_headers: [
            { text: 'Purpose', value: 'purpose' },
            { text: 'Bank', value: 'bank.name' },
            { text: 'Account Type', value: 'account_type' },
            { text: 'Account Number', value: 'account_number' },
            { text: 'Date Captured', value: 'created' },
            { text: 'Captured By', value: 'captured_by' },
        ],
        
        // Table data models
        underwriter_list: [],
        underwriter_documents: [],
        underwriter_bank_account_details: [],

        // Dialogs
        data_table_loading: false,
        open_add_underwriter_dialog: false,

        open_add_underwriter_documents_dialog: false,
        open_view_underwriter_document_dialog: false,
        open_delete_underwriter_document_dialog: false,

        open_add_banking_dialog: false,
        open_delete_banking_dialog: false,
        open_edit_banking_dialog: false,
    }),

    methods: {
        //-------------------- OPEN DIALOGS ---------------------//
        openUnderwriterDialog() {
            this.open_add_underwriter_dialog = true;
        },
        openAddUnderwriterDocumentsDialog() {
            this.open_add_underwriter_documents_dialog = true;
        },
        openViewUnderwriterDocumentDialog() {
            this.open_view_underwriter_document_dialog = true;
        },
        openDeleteUnderwriterDocumentDialog() {
            this.open_delete_underwriter_document_dialog = true;
        },

        // ---- BANKING DEIALOGS
        openAddBankingDialog() {
            this.open_add_banking_dialog = true;
        },
        openDeleteBankingDialog() {
            this.open_delete_banking_dialog = true;
        },
        openEditBankingDialog() {
            this.open_edit_banking_dialog = true;
        },


        closeAddBankingDialog(new_underwriter) {
            this.updateUITableModel(new_underwriter);
            this.open_add_banking_dialog = false;
        },
        closeDeleteBankingDialog(new_underwriter) {
            this.updateUITableModel(new_underwriter);
            this.open_delete_banking_dialog = false;

            this.selectedBankingCopy = null;
            this.selectedBanking = null;
            this.selectedBankingRow = null;
        },
        closeEditBankingDialog(new_underwriter) {
            this.updateUITableModel(new_underwriter);
            this.open_edit_banking_dialog = false;
        },

        //------------------- CLOSE DIALOGS---------------------//
        closeAddUnderwriterDialog(new_underwriter) {
            // this.refreshDataTable();
            if (new_underwriter !== null) {
                this.underwriter_list.push(new_underwriter);
            }

            this.open_add_underwriter_dialog = false;
        },


        updateUITableModel(new_underwriter) {
            if (new_underwriter !== null) {
                const obj_index = this.underwriter_list.map(obj => { return obj._id; }).indexOf(new_underwriter._id)
                this.underwriter_list[obj_index] = new_underwriter;

                 // Deep copy products for refreshing main data table
                const temp_arr = Array.from(this.underwriter_list);
                this.underwriter_list = temp_arr;

                // Also update the documents tab
                this.underwriter_documents = new_underwriter.documents.filter(doc => { return !doc.deleted });
                this.underwriter_bank_account_details = new_underwriter.bank_account_details.filter(banking => { return !banking.deleted });
            }
        },


        closeAddUnderwriterDocumetsDialog(new_underwriter) {
            this.updateUITableModel(new_underwriter);
            this.open_add_underwriter_documents_dialog = false;
        },
        closeViewUnderwriterDocumetDialog() {
            this.open_view_underwriter_document_dialog = false;
        },
        closeDeleteUnderwriterDocumetDialog(new_underwriter) {
            this.updateUITableModel(new_underwriter);
            this.open_delete_underwriter_document_dialog = false;

            this.selectedUnderwriterDocument = null;
        },



        selectMainTableRow(item, item_metadata) {
            if (this.selectedUnderwriterDocumentMetaData !== null) {
                this.selectedUnderwriterDocumentMetaData.select(false);

                this.selectedUnderwriterDocument = null;
                this.selectedUnderwriterDocumentMetaData = null;
            }

            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);

                this.selectedUnderwriter = item;
                this.selectedUnderwriterMetaData = item_metadata;

                this.underwriter_documents = item.documents.filter(doc => { return !doc.deleted });
                this.underwriter_bank_account_details = item.bank_account_details.filter(banking => { return !banking.deleted });
            } else {
                this.selectedUnderwriter = null;
                this.selectedUnderwriterMetaData = null;

                
                // Unighlight selected row
                item_metadata.select(false);

                this.underwriter_documents = [];
                this.underwriter_bank_account_details = [];
            }
        },
        selectDocumentsTableRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);

                this.selectedUnderwriterDocument = item;
                this.selectedUnderwriterDocumentMetaData = item_metadata;
            } else {
                this.selectedUnderwriterDocument = null;
                this.selectedUnderwriterDocumentMetaData = null;
                // Unighlight selected row
                item_metadata.select(false);
            }
        },
        selectBankingTabRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);
                
                this.selectedBanking = item;
                this.selectedBankingRow = item_metadata;

                // create copy
                this.selectedBankingCopy = Object.assign({}, this.selectedBanking);
            } else {
                // Unighlight selected row
                item_metadata.select(false);

                this.selectedBanking = null;
                this.selectedBankingRow = null;

                this.selectedBankingCopy = null;
            }
        },

        async refreshDataTable() {
            this.data_table_loading = true;
            this.underwriter_list = [];
        
            try {
                // const res = await getUnderwriters();

                // if (res.errors === true) {
                //     emitAlert(res.message);
                //     this.data_table_loading = false;
                //     return;
                // }

                const res = await this.$apollo
                                    .query({
                                        query: getUnderwriters,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'UNDERWRITER',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }

                this.data_table_loading = false;
                this.underwriter_list = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        paneResizing(pane, container, size) {
            const size_int = parseInt(size);

            this.main_tab_height = size;
            const max_screen_height = window.innerHeight - 300;

            const sub_tab_percentage = 1 - (size_int/max_screen_height);
            this.sub_tab_height = sub_tab_percentage * max_screen_height;
        },

        resetBankingEditForm(updatedBanking) {
            if (
                updatedBanking.purpose !== this.selectedBanking.purpose ||
                updatedBanking.bank !== this.selectedBanking.bank ||
                updatedBanking.account_type !== this.selectedBanking.account_type ||
                updatedBanking.account_number !== this.selectedBanking.account_number
            ) {
                this.selectedBankingCopy = Object.assign({}, this.selectedBanking);
            }
        },
    },

    async mounted() {
        eventBus.$on('CLOSE_ADD_UNDERWRITER_DIALOG', this.closeAddUnderwriterDialog);
        eventBus.$on('CLOSE_ADD_UNDERWRITER_DOCUMENTS_DIALOG', this.closeAddUnderwriterDocumetsDialog);
        eventBus.$on('CLOSE_VIEW_UNDERWRITER_DOCUMENT_DIALOG', this.closeViewUnderwriterDocumetDialog);
        eventBus.$on('CLOSE_DELETE_UNDERWRITER_DOCUMENT_DIALOG', this.closeDeleteUnderwriterDocumetDialog);

        eventBus.$on('CLOSE_EDIT_UNDERWRITER_BANKING_DIALOG', this.closeEditBankingDialog);
        eventBus.$on('CLOSE_ADD_UNDERWRITER_BANKING_DIALOG', this.closeAddBankingDialog);
        eventBus.$on('CLOSE_DELETE_UNDERWRITER_BANKING_DIALOG', this.closeDeleteBankingDialog);

        eventBus.$on('RESET_BANKING_EDIT_FORM', this.resetBankingEditForm);


        /**
         * The following logic is for manually triggering a pane-divider movement
         * so as to get the windows to properly resize
         */
        this.paneResizing(null, null, 250);
        

        this.data_table_loading = true;
        
        try {
            // const res = await getUnderwriters();
            // if (res.errors === true) {
            //     emitAlert(res.message);
            //     this.data_table_loading = false;
            //     return;
            // }

            const res = await this.$apollo
                                    .query({
                                        query: getUnderwriters,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'UNDERWRITER',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.data_table_loading = false;
                return;
            }

            this.data_table_loading = false;
            this.underwriter_list = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.data_table_loading = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
        }
    },

    watch: {
        
    }
}
</script>
<style scoped>
/* Overriding of textbox min height */
 .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
}
</style>
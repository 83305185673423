<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Generate System Finance Accounts</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <!-- Disclaimer -->
                        <v-col cols="12" sm="12">
                            <h3 style="padding-top:10px">Clicking the Generate button below will create system finance accounts if they don't exist</h3>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Generating System Finance Accounts
                        <v-progress-linear
                            indeterminate
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                @click="submitAccountsGeneration()"
                            >
                               Generate
                            </v-btn>
                        </template>
                        <span>Generate System Accounts</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../../utils/api";
import { eventBus } from "../../../../main";

import { getSystemFinanceAccounts } from "../gql/queries";

export default {
    name: 'SearchMembersDialog',
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        server_resp_accounts: null,
        submitting: false,
    }),
    methods: {
        closeDialog() {
            eventBus.$emit('CLOSE_GENERATE_SYSTEM_ACCOUNTS_DIALOG', this.server_resp_accounts);
        },
        async submitAccountsGeneration() {
            this.submitting = true;

            try {
                // const res = await generateSystemFinanceAccounts();
                // if (res.errors === true) {
                //     emitAlert(res.message);
                //     this.submitting = false;
                //     return;
                // }

                const res = await this.$apollo
                                    .query({
                                        query: getSystemFinanceAccounts,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'FINANCE_GENERATE_SYSTEM_ACCOUNT',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.submitting = false;
                    return;
                }

                emitSuccess(res.data.getBusinessObject.message);
                
                this.submitting = false;
                this.server_resp_accounts = res.data.getBusinessObject.business_objects;
                this.closeDialog();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        }
    },
    mounted() {
        
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
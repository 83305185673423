<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Loan Events Stream</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-timeline
                        dense
                        clipped
                        align-top
                    >
                    <!-- <v-timeline
                        :dense="$vuetify.breakpoint.smAndDown"
                    > -->
                        <v-timeline-item large color="black">START</v-timeline-item>
                        <v-timeline-item
                            v-for="(eventObj, i) in events"
                            :key="i"
                            :color="deriveEventColor(eventObj)"
                            :large="eventObj.event === 'DEADLINE-DATE' ? true : false"
                            :small="eventObj.event.startsWith('ALLOCATION') ? true : false"
                        >
                            <!-- <v-row justify="space-between"> -->
                            <v-row class="pt-1">
                                <v-col
                                    cols="3"
                                >
                                    <div>
                                        <!-- <h2>{{ eventObj.date.split('T')[0] }}</h2> -->
                                        {{ eventObj.date.split('T')[0] }}
                                    </div>
                                </v-col>
                                <v-col
                                    cols="4"
                                    :style="deriveEventDetailsColor(eventObj)"
                                >
                                    <h3>{{ eventObj.event }}</h3>
                                    <div>
                                        <span
                                            v-if="(
                                                eventObj.event !== 'LOAN-START' &&
                                                eventObj.event !== 'DEADLINE-DATE' &&
                                                eventObj.event !== 'LOAN-REPAID'
                                            )"
                                        >
                                            <b>Amount: </b> R{{ formatNumber(eventObj.amount) }}<br/>
                                        </span>
                                        <span><b>Loan Balance: </b> R{{ formatNumber(eventObj.loan_balance) }}<br/></span>
                                        <span
                                            v-if="eventObj.event === 'DEADLINE-DATE'"
                                        >
                                            <b>Expected Loan Balance: </b> R{{ formatNumber(eventObj.expected_loan_balance) }}<br/>
                                        </span>
                                        <span
                                            v-if="eventObj.event === 'PAYMENT'"
                                        >
                                            <b>Expected Payment Method: </b> {{ eventObj.expected_payment_method }}<br/>
                                            <b>Actual Payment Method: </b> {{ eventObj.payment_method }}<br/>
                                        </span>
                                        <!-- <span
                                        v-if="(
                                            eventObj.event !== 'LOAN-START' &&
                                            eventObj.event !== 'DEADLINE-DATE'
                                        )"
                                        >
                                            <b>Days: </b> {{ eventObj.days }}
                                        </span> -->
                                    </div>
                                </v-col>
                                <!-- <v-col
                                    class="text-left"
                                    cols="5"
                                >
                                
                                </v-col> -->
                            </v-row>
                        </v-timeline-item>

                        <v-timeline-item large color="black">END</v-timeline-item>
                    </v-timeline>

                    <v-card-text class="text-center" v-if="submitting">
                        <v-overlay :value="submitting" :absolute="true">
                            <v-progress-circular
                                color="white"
                                indeterminate
                                size="150"
                                width="9"
                            >
                                <h3 class="text-center">Loading...</h3>
                            </v-progress-circular>
                        </v-overlay>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <!-- <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom v-if="search_field_selected">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                @click="submitSearchQuery()"
                            >
                                <v-icon large>search</v-icon>
                            </v-btn>
                        </template>
                        <span>Submit Search Query</span>
                    </v-tooltip>
                </v-card-actions> -->
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { eventBus } from '../../../main';
import { emitAlert } from '../../../utils/api';
import { getLoanEventsStream } from "../gql/mutations";
import converter from "../../../mixins/converter";
export default {
    name: 'ViewDebitOrderStatusDialog',
    mixins: [converter],
    components: {},
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        loan: {
            type: Object,
            required: true,
            default: null
        },
    },
    data: () => ({
        effective_date_menu: false,
        debt_status_details: {
            total_collected: null,
            total_outstanding: null,
            total_outstanding_to_date: null,
        },

        events: [],

        submitting: true
    }),
    methods: {
        closeDialog() {
            eventBus.$emit('CLOSE_VIEW_EVENTS_STREAM_DIALOG', null);
        },

        deriveEventColor(eventObj) {
            let result = 'blue';
            if (eventObj.event === 'LOAN-START') {
                result = 'grey';
            }
            if (eventObj.event === 'LOAN-REPAID') {
                result = 'green';
            }
            if (eventObj.event === 'DEADLINE-DATE') {
                result = 'red';
            }
            if (eventObj.event.startsWith('ALLOCATION')) {
                // result = 'orange';
                result = '#e4e4e4';
            }
            return result;
        },
        deriveEventDetailsColor(eventObj) {
            // 'PAYMENT' ? 'background:#99c1eb; border-radius:15px;' :
            let result = 'background:#e4e4e4; border-radius:15px;';

            if (eventObj.event === 'PAYMENT') {
                result = 'background:#99c1eb; border-radius:15px;';
            }
            if (eventObj.event === 'DEADLINE-DATE') {
                result = 'background:#ca3939; color:white; border-radius:15px;';
            }
            if (eventObj.event === 'LOAN-REPAID') {
                result = 'background:green; color:white; border-radius:15px;';
            }
            return result;
        }
    },
    mounted() {
        
    },
    computed: {},
    watch: {
        async dialog(val) {
            if (val === true) {
                this.submitting = true;

                const payloadObj = {
                    OP_TYPE: 'GET_EVENTS_STREAM',
                    _id: this.loan._id,
                };

                try {
                    const res = await this.$apollo
                                            .mutate({
                                                mutation: getLoanEventsStream,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                    this.submitting = false;

                    this.events = res.data.businessProcessLoan.business_object.events_stream;
                } catch (error) {
                    this.submitting = false;
                    console.log(error);
                    if (error !== undefined) {
                        emitAlert(error.data.getBusinessObject.message);
                    } else {
                        emitAlert('The server has taken too long in responding to your request');
                    }
                }
            }

            if (val === false) {
                this.events = [];
            }
        }
    }
}
</script>
<style scoped>

</style>
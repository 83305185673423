var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticClass:"pa-0",attrs:{"color":"secondary","dark":"","height":"50","extension-height":"3"}},[_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"10px"},attrs:{"sm":"8"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.refreshProducts()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh")])]),(_vm.selectedProduct != null)?_c('v-tooltip',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.openEditProductDetailsDialog()}}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,false,2972311769)},[_c('span',[_vm._v("Edit Record")])]):_vm._e()],1),_c('v-col',{staticStyle:{"margin-top":"10px"},attrs:{"sm":"4"}},[_c('div',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"amber black--text"},on:{"click":function($event){return _vm.openAddProductDialog()}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v("Add Product")])])],1)])],1)],1),_c('multipane',{staticClass:"horizontal-panes",staticStyle:{"height":"100%"},attrs:{"layout":"horizontal"},on:{"paneResize":_vm.paneResizing}},[_c('div',{staticClass:"pane",style:({ height: '50%', maxHeight: '50%'})},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.main_tab_height,"headers":_vm.product_details_headers,"items":_vm.products,"item-key":"_id","single-select":true,"item-class":"secondary","disable-pagination":true,"loading":_vm.data_table_loading,"hide-default-footer":""},on:{"click:row":_vm.selectMainTableRow}})],1),_c('multipane-resizer'),_c('div',{staticClass:"pane tab-nav",style:({ height: '30%', maxHeight: '50%', minHeight: '30%' })},[_c('v-tabs',{staticClass:"tab-nav",attrs:{"color":"transparent","hide-slider":"","height":"35px","grow":""},model:{value:(_vm.sub_tabs),callback:function ($$v) {_vm.sub_tabs=$$v},expression:"sub_tabs"}},[[_c('v-tab',{key:"credit_rules_sub_tab",staticStyle:{"max-width":"210px","font-size":"12px"},attrs:{"active-class":"secondary white--text"}},[_c('span',{staticClass:"tab-title text-truncate"},[_vm._v(" Credit Rules ")]),_c('div',{staticClass:"spacer"})])]],2),_c('v-tabs-items',{model:{value:(_vm.sub_tabs),callback:function ($$v) {_vm.sub_tabs=$$v},expression:"sub_tabs"}},[_c('v-toolbar',{staticClass:"pa-0",attrs:{"color":"secondary","dark":"","height":"50","extension-height":"3"}},[(_vm.sub_tabs == 0 && _vm.selectedProduct != null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.openAddProductCreditRulesDialog()}}},on),[_c('v-icon',[_vm._v("add")])],1)]}}],null,false,1198795403)},[_c('span',[_vm._v("Add Credit Rules")])]):_vm._e(),(_vm.sub_tabs == 0  && _vm.selectedCreditRule != null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.removeCreditRuleItem()}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,false,783384549)},[_c('span',[_vm._v("Remove Credit Rule")])]):_vm._e(),_c('v-spacer')],1),_c('v-tab-item',{key:"credit_rules_sub_tab"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.sub_tab_height,"headers":_vm.credit_rules_headers,"items":_vm.credit_rules,"item-key":"_id","single-select":true,"item-class":"secondary","disable-pagination":true,"hide-default-footer":""},on:{"click:row":_vm.selectCreditRulesRow}})],1)],1)],1)],1),_c('AddProductDialog',{attrs:{"dialog":_vm.open_add_product_dialog}}),_c('EditProductDetailsDialog',{attrs:{"dialog":_vm.open_edit_product_details_dialog,"product":_vm.selectedProduct,"product_copy":_vm.selectedProductCopy}}),(_vm.selectedProduct !== null)?_c('AddProductRuleTypesDialog',{attrs:{"dialog":_vm.open_product_rule_parameters_dialog,"product_id":_vm.selectedProduct._id,"rule_level":_vm.product_rule_level}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
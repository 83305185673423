<template>
    <div>
        <div class="wrapper">
            <canvas ref="signature_pad" class="signature-pad" width=400 height=200></canvas>
        </div>
        <div style="color:red" v-if="signatureData === null"><b>NB: You have to click save to admit the signature</b></div>
        <div style="color:green" v-else><b>Signature Admitted</b></div>
        <div>
            <v-btn :color="signatureData === null ? 'secondary' : 'success'" @click="saveSignature()">Save</v-btn>
            <v-btn color="secondary" @click="clearSignature()" class="mx-2">Clear</v-btn>
            <v-icon
                v-if="signatureData !== null"
                large
                color="success"
            >mdi-check</v-icon>
        </div>
    </div>
</template>
<script>
import SignaturePad from 'signature_pad'
// import { submitDocumentumFile } from '../../../../utils/api';
import { eventBus } from '../../../../main';
import { Buffer } from 'buffer';
export default {
    name: "Signature",
    props: {
        signature_purpose: {
            type: String,
            required: true
        },
        unique_identifier: {
            type: Number,
            required: true
        },
    },
    data: () => ({
        signaturePad: null,
        signatureData: null
    }),
    computed: {},
    methods: {
        async saveSignature() {
            const data = this.signaturePad.toDataURL('image/png');
            // console.log(data.length)

            const array = []
            const blobBin = Buffer.from(data.split(',')[1], 'base64').toString('binary')
            for (let i = 0; i < blobBin.length; i++) {
                array.push(blobBin.charCodeAt(i))
            }
            // const file = new Blob([new Uint8Array(array)], {type: 'image/png'});

            // const file_id = '1'
            // const signatureId = '1'
            // const uploadRes = await submitDocumentumFile(file_id, file, 'signature', signatureId);
            // if (uploadRes.errors === true) {
            //     emitAlert(uploadRes.message);
            //     this.submitting = false;
            //     this.backendActionItems.push(`  --> Uploading ${file.name} failed`);
            //     return;
            // }

            this.signatureData = 'data-indicator';
            eventBus.$emit('SIGNATURE_CHANGED', {
                signature_purpose: this.signature_purpose,
                data_url: data
            });
        },
        clearSignature() {
            this.signaturePad.clear();

            this.signatureData = null

            eventBus.$emit('SIGNATURE_CHANGED', {
                signature_purpose: this.signature_purpose,
                data_url: null
            });
        }
    },
    mounted() {
        const canvasElement = this.$refs['signature_pad']
        
        const signaturePad = new SignaturePad(canvasElement, {
            backgroundColor: 'rgba(255, 255, 255, 0)', // signature must have a transparent background
            penColor: 'rgb(0, 0, 0)',
            minWidth: 1,
            maxWidth: 1,
            dotSize: 2
        });
        this.signaturePad = signaturePad;

        eventBus.$on('CLEAR_SIGNATURE_DATA', (id) => {
            if (id === this.unique_identifier) {
                this.clearSignature();
            }
        });
    },
    watch: {

    }
};
</script>
<style scoped>
.wrapper {
    position: relative;
    border: 1px solid black;
    background: white;
    width: 400px;
    height: 200px;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.signature-pad {
    position: absolute;
    left: 0;
    top: 0;
    width:400px;
    height:200px;
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"80%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-toolbar',{staticStyle:{"border":"1px solid #07ad2d"},attrs:{"dark":"","color":"secondary"}},[_c('v-toolbar-title',[_c('b',[_vm._v("Add Allowed Report(s)")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.resetForm()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Reset Form")])]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card',[_c('v-card-text',[_vm._l((_vm.allowed_reports),function(allowed_report){return _c('v-row',{key:allowed_report.id,staticStyle:{"background":"#eaeaea","padding-bottom":"10px"}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-select',{attrs:{"items":_vm.user_reports,"color":"secondary","item-text":"display_value","item-value":"_id","label":"Report","disabled":_vm.refreshing_reports_list,"hint":"You can click the refresh icon next to the field if the report list is incomplete","persistent-hint":"","required":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"success"},on:{"click":function($event){return _vm.refreshReportsList()}}},on),[_vm._v(" mdi-refresh ")])]}}],null,true)},[_c('span',[_vm._v("Refresh Reports List")])])]},proxy:true}],null,true),model:{value:(allowed_report.report),callback:function ($$v) {_vm.$set(allowed_report, "report", $$v)},expression:"allowed_report.report"}}),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"color":"green","height":"4","indeterminate":"","active":_vm.refreshing_reports_list}})],1),_c('div',{staticStyle:{"background":"white","height":"10px","width":"100%"}})],1)}),_c('v-row',[_c('v-col',{attrs:{"sm":"10"}}),_c('v-col',{attrs:{"sm":"2"}},[(_vm.allowed_reports.length > 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.removeReport()}}},on),[_c('v-icon',[_vm._v("remove")])],1)]}}],null,false,3417040776)},[_c('span',[_vm._v("Remove Report")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.addReport()}}},on),[_c('v-icon',[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v("Add Another Report")])])],1)],1),(_vm.submitting)?_c('v-card-text',{staticClass:"text-center"},[_vm._v(" Submitting Reports "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","active":_vm.submitting,"color":"green"}})],1):_vm._e()],2),_c('v-card-actions',[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"large":"","color":"success","disabled":_vm.submitDataBtnDisabled()},on:{"click":function($event){return _vm.submitData()}}},on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("save")])],1)]}}])},[_c('span',[_vm._v("Save Report Details")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"80%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-toolbar',{staticStyle:{"border":"1px solid #07ad2d"},attrs:{"dark":"","color":"secondary"}},[_c('v-toolbar-title',[_c('b',[_vm._v("Add User Role(s)")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.resetForm()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Reset Form")])]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.closeAddPolicyMemberDialog()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card',[_c('v-card-text',[_vm._l((_vm.roles),function(user_role){return _c('v-row',{key:user_role.id,staticStyle:{"background":"#eaeaea","padding-bottom":"10px"}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-select',{attrs:{"items":_vm.user_roles,"color":"secondary","item-value":"_id","item-text":"name","label":"Role","disabled":_vm.refreshing_user_roles_list,"hint":"You can click the refresh icon next to the field if the user roles list is incomplete","persistent-hint":"","required":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"success"},on:{"click":function($event){return _vm.refreshUserRolesList()}}},on),[_vm._v(" mdi-refresh ")])]}}],null,true)},[_c('span',[_vm._v("Refresh User Roles List")])])]},proxy:true}],null,true),model:{value:(user_role.role),callback:function ($$v) {_vm.$set(user_role, "role", $$v)},expression:"user_role.role"}}),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"color":"green","height":"4","indeterminate":"","active":_vm.refreshing_user_roles_list}})],1),_c('div',{staticStyle:{"background":"white","height":"10px","width":"100%"}})],1)}),_c('v-row',[_c('v-col',{attrs:{"sm":"10"}}),_c('v-col',{attrs:{"sm":"2"}},[(_vm.roles.length > 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.removeRole()}}},on),[_c('v-icon',[_vm._v("remove")])],1)]}}],null,false,596809362)},[_c('span',[_vm._v("Remove Role")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.addRole()}}},on),[_c('v-icon',[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v("Add Another Role")])])],1)],1),(_vm.submitting)?_c('v-card-text',{staticClass:"text-center"},[_vm._v(" Submitting Policy Members "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","active":_vm.submitting,"color":"green"}})],1):_vm._e()],2),_c('v-card-actions',[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"large":"","color":"success","disabled":_vm.submitUserRolesBtnDisabled()},on:{"click":function($event){return _vm.submitUserRoles()}}},on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("save")])],1)]}}])},[_c('span',[_vm._v("Save Policy Member")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import gql from 'graphql-tag'

export const lookupProcessEntityRelationshipType = gql`
    mutation lookupProcessEntityRelationshipType($input: EntityRelationshipTypeInput) {
        lookupProcessEntityRelationshipType(input: $input) {
            errors
            message
            business_object {
                ... on EntityRelationshipType {
                    __typename
                    _id
                    name
                    description
                    gender_map
                    opposite_relationship {
                        _id
                        name
                    }
                }
            }
        }
    }
`
import gql from 'graphql-tag'

export const createVAS = gql`
    mutation businessProcessVASService($input: VASServiceInput!) {
        businessProcessVASService(input: $input) {
            errors
            message
            business_object {
                ... on VASService {
                    __typename
                    _id

                    name
                    category {
                        _id
                        name
                    }
                    description
                    captured_by {
                        _id
                        entity {
                            name
                            surname
                        }
                    }
                    created

                    deleted
                    delete_date
                    deleted_by {
                        _id
                        entity {
                            name
                            surname
                        }
                    }
                    delete_reason

                    documents {
                        _id
                        type
                        mime_type
                        name

                        captured_by {
                            _id
                            entity {
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            _id
                            entity {
                                name
                                surname
                            }
                        }
                        delete_reason
                    }
                }
            }
        }
    }
`
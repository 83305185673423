<template>
    <v-autocomplete
        v-model="item.bank_feed_item"
        :items="search_entries"
        :loading="isLoading"
        :search-input.sync="search"
        hide-no-data
        small-chips
        item-text="display_value"
        item-value="_id"
        :label="label"
        placeholder="Start typing to Search"
        prepend-icon="search"
        color="secondary"
        :disabled="field_disabled"
        return-object
        @focus="focusEvent"
    ></v-autocomplete>
</template>

<script>
import { emitAlert } from '../../../utils/api';
import { searchBankFeedAutocomplete } from "../gql/queries";
export default {
    name: "BankFeedSearch",
    props: {
        item: {
            required: false,
            default: null
        },
        field_disabled: {
            required: false,
            default: false
        },
        label: {
            required: false,
            default: 'Search Bank Feed Transaction by description OR reference'
        }
    },
    data: () => ({
        search: null,
        search_entries: [],
        isLoading: false
    }),
    computed: {
        // items () {
        //     return this.search_entries.map(entry => {
        //         const display_value = `(Descr: ${entry.description}) --- (Ref: ${entry.reference}) --- (Amount: ${entry.amount}) --- (Date: ${entry.date.split('T')[0]})`;
        //         return Object.assign({}, entry, { display_value })
        //     })
        // },
    },
    mounted() {
        // Set selected chip if bank-feed is already initialized
        if (this.item.bank_feed_item !== null && this.item.bank_feed_item !== undefined) {
            this.search_entries.push(this.item.bank_feed_item);
        }
    },
    methods: {
        focusEvent() {
            // Avoid caching by clearing search entries in memory
            this.search_entries = [];
        }
    },
    watch: {
        async search (val) {
            // Items have already been loaded
            // if (this.items.length > 0) return

            // // Items have already been requested
            // if (this.isLoading) return

            this.isLoading = true

            if (val !== null) {
                if (val.length > 0) {
                    const searchQuery = {
                        description: val,
                        reference: val,
                        bank_account_name: 'MAIN',
                        classification: null
                    };

                    const res = await this.$apollo
                                            .query({
                                                query: searchBankFeedAutocomplete,
                                                // fetchPolicy: 'network-only',
                                                fetchPolicy: 'no-cache',
                                                errorPolicy: 'all',
                                                variables: {
                                                    objectType: 'BANK_FEED',
                                                    pagination: {
                                                        page: this.page,
                                                        limit: this.limit
                                                    },
                                                    searchInput: searchQuery
                                                },
                                            });
                    this.isLoading = false;
                    if (res.data.getBusinessObject.errors === true) {
                        emitAlert(res.data.getBusinessObject.message);
                        return;
                    }

                    res.data.getBusinessObject.business_objects.map(entry => {
                        const display_value = `(Descr: ${entry.description}) --- (Ref: ${entry.reference}) --- (Amount: ${entry.amount}) --- (Date: ${entry.date.split('T')[0]})`;
                        entry.display_value = display_value;
                        return entry;
                    });
                    this.search_entries = res.data.getBusinessObject.business_objects;
                } else {
                    this.isLoading = false;
                    this.search_entries = [];
                }
            } else {
                this.isLoading = false;
            }
            
        },
    }
};
</script>
<template>
    <v-row justify="center">
        <!-- <v-dialog
            v-model="dialog"
            fullscreen
            persistent
        > -->
        <v-dialog
            v-model="dialog"
            persistent
            width="40%"
        >
            <div style="background:white; overflow-y:auto; height:100%">
                <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d;">
                    <v-spacer></v-spacer>
                    <v-toolbar-title><b>Trigger A Business Process</b></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                class="mx-2"
                                color="red"
                                small
                                v-on="on"
                                @click="resetForm()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Reset Form</span>
                    </v-tooltip>
                    <v-btn icon @click="closeTriggerBusinessProcessDialog()" v-if="server_response_obj === null">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-select
                                    v-model="selected_process"
                                    :items="business_processes"
                                    color="secondary"
                                    item-text="name"
                                    item-value="_id"
                                    label="Select Business Process"
                                    hint="Select a business-process in order to proceed with the download process"
                                    persistent-hint
                                    return-object
                                    required
                                >
                                    <!-- CUSTOM RENDERING TEMPLATE -->
                                    <template
                                        v-slot:selection="{ item }"
                                    >
                                        <v-chip
                                            text-color="white"
                                            :color="color_map[item.group]"
                                        >
                                            {{ item.name }}
                                        </v-chip>
                                    </template>
                                    <!-- Template for render data when the select is expanded -->
                                    <template v-slot:item="{ item, attrs, on }">
                                        <v-list-item v-on="on" v-bind="attrs">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <v-row no-gutters align="center">
                                                    <span>{{ item.name }}</span>
                                                    <v-spacer></v-spacer>
                                                    <v-chip text-color="white" :color="color_map[item.group]" small
                                                    >{{ item.group }}</v-chip>
                                                    </v-row>
                                                </v-list-item-title>
                                                <!-- <v-list-item-subtitle>
                                                    {{item.description}}
                                                </v-list-item-subtitle> -->
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-select>
                            </v-col>
                        </v-row>
                        
                        <v-row v-if="selected_process !== null">
                            <v-col cols="12" sm="12">
                                <v-row style="background: #f6c20b">
                                    <v-col cols="12" sm="12">
                                        <h3>Business Process Details</h3>
                                    </v-col>
                                    <v-col cols="12" sm="12">
                                        <v-textarea
                                            :value="selected_process.description"
                                            label="Description"
                                            color="secondary"
                                            required
                                            rows="1"
                                            auto-grow
                                            disabled
                                        >

                                        </v-textarea>
                                    </v-col>
                                    <v-col cols="12" sm="12">
                                        <v-btn
                                            block
                                            class="ma-0"
                                            color="secondary"
                                            @click="triggerProcessRun()"
                                        >
                                            <v-spacer></v-spacer>
                                            Submit Business Process Trigger
                                            <v-spacer></v-spacer>
                                            <v-icon
                                                large
                                                right
                                            >
                                                mdi-run-fast
                                            </v-icon>
                                            <v-spacer></v-spacer>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </div>
        </v-dialog>
    </v-row>
</template>
<script>
import { eventBus } from '../main';
import { emitAlert, emitSuccess } from '../utils/api';
import { businessProcess } from "./gql/mutations";

export default {
    components: {},
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        selected_process: null,
        server_response_obj: null,
        business_processes: [
            {
                _id: 'build_dashboard_reports',
                name: 'Build Dashboard Reports',
                description: 'This business process will cause the dashboard graph to be rebuilt',
                group: 'Finance',
            },
            {
                _id: 'loan_recalculate_last_transaction_days',
                name: 'Bulk Loan - Last Transaction Date ReCalculation',
                description: 'This process will cause the last finance transaction date to be recalculated for all loans on the system',
                group: 'Finance',
            }
        ],

        clearTimeFunction: null,


        refreshing_fin_calendar_month_list: false,

        color_map: {
            'Finance': 'success',
            'Claim': 'red',
            'Agent': 'amber',
            // 'Finance': '#0e6db1',
            'Commissions': '#0e6db1',
            'Payment Instructions': '#0e6db1',
        },
    }),
    computed: {},
    methods: {
        closeTriggerBusinessProcessDialog() {
            eventBus.$emit('CLOSE_TRIGGER_BUSINESS_PROCESS_DIALOG', this.server_response_obj);
        },

        resetForm() {
            this.selected_process = null;
        },

        async triggerProcessRun() {
            // Now send a server request
            try {
                const payloadObj = {
                    process_group: 'BUSINESS_PROCESS',
                    process_id: this.selected_process._id,
                }

                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcess,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.businessProcess.errors === true) {
                    emitAlert(res.data.businessProcess.message);
                    return;
                }
                
                emitSuccess(res.data.businessProcess.message);
                this.closeTriggerBusinessProcessDialog();
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
            
        },
    },
    async mounted() {},
    watch: {}
}
</script>
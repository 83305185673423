<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Add Product Payout Item</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeEditProductDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <div v-if="product_id !== null">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-select
                                    v-model="payout_category_item.category"
                                    :items="payout_category_select_items"
                                    color="secondary"
                                    item-text="name"
                                    item-value="_id"
                                    label="Payout Category"
                                    required
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" >
                                <v-text-field
                                    v-model="payout_category_item.payout_amount"
                                    prefix="ZAR"
                                    color="secondary"
                                    label="Payout Amount"
                                    type="number"
                                    required
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
                <!-- Submission Results Section -->
                <div v-if="submitting">
                    <v-card-text class="text-center">
                        <v-progress-linear
                            color="green"
                            class="mb-0"
                            height="10"
                            indeterminate
                            :active="submitting"
                        ></v-progress-linear>
                    </v-card-text>
                    <v-list flat>
                        <v-subheader>Saving Product Info</v-subheader>
                        <v-list-item-group color="primary">
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon>done</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Submiting product payout item</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </div>

                <!-- Lower Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        large
                        color="success"
                        :disabled="submitProductPayoutItemBtnDisabled()"
                        @click="submitProductPayoutItem()"
                    >
                        <v-icon large>save</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../../../utils/api";
import { eventBus } from "../../../../../main";

import { getPayoutAgeCategories } from "../gql/queries";
import { productProcessInsuranceProductPrimary } from "../gql/mutations";

export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        product_id: {
            type: String,
            default: null
        }
    },
    data: () => ({
        server_resp_obj: null,
        submitting: false,
        payout_category_select_items: [],
        payout_category_item: {
            category: null,
            payout_amount: 0
        }
    }),
    methods: {
        closeEditProductDialog() {
            eventBus.$emit('CLOSE_ADD_PRODUCT_PAYOUT_ITEM_DIALOG', this.server_resp_obj);
        },

        resetForm() {
            this.submitting = false;
            this.server_resp_obj = null;
            this.payout_category_item = {
                category: null,
                payout_amount: 0
            };
        },

        submitProductPayoutItemBtnDisabled() {
            if (this.payout_category_item.category === null) {
                return true;
            }

            if (isNaN(parseFloat(this.payout_category_item.payout_amount))) {
                this.payout_category_item.payout_amount = 0;
                return true;
            }

            if (parseFloat(this.payout_category_item.payout_amount) === 0) {
                return true;
            }

            return false;
        },

        async submitProductPayoutItem() {
            try {
                this.submitting = true;

                // Sanitize numerical value
                this.payout_category_item.payout_amount = parseFloat(this.payout_category_item.payout_amount);

                const payloadObj = {
                    OP_TYPE: 'ADD_PAYOUT_STRUCTURE',
                    _id: this.product_id,

                    // Placeholder fields
                    name: '',
                    underwriter_premium: 0,
                    broker_commission: 0,
                    broker_markup: 0,

                    payout_structure: [this.payout_category_item]
                };

                const res = await this.$apollo
                                            .mutate({
                                                mutation: productProcessInsuranceProductPrimary,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.productProcessInsuranceProductPrimary.errors === true) {
                    emitAlert(res.data.productProcessInsuranceProductPrimary.message);
                    this.submitting = false;
                    return;
                }
                
                this.server_resp_obj = res.data.productProcessInsuranceProductPrimary.business_object;

                this.closeEditProductDialog();
                emitSuccess(res.data.productProcessInsuranceProductPrimary.message);

                // Reset fields
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.productProcessInsuranceProductPrimary.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        }
    },
    async mounted() {
        // Load lookup data
        try {
            const res = await this.$apollo
                                    .query({
                                        query: getPayoutAgeCategories,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'PAYOUT_CATEGORY',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.submitting = false;
                return;
            }
            this.payout_category_select_items = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.submitting = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
            
            return;
        }
    },
    computed: {

    },
    watch: {
        
    }
}
</script>
<style scoped>
.error-multi-line {
  white-space: pre-line;
}

</style>
import gql from 'graphql-tag'

export const entityProcessBusinessPartner = gql`
    mutation entityProcessBusinessPartner($input: BusinessPartnerInput!) {
        entityProcessBusinessPartner(input: $input) {
            errors
            message
            business_object {
                ... on BusinessPartner {
                    __typename
                    _id
                    bp_number

                    name
                    company_reg_number
                    type
                    status
                    administration_option

                    version_history {
                        _id
                        changes {
                            type
                            description
                            old
                            new
                            captured_by {
                                __typename
                                _id
                                entity {
                                    __typename
                                    _id
                                    name
                                    surname
                                }
                            }
                            created
                        }
                    }

                    vas_services {
                        _id
                        fee_amount
                        fee_billing_model
                        vas_service {
                            _id
                            name
                            # category {
                            #     _id
                            #     name
                            # }
                            description
                        }

                        captured_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        created

                        deleted
                        delete_date
                        deleted_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }
                    fee_structure {
                        _id
                        fee_item {
                            _id
                            name
                            description
                            captured_by {
                                _id
                                entity {
                                    _id
                                    name
                                    surname
                                }
                            }
                            created
                        }
                        payment_option
                        amount
                        captured_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }
                    addresses {
                        _id
                        type
                        line1
                        line2
                        line3
                        line4
                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }
                    contact_people {
                        _id
                        contact_person {
                            _id
                            name
                            surname
                            id_number
                        }
                        role

                        captured_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }

                    documents {
                        _id
                        type
                        mime_type
                        name

                        start_date
                        end_date
                        approved
                        approved_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }

                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }

                    bank_account_details {
                        _id
                        purpose
                        bank {
                            _id
                            name
                            universal_branch_code
                        }
                        account_type
                        account_number

                        captured_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }

                    created
                    captured_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }
                }
            }
        }
    }
`
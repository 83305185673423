const policy_roles = ['Principal Member', 'Dependent', 'Elected Dependent', 'Premium Payer'];
const entity_gender = ['Male', 'Female'];
const address_types = ['Postal', 'Residential', 'Postal + Residential'];
const marital_statuses = ['Single', 'Married', 'Divorced', 'Widowed', 'Not Specified'];
const contact_types = ['Mobile', 'Landline', 'Email'];
const banking_types = ['Collection', 'Settlement', 'Collection + Settlement'];
// const banks_list = ['Absa', 'Standard Bank', 'FNB', 'Capitec', 'Time Bank', 'Discovery Bank'];
const bank_account_types = ['Savings', 'Cheque'];
const elected_payment_dates = [1, 7, 16, 26, 30];
const payment_methods = ['CASH', 'DEBIT ORDER', 'RECURRING ONLINE CARD COLLECTION', 'ONCE OFF ONLINE CARD COLLECTION', 'EFT', 'CARD SWIPE', 'STOP ORDER'];
const agent_commission_rules = ['PERCENTAGE_PER_POLICY', 'FIXED_AMOUNT_PER_POLICY', 'PERCENTAGE_PER_LOAN', 'FIXED_AMOUNT_PER_LOAN', 'MONTHLY_SALARY'];
const document_types = [
    // underwriter docs
    {
        type: 'underwriter',
        name: 'Underwriter Agreement'
    },
    {
        type: 'underwriter',
        name: 'Product Info'
    },
    {
        type: 'underwriter',
        name: 'Member Submission File Template'
    },

    // Entity docs
    {
        type: 'entity',
        name: 'ID Document'
    },
    // {
    //     type: 'entity',
    //     name: 'Debit Order Mandate'
    // },
    {
        type: 'entity',
        name: 'Birth Certificate'
    },
    {
        type: 'entity',
        name: 'Marriage Certificate'
    },
    // {
    //     type: 'entity', //and claim???
    //     name: 'Death Certificate'
    // },

    // Business Partner docs
    {
        type: 'business_partner',
        name: 'Company Reg Certificate'
    },
    {
        type: 'business_partner',
        name: 'Debit Order Mandate'
    },
    {
        type: 'business_partner',
        name: 'Business Introducer Agreement'
    },
    {
        type: 'business_partner',
        name: 'VAS Provider Agreement'
    },

    // Claim
    {
        type: 'claim',
        name: 'Death Certificate'
    },
    {
        type: 'claim',
        name: 'Police Report'
    },
    {
        type: 'claim',
        name: 'Burial Order'
    },
    {
        type: 'claim',
        name: 'BI-1663'
    },
    {
        type: 'claim',
        name: 'BI-1680'
    },
    {
        type: 'claim',
        name: 'DHA-1663'
    },

    // Policy docs
    {
        type: 'policy',
        name: 'Debit Order Mandate'
    },
    {
        type: 'policy',
        name: 'Application Form'
    },
    {
        type: 'policy',
        name: 'Underwriter Policy Schedule'
    },
    {
        type: 'policy',
        name: 'Policy Certificate'
    },

    // Policy-Prospect docs
    {
        type: 'policy_prospect',
        name: 'Signed Application Form'
    },
    {
        type: 'policy_prospect',
        name: 'Signed Debit Order Mandate'
    },

    // Loan docs
    {
        type: 'loan',
        name: 'Affidavit'
    },
    {
        type: 'loan',
        name: 'Bank Statement'
    },
    {
        type: 'loan',
        name: 'Debit Order Mandate'
    },

    // Loan Quote docs
    {
        type: 'loan_quote',
        name: 'Bank Statement'
    },
    {
        type: 'loan_quote',
        name: 'Signed Loan Contract'
    },
    {
        type: 'loan_quote',
        name: 'Signed Debit Order Mandate'
    },

    // Corporate Bond docs
    {
        type: 'corporate_bond',
        name: 'Signed Bond Contract'
    },
    {
        type: 'corporate_bond',
        name: 'Signed Debit Order Mandate'
    },

    // Scheme docs
    {
        type: 'scheme',
        name: 'Scheme Info'
    },
    {
        type: 'scheme',
        name: 'Scheme Agreement'
    },
];

const POLICY_STATUS_ACTIONS = {
    // Draft
    DRAFT_TO_PENDING: 1,
    // Pending
    PENDING_TO_DECLINE: 2,
    PENDING_TO_ACTIVE: 3,
    // Active
    ACTIVE_TO_LAPSE: 4,
    ACTIVE_TO_PENDING_CHANGES: 5,
    ACTIVE_TO_CLOSED: 6,
    // Pending Changes
    PENDING_CHANGES_TO_ACTIVE: 7,
    // Lapse
    LAPSE_TO_ACTIVE: 8,
    LAPSE_TO_CLOSED: 9
};

const PROSPECT_POLICY_STATUS_ACTIONS = {
    // Draft
    DRAFT_TO_PENDING: 1,
    // Pending
    PENDING_TO_DECLINE: 2,
    PENDING_TO_AWAITING_UNDERWRITER_APPROVAL: 3,
    AWAITING_UNDERWRITER_APPROVAL_TO_APPROVED: 4,
};

const LOAN_QUOTE_STATUS_ACTIONS = {
    // Draft
    DRAFT_TO_PENDING_APPROVAL: 1,
    // Pending Approval
    PENDING_APPROVAL_TO_APPROVED: 2,
    PENDING_APPROVAL_TO_DECLINED: 3,
    // Approved
    APPROVED_TO_PAID_OUT: 4,
};

const BANK_STATEMENT_STATUS_ACTIONS = {
    // Not-Processed
    NOT_PROCESSED_TO_PROCESSING: 1,
};

const CORPORATE_BOND_STATUS_ACTIONS = {
    DRAFT_TO_ACTIVE: 1,
    DRAFT_TO_PAID_OFF: 2,
    ACTIVE_TO_CLOSED: 3,
}

const LOAN_STATUS_ACTIONS = {
    PENDING_PAYOUT_TO_ACTIVE: 1,
    PENDING_PAYOUT_TO_CANCELLED: 2,

    // // Draft
    // DRAFT_TO_PENDING_PAYOUT: 1,
    // // Pending Payout
    // PENDING_PAYOUT_TO_ACTIVE: 2,
    // // Active
    // ACTIVE_TO_STAGE_1_IMPAIRABLE: 3,
    // ACTIVE_TO_REPAID: 4,
    // // Impairable
    // STAGE_1_TO_STAGE_2_IMPAIRABLE: 5,
    // STAGE_2_TO_STAGE_3_IMPAIRABLE: 6,
    // STAGE_3_IMPAIRABLE_TO_IMPAIRED: 7,
    // IMPAIRABLE_TO_ACTIVE: 8,

    // PENDING_PAYOUT_TO_DECLINED: 9
};

const CLAIM_STATUS_ACTIONS = {
    // Draft
    DRAFT_TO_PENDING: 1,
    // Pending
    PENDING_TO_DECLINE: 2,
    PENDING_TO_APPROVED: 3
};

const POLICY_STATUSES = {
    DRAFT: 'DRAFT',
    PENDING: 'PENDING',
    ACTIVE: 'ACTIVE',
    DECLINED: 'DECLINED',
    LAPSABLE: 'LAPSABLE',
    LAPSED: 'LAPSED',
    PENDING_CHANGES: 'PENDING_CHANGES',
    DECLINED_CHANGES: 'DECLINED_CHANGES',
    CLOSED: 'CLOSED',
};

const POLICY_PROSPECT_STATUSES = {
    DRAFT: 'DRAFT',
    PENDING_APPROVAL: 'PENDING_APPROVAL',
    AWAITING_UNDERWRITER_APPROVAL: 'AWAITING_UNDERWRITER_APPROVAL',
    APPROVED: 'APPROVED',
};

const LOAN_STATUSES = {
    DRAFT: 'DRAFT',
    PENDING_PAYOUT: 'PENDING_PAYOUT',
    ACTIVE: 'ACTIVE',
    DECLINED: 'DECLINED',
    STAGE_1_IMPAIRABLE: 'STAGE_1_IMPAIRABLE',
    STAGE_2_IMPAIRABLE: 'STAGE_2_IMPAIRABLE',
    STAGE_3_IMPAIRABLE: 'STAGE_3_IMPAIRABLE',
    STAGE_3_IMPAIRABLE_ACTION_REQUIRED: 'STAGE_3_IMPAIRABLE_ACTION_REQUIRED',
    IMPAIRED: 'IMPAIRED',
    REPAID: 'REPAID',
};

const BOND_STATUSES = {
    DRAFT: 'DRAFT',
    PENDING_APPROVAL: 'PENDING_APPROVAL',
    ACTIVE: 'ACTIVE',
    PAID_OFF: 'PAID_OFF',
    CLOSED: 'CLOSED',
};

const LOAN_QUOTE_STATUSES = {
    DRAFT: 'DRAFT',
    PENDING_APPROVAL: 'PENDING_APPROVAL',
    DECLINED: 'DECLINED',
    PAID_OUT: 'PAID_OUT',
};

const POLICY_MEMBERSHIP_EDIT_ACTIONS = {
    ACTIVATE_MEMBER: 'ACTIVATE_MEMBER',
    EXPIRE_MEMBER: 'EXPIRE_MEMBER',
}

const USER_LEVELS = {
    ROOT_ADMIN: 'ROOT_ADMIN', //For the whole underwriting system
    ADMIN: 'ADMIN',
    BRANCH_MANAGER: 'BRANCH_MANAGER',
    BRANCH_OFFICE_AGENT: 'BRANCH_OFFICE_AGENT',
};

const BUSINESS_PARTNER_TYPES = {
    BUSINESS_INTRODUCER: 'BUSINESS_INTRODUCER',
    CENTRAL_UNDERWRITER: 'CENTRAL_UNDERWRITER',
    VAS_PROVIDER: 'VAS_PROVIDER'
}

const BUSINESS_PARTNER_TYPES_LIST = [ 'BUSINESS_INTRODUCER', 'VAS_PROVIDER', ];

const BP_ADMINISTRATION_OPTIONS = [
    {
        text: 'MANAGED - The central underwriter will manage everything for the bp',
        value: 'MANAGED'
    },
    {
        text: 'NOT_APPLICABLE - The bp will manage everything for themselves',
        value: 'NOT_APPLICABLE'
    },
    {
        text: 'VAS_PROVIDER - The VAS provider will just use the system to process VAS claims',
        value: 'VAS_PROVIDER'
    },
];

const PRODUCT_TYPES = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary'
}

const membership_categories = ['Dependent - Spouse', 'Dependent - Child', 'Dependent - Extended', 'Principal Member'];

const PAYOUT_MEMBERSHIP_CATEGORY = {
    DEPENDENT_SPOUSE: 'Dependent - Spouse',
    DEPENDENT_CHILD: 'Dependent - Child',
    DEPENDENT_EXTENDED: 'Dependent - Extended',
    PRINCIPAL_MEMBER: 'Principal Member'
};

function normalizePort(val) {
    const port = parseInt(val, 10);
    if (Object.is(port, NaN)) {
      // named pipe
      return val;
    }
    if (port >= 0) {
      // port number
      return port;
    }
    return false;
}

module.exports = {
    policy_roles,
    entity_gender,
    address_types,
    marital_statuses,
    contact_types,
    banking_types,
    bank_account_types,
    elected_payment_dates,
    payment_methods,
    agent_commission_rules,
    document_types,
    POLICY_STATUS_ACTIONS,
    POLICY_PROSPECT_STATUSES,
    POLICY_STATUSES,
    PROSPECT_POLICY_STATUS_ACTIONS,
    LOAN_QUOTE_STATUS_ACTIONS,
    BANK_STATEMENT_STATUS_ACTIONS,
    CORPORATE_BOND_STATUS_ACTIONS,
    LOAN_STATUS_ACTIONS,
    BOND_STATUSES,
    LOAN_STATUSES,
    LOAN_QUOTE_STATUSES,
    POLICY_MEMBERSHIP_EDIT_ACTIONS,
    CLAIM_STATUS_ACTIONS,

    USER_LEVELS,
    BUSINESS_PARTNER_TYPES,
    BUSINESS_PARTNER_TYPES_LIST,
    BP_ADMINISTRATION_OPTIONS,
    PRODUCT_TYPES,

    membership_categories,

    PAYOUT_MEMBERSHIP_CATEGORY,

    port: normalizePort(process.env.PORT || '8080'),
    host: process.env.HOST || process.env.DEFAULT_HOST || 'localhost',
    static_dir: 'dist',
    API_URL: process.env.API_URL || 'https://api.dev.afrosurance.com',
    SOCKET_URL: process.env.SOCKET_URL || 'ws://api.dev.afrosurance.com',
    API_HOSTNAME: process.env.API_HOSTNAME || "api.dev.afrosurance.com"
}
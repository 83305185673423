var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"80%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-toolbar',{staticStyle:{"border":"1px solid #07ad2d"},attrs:{"dark":"","color":"secondary"}},[_c('v-toolbar-title',[_c('b',[_vm._v("Updated Payment Arrangement Details")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.resetForm()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Reset Form")])]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card',[_c('v-card-text',[_c('v-row',{staticStyle:{"background":"#4490a030","padding-bottom":"10px"}},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"value":"PREMIUM_PAYER","label":"Payer Type","color":"secondary","disabled":"","required":""}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[(parseFloat(_vm.policy_copy.payment_information.premium_payer.percentage_amount) > 0)?_c('v-select',{attrs:{"items":[
                                'CASH',
                                'EFT',
                                'STOP ORDER' ],"label":"Payment Method","color":"secondary","hint":"Premium Payer Payment method","persistent-hint":"","required":""},model:{value:(_vm.policy_copy.payment_information.premium_payer.payment_method),callback:function ($$v) {_vm.$set(_vm.policy_copy.payment_information.premium_payer, "payment_method", $$v)},expression:"policy_copy.payment_information.premium_payer.payment_method"}}):_c('v-text-field',{attrs:{"value":"NOT APPLICABLE","hint":"Member Payment method","persistent-hint":"","disabled":"","required":""}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"prefix":"%","color":"secondary","label":"Percentage Amount","type":"number","required":""},model:{value:(_vm.policy_copy.payment_information.premium_payer.percentage_amount),callback:function ($$v) {_vm.$set(_vm.policy_copy.payment_information.premium_payer, "percentage_amount", $$v)},expression:"policy_copy.payment_information.premium_payer.percentage_amount"}})],1)],1),(parseFloat(_vm.policy_copy.payment_information.premium_payer.percentage_amount) < 100)?_c('v-row',{staticStyle:{"background":"#4490a030","padding-bottom":"10px"}},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"value":"SCHEME","label":"Payer Type","color":"secondary","disabled":"","required":""}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[(_vm.policy_copy.payment_information.scheme.percentage_amount > 0)?_c('v-select',{attrs:{"items":[
                                'CASH',
                                'EFT',
                                'STOP ORDER' ],"label":"Payment Method","color":"secondary","hint":"Scheme Payment method","persistent-hint":"","required":""},model:{value:(_vm.policy_copy.payment_information.scheme.payment_method),callback:function ($$v) {_vm.$set(_vm.policy_copy.payment_information.scheme, "payment_method", $$v)},expression:"policy_copy.payment_information.scheme.payment_method"}}):_c('v-text-field',{attrs:{"value":"NOT APPLICABLE","hint":"Scheme Payment method","persistent-hint":"","disabled":"","required":""}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"prefix":"%","color":"secondary","label":"Percentage Amount","type":"number","disabled":"","required":""},model:{value:(_vm.policy_copy.payment_information.scheme.percentage_amount),callback:function ($$v) {_vm.$set(_vm.policy_copy.payment_information.scheme, "percentage_amount", $$v)},expression:"policy_copy.payment_information.scheme.percentage_amount"}})],1)],1):_vm._e()],1),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"color":"green","height":"10","indeterminate":"","active":_vm.submitting}}),(_vm.submitting)?_c('v-list',{attrs:{"flat":""}},[_c('v-subheader',[_vm._v("Saving Policy Transaction")]),_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.backendActionItems),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("done")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)],1)}),1)],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"large":"","color":"success","disabled":_vm.submitBtnDisabled()},on:{"click":function($event){return _vm.submitUpdate()}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("save")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div>
        <v-toolbar
            color="secondary"
            dark
            class="pa-0"
            height="50"
            extension-height="3"
        >
            <!-- Action Buttons -->
            <v-spacer/>
        </v-toolbar>

        
        <v-row>
            <v-col cols="12" md="4">
                <v-card
                    class="ma-3 pa-6"
                    outlined
                    tile
                >
                    <h3>Monthly Revenue</h3>
                    <hr/>
                    <BarChart/>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card
                    class="ma-3 pa-6"
                    outlined
                    tile
                >
                    <h3>Monthly Revenue</h3>
                    <hr/>
                    <BarChart/>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card
                    class="ma-3 pa-6"
                    outlined
                    tile
                >
                    <h3>Monthly Sales</h3>
                    <hr/>
                    <PieChart/>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card
                    class="ma-3 pa-6"
                    outlined
                    tile
                >
                    <h3>Monthly Sales</h3>
                    <hr/>
                    <PieChart/>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
// import VueCharts from 'vue-chartjs';
// import { Bar, Line } from 'vue-chartjs';
import BarChart from './charts/bar_chart.vue';
import PieChart from './charts/pie_chart.vue';

export default {
    components: {
        BarChart,
        PieChart
    }

}
</script>

<style>

</style>
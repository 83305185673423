<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Loan Search</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeMemberSearchDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <!-- Loan Number -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.loan_number.search"
                                color="success"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-text-field
                                v-model="search_fields.loan_number.value"
                                prefix="L"
                                label="Loan Number"
                                type="number"
                                color="secondary"
                                required
                                :disabled="!search_fields.loan_number.search"
                            ></v-text-field>
                        </v-col>

                        <!-- Loan Status -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.status.search"
                                color="success"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-select
                                v-model="search_fields.status.value"
                                :items="[
                                    'DRAFT',
                                    'PENDING_PAYOUT',
                                    'ACTIVE',
                                    'DECLINED',
                                    'STAGE_1_IMPAIRABLE',
                                    'STAGE_2_IMPAIRABLE',
                                    'STAGE_3_IMPAIRABLE',
                                    'IMPAIRED',
                                    'REPAID',
                                ]"
                                label="Loan Status"
                                color="secondary"
                                :disabled="!search_fields.status.search"
                                required
                            ></v-select>
                        </v-col>

                        <!-- Entity Id Number -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.loan_entity_id_number.search"
                                color="success"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <MemberSearch :field_disabled="!search_fields.loan_entity_id_number.search" :member="loan_entity" label="Loan Entity" />
                        </v-col>

                        <!-- Loan Agent -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.agent.search"
                                color="success"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <AgentSearch :field_disabled="!search_fields.agent.search" :member="loan_agent" label="Loan Agent" />
                            <!-- <AgentSearch :member="agent_details" label="Agent Who Recruited This Member" /> -->
                        </v-col>

                        <!-- Effective Date -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.effective_date.search"
                                color="success"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-menu
                                v-model="effective_date_menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="search_fields.effective_date.value"
                                    label="Effective Date"
                                    color="secondary"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :disabled="!search_fields.effective_date.search"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    color="secondary"
                                    v-model="search_fields.effective_date.value"
                                    @input="effective_date_menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>

                        <!-- Elected Payment Date -->
                        <v-col cols="12" sm="1">
                            <v-checkbox
                                v-model="search_fields.elected_payment_date.search"
                                color="success"
                                required
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-text-field
                                v-model="search_fields.elected_payment_date.value"
                                type="number"
                                label="Elected Payment Date"
                                color="secondary"
                                :disabled="!search_fields.elected_payment_date.search"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Searching Loans
                        <v-progress-linear
                            indeterminate
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom v-if="search_field_selected">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                @click="submitSearchQuery()"
                            >
                                <v-icon large>search</v-icon>
                            </v-btn>
                        </template>
                        <span>Submit Search Query</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import MemberSearch from "../components/member_search";
import AgentSearch from "../components/agent_search";
import { eventBus } from '../../../main';
import { emitAlert } from '../../../utils/api';
import { getLoans } from "../gql/queries";
export default {
    name: 'SearchLoansDialog',
    components: {
        MemberSearch,
        AgentSearch,
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        effective_date_menu: false,
        loan_entity: {
            member: null
        },
        loan_agent: {
            member: null
        },
        search_fields: {
            loan_number: {
                search: false,
                value: null,
            },
            status: {
                search: false,
                value: null,
            },
            loan_entity_id_number: {
                search: false,
                value: null,
            },
            agent: {
                search: false,
                value: null,
            },
            effective_date: {
                search: false,
                value: null,
            },
            elected_payment_date: {
                search: false,
                value: null,
            },
        },
        submitting: false
    }),
    methods: {
        closeMemberSearchDialog() {
            eventBus.$emit('CLOSE_LOAN_SEARCH_DIALOG', null);
        },
        resetForm() {
            this.search_fields = {
                loan_number: {
                    search: false,
                    value: null,
                },
                status: {
                    search: false,
                    value: null,
                },
                loan_entity_id_number: {
                    search: false,
                    value: null,
                },
                agent: {
                    search: false,
                    value: null,
                },
                effective_date: {
                    search: false,
                    value: null,
                },
                elected_payment_date: {
                    search: false,
                    value: null,
                },
            };
        },
        async submitSearchQuery() {
            this.submitting = true;

            const searchObj = {};

            Object.keys(this.search_fields).forEach(searchField => {
                if (this.search_fields[ searchField ].search === true) {
                    if (
                        this.search_fields[ searchField ].value !== null &&
                        this.search_fields[ searchField ].value !== undefined
                    ) {
                        searchObj[ searchField ] = this.search_fields[ searchField ].value;
                    }

                    // Make sure we don't send empty strings
                    if (typeof(this.search_fields[ searchField ].value) === 'string') {
                        if (this.search_fields[ searchField ].value.trim().length === 0) {
                            delete searchObj[ searchField ];
                        }
                    }
                }
            });

            if (Object.keys(searchObj).length === 0) {
                this.submitting = false;
                emitAlert('Please capture valid search field values');
                return;
            }
            
            // const searchRes = await searchLoan(searchObj);
            // this.submitting = false;
            // eventBus.$emit('CLOSE_LOAN_SEARCH_DIALOG', searchRes.loans);


            try {
                const res = await this.$apollo
                                        .query({
                                            query: getLoans,
                                            // fetchPolicy: 'network-only',
                                            fetchPolicy: 'no-cache',
                                            errorPolicy: 'all',
                                            variables: {
                                                objectType: 'LOAN',
                                                pagination: {
                                                    page: 1,
                                                    limit: 1000
                                                },
                                                searchInput: searchObj
                                            },
                                        });
                this.submitting = false;
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }
    
                // this.loans = res.data.getBusinessObject.business_objects;
                eventBus.$emit('CLOSE_LOAN_SEARCH_DIALOG', res.data.getBusinessObject.business_objects);
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
    },
    mounted() {
        
    },
    computed: {
        search_field_selected() {
            return (
                this.search_fields.loan_number.search ||
                this.search_fields.status.search ||
                this.search_fields.loan_entity_id_number.search ||
                this.search_fields.agent.search ||
                this.search_fields.effective_date.search ||
                this.search_fields.elected_payment_date.search
            )
        }
    },
    watch: {
        "loan_entity.member": function(val) {
            if (val === null) {
                this.search_fields.loan_entity_id_number.value = null;
            } else {
                this.search_fields.loan_entity_id_number.value = val.id_number;
            }
        },
        "loan_agent.agent": function(val) {
            if (val === null) {
                this.search_fields.agent.value = null;
            } else {
                this.search_fields.agent.value = val._id;
            }
            // console.log(val)
        }
    }
}
</script>
<style scoped>

</style>
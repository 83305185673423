<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="60%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Get Agent Commissions Report</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <!-- Branch -->
                        <!-- <v-col cols="12" sm="12">
                            <v-autocomplete
                                v-model="fin_calendar_month"
                                :items="fin_calendar_months_lookup"
                                color="secondary"
                                label="Financial Calendar Month"
                                item-value="_id"
                                item-text="name"
                            ></v-autocomplete>
                        </v-col> -->
                        <v-col cols="12" sm="6">
                            <v-menu
                                v-model="start_date_menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="start_date"
                                    color="secondary"
                                    label="Start Date"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    color="secondary"
                                    v-model="start_date"
                                    @input="start_date_menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-menu
                                v-model="end_date_menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="end_date"
                                    color="secondary"
                                    label="End Date"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    color="secondary"
                                    v-model="end_date"
                                    @input="end_date_menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Retreiving Agent Commissions Report
                        <v-progress-linear
                            indeterminate
                            :active="submitting"
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submitAgentBtnDisabled()"
                                @click="downloadAgentReport()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Submit Agent's Details</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess, getAgentCommissionsReport, getAgentGloabCommissionsReport } from "../../../utils/api";
import { eventBus } from "../../../main";

import { getFinancialCalendarMonths } from "../gql/queries";
import { entityProcessAgent } from "../gql/mutations";

export default {
    components: {},
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        agent: {
            type: Object,
            default: null
        },
        mode: {
            type: String,
            default: null
        },
    },
    data: () => ({
        server_response_obj: null,
        submitting: false,

        fin_calendar_month: null,
        refreshing_fin_calendar_month_list: false,
        fin_calendar_months_lookup: [],

        start_date_menu: false,
        start_date: null,

        end_date_menu: false,
        end_date: null,

        branch_details: {
            branch: null,
            start_date: null,
        },
    }),
    methods: {
        closeDialog() {
            eventBus.$emit('CLOSE_AGENT_COMMISSIONS_REPORT_DIALOG', this.server_response_obj);
        },
        resetForm() {
            this.submitting = false;
            this.start_date = null;
            this.end_date = null;
        },

        submitAgentBtnDisabled() {
            if (this.start_date === null) {
                return true;
            }
            if (this.end_date === null) {
                return true;
            }
            return false;
        },

        async downloadAgentReport() {
            this.submitting = true;
            try {
                let res = null;
                let fileName = `GLOABAL-RPORT-(${this.start_date}-TO-${this.end_date}).xlsx`; // Set the file name
                if (this.mode === 'agent') {
                    res = await getAgentCommissionsReport(this.agent._id, this.start_date, this.end_date, this.mode);
                    fileName = `${this.agent.entity.name}-${this.agent.entity.surname}-(${this.start_date}-TO-${this.end_date}).xlsx`; // Set the file name

                    const url = window.URL.createObjectURL(new Blob([res], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));

                    const a = document.createElement("a");
                    a.href = url;
                    a.download = fileName;

                    // Append the anchor to the body
                    document.body.appendChild(a);

                    // Programmatically click the anchor to trigger the download
                    a.click();

                    // Remove the anchor from the document
                    document.body.removeChild(a);

                    // Release the ObjectURL when done
                    URL.revokeObjectURL(url);
                } else {
                    // global report
                    res = await getAgentGloabCommissionsReport(this.start_date, this.end_date, this.mode);
                    emitSuccess(res.message);
                    this.closeDialog();
                    this.resetForm();
                }
                
                
                this.submitting = false;
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.entityProcessScheme.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
        },

        async submitAgent() {
            this.submitting = true;

            try {
                const res = await this.$apollo
                                                .mutate({
                                                    mutation: entityProcessAgent,
                                                    variables: {
                                                        input: {
                                                            OP_TYPE: 'UPDATE_BRANCH_ASSIGNMENT',
                                                            _id: this.agent._id,
                                                            
                                                            branch_history: [
                                                                {
                                                                    branch: this.branch_details.branch._id,
                                                                    start_date: this.branch_details.start_date
                                                                }
                                                            ]
                                                        }
                                                    },
                                                });
                if (res.data.entityProcessAgent.errors === true) {
                    emitAlert(res.data.entityProcessAgent.message);
                    this.submitting = false;
                    return;
                }
                
                this.server_response_obj = res.data.entityProcessAgent.business_object;
                this.closeDialog();
                emitSuccess(res.data.entityProcessAgent.message);

                // Reset fields
                this.server_response_obj = null;
                this.submitting = false;
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.entityProcessAgent.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request')
                }
                
                return;
            }
        },
        async getFinCalendarMonthsLookupList() {
            this.refreshing_fin_calendar_month_list = true;
            try {
                // Load Opened Fin Calendar Months
                const res = await this.$apollo
                                    .query({
                                        query: getFinancialCalendarMonths,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'FINANCE_CALENDAR_MONTH',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            },
                                            searchInput: {
                                                // status: 'OPEN'
                                            }
                                        },
                                    });
                this.refreshing_fin_calendar_month_list = false;
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }

                if (res.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }
                this.fin_calendar_months_lookup = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.refreshing_fin_calendar_month_list = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
    },
    async mounted() {
        await this.getFinCalendarMonthsLookupList();
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Edit Business Partner Basic Details</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn
                    icon
                    @click="closeEditBusinessPartnerDialog()"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="business_partner_copy.name"
                                color="secondary"
                                label="Business Partner Name"
                                autocomplete="some-random-text"
                                type="text"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="business_partner_copy.company_reg_number"
                                color="secondary"
                                label="Company Reg Number"
                                autocomplete="some-random-text"
                                type="text"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-select
                                v-model="business_partner_copy.type"
                                :items="business_partner_types"
                                label="Type"
                                color="secondary"
                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <v-select
                                v-model="business_partner_copy.administration_option"
                                :items="bp_administration_options"
                                item-text="text"
                                item-value="value"
                                label="Administration Option"
                                color="secondary"
                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-select
                                v-model="business_partner_copy.status"
                                :items="['ACTIVE', 'INACTIVE', 'BLOCKED']"
                                label="STatus"
                                color="secondary"
                                required
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Updating Business Partner Details
                        <v-progress-linear
                            indeterminate
                            :active="submitting"
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        large
                        color="success"
                        :disabled="submitBtnDisabled()"
                        @click="submitBusinessPartner()"
                    >
                        <v-icon large>save</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";
import { BUSINESS_PARTNER_TYPES_LIST, BP_ADMINISTRATION_OPTIONS } from "../../../../config";

import { entityProcessBusinessPartner } from "../gql/mutations";

export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        business_partner_original: {
            type: Object,
            required: false,
            default: () => {
                return {
                    marketing_channels: []
                }
            },
        },
        business_partner_copy: {
            type: Object,
            required: false,
            default: () => {
                return {
                    marketing_channels: []
                }
            },
        },
    },
    data: () => ({
        submitting: false,
        server_response_obj: null,
        business_partner_types: BUSINESS_PARTNER_TYPES_LIST,
        bp_administration_options: BP_ADMINISTRATION_OPTIONS
    }),
        
    methods: {
        closeEditBusinessPartnerDialog() {
            eventBus.$emit('CLOSE_EDIT_BP_DIALOG', this.server_response_obj);
        },
        resetForm() {
            this.server_response_obj = null;
            eventBus.$emit('RESET_BP_EDIT_FORM', this.business_partner_copy);
        },
        
        submitBtnDisabled() {
            if (
                (this.business_partner_copy.name + '').trim() === (this.business_partner_original.name + '').trim() &&
                this.business_partner_copy.type === this.business_partner_original.type &&
                this.business_partner_copy.administration_option === this.business_partner_original.administration_option &&
                this.business_partner_copy.status === this.business_partner_original.status
            ) {
                return true;
            } else {
                // Cater for if name is an empty string
                if (typeof(this.business_partner_copy.name) === 'string') {
                    if (
                        this.business_partner_copy.name.trim() === '' &&
                        this.business_partner_original.name === null    
                    ) {
                        this.business_partner_copy.name = null;
                        return true;
                    }
                }
            }
            return false;
        },

        capturingDone() {
            this.closeEditBusinessPartnerDialog();
            this.resetForm();
        },
        async submitBusinessPartner() {
            this.submitting = true;

            try {
                // Payload payload
                const submitObj = {
                    name: this.business_partner_copy.name,
                    type: this.business_partner_copy.type,
                    administration_option: this.business_partner_copy.administration_option,
                    status: this.business_partner_copy.status,
                };

                // const res = await updateBusinessPartnerDetails(this.business_partner_original._id, submitObj);
                // if (res.errors === true) {
                //     emitAlert(res.message);
                //     this.submitting = false;
                //     return;
                // }

                const payloadObj = {
                    OP_TYPE: 'UPDATE',
                    _id: this.business_partner_original._id,
                    
                    ...submitObj
                };

                const res = await this.$apollo
                                            .mutate({
                                                mutation: entityProcessBusinessPartner,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.entityProcessBusinessPartner.errors === true) {
                    emitAlert(res.data.entityProcessBusinessPartner.message);
                    this.submitting = false;
                    return;
                }
                
                this.server_response_obj = res.data.entityProcessBusinessPartner.business_object;
                this.closeEditBusinessPartnerDialog();
                emitSuccess(res.data.entityProcessBusinessPartner.message);

                // Reset fields
                this.server_response_obj = null;
                this.submitting = false;
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.entityProcessBusinessPartner.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
        },
    },
    mounted() {

    },
}
</script>
<style scoped>

</style>
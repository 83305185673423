<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Add BP Bank Account Details</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row
                        v-for="banking in bank_account_details"
                        :key="banking.id"
                        style="background:#eaeaea; padding-bottom:10px"
                    >
                        <v-col cols="12" sm="12" v-if="banking.type === 1">
                            <h3>Banking Details</h3>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-select
                                v-model="banking.purpose"
                                :items="banking_detail_purposes"
                                color="secondary"
                                label="Purpose"
                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-select
                                v-model="banking.bank"
                                :items="banks_list"
                                item-value="_id"
                                item-text="name"
                                color="secondary"
                                label="Bank"
                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-select
                                v-model="banking.account_type"
                                :items="bank_account_types"
                                color="secondary"
                                label="Account Type"
                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                v-model="banking.account_number"
                                color="secondary"
                                label="Account Number"
                                autocomplete="some-random-text"
                                type="number"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <!-- Buttons for Adding/Removing Banking Details -->
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting Business Partner Banking Details
                        <v-progress-linear
                            indeterminate
                            :active="submitting"
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submitBtnDisabled()"
                                @click="submitData()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Save Business Partner Banking Details</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";
import { banking_types, bank_account_types } from "../../../../config";

import { getBanks } from "../gql/queries";
import { entityProcessBusinessPartner } from "../gql/mutations";

export default {
    components: {
        
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        business_partner_id: {
            type: String,
            default: null
        },
    },
    data: () => ({
        server_response_obj: null,
        submitting: false,
        bank_account_details: [
            {
                id: 1,
                purpose: null,

                // Banking Details
                bank: null,
                account_type: null,
                account_number: null,
            }
        ],

        isTypingCvc: false,
        cc: {
            number: '',
            holder: '',
            exp: '',
            cvc: '',
        },

        banking_detail_purposes: banking_types,
        banks_list: [],
        bank_account_types: bank_account_types,
    }),
    methods: {
        closeDialog() {
            eventBus.$emit('CLOSE_ADD_BP_BANKING_DIALOG', this.server_response_obj);
        },

        submitBtnDisabled() {
            if (
                this.bank_account_details[0].purpose === null ||
                this.bank_account_details[0].bank === null ||
                this.bank_account_details[0].account_number === null
            ) {
                return true;
            }

            if (typeof(this.bank_account_details[0].account_number) === 'string') {
                if (this.bank_account_details[0].account_number.trim().length === 0) {
                    return true;
                }
            }
            return false;
        },

        resetForm() {
            this.submitting = false;
            this.bank_account_details = [
                {
                    id: 1,
                    purpose: null,
                    bank: null,
                    account_type: null,
                    account_number: null
                }
            ];

            this.server_response_obj = null;
        },

        async submitData() {
            const validBankingDetails = this.bank_account_details
                                    .filter(bank => { return bank.purpose !== null })
                                    .map(bank => {
                                        delete bank.id;
                                        return bank;
                                    });

            if (validBankingDetails.length === 0) {
                emitAlert('Invalid banking details');
                return;
            }

            this.submitting = true;

            // Quickly sanitize the payload
            validBankingDetails.forEach(item => {
                item.account_number = item.account_number.trim()
            });

            try {
                const payloadObj = {
                    OP_TYPE: 'ADD_BANKING',
                    _id: this.business_partner_id,

                    bank_account_details: validBankingDetails
                };

                const res = await this.$apollo
                                            .mutate({
                                                mutation: entityProcessBusinessPartner,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.entityProcessBusinessPartner.errors === true) {
                    emitAlert(res.data.entityProcessBusinessPartner.message);
                    this.submitting = false;
                    return;
                }
                
                this.server_response_obj = res.data.entityProcessBusinessPartner.business_object;

                this.closeDialog();
                emitSuccess(res.data.entityProcessBusinessPartner.message);

                // Reset fields
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.entityProcessBusinessPartner.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
    },
    async mounted() {
        this.submitting = true;
        try {
            const res = await this.$apollo
                                    .query({
                                        query: getBanks,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'BANK',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.submitting = false;
                return;
            }
            
            this.banks_list = res.data.getBusinessObject.business_objects;

            // Reset fields
            this.submitting = false;
        } catch (error) {
            this.submitting = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
            
            return;
        }
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
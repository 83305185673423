<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="90%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Member Change History</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeViewMemberChangeHistoryDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text style="height: 500px; overflow-y:scroll; overflow-x:none">
                    <div v-if="change_history !== null" >
                        <v-timeline
                            v-for="(change, index) in change_history.changes"
                            :key="index"
                            dense
                        >
                            <v-timeline-item
                                color="red"
                                small
                            >
                                <v-row class="pt-2" :style="
                                        change.type === 'added'? 
                                        'background:#65ad61' :
                                        change.type === 'deleted' ? 'background:#f6c209' : 'background:#189eec'">
                                    <v-col cols="2">
                                        <strong>Date</strong>
                                        <div class="caption">
                                            {{ change.created.split('T')[0] + '    ' + change.created.split('T')[1].substr(0,5) }}
                                        </div>
                                    </v-col>
                                    <v-col cols="2">
                                        <strong>Change Type</strong>
                                        <div class="caption">
                                            {{ change.description }}
                                        </div>
                                    </v-col>
                                    <v-col cols="3">
                                        <strong>Captured By</strong>
                                        <div class="caption">
                                            {{ change.captured_by.entity.name + ' ' + change.captured_by.entity.surname }}
                                        </div>
                                    </v-col>
                                    <v-col>
                                        <strong>Change Details</strong>
                                        <!-- Relationships -->
                                        <div class="caption" v-if="change.description === 'Relationship Added' || change.description === 'Relationship Deleted' ">
                                            <div><b>Relationship Type:</b> {{ change.new.relationship_type.name }}</div>
                                            <div><b>Relationship Type Description:</b> {{ change.new.relationship_type.description }}</div>
                                            <div><b>Related Member:</b> {{ change.new.member.name + ' ' + change.new.member.surname }}</div>
                                            <div v-if="change.description === 'Relationship Deleted'">
                                                <hr style="border: 1px black solid"/>
                                                <b>Delete Reason:</b> {{ change.new.delete_reason }}
                                            </div>
                                        </div>
                                        <!-- Addresses -->
                                        <div class="caption" v-if="change.description === 'Address Added' || change.description === 'Address Deleted'">
                                            <div><b>Address Type:</b> {{ change.new.type }}</div>
                                            <div><b>Line 1:</b> {{ change.new.line1 }}</div>
                                            <div><b>Line 2:</b> {{ change.new.line2 }}</div>
                                            <div><b>Line 3:</b> {{ change.new.line3 }}</div>
                                            <div><b>Postal Code:</b> {{ change.new.line4 }}</div>
                                            <div v-if="change.description === 'Address Deleted'">
                                                <hr style="border: 1px black solid"/>
                                                <b>Delete Reason:</b> {{ change.new.delete_reason }}
                                            </div>
                                        </div>
                                        <div class="caption" v-if="change.description === 'Address Updated'">
                                            <div><b>Old Address Type:</b> {{ change.old.type }}</div>
                                            <div><b>Old Line 1:</b> {{ change.old.line1 }}</div>
                                            <div><b>Old Line 2:</b> {{ change.old.line2 }}</div>
                                            <div><b>Old Line 3:</b> {{ change.old.line3 }}</div>
                                            <div><b>Old Postal Code:</b> {{ change.old.line4 }}</div>
                                            <hr style="border: 1px black solid"/>
                                            <div><b>New Address Type:</b> {{ change.new.type }}</div>
                                            <div><b>New Line 1:</b> {{ change.new.line1 }}</div>
                                            <div><b>New Line 2:</b> {{ change.new.line2 }}</div>
                                            <div><b>New Line 3:</b> {{ change.new.line3 }}</div>
                                            <div><b>New Postal Code:</b> {{ change.new.line4 }}</div>
                                        </div>
                                        <!-- Contacts -->
                                        <div class="caption" v-if="change.description === 'Contact Details Added' || change.description === 'Contact Details Deleted'">
                                            <div><b>Contact Type:</b> {{ change.new.type }}</div>
                                            <div><b>Primary Contact:</b> {{ change.new.primary ? 'Yes' : 'No' }}</div>
                                            <div><b>Details:</b> {{ change.new.details }}</div>
                                            <div v-if="change.description === 'Contact Details Deleted'">
                                                <hr style="border: 1px black solid"/>
                                                <b>Delete Reason:</b> {{ change.new.delete_reason }}
                                            </div>
                                        </div>
                                        <div class="caption" v-if="change.description === 'Contact Details Updated'">
                                            <div><b>Old Contact Type:</b> {{ change.old.type }}</div>
                                            <div><b>Old Primary Contact:</b> {{ change.old.primary ? 'Yes' : 'No' }}</div>
                                            <div><b>Old Details:</b> {{ change.old.details }}</div>
                                            <hr style="border: 1px black solid">
                                            <div><b>New Contact Type:</b> {{ change.new.type }}</div>
                                            <div><b>New Primary Contact:</b> {{ change.new.primary ? 'Yes' : 'No' }}</div>
                                            <div><b>New Details:</b> {{ change.new.details }}</div>
                                        </div>
                                        <!-- Documents -->
                                        <div class="caption" v-if="change.description === 'Document Added' || change.description === 'Document Deleted' ">
                                            <div><b>Document Type:</b> {{ change.new.type }}</div>
                                            <div><b>Document Name:</b> {{ change.new.name }}</div>
                                            <div v-if="change.description === 'Document Deleted'">
                                                <hr style="border: 1px black solid"/>
                                                <b>Delete Reason:</b> {{ change.new.delete_reason }}
                                            </div>
                                        </div>
                                        <!-- Banking -->
                                        <div class="caption" v-if="change.description === 'Banking Details Added' || change.description === 'Banking Details Deleted'">
                                            <div><b>Banking Detail Purpose:</b> {{ change.new.purpose }}</div>
                                            <div><b>Bank:</b> {{ change.new.bank }}</div>
                                            <div><b>Account Type:</b> {{ change.new.account_type }}</div>
                                            <div><b>Account Number:</b> {{ change.new.account_number }}</div>
                                            <div v-if="change.description === 'Banking Details Deleted'">
                                                <hr style="border: 1px black solid"/>
                                                <b>Delete Reason:</b> {{ change.new.delete_reason }}
                                            </div>
                                        </div>
                                        <div class="caption" v-if="change.description === 'Banking Details Updated'">
                                            <div><b>Old Banking Detail Purpose:</b> {{ change.old.purpose }}</div>
                                            <div><b>Old Bank:</b> {{ change.old.bank }}</div>
                                            <div><b>Old Account Type:</b> {{ change.old.account_type }}</div>
                                            <div><b>Old Account Number:</b> {{ change.old.account_number }}</div>
                                            <hr style="border: 1px black solid"/>
                                            <div><b>New Banking Detail Purpose:</b> {{ change.new.purpose }}</div>
                                            <div><b>New Bank:</b> {{ change.new.bank }}</div>
                                            <div><b>New Account Type:</b> {{ change.new.account_type }}</div>
                                            <div><b>New Account Number:</b> {{ change.new.account_number }}</div>
                                        </div>
                                        <!-- Name Update -->
                                        <div class="caption" v-if="change.description === 'Name Updated'">
                                            <div><b>Old Value:</b> {{ change.old }}</div>
                                            <div><b>New Value:</b> {{ change.new }}</div>
                                        </div>
                                        <!-- Surname Update -->
                                        <div class="caption" v-if="change.description === 'Surname Updated'">
                                            <div><b>Old Value:</b> {{ change.old }}</div>
                                            <div><b>New Value:</b> {{ change.new }}</div>
                                        </div>
                                        <!-- Marital Status Update -->
                                        <div class="caption" v-if="change.description === 'Marital Status Updated'">
                                            <div><b>Old Value:</b> {{ change.old }}</div>
                                            <div><b>New Value:</b> {{ change.new }}</div>
                                        </div>
                                        <!-- Life Status Update -->
                                        <div class="caption" v-if="change.description === 'Life Status Updated'">
                                            <div><b>Old Value:</b> {{ change.old }}</div>
                                            <div><b>New Value:</b> {{ change.new }}</div>
                                        </div>
                                        <!-- Marketing Channels Update -->
                                        <div class="caption" v-if="change.description === 'Marketing Channels Updated'">
                                            <div><b>Old Value:</b> {{ change.old }}</div>
                                            <div><b>New Value:</b> {{ change.new }}</div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-timeline-item>
                        </v-timeline>
                    </div>
                </v-card-text>
                <!-- Button Section -->
                <!-- <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                color="secondary"
                                v-on="on"
                                @click="previewServerFile(document._id)"
                            >
                                <v-icon>remove_red_eye</v-icon>
                            </v-btn>
                        </template>
                        <span>Preview File From Server</span>
                    </v-tooltip>
                    <v-btn
                        fab
                        color="primary"
                        @click="closeViewMemberChangeHistoryDialog()"
                    >
                        <v-icon large>done</v-icon>
                    </v-btn>
                </v-card-actions> -->
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { eventBus } from "../../../main";
export default {
    components: {},
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        change_history: {
            type: Object,
            required: false,
            default: () => {
                return {}
            }
        },
    },
    data: () => ({
        server_resp_obj: null,

        // File props
        file: '',
        file_url: null,
        doc_type: null,
        
    }),
    methods: {
        closeViewMemberChangeHistoryDialog() {
            eventBus.$emit('CLOSE_VIEW_MEMBER_CHANGE_HISTORY_DIALOG', this.server_resp_obj);
        },
    },
    mounted() {
        
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
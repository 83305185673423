<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="40%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Mark Loan as Paid-Out</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeDialog(true, null)">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row v-if="loan !== null">
                        <v-col cols="12" sm="12">
                            <h3>You are about to mark loan (with loan number L{{loan.loan_number}}) as paid out, and thus changing its status to ACTIVE. Do you want to proceed?</h3>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Marking loan as paid out
                        <v-progress-linear
                            indeterminate
                            color="success"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>

                <v-divider></v-divider>

                <!-- Button Section -->
                <v-card-actions>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="red white--text"
                                v-on="on"
                                @click="closeDialog(true)"
                            >
                                <v-icon large>close</v-icon>
                                <span>Cancel</span>
                            </v-btn>
                        </template>
                        <span>Cancel</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                @click="submitDeleteAction()"
                            >
                                <v-icon large>done</v-icon>
                                <span>Proceed</span>
                            </v-btn>
                        </template>
                        <span>Proceed to delete loan</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { eventBus } from '../../../../../main';
import { emitAlert, emitSuccess } from '../../../../../utils/api';
import { businessProcessLoan } from '../../../gql/mutations';
import { LOAN_STATUS_ACTIONS } from '../../../../../../config';
export default {
    name: 'LoanPayoutDialog',
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        loan: {
            type: Object,
            default: null
        }
    },
    data: () => ({
        submitting: false
    }),
    methods: {
        closeDialog(cancelled) {
            eventBus.$emit('CLOSE_LOAN_PAYOUT_DIALOG', cancelled);
        },

        async submitDeleteAction() {
            this.submitting = true;

            const payloadObj = {
                OP_TYPE: 'STATUS',
                _id: this.loan._id,

                action_code: LOAN_STATUS_ACTIONS.PENDING_PAYOUT_TO_ACTIVE
            };

            try {
                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcessLoan,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                this.submitting = false;
                if (res.data.businessProcessLoan.errors === true) {
                    emitAlert(res.data.businessProcessLoan.message);
                    return;
                }

                emitSuccess(res.data.businessProcessLoan.message);
                
                // Show Results Dialog
                // this.closeAddLoanDialog();
                this.closeDialog(false, res.data.businessProcessLoan.business_object);
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessLoan);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },
    },
    mounted() {
        
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
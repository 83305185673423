import gql from 'graphql-tag'

export const createFinanceTransaction = gql`
    mutation finProcessFinanceAccountTransaction($input: FinanceAccountTransactionInput) {
        finProcessFinanceAccountTransaction(input: $input) {
            errors
            message
            business_object {
                ... on FinanceAccountTransaction {
                    __typename
                    _id
                    transaction_date

                    amount
                    from {
                        _id
                        name
                        balance
                        last_updated
                    }
                    to {
                        _id
                        name
                        balance
                        last_updated
                    }
                    reference
                    additional_reference

                    balance_from
                    balance_to

                    created
                    created_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }
                }
            }
        }
    }
`

export const entityProcessScheme = gql`
    mutation entityProcessScheme($input: SchemeInput!) {
        entityProcessScheme(input: $input) {
            errors
            message
            business_object {
                ... on Scheme {
                    __typename
                    _id

                    scheme_number
                    name
                    scheme_type
                    captured_by {
                        __typename
                        _id
                        entity {
                            __typename
                            _id
                            name
                            surname
                        }
                    }
                    created

                    addresses {
                        __typename
                        _id
                        type
                        line1
                        line2
                        line3
                        line4
                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }
                    contact_details {
                        __typename
                        _id
                        type
                        primary
                        details

                        captured_by {
                            __typename
                            _id
                            entity {
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }
                    documents {
                        __typename
                        _id
                        type
                        mime_type
                        name

                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }
                    bank_account_details {
                        __typename
                        _id
                        purpose
                        bank {
                            __typename
                            _id
                            name
                            universal_branch_code
                        }
                        account_type
                        account_number

                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }
                    loan_premium_allocation_priority {
                        principal
                        interest
                        monthly_admin_fees
                        initiation_fees
                    }
                }
            }
        }
    }
`
export const businessProcessPolicy = gql`
    mutation businessProcessPolicy($input: PolicyInput!) {
        businessProcessPolicy(input: $input) {
            errors
            message
            business_object {
                ... on Policy {
                    __typename
                    _id
                    business_partner {
                        _id
                        bp_number
                        name
                    }
                    group
                    policy_number
                    underwriter_policy_number
                    effective_date
                    end_date
                    high_risk

                    principal_member {
                        _id
                        name
                        surname
                        id_number
                    }
                    premium_payer {
                        _id
                        name
                        surname
                        id_number
                    }
                    elected_dependent {
                        _id
                        name
                        surname
                        id_number
                    }
                    
                    agent {
                        _id
                        agent_number
                        entity {
                            _id
                            name
                            surname
                            id_number
                        }
                    }
                    product {
                        _id
                        name
                        markup_fixed
                        markup_percentage
                        primary_product {
                            _id
                            name
                            underwriter_premium
                            broker_commission
                            broker_markup
                            
                            payout_structure {
                                _id
                                payout_amount
                                category {
                                    _id
                                    name
                                    membership_category
                                    start_age
                                    end_age
                                }
                            }
                        }
                    }
                    scheme {
                        _id
                        scheme_number
                        name
                    }
                    premium
                    total_cover_amount
                    elected_payment_date
                    # scheme_level_payment
                    status
                    # agent_status
                    underwriter_status
                    decline_reason
                    close_reason
                    created
                    captured_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                            id_number
                        }
                    }
                    payment_information {
                        premium_payer {
                            payment_method
                            percentage_amount
                        }
                        scheme {
                            payment_method
                            percentage_amount
                        }
                    }
                    members {
                        _id
                        entity {
                            _id
                            name
                            surname
                            id_number
                        }
                        role
                        covered_by_underwriter
                        created
                        start_date
                        end_date
                        termination_reason

                        cover_amount
                        gender

                        captured_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                                id_number
                            }
                        }
                        activated_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                                id_number
                            }
                        }
                        updated_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                                id_number
                            }
                        }

                        # Derived
                        age_inception
                        age_current
                    }
                    documents {
                        _id
                        type
                        mime_type
                        name
                        created
                        deleted
                        delete_date
                        delete_reason
                        captured_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                                id_number
                            }
                        }
                        deleted_by {
                            _id
                            entity {
                                _id
                                name
                                surname
                                id_number
                            }
                        }
                    }
                }
            }
        }
    }
`
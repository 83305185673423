import gql from 'graphql-tag'

export const getProducts = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on InsuranceProductPrimary {
                    __typename
                    _id
                    name
                    underwriter {
                        _id
                        name
                    }
                    underwriter_premium
                    broker_commission
                    broker_markup
                    active

                    payout_structure {
                        _id
                        payout_amount
                        category {
                            _id
                            name
                            membership_category
                            start_age
                            end_age
                        }
                    }
                    rules {
                        _id
                        rule_parameter {
                            _id
                            id
                            level
                            name
                            description
                            object_key
                        }
                        min
                        max
                    }
                    vas_services {
                        _id
                        limit_model
                        limit
                        created
                        vas_service {
                            _id

                            name
                            # category {
                            #     _id
                            #     name
                            # }
                            description
                            captured_by {
                                _id
                                entity {
                                    _id
                                    name
                                    surname
                                }
                            }
                            created
                        }
                    }
                }
            }
        }
    }
`

export const getUnderwriters = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on Underwriter {
                    __typename
                    _id
                    name
                    documents {
                        _id
                        type
                        mime_type
                        name

                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }
                    bank_account_details {
                        _id
                        purpose
                        bank {
                            __typename
                            _id
                            name
                            universal_branch_code
                        }
                        account_type
                        account_number

                        captured_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        created
                        deleted
                        delete_date
                        deleted_by {
                            __typename
                            _id
                            entity {
                                __typename
                                _id
                                name
                                surname
                            }
                        }
                        delete_reason
                    }
                    created
                    captured_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }
                }
            }
        }
    }
`

export const getPayoutAgeCategories = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on PayoutCategory {
                    __typename
                    _id
                    name
                    membership_category
                    start_age
                    end_age
                }
            }
        }
    }
`

export const getProductRuleParameters = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on ProductRuleParameter {
                    __typename
                    _id
                    id
                    level
                    name
                    description
                    object_key
                }
            }
        }
    }
`

export const getVASList = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on VASService {
                    __typename
                    _id
                    name
                    category {
                        _id
                        name
                    }
                    description
                    captured_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }
                    created

                    deleted
                    delete_date
                    deleted_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }
                    delete_reason
                }
            }
        }
    }
`
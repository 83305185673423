import gql from 'graphql-tag'

export const createReport = gql`
    mutation lookupProcessReport($input: ReportInput) {
        lookupProcessReport(input: $input) {
            errors
            message
            business_object {
                ... on Report {
                    _id
                    name
                    description
                    group
                    report_number
                }
            }
        }
    }
`
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="40%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Delete Prospect Policy</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeDialog(true)">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row v-if="policy !== null">
                        <v-col cols="12" sm="12">
                            <h3>Are you sure you want to delete prospect policy with prospect number {{policy.prospect_number}}?</h3>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting...
                        <v-progress-linear
                            indeterminate
                            color="success"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>

                <v-divider></v-divider>

                <!-- Button Section -->
                <v-card-actions>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="red white--text"
                                v-on="on"
                                @click="closeDialog(true)"
                            >
                                <v-icon large>close</v-icon>
                                <span>Cancel</span>
                            </v-btn>
                        </template>
                        <span>Cancel</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                @click="submitDeleteAction()"
                            >
                                <v-icon large>done</v-icon>
                                <span>Proceed</span>
                            </v-btn>
                        </template>
                        <span>Proceed to delete policy</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { elected_payment_dates } from '../../../../../../config';
import { eventBus } from '../../../../../main';

import { businessProcessProspectPolicy } from "../gql/mutations";

export default {
    name: 'SearchMembersDialog',
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        policy: {
            type: Object,
            default: null
        }
    },
    data: () => ({
        effective_date_menu: false,
        search_fields: {
            policy_number: {
                search: false,
                value: null,
            },
            status: {
                search: false,
                value: null,
            },
            member_id_number: {
                search: false,
                value: null,
            },
            effective_date: {
                search: false,
                value: null,
            },
            elected_payment_date: {
                search: false,
                value: null,
            },
        },

        elected_payment_dates: elected_payment_dates,
        submitting: false
    }),
    methods: {
        closeDialog(cancelled) {
            eventBus.$emit('CLOSE_DELETE_POLICY_DRAFTED_POLICY_DIALOG', cancelled);
        },
        async submitDeleteAction() {
            this.submitting = true;

            const payloadObj = {
                        OP_TYPE: 'DELETE',
                        _id: this.policy._id,
                    };

            await this.$apollo
                        .mutate({
                            mutation: businessProcessProspectPolicy,
                            variables: {
                                input: payloadObj
                            },
                        });
            this.submitting = false;
            this.closeDialog(false);
        },
    },
    mounted() {
        
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="95%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>System Approval Results</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card>
                <v-card-text>
                    <v-row
                        v-if="loan.system_approval_results.approval_method !== null"
                        style="background:#ffffff;"
                    >
                        <!-- <v-col cols="12" sm="12" style="padding-top: 0px;">
                            <h2>System Approval Results</h2>
                        </v-col> -->

                        <v-col cols="12" sm="2">
                            <v-text-field
                                :value="loan.system_approval_results.approval_method"
                                label="System Approval Method"
                                type="text"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="1">
                            <v-icon v-if="loan.system_approval_results.approval_method === 'AUTO-APPROVE'" color="green" x-large>mdi-check-circle</v-icon>
                            <v-icon v-if="loan.system_approval_results.approval_method === 'AUTO-DECLINE'" color="red" x-large>mdi-close-circle</v-icon>
                            <v-icon v-if="loan.system_approval_results.approval_method === 'MANUAL'" color="amber" x-large>mdi-minus-circle</v-icon>
                        </v-col>

                        <v-col cols="12" sm="3">
                            <v-text-field
                                :value="loan.system_approval_results.elected_collection_date"
                                label="System Suggested Collection Date"
                                type="text"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2" v-if="loan.premium > 0" style="background:rgb(202, 202, 202)">
                            <v-text-field
                                :value="loan.premium"
                                label="Premium"
                                prefix="ZAR"
                                type="text"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>

                        <!-- GOOD AGENT SCORE -->
                        <v-col
                            v-if="loan.agent_score >= 4"
                            cols="12" sm="2"
                            style="background:rgb(99, 180, 99)"
                        >
                            <v-text-field
                                :value="loan.agent_score"
                                label="Agent Score"
                                type="text"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <!-- BAD AGENT SCORE -->
                        <v-col
                            v-if="(
                                loan.agent_score >= 0 &&
                                loan.agent_score < 4
                            )"
                            cols="12" sm="2"
                            style="background:rgb(242, 148, 148)"
                        >
                            <v-text-field
                                :value="loan.agent_score"
                                label="Agent Score"
                                type="text"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                :value="loan.system_approval_results.reason"
                                label="System Reason"
                                type="text"
                                disabled
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row
                        v-if="loan.system_approval_results.approval_method !== null"
                        style="background:#ffffff;"
                    >
                        <v-col cols="12" sm="12">
                            <h3>Bank Statement Analysis</h3>
                        </v-col>

                        <!-- Day1 After -->
                        <v-col cols="12" sm="3" style="border: solid 3px grey; border-radius:10px">
                            <v-row>
                                <v-col cols="12" sm="12" >
                                    <h4>Month 1 <span style="color:red">( {{ loan.bank_statement_details.salary_1.date_on_statement.split('T')[0] }} )</span></h4>
                                </v-col>
                                <v-col cols="12" sm="10">
                                    <v-text-field
                                        style="padding-top: 0px; padding-bottom: 0px"
                                        :value="loan.system_approval_results.day1_after.month1_qualifies + '  (R' + loan.bank_statement_details.salary_1.balance_1_day_after + ')'"
                                        label="Day 1 Balance After Salary Qualifies?"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-icon v-if="loan.system_approval_results.day1_after.month1_qualifies === 'YES'" color="green" x-large>mdi-check-circle</v-icon>
                                    <v-icon v-if="loan.system_approval_results.day1_after.month1_qualifies === 'NO'" color="red" x-large>mdi-close-circle</v-icon>
                                </v-col>

                                <v-col cols="12" sm="10">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        :value="loan.system_approval_results.day2_after.month1_qualifies + '  (R' + loan.bank_statement_details.salary_1.balance_2_days_after + ')'"
                                        label="Day 2 Balance After Salary Qualifies?"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-icon v-if="loan.system_approval_results.day2_after.month1_qualifies === 'YES'" color="green" x-large>mdi-check-circle</v-icon>
                                    <v-icon v-if="loan.system_approval_results.day2_after.month1_qualifies === 'NO'" color="red" x-large>mdi-close-circle</v-icon>
                                </v-col>

                                <v-col cols="12" sm="10">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        :value="loan.system_approval_results.day3_after.month1_qualifies + '  (R' + loan.bank_statement_details.salary_1.balance_3_days_after + ')'"
                                        label="Day 3 Balance After Salary Qualifies?"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-icon v-if="loan.system_approval_results.day3_after.month1_qualifies === 'YES'" color="green" x-large>mdi-check-circle</v-icon>
                                    <v-icon v-if="loan.system_approval_results.day3_after.month1_qualifies === 'NO'" color="red" x-large>mdi-close-circle</v-icon>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" sm="1"></v-col>

                        <!-- Day2 After -->
                        <v-col cols="12" sm="3" style="border: solid 3px grey; border-radius:10px">
                            <v-row>
                                <v-col cols="12" sm="12" >
                                    <h4>Month 2 <span style="color:red">( {{ loan.bank_statement_details.salary_2.date_on_statement.split('T')[0] }} )</span></h4>
                                </v-col>
                                <v-col cols="12" sm="10">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        :value="loan.system_approval_results.day1_after.month2_qualifies + '  (R' + loan.bank_statement_details.salary_2.balance_1_day_after + ')'"
                                        label="Day 1 Balance After Salary Qualifies?"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-icon v-if="loan.system_approval_results.day1_after.month2_qualifies === 'YES'" color="green" x-large>mdi-check-circle</v-icon>
                                    <v-icon v-if="loan.system_approval_results.day1_after.month2_qualifies === 'NO'" color="red" x-large>mdi-close-circle</v-icon>
                                </v-col>

                                <v-col cols="12" sm="10">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        :value="loan.system_approval_results.day2_after.month2_qualifies + '  (R' + loan.bank_statement_details.salary_2.balance_2_days_after + ')'"
                                        label="Day 2 Balance After Salary Qualifies?"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-icon v-if="loan.system_approval_results.day2_after.month2_qualifies === 'YES'" color="green" x-large>mdi-check-circle</v-icon>
                                    <v-icon v-if="loan.system_approval_results.day2_after.month2_qualifies === 'NO'" color="red" x-large>mdi-close-circle</v-icon>
                                </v-col>

                                <v-col cols="12" sm="10">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        :value="loan.system_approval_results.day3_after.month2_qualifies + '  (R' + loan.bank_statement_details.salary_2.balance_3_days_after + ')'"
                                        label="Day 3 Balance After Salary Qualifies?"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-icon v-if="loan.system_approval_results.day3_after.month2_qualifies === 'YES'" color="green" x-large>mdi-check-circle</v-icon>
                                    <v-icon v-if="loan.system_approval_results.day3_after.month2_qualifies === 'NO'" color="red" x-large>mdi-close-circle</v-icon>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" sm="1"></v-col>

                        <!-- Day3 After -->
                        <v-col cols="12" sm="3" style="border: solid 3px grey; border-radius:10px">
                            <v-row>
                                <v-col cols="12" sm="12" >
                                    <h4>Month 3 <span style="color:red">( {{ loan.bank_statement_details.salary_3.date_on_statement.split('T')[0] }} )</span></h4>
                                </v-col>
                                <v-col cols="12" sm="10">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        :value="loan.system_approval_results.day1_after.month3_qualifies + '  (R' + loan.bank_statement_details.salary_3.balance_1_day_after + ')'"
                                        label="Day 1 Balance After Salary Qualifies?"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-icon v-if="loan.system_approval_results.day1_after.month3_qualifies === 'YES'" color="green" x-large>mdi-check-circle</v-icon>
                                    <v-icon v-if="loan.system_approval_results.day1_after.month3_qualifies === 'NO'" color="red" x-large>mdi-close-circle</v-icon>
                                </v-col>

                                <v-col cols="12" sm="10">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        :value="loan.system_approval_results.day2_after.month3_qualifies + '  (R' + loan.bank_statement_details.salary_3.balance_2_days_after + ')'"
                                        label="Day 2 Balance After Salary Qualifies?"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-icon v-if="loan.system_approval_results.day2_after.month3_qualifies === 'YES'" color="green" x-large>mdi-check-circle</v-icon>
                                    <v-icon v-if="loan.system_approval_results.day2_after.month3_qualifies === 'NO'" color="red" x-large>mdi-close-circle</v-icon>
                                </v-col>

                                <v-col cols="12" sm="10">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        :value="loan.system_approval_results.day3_after.month3_qualifies + '  (R' + loan.bank_statement_details.salary_3.balance_3_days_after + ')'"
                                        label="Day 3 Balance After Salary Qualifies?"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-icon v-if="loan.system_approval_results.day3_after.month3_qualifies === 'YES'" color="green" x-large>mdi-check-circle</v-icon>
                                    <v-icon v-if="loan.system_approval_results.day3_after.month3_qualifies === 'NO'" color="red" x-large>mdi-close-circle</v-icon>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { eventBus } from "../../../../main";
// import { getLoanQuotePremium } from "../gql/queries";
export default {
    components: {},
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        loan: {
            type: Object,
            default: null
        },
    },
    data: () => ({

    }),
    computed: {},
    methods: {
        closeDialog() {
            eventBus.$emit('CLOSE_VIEW_SYSTEM_VALIDATION_RESULTS_DIALOG');
        },
    },
    async mounted() {},
    watch: {
        // dialog: async function(val) {
        //     if (val === true) {
        //         // Get premium
        //         const premiumRes = await this.$apollo
        //                                     .query({
        //                                         query: getLoanQuotePremium,
        //                                         // fetchPolicy: 'network-only',
        //                                         fetchPolicy: 'no-cache',
        //                                         errorPolicy: 'all',
        //                                         variables: {
        //                                             loan_quote_id: this.loan._id
        //                                         },
        //                                     });

        //         const premium = premiumRes.data.getLoanQuotePremium;
        //         this.quoted_premium = premium;
        //     }

        //     if (val === false) {
        //         this.quoted_premium = 0;
        //     }
        // }
    }
}
</script>
<style scoped>

</style>
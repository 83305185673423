<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Create Bank Statement Classification</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeAddDialog()" v-if="server_response_obj === null">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-stepper v-model="e1">
                <v-stepper-header>
                    <v-stepper-step :complete="e1 > 1" step="1" color="success">Basic Details</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 2" step="2" color="success">Keywords</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="3" color="success">Processing</v-stepper-step>
                </v-stepper-header>
            
                <v-stepper-items>
                    <!-- Basic Details -->
                    <v-stepper-content step="1">
                        <v-card>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                        v-model="classification_details.name"
                                        label="Name"
                                        color="secondary"
                                        hint="e.g. Trade Receivable"
                                        persistent-hint
                                        required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-select
                                        v-model="classification_details.category"
                                        :items="[
                                            'EQUITY',
                                            'ASSET',
                                            'LIABILITY',
                                            'INCOME',
                                            'EXPENSE',
                                            ]"
                                            color="secondary"
                                            label="Accounting Category"
                                            required
                                            ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-progress-linear
                                            color="green"
                                            class="mb-0"
                                            height="10"
                                            indeterminate
                                            :active="backend_check"
                                        ></v-progress-linear>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    fab
                                    color="amber"
                                    :disabled="goToStep2BtnDisabled()"
                                    @click="goToStep2()"
                                >
                                    <v-icon large>keyboard_arrow_right</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Keywords Section -->
                    <v-stepper-content step="2">
                        <v-card>
                            <v-card-text>
                                <v-row
                                    v-for="keyword in keywords"
                                    :key="keyword.id"
                                    style="background:#eaeaea; padding-bottom:10px"
                                >
                                    <v-col cols="12" sm="12">
                                        <v-text-field
                                            v-model="keyword.keyword"
                                            label="Keyword"
                                            color="secondary"
                                            hint="e.g. ADT Cash Deposi 94859DS9"
                                            persistent-hint
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <div style="background:white; height: 10px; width: 100%"></div>
                                </v-row>
                                <!-- Buttons for Adding/Removing Keywords -->
                                <v-row>
                                    <v-col sm="10"></v-col>
                                    <v-col sm="2">
                                        <v-tooltip bottom v-if="keywords.length > 1">
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    class="mx-2"
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="removeKeyword()"
                                                >
                                                    <v-icon>remove</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Remove Keyword</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="addKeyword()"
                                                >
                                                    <v-icon>add</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Add Another Keyword</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="e1 = 1"
                                >
                                    <v-icon large>keyboard_arrow_left</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    large
                                    color="success"
                                    :disabled="saveClassificationBtnDisabled()"
                                    @click="saveClassification()"
                                >
                                    <v-icon large>save</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Submission Results Section -->
                    <v-stepper-content step="3">
                        <v-card>
                            <v-card-text class="text-center" v-if="submitting">
                                Submitting Classification Details
                                <v-progress-linear
                                    indeterminate
                                    color="green"
                                    class="mb-0"
                                    height="10"
                                    :active="submitting"
                                ></v-progress-linear>

                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="success"
                                    @click="closeAddDialog()"
                                >
                                    <v-icon large>done</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>
    </v-row>
</template>
<script>
import { eventBus } from ".././../../../main";
import { emitAlert, emitSuccess } from "../../../../utils/api";

import { getClassifications } from "../gql/queries";
import { businessProcessManagementAccounts } from "../gql/mutations";

export default {
    components: {},
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        e1: 1,
        classification_details: {
            name: null,
            category: null,
            key_words_filters: []
        },
        
        keywords: [
            {
                id: 1,
                keyword: null,
            }
        ],
        
        server_response_obj: null,
        submitting: false,
        backend_check: false,
    }),
    methods: {
        closeAddDialog() {
            eventBus.$emit('CLOSE_ADD_CLASSIFICATION_DIALOG', this.server_response_obj);
        },
        resetForm() {
            this.e1 = 1;
            this.classification_details = {
                name: null,
                category: null,
                key_words_filters: []
            };
            
            this.keywords = [
                {
                    id: 1,
                    keyword: null,
                }
            ];
            
            this.server_response_obj = null;
            this.submitting = false;
            this.backend_check = false;
        },

        //--------------------ADD/REMOVE KEYWORD--------------------------//
        addKeyword() {
            const keywords_size = this.keywords.length;

            this.keywords.push({
                id: keywords_size + 1,
                keyword: null
            });
        },
        removeKeyword() {
            this.keywords.pop();
        },
        
        goToStep2BtnDisabled() {
            if (this.classification_details.name === null || this.classification_details.name === undefined) {
                return true;
            }
            if (typeof(this.classification_details.name) === 'string') {
                if (this.classification_details.name.trim() === '') {
                    return true;
                }
            }

            if (this.classification_details.category === null) {
                return true;
            }
            return false;
        },
        async goToStep2() {
            // Check if classification exists
            try {
                this.backend_check = true;
                // const res = await getUserByEntity(this.user_details.member._id);
                const res = await this.$apollo
                                    .query({
                                        query: getClassifications,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'BANK_STATEMENT_CLASSIFICATION',
                                            pagination: {
                                                page: 1,
                                                limit: 100
                                            },
                                            searchInput: {
                                                // name: /^`${this.classification_details.name.trim().toLowerCase()}`/i
                                                // name: new RegExp(`/^${this.classification_details.name.trim().toLowerCase()}/i`)
                                                name: this.classification_details.name.trim().toLowerCase()
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.backend_check = false;
                    return;
                }
                
                this.backend_check = false;
                const classifications = res.data.getBusinessObject.business_objects;
                if (classifications.length === 0) {
                    this.e1 = 2;
                    return;
                }

                emitAlert(`A classification with the name '${this.classification_details.name.trim()}' already exists`);
            } catch (error) {
                this.backend_check = false;
                console.log(error);
                if (error !== undefined) {
                    // emitAlert(error.data.getBusinessObject.message);
                    emitAlert(error.data);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },

        saveClassificationBtnDisabled() {
            // const invalidKeywords = this.keywords.filter(item => {
            //     let invalid = false;

            //     if (typeof(item.keyword) === 'string') {
            //         if (item.keyword.trim() === '') {
            //             invalid = true;
            //         }
            //     }

            //     if (
            //         item.keyword === null ||
            //         item.keyword === undefined
            //     ) {
            //         invalid = true;
            //     }

            //     return invalid;
            // });

            // if (invalidKeywords.length > 0) {
            //     return true;
            // }
            return false;
        },

        async saveClassification() {
            // Then submit the classification
            this.submitting = true;
            this.e1 = 3;
            
            for (const keywordItem of this.keywords) {
                if (
                    keywordItem.keyword !== null &&
                    keywordItem.keyword !== undefined
                ) {
                    if (typeof(keywordItem.keyword) === 'string') {
                        if (keywordItem.keyword.trim() === '') {
                            this.classification_details.key_words_filters.push({
                                match_key_word: keywordItem.keyword
                            });
                        }
                    }
                }
            }

            // Submit classification to server
            try {
                const payload = {
                    OP_TYPE: 'CREATE_STATEMENT_CLASSIFICATION',
                    ...this.classification_details
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcessManagementAccounts,
                                                variables: {
                                                    input: payload
                                                },
                                            });
                this.submitting = false;

                if (res.data.businessProcessManagementAccounts.errors === true) {
                    emitAlert(res.data.businessProcessManagementAccounts.message);
                    return;
                }

                emitSuccess(res.data.businessProcessManagementAccounts.message);

                this.server_response_obj = res.data.businessProcessManagementAccounts.business_objects[0];
                this.closeAddDialog();

                // Reset
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessManagementAccounts.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
    },
    async mounted() {},
}
</script>
<style scoped>

</style>
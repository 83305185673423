var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"80%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-toolbar',{staticStyle:{"border":"1px solid #07ad2d"},attrs:{"dark":"","color":"secondary"}},[_c('v-toolbar-title',[_c('b',[_vm._v("Add Premium Payment")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.resetForm()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Reset Form")])]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.closeAddSchemeTransactionDialog()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card',[_c('v-card-text',[_c('v-row',{staticStyle:{"background":"#eaeaea","padding-bottom":"10px"}},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"color":"secondary","label":"Transaction Date","hint":"The date on which the transaction occurred","persistent-hint":"","readonly":""},model:{value:(_vm.premium_details.transaction_date),callback:function ($$v) {_vm.$set(_vm.premium_details, "transaction_date", $$v)},expression:"premium_details.transaction_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.transaction_date_menu),callback:function ($$v) {_vm.transaction_date_menu=$$v},expression:"transaction_date_menu"}},[_c('v-date-picker',{attrs:{"color":"secondary"},on:{"input":function($event){_vm.transaction_date_menu = false}},model:{value:(_vm.premium_details.transaction_date),callback:function ($$v) {_vm.$set(_vm.premium_details, "transaction_date", $$v)},expression:"premium_details.transaction_date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{attrs:{"color":"secondary","label":"Customer Reference","hint":"For EFT or CASH or ATM clients","persistent-hint":"","required":""},model:{value:(_vm.premium_details.additional_reference),callback:function ($$v) {_vm.$set(_vm.premium_details, "additional_reference", $$v)},expression:"premium_details.additional_reference"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"items":_vm.system_finance_accounts,"color":"secondary","item-text":"name","item-value":"_id","label":"Payment Method","disabled":_vm.refreshing_system_finance_accounts_list,"hint":"You can click the refresh icon next to the field if the system accounts list is incomplete","persistent-hint":"","required":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"success"},on:{"click":function($event){return _vm.refreshSystemAccountsLookupList()}}},on),[_vm._v(" mdi-refresh ")])]}}])},[_c('span',[_vm._v("Refresh System Accounts List")])])]},proxy:true}]),model:{value:(_vm.premium_details.payment_method),callback:function ($$v) {_vm.$set(_vm.premium_details, "payment_method", $$v)},expression:"premium_details.payment_method"}}),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"color":"green","height":"4","indeterminate":"","active":_vm.refreshing_system_finance_accounts_list}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-text-field',{attrs:{"color":"secondary","label":"Amount","type":"number","prefix":"R","required":""},model:{value:(_vm.premium_details.amount),callback:function ($$v) {_vm.$set(_vm.premium_details, "amount", $$v)},expression:"premium_details.amount"}})],1)],1)],1),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"color":"green","height":"10","indeterminate":"","active":_vm.submitting}}),(_vm.submitting)?_c('v-list',{attrs:{"flat":""}},[_c('v-subheader',[_vm._v("Saving Scheme Transaction")]),_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.backendActionItems),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("done")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)],1)}),1)],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"large":"","color":"success","disabled":_vm.submitSchemeDocumentBtnDisabled()},on:{"click":function($event){return _vm.saveSchemeTransaction()}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("save")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Add Role</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeOnboardingDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-stepper v-model="e1">
                <v-stepper-header>
                    <v-stepper-step :complete="e1 > 1" step="1" color="success">Basic Details</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 2" step="2" color="success">Allowed Screens</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 3" step="3" color="success">Allowed Reports</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 4" step="4" color="success">Allowed Dashboards</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 5" step="5" color="success">Allowed Business Processes</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="6" color="success">Processing</v-stepper-step>
                </v-stepper-header>
            
                <v-stepper-items>
                    <!-- Basic Details -->
                    <v-stepper-content step="1">
                        <v-card>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            v-model="role_details.name"
                                            :rules="name_rules"
                                            ref="name_field"
                                            color="secondary"
                                            label="Role Name"
                                            autocomplete="some-random-text"
                                            type="text"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="8">
                                        <v-select
                                            v-model="role_details.level"
                                            :items="role_levels"
                                            color="secondary"
                                            label="Role Level"
                                            chips
                                            multiple
                                            required
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="12">
                                        <v-text-field
                                            v-model="role_details.description"
                                            :rules="description_rules"
                                            ref="description_field"
                                            color="secondary"
                                            label="Description"
                                            autocomplete="some-random-text"
                                            type="text"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    fab
                                    color="amber"
                                    :disabled="goToStep2BtnDisabled()"
                                    @click="goToStep2()"
                                >
                                    <v-icon large>keyboard_arrow_right</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Allowed Screens Section -->
                    <v-stepper-content step="2">
                        <v-card>
                            <v-card-text>
                                <v-row
                                    v-for="allowed_screen in allowed_screens"
                                    :key="allowed_screen.id"
                                    style="background:#eaeaea; padding-bottom:10px"
                                >
                                    <v-col cols="12" sm="12">
                                        <v-select
                                            v-model="allowed_screen.screen"
                                            :items="user_screens"
                                            color="secondary"
                                            item-text="display_value"
                                            item-value="_id"
                                            label="Screen"
                                            :disabled="refreshing_screens_list"

                                            hint="You can click the refresh icon next to the field if the screens list is incomplete"
                                            persistent-hint

                                            required
                                        >
                                            <!-- Format prepend inner icon -->
                                            <template v-slot:prepend-inner>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon
                                                            v-on="on"
                                                            color="success"
                                                            @click="refreshScreensList()"
                                                        >
                                                            mdi-refresh
                                                        </v-icon>
                                                    </template>
                                                    <span>Refresh Screens List</span>
                                                </v-tooltip>
                                            </template>
                                        </v-select>
                                        <v-progress-linear
                                            color="green"
                                            class="mb-0"
                                            height="4"
                                            indeterminate
                                            :active="refreshing_screens_list"
                                        ></v-progress-linear>
                                    </v-col>
                                    <div style="background:white; height: 10px; width: 100%"></div>
                                </v-row>
                                <!-- Buttons for Adding/Removing allowed_screens -->
                                <v-row>
                                    <v-col sm="10"></v-col>
                                    <v-col sm="2">
                                        <v-tooltip bottom v-if="allowed_screens.length > 1">
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    class="mx-2"
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="removeAllowedScreen()"
                                                >
                                                    <v-icon>remove</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Remove Screen</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="addAllowedScreen()"
                                                >
                                                    <v-icon>add</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Add Another Screen</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="e1 = 1"
                                >
                                    <v-icon large>keyboard_arrow_left</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    fab
                                    color="amber"
                                    :disabled="goToStep3BtnDisabled()"
                                    @click="goToStep3()"
                                >
                                    <v-icon large>keyboard_arrow_right</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Allowed Reports Section -->
                    <v-stepper-content step="3">
                        <v-card>
                            <v-card-text>
                                <v-row
                                    v-for="allowed_report in allowed_reports"
                                    :key="allowed_report.id"
                                    style="background:#eaeaea; padding-bottom:10px"
                                >
                                    <v-col cols="12" sm="12">
                                        <v-select
                                            v-model="allowed_report.report"
                                            :items="user_reports"
                                            color="secondary"
                                            item-text="display_value"
                                            item-value="_id"
                                            label="Report"
                                            :disabled="refreshing_reports_list"

                                            hint="You can click the refresh icon next to the field if the reports list is incomplete"
                                            persistent-hint

                                            required
                                        >
                                            <!-- Format prepend inner icon -->
                                            <template v-slot:prepend-inner>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon
                                                            v-on="on"
                                                            color="success"
                                                            @click="refreshReportsList()"
                                                        >
                                                            mdi-refresh
                                                        </v-icon>
                                                    </template>
                                                    <span>Refresh Reports List</span>
                                                </v-tooltip>
                                            </template>
                                        </v-select>
                                        <v-progress-linear
                                            color="green"
                                            class="mb-0"
                                            height="4"
                                            indeterminate
                                            :active="refreshing_reports_list"
                                        ></v-progress-linear>
                                    </v-col>
                                    <div style="background:white; height: 10px; width: 100%"></div>
                                </v-row>
                                <!-- Buttons for Adding/Removing allowed_reports -->
                                <v-row>
                                    <v-col sm="10"></v-col>
                                    <v-col sm="2">
                                        <v-tooltip bottom v-if="allowed_reports.length > 1">
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    class="mx-2"
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="removeAllowedReport()"
                                                >
                                                    <v-icon>remove</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Remove Report</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="addAllowedReport()"
                                                >
                                                    <v-icon>add</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Add Another Report</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="e1 = 2"
                                >
                                    <v-icon large>keyboard_arrow_left</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    fab
                                    color="amber"
                                    :disabled="goToStep4BtnDisabled()"
                                    @click="goToStep4()"
                                >
                                    <v-icon large>keyboard_arrow_right</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Allowed Dashboards Section -->
                    <v-stepper-content step="4">
                        <v-card>
                            <v-card-text>
                                <v-row
                                    v-for="allowed_dashboard in allowed_dashboards"
                                    :key="allowed_dashboard.id"
                                    style="background:#eaeaea; padding-bottom:10px"
                                >
                                    <v-col cols="12" sm="12">
                                        <v-select
                                            v-model="allowed_dashboard.dashboard"
                                            :items="user_dashboards"
                                            color="secondary"
                                            item-text="display_value"
                                            item-value="_id"
                                            label="Dashboard"
                                            :disabled="refreshing_dashboards_list"

                                            hint="You can click the refresh icon next to the field if the dashboards list is incomplete"
                                            persistent-hint

                                            required
                                        >
                                            <!-- Format prepend inner icon -->
                                            <template v-slot:prepend-inner>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon
                                                            v-on="on"
                                                            color="success"
                                                            @click="refreshDashboardsList()"
                                                        >
                                                            mdi-refresh
                                                        </v-icon>
                                                    </template>
                                                    <span>Refresh Dashboards List</span>
                                                </v-tooltip>
                                            </template>
                                        </v-select>
                                        <v-progress-linear
                                            color="green"
                                            class="mb-0"
                                            height="4"
                                            indeterminate
                                            :active="refreshing_dashboards_list"
                                        ></v-progress-linear>
                                    </v-col>
                                    <div style="background:white; height: 10px; width: 100%"></div>
                                </v-row>
                                <!-- Buttons for Adding/Removing allowed_dashboards -->
                                <v-row>
                                    <v-col sm="10"></v-col>
                                    <v-col sm="2">
                                        <v-tooltip bottom v-if="allowed_dashboards.length > 1">
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    class="mx-2"
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="removeAllowedDashboard()"
                                                >
                                                    <v-icon>remove</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Remove Dashboard</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="addAllowedDashboard()"
                                                >
                                                    <v-icon>add</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Add Another Dashboard</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="e1 = 3"
                                >
                                    <v-icon large>keyboard_arrow_left</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    fab
                                    color="amber"
                                    :disabled="goToStep5BtnDisabled()"
                                    @click="goToStep5()"
                                >
                                    <v-icon large>keyboard_arrow_right</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Allowed Business Processes Section -->
                    <v-stepper-content step="5">
                        <v-card>
                            <v-card-text>
                                <v-row
                                    v-for="allowed_business_processe in allowed_business_processes"
                                    :key="allowed_business_processe.id"
                                    style="background:#eaeaea; padding-bottom:10px"
                                >
                                    <v-col cols="12" sm="12">
                                        <v-select
                                            v-model="allowed_business_processe.process"
                                            :items="business_processes"
                                            color="secondary"
                                            item-text="display_value"
                                            item-value="_id"
                                            label="Business Process"
                                            :disabled="refreshing_business_processes_list"

                                            hint="You can click the refresh icon next to the field if the business-processe list is incomplete"
                                            persistent-hint

                                            required
                                        >
                                            <!-- Format prepend inner icon -->
                                            <template v-slot:prepend-inner>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon
                                                            v-on="on"
                                                            color="success"
                                                            @click="refreshBusinessProcessesList()"
                                                        >
                                                            mdi-refresh
                                                        </v-icon>
                                                    </template>
                                                    <span>Refresh Business Processes List</span>
                                                </v-tooltip>
                                            </template>
                                        </v-select>
                                        <v-progress-linear
                                            color="green"
                                            class="mb-0"
                                            height="4"
                                            indeterminate
                                            :active="refreshing_business_processes_list"
                                        ></v-progress-linear>
                                    </v-col>
                                    <div style="background:white; height: 10px; width: 100%"></div>
                                </v-row>
                                <!-- Buttons for Adding/Removing allowed_business_processes -->
                                <v-row>
                                    <v-col sm="10"></v-col>
                                    <v-col sm="2">
                                        <v-tooltip bottom v-if="allowed_business_processes.length > 1">
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    class="mx-2"
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="removeAllowedBusinessProcess()"
                                                >
                                                    <v-icon>remove</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Remove Business Process</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="addAllowedBusinessProcess()"
                                                >
                                                    <v-icon>add</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Add Another Business Process</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="e1 = 4"
                                >
                                    <v-icon large>keyboard_arrow_left</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    large
                                    color="success"
                                    :disabled="saveRoleBtnDisabled()"
                                    @click="submitRole()"
                                >
                                    <v-icon large>save</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Submission Results Section -->
                    <v-stepper-content step="6">
                        <v-card light >
                            <v-card-text class="text-center">
                                <v-progress-linear
                                    color="green"
                                    class="mb-0"
                                    height="10"
                                    indeterminate
                                    :active="submitting"
                                ></v-progress-linear>
                            </v-card-text>
                            <v-list flat>
                                <v-subheader>Saving Role Info</v-subheader>
                                <!-- <v-list-item-group v-model="item" color="primary"> -->
                                <v-list-item-group color="primary">
                                    <v-list-item
                                        v-for="(item, i) in backendActionItems"
                                        :key="i"
                                    >
                                        <v-list-item-icon>
                                            <v-icon>done</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ item }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";

import { getUserScreens, getReports, getDashboards, getBusinessProcesses } from "../gql/queries";
import { lookupProcessUserRole } from "../gql/mutations";

export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        error_message: null,
        submitting: false,
        backendActionItems: [],
        server_response_obj: null,

        role_levels: [
            'CENTRAL_UNDERWRITER',
            'BUSINESS_INTRODUCER',
            'SALES_AGENCY',
            'VAS_PROVIDER',
            'SYSTEM'
        ],

        refreshing_screens_list: false,
        refreshing_reports_list: false,
        refreshing_dashboards_list: false,
        refreshing_business_processes_list: false,

        e1: 1,
        role_details: {
            name: '',
            level: [],
            description: ''
        },
        
        allowed_screens: [
            {
                id: 1,
                screen: null,
            }
        ],
        allowed_reports: [
            {
                id: 1,
                report: null,
            }
        ],
        allowed_dashboards: [
            {
                id: 1,
                dashboard: null,
            }
        ],
        allowed_business_processes: [
            {
                id: 1,
                process: null,
            }
        ],

        user_screens: [],
        user_reports: [],
        user_dashboards: [],
        business_processes: [],

        name_rules: [
            v => !!v || 'Role name is required',
            v => v.trim().length >= 3 || 'Min 3 characters'
        ],
        description_rules: [
            v => !!v || 'Role description is required',
            v => v.trim().length >= 5 || 'Min 5 characters'
        ],
    }),
    methods: {
        closeOnboardingDialog() {
            eventBus.$emit('CLOSE_ADD_ROLE_DIALOG', this.server_response_obj);
        },
        resetForm() {
            this.e1 = 1;
            this.submitting = false;
            this.server_response_obj = null;

            this.backendActionItems = [];
            this.role_details = {
                name: '',
                level: [],
                description: ''
            };

            this.allowed_screens = [
                {
                    id: 1,
                    screen: null,
                }
            ];

            this.$refs.name_field.resetValidation();
            this.$refs.description_field.resetValidation();
        },

        goToStep2BtnDisabled() {
            if (
                this.role_details.name.trim().length < 3 ||
                this.role_details.description.trim().length < 5 ||
                this.role_details.level.length === 0
            ) {
                return true;
            }

            return false;
        },
        goToStep2() {
            this.e1 = 2;
        },

        goToStep3BtnDisabled() {
            let validScreens = this.allowed_screens.filter(perm => { return perm.screen !== null });

            if (validScreens.length === 0) {
                return true;
            }

            return false;
        },
        goToStep3() {
            this.e1 = 3;
        },

        goToStep4BtnDisabled() {
            // NB: We don't enforce this anymore
            // let validReports = this.allowed_reports.filter(perm => { return perm.report !== null });

            // if (validReports.length === 0) {
            //     return true;
            // }

            return false;
        },
        goToStep4() {
            this.e1 = 4;
        },

        goToStep5BtnDisabled() {
            // NB: We don't enforce this anymore
            // let validReports = this.allowed_reports.filter(perm => { return perm.report !== null });

            // if (validReports.length === 0) {
            //     return true;
            // }

            return false;
        },
        goToStep5() {
            this.e1 = 5;
        },


        saveRoleBtnDisabled() {
            // NB: We don't enforce this anymore
            // let validDashboards = this.allowed_dashboards.filter(rep => { return rep.dashboard !== null });

            // if (validDashboards.length === 0) {
            //     return true;
            // }

            return false;
        },

        //--------------------ADD/REMOVE ALLOWED SCREENS--------------------------//
        addAllowedScreen() {
            const allowed_screens_size = this.allowed_screens.length;

            this.allowed_screens.push({
                id: allowed_screens_size + 1,
                screen: null,
            });
        },
        removeAllowedScreen() {
            this.allowed_screens.pop();
        },

        //--------------------ADD/REMOVE ALLOWED REPORTS--------------------------//
        addAllowedReport() {
            const allowed_reports_size = this.allowed_reports.length;

            this.allowed_reports.push({
                id: allowed_reports_size + 1,
                report: null,
            });
        },
        removeAllowedReport() {
            this.allowed_reports.pop();
        },

        //--------------------ADD/REMOVE ALLOWED DASHBOARDS--------------------------//
        addAllowedDashboard() {
            const allowed_dashboards_size = this.allowed_dashboards.length;

            this.allowed_dashboards.push({
                id: allowed_dashboards_size + 1,
                report: null,
            });
        },
        removeAllowedDashboard() {
            this.allowed_dashboards.pop();
        },

        //--------------------ADD/REMOVE ALLOWED BUSINESS PROCESSES--------------------------//
        addAllowedBusinessProcess() {
            const allowed_business_processes_size = this.allowed_business_processes.length;

            this.allowed_business_processes.push({
                id: allowed_business_processes_size + 1,
                report: null,
            });
        },
        removeAllowedBusinessProcess() {
            this.allowed_business_processes.pop();
        },
        
        async submitRole() {
            this.e1 = 6;
            this.submitting = true;
            this.backendActionItems.push("Saving role info");

            const roleObj = this.extractRoleInfo();

            try {
                const res = await this.$apollo
                                            .mutate({
                                                mutation: lookupProcessUserRole,
                                                variables: {
                                                    input: roleObj
                                                },
                                            });
                if (res.data.lookupProcessUserRole.errors === true) {
                    emitAlert(res.data.lookupProcessUserRole.message);
                    this.submitting = false;
                    return;
                }

                emitSuccess(res.data.lookupProcessUserRole.message);
                
                this.server_response_obj = res.data.lookupProcessUserRole.business_object;
                this.closeOnboardingDialog();
                
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.lookupProcessUserRole.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
        extractRoleInfo() {
            const roleObj = {
                OP_TYPE: 'CREATE',

                name: null,
                description: null,
                level: []
            };

            roleObj.name = this.role_details.name.trim();
            roleObj.description = this.role_details.description.trim();
            roleObj.level = this.role_details.level;

            // ----------------------------------Extract allowed_screens if there are any
            const allowed_screens = [];
            const validAllowedScreens = this.allowed_screens.filter(det => {
                return det.screen !== null
            });

            if (validAllowedScreens.length > 0) {
                validAllowedScreens.forEach(screen => {
                    allowed_screens.push({
                        screen: screen.screen,
                    });
                });
            }

            if (allowed_screens.length > 0) {
                roleObj.allowed_screens = allowed_screens;
            }

            // ----------------------------------Extract allowed_reports if there are any
            const allowed_reports = [];
            const validAllowedReports = this.allowed_reports.filter(rep => {
                return rep.report !== null
            });

            if (validAllowedReports.length > 0) {
                validAllowedReports.forEach(rep => {
                    allowed_reports.push({
                        report: rep.report,
                    });
                });
            }

            if (allowed_reports.length > 0) {
                roleObj.allowed_reports = allowed_reports;
            }

            // ----------------------------------Extract allowed_dashboards if there are any
            const allowed_dashboards = [];
            const validAllowedDashboards = this.allowed_dashboards.filter(rep => {
                return rep.dashboard !== null
            });

            if (validAllowedDashboards.length > 0) {
                validAllowedDashboards.forEach(dash => {
                    allowed_dashboards.push({
                        dashboard: dash.dashboard,
                    });
                });
            }

            if (allowed_dashboards.length > 0) {
                roleObj.allowed_dashboards = allowed_dashboards;
            }

            // ----------------------------------Extract allowed_business_processes if there are any
            const allowed_business_processes = [];
            const validAllowedBusinessProcesses = this.allowed_business_processes.filter(rep => {
                return rep.process !== null
            });

            if (validAllowedBusinessProcesses.length > 0) {
                validAllowedBusinessProcesses.forEach(proc => {
                    allowed_business_processes.push({
                        process: proc.process,
                    });
                });
            }

            if (allowed_business_processes.length > 0) {
                roleObj.allowed_business_processes = allowed_business_processes;
            }

            return roleObj;
        },

        async refreshScreensList() {
            this.refreshing_screens_list = true;
            this.user_screens = [];

            try {
                const res = await this.$apollo
                                    .query({
                                        query: getUserScreens,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'USER_SCREEN',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.submitting = false;
                    return;
                }

                this.refreshing_screens_list = false;
                
                this.user_screens = res.data.getBusinessObject.business_objects.map(screen => {
                    screen.display_value = screen.name + ' - ' + screen.description;
                    return screen
                });
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
        
        async refreshReportsList() {
            this.refreshing_reports_list = true;
            this.user_reports = [];

            try {
                const res = await this.$apollo
                                    .query({
                                        query: getReports,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'REPORT',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.submitting = false;
                    return;
                }

                this.refreshing_reports_list = false;
                
                this.user_reports = res.data.getBusinessObject.business_objects.map(report => {
                    report.display_value = report.name + ' - ' + report.description;
                    return report
                });
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },

        async refreshDashboardsList() {
            this.refreshing_dashboards_list = true;
            this.user_dashboards = [];

            try {
                const res = await this.$apollo
                                    .query({
                                        query: getDashboards,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'DASHBOARD',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.submitting = false;
                    return;
                }

                this.refreshing_dashboards_list = false;
                
                // Prepare user-dashboards data
                this.user_dashboards = res.data.getBusinessObject.business_objects.map(dashboard => {
                    dashboard.display_value = dashboard.name + ' - ' + dashboard.description;
                    return dashboard
                });
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },

        async refreshBusinessProcessesList() {
            this.refreshing_business_processes_list = true;
            this.business_processes = [];

            try {
                const res = await this.$apollo
                                    .query({
                                        query: getBusinessProcesses,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'BUSINESS_PROCESS',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.submitting = false;
                    return;
                }

                this.refreshing_business_processes_list = false;
                
                this.business_processes = res.data.getBusinessObject.business_objects.map(proc => {
                    proc.display_value = proc.name + ' - ' + proc.description;
                    return proc;
                });
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
    },
    async mounted() {
        try {
            // Load Screens List
            let res = await this.$apollo
                                    .query({
                                        query: getUserScreens,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'USER_SCREEN',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.submitting = false;
                return;
            }

            // Prepare user-screens data
            this.user_screens = res.data.getBusinessObject.business_objects.map(screen => {
                screen.display_value = screen.name + ' - ' + screen.description;
                return screen
            });

            res = await this.$apollo
                                    .query({
                                        query: getReports,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'REPORT',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.submitting = false;
                return;
            }
            
            // Prepare user-reports data
            this.user_reports = res.data.getBusinessObject.business_objects.map(report => {
                report.display_value = report.name + ' - ' + report.description;
                return report
            });

            res = await this.$apollo
                                    .query({
                                        query: getDashboards,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'DASHBOARD',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.submitting = false;
                return;
            }
            
            // Prepare user-dashboards data
            this.user_dashboards = res.data.getBusinessObject.business_objects.map(dashboard => {
                dashboard.display_value = dashboard.name + ' - ' + dashboard.description;
                return dashboard
            });

            // Business process list
            res = await this.$apollo
                                    .query({
                                        query: getBusinessProcesses,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'BUSINESS_PROCESS',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.submitting = false;
                return;
            }
            
            this.business_processes = res.data.getBusinessObject.business_objects.map(proc => {
                proc.display_value = proc.name + ' - ' + proc.description;
                return proc;
            });
        } catch (error) {
            this.submitting = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
            
            return;
        }
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
export default {
  methods: {
    formatNumber(value) {
      const val = Number(value / 1);
      return val.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace(/\.00$/, '');
    },
    formatNumberTable(value) {
      const val = Number(value / 1);
      return val.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    shortenNumber(number, fraction) {
      const ranges = [
        { divider: 1, suffix: '' },
        { divider: 1e3, suffix: 'k' },
        { divider: 1e6, suffix: 'm' },
        { divider: 1e9, suffix: 'bn' },
        { divider: 1e12, suffix: 'tn' },
      ];
      const index = (Math.abs(Math.round(number)).toString().length / 4).toFixed(0);
      return (number / ranges[index].divider).toFixed(fraction) + ranges[index].suffix;
    },
  },
};

<template>
    <v-row justify="center">
        <!-- <v-dialog
            v-model="dialog"
            fullscreen
            persistent
        > -->
        <v-dialog
            v-model="dialog"
            persistent
        >
            <div style="background:white; overflow-y:auto; height:100%">
                <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                    <v-spacer></v-spacer>
                    <v-toolbar-title><b>Add Dependents</b></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                class="mx-2"
                                color="red"
                                small
                                v-on="on"
                                @click="resetForm()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Reset Form</span>
                    </v-tooltip>
                    <v-btn icon @click="closeDialog()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card>
                    <v-card-text>
                        <v-row
                            v-for="dep in dependents"
                            :key="dep.id"
                        >
                            <v-col sm="12">
                                <DependentForm
                                    :dependent="dep"
                                    :product="product"
                                    :show_numbering="true"
                                />
                            </v-col>
                            <div style="background:white; height: 10px; width: 100%"></div>
                        </v-row>
                        <!-- <v-row
                            v-for="dep in dependents"
                            :key="dep.id"
                            style="background:#eaeaea; padding-bottom:10px"
                        > -->
                            
                            <!-- <v-col cols="12" sm="4">
                                <v-text-field
                                    v-model="dep.name"
                                    label="Dependent Name"
                                    color="secondary"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-text-field
                                    v-model="dep.surname"
                                    label="Dependent Surname"
                                    color="secondary"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-text-field
                                    v-model="dep.id_number"
                                    label="Dependent ID Number"
                                    color="secondary"
                                    type="number"
                                    required
                                ></v-text-field>
                            </v-col>
                            <div style="background:white; height: 10px; width: 100%"></div>
                        </v-row> -->
                        <!-- Buttons for Adding/Removing dependents -->
                        <v-row>
                            <v-col sm="10"></v-col>
                            <v-col sm="2">
                                <v-tooltip bottom v-if="dependents.length > 1">
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            class="mx-2"
                                            color="secondary"
                                            small
                                            v-on="on"
                                            @click="removeDependent()"
                                        >
                                            <v-icon>remove</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Remove Dependent</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            color="secondary"
                                            small
                                            v-on="on"
                                            @click="addDependent()"
                                        >
                                            <v-icon>add</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Add Another Dependent</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                        <v-card-text class="text-center" v-if="submitting">
                            Submitting Policy Dependents
                            <v-progress-linear
                                indeterminate
                                :active="submitting"
                                color="green"
                                class="mb-0"
                            ></v-progress-linear>
                        </v-card-text>
                    </v-card-text>
                    <!-- Button Section -->
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    large
                                    color="success"
                                    v-on="on"
                                    :disabled="submitBtnDisabled()"
                                    @click="submitDependents()"
                                >
                                    <v-icon large>save</v-icon>
                                </v-btn>
                            </template>
                            <span>Save Policy Dependent</span>
                        </v-tooltip>
                    </v-card-actions>
                </v-card>
            </div>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert } from "../../../../../utils/api";
import { eventBus } from "../../../../../main";
import DependentForm from "../components/DependentForm.vue";

import { businessProcessProspectPolicy } from "../gql/mutations";

export default {
    components: {
        DependentForm
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        policy_id: {
            type: String,
            default: null
        },
        product: {
            type: Object,
            default: null
        },
    },
    data: () => ({
        dependents: [
            {
                id: 1,
                name: '',
                surname: '',
                id_number: '',
                id_number_valid: false,
                spouse_to_principal: false,
                elected_dependent: false,

                member: null,
            }
        ],
        
        products: [],
        schemes: [],

        error_message: null,
        submitting: false,
        server_response_obj: null
    }),
    methods: {
        closeDialog() {
            eventBus.$emit('CLOSE_ADD_DEPENDENTS_DIALOG', this.server_response_obj);
        },
        resetForm() {
            this.submitting = false;
            this.server_response_obj = null;
            this.dependents = [
                {
                    id: 1,
                    name: '',
                    surname: '',
                    id_number: '',
                    id_number_valid: false,
                    spouse_to_principal: false,
                    elected_dependent: false,

                    member: null,
                }
            ];

            eventBus.$emit('RESET_DEPENDENT_FORM_FIELDS');
        },
        //--------------------ADD/REMOVE DEPENDENTS--------------------------//
        addDependent() {
            const size = this.dependents.length;

            this.dependents.push({
                id: size + 1,
                name: '',
                surname: '',
                id_number: '',

                id_number_valid: false,
                spouse_to_principal: false,
                elected_dependent: false,

                member: null,
            });
        },
        removeDependent() {
            this.dependents.pop();
        },

        submitBtnDisabled() {
            const validNewDependents = this.dependents
                                                    .filter(dep => {
                                                        return (
                                                            dep.member !== null
                                                        )
                                                    });

            if (validNewDependents.length === 0) {
                return true;
            }

            return false;
        },

        async submitDependents() {
            // Then submit the policy
            this.submitting = true;

            const validDependents = this.dependents.filter(dep => {
                                            return dep.member !== null
                                        })
                                        .map(dep => {
                                            return {
                                                entity: dep.member._id,
                                                spouse_to_principal: dep.spouse_to_principal,
                                                elected_dependent: dep.elected_dependent,
                                            }
                                        });

            // Submit policy to server
            try {
                const payloadObj = {
                    OP_TYPE: 'ADD_DEPENDENTS',
                    _id: this.policy_id,

                    dependents: validDependents
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcessProspectPolicy,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.businessProcessProspectPolicy.errors === true) {
                    emitAlert(res.data.businessProcessProspectPolicy.message);
                    this.submitting = false;
                    return;
                }

                this.server_response_obj = res.data.businessProcessProspectPolicy.business_object;
                this.submitting = false;
                this.closeDialog();

                // reset form
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessProspectPolicy.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        }
    },
    computed: {
    },
    mounted() {

    }
}
</script>
<style scoped>

</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticClass:"pa-0",attrs:{"color":"secondary","dark":"","height":"50","extension-height":"3"}},[_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"10px"},attrs:{"sm":"8"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.refreshDataTable()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh")])])],1),_c('v-col',{staticStyle:{"margin-top":"10px"},attrs:{"sm":"4"}},[_c('div',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"amber black--text"},on:{"click":function($event){return _vm.openUnderwriterDialog()}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v("Add Underwriter")])])],1)])],1)],1),_c('multipane',{staticClass:"horizontal-panes",staticStyle:{"height":"100%"},attrs:{"layout":"horizontal"},on:{"paneResize":_vm.paneResizing}},[_c('div',{staticClass:"pane",style:({ height: '50%', maxHeight: '50%'})},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.main_tab_height,"headers":_vm.underwriter_headers,"items":_vm.underwriter_list,"item-key":"_id","single-select":true,"item-class":"secondary","hide-default-footer":"","loading":_vm.data_table_loading,"disable-pagination":true},on:{"click:row":_vm.selectMainTableRow},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.created.split('T')[0]))])]}},{key:"item.captured_by",fn:function(ref){
var item = ref.item;
return [(item.captured_by !== null && item.captured_by !== undefined)?_c('td',[_vm._v(" "+_vm._s(item.captured_by.entity.name)+" "+_vm._s(item.captured_by.entity.surname)+" ")]):_vm._e()]}}],null,true)})],1),_c('multipane-resizer'),_c('div',{staticClass:"pane tab-nav",style:({ height: '30%', maxHeight: '50%', minHeight: '30%' })},[_c('v-tabs',{staticClass:"tab-nav",attrs:{"color":"transparent","hide-slider":"","height":"35px","grow":""},model:{value:(_vm.sub_tabs),callback:function ($$v) {_vm.sub_tabs=$$v},expression:"sub_tabs"}},[[_c('v-tab',{key:"documents_sub_tab",staticStyle:{"max-width":"210px","font-size":"12px"},attrs:{"active-class":"secondary white--text"}},[_c('span',{staticClass:"tab-title text-truncate"},[_vm._v(" Documents ")]),_c('div',{staticClass:"spacer"})])],[_c('v-tab',{key:"bank_account_details_sub_tab",staticStyle:{"max-width":"210px","font-size":"12px"},attrs:{"active-class":"secondary white--text"}},[_c('span',{staticClass:"tab-title text-truncate"},[_vm._v(" Bank Account Details ")]),_c('div',{staticClass:"spacer"})])]],2),_c('v-tabs-items',{model:{value:(_vm.sub_tabs),callback:function ($$v) {_vm.sub_tabs=$$v},expression:"sub_tabs"}},[_c('v-toolbar',{staticClass:"pa-0",attrs:{"color":"secondary","dark":"","height":"50","extension-height":"3"}},[(_vm.sub_tabs === 0 && _vm.selectedUnderwriter !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.openAddUnderwriterDocumentsDialog()}}},on),[_c('v-icon',[_vm._v("add")])],1)]}}],null,false,1969962603)},[_c('span',[_vm._v("Add Underwriter Document(s)")])]):_vm._e(),(_vm.sub_tabs === 0  && _vm.selectedUnderwriterDocument !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.openDeleteUnderwriterDocumentDialog()}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,false,268596664)},[_c('span',[_vm._v("Delete Underwriter Document(s)")])]):_vm._e(),(_vm.sub_tabs === 0  && _vm.selectedUnderwriterDocument !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.openViewUnderwriterDocumentDialog()}}},on),[_c('v-icon',[_vm._v("content_paste")])],1)]}}],null,false,4247098384)},[_c('span',[_vm._v("View Document Info")])]):_vm._e(),(_vm.sub_tabs === 1 && _vm.selectedUnderwriter !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.openAddBankingDialog()}}},on),[_c('v-icon',[_vm._v("add")])],1)]}}],null,false,150973398)},[_c('span',[_vm._v("Add Underwriter Banking Details")])]):_vm._e(),(_vm.sub_tabs === 1  && _vm.selectedBanking !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.openDeleteBankingDialog()}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,false,1247655574)},[_c('span',[_vm._v("Delete Underwriter Banking Details")])]):_vm._e(),(_vm.sub_tabs === 1  && _vm.selectedBanking !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.openEditBankingDialog()}}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,false,3739470390)},[_c('span',[_vm._v("Edit Underwriter Banking Details")])]):_vm._e(),_c('v-spacer')],1),_c('v-tab-item',{key:"documents_sub_tab"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.sub_tab_height,"headers":_vm.document_details_headers,"items":_vm.underwriter_documents,"item-key":"_id","single-select":true,"hide-default-footer":"","disable-pagination":true},on:{"click:row":_vm.selectDocumentsTableRow},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.created.split('T')[0]))])]}},{key:"item.deleted",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.deleted ? 'Yes' : 'No')+" ")])]}},{key:"item.captured_by",fn:function(ref){
var item = ref.item;
return [(item.captured_by !== null)?_c('td',[_vm._v(" "+_vm._s(item.captured_by.entity.name)+" "+_vm._s(item.captured_by.entity.surname)+" ")]):_vm._e()]}}],null,true)})],1),_c('v-tab-item',{key:"bank_account_details_sub_tab"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.sub_tab_height,"headers":_vm.bank_account_details_headers,"items":_vm.underwriter_bank_account_details,"item-key":"_id","single-select":true,"hide-default-footer":"","disable-pagination":true},on:{"click:row":_vm.selectBankingTabRow},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.created.split('T')[0]))])]}},{key:"item.captured_by",fn:function(ref){
var item = ref.item;
return [(item.captured_by !== null)?_c('td',[_vm._v(" "+_vm._s(item.captured_by.entity.name)+" "+_vm._s(item.captured_by.entity.surname)+" ")]):_vm._e()]}}],null,true)})],1)],1)],1)],1),_c('AddUnderwriterDialog',{attrs:{"dialog":_vm.open_add_underwriter_dialog}}),_c('AddUnderwriterDocumentsDialog',{attrs:{"dialog":_vm.open_add_underwriter_documents_dialog,"underwriter":_vm.selectedUnderwriter}}),(_vm.selectedUnderwriterDocument !== null)?_c('ViewUnderwriterDocumentDialog',{attrs:{"dialog":_vm.open_view_underwriter_document_dialog,"document":_vm.selectedUnderwriterDocument,"param_path":"underwriter","param_id":_vm.selectedUnderwriter._id}}):_vm._e(),(_vm.selectedUnderwriterDocument !== null)?_c('DeleteUnderwriterDocumentDialog',{attrs:{"dialog":_vm.open_delete_underwriter_document_dialog,"document":_vm.selectedUnderwriterDocument,"underwriter":_vm.selectedUnderwriter}}):_vm._e(),(_vm.selectedUnderwriter !== null)?_c('AddUnderwriterBankingDialog',{attrs:{"dialog":_vm.open_add_banking_dialog,"underwriter_id":_vm.selectedUnderwriter._id}}):_vm._e(),(_vm.selectedBanking !== null)?_c('DeleteUnderwriterBankingDialog',{attrs:{"dialog":_vm.open_delete_banking_dialog,"underwriter":_vm.selectedUnderwriter,"banking":_vm.selectedBanking}}):_vm._e(),(_vm.selectedUnderwriter !== null && _vm.selectedBanking !== null)?_c('EditUnderwriterBankingDialog',{attrs:{"dialog":_vm.open_edit_banking_dialog,"underwriter_id":_vm.selectedUnderwriter._id,"banking_original":_vm.selectedBanking,"banking_copy":_vm.selectedBankingCopy}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
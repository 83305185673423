import gql from 'graphql-tag'

export const getBanks = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on Bank {
                    __typename
                    _id
                    name
                    universal_branch_code
                }
            }
        }
    }
`
<template>
    <div>
        <v-toolbar
            color="secondary"
            dark
            class="pa-0"
            height="50"
            extension-height="3"
        >
            <v-row>
                <!-- Grid Buttons -->
                <v-col sm="8" style="margin-top:10px">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="refreshDataTable()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh</span>
                    </v-tooltip>
                </v-col>
                
            </v-row>
        </v-toolbar>
        <multipane class="horizontal-panes" layout="horizontal" style="height: 100%">
            <div class="pane" :style="{ height: '50%', maxHeight: '50%'}">
                <v-data-table
                    fixed-header
                    height="600"
                    :headers="product_rule_parameter_header_details"
                    :items="product_rule_parameters"
                    item-key="_id"
                    class="elevation-1"
                    :single-select="true"
                    @click:row="selectMainTableRow"
                    item-class="secondary"
                    hide-default-footer
                    :disable-pagination="true"
                    :loading="data_table_loading"
                    loading-text="Loading... Please wait"
                ></v-data-table>
            </div>
        </multipane>
    </div>
</template>
<script>
import { Multipane } from 'vue-multipane';
import { emitAlert } from '../../../utils/api';
import { getProductRuleParameters } from "./gql/queries";

export default {
    components: {
        Multipane,
        // AddBankDialog,
    },

    data: () => ({
        selectedRuleType: null,
        selectedRow: null,
        data_table_loading: false,

        product_rule_parameter_header_details: [
            { text: 'Rule Level', value: 'level' },
            { text: 'Rule ID', value: 'id' },
            { text: 'Name', value: 'name' },
            { text: 'Description', value: 'description' },
        ],
        
        // Table data models
        product_rule_parameters: [],
    }),

    methods: {
        selectMainTableRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);

                this.selectedRuleType = item;
            } else {
                this.selectedRuleType = null;
                // Unighlight selected row
                item_metadata.select(false);
            }
        },
        async refreshDataTable() {
            this.data_table_loading = true;
            this.product_rule_parameters = [];

            try {
                const res = await this.$apollo
                                            .query({
                                                query: getProductRuleParameters,
                                                // fetchPolicy: 'network-only',
                                                fetchPolicy: 'no-cache',
                                                errorPolicy: 'all',
                                                variables: {
                                                    objectType: 'PRODUCT_RULE_PARAMETER',
                                                    pagination: {
                                                        page: this.page,
                                                        limit: this.limit
                                                    }
                                                },
                                            });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }

                this.data_table_loading = false;
                this.product_rule_parameters = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        }
    },

    async mounted() {
        this.data_table_loading = true;

        try {
            const res = await this.$apollo
                                        .query({
                                            query: getProductRuleParameters,
                                            // fetchPolicy: 'network-only',
                                            fetchPolicy: 'no-cache',
                                            errorPolicy: 'all',
                                            variables: {
                                                objectType: 'PRODUCT_RULE_PARAMETER',
                                                pagination: {
                                                    page: this.page,
                                                    limit: this.limit
                                                }
                                            },
                                        });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.data_table_loading = false;
                return;
            }

            this.data_table_loading = false;
            this.product_rule_parameters = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.data_table_loading = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
        }
    },

    watch: {
        
    }
}
</script>
<style scoped>
/* Overriding of textbox min height */
 .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
}
</style>
<template>
    <div>
        <v-toolbar
            color="secondary"
            dark
            class="pa-0"
            height="50"
            extension-height="3"
        >
            <v-row>
                <!-- Grid Buttons -->
                <v-col sm="8" style="margin-top:10px">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="refreshDataTable()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh</span>
                    </v-tooltip>
                </v-col>


                <!-- Action Buttons -->
                <v-col sm="4" style="margin-top:10px">
                    <div class="text-right">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="openAddScreenDialog()"
                                    v-on="on"
                                >
                                    <v-icon medium>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add User Screen</span>
                        </v-tooltip>
                    </div>
                </v-col>
            </v-row>
        </v-toolbar>
        <multipane class="horizontal-panes" layout="horizontal" style="height: 100%">
            <div class="pane" :style="{ height: '50%', maxHeight: '50%'}">
                <v-data-table
                    fixed-header
                    height="600"
                    :headers="user_screen_details_headers"
                    :items="user_screens"
                    item-key="_id"
                    class="elevation-1"
                    :single-select="true"
                    @click:row="selectMainTableRow"
                    item-class="secondary"
                    hide-default-footer
                    :disable-pagination="true"
                    :loading="data_table_loading"
                    loading-text="Loading... Please wait"
                ></v-data-table>
            </div>
        </multipane>
        <AddScreenDialog
            :dialog="open_add_user_screen_dialog"
        />
    </div>
</template>
<script>
import { Multipane } from 'vue-multipane';
import { emitAlert } from '../../utils/api';
import AddScreenDialog from './dialogs/add_user_screen';
import { eventBus } from "../../main";

import { getUserScreens } from "./gql/queries";

export default {
    components: {
        Multipane,
        AddScreenDialog,
    },

    data: () => ({
        selectedUserScreen: null,
        selectedRow: null,
        data_table_loading: false,

        user_screen_details_headers: [
            { text: 'Category', value: 'category' },
            { text: 'Name', value: 'name' },
            { text: 'Description', value: 'description' },
        ],
        
        // Table data models
        user_screens: [],

        // Dialogs
        open_add_user_screen_dialog: false,
    }),

    methods: {
        //-------------------- OPEN DIALOGS ---------------------//
        openAddScreenDialog() {
            // Open onboarding dialog
            this.open_add_user_screen_dialog = true;
        },

        //------------------- CLOSE DIALOGS---------------------//
        closeAddScreenDialog(userScreenObj) {
            if (userScreenObj !== null) {
                this.user_screens.push(userScreenObj);
            }

            this.open_add_user_screen_dialog = false;
        },
        selectMainTableRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);

                this.selectedUserScreen = item;
            } else {
                this.selectedUserScreen = null;
                // Unighlight selected row
                item_metadata.select(false);
            }
        },
        async refreshDataTable() {
            this.data_table_loading = true;
            this.user_screens = [];
            
            try {
                const res = await this.$apollo
                                    .query({
                                        query: getUserScreens,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'USER_SCREEN',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }

                this.data_table_loading = false;
                this.user_screens = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        }
    },

    async mounted() {
        eventBus.$on('CLOSE_ADD_SCREEN_DIALOG', this.closeAddScreenDialog);

        this.data_table_loading = true;
        try {
            const res = await this.$apollo
                                    .query({
                                        query: getUserScreens,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'USER_SCREEN',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.data_table_loading = false;
                return;
            }

            this.data_table_loading = false;
            this.user_screens = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.data_table_loading = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
        }
    },

    watch: {
        
    }
}
</script>
<style scoped>
/* Overriding of textbox min height */
 .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
}
</style>
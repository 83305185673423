<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Create A User</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeAddUserDialog()" v-if="server_response_obj === null">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-stepper v-model="e1">
                <v-stepper-header>
                    <v-stepper-step :complete="e1 > 1" step="1" color="success">User Details</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 2" step="2" color="success">Roles</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="3" color="success">Processing</v-stepper-step>
                </v-stepper-header>
            
                <v-stepper-items>
                    <!-- Basic Details -->
                    <v-stepper-content step="1">
                        <v-card>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" sm="12">
                                        <MemberSearch :member="user_details" />
                                        <v-progress-linear
                                            color="green"
                                            class="mb-0"
                                            height="10"
                                            indeterminate
                                            :active="backend_check"
                                        ></v-progress-linear>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    fab
                                    color="amber"
                                    :disabled="goToStep2BtnDisabled()"
                                    @click="goToStep2()"
                                >
                                    <v-icon large>keyboard_arrow_right</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- User Roles Section -->
                    <v-stepper-content step="2">
                        <v-card>
                            <v-card-text>
                                <v-row
                                    v-for="user_role in roles"
                                    :key="user_role.id"
                                    style="background:#eaeaea; padding-bottom:10px"
                                >
                                    <v-col cols="12" sm="12">
                                        <v-select
                                            v-model="user_role.role"
                                            :items="user_roles"
                                            color="secondary"
                                            item-value="_id"
                                            item-text="name"
                                            label="Role"
                                            :disabled="refreshing_user_roles_list"

                                            hint="You can click the refresh icon next to the field if the user roles list is incomplete"
                                            persistent-hint

                                            required
                                        >
                                            <!-- Format prepend inner icon -->
                                            <template v-slot:prepend-inner>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon
                                                            v-on="on"
                                                            color="success"
                                                            @click="refreshUserRolesList()"
                                                        >
                                                            mdi-refresh
                                                        </v-icon>
                                                    </template>
                                                    <span>Refresh User Roles List</span>
                                                </v-tooltip>
                                            </template>
                                        </v-select>
                                        <v-progress-linear
                                            color="green"
                                            class="mb-0"
                                            height="4"
                                            indeterminate
                                            :active="refreshing_user_roles_list"
                                        ></v-progress-linear>
                                    </v-col>
                                    <div style="background:white; height: 10px; width: 100%"></div>
                                </v-row>
                                <!-- Buttons for Adding/Removing User Roles -->
                                <v-row>
                                    <v-col sm="10"></v-col>
                                    <v-col sm="2">
                                        <v-tooltip bottom v-if="roles.length > 1">
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    class="mx-2"
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="removeRole()"
                                                >
                                                    <v-icon>remove</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Remove Role</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    color="secondary"
                                                    small
                                                    v-on="on"
                                                    @click="addRole()"
                                                >
                                                    <v-icon>add</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Add Another Role</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <!-- Button Section -->
                            <v-card-actions>
                                <v-btn
                                    fab
                                    color="amber"
                                    @click="e1 = 1"
                                >
                                    <v-icon large>keyboard_arrow_left</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    large
                                    color="success"
                                    :disabled="saveUserBtnDisabled()"
                                    @click="saveUser()"
                                >
                                    <v-icon large>save</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <!-- Submission Results Section -->
                    <v-stepper-content step="3">
                        <v-card>
                            <v-card-text class="text-center" v-if="submitting">
                                Submitting User Details
                                <v-progress-linear
                                    indeterminate
                                    color="green"
                                    class="mb-0"
                                    height="10"
                                    :active="submitting"
                                ></v-progress-linear>

                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="success"
                                    @click="closeAddUserDialog()"
                                >
                                    <v-icon large>done</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>
    </v-row>
</template>
<script>
import MemberSearch from "../components/member_search";

import { eventBus } from ".././../../main";
import { emitAlert, emitSuccess } from "../../../utils/api";

import { getUserRolesContextualized, getUsers } from "../gql/queries";
import { entityProcessUser } from "../gql/mutations";

export default {
    components: {
        MemberSearch
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        e1: 1,
        user_details: {
            member: null,
            roles: []
        },
        
        roles: [
            {
                id: 1,
                role: null,
            }
        ],
        
        server_response_obj: null,
        submitting: false,
        backend_check: false,

        refreshing_user_roles_list: false,

        user_roles: []
    }),
    methods: {
        closeAddUserDialog() {
            eventBus.$emit('CLOSE_ADD_USER_DIALOG', this.server_response_obj);
        },
        resetForm() {
            this.e1 = 1;
            this.user_details = {
                member: null,
                roles: []
            };
            
            this.roles = [
                {
                    id: 1,
                    role: null,
                }
            ];
            
            this.server_response_obj = null;
            this.submitting = false;
            this.backend_check = false;
        },

        //--------------------ADD/REMOVE ROLES--------------------------//
        addRole() {
            const role_size = this.roles.length;

            this.roles.push({
                id: role_size + 1,
                role: null
            });
        },
        removeRole() {
            this.roles.pop();
        },
        
        goToStep2BtnDisabled() {
            if (this.user_details.member === null || this.user_details.member === undefined) {
                return true;
            }
            return false;
        },
        async goToStep2() {
            // Check if user exists
            try {
                this.backend_check = true;
                // const res = await getUserByEntity(this.user_details.member._id);
                const res = await this.$apollo
                                    .query({
                                        query: getUsers,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'USER_BY_ENTITY',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            },
                                            searchInput: {
                                                entity_id: this.user_details.member._id
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.backend_check = false;
                    return;
                }
                
                this.backend_check = false;
                const users = res.data.getBusinessObject.business_objects;
                if (users.length === 0) {
                    this.e1 = 2;
                    return;
                }

                emitAlert('A user profile is already created for the selected person');
            } catch (error) {
                this.backend_check = false;
                console.log(error);
                if (error !== undefined) {
                    // emitAlert(error.data.getBusinessObject.message);
                    emitAlert(error.data);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
            
        },

        saveUserBtnDisabled() {
            const validRoles = this.roles.filter(role => { return role.role !== null; });
            if (validRoles.length === 0) {
                return true;
            }
            return false;
        },

        async saveUser() {
            // Then submit the user
            this.submitting = true;
            this.e1 = 3;
            
            for (const role of this.roles) {
                if (role.role !== null) {
                    this.user_details.roles.push({
                        role: role.role
                    });
                }
            }

            this.user_details.entity = this.user_details.member._id;

            // Submit user to server
            try {
                const payload = {
                    OP_TYPE: 'CREATE',
                    active: true,
                    ...this.user_details
                };
                delete payload.member;
                const res = await this.$apollo
                                            .mutate({
                                                mutation: entityProcessUser,
                                                variables: {
                                                    input: payload
                                                },
                                            });
                this.submitting = false;

                if (res.data.entityProcessUser.errors === true) {
                    emitAlert(res.data.entityProcessUser.message);
                    return;
                }

                emitSuccess(res.data.entityProcessUser.message);

                this.server_response_obj = res.data.entityProcessUser.business_object;
                this.closeAddUserDialog();

                // Reset
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.entityProcessUser.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },

        async refreshUserRolesList() {
            this.refreshing_user_roles_list = true;
            try {
            // Load Contextualized user roles
                const res = await this.$apollo
                                    .query({
                                        query: getUserRolesContextualized,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'USER_ROLE_CONTEXTUALIZED',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                this.refreshing_user_roles_list = false;

                if (res.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }
                this.user_roles = res.data.getBusinessObject.business_objects
            } catch (error) {
                this.refreshing_user_roles_list = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        }
    },
    async mounted() {
        try {
            // Load Contextualized user roles
            // const res = await getUserRolesContextualized();
            const res = await this.$apollo
                                    .query({
                                        query: getUserRolesContextualized,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'USER_ROLE_CONTEXTUALIZED',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.submitting = false;
                return;
            }
            this.user_roles = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.submitting = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
            
            return;
        }
    },
}
</script>
<style scoped>

</style>
<template>
    <v-autocomplete
        v-model="member.agent"
        :items="search_entries"
        :loading="isLoading"
        :search-input.sync="search"
        hide-no-data
        small-chips
        item-text="display_value"
        item-value="_id"
        :label="label"
        placeholder="Start typing to Search"
        prepend-icon="search"
        color="secondary"
        return-object
    ></v-autocomplete>
</template>

<script>
import { emitAlert } from '../../../../utils/api';
import { searchAgentAutocomplete } from "../gql/queries";
export default {
    name: "AgentSearch",
    props: {
        member: {
            required: false,
            default: null
        },
        label: {
            required: false,
            default: 'Search Agent Member'
        }
    },
    data: () => ({
        search: null,
        search_entries: [],
        isLoading: false
    }),
    computed: {
        items () {
            return this.search_entries.map(entry => {
                const display_value = `${entry.name}  ${entry.surname} (${entry.agent_number})`;
                return Object.assign({}, entry, { display_value })
            })
        },
    },
    mounted() {
        // Set selected chip if agent entity is already initialized
        if (this.member.agent !== null && this.member.agent !== undefined) {
            this.search_entries.push(this.member.agent);
        }
    },
    watch: {
        async search (val) {
            // Items have already been loaded
            // if (this.items.length > 0) return

            // // Items have already been requested
            // if (this.isLoading) return

            this.isLoading = true

            if (val !== null) {
                if (val.length > 0) {
                    const searchQuery = {
                        name: val,
                        surname: val,
                        agent_number: val,
                    };

                    const res = await this.$apollo
                                            .query({
                                                query: searchAgentAutocomplete,
                                                // fetchPolicy: 'network-only',
                                                fetchPolicy: 'no-cache',
                                                errorPolicy: 'all',
                                                variables: {
                                                    objectType: 'AGENT',
                                                    pagination: {
                                                        page: this.page,
                                                        limit: this.limit
                                                    },
                                                    searchInput: searchQuery
                                                },
                                            });
                    this.isLoading = false;
                    if (res.data.getBusinessObject.errors === true) {
                        emitAlert(res.data.getBusinessObject.message);
                        return;
                    }

                    res.data.getBusinessObject.business_objects.map(entry => {
                        const display_value = `${entry.entity.name}  ${entry.entity.surname} (${entry.entity.id_number})`;
                        entry.display_value = display_value;
                        return entry;
                    });
                    this.search_entries = res.data.getBusinessObject.business_objects;
                } else {
                    this.isLoading = false;
                    this.search_entries = [];
                }
            } else {
                this.isLoading = false;
            }
            
        },
    }
};
</script>
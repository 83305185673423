import gql from 'graphql-tag'

export const getBusinessProcessRuns = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on BusinessProcessRun {
                    __typename
                    _id
                    status
                    time_taken
                    fin_calendar_month {
                        _id
                        name
                        value
                    }

                    business_process {
                        _id
                        name
                        process_number
                        group
                        description
                    }

                    created
                    captured_by {
                      _id
                      entity {
                        _id
                        name
                        surname
                      }
                    }
                }
            }
        }
    }
`

export const getFinancialCalendarMonths = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on FinanceCalendarMonth {
                    __typename
                    _id
                    name
                    value
                    status
                    created
                }
            }
        }
    }
`

export const getBusinessProcesses = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on BusinessProcess {
                    __typename
                    _id
                    name
                    group
                    process_number
                    description
                }
            }
        }
    }
`

export const getProducts = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on InsuranceProductSecondary {
                    __typename
                    _id
                    name
                    markup_fixed
                    markup_percentage
                    created
                    captured_by {
                        _id
                        entity {
                            _id
                            name
                            surname
                        }
                    }

                    primary_product {
                        __typename
                        _id
                        name

                        underwriter {
                            _id
                            name
                        }
                        underwriter_premium
                        broker_commission
                        broker_markup

                        payout_structure {
                            _id
                            category {
                                _id
                                name
                                membership_category
                                start_age
                                end_age
                            }
                            payout_amount
                        }
                        rules {
                            _id
                            rule_parameter {
                                _id
                                id
                                level
                                name
                                description
                                object_key
                            }
                            min
                            max
                        }
                        vas_services {
                            _id
                            limit_model
                            limit
                            created
                            vas_service {
                                _id

                                name
                                # category {
                                #     _id
                                #     name
                                # }
                                description
                                captured_by {
                                    _id
                                    entity {
                                        _id
                                        name
                                        surname
                                    }
                                }
                                created
                            }
                        }
                    }
                }
            }
        }
    }
`

export const getBanks = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!) {
        getBusinessObject(objectType: $objectType, pagination: $pagination) {
            errors
            message
            business_objects {
                ... on Bank {
                    __typename
                    _id
                    name
                    universal_branch_code
                }
            }
        }
    }
`

export const getSchemes = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on Scheme {
                    __typename
                    _id

                    scheme_number
                    name
                }
            }
        }
    }
`

export const searchAgentAutocomplete = gql`
    query getBusinessObject($objectType: String!, $pagination: PaginationInput!, $searchInput: SearchInput) {
        getBusinessObject(objectType: $objectType, pagination: $pagination, searchInput: $searchInput) {
            errors
            message
            business_objects {
                ... on Agent {
                    __typename
                    _id

                    agent_number
                    entity {
                        _id
                        name
                        surname
                        id_number
                    }
                }
            }
        }
    }
`

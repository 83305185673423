<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="90%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Validate Drafted Loan</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeValidateDraftedLoanDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card v-if="loan !== null && loan !== undefined">
                <v-expansion-panels focusable :value="1">
                    <v-expansion-panel style="background: #f6c20a8f" >
                        <v-expansion-panel-header>
                            <h3>Loan Details</h3><br>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row
                                style="background:#ffffff; padding-top:30px"
                            >
                                <v-col cols="12" sm="2" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="loan.quote_number"
                                        prefix="L"
                                        label="Quote Number"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2" style="padding-top: 0px">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="loan.created.split('T')[0]"
                                        label="Date Captured"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" style="padding-top: 0px">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        :value="loan.entity.name + ' ' + loan.entity.surname + ' (' + loan.entity.id_number + ')'"
                                        label="Loan Entity"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2" style="padding-top: 0px">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="loan.status"
                                        label="Status"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <div style="background:white; height: 10px; width: 100%"></div>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel style="background: #f6c20a8f">
                        <v-expansion-panel-header>
                            <h3>Loan Parameters</h3><br>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row
                                style="background:#ffffff; padding-top:30px"
                            >
                                <v-col cols="12" sm="2" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="getLoanType"
                                        label="Loan Type"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        :value="loan.pricing_input.principal_amount"
                                        label="Principal Amount"
                                        prefix="ZAR"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        :value="loan.pricing_input.loan_term"
                                        label="Loan Term"
                                        suffix="MONTHS"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <!-- <div style="background:white; height: 10px; width: 100%"></div> -->
                            <!-- </v-row> -->

                            <!-- <v-row style="background:#ffffff;"> -->
                                <v-col cols="12" sm="2" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        :value="loan.pricing_input.fees_config.initiation_fee_paid_at_inception === true ? 'YES' : 'NO'"
                                        label="Initiation Fees Paid At Inception"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row style="background:#ffffff;">
                                <v-col cols="12" sm="2" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="initiationFeeAmount"
                                        label="Initiation Fees"
                                        prefix="ZAR"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        v-model="loan.pricing_input.fees_config.monthly_admin_fee"
                                        label="Monthly Admin Fees"
                                        prefix="ZAR"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                            
                                <v-col cols="12" sm="2" style="padding-top: 0px;">
                                    <v-text-field
                                        style="padding-top: 0px"
                                        :value="loan.pricing_input.interest_config.annual_interest_rate"
                                        label="Annual Interest Rate"
                                        prefix="%"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="2" style="background: #af3939;">
                                    <!-- <v-text-field
                                        style="padding-top: 0px"
                                        :value="loan.premium"
                                        label="Original Premium"
                                        prefix="ZAR"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field> -->

                                    <h3 style="color:white;">Original Premium: <br>R{{ loan.premium }}</h3>
                                </v-col>
                            </v-row>


                            <!-- <v-row
                                v-if="loan.system_approval_results.approval_method !== null"
                                style="background:#ffffff;"
                            > -->
                            <v-row
                                v-if="validation_results !== null"
                                style="background:#ffffff;"
                            >
                                <v-col cols="12" sm="12" style="padding-top: 0px;">
                                    <h2>System Approval Results</h2>
                                </v-col>

                                <!-- <v-col cols="12" sm="2" style="padding-top: 0px;">
                                    <v-text-field
                                        :value="loan.system_approval_results.approval_method"
                                        label="System Approval Method"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="1">
                                    <v-icon v-if="loan.system_approval_results.approval_method === 'AUTO-APPROVE'" color="green" x-large>mdi-check-circle</v-icon>
                                    <v-icon v-if="loan.system_approval_results.approval_method === 'AUTO-DECLINE'" color="red" x-large>mdi-close-circle</v-icon>
                                    <v-icon v-if="loan.system_approval_results.approval_method === 'MANUAL'" color="amber" x-large>mdi-minus-circle</v-icon>
                                </v-col> -->

                                <v-col cols="12" sm="2" style="padding-top: 0px;">
                                    <v-text-field
                                        :value="system_collection_date === -1 ? 'NO-DATE' : system_collection_date"
                                        label="System Chosen Collection Date"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    v-if="validation_results !== null"
                                    cols="12" sm="2" style="background: green"
                                >
                                    <!-- <v-text-field
                                        style="padding-top: 0px"
                                        :value="discounted_premium"
                                        label="Final Premium"
                                        prefix="ZAR"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field> -->
                                    <h3 style="color:white;">Discounted Premium: <br>R{{ discounted_premium }}</h3>
                                </v-col>
                                <!-- <v-col cols="12" sm="7" style="padding-top: 0px;">
                                    <v-text-field
                                        :value="loan.system_approval_results.reason"
                                        label="System Reason"
                                        type="text"
                                        disabled
                                        required
                                    ></v-text-field>
                                </v-col> -->
                            </v-row>

                            <!-- <v-row
                                v-if="loan.system_approval_results.approval_method !== null"
                                style="background:#ffffff;"
                            >
                                <v-col cols="12" sm="12" style="padding-top: 0px;">
                                    <h3>Bank Statement Analysis</h3>
                                </v-col>

                                <v-col cols="12" sm="3" style="border: solid 3px grey; border-radius:10px">
                                    <v-row>
                                        <v-col cols="12" sm="12" >
                                            <h4>Month 1</h4>
                                        </v-col>
                                        <v-col cols="12" sm="10">
                                            <v-text-field
                                                style="padding-top: 0px; padding-bottom: 0px"
                                                :value="loan.system_approval_results.day1_after.month1_qualifies"
                                                label="Day 1 Balance After Salary Qualifies?"
                                                type="text"
                                                disabled
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="2">
                                            <v-icon v-if="loan.system_approval_results.day1_after.month1_qualifies === 'YES'" color="green" x-large>mdi-check-circle</v-icon>
                                            <v-icon v-if="loan.system_approval_results.day1_after.month1_qualifies === 'NO'" color="red" x-large>mdi-close-circle</v-icon>
                                        </v-col>

                                        <v-col cols="12" sm="10">
                                            <v-text-field
                                                style="padding-top: 0px"
                                                :value="loan.system_approval_results.day2_after.month1_qualifies"
                                                label="Day 2 Balance After Salary Qualifies?"
                                                type="text"
                                                disabled
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="2">
                                            <v-icon v-if="loan.system_approval_results.day2_after.month1_qualifies === 'YES'" color="green" x-large>mdi-check-circle</v-icon>
                                            <v-icon v-if="loan.system_approval_results.day2_after.month1_qualifies === 'NO'" color="red" x-large>mdi-close-circle</v-icon>
                                        </v-col>

                                        <v-col cols="12" sm="10">
                                            <v-text-field
                                                style="padding-top: 0px"
                                                :value="loan.system_approval_results.day3_after.month1_qualifies"
                                                label="Day 3 Balance After Salary Qualifies?"
                                                type="text"
                                                disabled
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="2">
                                            <v-icon v-if="loan.system_approval_results.day3_after.month1_qualifies === 'YES'" color="green" x-large>mdi-check-circle</v-icon>
                                            <v-icon v-if="loan.system_approval_results.day3_after.month1_qualifies === 'NO'" color="red" x-large>mdi-close-circle</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" sm="1"></v-col>

                                <v-col cols="12" sm="3" style="border: solid 3px grey; border-radius:10px">
                                    <v-row>
                                        <v-col cols="12" sm="12" >
                                            <h4>Month 2</h4>
                                        </v-col>
                                        <v-col cols="12" sm="10">
                                            <v-text-field
                                                style="padding-top: 0px"
                                                :value="loan.system_approval_results.day1_after.month2_qualifies"
                                                label="Day 1 Balance After Salary Qualifies?"
                                                type="text"
                                                disabled
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="2">
                                            <v-icon v-if="loan.system_approval_results.day1_after.month2_qualifies === 'YES'" color="green" x-large>mdi-check-circle</v-icon>
                                            <v-icon v-if="loan.system_approval_results.day1_after.month2_qualifies === 'NO'" color="red" x-large>mdi-close-circle</v-icon>
                                        </v-col>

                                        <v-col cols="12" sm="10">
                                            <v-text-field
                                                style="padding-top: 0px"
                                                :value="loan.system_approval_results.day2_after.month2_qualifies"
                                                label="Day 2 Balance After Salary Qualifies?"
                                                type="text"
                                                disabled
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="2">
                                            <v-icon v-if="loan.system_approval_results.day2_after.month2_qualifies === 'YES'" color="green" x-large>mdi-check-circle</v-icon>
                                            <v-icon v-if="loan.system_approval_results.day2_after.month2_qualifies === 'NO'" color="red" x-large>mdi-close-circle</v-icon>
                                        </v-col>

                                        <v-col cols="12" sm="10">
                                            <v-text-field
                                                style="padding-top: 0px"
                                                :value="loan.system_approval_results.day3_after.month2_qualifies"
                                                label="Day 3 Balance After Salary Qualifies?"
                                                type="text"
                                                disabled
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="2">
                                            <v-icon v-if="loan.system_approval_results.day3_after.month2_qualifies === 'YES'" color="green" x-large>mdi-check-circle</v-icon>
                                            <v-icon v-if="loan.system_approval_results.day3_after.month2_qualifies === 'NO'" color="red" x-large>mdi-close-circle</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" sm="1"></v-col>

                                <v-col cols="12" sm="3" style="border: solid 3px grey; border-radius:10px">
                                    <v-row>
                                        <v-col cols="12" sm="12" >
                                            <h4>Month 3</h4>
                                        </v-col>
                                        <v-col cols="12" sm="10">
                                            <v-text-field
                                                style="padding-top: 0px"
                                                :value="loan.system_approval_results.day1_after.month3_qualifies"
                                                label="Day 1 Balance After Salary Qualifies?"
                                                type="text"
                                                disabled
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="2">
                                            <v-icon v-if="loan.system_approval_results.day1_after.month3_qualifies === 'YES'" color="green" x-large>mdi-check-circle</v-icon>
                                            <v-icon v-if="loan.system_approval_results.day1_after.month3_qualifies === 'NO'" color="red" x-large>mdi-close-circle</v-icon>
                                        </v-col>

                                        <v-col cols="12" sm="10">
                                            <v-text-field
                                                style="padding-top: 0px"
                                                :value="loan.system_approval_results.day2_after.month3_qualifies"
                                                label="Day 2 Balance After Salary Qualifies?"
                                                type="text"
                                                disabled
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="2">
                                            <v-icon v-if="loan.system_approval_results.day2_after.month3_qualifies === 'YES'" color="green" x-large>mdi-check-circle</v-icon>
                                            <v-icon v-if="loan.system_approval_results.day2_after.month3_qualifies === 'NO'" color="red" x-large>mdi-close-circle</v-icon>
                                        </v-col>

                                        <v-col cols="12" sm="10">
                                            <v-text-field
                                                style="padding-top: 0px"
                                                :value="loan.system_approval_results.day3_after.month3_qualifies"
                                                label="Day 3 Balance After Salary Qualifies?"
                                                type="text"
                                                disabled
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="2">
                                            <v-icon v-if="loan.system_approval_results.day3_after.month3_qualifies === 'YES'" color="green" x-large>mdi-check-circle</v-icon>
                                            <v-icon v-if="loan.system_approval_results.day3_after.month3_qualifies === 'NO'" color="red" x-large>mdi-close-circle</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                             -->
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                <v-card-text>
                    <div style="background:white; height: 20px; width: 100%;"></div>
                    <!-- VALIDATION RESULTS -->
                    <div v-if="validation_results !== null">
                        <v-row style="background:#4490a052;">
                            <v-col cols="12" sm="12">
                                <h1>Validation Results</h1>
                            </v-col>
                        </v-row>
                        <v-row style="background:#4490a052; ">
                            <v-col cols="12" sm="12" style="padding-top:0px">
                                <h3>Overall Status: {{validation_overall_status ? 'VALID' : 'INVALID'}}</h3>
                            </v-col>
                        </v-row>
                        <v-row
                            v-for="(result, index) in validation_results"
                            :key="result._id"

                            style="background:#4490a052; padding-bottom:0px; height: 45px"
                        >
                            <v-col cols="12" sm="1" style="padding-top: 0px">
                                <v-text-field
                                    style="padding-top: 0px"
                                    :value="result.rule_id"
                                    :label="index === 0 ? 'Rule ID' : null"
                                    type="text"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="7" style="padding-top: 0px">
                                <v-text-field 
                                    style="padding-top: 0px"
                                    v-model="result.rule_name"
                                    :label="index === 0 ? 'Rule Name' : null"
                                    type="text"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="1" style="padding-top: 0px">
                                <v-text-field
                                    style="padding-top: 0px"
                                    :value="result.min"
                                    :label="index === 0 ? 'Min' : null"
                                    type="text"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="1" style="padding-top: 0px">
                                <v-text-field
                                    style="padding-top: 0px"
                                    :value="result.max"
                                    :label="index === 0 ? 'Max' : null"
                                    type="text"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="1" style="padding-top: 0px">
                                <v-text-field
                                    style="padding-top: 0px"
                                    :value="result.loan_quote_param_value"
                                    :label="index === 0 ? 'Value' : null"
                                    type="text"
                                    disabled
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="1" style="padding-top: 0px">
                                <v-tooltip bottom v-if="result.valid">
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        color="success"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                    >done</v-icon>
                                    </template>
                                    <span>Rule ran successfully</span>
                                </v-tooltip>
                                <v-tooltip bottom v-else-if="result.waived">
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        color="primary"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                    >mdi-cancel</v-icon>
                                    </template>
                                    <span>Rule was waived</span>
                                </v-tooltip>
                                <v-tooltip bottom v-else>
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        style="color:red"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                    >mdi-close</v-icon>
                                    </template>
                                    <span>{{result.fail_reason}}</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </div>
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting Loan Validation
                        <v-progress-linear
                            indeterminate
                            :active="submitting"
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>

                <!-- Button Section -->
                <v-card-actions>
                    <!-- <v-spacer></v-spacer> -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="amber"
                                v-on="on"
                                @click="submitLoanQuoteValidation()"
                            >
                                <v-icon large>done</v-icon> Run Loan Validation
                            </v-btn>
                        </template>
                        <span>Run Loan Validation</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-menu
                        v-if="(
                            validation_overall_status === true &&
                            loan.system_approval_results.approval_method === 'AUTO-APPROVE'
                        )"
                        v-model="effective_date_menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="effective_date"
                            color="secondary"
                            label="Effective Date"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                            color="secondary"
                            v-model="effective_date"
                            :min="new Date().toISOString().substr(0, 10)"
                            :allowed-dates="allowedEffectiveDates"
                            @input="effective_date_menu = false"
                        ></v-date-picker>
                    </v-menu>
                    <v-tooltip bottom v-if="validation_overall_status === true">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                class="mx-2"
                                v-on="on"
                                @click="upgradeLoanQuoteStatus()"
                                :disabled="upgradeButtonDisabled()"
                            >
                                <v-icon large>mdi-checkbox-marked-circle-outline</v-icon> Upgrade Quote Status
                            </v-btn>
                        </template>
                        <span>Upgrade Loan Status</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../../../../utils/api";
import { eventBus } from "../../../../../../main";
import converter from "../../../../../../mixins/converter";
import { LOAN_QUOTE_STATUS_ACTIONS } from '../../../../../../../config';
import { businessProcessLoanQuote, businessValidateLoanQuote } from '../../../gql/mutations';

export default {
    mixins: [converter],
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        loan: {
            type: Object,
            default: null
        },
    },
    data: () => ({
        submitting: false,
        server_response_obj: null,
        validation_results: null,
        validation_overall_status: null,
        discounted_premium: null,
        system_collection_date: null,

        effective_date_menu: false,
        effective_date: null,
    }),
    methods: {
        allowedEffectiveDates(val) {
            const datePortion = parseInt(val.split('-')[2]);
            return (
                datePortion !== 28 &&
                datePortion !== 29 &&
                datePortion !== 30 &&
                datePortion !== 31
            )
        },
        closeValidateDraftedLoanDialog() {
            this.validation_overall_status = false;
            this.validation_results = null;
            eventBus.$emit('CLOSE_VALIDATE_LOAN_DIALOG', this.server_response_obj);
        },
        async submitLoanQuoteValidation() {
            // Then submit the loan-quote validation
            this.submitting = true;
            
            // Submit loan-quote validation to server
            try {
                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessValidateLoanQuote,
                                                variables: {
                                                    loan_quote_id: this.loan._id
                                                },
                                            });
                this.submitting = false;
                if (res.data.businessValidateLoanQuote.errors === true) {
                    emitAlert(res.data.businessValidateLoanQuote.message);
                    return;
                }
                
                this.validation_overall_status = res.data.businessValidateLoanQuote.overall_status;
                this.discounted_premium = res.data.businessValidateLoanQuote.discounted_premium;
                this.system_collection_date = res.data.businessValidateLoanQuote.system_collection_date;
                this.validation_results = res.data.businessValidateLoanQuote.validation_results;
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessValidateLoanQuote.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
        },
        upgradeButtonDisabled() {
            if (this.validation_overall_status === false) {
                return true;
            }

            if (
                this.validation_overall_status === true &&
                this.loan.system_approval_results.approval_method === 'AUTO-APPROVE'
            ) {
                // Then we need the effective date
                if (this.effective_date === null) {
                    return true;
                }
            }

            return false;
        },
        async upgradeLoanQuoteStatus() {
            this.submitting = true;

            try {
                const payloadObj = {
                    OP_TYPE: 'STATUS',
                    _id: this.loan._id,
                    effective_date: this.effective_date,

                    action_code: LOAN_QUOTE_STATUS_ACTIONS.DRAFT_TO_PENDING_APPROVAL
                };

                const res = await this.$apollo
                                            .mutate({
                                                mutation: businessProcessLoanQuote,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                this.submitting = false;
                if (res.data.businessProcessLoanQuote.errors === true) {
                    emitAlert(res.data.businessProcessLoanQuote.message);
                    return;
                }

                this.server_response_obj = res.data.businessProcessLoanQuote.business_object;
                this.closeValidateDraftedLoanDialog();
                this.server_response_obj = null;
                this.effective_date = null;

                emitSuccess(res.data.businessProcessLoanQuote.message);
                return;
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessLoanQuote.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                return;
            }
        }
    },
    computed: {
        getLoanType() {
            switch(this.loan.pricing_input.loan_type) {
                case 1:
                    return 'Unsecured Credit';
                case 2:
                    return 'Asset Financing';
                case 3:
                    return 'Revolving Credit';
                case 4:
                    return 'Developmental Credit';
                case 5:
                    return 'Mortage Agreement';
                default:
                    return 'UNKNOWN'
            }
        },
        initiationFeeAmount() {
            let initFeeAmount = (
                (parseFloat(this.loan.pricing_input.fees_config.initiation_fee_principal_percentage) / 100) * 
                (parseFloat(this.loan.pricing_input.principal_amount))
            );

            if (isNaN(initFeeAmount)) {
                initFeeAmount = 0;
            }

            // Round to 2 decimal places
            initFeeAmount = Math.round( ( initFeeAmount + Number.EPSILON ) * 100 ) / 100;

            return initFeeAmount;
        },
    },
    mounted() {
    }
}
</script>
<style scoped>

</style>
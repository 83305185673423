import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#333132',
                secondary: '#4590a0',
                secondarylight: '#9fcad3',
                accent: '#00b3c5',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#f14219',
                purple: '#5e2073',
                mutegrey: '#52565a',
                orange: '#f5821f',
                bile: '#b3c500',
                whale: '#f0efef',
                turquoiseblue: '#00b3c5',
                lightgrey: '#aab0bb',
                macgrey: '#707070',
                aluminiumgrey: '#EFEFEF',
                amber: '#f6c209'
            },
            dark: {
                primary: '#333132',
                secondary: '#4590a0',
                secondarylight: '#9fcad3',
                accent: '#00b3c5',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#f14219',
                purple: '#5e2073',
                mutegrey: '#52565a',
                orange: '#f5821f',
                bile: '#b3c500',
                whale: '#f0efef',
                turquoiseblue: '#00b3c5',
                lightgrey: '#aab0bb',
                macgrey: '#707070',
                aluminiumgrey: '#EFEFEF',
                amber: '#f6c209'
            }
        }
    }
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","width":"40%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticStyle:{"background":"white","overflow-y":"auto","height":"100%"}},[_c('v-toolbar',{staticStyle:{"border":"1px solid #07ad2d"},attrs:{"dark":"","color":"secondary"}},[_c('v-spacer'),_c('v-toolbar-title',[_c('b',[_vm._v("Trigger A Business Process")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.resetForm()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Reset Form")])]),(_vm.server_response_obj === null)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.closeTriggerBusinessProcessDialog()}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1),_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-select',{attrs:{"items":_vm.business_processes,"color":"secondary","item-text":"name","item-value":"_id","label":"Select Business Process","hint":"Select a business-process in order to proceed with the download process","persistent-hint":"","return-object":"","required":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"text-color":"white","color":_vm.color_map[item.group]}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('span',[_vm._v(_vm._s(item.name))]),_c('v-spacer'),_c('v-chip',{attrs:{"text-color":"white","color":_vm.color_map[item.group],"small":""}},[_vm._v(_vm._s(item.group))])],1)],1)],1)],1)]}}]),model:{value:(_vm.selected_process),callback:function ($$v) {_vm.selected_process=$$v},expression:"selected_process"}})],1)],1),(_vm.selected_process !== null)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-row',{staticStyle:{"background":"#f6c20b"}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('h3',[_vm._v("Business Process Details")])]),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-textarea',{attrs:{"value":_vm.selected_process.description,"label":"Description","color":"secondary","required":"","rows":"1","auto-grow":"","disabled":""}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-btn',{staticClass:"ma-0",attrs:{"block":"","color":"secondary"},on:{"click":function($event){return _vm.triggerProcessRun()}}},[_c('v-spacer'),_vm._v(" Submit Business Process Trigger "),_c('v-spacer'),_c('v-icon',{attrs:{"large":"","right":""}},[_vm._v(" mdi-run-fast ")]),_c('v-spacer')],1)],1)],1)],1)],1):_vm._e()],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Edit Basic Product Details</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeEditProductDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <div v-if="product_copy !== null">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-model="product_copy.name"
                                    color="secondary"
                                    label="Product Name"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" >
                                <v-select
                                    v-model="product_copy.underwriter"
                                    :items="underwriter_select_items"
                                    color="secondary"
                                    item-text="name"
                                    item-value="_id"
                                    label="Underwriter"
                                    return-object
                                    required
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="4" >
                                <v-text-field
                                    v-model="product_copy.underwriter_premium"
                                    color="secondary"
                                    type="number"
                                    label="Underwriter Premium"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" >
                                <v-text-field
                                    v-model="product_copy.broker_commission"
                                    color="secondary"
                                    type="number"
                                    label="Broker Commission (%)"
                                    hint="The % commission the underwriter pays the broker. This is based on the underwriter premium amount"
                                    persistent-hint
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" >
                                <v-text-field
                                    v-model="product_copy.broker_markup"
                                    color="secondary"
                                    type="number"
                                    label="Broker Markup"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" >
                                <!-- <v-text-field
                                    v-model="product_copy.active"
                                    color="secondary"
                                    type="number"
                                    label="Broker Markup"
                                    required
                                ></v-text-field> -->
                                <v-checkbox
                                    v-model="product_copy.active"
                                    color="secondary"
                                    label="Product Active"
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
                <!-- Submission Results Section -->
                <div v-if="submitting">
                    <v-card-text class="text-center">
                        <v-progress-linear
                            color="green"
                            class="mb-0"
                            height="10"
                            indeterminate
                            :active="submitting"
                        ></v-progress-linear>
                    </v-card-text>
                    <v-list flat>
                        <v-subheader>Saving Product Info</v-subheader>
                        <v-list-item-group color="primary">
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon>done</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Submiting edited product features</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </div>

                <!-- Lower Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        large
                        color="success"
                        :disabled="submitProductDetailsBtnDisabled()"
                        @click="submitProductDetails()"
                    >
                        <v-icon large>save</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../../../utils/api";
import { eventBus } from "../../../../../main";

import { getUnderwriters } from "../gql/queries";
import { productProcessInsuranceProductPrimary } from "../gql/mutations";

export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        product: {
            type: Object,
            default: () => {
                return {
                    _id: null,
                    name: null,
                    underwriter: null,
                    premium: null,
                    underwriter_premium: null,
                    broker_commission: null,
                    features: {
                        principal_members_per_scheme: null,
                        dependents_size: null,
                        principal_member_max_age: null,
                        principal_member_spouse_max_age: null,
                        dependent_max_age: null,
                        general_waiting_period: null,
                        suicide_waiting_period: null,
                        accidental_cover: null,
                        repatriation: null,
                    },
                }
            }
        },
        product_copy: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data: () => ({
        server_resp_obj: null,
        submitting: false,
        underwriter_select_items: []
    }),
    methods: {
        closeEditProductDialog() {
            eventBus.$emit('CLOSE_EDIT_PRODUCT_DETAILS_DIALOG', this.server_resp_obj);
        },
        resetForm() {
            this.submitting = false;
            this.server_resp_obj = false;
            eventBus.$emit('RESET_PRIMARY_PRODUCT_EDIT_FORM', this.product_copy);
        },

        submitProductDetailsBtnDisabled() {
            if (this.product !== null && this.product_copy !== null) {
                if (
                    this.product_copy.name.trim() === this.product.name.trim() &&
                    this.product_copy.underwriter._id === this.product.underwriter._id &&
                    parseFloat(this.product_copy.underwriter_premium) === parseFloat(this.product.underwriter_premium) &&
                    parseFloat(this.product_copy.broker_commission) === parseFloat(this.product.broker_commission) &&
                    parseFloat(this.product_copy.broker_markup) === parseFloat(this.product.broker_markup) &&
                    this.product_copy.active === this.product.active
                ) {
                    return true;
                }
            }
            
            return false;
        },

        async submitProductDetails() {

            this.submitting = true;

            try {
                // Payload with old values
                const payload = {
                    name: this.product_copy.name.trim(),
                    underwriter: this.product_copy.underwriter._id,
                    underwriter_premium: parseFloat(this.product_copy.underwriter_premium),
                    broker_commission: parseFloat(this.product_copy.broker_commission),
                    broker_markup: parseFloat(this.product_copy.broker_markup),
                    active: this.product_copy.active,
                };

                const payloadObj = {
                    OP_TYPE: 'UPDATE',
                    _id: this.product._id,

                    ...payload
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: productProcessInsuranceProductPrimary,
                                                variables: {
                                                    input: payloadObj
                                                },
                                            });
                if (res.data.productProcessInsuranceProductPrimary.errors === true) {
                    emitAlert(res.data.productProcessInsuranceProductPrimary.message);
                    this.submitting = false;
                    return;
                }

                this.server_resp_obj = res.data.productProcessInsuranceProductPrimary.business_object;
                this.closeEditProductDialog();
                emitSuccess(res.data.productProcessInsuranceProductPrimary.message);

                // Reset fields
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.productProcessInsuranceProductPrimary.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        }
    },
    async mounted() {
        // Load Underwriters
        try {
            const res = await this.$apollo
                                .query({
                                    query: getUnderwriters,
                                    // fetchPolicy: 'network-only',
                                    fetchPolicy: 'no-cache',
                                    errorPolicy: 'all',
                                    variables: {
                                        objectType: 'UNDERWRITER',
                                        pagination: {
                                            page: this.page,
                                            limit: this.limit
                                        }
                                    },
                                });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                return;
            }
            this.underwriter_select_items = res.data.getBusinessObject.business_objects;
        } catch (error) {
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
            
            return;
        }
    },
    computed: {

    },
    watch: {
        
    }
}
</script>
<style scoped>
.error-multi-line {
  white-space: pre-line;
}

</style>
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Entity Product Profiling</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text v-if="entity_id !== null && entity_profile !== null">
                    <v-expansion-panels focusable :value="0">
                        <br><br>
                        <v-expansion-panel>
                        <!-- <v-expansion-panel style="background: #a9e8ff8f"> -->
                            <v-expansion-panel-header style="background: #a9e8ff8f">
                                <h2>Credit Profile</h2><br>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                    
                                <br>
                                <h4>Total Principal Amount: </h4> ZAR {{ formatNumber(entity_profile.credit_profile.total_principal_amount) }}
                                <h4>Total Principal Collected To Date: </h4> ZAR {{ formatNumber(entity_profile.credit_profile.total_principal_amount_collected_to_date) }}
                                <h4>Total Active Premium: </h4> ZAR {{ formatNumber(entity_profile.credit_profile.total_active_premium) }}


                                <v-expansion-panels focusable :value="0">
                                    <br><br>
                                    <!-- Credit Score -->
                                    <v-expansion-panel style="background: #d5d4d08f">
                                        <v-expansion-panel-header>
                                            <h2>Credit Score  [ {{ entity_profile.credit_profile.credit_score.overall_credit_score*100 }}% ]</h2><br>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row>
                                                <v-col cols="12" sm="12">
                                                    <v-col
                                                        class="text-subtitle-1 text-center"
                                                        cols="12"
                                                    >
                                                        Overall Performance
                                                    </v-col>

                                                    <v-progress-linear
                                                        :value="entity_profile.credit_profile.credit_score.overall_credit_score*100"
                                                        color="success"
                                                        height="25"
                                                    >
                                                        <template v-slot:default="{ value }">
                                                            <strong>{{ Math.ceil(value) }}%</strong>
                                                        </template>
                                                    </v-progress-linear>
                                                </v-col>
                                                <v-col cols="12" sm="6">
                                                    <v-col
                                                        class="text-subtitle-1 text-center"
                                                        cols="12"
                                                    >
                                                        Principal Repayment Performance
                                                    </v-col>

                                                    <v-progress-linear
                                                        :value="entity_profile.credit_profile.credit_score.principal_repayment_performance*100"
                                                        color="success"
                                                        height="25"
                                                    >
                                                        <template v-slot:default="{ value }">
                                                            <strong>{{ Math.ceil(value) }}%</strong>
                                                        </template>
                                                    </v-progress-linear>
                                                </v-col>
                                                <v-col cols="12" sm="6">
                                                    <v-col
                                                        class="text-subtitle-1 text-center"
                                                        cols="12"
                                                    >
                                                        Profit Performance
                                                    </v-col>
                                                    <v-progress-linear
                                                        :value="entity_profile.credit_profile.credit_score.profit_performance*100"
                                                        color="success"
                                                        height="25"
                                                    >
                                                        <template v-slot:default="{ value }">
                                                            <strong>{{ Math.ceil(value) }}%</strong>
                                                        </template>
                                                    </v-progress-linear>
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>


                                    <!-- Income / Losses -->
                                    <v-expansion-panel style="background: #e8ab3a8f">
                                        <v-expansion-panel-header>
                                            <h2>Income and Losses  [ ZAR {{ entity_profile.credit_profile.income.actual_profit_to_date - entity_profile.credit_profile.income.loss_to_date }} ]</h2><br>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <br>
                                            <v-row>
                                                <v-col cols="12" sm="6">
                                                    <v-text-field
                                                        :value="entity_profile.credit_profile.income.actual_profit_to_date"
                                                        color="secondary"
                                                        label="Income To Date"
                                                        prefix="ZAR"
                                                        autocomplete="some-random-text2"
                                                        type="text"
                                                        disabled
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6">
                                                    <v-text-field
                                                        :value="entity_profile.credit_profile.income.loss_to_date"
                                                        color="secondary"
                                                        label="Losses To Date"
                                                        prefix="ZAR"
                                                        autocomplete="some-random-text2"
                                                        type="text"
                                                        disabled
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>


                                    <!-- Loan Count Info -->
                                    <v-expansion-panel style="background: #8b3ef68f">
                                        <v-expansion-panel-header>
                                            <h2>Loan Count Info</h2><br>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <br>
                                            <v-row>
                                                <v-col cols="12" sm="2">
                                                    <v-text-field
                                                        :value="entity_profile.credit_profile.count.total_loans"
                                                        color="secondary"
                                                        label="Total Loans"
                                                        autocomplete="some-random-text2"
                                                        type="text"
                                                        disabled
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="2">
                                                    <v-text-field
                                                        :value="entity_profile.credit_profile.count.active_loans"
                                                        color="secondary"
                                                        label="Active Loans"
                                                        autocomplete="some-random-text2"
                                                        type="text"
                                                        disabled
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="2">
                                                    <v-text-field
                                                        :value="entity_profile.credit_profile.count.repaid_loans"
                                                        color="secondary"
                                                        label="Repaid Loans"
                                                        autocomplete="some-random-text2"
                                                        type="text"
                                                        disabled
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="2">
                                                    <v-text-field
                                                        :value="entity_profile.credit_profile.count.stage_1__1_to_7_days"
                                                        color="secondary"
                                                        label="Stage 1 (1 - 7 Days)"
                                                        autocomplete="some-random-text2"
                                                        type="text"
                                                        disabled
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="2">
                                                    <v-text-field
                                                        :value="entity_profile.credit_profile.count.stage_1__8_to_30_days"
                                                        color="secondary"
                                                        label="Stage 1 (8 - 30 Days)"
                                                        autocomplete="some-random-text2"
                                                        type="text"
                                                        disabled
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="2">
                                                    <v-text-field
                                                        :value="entity_profile.credit_profile.count.stage_2__31_to_60_days"
                                                        color="secondary"
                                                        label="Stage 2 (31 - 60 Days)"
                                                        autocomplete="some-random-text2"
                                                        type="text"
                                                        disabled
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="2">
                                                    <v-text-field
                                                        :value="entity_profile.credit_profile.count.stage_3__61_to_90_days"
                                                        color="secondary"
                                                        label="Stage 3 (61 - 90 Days)"
                                                        autocomplete="some-random-text2"
                                                        type="text"
                                                        disabled
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="2">
                                                    <v-text-field
                                                        :value="entity_profile.credit_profile.count.stage_3__above_90_days"
                                                        color="secondary"
                                                        label="Stage 3 (Above 90 Days)"
                                                        autocomplete="some-random-text2"
                                                        type="text"
                                                        disabled
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="2">
                                                    <v-text-field
                                                        :value="entity_profile.credit_profile.count.loans_in_arrears"
                                                        color="secondary"
                                                        label="Loans in Arrears"
                                                        autocomplete="some-random-text2"
                                                        type="text"
                                                        disabled
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>


                                        <!-- Loans -->
                                    <v-expansion-panel style="background: #ff4b4b8f">
                                        <v-expansion-panel-header>
                                            <h2>Loans [ {{ entity_profile.credit_profile.loans_list.length }} ]</h2><br>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-expansion-panels focusable>
                                                <br><br>
                                                <!-- Credit Score -->
                                                <v-expansion-panel
                                                    v-for="loan of entity_profile.credit_profile.loans_list"
                                                    :key="loan.loan_number"
                                                    style="background: #d5d4d08f"
                                                >
                                                    <v-expansion-panel-header>
                                                        <h2>L{{ loan.loan_number }} ------ [ Income: ZAR {{ loan.income.actual_profit_to_date - loan.income.loss_to_date }} ]</h2><br>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <br>
                                                        <v-row>
                                                            <!-- First Row -->
                                                            <v-col cols="12" sm="3">
                                                                <v-text-field
                                                                    :value="loan.status"
                                                                    color="secondary"
                                                                    label="Status"
                                                                    autocomplete="some-random-text2"
                                                                    type="text"
                                                                    disabled
                                                                    required
                                                                ></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" sm="3">
                                                                <v-text-field
                                                                    :value="loan.principal_amount"
                                                                    color="secondary"
                                                                    prefix="ZAR"
                                                                    label="Principal Amount"
                                                                    autocomplete="some-random-text2"
                                                                    type="text"
                                                                    disabled
                                                                    required
                                                                ></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" sm="3">
                                                                <v-text-field
                                                                    :value="loan.premium"
                                                                    color="secondary"
                                                                    prefix="ZAR"
                                                                    label="Premium"
                                                                    autocomplete="some-random-text2"
                                                                    type="text"
                                                                    disabled
                                                                    required
                                                                ></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" sm="3">
                                                                <v-text-field
                                                                    :value="loan.active_premium"
                                                                    color="secondary"
                                                                    prefix="ZAR"
                                                                    label="Active Premium"
                                                                    autocomplete="some-random-text2"
                                                                    type="text"
                                                                    disabled
                                                                    required
                                                                ></v-text-field>
                                                            </v-col>

                                                            <!-- Second Row -->
                                                            <v-col cols="12" sm="3">
                                                                <v-text-field
                                                                    :value="loan.current_days_in_arrears"
                                                                    color="secondary"
                                                                    prefix="Days"
                                                                    label="Current Days In Arrears"
                                                                    autocomplete="some-random-text2"
                                                                    type="text"
                                                                    disabled
                                                                    required
                                                                ></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" sm="3">
                                                                <v-text-field
                                                                    :value="loan.oldest_days_in_arrears"
                                                                    color="secondary"
                                                                    prefix="Days"
                                                                    label="Oldest Days In Arrears"
                                                                    autocomplete="some-random-text2"
                                                                    type="text"
                                                                    disabled
                                                                    required
                                                                ></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" sm="3">
                                                                <v-text-field
                                                                    :value="loan.payment_method"
                                                                    color="secondary"
                                                                    label="Payment Method"
                                                                    autocomplete="some-random-text2"
                                                                    type="text"
                                                                    disabled
                                                                    required
                                                                ></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" sm="3">
                                                                <v-text-field
                                                                    :value="loan.payment_date"
                                                                    color="secondary"
                                                                    label="Payment Date"
                                                                    autocomplete="some-random-text2"
                                                                    type="text"
                                                                    disabled
                                                                    required
                                                                ></v-text-field>
                                                            </v-col>

                                                            <!-- 3rd Row -->
                                                            <v-col cols="12" sm="6">
                                                                <v-text-field
                                                                    :value="loan.income.actual_profit_to_date"
                                                                    color="secondary"
                                                                    label="Income To Date"
                                                                    prefix="ZAR"
                                                                    autocomplete="some-random-text2"
                                                                    type="text"
                                                                    disabled
                                                                    required
                                                                ></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" sm="6">
                                                                <v-text-field
                                                                    :value="loan.income.loss_to_date"
                                                                    color="secondary"
                                                                    label="Losses To Date"
                                                                    prefix="ZAR"
                                                                    autocomplete="some-random-text2"
                                                                    type="text"
                                                                    disabled
                                                                    required
                                                                ></v-text-field>
                                                            </v-col>

                                                            <!-- 4th Row (Credit Score) -->
                                                            <v-col cols="12" sm="12">
                                                                <v-col
                                                                    class="text-subtitle-1 text-center"
                                                                    cols="12"
                                                                >
                                                                    Overall Performance
                                                                </v-col>

                                                                <v-progress-linear
                                                                    :value="loan.credit_score.overall_credit_score*100"
                                                                    color="success"
                                                                    height="25"
                                                                >
                                                                    <template v-slot:default="{ value }">
                                                                        <strong>{{ Math.ceil(value) }}%</strong>
                                                                    </template>
                                                                </v-progress-linear>
                                                            </v-col>
                                                            <v-col cols="12" sm="6">
                                                                <v-col
                                                                    class="text-subtitle-1 text-center"
                                                                    cols="12"
                                                                >
                                                                    Principal Repayment Performance
                                                                </v-col>

                                                                <v-progress-linear
                                                                    :value="loan.credit_score.principal_repayment_performance*100"
                                                                    color="success"
                                                                    height="25"
                                                                >
                                                                    <template v-slot:default="{ value }">
                                                                        <strong>{{ Math.ceil(value) }}%</strong>
                                                                    </template>
                                                                </v-progress-linear>
                                                            </v-col>
                                                            <v-col cols="12" sm="6">
                                                                <v-col
                                                                    class="text-subtitle-1 text-center"
                                                                    cols="12"
                                                                >
                                                                    Profit Performance
                                                                </v-col>
                                                                <v-progress-linear
                                                                    :value="loan.credit_score.profit_performance*100"
                                                                    color="success"
                                                                    height="25"
                                                                >
                                                                    <template v-slot:default="{ value }">
                                                                        <strong>{{ Math.ceil(value) }}%</strong>
                                                                    </template>
                                                                </v-progress-linear>
                                                            </v-col>
                                                        </v-row>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>

                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    
                    <!-- <v-card-text class="text-center" v-if="submitting">
                        <v-overlay :value="submitting" :absolute="true">
                            <v-progress-circular
                                color="white"
                                indeterminate
                                size="150"
                                width="9"
                            >
                                <h3 class="text-center">Loading...</h3>
                            </v-progress-circular>
                        </v-overlay>
                    </v-card-text> -->
                </v-card-text>

                <v-card-text class="text-center" v-if="submitting">
                    Retreiving entity profile
                    <v-progress-linear
                        indeterminate
                        :active="submitting"
                        color="green"
                        class="mb-0"
                    ></v-progress-linear>
                </v-card-text>
                <!-- Button Section -->
                <!-- <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom v-if="search_field_selected">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                @click="submitSearchQuery()"
                            >
                                <v-icon large>search</v-icon>
                            </v-btn>
                        </template>
                        <span>Submit Search Query</span>
                    </v-tooltip>
                </v-card-actions> -->
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { eventBus } from '../../../main';
import { emitAlert } from '../../../utils/api';
import { getEntityProfile } from "../gql/queries";
import converter from "../../../mixins/converter";
export default {
    name: 'EntityScoreCardDialog',
    mixins: [converter],
    components: {},
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        entity_id: {
            type: String,
            required: true,
            default: null
        },
    },
    data: () => ({
        effective_date_menu: false,
        entity_profile: null,
        entity_profile_old: {
            credit_profile: {
                total_principal_amount: 0,
                total_principal_amount_collected_to_date: 0,
                total_active_premium: 0,

                credit_score: {
                    repayment_performance: 0,
                    profit_performance: 0,
                    arrears_performance: 0,
                },
                
                count: {
                    total_loans: 0,
                    active_loans: 0,
                    repaid_loans: 0,
                    loans_in_arrears: 0,

                    stage_1__1_to_7_days: 0,
                    stage_1__8_to_30_days: 0,
                    stage_2__31_to_60_days: 0,
                    stage_3__61_to_90_days: 0,
                    stage_3__above_90_days: 0,
                },

                income: {
                    expected_income_at_end: 0,
                    expected_income_to_date: 0,
                    actual_profit_to_date: 0,
                    loss_to_date: 0,
                },

                loans_list: []
            }
        },

        submitting: true
    }),
    methods: {
        closeDialog() {
            eventBus.$emit('CLOSE_PRODUCT_PROFILING_DIALOG', null);
        },
    },
    mounted() {
        
    },
    computed: {},
    watch: {
        async dialog(val) {
            if (val === true) {
                this.submitting = true;

                try {
                    const res = await this.$apollo.query({
                                                        query: getEntityProfile,
                                                        // fetchPolicy: 'network-only',
                                                        fetchPolicy: 'no-cache',
                                                        errorPolicy: 'all',
                                                        variables: {
                                                            entity_id: this.entity_id
                                                        },
                                                    });
                    this.submitting = false;

                    this.entity_profile = res.data.profilingGetEntityProductProfile;
                } catch (error) {
                    this.submitting = false;
                    console.log(error);
                    if (error !== undefined) {
                        emitAlert(error.data.getBusinessObject.message);
                    } else {
                        emitAlert('The server has taken too long in responding to your request');
                    }
                }
            }

            if (val === false) {
                this.entity_profile.total_collected = null;
                this.entity_profile.total_outstanding = null;
                this.entity_profile.total_outstanding_to_date = null;
            }
        }
    }
}
</script>
<style scoped>

</style>
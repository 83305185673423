<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Add VAS Service</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeAddVASDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <!-- VAS Name -->
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="vas.name"
                                color="secondary"
                                label="VAS Service Name"
                                required
                            ></v-text-field>
                        </v-col>
                        <!-- VAS Category -->
                        <v-col cols="12" sm="6">
                            <v-select
                                v-model="vas.category"
                                :items="vas_category_select_items"
                                color="secondary"
                                item-text="name"
                                item-value="_id"
                                label="VAS Category"
                                :disabled="refreshing_vas_category_list"

                                hint="You can click the refresh icon next to the field if the category list is incomplete"
                                persistent-hint

                                required
                            >
                                <!-- Format prepend inner icon -->
                                <template v-slot:prepend-inner>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon
                                                v-on="on"
                                                color="success"
                                                @click="refreshVASCategoryLookupList()"
                                            >
                                                mdi-refresh
                                            </v-icon>
                                        </template>
                                        <span>Refresh VAS Category List</span>
                                    </v-tooltip>
                                </template>
                            </v-select>
                            <v-progress-linear
                                color="green"
                                class="mb-0"
                                height="4"
                                indeterminate
                                :active="refreshing_vas_category_list"
                            ></v-progress-linear>
                        </v-col>
                        <!-- VAS Description Code -->
                        <v-col cols="12" sm="12">
                            <v-textarea
                                v-model="vas.description"
                                color="secondary"
                                label="Description"
                                filled
                                auto-grow
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting">
                        Submitting VAS Details
                        <v-progress-linear
                            indeterminate
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                :disabled="submitVASBtnDisabled()"
                                @click="submitVAS()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Submit VAS Details</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../../utils/api";
import { eventBus } from "../../../../main";

import { getVASCategoryList } from "../gql/queries";
import { createVAS } from "../gql/mutations";

export default {
    name: 'SearchMembersDialog',
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data: () => ({
        vas: {
            name: null,
            category: null,
            description: null
        },
        server_resp_vas: null,
        submitting: false,

        refreshing_vas_category_list: false,
        vas_category_select_items: [],
    }),
    methods: {
        closeAddVASDialog() {
            eventBus.$emit('CLOSE_ADD_VAS_DIALOG', this.server_resp_vas);
        },
        resetForm() {
            this.vas = {
                name: null,
                category: null,
                description: null
            };
            this.server_resp_vas = null;
        },
        submitVASBtnDisabled() {
            if (this.vas.name === null || this.vas.category === null || this.vas.description === null) {
                return true;
            }

            if (typeof(this.vas.name) === 'string') {
                if (this.vas.name.trim() === '') {
                    this.vas.name = null;
                    return true;
                }
            }

            if (typeof(this.vas.description) === 'string') {
                if (this.vas.description.trim() === '') {
                    this.vas.description = null;
                    return true;
                }
            }

            return false;
        },
        async submitVAS() {
            this.submitting = true;

            try {
                // const res = await createVAS(this.vas);
                const res = await this.$apollo
                                            .mutate({
                                                mutation: createVAS,
                                                variables: {
                                                    input: this.vas
                                                },
                                            });
                if (res.data.businessProcessVASService.errors === true) {
                    emitAlert(res.data.businessProcessVASService.message);
                    this.submitting = false;
                    return;
                }

                emitSuccess(res.data.businessProcessVASService.message);
                
                this.submitting = false;
                this.server_resp_vas = res.data.businessProcessVASService.business_object;
                this.closeAddVASDialog();

                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.businessProcessVASService.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        async refreshVASCategoryLookupList() {
            this.refreshing_vas_category_list = true;
            try {
                // Load VAS Categories
                const res = await this.$apollo
                                    .query({
                                        query: getVASCategoryList,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'VAS_CATEGORY',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                this.refreshing_vas_category_list = false;

                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    return;
                }
                this.vas_category_select_items = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.refreshing_vas_category_list = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
    },
    async mounted() {
        // Load lookup data
        try {
            // Load VAS Categories
            const res = await this.$apollo
                                    .query({
                                        query: getVASCategoryList,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'VAS_CATEGORY',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.submitting = false;
                return;
            }
            this.vas_category_select_items = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.submitting = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
            
            return;
        }
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Loan Calculation Results</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card v-if="(calculation_results !== null)">
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12"><h2>Basic Results</h2></v-col>
                        <v-col cols="12" sm="2">
                            <v-text-field
                                v-model="calculation_results.loan_term"
                                label="Term"
                                color="secondary"
                                suffix="MONTHS"
                                required
                                disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="1"></v-col>
                        <v-col cols="12" sm="2">
                            <v-text-field
                                v-model="calculation_results.initiation_fee"
                                label="Initiation Fee"
                                color="secondary"
                                prefix="ZAR"
                                required
                                disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-text-field
                                :style="calculation_results.initiation_fee_paid_at_inception === true ? 'background:#9ee89e' : 'background:#f6b5b5'"
                                :value="calculation_results.initiation_fee_paid_at_inception === true ? 'YES' : 'NO'"
                                label="Initiation Fee Paid At Inception"
                                color="secondary"
                                required
                                disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-text-field
                                v-model="calculation_results.monthly_admin_fee"
                                label="Monthly Admin Fee"
                                color="secondary"
                                prefix="ZAR"
                                required
                                disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-tooltip bottom color="secondary text--white">
                                <template v-slot:activator="{ on }">
                                    <div v-on="on">
                                        <v-text-field
                                            v-if="calculation_results.initiation_fee_paid_at_inception === true"
                                            :value="(calculation_results.total_expected_collection + calculation_results.initiation_fee)"
                                            label="Total Expected Collected"
                                            color="secondary"
                                            prefix="ZAR"
                                            required
                                            disabled
                                        ></v-text-field>
                                        <v-text-field
                                            v-else
                                            :value="(calculation_results.total_expected_collection)"
                                            label="Total Expected Collected"
                                            color="secondary"
                                            prefix="ZAR"
                                            required
                                            disabled
                                        ></v-text-field>
                                    </div>
                                </template>
                                <span v-if="calculation_results.initiation_fee_paid_at_inception === true">
                                    Total Premiums: ZAR {{calculation_results.total_expected_collection}}<br>
                                    Initiation Fees: ZAR {{calculation_results.initiation_fee}}
                                </span>
                                <span v-else>
                                    Total Premiums: ZAR {{calculation_results.total_expected_collection}}<br>
                                    Initiation Fees: ZAR {{0}}
                                </span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <br><br>

                    <h2>Monthly Repayments</h2>
                    <v-row
                        v-for="(resultItem, i) in calculation_results.premium_profile"
                        :key="i"
                    >
                        <v-col cols="12" sm="3">
                            <v-text-field
                                v-model="resultItem.period"
                                label="Period"
                                color="secondary"
                                required
                                disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-tooltip right color="secondary text--white">
                                <template v-slot:activator="{ on }">
                                    <div v-on="on">
                                        <v-text-field
                                            v-model="resultItem.total"
                                            label="Total Monthly Installment"
                                            color="secondary"
                                            prefix="ZAR"
                                            required
                                            disabled
                                        ></v-text-field>
                                    </div>
                                </template>
                                <span>
                                    Capital Repyament: ZAR {{resultItem.principal}}<br>
                                    Initiation Fees: ZAR {{resultItem.initiation_fees}}<br>
                                    Admin Fees: ZAR {{resultItem.admin_fees}}<br>
                                    Interest: ZAR {{resultItem.interest_amount}}
                                </span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <br><br>

                    <!-- <h2>Amortization Schedule</h2>
                    <v-row
                        no-gutters
                        v-for="(resultItem, i) in calculation_results.amortization_schedule"
                        :key="i+'_'"
                    >
                        <v-col cols="12" sm="3" v-if="(i === 0)">
                            <v-text-field
                                v-model="resultItem.installment_number"
                                label="Installment Number"
                                color="secondary"
                                required
                                disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3" v-else>
                            <v-text-field
                                v-model="resultItem.installment_number"
                                color="secondary"
                                required
                                disabled
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="3" v-if="(i === 0)">
                            <v-text-field
                                v-model="resultItem.premium_total"
                                label="Premium"
                                color="secondary"
                                prefix="ZAR"
                                required
                                disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3" v-else>
                            <v-text-field
                                v-model="resultItem.premium_total"
                                color="secondary"
                                prefix="ZAR"
                                required
                                disabled
                            ></v-text-field>
                        </v-col>
                    </v-row> -->
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { eventBus } from '../../../../main';
// import { emitAlert } from '../../../../utils/api';
export default {
    name: 'ViewLoanCalculationResultsDialog',
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        calculation_results: {
            type: Object,
            required: true,
            default: () => {
                return {}
            }
        },
    },
    data: () => ({
    }),
    methods: {
        closeDialog() {
            eventBus.$emit('CLOSE_VIEW_LOAN_CALCULATION_RESULTS_DIALOG', null);
        },
    },
    mounted() {
        
    },
    computed: {},
}
</script>
<style scoped>

</style>
<template>
    <div>
        <v-toolbar
            color="secondary"
            dark
            class="pa-0"
            height="50"
            extension-height="3"
        >
            <v-row>
                <!-- Grid Buttons -->
                <v-col sm="8" style="margin-top:10px">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="refreshDataTable()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh</span>
                    </v-tooltip>

                    <v-tooltip v-if="selectedRelType != null">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary"
                                small
                                v-on="on"
                                @click="openEditRelTypeDialog()"
                            >
                                <v-icon>edit</v-icon>
                            </v-btn>
                        </template>
                        <span>Edit Record</span>
                    </v-tooltip>
                </v-col>


                <!-- Action Buttons -->
                <v-col sm="4" style="margin-top:10px">
                    <!-- <v-spacer></v-spacer> -->
                    <div class="text-right">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="amber black--text"
                                    class="mx-2"
                                    @click="openAddRelTypeDialog()"
                                    v-on="on"
                                >
                                    <v-icon medium>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Add Relationship</span>
                        </v-tooltip>
                    </div>
                </v-col>
            </v-row>
        </v-toolbar>
        <v-data-table
            fixed-header
            height="600"
            :headers="rel_type_headers"
            :items="relationship_types_list"
            item-key="_id"
            class="elevation-1"
            :single-select="true"
            @click:row="selectMainTableRow"
            item-class="secondary"
            hide-default-footer
            :disable-pagination="true"
            :loading="data_table_loading"
            loading-text="Loading... Please wait"
        >
            <template v-slot:[`item.payout_target_roles_for_principal`]="{ item }" >
                <td>
                    <span
                        v-for="(role) in item.payout_target_roles_for_principal"
                        :key="role._id"
                    >
                        # {{role}}<br>
                    </span>
                </td>
            </template>
        </v-data-table>
        <AddRelTypeDialog
            :dialog="open_add_rel_type_dialog"
            :rel_type_list="relationship_types_list"
        />
        <EditRelTypeDialog
            v-if="selectedRelType !== null"
            :dialog="open_edit_rel_type_dialog"
            :selected_rel_type="selectedRelType"
            :selected_rel_type_copy="selectedRelTypeCopy"
            :rel_type_list="relationship_types_list"
        />
    </div>
</template>
<script>
// import { Multipane/*, MultipaneResizer*/ } from 'vue-multipane';
import { emitAlert } from '../../../utils/api';
import AddRelTypeDialog from './dialogs/add_rel_type';
import EditRelTypeDialog from './dialogs/edit_rel_type';
import { eventBus } from "../../../main";

import { getEntityRelationshipTypes } from "./gql/queries";

export default {
    components: {
        // Multipane,
        AddRelTypeDialog,
        EditRelTypeDialog,
    },

    data: () => ({
        selectedRelType: null,
        selectedRelTypeCopy: null,
        selectedRelTypeMetaData: null,
        selectedRow: null,
        data_table_loading: false,

        rel_type_headers: [
            { text: 'Name', value: 'name' },
            { text: 'Target Gender', value: 'gender_map' },
            { text: 'Opposite Relationship', value: 'opposite_relationship.name' },
            { text: 'Description', value: 'description' },
            { text: 'Payout Target Roles For Principal', value: 'payout_target_roles_for_principal' },
        ],
        
        // Table data models
        relationship_types_list: [],

        // Dialogs
        open_add_rel_type_dialog: false,
        open_edit_rel_type_dialog: false,
    }),

    methods: {
        //-------------------- OPEN DIALOGS ---------------------//
        openAddRelTypeDialog() {
            this.open_add_rel_type_dialog = true;
        },
        openEditRelTypeDialog() {
            this.open_edit_rel_type_dialog = true;
        },

        //------------------- CLOSE DIALOGS---------------------//
        closeAddRelTypeDialog(relTypeObj) {
            if (relTypeObj !== null) {
                this.relationship_types_list.push(relTypeObj);
            }

            // this.refreshDataTable();
            this.open_add_rel_type_dialog = false;
            // this.selectedRelType = null;
            // this.selectedRelTypeMetaData = null;
        },
        closeEditRelTypeDialog(relTypeObj) {
            if (relTypeObj !== null) {
                const obj_index = this.relationship_types_list.map(obj => { return obj._id; }).indexOf(relTypeObj._id)
                this.relationship_types_list[obj_index] = relTypeObj;

                // Duplicate array to force re-rendering of datatable
                const duplicateArr = [...this.relationship_types_list];
                this.relationship_types_list = [];
                this.relationship_types_list = duplicateArr;
            }

            // this.refreshDataTable();
            this.open_edit_rel_type_dialog = false;
            // this.selectedRelType = null;
            // this.selectedRelTypeMetaData = null;
        },
        selectMainTableRow(item, item_metadata) {
            if (!item_metadata.isSelected) {
                // Highlight selected row
                item_metadata.select(true);

                this.selectedRelType = item;
                this.selectedRelTypeMetaData = item_metadata;

                // The following is to avoid null-pointer exceptions on the "opposite_relationship._id" filed
                if (this.selectedRelType.opposite_relationship === null) {
                    this.selectedRelType.opposite_relationship = { _id: null }
                }

                // Set copy
                this.selectedRelTypeCopy = Object.assign({}, this.selectedRelType);
            } else {
                this.selectedRelType = null;
                this.selectedRelTypeMetaData = null;
                // Unighlight selected row
                item_metadata.select(false);
            }
        },
        async refreshDataTable() {
            this.data_table_loading = true;
            this.relationship_types_list = [];
            this.selectedRelType = null;
            // Unselect selected row
            if (this.selectedRelTypeMetaData !== null) {
                this.selectedRelTypeMetaData.select(false);
            }


            try {
                const res = await this.$apollo
                                    .query({
                                        query: getEntityRelationshipTypes,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'ENTITY_RELATIONSHIP_TYPE',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
                if (res.data.getBusinessObject.errors === true) {
                    emitAlert(res.data.getBusinessObject.message);
                    this.data_table_loading = false;
                    return;
                }

                this.data_table_loading = false;
                this.relationship_types_list = res.data.getBusinessObject.business_objects;
            } catch (error) {
                this.data_table_loading = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.getBusinessObject.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
            }
        },

        resetRelTypeEditForm(/*updatedRelType*/) {
            // if (
            //     updatedRelType.name !== this.selectedRelTypeCopy.name ||
            //     updatedRelType.gender_map !== this.selectedRelTypeCopy.gender_map ||
            //     updatedRelType.description !== this.selectedRelTypeCopy.description ||
            //     updatedRelType.opposite_relationship._id !== this.selectedRelTypeCopy.opposite_relationship._id
            // ) {
            //     }
            this.selectedRelTypeCopy = Object.assign({}, this.selectedRelType);
        }
    },

    async mounted() {
        this.data_table_loading = true;

        try {
            const res = await this.$apollo
                                    .query({
                                        query: getEntityRelationshipTypes,
                                        // fetchPolicy: 'network-only',
                                        fetchPolicy: 'no-cache',
                                        errorPolicy: 'all',
                                        variables: {
                                            objectType: 'ENTITY_RELATIONSHIP_TYPE',
                                            pagination: {
                                                page: this.page,
                                                limit: this.limit
                                            }
                                        },
                                    });
            if (res.data.getBusinessObject.errors === true) {
                emitAlert(res.data.getBusinessObject.message);
                this.data_table_loading = false;
                return;
            }

            this.data_table_loading = false;
            this.relationship_types_list = res.data.getBusinessObject.business_objects;
        } catch (error) {
            this.data_table_loading = false;
            console.log(error);
            if (error !== undefined) {
                emitAlert(error.data.getBusinessObject.message);
            } else {
                emitAlert('The server has taken too long in responding to your request');
            }
        }

        eventBus.$on('CLOSE_ADD_REL_TYPE_DIALOG', this.closeAddRelTypeDialog);
        eventBus.$on('CLOSE_EDIT_REL_TYPE_DIALOG', this.closeEditRelTypeDialog);
        eventBus.$on('RESET_REL_TYPE_EDIT_FORM', this.resetRelTypeEditForm);
    },

    watch: {
        
    }
}
</script>
<style scoped>
/* Overriding of textbox min height */
 .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
}
</style>
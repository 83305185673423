<template>
    <!-- <input
        ref="inputRef"
        type="text"
        :placeholder="hint"
    /> -->

    <div>
        <label style="font-size: 12px;">{{label}}</label>
        <input
            ref="inputRef"
            type="text"
            :placeholder="hint"
        />
        <label style="font-size: 12px;">{{hint}}</label>
    </div>

    <!-- <v-text-field
        ref="inputRef"
        type="text"
        :label="label"
        :hint="hint"
        color="secondary"
        persistent-hint
    ></v-text-field> -->
</template>
  
<script>
import { watch } from 'vue'
import { useCurrencyInput } from 'vue-currency-input'
  
export default {
    name: 'CurrencyInput',
    props: {
        // modelValue: Number, // Vue 3
        value: Number, // Vue 2: value
        options: Object,
        label: String,
        hint: String,
    },
    setup(props) {
        const { inputRef, setValue } = useCurrencyInput(props.options);

        watch(
            () => props.value, // Vue 2: props.value
            (value) => {
                setValue(value)
            }
        )
    
        return { inputRef }
    }
}
</script>
<style scoped>
input[type=text] {
    width: 100%;
    padding: 5px 20px;
    margin: 0px 0;
    box-sizing: border-box;

    border: 1px solid grey;
    border-radius: 4px;
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"80%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-toolbar',{staticStyle:{"border":"1px solid #07ad2d"},attrs:{"dark":"","color":"secondary"}},[_c('v-toolbar-title',[_c('b',[_vm._v("Add Policy Subsidy")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.resetForm()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Reset Form")])]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.closeAddPolicyTransactionDialog()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card',[_c('v-card-text',[_c('v-row',{staticStyle:{"background":"#eaeaea","padding-bottom":"10px"}},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"items":_vm.subsidy_types,"color":"secondary","label":"Subsidy Type","persistent-hint":"","required":""},model:{value:(_vm.premium_details.payment_method),callback:function ($$v) {_vm.$set(_vm.premium_details, "payment_method", $$v)},expression:"premium_details.payment_method"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"color":"secondary","label":"Start Date","hint":"The date from which the subsidy is active","persistent-hint":"","readonly":""},model:{value:(_vm.premium_details.start_date),callback:function ($$v) {_vm.$set(_vm.premium_details, "start_date", $$v)},expression:"premium_details.start_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.start_date_menu),callback:function ($$v) {_vm.start_date_menu=$$v},expression:"start_date_menu"}},[_c('v-date-picker',{attrs:{"color":"secondary"},on:{"input":function($event){_vm.start_date_menu = false}},model:{value:(_vm.premium_details.start_date),callback:function ($$v) {_vm.$set(_vm.premium_details, "start_date", $$v)},expression:"premium_details.start_date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"color":"secondary","label":"End Date","hint":"(Optional) The date at which the subsidy terminates","persistent-hint":"","readonly":""},model:{value:(_vm.premium_details.end_date),callback:function ($$v) {_vm.$set(_vm.premium_details, "end_date", $$v)},expression:"premium_details.end_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.end_date_menu),callback:function ($$v) {_vm.end_date_menu=$$v},expression:"end_date_menu"}},[_c('v-date-picker',{attrs:{"color":"secondary"},on:{"input":function($event){_vm.end_date_menu = false}},model:{value:(_vm.premium_details.end_date),callback:function ($$v) {_vm.$set(_vm.premium_details, "end_date", $$v)},expression:"premium_details.end_date"}})],1)],1)],1)],1),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"color":"green","height":"10","indeterminate":"","active":_vm.submitting}}),(_vm.submitting)?_c('v-list',{attrs:{"flat":""}},[_c('v-subheader',[_vm._v("Saving Policy Transaction")]),_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.backendActionItems),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("done")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)],1)}),1)],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"large":"","color":"success","disabled":_vm.submitPolicyDocumentBtnDisabled()},on:{"click":function($event){return _vm.savePolicyTransaction()}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("save")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
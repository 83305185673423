<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Re-Activate User</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeReactivateUserDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <h1>User Details</h1>
                        </v-col>
                    </v-row>
                    <v-row v-if="user !== null">
                        <!-- User -->
                        <v-col cols="12" sm="6">
                            <v-text-field
                                :value="user.entity.name + ' ' + user.entity.surname + ' ('+user.entity.id_number+')'"
                                label="User"
                                required
                                disabled
                            ></v-text-field>
                        </v-col>

                        <!-- Status -->
                        <v-col cols="12" sm="6">
                            <v-text-field
                                :value="user.status ? 'Active' : 'Inactive'"
                                label="Status"
                                required
                                disabled
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-card-text class="text-center" v-if="submitting === true">
                        Reactivating User
                        <v-progress-linear
                            indeterminate
                            color="green"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card-text>
                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                large
                                color="success"
                                v-on="on"
                                @click="submitUserReactivation()"
                            >
                                <v-icon large>save</v-icon>
                            </v-btn>
                        </template>
                        <span>Reactivate User</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from '../../../utils/api';
import { eventBus } from "../../../main";
import { entityProcessUser } from "../gql/mutations";
export default {
    components: {
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        user: {
            type: Object,
            default: null
        },
    },
    data: () => ({
        submitting: false,
        server_response_obj: null
    }),
    methods: {
        closeReactivateUserDialog() {
            eventBus.$emit('CLOSE_REACTIVATE_USER_DIALOG', this.server_response_obj);
        },
        async submitUserReactivation() {
            this.submitting = true;

            try {
                const payload = {
                    OP_TYPE: 'REACTIVATE',
                    _id: this.user._id
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: entityProcessUser,
                                                variables: {
                                                    input: payload
                                                },
                                            });
                this.submitting = false;

                if (res.data.entityProcessUser.errors === true) {
                    emitAlert(res.data.entityProcessUser.message);
                    return;
                }

                emitSuccess(res.data.entityProcessUser.message);

                this.server_response_obj = res.data.entityProcessUser.business_object;
                this.closeReactivateUserDialog();
                this.server_response_obj = null;
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.entityProcessUser.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        }
    },
    mounted() {
        
    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
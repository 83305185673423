var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticClass:"pa-0",attrs:{"color":"secondary","dark":"","height":"50","extension-height":"3"}},[_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"10px"},attrs:{"sm":"5"}},[(_vm.SEARCH_MODE === false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.loadMoreData()}}},on),[_c('v-icon',[_vm._v("cloud_download")])],1)]}}],null,false,3262684405)},[_c('span',[_vm._v("Load More Data")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.openSearchCasesDialog()}}},on),[_c('v-icon',[_vm._v("search")])],1)]}}])},[_c('span',[_vm._v("Search for Cases")])]),(_vm.SEARCH_MODE === false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","small":""},on:{"click":function($event){return _vm.refreshCases()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}],null,false,3655229638)},[_c('span',[_vm._v("Refresh")])]):_vm._e()],1),_c('v-col',{attrs:{"sm":"3"}},[(_vm.SEARCH_MODE === true)?_c('v-card-actions',[_c('h3',[_vm._v("Search Results")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"amber black--text"},on:{"click":function($event){return _vm.clearSearchResults()}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("search")]),_c('v-icon',{attrs:{"small":""}},[_vm._v("close")])],1)]}}],null,false,1354360713)},[_c('span',[_vm._v("Clear Search Results")])])],1):_vm._e()],1),_c('v-col',{staticStyle:{"margin-top":"10px"},attrs:{"sm":"4"}},[_c('div',{staticClass:"text-right"},[(_vm.selectedCase !== null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"amber primary--text","small":""},on:{"click":function($event){return _vm.openEditCaseDialog()}}},on),[_c('v-icon',[_vm._v("mdi-eye-circle")]),_vm._v(" View Case ")],1)]}}],null,false,3714770460)},[_c('span',[_vm._v("Edit Record")])]):_vm._e()],1)])],1)],1),_c('multipane',{staticClass:"horizontal-panes",staticStyle:{"height":"100%"},attrs:{"layout":"horizontal"},on:{"paneResize":_vm.paneResizing}},[_c('div',{staticClass:"pane",style:({ height: '50%', maxHeight: '50%'})},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.main_tab_height,"headers":_vm.case_details_headers,"items":_vm.cases,"item-key":"case_number","single-select":true,"item-class":"secondary","hide-default-footer":"","loading":_vm.data_table_loading,"disable-pagination":true},on:{"click:row":_vm.selectMainTableRow},scopedSlots:_vm._u([{key:"item.case_number",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v("CS-"+_vm._s(item.case_number))])]}},{key:"item.next_action_date",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.next_action_date === null ? '' : item.next_action_date.split('T')[0]))])]}},{key:"item.close_date",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.close_date === null ? '' : item.close_date.split('T')[0]))])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.created.split('T')[0]))])]}},{key:"item.loan",fn:function(ref){
var item = ref.item;
return [(item.loan !== null)?_c('td',[_vm._v("L"+_vm._s(item.loan.loan_number))]):_c('td')]}},{key:"item.policy",fn:function(ref){
var item = ref.item;
return [(item.policy !== null)?_c('td',[_vm._v(_vm._s(item.policy.policy_number))]):_c('td')]}},{key:"item.entity",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.entity !== null && item.entity !== undefined ? item.entity.name + ' ' + item.entity.surname + ' (' + item.entity.id_number + ')': '')+" ")])]}},{key:"item.agent",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.agent !== null && item.agent !== undefined ? item.agent.entity.name + ' ' + item.agent.entity.surname + ' (' + item.agent.agent_number + ')': '')+" ")])]}},{key:"item.is_in_disciplinary_action",fn:function(ref){
var item = ref.item;
return [(item.is_in_disciplinary_action === true)?_c('td',{staticStyle:{"background":"red","color":"white","font-weight":"bold","font-size":"20px","border":"1px solid white"}},[_vm._v("YES")]):_c('td',{staticStyle:{"background":"green","color":"white","font-weight":"bold","font-size":"20px","border":"1px solid white"}},[_vm._v("NO")])]}},{key:"item.is_in_litigation",fn:function(ref){
var item = ref.item;
return [(item.is_in_litigation === true)?_c('td',{staticStyle:{"background":"red","color":"white","font-weight":"bold","font-size":"20px","border":"1px solid white"}},[_vm._v("YES")]):_c('td',{staticStyle:{"background":"green","color":"white","font-weight":"bold","font-size":"20px","border":"1px solid white"}},[_vm._v("NO")])]}}])})],1),_c('multipane-resizer'),_c('div',{staticClass:"pane tab-nav",style:({ height: '30%', maxHeight: '50%', minHeight: '30%' })},[_c('v-tabs',{staticClass:"tab-nav",attrs:{"color":"transparent","hide-slider":"","height":"35px","grow":""},model:{value:(_vm.sub_tabs),callback:function ($$v) {_vm.sub_tabs=$$v},expression:"sub_tabs"}},[[_c('v-tab',{key:"case_notes_sub_tab",staticStyle:{"max-width":"210px","font-size":"12px"},attrs:{"active-class":"secondary white--text"}},[_c('span',{staticClass:"tab-title text-truncate"},[_vm._v(" Case Notes ")]),_c('div',{staticClass:"spacer"})])]],2),_c('v-tabs-items',{model:{value:(_vm.sub_tabs),callback:function ($$v) {_vm.sub_tabs=$$v},expression:"sub_tabs"}},[_c('v-toolbar',{staticClass:"pa-0",attrs:{"color":"secondary","dark":"","height":"50","extension-height":"3"}},[_c('v-spacer')],1),_c('v-tab-item',{key:"case_notes_sub_tab"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":_vm.sub_tab_height,"headers":_vm.case_note_details_headers,"items":_vm.case_notes,"item-key":"_id","single-select":true,"hide-default-footer":"","disable-pagination":true},on:{"click:row":_vm.tobeDefined},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.created !== null ? item.created.split('T')[0] + ' @ ' + item.created.split('T')[1].substr(0,8) : ''))])]}},{key:"item.captured_by",fn:function(ref){
var item = ref.item;
return [(item.captured_by !== null && item.captured_by !== undefined)?_c('td',[_vm._v(" "+_vm._s(item.captured_by.entity.name)+" "+_vm._s(item.captured_by.entity.surname)+" ")]):_vm._e()]}}])})],1)],1)],1)],1),_c('SearchLoansDialog',{attrs:{"dialog":_vm.open_search_cases_dialog}}),(_vm.selectedCase !== null)?_c('EditCaseDialog',{attrs:{"dialog":_vm.open_edit_case_dialog,"case_original":_vm.selectedCase,"case_copy":_vm.selectedCaseCopy}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="80%">
            <v-toolbar dark color="secondary" style="border: 1px solid #07ad2d">
                <v-toolbar-title><b>Add Policy Subsidy</b></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="red"
                            small
                            v-on="on"
                            @click="resetForm()"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset Form</span>
                </v-tooltip>
                <v-btn icon @click="closeAddPolicyTransactionDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-row style="background:#eaeaea; padding-bottom:10px">
                        <v-col cols="12" sm="4">
                            <v-select
                                v-model="premium_details.payment_method"
                                :items="subsidy_types"
                                color="secondary"
                                label="Subsidy Type"
                                persistent-hint

                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-menu
                                v-model="start_date_menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="premium_details.start_date"
                                    color="secondary"
                                    label="Start Date"
                                    hint="The date from which the subsidy is active"
                                    persistent-hint
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    color="secondary"
                                    v-model="premium_details.start_date"
                                    @input="start_date_menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-menu
                                v-model="end_date_menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="premium_details.end_date"
                                    color="secondary"
                                    label="End Date"
                                    hint="(Optional) The date at which the subsidy terminates"
                                    persistent-hint
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    color="secondary"
                                    v-model="premium_details.end_date"
                                    @input="end_date_menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-card-text>
                <!-- Progress Section -->
                <v-progress-linear
                    color="green"
                    class="mb-0"
                    height="10"
                    indeterminate
                    :active="submitting"
                ></v-progress-linear>
                <v-list flat v-if="submitting">
                    <v-subheader>Saving Policy Transaction</v-subheader>
                    <!-- <v-list-item-group v-model="item" color="primary"> -->
                    <v-list-item-group color="primary">
                        <v-list-item
                            v-for="(item, i) in backendActionItems"
                            :key="i"
                        >
                            <v-list-item-icon>
                                <v-icon>done</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ item }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>

                <!-- Button Section -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        large
                        color="success"
                        :disabled="submitPolicyDocumentBtnDisabled()"
                        @click="savePolicyTransaction()"
                    >
                        <v-icon large>save</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { emitAlert, emitSuccess } from "../../../utils/api";
import { eventBus } from "../../../main";
import config from "../../../../config";

// import { getSystemFinanceAccounts } from "../gql/queries";
import { createFinanceTransaction } from "../gql/mutations";

export default {
    components: {
        
    },
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        policy: {
            type: Object,
            required: false,
            default: () => {
                return {
                    // name,
                    documents: []
                }
            }
        },
    },
    data: () => ({
        server_resp_obj: null,
        start_date_menu: false,
        end_date_menu: false,
        submitting: false,

        premium_details: {
            start_date: null,
            end_date: null,
            payment_method: null,
            amount: 0,
        },

        backendActionItems: [],

        subsidy_types: [ 'TOPUP', 'CAMPAIGN' ],
    }),
    methods: {
        closeAddPolicyTransactionDialog() {
            eventBus.$emit('CLOSE_ADD_POLICY_SUBSIDY_DIALOG', this.server_resp_obj);
        },
        resetForm() {
            this.premium_details = {
                start_date: null,
                end_date: null,
                payment_method: null,
                amount: 0,
            };
            this.server_resp_obj = null;
            this.backendActionItems = [];
        },

        submitPolicyDocumentBtnDisabled() {
            if (
                this.premium_details.start_date === null ||
                this.premium_details.end_date === null ||
                this.premium_details.payment_method === null ||
                this.premium_details.amount === null ||
                this.premium_details.amount === undefined ||
                parseFloat(this.premium_details.amount) <= 0 ||
                isNaN(parseFloat(this.premium_details.amount))
            ) {
                return true;
            }
            
            return false;
        },
        
        async savePolicyTransaction() {
            if (this.policy.status === config.POLICY_STATUSES.DRAFT) {
                emitAlert(`Adding a premium payment is not allowed for policy in status: ${this.policy.status}`);
                return;
            }

            this.submitting = true;
            try {
                const transactionObj = {
                    // OP_TYPE: 'CREATE',
                    
                    // start_date: this.premium_details.start_date,
                    // end_date: this.premium_details.end_date,
                    // from: this.premium_details.payment_method,
                    // to: this.policy._id,
                    // amount: parseFloat(this.premium_details.amount),
                    // reference: 'CUSTOMER PAYMENT',
                };
                const res = await this.$apollo
                                            .mutate({
                                                mutation: createFinanceTransaction,
                                                variables: {
                                                    input: transactionObj
                                                },
                                            });

                this.submitting = false;
                if (res.data.finProcessFinanceAccountTransaction.errors === true) {
                    emitAlert(res.data.finProcessFinanceAccountTransaction.message);
                    return;
                }

                emitSuccess(res.data.finProcessFinanceAccountTransaction.message);

                this.server_resp_obj = res.data.finProcessFinanceAccountTransaction.business_object;
                this.closeAddPolicyTransactionDialog();

                // Reset form
                this.resetForm();
            } catch (error) {
                this.submitting = false;
                console.log(error);
                if (error !== undefined) {
                    emitAlert(error.data.finProcessFinanceAccountTransaction.message);
                } else {
                    emitAlert('The server has taken too long in responding to your request');
                }
                
                return;
            }
        },
    },
    async mounted() {

    },
    watch: {
        
    }
}
</script>
<style scoped>

</style>
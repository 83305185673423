/**
 * 
 * @param {String} id_number 
 * @returns {Object}
 */
function extractBirthDate(id_number) {
  const result = {
    partial_date_string: null,
    century_string: null
  };

  const idYear = id_number.substr(0, 2);
  const idMonth = id_number.substr(2, 2);
  const idDay = id_number.substr(4, 2);

  const currentDateYear = parseInt( (new Date()).getFullYear().toString().substr(2, 2) );

  result.partial_date_string = `${idYear}-${idMonth}-${idDay}`;

  if (parseInt(idYear) > currentDateYear) {
    // Then they were definitely born in the 1900s
    result.century_string = '19';
  } else {
    result.century_string = '20';
  }

  // If the above confition is not met, then its ambiguous, and the user needs to specify the year

  return result;
}

/**
 * 
 * @param {String} id_number 
 */
function extractGender(id_number) {
  const genderPortion = id_number.substr(6, 4);

  // 0000-4999 and males from 5000-9999.
  const genderPortionInt = parseInt(genderPortion);
  if (genderPortionInt >= 0 && genderPortionInt <= 4999) {
    return 'Female';
  } else if (genderPortionInt >= 5000 && genderPortionInt <= 9999) {
    return 'Male';
  } else {
    return null;
  }
}

/**
 * Implementation of Luhn algorithm
 * @param {String} id_number 
 */
function idValid(id_number) {
  let result = false;
  if (id_number.length === 13) {
    // Get every second digit and double them
    let pos1 =  parseInt(id_number.charAt(0));
    let pos2 =  parseInt(id_number.charAt(1))*2;
    let pos3 =  parseInt(id_number.charAt(2));
    let pos4 =  parseInt(id_number.charAt(3))*2;
    let pos5 =  parseInt(id_number.charAt(4));
    let pos6 =  parseInt(id_number.charAt(5))*2;
    let pos7 =  parseInt(id_number.charAt(6));
    let pos8 =  parseInt(id_number.charAt(7))*2;
    let pos9 =  parseInt(id_number.charAt(8));
    let pos10 = parseInt(id_number.charAt(9))*2;
    let pos11 =  parseInt(id_number.charAt(10));
    let pos12 = parseInt(id_number.charAt(11))*2;
    let pos13 =  parseInt(id_number.charAt(12));

    // Add the resultant second digits together
    pos2 = _addNumberDigits(pos2);
    pos4 = _addNumberDigits(pos4);
    pos6 = _addNumberDigits(pos6);
    pos8 = _addNumberDigits(pos8);
    pos10 = _addNumberDigits(pos10);
    pos12 = _addNumberDigits(pos12);

    // Add the resultant digits together, with the remaining (odd) digits of the ID number.
    const total_sum = pos1 + pos2 + pos3 + pos4 + pos5 + pos6 + pos7 + pos8 + pos9 + pos10 + pos11 + pos12 + pos13;
        
    // Id is valid if total_sum divided by 10 leaves no remainder
    result = (total_sum%10 === 0);
  }

  return result;
}

function _addNumberDigits(number) {
  const digits = number.toString().split('').map(iNum => parseInt(iNum, 10));
  const arrSum = (arr) => arr.reduce((a,b) => a + b, 0);

  let result = arrSum(digits);

  if (result > 9) {
    result = _addNumberDigits(result);
  }

  return result;
}

module.exports = {
  extractBirthDate,
  extractGender,
  idValid
}
import gql from 'graphql-tag'

export const authLoginStage1 = gql`
    mutation authLoginStage1($username: String!) {
        authLoginStage1(username: $username) {
            errors
            existing_user
            message
            users {
                _id
                new_login
                business_partner {
                    _id
                    name
                }
            }
        }
    }
`

export const authRegisterUser = gql`
    mutation authRegisterUser($input: RegisterUserInput!) {
        authRegisterUser(input: $input) {
            errors
            successful
            message
        }
    }
`

export const authLogin = gql`
    mutation authLogin($input: LoginInput!) {
        authLogin(input: $input) {
            errors
            token
            message
            context_list {
                selected
                business_partner {
                    _id
                    name
                }
            }
        }
    }
`
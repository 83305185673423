import gql from 'graphql-tag'

export const productProcessInsuranceProductPrimary = gql`
    mutation productProcessInsuranceProductPrimary($input: InsuranceProductPrimaryInput!) {
        productProcessInsuranceProductPrimary(input: $input) {
            errors
            message
            business_object {
                ... on InsuranceProductPrimary {
                    __typename
                    _id
                    name
                    underwriter {
                        _id
                        name
                    }
                    underwriter_premium
                    broker_commission
                    broker_markup
                    active

                    payout_structure {
                        _id
                        category {
                            _id
                            name
                            membership_category
                            start_age
                            end_age
                        }
                        payout_amount
                    }
                    rules {
                        _id
                        rule_parameter {
                            _id
                            id
                            level
                            name
                            description
                            object_key
                        }
                        min
                        max
                    }
                    vas_services {
                        _id
                        limit_model
                        limit
                        created
                        vas_service {
                            _id

                            name
                            # category {
                            #     _id
                            #     name
                            # }
                            description
                            captured_by {
                                _id
                                entity {
                                    _id
                                    name
                                    surname
                                }
                            }
                            created
                        }
                    }
                }
            }
        }
    }
`